import React from "react";
import { connect } from "react-redux";
import "styles/components/map-info.css";
import StackedBarCenterAxis from "components/shared/charts/StackedBarCenterAxis";
import PieChart from "components/shared/charts/PieChart";
import NumberEasing from "che-react-number-easing";
import SESPie from "components/shared/charts/SESPie";
import Bar100Horizontal from "components/shared/charts/Bar100Horizontal";

const sesColorScale =[
  "#007b4a",
  "#ffaa55",
  "#9335f3",
  "#0097a5",
  "#a91922"
]

const MapStackedInfo = props => (
  <div className={`map-info-stack ${props.show ? "active" : ""}`}>
    <span
      className="button-close-diagonal"
      onClick={() => props.handleToggle()}
    >
      <i
        className={`icon dripicons-${props.show ? "cross" : "chevron-left"}`}
      />
    </span>
    <div className={`stack-item ${props.info.dpt !== null ? "active" : ""}`}>
      <h4>TOTAL TURNOUT MEMILIH di INDONESIA </h4>
      <span className="stack-total-penduduk red">
        <NumberEasing
          value={props.info.province.total_dpt}
          speed={3000}
          ease="quintInOut"
          useLocaleString={true}
          precision={0}
        />
      </span>
      {/* <span className="stack-total-penduduk">+ </span> */}
      <span className="stack-small"> Jiwa</span>
    </div>
    {props.info.gender.show && (
      <div className="stack-item active">
        <div className="stack-two">
          <div className="stack-two-items">
            <h4>Pria</h4>
            <span className="stack-total-gender pria">
              <NumberEasing
                value={props.info.gender.value.total_male}
                speed={3000}
                ease="quintInOut"
                useLocaleString={true}
                precision={0}
              />
            </span>
          </div>
          <div className="stack-two-items">
            <h4>Wanita</h4>
            <span className="stack-total-gender wanita">
              <NumberEasing
                value={props.info.gender.value.total_female}
                speed={3000}
                ease="quintInOut"
                useLocaleString={true}
                precision={0}
              />
            </span>
          </div>
        </div>
      </div>
    )}
    {props.info.age.show && (
      <div className="stack-item active">
        <h4>Kelompok Umur</h4>
        <StackedBarCenterAxis age={props.info.age.value} />
      </div>
    )}
    {props.info.agama.show && (
      <div className="stack-item active">
        <h4>Religi</h4>
        <PieChart agama={props.info.agama.value} />
      </div>
    )}
    {props.info.pendidikan.show && (
      <div className="stack-item active">
        <h4>Pendidikan</h4>
        <Bar100Horizontal pendidikan={props.info.pendidikan.value} />
      </div>
    )}
    {props.info.ses.show && (
      <div className="stack-item active">
        <h4>Status Sosial Ekonomi </h4>
        <SESPie ses={props.info.ses.value} />
        <div className="stack-small social-economy-status">
          {props.info.ses.value.data.map((d, i) => (
            <p key={i}>
              <span>
                {((d.total_data / props.info.ses.value.total_value) * 100).toFixed(2)} %
              </span>
              <span style={{ color: sesColorScale[i] }}>{d.order_name}</span> :{" "}
              <span>{d.order_value}</span>
            </p>
          ))}

          {/* <p>
            <span>33%</span>
            <span style={{ color: "#ffaa55" }}>Group B</span> :{" "}
            <span>Rp 5.000.000 - Rp 7.500.000</span>
          </p>
          <p>
            <span>10%</span>
            <span style={{ color: "#9335f3" }}>Group C</span> :{" "}
            <span>Rp 3.000.000 - Rp 5.000.000</span>
          </p>
          <p>
            <span>27%</span>
            <span style={{ color: "#0097a5" }}>Group D</span> :{" "}
            <span>Rp 1.000.000 - Rp 2.500.000</span>
          </p>
          <p>
            <span>20%</span>
            <span style={{ color: "#a91922" }}>Group E</span> :{" "}
            <span> Rp. 500.000 + </span>
          </p> */}
        </div>
      </div>
    )}
  </div>
);

const mapStateToProps = state => ({
  info: state.map
});

export default connect(mapStateToProps)(MapStackedInfo);
