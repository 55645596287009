import React, { Fragment, Component } from "react";

import Sidebar from "components/layout/sidebar/Sidebar";
import Map from "components/shared/map/Map";
import MapStackedInfo from "./MapStackedInfo";
import ModalDialog from "components/shared/ModalDialog";
import { VictoryChart, VictoryStack, VictoryBar, VictoryAxis } from "victory";
import { Dark } from "components/shared/charts/themeVictory";
import SubmenuRegion from "components/layout/sidebar/SubmenuRegion";
import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";
import CustomLabel from "components/shared/charts/CustomLabel";
import GroupedBarTurnout from "components/shared/charts/GroupedBarTurnout";

import PieRechart from "components/shared/charts/PieRechart";
import BarChartHRechart from "components/shared/charts/BarChartHRechart";

import { getTurnout } from "actions/mapActions";
import {
  horserace,
  reelect,
  undecided_a,
  undecided_b,
  turnout_binary,
  turnout_continuous
} from "./turnoutDummy";

import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import "styles/components/map-submenu.css";
import MapTurnout from "components/shared/map/MapTurnout";


const API_HOST = process.env.REACT_APP_API_HOST;

class TurnoutScore extends Component {
  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "Intent to Get Vaccinated",
        map_type: "horserace",
        data: [
          { id: 1, name: "Intent to Get Vaccinated" },
          { id: 2, name: "Vaccine Effectiveness" },
          { id: 3, name: "Willingness to be Vaccinated" },
          { id: 4, name: "Confidence in Ability to Get Vaccine" }
        ]
      }
    },
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "Intent to Get Vaccinated" },
            { id: 2, name: "Vaccine Effectiveness" },
            { id: 3, name: "Willingness to be Vaccinated" },
            { id: 4, name: "Confidence in Ability to Get Vaccine" }
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
          // err.response.status === 401
          //   ? this.props.reAuth()
          //   : toastr.error(err.response.statusText);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
    // await GET(API_HOST + "/sental/master/pulau")
    //   .then(res =>
    //     this.setState({
    //       ...this.state,
    //       region: {
    //         ...this.state.region,
    //         pulau: { ...this.state.region.pulau, data: res.data },
    //         step: 1
    //       }
    //     })
    //   )
    //   .catch(err => {
    //     err.response.status === 401
    //       ? this.props.reAuth()
    //       : toastr.error(err.response.statusText);
    //   });
  }

  render() {
    const { info, state, handleToggleSatellite } = this.props;
    const {
      openMap,
      isSubOpen,
      openPOI,
      isPOISubOpen,
      region,
      turnout
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition,
      handleGetSubMenu,
      handleChangeMethod
    } = this;

    let dataSource;
    let turnout_intent_get_vaccinated = {
      max_value: 100,
      total_value: 100,
      data: [
        {
          order_id: 1,
          order_name: "Total Try",
          left_label: "Probably Try",
          right_label: "Definitely Try",
          left_data: 33,
          right_data: 29
        },
        {
          order_id: 2,
          order_name: "Total Not Try",
          left_label: "Probably Not Try",
          right_label: "Definitely Not Try",
          left_data: 9,
          right_data: 7
        },
        {
          order_id: 3,
          order_name: "Total Unsure",
          left_label: "Don't Know",
          right_label: "Might or might not",
          left_data: 6,
          right_data: 16
        },
      ]
  }

  let turnout_confidence_get_vaccinated = {
    max_value: 100,
    total_value: 100,
    data: [
      {
        order_id: 1,
        order_name: "Total confident",
        left_label: "Somewhat confident",
        right_label: "Very confident",
        left_data: 54,
        right_data: 7
      },
      {
        order_id: 2,
        order_name: "Total Not Confident",
        left_label: "Not very confident",
        right_label: "Not at all confident",
        left_data: 31,
        right_data: 2
      },
      {
        order_id: 3,
        order_name: "Total Unsure",
        left_label: "Don't Know",
        right_label: "Don't Know",
        left_data: 3,
        right_data: 3
      },
    ]
}

let data_effectiveness = [
  { name: "Sangat yakin", value: 31 },
  { name: "Agak yakin", value: 43 },
  { name: "Tidak terlalu yakin", value: 18 },
  { name: "Sama sekali tidak yakin", value: 2 },
  { name: "Tidak tahu", value: 6 },
];

let data_vaccine_keluarga = [
  { name: "Saya sendiri / teman dekat / keluarga saya telah di vaksinasi atau punya janji vaksinasi", value: 28 },
  { name: "Baik saya / teman / keluarga saya belum di vaksinasi dan tidak ada janji vaksinasi", value: 52 },
  { name: "Tidak Tahu", value: 20 },
];

let data_vaccine_aman = [
  {
    "name": "Sangat yakin",
    "value": 35
  },
  {
    "name": "Agak yakin",
    "value": 41
  },
  {
    "name": "Tidak terlalu yakin",
    "value": 17
  },
  {
    "name": "Sama sekali tidak yakin",
    "value": 2
  },
  {
    "name": "Tidak tahu",
    "value": 6
  }
];

let data_janji_divaksin = [
  {
    "name": "Saya tidak akan di vaksinasi kecuali halal dan saya tidak percaya bahwa vaksin itu halal",
    "value": 11
  },
  {
    "name": "Saya hanya akan di  vaksinasi jika halal dan saya percaya vaksin itu halal",
    "value": 48
  },
  {
    "name": "Saya akan di vaksinasi jika vaksin efektif dan tidak penting vaksin apakah halal atau tidak",
    "value": 17
  },
  {
    "name": "Saya tidak akan di vaksinasi walaupun halal",
    "value": 8
  },
  {
    "name": "Tidak tahu",
    "value": 16
  }
];

let color_common =  [
  "#68f76a",
  "#b8fcb9",
  "#f74a64",
  "#ff2b2b",
  "#e8e8e8"
];



    let groupBarData = turnout_intent_get_vaccinated;

    //chart
    let chart_vaccinated;

    switch (turnout.method.value) {
      case "Intent to Get Vaccinated":
        dataSource = {value : horserace, max: 25000000, min: 5000000};
        turnout.method.map_type = "horserace"
        groupBarData = turnout_intent_get_vaccinated;

        chart_vaccinated = <div className="col-lg-12" style={{ height: "100%" }}>
        <GroupedBarTurnout
            data={groupBarData}
            color={"qualitative"}
            total={groupBarData.total_value}
          />
      </div>
        break;
      case "Confidence in Ability to Get Vaccine":
        dataSource = {value : reelect , max: 17300000, min: 17000000};
        turnout.method.map_type = "reelect"
        groupBarData = turnout_confidence_get_vaccinated;

        chart_vaccinated = <div className="col-lg-12" style={{ height: "100%" }}>
        <GroupedBarTurnout
            data={groupBarData}
            color={"qualitative"}
            total={groupBarData.total_value}
          />
      </div>
        break;
      case "Willingness to be Vaccinated":

        chart_vaccinated = <Fragment>
          <div className="col-lg-6" style={{ height: "70%", marginTop: "80px", marginBottom: "20px" }}>            
            <h5 className="col-lg-12" 
              style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-10px'}}>
              Majelis Ulama Indonesia telah menetapkan bahwa vaksin Sinovac CoronaVac adalah halal. Mana yang paling dekat dengan pandangan Anda?
            </h5>
            <div style={{height: '65%', marginTop:'30px', marginBottom: '30px'}}>
              <BarChartHRechart data={data_janji_divaksin} />
            </div>
          </div>
          <div className="col-lg-6" style={{ height: "95%", marginTop: "20px", marginBottom: "20px" }}> 
            <h5 className="col-lg-12" 
              style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white',marginTop: "60px", marginBottom:'-10px'}}>
              Apakah Anda memiliki teman atau keluarga yang sudah di vaksinasi atau punya janji untuk di vaksinasi?
            </h5>
            <div style={{height: '100%', marginTop:'-40px', marginBottom: '10px'}}>
              <PieRechart data={data_vaccine_keluarga} colors={color_common}/>
            </div>
          </div>
        </Fragment>
        break;
      case "Vaccine Effectiveness":
        chart_vaccinated = <Fragment>
          <div className="col-lg-6" style={{ height: "80%", marginTop: "80px", marginBottom: "20px" }}>            
            <h5 className="col-lg-12" 
              style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-10px'}}>
              Seberapa yakin Anda bahwa vaksin tersebut aman ?
            </h5>
            <div style={{height: '65%', marginTop:'30px', marginBottom: '30px'}}>
              <BarChartHRechart data={data_vaccine_aman} />
            </div>
          </div>
          <div className="col-lg-6" style={{ height: "95%", marginTop: "20px", marginBottom: "20px" }}> 
            <h5 className="col-lg-12" 
              style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white',marginTop: "60px", marginBottom:'-10px'}}>
              Seberapa yakin Anda bahwa vaksin tersebut efektif?
            </h5>
            <div style={{height: '100%', marginTop:'-40px', marginBottom: '10px'}}>
              <PieRechart data={data_effectiveness} colors={color_common}/>
            </div>
          </div>
        </Fragment>
        break;
      default:
        chart_vaccinated = <div className="col-lg-12" style={{ height: "100%" }}>
        <GroupedBarTurnout
            data={groupBarData}
            color={"qualitative"}
            total={groupBarData.total_value}
          />
      </div>
        break;
    }

    return (
      <div className="map50">
        <div className="row heightTurnoutBar" style={{borderBottom: '3px solid #932427'}}>
          <div className="col-lg-12">
            <div className="sidebar sidebar-left">
              <div className="sidebar-content">
                <nav className="main-menu">
                  <ul className="nav">
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}
                      >
                        <i className="icon dripicons-graph-bar" />
                      </a>
                      <SubmenuTurnout
                        handleToggleInfo={handleToggleInfo}
                        handleGetSubMenu={handleGetSubMenu}
                        handleToggleSubMenu={handleTogglePOISubMenu}
                        handleCloseSubmenu={handleCloseSubmenu}
                        handleChangeMethod={handleChangeMethod}
                        placeholder="Select Scoring"
                        data={{
                          info,
                          openPOI,
                          isPOISubOpen,
                          handleSubMenuChange,
                          turnout
                        }}
                      />
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          {chart_vaccinated}
        </div>
        <MapTurnout source="turnout" type="vaccinated" />
        <ModalDialog />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnoutScore);
