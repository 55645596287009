import React, { PureComponent, FunctionComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, Text } from 'recharts';

const data_default = [
  {
    "name": "Providing accurate information about coronavirus and the vaccine",
    "value": 6.6
  },
  {
    "name": "Ensuring that the coronavirus vaccines are halal",
    "value": 6.5
  },
  {
    "name": "Coronavirus response",
    "value": 6.5
  },
  {
    "name": "Prioritizing the right population for coronavirus vaccine distribution and delivery",
    "value": 6.2
  },
  {
    "name": "Ensuring rapid coronavirus vaccination nationwide",
    "value": 6.2
  },
  {
    "name": "Obtaining sufficient doses of coronavirus vaccine",
    "value": 6.2
  },
  {
    "name": "Managing state-owned enterprises",
    "value": 6.0
  },
  {
    "name": "Economy and jobs",
    "value": 5.6
  },
  {
    "name": "Fighting corruption",
    "value": 5.5
  }
];

class CustomizedLabel extends PureComponent {
    render() {
    const { x, y, fill, value } = this.props;
  
      return (
        <text 
            x={x} 
            y={y} 

            fontSize='16' 
            fontFamily='sans-serif'
            fill={fill}
            textAnchor="start">{value}
        </text>
      );
    }
        
  }

export default class BarChartHRechart extends PureComponent {
    
  state = {
    data: [],
    colors: []
  };

  componentDidMount() {

    if(this.props.data !== undefined) {
      this.setState({
        data: this.props.data
      })
    } else {
      this.setState({
        data: data_default
      })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if(this.props.data !== undefined) {
      this.setState({
        data: this.props.data
      })
    } else {
      this.setState({
        data: data_default
      })
    }
  }

  render() {
    const {data} = this.state;
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          width={500}
          height={500}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 20,
            bottom: 0,
          }}
          barSize={100}
        >
          {/* <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
          <YAxis /> */}
          <XAxis type="number" hide />
           <YAxis yAxisId={0} stroke="white" type="category" width={400} padding={{ left: 10 }} dataKey="name"/>
           <YAxis orientation="right" yAxisId={1} stroke="white" type="category" width={50} dataKey="value"/>
          <Tooltip />
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <Bar 
            dataKey="value" 
            fill="#1ebcc7" 
            background={{ fill: '#535454' }} 
            // label={<CustomizedLabel fill="white" />}
        />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
