import React, { Fragment, Component } from "react";

import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";

import MapBottom from "components/shared/map/MapBottom";

import { VictoryChart, VictoryBar, VictoryStack, VictoryGroup, VictoryAxis, VictoryLegend, VictoryPie, VictoryTooltip } from "victory";

import { PieChart, Pie, Legend, Tooltip, Cell } from "recharts";
import PieRechart from "components/shared/charts/PieRechart";

import HorizontalSupporterDark from "components/shared/charts/HorizontalSupporterDark";
import BarChartHRechart from "components/shared/charts/BarChartHRechart";

import { getTurnout } from "actions/mapActions";

import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import "styles/components/map-submenu.css";
import MapTurnout from "components/shared/map/MapTurnout";


const API_HOST = process.env.REACT_APP_API_HOST;

const data01 = [
  { name: "Yes", value: 42 },
  { name: "No", value: 38 },
  { name: "Don't Know", value: 19 }
];

const data11 = [
  { name: "State-owned enterprises should be expanded", value: 65 },
  { name: "There should be fewer state-owned enterprises", value: 8 },
  { name: "Don't Know", value: 17 },
  { name: "State-owned enterprises should not be expanded or reduced ", value: 11 },
];

const data21 = [
  { name: "Very important", value: 62 },
  { name: "Somewhat important", value: 22 },
  { name: "Not very or not at all important", value: 3 },
  { name: "Don’t know", value: 13 },
];

const data22 = [
  { name: "Major problem", value: 87 },
  { name: "Minor problem", value: 6 },
  { name: "Not a problem at all", value: 2 },
  { name: "Don't Know ", value: 5 },
];

const COLORS = [
  "#0088FE",
  "#ff2b2b",
  "#bdbdbd",
  "#ed80a1",
  "#f123ff",
  "#a1bb23"
];


class ScoreAnalysis extends Component {
  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "Wave 5 (jan 2022)",
        map_type: "horserace",
        data: [
          { id: 1, name: "Wave 5 (jan 2022)" },
          { id: 2, name: "Wave 4 (jul 2021)" },
          { id: 3, name: "Wave 3 (jun 2021)" },
          { id: 4, name: "Wave 2 (mar 2021)" },
          { id: 5, name: "Wave 1 (nov 2020)" },
        ]
      }
    },
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "Wave 5 (jan 2022)" },
            { id: 2, name: "Wave 4 (jul 2021)" },
            { id: 3, name: "Wave 3 (jun 2021)" },
            { id: 4, name: "Wave 2 (mar 2021)" },
            { id: 5, name: "Wave 1 (nov 2020)" },
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
  }

  render() {
    const { info, state, handleToggleSatellite } = this.props;
    const {
      openMap,
      isSubOpen,
      openPOI,
      isPOISubOpen,
      region,
      turnout
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition,
      handleGetSubMenu,
      handleChangeMethod
    } = this;

    let dataSource;
    let survey_2_data = {
      max_value: 100,
      total_value: 100,
      data: [
        {
          order_id: 1,
          order_name: "Total Try",
          left_label: "Probably Try",
          right_label: "Definitely Try",
          left_data: 33,
          right_data: 29
        },
        {
          order_id: 2,
          order_name: "Total Not Try",
          left_label: "Probably Not Try",
          right_label: "Definitely Not Try",
          left_data: 9,
          right_data: 7
        },
        {
          order_id: 3,
          order_name: "Total Unsure",
          left_label: "Don't Know",
          right_label: "Might or might not",
          left_data: 6,
          right_data: 16
        },
      ]
  }

let horizontalChartData = {
  wave1:[
    {
      data: [
        { percentage: 12, color: "rgba(39, 204, 149, .8)" },
        { percentage: 66, color: "rgba(60, 210, 240, .8)" },
        { percentage: 17, color: "rgba(240, 66, 60, .8)" },
        { percentage: 2, color: "rgba(148, 5, 0, .8)" },
        { percentage: 3, color: "rgba(184, 180, 180, .8)" }
      ],
      type: "job_approval",
      title: "Joko Widodo - President"
    },
    {
      data: [
        { percentage: 7, color: "rgba(39, 204, 149, .8)" },
        { percentage: 63, color: "rgba(60, 210, 240, .8)" },
        { percentage: 25, color: "rgba(240, 66, 60, .8)" },
        { percentage: 2, color: "rgba(148, 5, 0, .8)" },
        { percentage: 3, color: "rgba(184, 180, 180, .8)" }
      ],
      type: "job_approval",
      title: "Joko Widodo - Economy"
    },
    {
      data: [
        { percentage: 8, color: "rgba(39, 204, 149, .8)" },
        { percentage: 60, color: "rgba(60, 210, 240, .8)" },
        { percentage: 26, color: "rgba(240, 66, 60, .8)" },
        { percentage: 3, color: "rgba(148, 5, 0, .8)" },
        { percentage: 3, color: "rgba(184, 180, 180, .8)" }
      ],
      type: "job_approval",
      title: "Joko Widodo - COVID"
    },
    {
      data: [
        { percentage: 8, color: "rgba(39, 204, 149, .8)" },
        { percentage: 60, color: "rgba(60, 210, 240, .8)" },
        { percentage: 24, color: "rgba(240, 66, 60, .8)" },
        { percentage: 3, color: "rgba(148, 5, 0, .8)" },
        { percentage: 3, color: "rgba(184, 180, 180, .8)" }
      ],
      type: "job_approval",
      title: "Government"
    },
    {
      data: [
        { percentage: 6, color: "rgba(39, 204, 149, .8)" },
        { percentage: 59, color: "rgba(60, 210, 240, .8)" },
        { percentage: 29, color: "rgba(240, 66, 60, .8)" },
        { percentage: 2, color: "rgba(148, 5, 0, .8)" },
        { percentage: 3, color: "rgba(184, 180, 180, .8)" }
      ],
      type: "job_approval",
      title: "Government - Economy"
    },
    {
      data: [
        { percentage: 7, color: "rgba(39, 204, 149, .8)" },
        { percentage: 56, color: "rgba(60, 210, 240, .8)" },
        { percentage: 31, color: "rgba(240, 66, 60, .8)" },
        { percentage: 3, color: "rgba(148, 5, 0, .8)" },
        { percentage: 3, color: "rgba(184, 180, 180, .8)" }
      ],
      type: "job_approval",
      title: "Government - COVID"
    },
    {
      data: [
        { percentage: 7, color: "rgba(39, 204, 149, .8)" },
        { percentage: 68, color: "rgba(60, 210, 240, .8)" },
        { percentage: 12, color: "rgba(240, 66, 60, .8)" },
        { percentage: 1, color: "rgba(148, 5, 0, .8)" },
        { percentage: 12, color: "rgba(184, 180, 180, .8)" }
      ],
      type: "job_approval",
      title: "Erick Thohir - COVID"
    },
    {
      data: [
        { percentage: 9, color: "rgba(39, 204, 149, .8)" },
        { percentage: 65, color: "rgba(60, 210, 240, .8)" },
        { percentage: 12, color: "rgba(240, 66, 60, .8)" },
        { percentage: 1, color: "rgba(148, 5, 0, .8)" },
        { percentage: 13, color: "rgba(184, 180, 180, .8)" }
      ],
      type: "job_approval",
      title: "Erick Thohir - SOE"
    }],
    wave4:[
      {
        data: [
          { percentage: 13, color: "rgba(39, 204, 149, .8)" },
          { percentage: 31, color: "rgba(60, 210, 240, .8)" },
          { percentage: 29, color: "rgba(183, 230, 252, .8)" },
          { percentage: 18, color: "rgba(247, 255, 255, .8)" },
          { percentage: 2, color: "rgba(240, 66, 60, .8)" },
          { percentage: 0, color: "rgba(148, 5, 0, .8)" },
          { percentage: 7, color: "rgba(184, 180, 180, .8)" }
        ],
        type: "job_approval",
        title: "Ganjar Pranowo"
      },
      {
        data: [
          { percentage: 7, color: "rgba(39, 204, 149, .8)" },
          { percentage: 26, color: "rgba(60, 210, 240, .8)" },
          { percentage: 35, color: "rgba(183, 230, 252, .8)" },
          { percentage: 23, color: "rgba(247, 255, 255, .8)" },
          { percentage: 3, color: "rgba(240, 66, 60, .8)" },
          { percentage: 0, color: "rgba(148, 5, 0, .8)" },
          { percentage: 6, color: "rgba(184, 180, 180, .8)" }
        ],
        type: "job_approval",
        title: "Ridwan Kamil"
      },
      {
        data: [
          { percentage: 7, color: "rgba(39, 204, 149, .8)" },
          { percentage: 29, color: "rgba(60, 210, 240, .8)" },
          { percentage: 27, color: "rgba(183, 230, 252, .8)" },
          { percentage: 21, color: "rgba(247, 255, 255, .8)" },
          { percentage: 4, color: "rgba(240, 66, 60, .8)" },
          { percentage: 1, color: "rgba(148, 5, 0, .8)" },
          { percentage: 10, color: "rgba(184, 180, 180, .8)" }
        ],
        type: "job_approval",
        title: "Erick Thohir"
      },
      {
        data: [
          { percentage: 7, color: "rgba(39, 204, 149, .8)" },
          { percentage: 23, color: "rgba(60, 210, 240, .8)" },
          { percentage: 28, color: "rgba(183, 230, 252, .8)" },
          { percentage: 21, color: "rgba(247, 255, 255, .8)" },
          { percentage: 11, color: "rgba(240, 66, 60, .8)" },
          { percentage: 2, color: "rgba(148, 5, 0, .8)" },
          { percentage: 7, color: "rgba(184, 180, 180, .8)" }
        ],
        type: "job_approval",
        title: "Anies Baswedan"
      },
      {
        data: [
          { percentage: 6, color: "rgba(39, 204, 149, .8)" },
          { percentage: 20, color: "rgba(60, 210, 240, .8)" },
          { percentage: 31, color: "rgba(183, 230, 252, .8)" },
          { percentage: 25, color: "rgba(247, 255, 255, .8)" },
          { percentage: 7, color: "rgba(240, 66, 60, .8)" },
          { percentage: 1, color: "rgba(148, 5, 0, .8)" },
          { percentage: 9, color: "rgba(184, 180, 180, .8)" }
        ],
        type: "job_approval",
        title: "Sandiaga Uno"
      },
      {
        data: [
          { percentage: 7, color: "rgba(39, 204, 149, .8)" },
          { percentage: 22, color: "rgba(60, 210, 240, .8)" },
          { percentage: 28, color: "rgba(183, 230, 252, .8)" },
          { percentage: 24, color: "rgba(247, 255, 255, .8)" },
          { percentage: 8, color: "rgba(240, 66, 60, .8)" },
          { percentage: 2, color: "rgba(148, 5, 0, .8)" },
          { percentage: 9, color: "rgba(184, 180, 180, .8)" }
        ],
        type: "job_approval",
        title: "Prabowo Subianto"
      },
      {
        data: [
          { percentage: 3, color: "rgba(39, 204, 149, .8)" },
          { percentage: 12, color: "rgba(60, 210, 240, .8)" },
          { percentage: 22, color: "rgba(183, 230, 252, .8)" },
          { percentage: 35, color: "rgba(247, 255, 255, .8)" },
          { percentage: 9, color: "rgba(240, 66, 60, .8)" },
          { percentage: 1, color: "rgba(148, 5, 0, .8)" },
          { percentage: 17, color: "rgba(184, 180, 180, .8)" }
        ],
        type: "job_approval",
        title: "AHY"
      },
      {
        data: [
          { percentage: 1, color: "rgba(39, 204, 149, .8)" },
          { percentage: 8, color: "rgba(60, 210, 240, .8)" },
          { percentage: 16, color: "rgba(183, 230, 252, .8)" },
          { percentage: 30, color: "rgba(247, 255, 255, .8)" },
          { percentage: 16, color: "rgba(240, 66, 60, .8)" },
          { percentage: 6, color: "rgba(148, 5, 0, .8)" },
          { percentage: 22, color: "rgba(184, 180, 180, .8)" }
        ],
        type: "job_approval",
        title: "Puan Maharani"
      }],
    wave5:[
      {
        data: [
          { percentage: 25, color: "rgba(39, 204, 149, .8)" },
          { percentage: 66, color: "rgba(60, 210, 240, .8)" },
          { percentage: 9, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Ganjar Pranowo"
      },
      {
        data: [
          { percentage: 36, color: "rgba(39, 204, 149, .8)" },
          { percentage: 46, color: "rgba(60, 210, 240, .8)" },
          { percentage: 18, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Joko Widodo"
      },
      {
        data: [
          { percentage: 20, color: "rgba(39, 204, 149, .8)" },
          { percentage: 64, color: "rgba(60, 210, 240, .8)" },
          { percentage: 16, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Ridwan Kamil"
      },
      {
        data: [
          { percentage: 12, color: "rgba(39, 204, 149, .8)" },
          { percentage: 72, color: "rgba(60, 210, 240, .8)" },
          { percentage: 16, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Erick Thohir"
      },
      {
        data: [
          { percentage: 11, color: "rgba(39, 204, 149, .8)" },
          { percentage: 63, color: "rgba(60, 210, 240, .8)" },
          { percentage: 25, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Sandiaga Uno"
      },
      {
        data: [
          { percentage: 14, color: "rgba(39, 204, 149, .8)" },
          { percentage: 54, color: "rgba(60, 210, 240, .8)" },
          { percentage: 32, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Prabowo Subianto"
      },
      {
        data: [
          { percentage: 13, color: "rgba(39, 204, 149, .8)" },
          { percentage: 56, color: "rgba(60, 210, 240, .8)" },
          { percentage: 31, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Anies Baswedan"
      },
      {
        data: [
          { percentage: 4, color: "rgba(39, 204, 149, .8)" },
          { percentage: 47, color: "rgba(60, 210, 240, .8)" },
          { percentage: 49, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "AHY"
      },
      {
        data: [
          { percentage: 4, color: "rgba(39, 204, 149, .8)" },
          { percentage: 52, color: "rgba(60, 210, 240, .8)" },
          { percentage: 44, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Budi Gunadi S."
      },
      {
        data: [
          { percentage: 5, color: "rgba(39, 204, 149, .8)" },
          { percentage: 44, color: "rgba(60, 210, 240, .8)" },
          { percentage: 51, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Luhut Binsar P."
      },
      {
        data: [
          { percentage: 2, color: "rgba(39, 204, 149, .8)" },
          { percentage: 30, color: "rgba(60, 210, 240, .8)" },
          { percentage: 68, color: "rgba(240, 66, 60, .8)" },
        ],
        type: "job_approval",
        title: "Puan Maharani"
      }]
}

const RADIAN = Math.PI / 180;

let colors01 =  [
  "#0088FE",
  "#ff2b2b",
  "#bdbdbd"
];

let colors11 =  [
  "#0088FE",
  "#ff2b2b",
  "#a39d9d",
  "#e8e8e8"
];

let colors21 =  [
  "#352afa",
  "#0088FE",
  "#bdbdbd",
  "#e8e8e8"
];

let colors22 =  [
  "#ff2b2b",
  "#bdbdbd",
  "#d9d7fa",
  "#e8e8e8"
];

let pie_chart_top_01 = <PieRechart data={data01} colors={colors01}/>;
let pie_chart_top_11 = <PieRechart data={data11} colors={colors11}/>;
let pie_chart_bottom_21 = <PieRechart data={data21} colors={colors21}/>;
let pie_chart_bottom_22 = <PieRechart data={data22} colors={colors22}/>;
let bar_stacked_chart;
let chart_title_left_top = 'HOW FAVORABLE ARE YOU TOWARD EACH FOLLOWING?';

let chart_label_left = <div className="row" style={{padding: '5px', marginBottom:'10px'}}>
<div className="col-md-12">
  <div className="scoring-legend">
    <span style={{color: 'white', fontSize: '0.6em'}}>
      <span className="legend-box supporter-a" /> Strongly approve
    </span>
    <span style={{color: 'white', fontSize: '0.6em'}}>
      <span className="legend-box supporter-b" /> Somewhat approve{" "}
    </span>
    <span style={{color: 'white', fontSize: '0.6em'}}>
      <span className="legend-box supporter-c" /> Somewhat disapprove
    </span>
    <span style={{color: 'white', fontSize: '0.6em'}}>
      <span className="legend-box supporter-d" /> Strongly disapprove
    </span>
    <span style={{color: 'white', fontSize: '0.6em'}}>
      <span className="legend-box supporter-e" /> Don't know
    </span>
  </div>
</div>
</div>;

bar_stacked_chart = <HorizontalSupporterDark data={horizontalChartData.wave1} />;

let right_info = <Fragment>
  <div className="row">
    <h5 className="col-lg-12" 
      style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-15px'}}>
      GOVERNMENT PERFORMANCE
    </h5>
  </div>
  <div className="row">
    <div className="col-lg-12">
      <p style={{display: 'flex', fontSize:'0.8em',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '55px', marginBottom:'20px'}}>
      Average Score on a Scale from 1 to 9
      </p>
    </div>
  </div>
  <div className="row" style={{height: "100%"}}>
    <div className="col-lg-12">
      <BarChartHRechart />
    </div>
  </div>
  <div className="row">
    <div className="col-lg-12">
      <p style={{display: 'flex', fontSize:'0.8em', align: 'center',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '40px', marginBottom:'10px'}}>
      On a scale from 1 to 9 where 1 is poor, 9 is perfect and 5 is Average
      <br/>
      how good a job is the government doing managing each of the following?
      </p>
    </div>
  </div>
</Fragment>


let data_chart_right ;
switch (turnout.method.value) {
  case "Wave 5 (jan 2022)":
    chart_title_left_top = 'Do you approve or disapprove of the work this person is doing in their current position?';

    chart_label_left = <div className="row" style={{padding: '5px', marginBottom:'10px'}}>
    <div className="col-md-12">
      <div className="scoring-legend">
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-a" /> Strongly approve
        </span>
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-b" /> Approve
        </span>
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-c" /> Disapprove
        </span>
      </div>
    </div>
    </div>;

    bar_stacked_chart = <HorizontalSupporterDark data={horizontalChartData.wave5} />;

    const data_pie_1_raw = [
      { x: 1, y: 36, label: "Joko Widodo" },
      { x: 2, y: 25, label: "Ganjar Pranowo" },
      { x: 3, y: 8, label: "Sandiaga Uno" },
      { x: 4, y: 16, label: "Ridwan Kamil" },
      { x: 5, y: 11, label: "Erick Thohir" },
      { x: 6, y: 10, label: "Prabowo Subianto" },
      { x: 7, y: 10, label: "Anies Baswedan" }
    ]

    let pie_chart_overview = <Fragment>  
      <VictoryPie
          colorScale={"qualitative"}
          data={data_pie_1_raw}
          height={220}
          style={{
            data: {
              fillOpacity: 0.9, stroke: "white", strokeWidth: 2
            },
            labels: {
              fontSize: 8, fill: "white"
            }
          }}
        />
    </Fragment>

    right_info = <Fragment>
      <div className="row">
        <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-15px'}}>
          How favorable are you toward each of the following?
        </h5>
      </div>
      <div className="row">
        {pie_chart_overview}
      </div>
    </Fragment>

    break;
  case "Wave 4 (jul 2021)":

    chart_title_left_top = 'How good a job do you think each of these people would do as President managing the COVID crisis?';

    chart_label_left = <div className="row" style={{padding: '5px', marginBottom:'10px'}}>
    <div className="col-md-12">
      <div className="scoring-legend">
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-a" /> Excellent
        </span>
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-b" /> Very good
        </span>
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-f" /> Good
        </span>
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-g" /> Okay
        </span>
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-c" /> Not very good
        </span>
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-d" /> Terrible
        </span>
        <span style={{color: 'white', fontSize: '0.6em'}}>
          <span className="legend-box supporter-e" /> Don't know
        </span>
      </div>
    </div>
    </div>;

    bar_stacked_chart = <HorizontalSupporterDark data={horizontalChartData.wave4} />;

    data_chart_right = [
      {
        "name": "Create Jobs",
        "value": 73
      },
      {
        "name": "Put the economy work again",
        "value": 71
      },
      {
        "name": "Fight corruption",
        "value": 68
      },
      {
        "name": "Solve COVID crisis",
        "value": 68
      },
      {
        "name": "Improve public health care",
        "value": 67
      },
      {
        "name": "Increase wages",
        "value": 66
      },
      {
        "name": "Improve public education",
        "value": 63
      },
      {
        "name": "Invest more in Infra.",
        "value": 47
      },
      {
        "name": "All vaccinated",
        "value": 45
      }
    ];
    
    right_info = <Fragment>
      <div className="row">
        <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-15px'}}>
          Most Important Presidential Priorities
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p style={{display: 'flex', fontSize:'0.8em',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '55px', marginBottom:'20px'}}>
          Jobs, Economy, Corruption, COVID
          </p>
        </div>
      </div>
      <div className="row" style={{height: "100%"}}>
        <div className="col-lg-12">
          <BarChartHRechart data={data_chart_right} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p style={{display: 'flex', fontSize:'0.8em', align: 'center',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '40px', marginBottom:'10px'}}>
          Percent Saying ‘Extremely Important’
          <br/>
          How important is it to you that the next president of Indonesia make this a top priority?
          </p>
        </div>
      </div>
    </Fragment>
    break;

  case "Wave 3 (jun 2021)":

    data_chart_right = [
      {
        "name": "Coronavirus Response",
        "value": 5.5
      },
      {
        "name": "Managing state-owned enterprises",
        "value": 5.3
      },
      {
        "name": "Economy and Jobs",
        "value": 5.2
      },
      {
        "name": "Fighting Corruption",
        "value": 4.9
      }
    ];
    
    right_info = <Fragment>
      <div className="row">
        <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-15px'}}>
          GOVERNMENT PERFORMANCE
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p style={{display: 'flex', fontSize:'0.8em',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '55px', marginBottom:'20px'}}>
          Government performance has declined on all measures - especially COVID response.
          </p>
        </div>
      </div>
      <div className="row" style={{height: "100%"}}>
        <div className="col-lg-12">
          <BarChartHRechart data={data_chart_right} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p style={{display: 'flex', fontSize:'0.8em', align: 'center',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '40px', marginBottom:'10px'}}>
          On a scale from 1 to 9 where 1 is poor, 9 is perfect and 5 is Average
          <br/>
          how good a job is the government doing managing each of the following?
          </p>
        </div>
      </div>
    </Fragment>
    break;
  case "Wave 2 (mar 2021)":
    right_info = <Fragment>
      <div className="row">
        <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-15px'}}>
          GOVERNMENT PERFORMANCE
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p style={{display: 'flex', fontSize:'0.8em',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '55px', marginBottom:'20px'}}>
          Average Score on a Scale from 1 to 9
          </p>
        </div>
      </div>
      <div className="row" style={{height: "100%"}}>
        <div className="col-lg-12">
          <BarChartHRechart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p style={{display: 'flex', fontSize:'0.8em', align: 'center',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '40px', marginBottom:'10px'}}>
          On a scale from 1 to 9 where 1 is poor, 9 is perfect and 5 is Average
          <br/>
          how good a job is the government doing managing each of the following?
          </p>
        </div>
      </div>
    </Fragment>
    break;
  default:
    right_info = <Fragment>
      <div className="row">
        <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-15px'}}>
          GOVERNMENT PERFORMANCE
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p style={{display: 'flex', fontSize:'0.8em',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '55px', marginBottom:'20px'}}>
          Average Score on a Scale from 1 to 9
          </p>
        </div>
      </div>
      <div className="row" style={{height: "100%"}}>
        <div className="col-lg-12">
          <BarChartHRechart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p style={{display: 'flex', fontSize:'0.8em', align: 'center',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '40px', marginBottom:'10px'}}>
          On a scale from 1 to 9 where 1 is poor, 9 is perfect and 5 is Average
          <br/>
          how good a job is the government doing managing each of the following?
          </p>
        </div>
      </div>
    </Fragment>
    break;

}

    return (
      <React.Fragment>
        <div className="full-width" style={{background: '#212529'}}>
            <div className="col-lg-12">
              <div className="sidebar sidebar-left">
                <div className="sidebar-content">
                  <nav className="main-menu">
                    <ul className="nav">
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="icon dripicons-graph-bar" />
                        </a>
                        <SubmenuTurnout
                          handleToggleInfo={handleToggleInfo}
                          handleGetSubMenu={handleGetSubMenu}
                          handleToggleSubMenu={handleTogglePOISubMenu}
                          handleCloseSubmenu={handleCloseSubmenu}
                          handleChangeMethod={handleChangeMethod}
                          placeholder="Select Scoring"
                          data={{
                            info,
                            openPOI,
                            isPOISubOpen,
                            handleSubMenuChange,
                            turnout
                          }}
                        />
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
        </div>
        <div className="row mapHeightHalf" style={{borderBottom: '3px solid #932427', background: '#212529'}}>
          <div className="row col-lg-12" style={{height: '40%'}}>
            <div className="col-lg-6" style={{height: "100%", paddingRight: '50px', paddingLeft: '30px', marginTop: "80px", marginBottom: "20px" }}>            
              <h5 className="col-lg-12" 
                style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'25px'}}>
                {chart_title_left_top}
              </h5>
              {chart_label_left}
              <div>
                {bar_stacked_chart}
              </div>
            </div>
            <div className="col-lg-6" style={{height: "100%",  marginTop: "80px", marginBottom: "20px" }}> 
              {right_info}
            </div>
          </div>
          
          {/* <div className="row col-lg-12" style={{height: '40%'}}>
            <div className="col-lg-6" style={{ height: "100%", marginTop: "80px", marginBottom: "20px", paddingRight: '50px' }}>  
              <div className="col-lg-12" style={{marginBottom: '20px'}}>
                <div className="row">
                </div>
              </div>
            </div>
            <div className="col-lg-6" style={{ height: "100%", marginTop: "80px", marginBottom: "20px" }}> 
              <div className="row">
                <div className="col-lg-6">
                  <p style={{display: 'flex', fontSize:'0.6em',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '30px', marginBottom:'-10px'}}>
                  How important do you think it is to make state-owned enterprises run more efficiently and more effectively with better management practices and smaller executive teams?
                  </p>
                </div>
                <div className="col-lg-6">
                  <p f style={{display: 'flex', fontSize:'0.5em', justifyContent:'center', alignItems:'center', color:'white', marginTop: '30px', marginBottom:'-10px'}}>
                  Is corruption at state-owned enterprises (SOEs) in Indonesia a major problem, a minor problem, or not a problem at all?
                  </p>
                </div>
              </div>
              <div className="row" style={{height: "100%"}}>
                  <div className="col-lg-6">
                    {pie_chart_bottom_21}
                  </div>
                  <div className="col-lg-6">
                    {pie_chart_bottom_22}
                  </div>
                </div>
            </div>
          </div> */}
        </div>
        <div className="map50-bottom-soe">
          <MapBottom source="turnout" type="job_approval" handleBorderMapClick={()=>{}} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScoreAnalysis);
