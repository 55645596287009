import React, { PureComponent } from "react";
import { VictoryChart, VictoryBar, VictoryStack } from "victory";
import CustomLabel from "./CustomLabel";
import { Dark } from "./themeVictory";

export default class Bar100Horizontal extends PureComponent {
  render() {
    return (
      <VictoryChart
        theme={Dark}
        height={200}
        width={450}
        padding={{ left: 70, top: 0, right: 10, bottom: 50 }}
        domainPadding={{ x: 20, y: 30 }}
      >
        <VictoryStack horizontal>
          <VictoryBar
            barWidth={20}
            data={this.props.pendidikan.data.map(d => { 
              const total_data = d.total_data === undefined ? d.total_value : d.total_data
              return ({
                x: d.order_name,
                y: total_data ,
                label: `${d.order_name.toUpperCase()} \n ${total_data.toLocaleString()} Jiwa`
            })})}
            style={{
              data: {
                fill: ({ x }) =>
                  x === "sd"
                    ? "#B2292A"
                    : x === "smp"
                    ? "#3E639C"
                    : x === "sma"
                    ? "#94BDD0"
                    : "#777"
              },
              labels: {
                fill: "#121212",
                fontSize: 22,
                fontWeight: 500,
              }
            }}
            labelComponent={<CustomLabel dx={-20} dy={20} />}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => ({
                          style: { fill: "#00de78" }
                        })
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: true })
                      }
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => {}
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: false })
                      }
                    ];
                  }
                }
              }
            ]}
          />
        </VictoryStack>
      </VictoryChart>
    );
  }
}
