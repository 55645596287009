import toastr from "toastr";
import { GET, GET_NOBEARER } from "API";
import { reAuth } from "./authActions";
const API_HOST = process.env.REACT_APP_API_HOST,
  API_DIR = process.env.REACT_APP_API_DIR,
  API_TOPIC = process.env.REACT_APP_API_TOPIC;

// Impure Functions to do api actions
// HRC API
export function getHRC(mode, type, id_loc = "") {
  return async dispatch => {
    try {
      const response = await GET(
        `${API_HOST}/${API_DIR}/horse/${mode}/${type}`,
        { id_loc }
      );
      dispatch(setHRC(response.data.data));
    } catch (err) {
      err.response.status === 401
      ? dispatch(reAuth())
      : toastr.error(err.response.statusText);
    }
  };
}

export function getSocialMediaPost(type, path, params, page, limit) {
  let parameters;
  switch (type) {
    case "pie":
      parameters = `candidate=${params.candidate}&sentimen_type=${
        params.sentiment_type
      }`;
      break;
    case "map":
      parameters = `candidate=${params.candidate}&location_code=${
        params.location_code
      }`;
      break;
    case "hashtag":
      parameters = `keyword=${params.keyword}`;
      break;
    default:
      break;
  }

  return async dispatch => {
    try {
      await dispatch(fetchingChart());
      const response = await GET(
        `${API_HOST}/${API_DIR}/${path}?${parameters}&page=${page}&limit=${limit}`
      );
      dispatch(setSocialMediaPost(response.data.data));
    } catch (err) {
      err.response.status === 401
      ? dispatch(reAuth())
      : toastr.error(err.response.statusText);
    }
  };
}

// Pure functions to change reducer state
function fetchingChart() {
  return {
    type: "FETCHING"
  };
}

export function setHRC(payload) {
  return {
    type: "SET_HRC",
    payload
  };
}

export function setWordCloud(payload) {
  return {
    type: "SET_WORD_CLOUD",
    payload
  };
}

export function setSocialMediaPost(payload) {
  return {
    type: "SET_SOCIAL_MEDIA_POST",
    payload
  };
}
