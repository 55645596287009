import React, { Fragment, Component, useState } from "react";

import Sidebar from "components/layout/sidebar/Sidebar";
import Map from "components/shared/map/Map";
import ModalDialog from "components/shared/ModalDialog";
import { Dark } from "components/shared/charts/themeVictory";
import SubmenuRegion from "components/layout/sidebar/SubmenuRegion";
import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";
import CustomLabel from "components/shared/charts/CustomLabel";
import GroupedBarTurnout from "components/shared/charts/GroupedBarTurnout";

import MapBottom from "components/shared/map/MapBottom";

import {Sigma, RandomizeNodePositions, RelativeSize, EdgeShapes, NodeShapes, LoadJSON, ForceAtlas2, NOverlap} from 'react-sigma';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {CustomInput, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup, ButtonToolbar, Label, Input } from 'reactstrap';
// import { Timeline, Tweet } from 'react-twitter-widgets'
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import BarChartHRechart from "components/shared/charts/BarChartHRechart";

import { setModalTopic } from "actions";
import "styles/components/whatson_et.css";

import { getTurnout } from "actions/mapActions";

import { VictoryChart, VictoryBar, VictoryGroup, VictoryLabel, VictoryAxis } from "victory";

import {
  node_sna_1,
  node_sna_7,
  node_sna_14,
  node_sna_30,
  data_influencer_1,
  data_influencer_7,
  data_influencer_14,
  data_influencer_30,
  popular_hashtags_1,
  popular_hashtags_7,
  popular_hashtags_14,
  popular_hashtags_30
} from "./SnaDataDummy"

import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap,
  getProvince
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import "styles/components/map-submenu.css";
import MapTurnout from "components/shared/map/MapTurnout";

import Select from 'react-select';
import Cookies from 'universal-cookie';

const cookies = new Cookies();


const API_HOST = process.env.REACT_APP_API_HOST;

const options = [
  {
    label: '24 Hours',
    value: 1
  },
  {
    label: '7 Days',
    value: 7
  },
  {
    label: '14 Days',
    value: 14
  },
  {
    label: '1 Month',
    value: 30
  }
];

// let data_influencer = data_influencer_1;

// let tweet_trend_text = data_influencer[0]['url_tweet'].split('/')
// let tweet_trend_text2 = data_influencer[1]['url_tweet'].split('/')

let popular_hashtags = popular_hashtags_1;

let node_path_sigma = `${process.env.PUBLIC_URL}/data/sna_data1.json`;

const dataSentimentTotal = [
  {
    "x": "Negative",
    "y": 15,
    "label": "15 %"
  },
  {
    "x": "Positive",
    "y": 65,
    "label": "65 %"
  },
  {
    "x": "Netral",
    "y": 20,
    "label": "20 %"
  }
];

const data_dropdown = [
  {
    timeNamed: '7 Days',
    days: 7
  },
  {
    timeNamed: '14 Days',
    days: 14
  },
  {
    timeNamed: '1 Month',
    days: 30
  },
  {
    timeNamed: '3 Months',
    days: 90
  }
]

class Sna extends Component {

  constructor(props) {
    super(props);

    cookies.set('sna_dd', '', { path: '/' });
  }

  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "News Media",
        map_type: "horserace",
        data: [
          { id: 1, name: "News Media" },
          { id: 2, name: "Twitter" },
          { id: 3, name: "Facebook" }
        ]
      }
    },
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null,

    dropdownOpen: false,

    openSubOne: {
      active: 1,
      opened: false
    },
    openSubTwo: {
      active: 1,
      opened: false
    },
    isLoadingOne: true,
    isLoadingTwo: true,

    selectedOption: null,
    dataSelected : {
      timeNamed: '7 Days',
      days: 7
    },

    data_influencer: data_influencer_1,
    tweet_trend_text: data_influencer_1[0]['url_tweet'].split('/'),
    tweet_trend_text2: data_influencer_1[1]['url_tweet'].split('/')
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  changesDataSelected = async (data) => {
    console.log("XXXX changes");
    console.log(data);

    await this.setState({
      ...this.state,
      dataSelected: data,
    });

    cookies.set('sna_dd', data, { path: '/' });
  }

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  toogleDropdown = () => {
    this.setState({
      ...this.state,
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  handleHashtagClick = text => {
    this.props.setModalTopic({
      type: "hashtag",
      // path: `whatson/wordcloud/data`,
      path: `whatson/${"sentimen"}/data`,
      params: { keyword: text }
    });
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    // console.log(`Option selected:`, selectedOption);

    switch(selectedOption.value) {
      case 1:
        // data_influencer = data_influencer_1;

        this.setState({
          ...this.state,
          data_influencer: data_influencer_1,
          tweet_trend_text: data_influencer_1[0]['url_tweet'].split('/'),
          tweet_trend_text2: data_influencer_1[1]['url_tweet'].split('/')
        });

        popular_hashtags = popular_hashtags_1;
        node_path_sigma = `${process.env.PUBLIC_URL}/data/sna_data1.json`;

        // tweet_trend_text = data_influencer[0]['url_tweet'].split('/')
        // tweet_trend_text2 = data_influencer[1]['url_tweet'].split('/')
        break;
      case 7:
        this.setState({
          ...this.state,
          data_influencer: data_influencer_7,
          tweet_trend_text: data_influencer_7[0]['url_tweet'].split('/'),
          tweet_trend_text2: data_influencer_7[1]['url_tweet'].split('/')
        });

        // data_influencer = data_influencer_7;
        popular_hashtags = popular_hashtags_7;
        node_path_sigma = `${process.env.PUBLIC_URL}/data/sna_data7.json`;

        // tweet_trend_text = data_influencer[0]['url_tweet'].split('/')
        // tweet_trend_text2 = data_influencer[1]['url_tweet'].split('/')
        break;
      case 14:
        this.setState({
          ...this.state,
          data_influencer: data_influencer_14,
          tweet_trend_text: data_influencer_14[0]['url_tweet'].split('/'),
          tweet_trend_text2: data_influencer_14[1]['url_tweet'].split('/')
        });
        // data_influencer = data_influencer_14;
        popular_hashtags = popular_hashtags_14;
        node_path_sigma = `${process.env.PUBLIC_URL}/data/sna_data14.json`;

        // tweet_trend_text = data_influencer[0]['url_tweet'].split('/')
        // tweet_trend_text2 = data_influencer[1]['url_tweet'].split('/')
        break;
      case 30:
        this.setState({
          ...this.state,
          data_influencer: data_influencer_30,
          tweet_trend_text: data_influencer_30[0]['url_tweet'].split('/'),
          tweet_trend_text2: data_influencer_30[1]['url_tweet'].split('/')
        });
        // data_influencer = data_influencer_30;
        popular_hashtags = popular_hashtags_30;
        node_path_sigma = `${process.env.PUBLIC_URL}/data/sna_data30.json`;

        // tweet_trend_text = data_influencer[0]['url_tweet'].split('/')
        // tweet_trend_text2 = data_influencer[1]['url_tweet'].split('/')
        break;   
    }
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "News Media" },
            { id: 2, name: "Twitter" },
            { id: 3, name: "Facebook" }
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
    // await GET(API_HOST + "/sental/master/pulau")
    //   .then(res =>
    //     this.setState({
    //       ...this.state,
    //       region: {
    //         ...this.state.region,
    //         pulau: { ...this.state.region.pulau, data: res.data },
    //         step: 1
    //       }
    //     })
    //   )
    //   .catch(err => {
    //     err.response.status === 401
    //       ? this.props.reAuth()
    //       : toastr.error(err.response.statusText);
    //   });

    let cookies_sna = cookies.get('sna_dd');
    // console.log(cookies_chosen);

    if(cookies_sna !== '' ) {
      // console.log("OKK")

      if(this.state.dataSelected.timeNamed != cookies_sna.timeNamed) {
        this.setState({
          ...this.state,
          dataSelected: cookies_sna
        });
      }
    }
  }

  render() {
    const { info, state, handleToggleSatellite } = this.props;
    const {
      openMap,
      isSubOpen,
      openPOI,
      isPOISubOpen,
      region,
      turnout,
      dropdownOpen,
      openSubOne,
      isLoadingOne,
      dataSelected,
      selectedOption,
      data_influencer,
      tweet_trend_text,
      tweet_trend_text2
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition,
      handleGetSubMenu,
      handleChangeMethod,
      handleHashtagClick,
      changesDataSelected
    } = this;

    let myGraph = {nodes:[{id:"n1", label:"Alice"}, {id:"n2", label:"Rabbit"}], edges:[{id:"e1",source:"n1",target:"n2",label:"SEES"}]};

    // let sentiment_component = data_sentiment_1.map((el) => {
    //   return <div className="col-lg-6">
    //     <ul style={{listStyleType: 'none'}}>
    //       <li>
    //       <img
    //           src={`${process.env.PUBLIC_URL}/img/icon/twiiter_logo_2.png `}
    //           onClick={() => {}}
    //           style={{position:'absolute', left: 0, top: 0, width: '40px' }}
    //           alt=""
    //         />
    //         <div style={{padding: '1px 0 1px 5px'}}>
    //           <a href={el.url} target="_blank" style={{fontSize:'0.7rem', paddingRight: '10px'}} >{el.title} : {el.count}  </a>
    //         </div>
    //       </li>
    //     </ul>
    //     </div>
    // });

    let top_influencer = data_influencer.map((el) => { 
      return <div className="col-lg-3">
      <div className="centerContent">
        <div className="selfCenter" style={{height: 100, marginRight: 5}}>
          <TwitterTimelineEmbed 
            sourceType="profile" 
            screenName={el.username.substring(1)} 
            // theme="dark"
            noHeader
            noFooter 
            autoHeight />
        </div>
        {/* <div className="selfCenter">
          <TwitterTweetEmbed style={{width: '100%'}} tweetId="1423664114178695179" options={{
              cards: 'hidden',
            }} onLoad={()=>{
              //bla bla after load
            }} />
        </div> */}
      </div>
      {/* <Tweet tweetId="1423664114178695179" options={{ width: "200" }} /> */}
    </div>
    });

    // let tweet_trend_text = data_influencer[0]['url_tweet'].split('/')
    // let tweet_trend_text2 = data_influencer[1]['url_tweet'].split('/')
    // let tweet_trend = ;

    return (
      <React.Fragment>
        <div className="row mapHeight" style={{borderBottom: '3px solid #932427', background: '#212529'}}>
          <div className="col-lg-7">
            <div className="row">
              <h2 style={{ marginLeft: "40px", marginTop:"25px",marginBottom:"2px", fontWeight: 'bolder',
                  fontSize:'2.1em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Social Network Analysis</h2>
            </div>
            {/* <div className="row">
              <h2 style={{ marginLeft: "40px", marginTop:"2px",marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.8em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Topic Related to Erick Thohir</h2>
            </div> */}
            
            <div className="row align-items-center" style={{paddingLeft: '22px', width: '60%'}}>
              {/* <Dropdown className="dropdown" isOpen={dropdownOpen} toggle={this.toogleDropdown}>
                    <DropdownToggle caret>
                      Filters : <strong>[Last 1 Month]</strong>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem style={{color:'white'}} className="dropdown-item">7 Days</DropdownItem>
                      <DropdownItem style={{color:'white'}} className="dropdown-item">14 Days</DropdownItem>
                      <DropdownItem style={{color:'white'}} className="dropdown-item">1 Month</DropdownItem>
                      <DropdownItem style={{color:'white'}} className="dropdown-item">3 Months</DropdownItem>
                    </DropdownMenu>
                </Dropdown> */}
                {/* <Dropdown className="dropdown" isOpen={dropdownOpen} toggle={this.toogleDropdown}>
                  <DropdownToggle>
                    Filters : <strong>[{dataSelected.timeNamed}]</strong>
                  </DropdownToggle>
                  <DropdownMenu>
                    {data_dropdown.map((data, index) => (
                      <DropdownItem key={index} style={{color:'white'}} className="dropdown-item" onClick={()=>{changesDataSelected(data)}} value={data} >
                        <div>{data.timeNamed}</div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown> */}
                <div className="col-lg-3 text-right" style={{color: 'white', fontSize: '1.1rem'}}>
                  Filters : 
                </div>
                <div className="col-lg-6">
                  <Select
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={options}
                    />
                </div>
                
                 
              </div>

              <div className="row" style={{height: '65%'}}>
                <div className="col-lg-12">
                <Sigma
              renderer="canvas"
              // graph={myGraph}
              settings={{
                borderSize: 2,
                batchEdgesDrawing: true,
                drawEdgeLabels: false,
                defaultLabelSize: 8,
                labelThreshold: 2,
                drawLabels: true,
                // labelSize: 'fixed',
                defaultLabelHoverColor: '#36362d',
                labelHoverShadowColor: '#FFF',
                defaultNodeHoverColor: '#FFF',
                defaultHoverLabelBGColor: '#ddd',
                defaultLabelColor: '#FFF',
                defaultEdgeColor: "#FFF",
                defaultNodeColor: '#FFF',
                edgeHoverSizeRatio: 2,
                enableEdgeHovering: true,
                singleHover: true,
                autoRescale: true
              }}
              style={{
                height: '100%',
                width: '98%'
              }}
            >
              <EdgeShapes default="tapered" />
              <NodeShapes default="star" />
              <LoadJSON path={node_path_sigma}>
                <RandomizeNodePositions>
                  <ForceAtlas2
                    iterationsPerRender={1}
                    linLogMode
                    timeout={2000}
                    worker
                  />
                  <RelativeSize initialSize={1} />
                  {/* <NOverlap
                    duration={3000}
                    easing="quadraticInOut"
                    gridSize={20}
                    maxIterations={100}
                    nodeMargin={10}
                    scaleNodes={2}
                    speed={10}
                  /> */}
                </RandomizeNodePositions>
                {/* <RelativeSize initialSize={20} /> */}
              </LoadJSON>
            </Sigma>
                </div>
              </div>
            
            <div className="row">
              <h3 style={{ background: '#a61c1d', padding: '10px', marginLeft: "30px", marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.5em',  justifyContent:'center', alignItems:'center', color:'white'  }}>HASHTAGS | Most Like | Retweet | Reply</h3>
            </div>

            <div className="row" style={{width:'100%', height: '15%', paddingRight:'150px', marginBottom: '100px'}}>
              <BarChartHRechart data={popular_hashtags} />    
            </div>
            
          </div>      
          <div className="col-lg-5" >
            <div className="row">
              <h2 style={{ marginLeft: "20px", marginTop:"25px",marginBottom:"2px", fontWeight: 'bolder',
                  fontSize:'2.1em',  justifyContent:'center', alignItems:'center', color:'white'  }}>TOP INFLUENCER</h2>
            </div>

            <div className="row" style={{paddingRight:'10px', marginTop: '-32px'}}>
              <div className="col-lg-12">
                <ul className="sliding-menu">
                  <li style={{width: 'calc(100%/2)'}}>
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                      }}
                      className={` ${"selected" 
                      }`}
                    >
                      POSITIVE
                    </a>
                  </li>
                  <li style={{width: 'calc(100%/2)'}}>
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                      }}
                      className={` ${""
                      }`}
                    >
                      NEGATIVE
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>

            <div className="row" style={{marginRight: "25px"}}>
              {top_influencer}
            </div>

            <div className="row" style={{background: '#a61c1d', marginTop:"125px", marginBottom: '5px', 
              marginLeft:'2px', marginRight:'10px', paddingTop: '5px', paddingLeft: '10px'}}>
              <h2 style={{ marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.8em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Tweet | Top Trending</h2>
            </div>

            <div className="row" style={{marginRight: "15px"}}>
              <div className="col-lg-6" style={{height: '350px', overflowY: 'scroll'}}>
                <div className="centerContent">
                  <div className="selfCenter">
                      <TwitterTweetEmbed style={{}} tweetId={tweet_trend_text[tweet_trend_text.length - 1]} options={{
                          cards: 'hidden',
                        }} onLoad={()=>{
                          //bla bla after load
                        }} />
                    </div>
                </div>
              </div>
              <div className="col-lg-6" style={{height: '350px', overflowY: 'scroll'}}>
                <div className="centerContent">
                  <div className="selfCenter">
                      <TwitterTweetEmbed style={{width: '100%'}} tweetId={tweet_trend_text2[tweet_trend_text2.length - 1]} options={{
                          cards: 'hidden',
                        }} onLoad={()=>{
                          //bla bla after load
                        }} />
                    </div>
                </div>
              </div>
              
            </div>

          </div>   
        </div>
        <div className="row" style={{color: 'white', paddingTop: '30px', background: '#212529'}}>
          <div className="col-lg-12">
            <h4 className="text-center">Total Sentiment SNA</h4>
              <VictoryChart
                height={200}
                width={1366}
                domainPadding={100}
                maxDomain={{ y: 50 }}
              >
                <VictoryBar
                  data={dataSentimentTotal}
                  labels={({ datum }) => datum.y}
                  style={{
                    // color: 'white',
                    data: {
                      fill: d =>
                        d.x === "Negative"
                          ? "#d0021b"
                          : d.x === "Positive"
                          ? "#61ff69"
                          : "#bbb",
                      width: 150
                    },
                    labels: { fill: "white" }
                  }}
                  // labelComponent={<VictoryLabel dy={40}/>}
                />
                <VictoryAxis
                  style={{
                    axis: { stroke: "none" },
                    tickLabels: { fill: "white" }
                  }}
                />
              </VictoryChart>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout,
  setModalTopic
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sna);
