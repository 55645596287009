import React, { Component } from "react";
import { reAuth } from "actions/authActions";
import { connect } from "react-redux";
import {Box, Grid, Card, Paper, Button, Switch, FormHelperText, FormGroup, FormControlLabel,
  TextField, Select, MenuItem, InputLabel, FormControl, FormLabel, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
// import Mapping from "components/mapping/MappingCrowd";
import MapPoiRelawan from "components/shared/map/MapPoiRelawan";
import SidePanelRelawan from "../mapping/SidePanelRelawan";

import "styles/components/map-submenu.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const masterProvince = [
  {
    "location_id": "32",
    "location_name": "Jawa Barat"
  },
  {
    "location_id": "75",
    "location_name": "Gorontalo"
  },
  {
    "location_id": "64",
    "location_name": "Kalimantan Timur"
  },
  {
    "location_id": "17",
    "location_name": "Bengkulu"
  },
  {
    "location_id": "19",
    "location_name": "Bangka Belitung"
  },
  {
    "location_id": "12",
    "location_name": "Sumatera Utara"
  },
  {
    "location_id": "63",
    "location_name": "Kalimantan Selatan"
  },
  {
    "location_id": "36",
    "location_name": "Banten"
  },
  {
    "location_id": "71",
    "location_name": "Sulawesi Utara"
  },
  {
    "location_id": "52",
    "location_name": "Nusa Tenggara Barat"
  },
  {
    "location_id": "11",
    "location_name": "Aceh"
  },
  {
    "location_id": "62",
    "location_name": "Kalimantan Tengah"
  },
  {
    "location_id": "34",
    "location_name": "Yogyakarta"
  },
  {
    "location_id": "72",
    "location_name": "Sulawesi Tengah"
  },
  {
    "location_id": "73",
    "location_name": "Sulawesi Selatan"
  },
  {
    "location_id": "76",
    "location_name": "Sulawesi Barat"
  },
  {
    "location_id": "64",
    "location_name": "Kalimantan Utara"
  },
  {
    "location_id": "15",
    "location_name": "Jambi"
  },
  {
    "location_id": "81",
    "location_name": "Maluku"
  },
  {
    "location_id": "33",
    "location_name": "Jawa Tengah"
  },
  {
    "location_id": "51",
    "location_name": "Bali"
  },
  {
    "location_id": "35",
    "location_name": "Jawa Timur"
  },
  {
    "location_id": "61",
    "location_name": "Kalimantan Barat"
  },
  {
    "location_id": "31",
    "location_name": "Jakarta"
  },
  {
    "location_id": "91",
    "location_name": "Papua"
  },
  {
    "location_id": "92",
    "location_name": "Papua Barat"
  },
  {
    "location_id": "53",
    "location_name": "Nusa Tenggara Timur"
  },
  {
    "location_id": "82",
    "location_name": "Maluku Utara"
  },
  {
    "location_id": "21",
    "location_name": "Kepulauan Riau"
  },
  {
    "location_id": "13",
    "location_name": "Sumatera Barat"
  },
  {
    "location_id": "18",
    "location_name": "Lampung"
  },
  {
    "location_id": "16",
    "location_name": "Sumatera Selatan"
  },
  {
    "location_id": "74",
    "location_name": "Sulawesi Tenggara"
  },
  {
    "location_id": "14",
    "location_name": "Riau"
  }
]

class POISupporter extends Component {
  state = {
    modal: false,
    region: "35",
    inputProvinceName: null,
    inputPlaceName: null,

    isFootballYes: true,
    isETStrongYes: true,
    isETSomewhatYes: false,
    isETNo: false,
    isStadiumIncluded: true
  }

  // const handleFrameElement = React.useCallback(e => {
  //   setFrameElement(e.target);
  // }, []);

  handleFrameElement = val => {
    this.setState({
      // frameElement: val
    })
  }

  handleInputPlace = val => {
    this.setState({
      inputPlaceName: val.target.value
    })
  }

  handleMatchmakingType = val => {
    // this.setState({
    //   inputPlaceName: val.target.value
    // })

    this.setState({
      [val.target.name]: val.target.checked,
    });
  }

  handleInputArea = val => {
    console.log('xxx input area ', val.target.value);
    this.setState({
      inputProvinceName: val.target.value,
      region: val.target.value
    })
  }
  

  componentDidMount() {

  }
  componentDidUpdate(prevProps, prevState) {
  }

  render() {

    // document.cookie = ""

    return (
      <React.Fragment>

      <div class="container-fullwidth" style={{padding: '0 !important'}}>
        <div className="row no-gutters flex-fill" style={{minHeight: '0', height: '95vh', background: '#191819'}}>
          <div className="col-md-3 mh-100" style={{overflowY: 'scroll'}}>
            <div style={{padding: '22px', color: 'white', background: '#191819'}}>
              <div
                  component="form"
                  noValidate
                  autoComplete="off"
                  style={{width: '100%', background: '#ddd !important', color: 'white !important', textColor: 'white !important'}} 
                >
                  <Card style={{background: '#282828', padding: '20px'}}>

                    <FormHelperText style={{color: 'white', paddingLeft: '18px'}}>Processing Deployed (Coming soon...)</FormHelperText>
                    <div style={{width: '100%', marginTop: '10px'}}>
                      
                      <div className="row" style={{padding: '0px 32px', color: 'white', fontSize: '12px'}}>
                          <strong>Region</strong>
                      </div>
                      <div className="row" style={{padding: '5px 30px'}}>
                          <Select
                              value={this.state.region}
                              onChange={this.handleInputArea}
                              label="National"
                              fullWidth
                              style={{background: '#4D4C51', color:"white", borderRadius: '4px', padding: '1px 20px', fontSize: '12px', textTransform:'capitalize'}}
                          >
                              {
                                  masterProvince.map((val, idx) => (
                                      <MenuItem value={val.location_id} style={{color:"#4D4C51", padding: '5px 8px'}}>
                                          {val.location_name}
                                      </MenuItem>
                                  ))
                              }
                          </Select>
                      </div>

                      <div className="row" style={{padding: '5px 30px'}}>
                        <div className="col-md-8"></div>
                        <div className="col-md-4" style={{right: '20px'}}>
                          <Button 
                            style={{padding: '10px 30px'}} 
                            variant="contained" 
                            disableElevation

                            // onClick={this.handleButtonFilterLeft}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card>
              </div>
            </div>
          </div>
          <div className="col-md-6 mh-100" style={{overflowY: 'scroll'}}>
            <MapPoiRelawan source="turnout" type="poi_zonasi" handleBorderMapClick={()=>{}} />
          </div>
          <div className="col-md-3 mh-100" style={{overflowY: 'scroll'}}>
            <div style={{ padding: '20px', background: '#282828'}}>
              <SidePanelRelawan region={this.state.region} />
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  map: state.map
  // turnout: state.map.turnout
});

const mapDispatchToProps = {
  reAuth
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(POISupporter);
