export const scatterA = [
	{
		x:0.83160000000000001,
		y:0.95009999999999994,
		supporter:"A"
	},
	{
		x:0.8891,
		y:0.95589999999999997,
		supporter:"A"
	},
	{
		x:0.89119999999999999,
		y:0.95240000000000002,
		supporter:"A"
	},
	{
		x:0.88929999999999998,
		y:0.95289999999999997,
		supporter:"A"
	},
	{
		x:0.87160000000000004,
		y:0.95069999999999999,
		supporter:"A"
	},
	{
		x:0.8508,
		y:0.95169999999999999,
		supporter:"A"
	},
	{
		x:0.88090000000000002,
		y:0.95140000000000002,
		supporter:"A"
	},
	{
		x:0.88560000000000005,
		y:0.9516,
		supporter:"A"
	},
	{
		x:0.87560000000000004,
		y:0.95020000000000004,
		supporter:"A"
	},
	{
		x:0.875,
		y:0.95320000000000005,
		supporter:"A"
	},
	{
		x:0.87849999999999995,
		y:0.95189999999999997,
		supporter:"A"
	},
	{
		x:0.63749999999999996,
		y:0.98150000000000004,
		supporter:"A"
	},
	{
		x:0.88239999999999996,
		y:0.95169999999999999,
		supporter:"A"
	},
	{
		x:0.88970000000000005,
		y:0.95109999999999995,
		supporter:"A"
	},
	{
		x:0.83440000000000003,
		y:0.95079999999999998,
		supporter:"A"
	},
	{
		x:0.88560000000000005,
		y:0.95250000000000001,
		supporter:"A"
	},
	{
		x:0.88849999999999996,
		y:0.9536,
		supporter:"A"
	},
	{
		x:0.7681,
		y:0.96460000000000001,
		supporter:"A"
	},
	{
		x:0.87280000000000002,
		y:0.95199999999999996,
		supporter:"A"
	},
	{
		x:0.87839999999999996,
		y:0.9516,
		supporter:"A"
	},
	{
		x:0.89610000000000001,
		y:0.95920000000000005,
		supporter:"A"
	},
	{
		x:0.8891,
		y:0.95760000000000001,
		supporter:"A"
	},
	{
		x:0.88470000000000004,
		y:0.95379999999999998,
		supporter:"A"
	},
	{
		x:0.76590000000000003,
		y:0.97140000000000004,
		supporter:"A"
	},
	{
		x:0.86609999999999998,
		y:0.9536,
		supporter:"A"
	},
	{
		x:0.89749999999999996,
		y:0.95579999999999998,
		supporter:"A"
	},
	{
		x:0.69979999999999998,
		y:0.96199999999999997,
		supporter:"A"
	},
	{
		x:0.89680000000000004,
		y:0.95189999999999997,
		supporter:"A"
	},
	{
		x:0.88600000000000001,
		y:0.95240000000000002,
		supporter:"A"
	},
	{
		x:0.86980000000000002,
		y:0.95289999999999997,
		supporter:"A"
	},
	{
		x:0.88319999999999999,
		y:0.95120000000000005,
		supporter:"A"
	},
	{
		x:0.89300000000000002,
		y:0.9526,
		supporter:"A"
	},
	{
		x:0.88180000000000003,
		y:0.95220000000000005,
		supporter:"A"
	},
	{
		x:0.89659999999999995,
		y:0.95050000000000001,
		supporter:"A"
	},
	{
		x:0.83160000000000001,
		y:0.95069999999999999,
		supporter:"A"
	},
	{
		x:0.89390000000000003,
		y:0.95350000000000001,
		supporter:"A"
	}
]

export const scatterB = [
	{
		x:0.78069999999999995,
		y:0.78220000000000001,
		supporter:"B"
	},
	{
		x:0.66020000000000001,
		y:0.89980000000000004,
		supporter:"B"
	},
	{
		x:0.70989999999999998,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.73550000000000004,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.87239999999999995,
		y:0.90649999999999997,
		supporter:"B"
	},
	{
		x:0.7681,
		y:0.79069999999999996,
		supporter:"B"
	},
	{
		x:0.70320000000000005,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.80889999999999995,
		y:0.91469999999999996,
		supporter:"B"
	},
	{
		x:0.69420000000000004,
		y:0.88419999999999999,
		supporter:"B"
	},
	{
		x:0.69730000000000003,
		y:0.90249999999999997,
		supporter:"B"
	},
	{
		x:0.63800000000000001,
		y:0.91020000000000001,
		supporter:"B"
	},
	{
		x:0.86880000000000002,
		y:0.94950000000000001,
		supporter:"B"
	},
	{
		x:0.7752,
		y:0.89659999999999995,
		supporter:"B"
	},
	{
		x:0.64449999999999996,
		y:0.91549999999999998,
		supporter:"B"
	},
	{
		x:0.86180000000000001,
		y:0.81669999999999998,
		supporter:"B"
	},
	{
		x:0.67689999999999995,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.65159999999999996,
		y:0.91720000000000002,
		supporter:"B"
	},
	{
		x:0.67800000000000005,
		y:0.90629999999999999,
		supporter:"B"
	},
	{
		x:0.65400000000000003,
		y:0.91720000000000002,
		supporter:"B"
	},
	{
		x:0.82799999999999996,
		y:0.89649999999999996,
		supporter:"B"
	},
	{
		x:0.64739999999999998,
		y:0.91600000000000004,
		supporter:"B"
	},
	{
		x:0.85909999999999997,
		y:0.91300000000000003,
		supporter:"B"
	},
	{
		x:0.69269999999999998,
		y:0.92390000000000005,
		supporter:"B"
	},
	{
		x:0.69930000000000003,
		y:0.78110000000000002,
		supporter:"B"
	},
	{
		x:0.70650000000000002,
		y:0.92520000000000002,
		supporter:"B"
	},
	{
		x:0.82199999999999995,
		y:0.82489999999999997,
		supporter:"B"
	},
	{
		x:0.68200000000000005,
		y:0.89590000000000003,
		supporter:"B"
	},
	{
		x:0.76500000000000001,
		y:0.87590000000000001,
		supporter:"B"
	},
	{
		x:0.85860000000000003,
		y:0.91039999999999999,
		supporter:"B"
	},
	{
		x:0.69779999999999998,
		y:0.9204,
		supporter:"B"
	},
	{
		x:0.72940000000000005,
		y:0.90459999999999996,
		supporter:"B"
	},
	{
		x:0.76470000000000005,
		y:0.78280000000000005,
		supporter:"B"
	},
	{
		x:0.7379,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.7107,
		y:0.90629999999999999,
		supporter:"B"
	},
	{
		x:0.65069999999999995,
		y:0.91520000000000001,
		supporter:"B"
	},
	{
		x:0.65269999999999995,
		y:0.90569999999999995,
		supporter:"B"
	},
	{
		x:0.71330000000000005,
		y:0.93489999999999995,
		supporter:"B"
	},
	{
		x:0.76729999999999998,
		y:0.87760000000000005,
		supporter:"B"
	},
	{
		x:0.66139999999999999,
		y:0.90580000000000005,
		supporter:"B"
	},
	{
		x:0.73660000000000003,
		y:0.91810000000000003,
		supporter:"B"
	},
	{
		x:0.70009999999999994,
		y:0.88100000000000001,
		supporter:"B"
	},
	{
		x:0.66310000000000002,
		y:0.90049999999999997,
		supporter:"B"
	},
	{
		x:0.6835,
		y:0.90449999999999997,
		supporter:"B"
	},
	{
		x:0.73199999999999998,
		y:0.79810000000000003,
		supporter:"B"
	},
	{
		x:0.68689999999999996,
		y:0.88480000000000003,
		supporter:"B"
	},
	{
		x:0.752,
		y:0.91590000000000005,
		supporter:"B"
	},
	{
		x:0.77800000000000002,
		y:0.86939999999999995,
		supporter:"B"
	},
	{
		x:0.69420000000000004,
		y:0.83809999999999996,
		supporter:"B"
	},
	{
		x:0.69240000000000002,
		y:0.84640000000000004,
		supporter:"B"
	},
	{
		x:0.67659999999999998,
		y:0.91669999999999996,
		supporter:"B"
	},
	{
		x:0.66469999999999996,
		y:0.88119999999999998,
		supporter:"B"
	},
	{
		x:0.749,
		y:0.84309999999999996,
		supporter:"B"
	},
	{
		x:0.66759999999999997,
		y:0.91180000000000005,
		supporter:"B"
	},
	{
		x:0.83830000000000005,
		y:0.90149999999999997,
		supporter:"B"
	},
	{
		x:0.65629999999999999,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.6774,
		y:0.8518,
		supporter:"B"
	},
	{
		x:0.6875,
		y:0.92259999999999998,
		supporter:"B"
	},
	{
		x:0.66969999999999996,
		y:0.90559999999999996,
		supporter:"B"
	},
	{
		x:0.72640000000000005,
		y:0.88160000000000005,
		supporter:"B"
	},
	{
		x:0.63129999999999997,
		y:0.90920000000000001,
		supporter:"B"
	},
	{
		x:0.70389999999999997,
		y:0.91979999999999995,
		supporter:"B"
	},
	{
		x:0.64170000000000005,
		y:0.9173,
		supporter:"B"
	},
	{
		x:0.65790000000000004,
		y:0.91559999999999997,
		supporter:"B"
	},
	{
		x:0.64810000000000001,
		y:0.79979999999999996,
		supporter:"B"
	},
	{
		x:0.71079999999999999,
		y:0.91,
		supporter:"B"
	},
	{
		x:0.8034,
		y:0.87350000000000005,
		supporter:"B"
	},
	{
		x:0.76980000000000004,
		y:0.78510000000000002,
		supporter:"B"
	},
	{
		x:0.78620000000000001,
		y:0.79179999999999995,
		supporter:"B"
	},
	{
		x:0.70020000000000004,
		y:0.89490000000000003,
		supporter:"B"
	},
	{
		x:0.71199999999999997,
		y:0.88700000000000001,
		supporter:"B"
	},
	{
		x:0.64729999999999999,
		y:0.92049999999999998,
		supporter:"B"
	},
	{
		x:0.69110000000000005,
		y:0.78480000000000005,
		supporter:"B"
	},
	{
		x:0.65310000000000001,
		y:0.9022,
		supporter:"B"
	},
	{
		x:0.7177,
		y:0.82709999999999995,
		supporter:"B"
	},
	{
		x:0.75549999999999995,
		y:0.88890000000000002,
		supporter:"B"
	},
	{
		x:0.85199999999999998,
		y:0.87670000000000003,
		supporter:"B"
	},
	{
		x:0.78549999999999998,
		y:0.89229999999999998,
		supporter:"B"
	},
	{
		x:0.63019999999999998,
		y:0.92349999999999999,
		supporter:"B"
	},
	{
		x:0.65229999999999999,
		y:0.91739999999999999,
		supporter:"B"
	},
	{
		x:0.86799999999999999,
		y:0.91769999999999996,
		supporter:"B"
	},
	{
		x:0.70630000000000004,
		y:0.7994,
		supporter:"B"
	},
	{
		x:0.69940000000000002,
		y:0.90280000000000005,
		supporter:"B"
	},
	{
		x:0.65759999999999996,
		y:0.81510000000000005,
		supporter:"B"
	},
	{
		x:0.63670000000000004,
		y:0.91510000000000002,
		supporter:"B"
	},
	{
		x:0.65229999999999999,
		y:0.91349999999999998,
		supporter:"B"
	},
	{
		x:0.63590000000000002,
		y:0.90539999999999998,
		supporter:"B"
	},
	{
		x:0.64200000000000002,
		y:0.91720000000000002,
		supporter:"B"
	},
	{
		x:0.84989999999999999,
		y:0.94910000000000005,
		supporter:"B"
	},
	{
		x:0.67800000000000005,
		y:0.92130000000000001,
		supporter:"B"
	},
	{
		x:0.72970000000000002,
		y:0.78969999999999996,
		supporter:"B"
	},
	{
		x:0.69699999999999995,
		y:0.92090000000000005,
		supporter:"B"
	},
	{
		x:0.69769999999999999,
		y:0.92849999999999999,
		supporter:"B"
	},
	{
		x:0.79430000000000001,
		y:0.89949999999999997,
		supporter:"B"
	},
	{
		x:0.68010000000000004,
		y:0.88319999999999999,
		supporter:"B"
	},
	{
		x:0.64659999999999995,
		y:0.9123,
		supporter:"B"
	},
	{
		x:0.67459999999999998,
		y:0.79600000000000004,
		supporter:"B"
	},
	{
		x:0.71189999999999998,
		y:0.90949999999999998,
		supporter:"B"
	},
	{
		x:0.86060000000000003,
		y:0.91910000000000003,
		supporter:"B"
	},
	{
		x:0.74129999999999996,
		y:0.88319999999999999,
		supporter:"B"
	},
	{
		x:0.66310000000000002,
		y:0.93010000000000004,
		supporter:"B"
	},
	{
		x:0.87790000000000001,
		y:0.90639999999999998,
		supporter:"B"
	},
	{
		x:0.63249999999999995,
		y:0.91169999999999995,
		supporter:"B"
	},
	{
		x:0.71319999999999995,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.68869999999999998,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.68569999999999998,
		y:0.91159999999999997,
		supporter:"B"
	},
	{
		x:0.8831,
		y:0.876,
		supporter:"B"
	},
	{
		x:0.67610000000000003,
		y:0.8901,
		supporter:"B"
	},
	{
		x:0.66879999999999995,
		y:0.87719999999999998,
		supporter:"B"
	},
	{
		x:0.69779999999999998,
		y:0.88529999999999998,
		supporter:"B"
	},
	{
		x:0.70469999999999999,
		y:0.77490000000000003,
		supporter:"B"
	},
	{
		x:0.79179999999999995,
		y:0.78739999999999999,
		supporter:"B"
	},
	{
		x:0.74550000000000005,
		y:0.87609999999999999,
		supporter:"B"
	},
	{
		x:0.67979999999999996,
		y:0.89139999999999997,
		supporter:"B"
	},
	{
		x:0.872,
		y:0.91039999999999999,
		supporter:"B"
	},
	{
		x:0.68189999999999995,
		y:0.874,
		supporter:"B"
	},
	{
		x:0.67969999999999997,
		y:0.91930000000000001,
		supporter:"B"
	},
	{
		x:0.70079999999999998,
		y:0.91559999999999997,
		supporter:"B"
	},
	{
		x:0.63690000000000002,
		y:0.91749999999999998,
		supporter:"B"
	},
	{
		x:0.71179999999999999,
		y:0.85640000000000005,
		supporter:"B"
	},
	{
		x:0.65939999999999999,
		y:0.92130000000000001,
		supporter:"B"
	},
	{
		x:0.85880000000000001,
		y:0.90939999999999999,
		supporter:"B"
	},
	{
		x:0.70799999999999996,
		y:0.91510000000000002,
		supporter:"B"
	},
	{
		x:0.76900000000000002,
		y:0.91920000000000002,
		supporter:"B"
	},
	{
		x:0.64529999999999998,
		y:0.89659999999999995,
		supporter:"B"
	},
	{
		x:0.86219999999999997,
		y:0.78649999999999998,
		supporter:"B"
	},
	{
		x:0.69030000000000002,
		y:0.79990000000000006,
		supporter:"B"
	},
	{
		x:0.70699999999999996,
		y:0.90069999999999995,
		supporter:"B"
	},
	{
		x:0.64370000000000005,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.65339999999999998,
		y:0.91879999999999995,
		supporter:"B"
	},
	{
		x:0.70779999999999998,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.8649,
		y:0.90329999999999999,
		supporter:"B"
	},
	{
		x:0.6462,
		y:0.9123,
		supporter:"B"
	},
	{
		x:0.65229999999999999,
		y:0.90129999999999999,
		supporter:"B"
	},
	{
		x:0.6835,
		y:0.91020000000000001,
		supporter:"B"
	},
	{
		x:0.66220000000000001,
		y:0.92810000000000004,
		supporter:"B"
	},
	{
		x:0.76929999999999998,
		y:0.88870000000000005,
		supporter:"B"
	},
	{
		x:0.70230000000000004,
		y:0.9022,
		supporter:"B"
	},
	{
		x:0.87450000000000006,
		y:0.90969999999999995,
		supporter:"B"
	},
	{
		x:0.72419999999999995,
		y:0.91710000000000003,
		supporter:"B"
	},
	{
		x:0.65910000000000002,
		y:0.92369999999999997,
		supporter:"B"
	},
	{
		x:0.69989999999999997,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.64039999999999997,
		y:0.79690000000000005,
		supporter:"B"
	},
	{
		x:0.82809999999999995,
		y:0.877,
		supporter:"B"
	},
	{
		x:0.71830000000000005,
		y:0.92530000000000001,
		supporter:"B"
	},
	{
		x:0.63090000000000002,
		y:0.92210000000000003,
		supporter:"B"
	},
	{
		x:0.87370000000000003,
		y:0.90739999999999998,
		supporter:"B"
	},
	{
		x:0.73360000000000003,
		y:0.89600000000000002,
		supporter:"B"
	},
	{
		x:0.63219999999999998,
		y:0.91600000000000004,
		supporter:"B"
	},
	{
		x:0.71719999999999995,
		y:0.82320000000000004,
		supporter:"B"
	},
	{
		x:0.65080000000000005,
		y:0.90690000000000004,
		supporter:"B"
	},
	{
		x:0.65159999999999996,
		y:0.90359999999999996,
		supporter:"B"
	},
	{
		x:0.88460000000000005,
		y:0.91510000000000002,
		supporter:"B"
	},
	{
		x:0.84240000000000004,
		y:0.90529999999999999,
		supporter:"B"
	},
	{
		x:0.67249999999999999,
		y:0.90839999999999999,
		supporter:"B"
	},
	{
		x:0.74629999999999996,
		y:0.90429999999999999,
		supporter:"B"
	},
	{
		x:0.78490000000000004,
		y:0.89890000000000003,
		supporter:"B"
	},
	{
		x:0.68759999999999999,
		y:0.88349999999999995,
		supporter:"B"
	},
	{
		x:0.7167,
		y:0.91220000000000001,
		supporter:"B"
	},
	{
		x:0.70169999999999999,
		y:0.92010000000000003,
		supporter:"B"
	},
	{
		x:0.67749999999999999,
		y:0.91769999999999996,
		supporter:"B"
	},
	{
		x:0.76400000000000001,
		y:0.89159999999999995,
		supporter:"B"
	},
	{
		x:0.67569999999999997,
		y:0.91890000000000005,
		supporter:"B"
	},
	{
		x:0.65990000000000004,
		y:0.91520000000000001,
		supporter:"B"
	},
	{
		x:0.65069999999999995,
		y:0.92479999999999996,
		supporter:"B"
	},
	{
		x:0.69010000000000005,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.66849999999999998,
		y:0.9244,
		supporter:"B"
	},
	{
		x:0.63460000000000005,
		y:0.9194,
		supporter:"B"
	},
	{
		x:0.65039999999999998,
		y:0.91669999999999996,
		supporter:"B"
	},
	{
		x:0.67689999999999995,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.65920000000000001,
		y:0.92659999999999998,
		supporter:"B"
	},
	{
		x:0.67869999999999997,
		y:0.90200000000000002,
		supporter:"B"
	},
	{
		x:0.65849999999999997,
		y:0.92130000000000001,
		supporter:"B"
	},
	{
		x:0.69440000000000002,
		y:0.88890000000000002,
		supporter:"B"
	},
	{
		x:0.74080000000000001,
		y:0.92459999999999998,
		supporter:"B"
	},
	{
		x:0.67049999999999998,
		y:0.89849999999999997,
		supporter:"B"
	},
	{
		x:0.64800000000000002,
		y:0.92049999999999998,
		supporter:"B"
	},
	{
		x:0.70299999999999996,
		y:0.90080000000000005,
		supporter:"B"
	},
	{
		x:0.82299999999999995,
		y:0.88970000000000005,
		supporter:"B"
	},
	{
		x:0.84150000000000003,
		y:0.90749999999999997,
		supporter:"B"
	},
	{
		x:0.7409,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.73250000000000004,
		y:0.78069999999999995,
		supporter:"B"
	},
	{
		x:0.71389999999999998,
		y:0.88149999999999995,
		supporter:"B"
	},
	{
		x:0.68210000000000004,
		y:0.7893,
		supporter:"B"
	},
	{
		x:0.69269999999999998,
		y:0.92010000000000003,
		supporter:"B"
	},
	{
		x:0.67879999999999996,
		y:0.9153,
		supporter:"B"
	},
	{
		x:0.77739999999999998,
		y:0.78210000000000002,
		supporter:"B"
	},
	{
		x:0.88229999999999997,
		y:0.91900000000000004,
		supporter:"B"
	},
	{
		x:0.85750000000000004,
		y:0.79220000000000002,
		supporter:"B"
	},
	{
		x:0.64839999999999998,
		y:0.93189999999999995,
		supporter:"B"
	},
	{
		x:0.64349999999999996,
		y:0.91369999999999996,
		supporter:"B"
	},
	{
		x:0.68440000000000001,
		y:0.90680000000000005,
		supporter:"B"
	},
	{
		x:0.65539999999999998,
		y:0.79200000000000004,
		supporter:"B"
	},
	{
		x:0.66810000000000003,
		y:0.91220000000000001,
		supporter:"B"
	},
	{
		x:0.872,
		y:0.91479999999999995,
		supporter:"B"
	},
	{
		x:0.63660000000000005,
		y:0.9143,
		supporter:"B"
	},
	{
		x:0.8679,
		y:0.91190000000000004,
		supporter:"B"
	},
	{
		x:0.78159999999999996,
		y:0.79430000000000001,
		supporter:"B"
	},
	{
		x:0.76329999999999998,
		y:0.89470000000000005,
		supporter:"B"
	},
	{
		x:0.65890000000000004,
		y:0.91900000000000004,
		supporter:"B"
	},
	{
		x:0.66879999999999995,
		y:0.80800000000000005,
		supporter:"B"
	},
	{
		x:0.95150000000000001,
		y:0.90400000000000003,
		supporter:"B"
	},
	{
		x:0.70169999999999999,
		y:0.92310000000000003,
		supporter:"B"
	},
	{
		x:0.69440000000000002,
		y:0.89500000000000002,
		supporter:"B"
	},
	{
		x:0.75729999999999997,
		y:0.79300000000000004,
		supporter:"B"
	},
	{
		x:0.66379999999999995,
		y:0.88919999999999999,
		supporter:"B"
	},
	{
		x:0.65910000000000002,
		y:0.90380000000000005,
		supporter:"B"
	},
	{
		x:0.86770000000000003,
		y:0.9123,
		supporter:"B"
	},
	{
		x:0.66139999999999999,
		y:0.89929999999999999,
		supporter:"B"
	},
	{
		x:0.85650000000000004,
		y:0.82899999999999996,
		supporter:"B"
	},
	{
		x:0.64100000000000001,
		y:0.88819999999999999,
		supporter:"B"
	},
	{
		x:0.86860000000000004,
		y:0.90720000000000001,
		supporter:"B"
	},
	{
		x:0.63849999999999996,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.82950000000000002,
		y:0.91220000000000001,
		supporter:"B"
	},
	{
		x:0.65369999999999995,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.70209999999999995,
		y:0.87870000000000004,
		supporter:"B"
	},
	{
		x:0.63190000000000002,
		y:0.90769999999999995,
		supporter:"B"
	},
	{
		x:0.69889999999999997,
		y:0.93440000000000001,
		supporter:"B"
	},
	{
		x:0.8458,
		y:0.89490000000000003,
		supporter:"B"
	},
	{
		x:0.65349999999999997,
		y:0.91930000000000001,
		supporter:"B"
	},
	{
		x:0.67200000000000004,
		y:0.76400000000000001,
		supporter:"B"
	},
	{
		x:0.63249999999999995,
		y:0.91559999999999997,
		supporter:"B"
	},
	{
		x:0.73629999999999995,
		y:0.78490000000000004,
		supporter:"B"
	},
	{
		x:0.64649999999999996,
		y:0.92200000000000004,
		supporter:"B"
	},
	{
		x:0.73109999999999997,
		y:0.92759999999999998,
		supporter:"B"
	},
	{
		x:0.66910000000000003,
		y:0.91490000000000005,
		supporter:"B"
	},
	{
		x:0.67759999999999998,
		y:0.91210000000000002,
		supporter:"B"
	},
	{
		x:0.84530000000000005,
		y:0.90090000000000003,
		supporter:"B"
	},
	{
		x:0.73599999999999999,
		y:0.91359999999999997,
		supporter:"B"
	},
	{
		x:0.87090000000000001,
		y:0.94969999999999999,
		supporter:"B"
	},
	{
		x:0.69779999999999998,
		y:0.92349999999999999,
		supporter:"B"
	},
	{
		x:0.72929999999999995,
		y:0.92720000000000002,
		supporter:"B"
	},
	{
		x:0.73719999999999997,
		y:0.91469999999999996,
		supporter:"B"
	},
	{
		x:0.76290000000000002,
		y:0.9073,
		supporter:"B"
	},
	{
		x:0.85640000000000005,
		y:0.79049999999999998,
		supporter:"B"
	},
	{
		x:0.67830000000000001,
		y:0.90290000000000004,
		supporter:"B"
	},
	{
		x:0.72929999999999995,
		y:0.9002,
		supporter:"B"
	},
	{
		x:0.68620000000000003,
		y:0.89439999999999997,
		supporter:"B"
	},
	{
		x:0.65610000000000002,
		y:0.85289999999999999,
		supporter:"B"
	},
	{
		x:0.76280000000000003,
		y:0.88670000000000004,
		supporter:"B"
	},
	{
		x:0.76570000000000005,
		y:0.87450000000000006,
		supporter:"B"
	},
	{
		x:0.65100000000000002,
		y:0.91759999999999997,
		supporter:"B"
	},
	{
		x:0.86580000000000001,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.74519999999999997,
		y:0.91439999999999999,
		supporter:"B"
	},
	{
		x:0.94799999999999995,
		y:0.90620000000000001,
		supporter:"B"
	},
	{
		x:0.72829999999999995,
		y:0.87819999999999998,
		supporter:"B"
	},
	{
		x:0.70730000000000004,
		y:0.91900000000000004,
		supporter:"B"
	},
	{
		x:0.64219999999999999,
		y:0.91839999999999999,
		supporter:"B"
	},
	{
		x:0.67469999999999997,
		y:0.92889999999999995,
		supporter:"B"
	},
	{
		x:0.65459999999999996,
		y:0.92730000000000001,
		supporter:"B"
	},
	{
		x:0.66120000000000001,
		y:0.91700000000000004,
		supporter:"B"
	},
	{
		x:0.70579999999999998,
		y:0.90210000000000001,
		supporter:"B"
	},
	{
		x:0.69989999999999997,
		y:0.92910000000000004,
		supporter:"B"
	},
	{
		x:0.6401,
		y:0.90390000000000004,
		supporter:"B"
	},
	{
		x:0.69010000000000005,
		y:0.89849999999999997,
		supporter:"B"
	},
	{
		x:0.76080000000000003,
		y:0.78480000000000005,
		supporter:"B"
	},
	{
		x:0.85540000000000005,
		y:0.80279999999999996,
		supporter:"B"
	},
	{
		x:0.80300000000000005,
		y:0.90010000000000001,
		supporter:"B"
	},
	{
		x:0.85929999999999995,
		y:0.90559999999999996,
		supporter:"B"
	},
	{
		x:0.8669,
		y:0.79359999999999997,
		supporter:"B"
	},
	{
		x:0.72199999999999998,
		y:0.88190000000000002,
		supporter:"B"
	},
	{
		x:0.65459999999999996,
		y:0.79400000000000004,
		supporter:"B"
	},
	{
		x:0.63739999999999997,
		y:0.91400000000000003,
		supporter:"B"
	},
	{
		x:0.69779999999999998,
		y:0.8901,
		supporter:"B"
	},
	{
		x:0.76839999999999997,
		y:0.89800000000000002,
		supporter:"B"
	},
	{
		x:0.66210000000000002,
		y:0.90939999999999999,
		supporter:"B"
	},
	{
		x:0.65580000000000005,
		y:0.92979999999999996,
		supporter:"B"
	},
	{
		x:0.6855,
		y:0.8841,
		supporter:"B"
	},
	{
		x:0.69579999999999997,
		y:0.88519999999999999,
		supporter:"B"
	},
	{
		x:0.74070000000000003,
		y:0.90620000000000001,
		supporter:"B"
	},
	{
		x:0.71360000000000001,
		y:0.90680000000000005,
		supporter:"B"
	},
	{
		x:0.878,
		y:0.91920000000000002,
		supporter:"B"
	},
	{
		x:0.8669,
		y:0.91039999999999999,
		supporter:"B"
	},
	{
		x:0.64729999999999999,
		y:0.92030000000000001,
		supporter:"B"
	},
	{
		x:0.63680000000000003,
		y:0.9264,
		supporter:"B"
	},
	{
		x:0.6613,
		y:0.92600000000000005,
		supporter:"B"
	},
	{
		x:0.73499999999999999,
		y:0.9163,
		supporter:"B"
	},
	{
		x:0.69640000000000002,
		y:0.92959999999999998,
		supporter:"B"
	},
	{
		x:0.68930000000000002,
		y:0.92620000000000002,
		supporter:"B"
	},
	{
		x:0.68220000000000003,
		y:0.92959999999999998,
		supporter:"B"
	},
	{
		x:0.66959999999999997,
		y:0.88319999999999999,
		supporter:"B"
	},
	{
		x:0.78800000000000003,
		y:0.86019999999999996,
		supporter:"B"
	},
	{
		x:0.6401,
		y:0.81100000000000005,
		supporter:"B"
	},
	{
		x:0.69950000000000001,
		y:0.87870000000000004,
		supporter:"B"
	},
	{
		x:0.68220000000000003,
		y:0.92630000000000001,
		supporter:"B"
	},
	{
		x:0.65590000000000004,
		y:0.90869999999999995,
		supporter:"B"
	},
	{
		x:0.71640000000000004,
		y:0.91490000000000005,
		supporter:"B"
	},
	{
		x:0.87380000000000002,
		y:0.91239999999999999,
		supporter:"B"
	},
	{
		x:0.65180000000000005,
		y:0.91859999999999997,
		supporter:"B"
	},
	{
		x:0.68279999999999996,
		y:0.88749999999999996,
		supporter:"B"
	},
	{
		x:0.7016,
		y:0.9032,
		supporter:"B"
	},
	{
		x:0.68169999999999997,
		y:0.88880000000000003,
		supporter:"B"
	},
	{
		x:0.72070000000000001,
		y:0.87039999999999995,
		supporter:"B"
	},
	{
		x:0.64810000000000001,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.66210000000000002,
		y:0.90280000000000005,
		supporter:"B"
	},
	{
		x:0.69450000000000001,
		y:0.90490000000000004,
		supporter:"B"
	},
	{
		x:0.88149999999999995,
		y:0.91559999999999997,
		supporter:"B"
	},
	{
		x:0.66830000000000001,
		y:0.89329999999999998,
		supporter:"B"
	},
	{
		x:0.82889999999999997,
		y:0.89219999999999999,
		supporter:"B"
	},
	{
		x:0.67259999999999998,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.6552,
		y:0.91180000000000005,
		supporter:"B"
	},
	{
		x:0.69410000000000005,
		y:0.91339999999999999,
		supporter:"B"
	},
	{
		x:0.67700000000000005,
		y:0.90129999999999999,
		supporter:"B"
	},
	{
		x:0.83109999999999995,
		y:0.77480000000000004,
		supporter:"B"
	},
	{
		x:0.67669999999999997,
		y:0.92689999999999995,
		supporter:"B"
	},
	{
		x:0.74570000000000003,
		y:0.91490000000000005,
		supporter:"B"
	},
	{
		x:0.82789999999999997,
		y:0.80249999999999999,
		supporter:"B"
	},
	{
		x:0.63349999999999995,
		y:0.79949999999999999,
		supporter:"B"
	},
	{
		x:0.71230000000000004,
		y:0.91979999999999995,
		supporter:"B"
	},
	{
		x:0.73019999999999996,
		y:0.87660000000000005,
		supporter:"B"
	},
	{
		x:0.6421,
		y:0.9153,
		supporter:"B"
	},
	{
		x:0.73280000000000001,
		y:0.89800000000000002,
		supporter:"B"
	},
	{
		x:0.73,
		y:0.91390000000000005,
		supporter:"B"
	},
	{
		x:0.73970000000000002,
		y:0.9083,
		supporter:"B"
	},
	{
		x:0.87009999999999998,
		y:0.90739999999999998,
		supporter:"B"
	},
	{
		x:0.64680000000000004,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.8,
		y:0.9093,
		supporter:"B"
	},
	{
		x:0.78339999999999999,
		y:0.88519999999999999,
		supporter:"B"
	},
	{
		x:0.71530000000000005,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.70299999999999996,
		y:0.90939999999999999,
		supporter:"B"
	},
	{
		x:0.78320000000000001,
		y:0.77939999999999998,
		supporter:"B"
	},
	{
		x:0.64900000000000002,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.66349999999999998,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.65169999999999995,
		y:0.91849999999999998,
		supporter:"B"
	},
	{
		x:0.63439999999999996,
		y:0.90059999999999996,
		supporter:"B"
	},
	{
		x:0.65590000000000004,
		y:0.92010000000000003,
		supporter:"B"
	},
	{
		x:0.65359999999999996,
		y:0.89580000000000004,
		supporter:"B"
	},
	{
		x:0.71950000000000003,
		y:0.89370000000000005,
		supporter:"B"
	},
	{
		x:0.78949999999999998,
		y:0.88060000000000005,
		supporter:"B"
	},
	{
		x:0.68630000000000002,
		y:0.79379999999999995,
		supporter:"B"
	},
	{
		x:0.68089999999999995,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.68869999999999998,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.70309999999999995,
		y:0.91459999999999997,
		supporter:"B"
	},
	{
		x:0.71730000000000005,
		y:0.92390000000000005,
		supporter:"B"
	},
	{
		x:0.72629999999999995,
		y:0.88680000000000003,
		supporter:"B"
	},
	{
		x:0.74170000000000003,
		y:0.87560000000000004,
		supporter:"B"
	},
	{
		x:0.71389999999999998,
		y:0.80869999999999997,
		supporter:"B"
	},
	{
		x:0.86040000000000005,
		y:0.91400000000000003,
		supporter:"B"
	},
	{
		x:0.72750000000000004,
		y:0.87480000000000002,
		supporter:"B"
	},
	{
		x:0.85260000000000002,
		y:0.87990000000000002,
		supporter:"B"
	},
	{
		x:0.70630000000000004,
		y:0.89590000000000003,
		supporter:"B"
	},
	{
		x:0.65229999999999999,
		y:0.91020000000000001,
		supporter:"B"
	},
	{
		x:0.64900000000000002,
		y:0.86119999999999997,
		supporter:"B"
	},
	{
		x:0.70730000000000004,
		y:0.86980000000000002,
		supporter:"B"
	},
	{
		x:0.72740000000000005,
		y:0.90900000000000003,
		supporter:"B"
	},
	{
		x:0.72419999999999995,
		y:0.9103,
		supporter:"B"
	},
	{
		x:0.65510000000000002,
		y:0.90090000000000003,
		supporter:"B"
	},
	{
		x:0.72019999999999995,
		y:0.8075,
		supporter:"B"
	},
	{
		x:0.68659999999999999,
		y:0.88639999999999997,
		supporter:"B"
	},
	{
		x:0.67379999999999995,
		y:0.90910000000000002,
		supporter:"B"
	},
	{
		x:0.7591,
		y:0.88129999999999997,
		supporter:"B"
	},
	{
		x:0.67659999999999998,
		y:0.9,
		supporter:"B"
	},
	{
		x:0.71389999999999998,
		y:0.88490000000000002,
		supporter:"B"
	},
	{
		x:0.76729999999999998,
		y:0.87890000000000001,
		supporter:"B"
	},
	{
		x:0.85340000000000005,
		y:0.91269999999999996,
		supporter:"B"
	},
	{
		x:0.63109999999999999,
		y:0.92230000000000001,
		supporter:"B"
	},
	{
		x:0.76160000000000005,
		y:0.91839999999999999,
		supporter:"B"
	},
	{
		x:0.78380000000000005,
		y:0.89990000000000003,
		supporter:"B"
	},
	{
		x:0.70709999999999995,
		y:0.92059999999999997,
		supporter:"B"
	},
	{
		x:0.7419,
		y:0.93100000000000005,
		supporter:"B"
	},
	{
		x:0.71440000000000003,
		y:0.89749999999999996,
		supporter:"B"
	},
	{
		x:0.64739999999999998,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.7107,
		y:0.9224,
		supporter:"B"
	},
	{
		x:0.70340000000000003,
		y:0.90190000000000003,
		supporter:"B"
	},
	{
		x:0.63170000000000004,
		y:0.92989999999999995,
		supporter:"B"
	},
	{
		x:0.67449999999999999,
		y:0.89980000000000004,
		supporter:"B"
	},
	{
		x:0.7177,
		y:0.91490000000000005,
		supporter:"B"
	},
	{
		x:0.66139999999999999,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.6623,
		y:0.89590000000000003,
		supporter:"B"
	},
	{
		x:0.69240000000000002,
		y:0.91290000000000004,
		supporter:"B"
	},
	{
		x:0.72789999999999999,
		y:0.90339999999999998,
		supporter:"B"
	},
	{
		x:0.67449999999999999,
		y:0.91490000000000005,
		supporter:"B"
	},
	{
		x:0.68569999999999998,
		y:0.91979999999999995,
		supporter:"B"
	},
	{
		x:0.85129999999999995,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.73480000000000001,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.72240000000000004,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.77680000000000005,
		y:0.89100000000000001,
		supporter:"B"
	},
	{
		x:0.68579999999999997,
		y:0.89749999999999996,
		supporter:"B"
	},
	{
		x:0.83209999999999995,
		y:0.90629999999999999,
		supporter:"B"
	},
	{
		x:0.86060000000000003,
		y:0.90680000000000005,
		supporter:"B"
	},
	{
		x:0.64349999999999996,
		y:0.89659999999999995,
		supporter:"B"
	},
	{
		x:0.79259999999999997,
		y:0.87919999999999998,
		supporter:"B"
	},
	{
		x:0.66969999999999996,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.70750000000000002,
		y:0.91679999999999995,
		supporter:"B"
	},
	{
		x:0.86009999999999998,
		y:0.9,
		supporter:"B"
	},
	{
		x:0.65180000000000005,
		y:0.91510000000000002,
		supporter:"B"
	},
	{
		x:0.76890000000000003,
		y:0.92079999999999995,
		supporter:"B"
	},
	{
		x:0.68159999999999998,
		y:0.92979999999999996,
		supporter:"B"
	},
	{
		x:0.70489999999999997,
		y:0.88019999999999998,
		supporter:"B"
	},
	{
		x:0.70009999999999994,
		y:0.8841,
		supporter:"B"
	},
	{
		x:0.66120000000000001,
		y:0.92110000000000003,
		supporter:"B"
	},
	{
		x:0.77100000000000002,
		y:0.89810000000000001,
		supporter:"B"
	},
	{
		x:0.68620000000000003,
		y:0.91149999999999998,
		supporter:"B"
	},
	{
		x:0.65859999999999996,
		y:0.89829999999999999,
		supporter:"B"
	},
	{
		x:0.66049999999999998,
		y:0.91049999999999998,
		supporter:"B"
	},
	{
		x:0.6361,
		y:0.90949999999999998,
		supporter:"B"
	},
	{
		x:0.66320000000000001,
		y:0.9234,
		supporter:"B"
	},
	{
		x:0.70099999999999996,
		y:0.88229999999999997,
		supporter:"B"
	},
	{
		x:0.64949999999999997,
		y:0.90090000000000003,
		supporter:"B"
	},
	{
		x:0.65749999999999997,
		y:0.91369999999999996,
		supporter:"B"
	},
	{
		x:0.67859999999999998,
		y:0.92949999999999999,
		supporter:"B"
	},
	{
		x:0.68979999999999997,
		y:0.90849999999999997,
		supporter:"B"
	},
	{
		x:0.70140000000000002,
		y:0.91359999999999997,
		supporter:"B"
	},
	{
		x:0.70420000000000005,
		y:0.92349999999999999,
		supporter:"B"
	},
	{
		x:0.6552,
		y:0.80669999999999997,
		supporter:"B"
	},
	{
		x:0.80569999999999997,
		y:0.89459999999999995,
		supporter:"B"
	},
	{
		x:0.63029999999999997,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.77690000000000003,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.75249999999999995,
		y:0.84419999999999995,
		supporter:"B"
	},
	{
		x:0.66879999999999995,
		y:0.90490000000000004,
		supporter:"B"
	},
	{
		x:0.73819999999999997,
		y:0.875,
		supporter:"B"
	},
	{
		x:0.73470000000000002,
		y:0.91010000000000002,
		supporter:"B"
	},
	{
		x:0.86309999999999998,
		y:0.79110000000000003,
		supporter:"B"
	},
	{
		x:0.66669999999999996,
		y:0.92520000000000002,
		supporter:"B"
	},
	{
		x:0.69810000000000005,
		y:0.79469999999999996,
		supporter:"B"
	},
	{
		x:0.71660000000000001,
		y:0.8931,
		supporter:"B"
	},
	{
		x:0.74850000000000005,
		y:0.92100000000000004,
		supporter:"B"
	},
	{
		x:0.85109999999999997,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.75800000000000001,
		y:0.80500000000000005,
		supporter:"B"
	},
	{
		x:0.65410000000000001,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.67730000000000001,
		y:0.89700000000000002,
		supporter:"B"
	},
	{
		x:0.82779999999999998,
		y:0.89470000000000005,
		supporter:"B"
	},
	{
		x:0.94369999999999998,
		y:0.90169999999999995,
		supporter:"B"
	},
	{
		x:0.65629999999999999,
		y:0.90810000000000002,
		supporter:"B"
	},
	{
		x:0.66539999999999999,
		y:0.92130000000000001,
		supporter:"B"
	},
	{
		x:0.89539999999999997,
		y:0.9103,
		supporter:"B"
	},
	{
		x:0.66620000000000001,
		y:0.9093,
		supporter:"B"
	},
	{
		x:0.81759999999999999,
		y:0.87409999999999999,
		supporter:"B"
	},
	{
		x:0.66969999999999996,
		y:0.88009999999999999,
		supporter:"B"
	},
	{
		x:0.68079999999999996,
		y:0.91639999999999999,
		supporter:"B"
	},
	{
		x:0.68779999999999997,
		y:0.90359999999999996,
		supporter:"B"
	},
	{
		x:0.85399999999999998,
		y:0.91600000000000004,
		supporter:"B"
	},
	{
		x:0.68559999999999999,
		y:0.93179999999999996,
		supporter:"B"
	},
	{
		x:0.67300000000000004,
		y:0.91669999999999996,
		supporter:"B"
	},
	{
		x:0.69040000000000001,
		y:0.90900000000000003,
		supporter:"B"
	},
	{
		x:0.63280000000000003,
		y:0.90200000000000002,
		supporter:"B"
	},
	{
		x:0.6724,
		y:0.90410000000000001,
		supporter:"B"
	},
	{
		x:0.81930000000000003,
		y:0.88539999999999996,
		supporter:"B"
	},
	{
		x:0.67710000000000004,
		y:0.91410000000000002,
		supporter:"B"
	},
	{
		x:0.76680000000000004,
		y:0.91969999999999996,
		supporter:"B"
	},
	{
		x:0.69679999999999997,
		y:0.9163,
		supporter:"B"
	},
	{
		x:0.66059999999999997,
		y:0.92120000000000002,
		supporter:"B"
	},
	{
		x:0.66739999999999999,
		y:0.88460000000000005,
		supporter:"B"
	},
	{
		x:0.7591,
		y:0.92469999999999997,
		supporter:"B"
	},
	{
		x:0.68700000000000006,
		y:0.88419999999999999,
		supporter:"B"
	},
	{
		x:0.64729999999999999,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.72729999999999995,
		y:0.79459999999999997,
		supporter:"B"
	},
	{
		x:0.66210000000000002,
		y:0.88090000000000002,
		supporter:"B"
	},
	{
		x:0.64219999999999999,
		y:0.91869999999999996,
		supporter:"B"
	},
	{
		x:0.69979999999999998,
		y:0.73470000000000002,
		supporter:"B"
	},
	{
		x:0.85899999999999999,
		y:0.80059999999999998,
		supporter:"B"
	},
	{
		x:0.65259999999999996,
		y:0.91830000000000001,
		supporter:"B"
	},
	{
		x:0.6845,
		y:0.78820000000000001,
		supporter:"B"
	},
	{
		x:0.66039999999999999,
		y:0.89759999999999995,
		supporter:"B"
	},
	{
		x:0.65100000000000002,
		y:0.90849999999999997,
		supporter:"B"
	},
	{
		x:0.70609999999999995,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.8276,
		y:0.8921,
		supporter:"B"
	},
	{
		x:0.71899999999999997,
		y:0.92290000000000005,
		supporter:"B"
	},
	{
		x:0.65410000000000001,
		y:0.89990000000000003,
		supporter:"B"
	},
	{
		x:0.77900000000000003,
		y:0.88970000000000005,
		supporter:"B"
	},
	{
		x:0.65439999999999998,
		y:0.93300000000000005,
		supporter:"B"
	},
	{
		x:0.74,
		y:0.91310000000000002,
		supporter:"B"
	},
	{
		x:0.64129999999999998,
		y:0.92700000000000005,
		supporter:"B"
	},
	{
		x:0.86140000000000005,
		y:0.90459999999999996,
		supporter:"B"
	},
	{
		x:0.63890000000000002,
		y:0.9244,
		supporter:"B"
	},
	{
		x:0.78059999999999996,
		y:0.92549999999999999,
		supporter:"B"
	},
	{
		x:0.63270000000000004,
		y:0.90210000000000001,
		supporter:"B"
	},
	{
		x:0.70589999999999997,
		y:0.91069999999999995,
		supporter:"B"
	},
	{
		x:0.83489999999999998,
		y:0.88880000000000003,
		supporter:"B"
	},
	{
		x:0.66620000000000001,
		y:0.91169999999999995,
		supporter:"B"
	},
	{
		x:0.74319999999999997,
		y:0.79349999999999998,
		supporter:"B"
	},
	{
		x:0.69420000000000004,
		y:0.89400000000000002,
		supporter:"B"
	},
	{
		x:0.65159999999999996,
		y:0.91900000000000004,
		supporter:"B"
	},
	{
		x:0.63190000000000002,
		y:0.79869999999999997,
		supporter:"B"
	},
	{
		x:0.65529999999999999,
		y:0.80740000000000001,
		supporter:"B"
	},
	{
		x:0.6381,
		y:0.90900000000000003,
		supporter:"B"
	},
	{
		x:0.78949999999999998,
		y:0.90469999999999995,
		supporter:"B"
	},
	{
		x:0.86509999999999998,
		y:0.90349999999999997,
		supporter:"B"
	},
	{
		x:0.79210000000000003,
		y:0.78800000000000003,
		supporter:"B"
	},
	{
		x:0.83879999999999999,
		y:0.80120000000000002,
		supporter:"B"
	},
	{
		x:0.76800000000000002,
		y:0.87439999999999996,
		supporter:"B"
	},
	{
		x:0.76519999999999999,
		y:0.91790000000000005,
		supporter:"B"
	},
	{
		x:0.68,
		y:0.90310000000000001,
		supporter:"B"
	},
	{
		x:0.87990000000000002,
		y:0.90600000000000003,
		supporter:"B"
	},
	{
		x:0.72770000000000001,
		y:0.79110000000000003,
		supporter:"B"
	},
	{
		x:0.68910000000000005,
		y:0.79630000000000001,
		supporter:"B"
	},
	{
		x:0.76729999999999998,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.754,
		y:0.91649999999999998,
		supporter:"B"
	},
	{
		x:0.81589999999999996,
		y:0.88319999999999999,
		supporter:"B"
	},
	{
		x:0.65710000000000002,
		y:0.79549999999999998,
		supporter:"B"
	},
	{
		x:0.69569999999999999,
		y:0.92430000000000001,
		supporter:"B"
	},
	{
		x:0.63429999999999997,
		y:0.91500000000000004,
		supporter:"B"
	},
	{
		x:0.70789999999999997,
		y:0.92479999999999996,
		supporter:"B"
	},
	{
		x:0.7712,
		y:0.88680000000000003,
		supporter:"B"
	},
	{
		x:0.72,
		y:0.92190000000000005,
		supporter:"B"
	},
	{
		x:0.64739999999999998,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.63949999999999996,
		y:0.91459999999999997,
		supporter:"B"
	},
	{
		x:0.64959999999999996,
		y:0.77580000000000005,
		supporter:"B"
	},
	{
		x:0.64339999999999997,
		y:0.90869999999999995,
		supporter:"B"
	},
	{
		x:0.74309999999999998,
		y:0.89829999999999999,
		supporter:"B"
	},
	{
		x:0.70289999999999997,
		y:0.9224,
		supporter:"B"
	},
	{
		x:0.67510000000000003,
		y:0.92569999999999997,
		supporter:"B"
	},
	{
		x:0.68359999999999999,
		y:0.90969999999999995,
		supporter:"B"
	},
	{
		x:0.71779999999999999,
		y:0.90559999999999996,
		supporter:"B"
	},
	{
		x:0.6996,
		y:0.92500000000000004,
		supporter:"B"
	},
	{
		x:0.68589999999999995,
		y:0.88629999999999998,
		supporter:"B"
	},
	{
		x:0.70850000000000002,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.77049999999999996,
		y:0.89390000000000003,
		supporter:"B"
	},
	{
		x:0.72089999999999999,
		y:0.90620000000000001,
		supporter:"B"
	},
	{
		x:0.66820000000000002,
		y:0.90159999999999996,
		supporter:"B"
	},
	{
		x:0.71160000000000001,
		y:0.8952,
		supporter:"B"
	},
	{
		x:0.76880000000000004,
		y:0.88739999999999997,
		supporter:"B"
	},
	{
		x:0.72489999999999999,
		y:0.91390000000000005,
		supporter:"B"
	},
	{
		x:0.85309999999999997,
		y:0.86099999999999999,
		supporter:"B"
	},
	{
		x:0.66210000000000002,
		y:0.90549999999999997,
		supporter:"B"
	},
	{
		x:0.70950000000000002,
		y:0.87739999999999996,
		supporter:"B"
	},
	{
		x:0.67220000000000002,
		y:0.91930000000000001,
		supporter:"B"
	},
	{
		x:0.69789999999999996,
		y:0.91020000000000001,
		supporter:"B"
	},
	{
		x:0.69410000000000005,
		y:0.92230000000000001,
		supporter:"B"
	},
	{
		x:0.67049999999999998,
		y:0.92169999999999996,
		supporter:"B"
	},
	{
		x:0.6573,
		y:0.9103,
		supporter:"B"
	},
	{
		x:0.72799999999999998,
		y:0.91369999999999996,
		supporter:"B"
	},
	{
		x:0.64829999999999999,
		y:0.91859999999999997,
		supporter:"B"
	},
	{
		x:0.66020000000000001,
		y:0.92090000000000005,
		supporter:"B"
	},
	{
		x:0.89419999999999999,
		y:0.91620000000000001,
		supporter:"B"
	},
	{
		x:0.67469999999999997,
		y:0.92810000000000004,
		supporter:"B"
	},
	{
		x:0.78990000000000005,
		y:0.89370000000000005,
		supporter:"B"
	},
	{
		x:0.67549999999999999,
		y:0.92220000000000002,
		supporter:"B"
	},
	{
		x:0.76749999999999996,
		y:0.88019999999999998,
		supporter:"B"
	},
	{
		x:0.6391,
		y:0.91710000000000003,
		supporter:"B"
	},
	{
		x:0.66159999999999997,
		y:0.78790000000000004,
		supporter:"B"
	},
	{
		x:0.68140000000000001,
		y:0.92059999999999997,
		supporter:"B"
	},
	{
		x:0.66410000000000002,
		y:0.90790000000000004,
		supporter:"B"
	},
	{
		x:0.63849999999999996,
		y:0.92420000000000002,
		supporter:"B"
	},
	{
		x:0.72219999999999995,
		y:0.79039999999999999,
		supporter:"B"
	},
	{
		x:0.6633,
		y:0.90059999999999996,
		supporter:"B"
	},
	{
		x:0.81489999999999996,
		y:0.88439999999999996,
		supporter:"B"
	},
	{
		x:0.64880000000000004,
		y:0.89959999999999996,
		supporter:"B"
	},
	{
		x:0.71509999999999996,
		y:0.93220000000000003,
		supporter:"B"
	},
	{
		x:0.68679999999999997,
		y:0.9204,
		supporter:"B"
	},
	{
		x:0.64839999999999998,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.74350000000000005,
		y:0.87690000000000001,
		supporter:"B"
	},
	{
		x:0.6431,
		y:0.93069999999999997,
		supporter:"B"
	},
	{
		x:0.69750000000000001,
		y:0.87329999999999997,
		supporter:"B"
	},
	{
		x:0.68320000000000003,
		y:0.79259999999999997,
		supporter:"B"
	},
	{
		x:0.75280000000000002,
		y:0.92210000000000003,
		supporter:"B"
	},
	{
		x:0.65059999999999996,
		y:0.91620000000000001,
		supporter:"B"
	},
	{
		x:0.77610000000000001,
		y:0.89300000000000002,
		supporter:"B"
	},
	{
		x:0.71240000000000003,
		y:0.92279999999999995,
		supporter:"B"
	},
	{
		x:0.68140000000000001,
		y:0.91669999999999996,
		supporter:"B"
	},
	{
		x:0.66659999999999997,
		y:0.89880000000000004,
		supporter:"B"
	},
	{
		x:0.68430000000000002,
		y:0.9022,
		supporter:"B"
	},
	{
		x:0.85109999999999997,
		y:0.90920000000000001,
		supporter:"B"
	},
	{
		x:0.73470000000000002,
		y:0.90939999999999999,
		supporter:"B"
	},
	{
		x:0.65680000000000005,
		y:0.92869999999999997,
		supporter:"B"
	},
	{
		x:0.70799999999999996,
		y:0.88,
		supporter:"B"
	},
	{
		x:0.70099999999999996,
		y:0.90349999999999997,
		supporter:"B"
	},
	{
		x:0.6391,
		y:0.9234,
		supporter:"B"
	},
	{
		x:0.64029999999999998,
		y:0.89780000000000004,
		supporter:"B"
	},
	{
		x:0.71940000000000004,
		y:0.92290000000000005,
		supporter:"B"
	},
	{
		x:0.68879999999999997,
		y:0.92779999999999996,
		supporter:"B"
	},
	{
		x:0.6613,
		y:0.94350000000000001,
		supporter:"B"
	},
	{
		x:0.67949999999999999,
		y:0.90869999999999995,
		supporter:"B"
	},
	{
		x:0.68459999999999999,
		y:0.90180000000000005,
		supporter:"B"
	},
	{
		x:0.85270000000000001,
		y:0.80600000000000005,
		supporter:"B"
	},
	{
		x:0.65810000000000002,
		y:0.84670000000000001,
		supporter:"B"
	},
	{
		x:0.65600000000000003,
		y:0.79669999999999996,
		supporter:"B"
	},
	{
		x:0.72230000000000005,
		y:0.88380000000000003,
		supporter:"B"
	},
	{
		x:0.74490000000000001,
		y:0.76300000000000001,
		supporter:"B"
	},
	{
		x:0.65669999999999995,
		y:0.89910000000000001,
		supporter:"B"
	},
	{
		x:0.87239999999999995,
		y:0.91100000000000003,
		supporter:"B"
	},
	{
		x:0.73029999999999995,
		y:0.90539999999999998,
		supporter:"B"
	},
	{
		x:0.63849999999999996,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.81579999999999997,
		y:0.89319999999999999,
		supporter:"B"
	},
	{
		x:0.70009999999999994,
		y:0.91839999999999999,
		supporter:"B"
	},
	{
		x:0.70040000000000002,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.79010000000000002,
		y:0.79730000000000001,
		supporter:"B"
	},
	{
		x:0.76580000000000004,
		y:0.91359999999999997,
		supporter:"B"
	},
	{
		x:0.65639999999999998,
		y:0.91869999999999996,
		supporter:"B"
	},
	{
		x:0.66539999999999999,
		y:0.92700000000000005,
		supporter:"B"
	},
	{
		x:0.73460000000000003,
		y:0.89649999999999996,
		supporter:"B"
	},
	{
		x:0.66379999999999995,
		y:0.91749999999999998,
		supporter:"B"
	},
	{
		x:0.85189999999999999,
		y:0.80559999999999998,
		supporter:"B"
	},
	{
		x:0.76129999999999998,
		y:0.89170000000000005,
		supporter:"B"
	},
	{
		x:0.70889999999999997,
		y:0.92210000000000003,
		supporter:"B"
	},
	{
		x:0.70809999999999995,
		y:0.8982,
		supporter:"B"
	},
	{
		x:0.82579999999999998,
		y:0.76890000000000003,
		supporter:"B"
	},
	{
		x:0.79920000000000002,
		y:0.78710000000000002,
		supporter:"B"
	},
	{
		x:0.78190000000000004,
		y:0.88580000000000003,
		supporter:"B"
	},
	{
		x:0.6421,
		y:0.91559999999999997,
		supporter:"B"
	},
	{
		x:0.67249999999999999,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.6865,
		y:0.92149999999999999,
		supporter:"B"
	},
	{
		x:0.63170000000000004,
		y:0.91200000000000003,
		supporter:"B"
	},
	{
		x:0.65759999999999996,
		y:0.92259999999999998,
		supporter:"B"
	},
	{
		x:0.69540000000000002,
		y:0.89200000000000002,
		supporter:"B"
	},
	{
		x:0.72940000000000005,
		y:0.91279999999999994,
		supporter:"B"
	},
	{
		x:0.64970000000000006,
		y:0.85399999999999998,
		supporter:"B"
	},
	{
		x:0.65159999999999996,
		y:0.92010000000000003,
		supporter:"B"
	},
	{
		x:0.63739999999999997,
		y:0.78049999999999997,
		supporter:"B"
	},
	{
		x:0.88729999999999998,
		y:0.91879999999999995,
		supporter:"B"
	},
	{
		x:0.67820000000000003,
		y:0.90839999999999999,
		supporter:"B"
	},
	{
		x:0.73819999999999997,
		y:0.55179999999999996,
		supporter:"B"
	},
	{
		x:0.68810000000000004,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.72209999999999996,
		y:0.90369999999999995,
		supporter:"B"
	},
	{
		x:0.78200000000000003,
		y:0.88619999999999999,
		supporter:"B"
	},
	{
		x:0.69259999999999999,
		y:0.86339999999999995,
		supporter:"B"
	},
	{
		x:0.71160000000000001,
		y:0.91900000000000004,
		supporter:"B"
	},
	{
		x:0.66269999999999996,
		y:0.90349999999999997,
		supporter:"B"
	},
	{
		x:0.65359999999999996,
		y:0.7903,
		supporter:"B"
	},
	{
		x:0.72789999999999999,
		y:0.92400000000000004,
		supporter:"B"
	},
	{
		x:0.63100000000000001,
		y:0.90239999999999998,
		supporter:"B"
	},
	{
		x:0.67230000000000001,
		y:0.90969999999999995,
		supporter:"B"
	},
	{
		x:0.72330000000000005,
		y:0.88149999999999995,
		supporter:"B"
	},
	{
		x:0.74119999999999997,
		y:0.85980000000000001,
		supporter:"B"
	},
	{
		x:0.65949999999999998,
		y:0.8992,
		supporter:"B"
	},
	{
		x:0.64780000000000004,
		y:0.92500000000000004,
		supporter:"B"
	},
	{
		x:0.67130000000000001,
		y:0.80579999999999996,
		supporter:"B"
	},
	{
		x:0.66439999999999999,
		y:0.92930000000000001,
		supporter:"B"
	},
	{
		x:0.69159999999999999,
		y:0.88429999999999997,
		supporter:"B"
	},
	{
		x:0.64170000000000005,
		y:0.77,
		supporter:"B"
	},
	{
		x:0.69579999999999997,
		y:0.93489999999999995,
		supporter:"B"
	},
	{
		x:0.86229999999999996,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.68610000000000004,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.65690000000000004,
		y:0.91549999999999998,
		supporter:"B"
	},
	{
		x:0.72589999999999999,
		y:0.90510000000000002,
		supporter:"B"
	},
	{
		x:0.66169999999999995,
		y:0.90739999999999998,
		supporter:"B"
	},
	{
		x:0.64559999999999995,
		y:0.89859999999999995,
		supporter:"B"
	},
	{
		x:0.70630000000000004,
		y:0.77880000000000005,
		supporter:"B"
	},
	{
		x:0.67579999999999996,
		y:0.91169999999999995,
		supporter:"B"
	},
	{
		x:0.76639999999999997,
		y:0.87819999999999998,
		supporter:"B"
	},
	{
		x:0.84109999999999996,
		y:0.94910000000000005,
		supporter:"B"
	},
	{
		x:0.63170000000000004,
		y:0.9204,
		supporter:"B"
	},
	{
		x:0.73419999999999996,
		y:0.91479999999999995,
		supporter:"B"
	},
	{
		x:0.75019999999999998,
		y:0.94769999999999999,
		supporter:"B"
	},
	{
		x:0.69910000000000005,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.85580000000000001,
		y:0.91320000000000001,
		supporter:"B"
	},
	{
		x:0.6492,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.66180000000000005,
		y:0.90580000000000005,
		supporter:"B"
	},
	{
		x:0.67469999999999997,
		y:0.92769999999999997,
		supporter:"B"
	},
	{
		x:0.66120000000000001,
		y:0.91810000000000003,
		supporter:"B"
	},
	{
		x:0.70650000000000002,
		y:0.89949999999999997,
		supporter:"B"
	},
	{
		x:0.73709999999999998,
		y:0.7702,
		supporter:"B"
	},
	{
		x:0.63360000000000005,
		y:0.91279999999999994,
		supporter:"B"
	},
	{
		x:0.76959999999999995,
		y:0.88,
		supporter:"B"
	},
	{
		x:0.70409999999999995,
		y:0.93030000000000002,
		supporter:"B"
	},
	{
		x:0.68220000000000003,
		y:0.90390000000000004,
		supporter:"B"
	},
	{
		x:0.66159999999999997,
		y:0.9163,
		supporter:"B"
	},
	{
		x:0.65890000000000004,
		y:0.91649999999999998,
		supporter:"B"
	},
	{
		x:0.63729999999999998,
		y:0.9113,
		supporter:"B"
	},
	{
		x:0.65329999999999999,
		y:0.91679999999999995,
		supporter:"B"
	},
	{
		x:0.67530000000000001,
		y:0.91469999999999996,
		supporter:"B"
	},
	{
		x:0.73299999999999998,
		y:0.76700000000000002,
		supporter:"B"
	},
	{
		x:0.91120000000000001,
		y:0.90049999999999997,
		supporter:"B"
	},
	{
		x:0.79600000000000004,
		y:0.91200000000000003,
		supporter:"B"
	},
	{
		x:0.70250000000000001,
		y:0.89329999999999998,
		supporter:"B"
	},
	{
		x:0.70120000000000005,
		y:0.89639999999999997,
		supporter:"B"
	},
	{
		x:0.69810000000000005,
		y:0.84670000000000001,
		supporter:"B"
	},
	{
		x:0.76780000000000004,
		y:0.87770000000000004,
		supporter:"B"
	},
	{
		x:0.67190000000000005,
		y:0.90210000000000001,
		supporter:"B"
	},
	{
		x:0.77839999999999998,
		y:0.91849999999999998,
		supporter:"B"
	},
	{
		x:0.88690000000000002,
		y:0.89270000000000005,
		supporter:"B"
	},
	{
		x:0.66210000000000002,
		y:0.91510000000000002,
		supporter:"B"
	},
	{
		x:0.67479999999999996,
		y:0.9304,
		supporter:"B"
	},
	{
		x:0.65539999999999998,
		y:0.83950000000000002,
		supporter:"B"
	},
	{
		x:0.6593,
		y:0.81169999999999998,
		supporter:"B"
	},
	{
		x:0.65849999999999997,
		y:0.89349999999999996,
		supporter:"B"
	},
	{
		x:0.66869999999999996,
		y:0.7833,
		supporter:"B"
	},
	{
		x:0.63729999999999998,
		y:0.92759999999999998,
		supporter:"B"
	},
	{
		x:0.72929999999999995,
		y:0.80549999999999999,
		supporter:"B"
	},
	{
		x:0.64700000000000002,
		y:0.91310000000000002,
		supporter:"B"
	},
	{
		x:0.66830000000000001,
		y:0.9103,
		supporter:"B"
	},
	{
		x:0.70679999999999998,
		y:0.8982,
		supporter:"B"
	},
	{
		x:0.64839999999999998,
		y:0.81969999999999998,
		supporter:"B"
	},
	{
		x:0.78220000000000001,
		y:0.9224,
		supporter:"B"
	},
	{
		x:0.6895,
		y:0.91339999999999999,
		supporter:"B"
	},
	{
		x:0.6573,
		y:0.85350000000000004,
		supporter:"B"
	},
	{
		x:0.74529999999999996,
		y:0.91310000000000002,
		supporter:"B"
	},
	{
		x:0.63290000000000002,
		y:0.9234,
		supporter:"B"
	},
	{
		x:0.68049999999999999,
		y:0.92200000000000004,
		supporter:"B"
	},
	{
		x:0.72989999999999999,
		y:0.9103,
		supporter:"B"
	},
	{
		x:0.63190000000000002,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.72529999999999994,
		y:0.90539999999999998,
		supporter:"B"
	},
	{
		x:0.85319999999999996,
		y:0.82530000000000003,
		supporter:"B"
	},
	{
		x:0.79949999999999999,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.66790000000000005,
		y:0.8024,
		supporter:"B"
	},
	{
		x:0.7097,
		y:0.92120000000000002,
		supporter:"B"
	},
	{
		x:0.68269999999999997,
		y:0.91900000000000004,
		supporter:"B"
	},
	{
		x:0.85489999999999999,
		y:0.91449999999999998,
		supporter:"B"
	},
	{
		x:0.6593,
		y:0.92820000000000003,
		supporter:"B"
	},
	{
		x:0.82769999999999999,
		y:0.94830000000000003,
		supporter:"B"
	},
	{
		x:0.69310000000000005,
		y:0.91720000000000002,
		supporter:"B"
	},
	{
		x:0.73899999999999999,
		y:0.91039999999999999,
		supporter:"B"
	},
	{
		x:0.87780000000000002,
		y:0.89610000000000001,
		supporter:"B"
	},
	{
		x:0.82769999999999999,
		y:0.91410000000000002,
		supporter:"B"
	},
	{
		x:0.67569999999999997,
		y:0.89959999999999996,
		supporter:"B"
	},
	{
		x:0.72150000000000003,
		y:0.91849999999999998,
		supporter:"B"
	},
	{
		x:0.68240000000000001,
		y:0.93920000000000003,
		supporter:"B"
	},
	{
		x:0.72709999999999997,
		y:0.78410000000000002,
		supporter:"B"
	},
	{
		x:0.71120000000000005,
		y:0.89859999999999995,
		supporter:"B"
	},
	{
		x:0.65720000000000001,
		y:0.91269999999999996,
		supporter:"B"
	},
	{
		x:0.75409999999999999,
		y:0.9234,
		supporter:"B"
	},
	{
		x:0.69740000000000002,
		y:0.92359999999999998,
		supporter:"B"
	},
	{
		x:0.69140000000000001,
		y:0.90749999999999997,
		supporter:"B"
	},
	{
		x:0.67689999999999995,
		y:0.91620000000000001,
		supporter:"B"
	},
	{
		x:0.64290000000000003,
		y:0.9335,
		supporter:"B"
	},
	{
		x:0.71430000000000005,
		y:0.91830000000000001,
		supporter:"B"
	},
	{
		x:0.79849999999999999,
		y:0.88880000000000003,
		supporter:"B"
	},
	{
		x:0.72389999999999999,
		y:0.90300000000000002,
		supporter:"B"
	},
	{
		x:0.63339999999999996,
		y:0.91359999999999997,
		supporter:"B"
	},
	{
		x:0.66830000000000001,
		y:0.89790000000000003,
		supporter:"B"
	},
	{
		x:0.6704,
		y:0.92569999999999997,
		supporter:"B"
	},
	{
		x:0.86529999999999996,
		y:0.90469999999999995,
		supporter:"B"
	},
	{
		x:0.76319999999999999,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.65359999999999996,
		y:0.92149999999999999,
		supporter:"B"
	},
	{
		x:0.64829999999999999,
		y:0.91590000000000005,
		supporter:"B"
	},
	{
		x:0.73009999999999997,
		y:0.91049999999999998,
		supporter:"B"
	},
	{
		x:0.73019999999999996,
		y:0.90569999999999995,
		supporter:"B"
	},
	{
		x:0.80079999999999996,
		y:0.89700000000000002,
		supporter:"B"
	},
	{
		x:0.89270000000000005,
		y:0.91830000000000001,
		supporter:"B"
	},
	{
		x:0.70279999999999998,
		y:0.88029999999999997,
		supporter:"B"
	},
	{
		x:0.67120000000000002,
		y:0.91120000000000001,
		supporter:"B"
	},
	{
		x:0.87860000000000005,
		y:0.91239999999999999,
		supporter:"B"
	},
	{
		x:0.66059999999999997,
		y:0.9022,
		supporter:"B"
	},
	{
		x:0.86709999999999998,
		y:0.80830000000000002,
		supporter:"B"
	},
	{
		x:0.63639999999999997,
		y:0.92930000000000001,
		supporter:"B"
	},
	{
		x:0.72189999999999999,
		y:0.93279999999999996,
		supporter:"B"
	},
	{
		x:0.67149999999999999,
		y:0.8085,
		supporter:"B"
	},
	{
		x:0.70630000000000004,
		y:0.92179999999999995,
		supporter:"B"
	},
	{
		x:0.88880000000000003,
		y:0.81399999999999995,
		supporter:"B"
	},
	{
		x:0.63970000000000005,
		y:0.91859999999999997,
		supporter:"B"
	},
	{
		x:0.76470000000000005,
		y:0.92720000000000002,
		supporter:"B"
	},
	{
		x:0.86770000000000003,
		y:0.90039999999999998,
		supporter:"B"
	},
	{
		x:0.64610000000000001,
		y:0.879,
		supporter:"B"
	},
	{
		x:0.74370000000000003,
		y:0.92120000000000002,
		supporter:"B"
	},
	{
		x:0.70420000000000005,
		y:0.9012,
		supporter:"B"
	},
	{
		x:0.71860000000000002,
		y:0.88490000000000002,
		supporter:"B"
	},
	{
		x:0.87629999999999997,
		y:0.90739999999999998,
		supporter:"B"
	},
	{
		x:0.69510000000000005,
		y:0.90029999999999999,
		supporter:"B"
	},
	{
		x:0.87360000000000004,
		y:0.91069999999999995,
		supporter:"B"
	},
	{
		x:0.7621,
		y:0.86939999999999995,
		supporter:"B"
	},
	{
		x:0.65400000000000003,
		y:0.89959999999999996,
		supporter:"B"
	},
	{
		x:0.86719999999999997,
		y:0.91059999999999997,
		supporter:"B"
	},
	{
		x:0.65500000000000003,
		y:0.91620000000000001,
		supporter:"B"
	},
	{
		x:0.86619999999999997,
		y:0.90569999999999995,
		supporter:"B"
	},
	{
		x:0.77529999999999999,
		y:0.88670000000000004,
		supporter:"B"
	},
	{
		x:0.6905,
		y:0.92100000000000004,
		supporter:"B"
	},
	{
		x:0.7268,
		y:0.91830000000000001,
		supporter:"B"
	},
	{
		x:0.64849999999999997,
		y:0.91639999999999999,
		supporter:"B"
	},
	{
		x:0.76729999999999998,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.68500000000000005,
		y:0.92720000000000002,
		supporter:"B"
	},
	{
		x:0.75219999999999998,
		y:0.89610000000000001,
		supporter:"B"
	},
	{
		x:0.69489999999999996,
		y:0.79549999999999998,
		supporter:"B"
	},
	{
		x:0.69799999999999995,
		y:0.90459999999999996,
		supporter:"B"
	},
	{
		x:0.73799999999999999,
		y:0.8881,
		supporter:"B"
	},
	{
		x:0.77569999999999995,
		y:0.86819999999999997,
		supporter:"B"
	},
	{
		x:0.72209999999999996,
		y:0.90780000000000005,
		supporter:"B"
	},
	{
		x:0.68359999999999999,
		y:0.89549999999999996,
		supporter:"B"
	},
	{
		x:0.70960000000000001,
		y:0.89990000000000003,
		supporter:"B"
	},
	{
		x:0.64800000000000002,
		y:0.91559999999999997,
		supporter:"B"
	},
	{
		x:0.70579999999999998,
		y:0.89559999999999995,
		supporter:"B"
	},
	{
		x:0.73029999999999995,
		y:0.9133,
		supporter:"B"
	},
	{
		x:0.72719999999999996,
		y:0.88280000000000003,
		supporter:"B"
	},
	{
		x:0.64039999999999997,
		y:0.82179999999999997,
		supporter:"B"
	},
	{
		x:0.74080000000000001,
		y:0.91569999999999996,
		supporter:"B"
	},
	{
		x:0.66469999999999996,
		y:0.90529999999999999,
		supporter:"B"
	},
	{
		x:0.8518,
		y:0.89600000000000002,
		supporter:"B"
	},
	{
		x:0.74819999999999998,
		y:0.91369999999999996,
		supporter:"B"
	},
	{
		x:0.67989999999999995,
		y:0.91439999999999999,
		supporter:"B"
	},
	{
		x:0.65810000000000002,
		y:0.91749999999999998,
		supporter:"B"
	},
	{
		x:0.67369999999999997,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.66059999999999997,
		y:0.8972,
		supporter:"B"
	},
	{
		x:0.88319999999999999,
		y:0.90690000000000004,
		supporter:"B"
	},
	{
		x:0.69130000000000003,
		y:0.92400000000000004,
		supporter:"B"
	},
	{
		x:0.87480000000000002,
		y:0.90349999999999997,
		supporter:"B"
	},
	{
		x:0.67010000000000003,
		y:0.79590000000000005,
		supporter:"B"
	},
	{
		x:0.63029999999999997,
		y:0.91,
		supporter:"B"
	},
	{
		x:0.6431,
		y:0.9083,
		supporter:"B"
	},
	{
		x:0.63929999999999998,
		y:0.90980000000000005,
		supporter:"B"
	},
	{
		x:0.64610000000000001,
		y:0.89649999999999996,
		supporter:"B"
	},
	{
		x:0.63849999999999996,
		y:0.92100000000000004,
		supporter:"B"
	},
	{
		x:0.63449999999999995,
		y:0.78959999999999997,
		supporter:"B"
	},
	{
		x:0.77480000000000004,
		y:0.91269999999999996,
		supporter:"B"
	},
	{
		x:0.77759999999999996,
		y:0.88690000000000002,
		supporter:"B"
	},
	{
		x:0.72650000000000003,
		y:0.87360000000000004,
		supporter:"B"
	},
	{
		x:0.70240000000000002,
		y:0.91769999999999996,
		supporter:"B"
	},
	{
		x:0.64880000000000004,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.78420000000000001,
		y:0.88929999999999998,
		supporter:"B"
	},
	{
		x:0.65129999999999999,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.7349,
		y:0.9173,
		supporter:"B"
	},
	{
		x:0.70369999999999999,
		y:0.90049999999999997,
		supporter:"B"
	},
	{
		x:0.65620000000000001,
		y:0.77749999999999997,
		supporter:"B"
	},
	{
		x:0.72709999999999997,
		y:0.90780000000000005,
		supporter:"B"
	},
	{
		x:0.68730000000000002,
		y:0.92290000000000005,
		supporter:"B"
	},
	{
		x:0.65980000000000005,
		y:0.91500000000000004,
		supporter:"B"
	},
	{
		x:0.65190000000000003,
		y:0.92110000000000003,
		supporter:"B"
	},
	{
		x:0.70399999999999996,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.68979999999999997,
		y:0.88370000000000004,
		supporter:"B"
	},
	{
		x:0.70740000000000003,
		y:0.92510000000000003,
		supporter:"B"
	},
	{
		x:0.63400000000000001,
		y:0.91379999999999995,
		supporter:"B"
	},
	{
		x:0.87380000000000002,
		y:0.91049999999999998,
		supporter:"B"
	},
	{
		x:0.6452,
		y:0.91149999999999998,
		supporter:"B"
	},
	{
		x:0.68879999999999997,
		y:0.89449999999999996,
		supporter:"B"
	},
	{
		x:0.79249999999999998,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.66320000000000001,
		y:0.90790000000000004,
		supporter:"B"
	},
	{
		x:0.82489999999999997,
		y:0.9466,
		supporter:"B"
	},
	{
		x:0.65900000000000003,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.70660000000000001,
		y:0.89339999999999997,
		supporter:"B"
	},
	{
		x:0.70579999999999998,
		y:0.91910000000000003,
		supporter:"B"
	},
	{
		x:0.88,
		y:0.91169999999999995,
		supporter:"B"
	},
	{
		x:0.69259999999999999,
		y:0.87890000000000001,
		supporter:"B"
	},
	{
		x:0.64629999999999999,
		y:0.80579999999999996,
		supporter:"B"
	},
	{
		x:0.68240000000000001,
		y:0.90600000000000003,
		supporter:"B"
	},
	{
		x:0.72699999999999998,
		y:0.90010000000000001,
		supporter:"B"
	},
	{
		x:0.87190000000000001,
		y:0.91339999999999999,
		supporter:"B"
	},
	{
		x:0.67420000000000002,
		y:0.91639999999999999,
		supporter:"B"
	},
	{
		x:0.6381,
		y:0.91930000000000001,
		supporter:"B"
	},
	{
		x:0.68600000000000005,
		y:0.91500000000000004,
		supporter:"B"
	},
	{
		x:0.69769999999999999,
		y:0.92190000000000005,
		supporter:"B"
	},
	{
		x:0.64429999999999998,
		y:0.90600000000000003,
		supporter:"B"
	},
	{
		x:0.69220000000000004,
		y:0.89249999999999996,
		supporter:"B"
	},
	{
		x:0.84640000000000004,
		y:0.94750000000000001,
		supporter:"B"
	},
	{
		x:0.77710000000000001,
		y:0.92630000000000001,
		supporter:"B"
	},
	{
		x:0.63890000000000002,
		y:0.92220000000000002,
		supporter:"B"
	},
	{
		x:0.71289999999999998,
		y:0.92210000000000003,
		supporter:"B"
	},
	{
		x:0.749,
		y:0.81469999999999998,
		supporter:"B"
	},
	{
		x:0.74129999999999996,
		y:0.9214,
		supporter:"B"
	},
	{
		x:0.7752,
		y:0.9234,
		supporter:"B"
	},
	{
		x:0.68620000000000003,
		y:0.89710000000000001,
		supporter:"B"
	},
	{
		x:0.63849999999999996,
		y:0.76300000000000001,
		supporter:"B"
	},
	{
		x:0.72109999999999996,
		y:0.87870000000000004,
		supporter:"B"
	},
	{
		x:0.6431,
		y:0.77390000000000003,
		supporter:"B"
	},
	{
		x:0.66369999999999996,
		y:0.9153,
		supporter:"B"
	},
	{
		x:0.68930000000000002,
		y:0.92049999999999998,
		supporter:"B"
	},
	{
		x:0.76349999999999996,
		y:0.86519999999999997,
		supporter:"B"
	},
	{
		x:0.76670000000000005,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.73939999999999995,
		y:0.80179999999999996,
		supporter:"B"
	},
	{
		x:0.7389,
		y:0.88670000000000004,
		supporter:"B"
	},
	{
		x:0.6623,
		y:0.80459999999999998,
		supporter:"B"
	},
	{
		x:0.85309999999999997,
		y:0.90059999999999996,
		supporter:"B"
	},
	{
		x:0.69550000000000001,
		y:0.92159999999999997,
		supporter:"B"
	},
	{
		x:0.79769999999999996,
		y:0.89439999999999997,
		supporter:"B"
	},
	{
		x:0.71260000000000001,
		y:0.91679999999999995,
		supporter:"B"
	},
	{
		x:0.69940000000000002,
		y:0.90049999999999997,
		supporter:"B"
	},
	{
		x:0.72609999999999997,
		y:0.90549999999999997,
		supporter:"B"
	},
	{
		x:0.69089999999999996,
		y:0.9244,
		supporter:"B"
	},
	{
		x:0.65590000000000004,
		y:0.91569999999999996,
		supporter:"B"
	},
	{
		x:0.67579999999999996,
		y:0.77759999999999996,
		supporter:"B"
	},
	{
		x:0.67920000000000003,
		y:0.90700000000000003,
		supporter:"B"
	},
	{
		x:0.66320000000000001,
		y:0.90459999999999996,
		supporter:"B"
	},
	{
		x:0.63759999999999994,
		y:0.91249999999999998,
		supporter:"B"
	},
	{
		x:0.71940000000000004,
		y:0.91369999999999996,
		supporter:"B"
	},
	{
		x:0.67379999999999995,
		y:0.8992,
		supporter:"B"
	},
	{
		x:0.74219999999999997,
		y:0.88949999999999996,
		supporter:"B"
	},
	{
		x:0.78320000000000001,
		y:0.89239999999999997,
		supporter:"B"
	},
	{
		x:0.69289999999999996,
		y:0.89790000000000003,
		supporter:"B"
	},
	{
		x:0.68789999999999996,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.66169999999999995,
		y:0.89780000000000004,
		supporter:"B"
	},
	{
		x:0.65980000000000005,
		y:0.91859999999999997,
		supporter:"B"
	},
	{
		x:0.79159999999999997,
		y:0.88460000000000005,
		supporter:"B"
	},
	{
		x:0.70140000000000002,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.73819999999999997,
		y:0.78280000000000005,
		supporter:"B"
	},
	{
		x:0.64670000000000005,
		y:0.88660000000000005,
		supporter:"B"
	},
	{
		x:0.69379999999999997,
		y:0.8962,
		supporter:"B"
	},
	{
		x:0.69689999999999996,
		y:0.91010000000000002,
		supporter:"B"
	},
	{
		x:0.67310000000000003,
		y:0.92869999999999997,
		supporter:"B"
	},
	{
		x:0.6754,
		y:0.92349999999999999,
		supporter:"B"
	},
	{
		x:0.76429999999999998,
		y:0.90269999999999995,
		supporter:"B"
	},
	{
		x:0.82509999999999994,
		y:0.8891,
		supporter:"B"
	},
	{
		x:0.65149999999999997,
		y:0.91490000000000005,
		supporter:"B"
	},
	{
		x:0.75090000000000001,
		y:0.86729999999999996,
		supporter:"B"
	},
	{
		x:0.69720000000000004,
		y:0.93089999999999995,
		supporter:"B"
	},
	{
		x:0.81140000000000001,
		y:0.89339999999999997,
		supporter:"B"
	},
	{
		x:0.63460000000000005,
		y:0.9083,
		supporter:"B"
	},
	{
		x:0.74160000000000004,
		y:0.88129999999999997,
		supporter:"B"
	},
	{
		x:0.6623,
		y:0.89139999999999997,
		supporter:"B"
	},
	{
		x:0.80800000000000005,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.6331,
		y:0.90359999999999996,
		supporter:"B"
	},
	{
		x:0.71340000000000003,
		y:0.90069999999999995,
		supporter:"B"
	},
	{
		x:0.87229999999999996,
		y:0.88390000000000002,
		supporter:"B"
	},
	{
		x:0.80159999999999998,
		y:0.89049999999999996,
		supporter:"B"
	},
	{
		x:0.70230000000000004,
		y:0.91759999999999997,
		supporter:"B"
	},
	{
		x:0.67490000000000006,
		y:0.87439999999999996,
		supporter:"B"
	},
	{
		x:0.72340000000000004,
		y:0.89019999999999999,
		supporter:"B"
	},
	{
		x:0.85929999999999995,
		y:0.91479999999999995,
		supporter:"B"
	},
	{
		x:0.82450000000000001,
		y:0.90690000000000004,
		supporter:"B"
	},
	{
		x:0.69889999999999997,
		y:0.92549999999999999,
		supporter:"B"
	},
	{
		x:0.65190000000000003,
		y:0.91869999999999996,
		supporter:"B"
	},
	{
		x:0.86399999999999999,
		y:0.90439999999999998,
		supporter:"B"
	},
	{
		x:0.75,
		y:0.9123,
		supporter:"B"
	},
	{
		x:0.78790000000000004,
		y:0.90669999999999995,
		supporter:"B"
	},
	{
		x:0.68369999999999997,
		y:0.87829999999999997,
		supporter:"B"
	},
	{
		x:0.86550000000000005,
		y:0.90090000000000003,
		supporter:"B"
	},
	{
		x:0.70120000000000005,
		y:0.88980000000000004,
		supporter:"B"
	},
	{
		x:0.72840000000000005,
		y:0.90839999999999999,
		supporter:"B"
	},
	{
		x:0.66100000000000003,
		y:0.90100000000000002,
		supporter:"B"
	},
	{
		x:0.71550000000000002,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.68149999999999999,
		y:0.89600000000000002,
		supporter:"B"
	},
	{
		x:0.6452,
		y:0.80359999999999998,
		supporter:"B"
	},
	{
		x:0.70779999999999998,
		y:0.92079999999999995,
		supporter:"B"
	},
	{
		x:0.65690000000000004,
		y:0.92559999999999998,
		supporter:"B"
	},
	{
		x:0.65800000000000003,
		y:0.92530000000000001,
		supporter:"B"
	},
	{
		x:0.84460000000000002,
		y:0.87739999999999996,
		supporter:"B"
	},
	{
		x:0.751,
		y:0.78520000000000001,
		supporter:"B"
	},
	{
		x:0.64829999999999999,
		y:0.91869999999999996,
		supporter:"B"
	},
	{
		x:0.83350000000000002,
		y:0.88280000000000003,
		supporter:"B"
	},
	{
		x:0.63580000000000003,
		y:0.90780000000000005,
		supporter:"B"
	},
	{
		x:0.71140000000000003,
		y:0.9052,
		supporter:"B"
	},
	{
		x:0.68830000000000002,
		y:0.88129999999999997,
		supporter:"B"
	},
	{
		x:0.84219999999999995,
		y:0.90190000000000003,
		supporter:"B"
	},
	{
		x:0.7369,
		y:0.85029999999999994,
		supporter:"B"
	},
	{
		x:0.72950000000000004,
		y:0.78349999999999997,
		supporter:"B"
	},
	{
		x:0.8679,
		y:0.80330000000000001,
		supporter:"B"
	},
	{
		x:0.63270000000000004,
		y:0.91,
		supporter:"B"
	},
	{
		x:0.68179999999999996,
		y:0.91290000000000004,
		supporter:"B"
	},
	{
		x:0.70440000000000003,
		y:0.91420000000000001,
		supporter:"B"
	},
	{
		x:0.71660000000000001,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.71199999999999997,
		y:0.9143,
		supporter:"B"
	},
	{
		x:0.71430000000000005,
		y:0.93179999999999996,
		supporter:"B"
	},
	{
		x:0.63380000000000003,
		y:0.91069999999999995,
		supporter:"B"
	},
	{
		x:0.65810000000000002,
		y:0.76519999999999999,
		supporter:"B"
	},
	{
		x:0.68400000000000005,
		y:0.88959999999999995,
		supporter:"B"
	},
	{
		x:0.75290000000000001,
		y:0.91090000000000004,
		supporter:"B"
	},
	{
		x:0.73119999999999996,
		y:0.90480000000000005,
		supporter:"B"
	},
	{
		x:0.70809999999999995,
		y:0.90569999999999995,
		supporter:"B"
	},
	{
		x:0.6724,
		y:0.79910000000000003,
		supporter:"B"
	},
	{
		x:0.69469999999999998,
		y:0.92589999999999995,
		supporter:"B"
	},
	{
		x:0.63480000000000003,
		y:0.82720000000000005,
		supporter:"B"
	},
	{
		x:0.63329999999999997,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.72070000000000001,
		y:0.87280000000000002,
		supporter:"B"
	},
	{
		x:0.80079999999999996,
		y:0.89119999999999999,
		supporter:"B"
	},
	{
		x:0.6603,
		y:0.89159999999999995,
		supporter:"B"
	},
	{
		x:0.64990000000000003,
		y:0.91869999999999996,
		supporter:"B"
	},
	{
		x:0.73099999999999998,
		y:0.79700000000000004,
		supporter:"B"
	},
	{
		x:0.70599999999999996,
		y:0.92949999999999999,
		supporter:"B"
	},
	{
		x:0.81,
		y:0.76219999999999999,
		supporter:"B"
	},
	{
		x:0.74360000000000004,
		y:0.92330000000000001,
		supporter:"B"
	},
	{
		x:0.72430000000000005,
		y:0.88149999999999995,
		supporter:"B"
	},
	{
		x:0.8639,
		y:0.89190000000000003,
		supporter:"B"
	},
	{
		x:0.66769999999999996,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.71809999999999996,
		y:0.91080000000000005,
		supporter:"B"
	},
	{
		x:0.67669999999999997,
		y:0.90249999999999997,
		supporter:"B"
	},
	{
		x:0.65759999999999996,
		y:0.8095,
		supporter:"B"
	},
	{
		x:0.67269999999999996,
		y:0.89119999999999999,
		supporter:"B"
	},
	{
		x:0.67569999999999997,
		y:0.89549999999999996,
		supporter:"B"
	},
	{
		x:0.69769999999999999,
		y:0.89229999999999998,
		supporter:"B"
	},
	{
		x:0.63270000000000004,
		y:0.91269999999999996,
		supporter:"B"
	},
	{
		x:0.67549999999999999,
		y:0.90549999999999997,
		supporter:"B"
	},
	{
		x:0.75919999999999999,
		y:0.88790000000000002,
		supporter:"B"
	},
	{
		x:0.87160000000000004,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.746,
		y:0.90859999999999996,
		supporter:"B"
	},
	{
		x:0.68069999999999997,
		y:0.89280000000000004,
		supporter:"B"
	},
	{
		x:0.72729999999999995,
		y:0.8851,
		supporter:"B"
	},
	{
		x:0.65690000000000004,
		y:0.91069999999999995,
		supporter:"B"
	},
	{
		x:0.67689999999999995,
		y:0.89500000000000002,
		supporter:"B"
	},
	{
		x:0.75790000000000002,
		y:0.80220000000000002,
		supporter:"B"
	},
	{
		x:0.77410000000000001,
		y:0.92559999999999998,
		supporter:"B"
	},
	{
		x:0.78280000000000005,
		y:0.88460000000000005,
		supporter:"B"
	},
	{
		x:0.67179999999999995,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.64019999999999999,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.63319999999999999,
		y:0.88160000000000005,
		supporter:"B"
	},
	{
		x:0.7681,
		y:0.91159999999999997,
		supporter:"B"
	},
	{
		x:0.72929999999999995,
		y:0.78339999999999999,
		supporter:"B"
	},
	{
		x:0.72699999999999998,
		y:0.92100000000000004,
		supporter:"B"
	},
	{
		x:0.6623,
		y:0.92190000000000005,
		supporter:"B"
	},
	{
		x:0.71660000000000001,
		y:0.90269999999999995,
		supporter:"B"
	},
	{
		x:0.71779999999999999,
		y:0.89429999999999998,
		supporter:"B"
	},
	{
		x:0.67530000000000001,
		y:0.89980000000000004,
		supporter:"B"
	},
	{
		x:0.69430000000000003,
		y:0.79579999999999995,
		supporter:"B"
	},
	{
		x:0.7228,
		y:0.92220000000000002,
		supporter:"B"
	},
	{
		x:0.67459999999999998,
		y:0.9224,
		supporter:"B"
	},
	{
		x:0.77100000000000002,
		y:0.88339999999999996,
		supporter:"B"
	},
	{
		x:0.6744,
		y:0.76519999999999999,
		supporter:"B"
	},
	{
		x:0.71560000000000001,
		y:0.88600000000000001,
		supporter:"B"
	},
	{
		x:0.66479999999999995,
		y:0.91830000000000001,
		supporter:"B"
	},
	{
		x:0.73199999999999998,
		y:0.88260000000000005,
		supporter:"B"
	},
	{
		x:0.78,
		y:0.91469999999999996,
		supporter:"B"
	},
	{
		x:0.68730000000000002,
		y:0.90010000000000001,
		supporter:"B"
	},
	{
		x:0.80979999999999996,
		y:0.89600000000000002,
		supporter:"B"
	},
	{
		x:0.69120000000000004,
		y:0.92220000000000002,
		supporter:"B"
	},
	{
		x:0.69620000000000004,
		y:0.78269999999999995,
		supporter:"B"
	},
	{
		x:0.66690000000000005,
		y:0.9214,
		supporter:"B"
	},
	{
		x:0.65769999999999995,
		y:0.91859999999999997,
		supporter:"B"
	},
	{
		x:0.69140000000000001,
		y:0.90039999999999998,
		supporter:"B"
	},
	{
		x:0.65869999999999995,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.68489999999999995,
		y:0.92220000000000002,
		supporter:"B"
	},
	{
		x:0.63519999999999999,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.72060000000000002,
		y:0.89870000000000005,
		supporter:"B"
	},
	{
		x:0.70040000000000002,
		y:0.82189999999999996,
		supporter:"B"
	},
	{
		x:0.74429999999999996,
		y:0.85489999999999999,
		supporter:"B"
	},
	{
		x:0.65229999999999999,
		y:0.91410000000000002,
		supporter:"B"
	},
	{
		x:0.76929999999999998,
		y:0.78439999999999999,
		supporter:"B"
	},
	{
		x:0.70540000000000003,
		y:0.88859999999999995,
		supporter:"B"
	},
	{
		x:0.68079999999999996,
		y:0.85050000000000003,
		supporter:"B"
	},
	{
		x:0.64280000000000004,
		y:0.88880000000000003,
		supporter:"B"
	},
	{
		x:0.73970000000000002,
		y:0.93079999999999996,
		supporter:"B"
	},
	{
		x:0.64680000000000004,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.66390000000000005,
		y:0.89149999999999996,
		supporter:"B"
	},
	{
		x:0.74929999999999997,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.67110000000000003,
		y:0.91810000000000003,
		supporter:"B"
	},
	{
		x:0.63660000000000005,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.67889999999999995,
		y:0.92149999999999999,
		supporter:"B"
	},
	{
		x:0.69130000000000003,
		y:0.89119999999999999,
		supporter:"B"
	},
	{
		x:0.66220000000000001,
		y:0.88819999999999999,
		supporter:"B"
	},
	{
		x:0.69410000000000005,
		y:0.93140000000000001,
		supporter:"B"
	},
	{
		x:0.93630000000000002,
		y:0.90310000000000001,
		supporter:"B"
	},
	{
		x:0.67749999999999999,
		y:0.90190000000000003,
		supporter:"B"
	},
	{
		x:0.6462,
		y:0.89890000000000003,
		supporter:"B"
	},
	{
		x:0.63190000000000002,
		y:0.91479999999999995,
		supporter:"B"
	},
	{
		x:0.65290000000000004,
		y:0.79200000000000004,
		supporter:"B"
	},
	{
		x:0.89290000000000003,
		y:0.88200000000000001,
		supporter:"B"
	},
	{
		x:0.83879999999999999,
		y:0.89219999999999999,
		supporter:"B"
	},
	{
		x:0.6875,
		y:0.8962,
		supporter:"B"
	},
	{
		x:0.64349999999999996,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.66400000000000003,
		y:0.90180000000000005,
		supporter:"B"
	},
	{
		x:0.67649999999999999,
		y:0.92559999999999998,
		supporter:"B"
	},
	{
		x:0.6381,
		y:0.88739999999999997,
		supporter:"B"
	},
	{
		x:0.63480000000000003,
		y:0.77780000000000005,
		supporter:"B"
	},
	{
		x:0.7651,
		y:0.9032,
		supporter:"B"
	},
	{
		x:0.80210000000000004,
		y:0.88060000000000005,
		supporter:"B"
	},
	{
		x:0.68769999999999998,
		y:0.92010000000000003,
		supporter:"B"
	},
	{
		x:0.65349999999999997,
		y:0.91249999999999998,
		supporter:"B"
	},
	{
		x:0.6673,
		y:0.91890000000000005,
		supporter:"B"
	},
	{
		x:0.6673,
		y:0.80940000000000001,
		supporter:"B"
	},
	{
		x:0.72970000000000002,
		y:0.93169999999999997,
		supporter:"B"
	},
	{
		x:0.68530000000000002,
		y:0.9294,
		supporter:"B"
	},
	{
		x:0.65920000000000001,
		y:0.91810000000000003,
		supporter:"B"
	},
	{
		x:0.66610000000000003,
		y:0.79800000000000004,
		supporter:"B"
	},
	{
		x:0.79779999999999995,
		y:0.9,
		supporter:"B"
	},
	{
		x:0.88190000000000002,
		y:0.90490000000000004,
		supporter:"B"
	},
	{
		x:0.64319999999999999,
		y:0.91349999999999998,
		supporter:"B"
	},
	{
		x:0.85960000000000003,
		y:0.84950000000000003,
		supporter:"B"
	},
	{
		x:0.82230000000000003,
		y:0.87509999999999999,
		supporter:"B"
	},
	{
		x:0.67510000000000003,
		y:0.89859999999999995,
		supporter:"B"
	},
	{
		x:0.81079999999999997,
		y:0.8891,
		supporter:"B"
	},
	{
		x:0.67530000000000001,
		y:0.92510000000000003,
		supporter:"B"
	},
	{
		x:0.83889999999999998,
		y:0.94799999999999995,
		supporter:"B"
	},
	{
		x:0.78180000000000005,
		y:0.88339999999999996,
		supporter:"B"
	},
	{
		x:0.65239999999999998,
		y:0.91879999999999995,
		supporter:"B"
	},
	{
		x:0.74260000000000004,
		y:0.91469999999999996,
		supporter:"B"
	},
	{
		x:0.73399999999999999,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.71519999999999995,
		y:0.90529999999999999,
		supporter:"B"
	},
	{
		x:0.7077,
		y:0.88590000000000002,
		supporter:"B"
	},
	{
		x:0.64800000000000002,
		y:0.91510000000000002,
		supporter:"B"
	},
	{
		x:0.82389999999999997,
		y:0.88600000000000001,
		supporter:"B"
	},
	{
		x:0.69479999999999997,
		y:0.92649999999999999,
		supporter:"B"
	},
	{
		x:0.7127,
		y:0.90439999999999998,
		supporter:"B"
	},
	{
		x:0.63800000000000001,
		y:0.90790000000000004,
		supporter:"B"
	},
	{
		x:0.71150000000000002,
		y:0.91659999999999997,
		supporter:"B"
	},
	{
		x:0.71879999999999999,
		y:0.76339999999999997,
		supporter:"B"
	},
	{
		x:0.65710000000000002,
		y:0.91590000000000005,
		supporter:"B"
	},
	{
		x:0.75349999999999995,
		y:0.91520000000000001,
		supporter:"B"
	},
	{
		x:0.68179999999999996,
		y:0.92910000000000004,
		supporter:"B"
	},
	{
		x:0.7994,
		y:0.78859999999999997,
		supporter:"B"
	},
	{
		x:0.64790000000000003,
		y:0.78800000000000003,
		supporter:"B"
	},
	{
		x:0.65759999999999996,
		y:0.91890000000000005,
		supporter:"B"
	},
	{
		x:0.77929999999999999,
		y:0.88660000000000005,
		supporter:"B"
	},
	{
		x:0.70650000000000002,
		y:0.8579,
		supporter:"B"
	},
	{
		x:0.79339999999999999,
		y:0.8972,
		supporter:"B"
	},
	{
		x:0.751,
		y:0.78849999999999998,
		supporter:"B"
	},
	{
		x:0.69110000000000005,
		y:0.87509999999999999,
		supporter:"B"
	},
	{
		x:0.63160000000000005,
		y:0.90710000000000002,
		supporter:"B"
	},
	{
		x:0.77669999999999995,
		y:0.89290000000000003,
		supporter:"B"
	},
	{
		x:0.69199999999999995,
		y:0.79690000000000005,
		supporter:"B"
	},
	{
		x:0.7329,
		y:0.88819999999999999,
		supporter:"B"
	},
	{
		x:0.68789999999999996,
		y:0.88859999999999995,
		supporter:"B"
	},
	{
		x:0.63160000000000005,
		y:0.91420000000000001,
		supporter:"B"
	},
	{
		x:0.68600000000000005,
		y:0.91090000000000004,
		supporter:"B"
	},
	{
		x:0.64510000000000001,
		y:0.92349999999999999,
		supporter:"B"
	},
	{
		x:0.63439999999999996,
		y:0.89529999999999998,
		supporter:"B"
	},
	{
		x:0.68920000000000003,
		y:0.92310000000000003,
		supporter:"B"
	},
	{
		x:0.74119999999999997,
		y:0.91869999999999996,
		supporter:"B"
	},
	{
		x:0.67230000000000001,
		y:0.88680000000000003,
		supporter:"B"
	},
	{
		x:0.64059999999999995,
		y:0.89980000000000004,
		supporter:"B"
	},
	{
		x:0.72650000000000003,
		y:0.88370000000000004,
		supporter:"B"
	},
	{
		x:0.66520000000000001,
		y:0.88900000000000001,
		supporter:"B"
	},
	{
		x:0.66239999999999999,
		y:0.92230000000000001,
		supporter:"B"
	},
	{
		x:0.78469999999999995,
		y:0.8821,
		supporter:"B"
	},
	{
		x:0.70430000000000004,
		y:0.91,
		supporter:"B"
	},
	{
		x:0.64159999999999995,
		y:0.91510000000000002,
		supporter:"B"
	},
	{
		x:0.67879999999999996,
		y:0.91490000000000005,
		supporter:"B"
	},
	{
		x:0.65610000000000002,
		y:0.84809999999999997,
		supporter:"B"
	},
	{
		x:0.73260000000000003,
		y:0.92459999999999998,
		supporter:"B"
	},
	{
		x:0.76580000000000004,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.64729999999999999,
		y:0.91290000000000004,
		supporter:"B"
	},
	{
		x:0.86990000000000001,
		y:0.91700000000000004,
		supporter:"B"
	},
	{
		x:0.65880000000000005,
		y:0.91920000000000002,
		supporter:"B"
	},
	{
		x:0.64690000000000003,
		y:0.91879999999999995,
		supporter:"B"
	},
	{
		x:0.74850000000000005,
		y:0.93310000000000004,
		supporter:"B"
	},
	{
		x:0.85119999999999996,
		y:0.91379999999999995,
		supporter:"B"
	},
	{
		x:0.87629999999999997,
		y:0.91080000000000005,
		supporter:"B"
	},
	{
		x:0.75990000000000002,
		y:0.91969999999999996,
		supporter:"B"
	},
	{
		x:0.75819999999999999,
		y:0.90800000000000003,
		supporter:"B"
	},
	{
		x:0.8599,
		y:0.90380000000000005,
		supporter:"B"
	},
	{
		x:0.68520000000000003,
		y:0.92630000000000001,
		supporter:"B"
	},
	{
		x:0.72340000000000004,
		y:0.91090000000000004,
		supporter:"B"
	},
	{
		x:0.64449999999999996,
		y:0.90639999999999998,
		supporter:"B"
	},
	{
		x:0.68979999999999997,
		y:0.81,
		supporter:"B"
	},
	{
		x:0.82169999999999999,
		y:0.88290000000000002,
		supporter:"B"
	},
	{
		x:0.67500000000000004,
		y:0.92569999999999997,
		supporter:"B"
	},
	{
		x:0.70850000000000002,
		y:0.92259999999999998,
		supporter:"B"
	},
	{
		x:0.82520000000000004,
		y:0.88549999999999995,
		supporter:"B"
	},
	{
		x:0.69010000000000005,
		y:0.92989999999999995,
		supporter:"B"
	},
	{
		x:0.63109999999999999,
		y:0.90029999999999999,
		supporter:"B"
	},
	{
		x:0.63849999999999996,
		y:0.87,
		supporter:"B"
	},
	{
		x:0.65359999999999996,
		y:0.89910000000000001,
		supporter:"B"
	},
	{
		x:0.72250000000000003,
		y:0.9022,
		supporter:"B"
	},
	{
		x:0.63729999999999998,
		y:0.90569999999999995,
		supporter:"B"
	},
	{
		x:0.65380000000000005,
		y:0.9052,
		supporter:"B"
	},
	{
		x:0.67110000000000003,
		y:0.8921,
		supporter:"B"
	},
	{
		x:0.74980000000000002,
		y:0.93520000000000003,
		supporter:"B"
	},
	{
		x:0.68540000000000001,
		y:0.92730000000000001,
		supporter:"B"
	},
	{
		x:0.65529999999999999,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.75990000000000002,
		y:0.89459999999999995,
		supporter:"B"
	},
	{
		x:0.67310000000000003,
		y:0.79159999999999997,
		supporter:"B"
	},
	{
		x:0.70099999999999996,
		y:0.92379999999999995,
		supporter:"B"
	},
	{
		x:0.8619,
		y:0.90149999999999997,
		supporter:"B"
	},
	{
		x:0.70009999999999994,
		y:0.92789999999999995,
		supporter:"B"
	},
	{
		x:0.65400000000000003,
		y:0.9163,
		supporter:"B"
	},
	{
		x:0.87450000000000006,
		y:0.91220000000000001,
		supporter:"B"
	},
	{
		x:0.67749999999999999,
		y:0.88460000000000005,
		supporter:"B"
	},
	{
		x:0.64400000000000002,
		y:0.9234,
		supporter:"B"
	},
	{
		x:0.67479999999999996,
		y:0.86229999999999996,
		supporter:"B"
	},
	{
		x:0.71630000000000005,
		y:0.89580000000000004,
		supporter:"B"
	},
	{
		x:0.65490000000000004,
		y:0.80520000000000003,
		supporter:"B"
	},
	{
		x:0.81930000000000003,
		y:0.80149999999999999,
		supporter:"B"
	},
	{
		x:0.63680000000000003,
		y:0.91610000000000003,
		supporter:"B"
	},
	{
		x:0.70950000000000002,
		y:0.89390000000000003,
		supporter:"B"
	},
	{
		x:0.63439999999999996,
		y:0.91710000000000003,
		supporter:"B"
	},
	{
		x:0.87250000000000005,
		y:0.90980000000000005,
		supporter:"B"
	},
	{
		x:0.88480000000000003,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.69940000000000002,
		y:0.90149999999999997,
		supporter:"B"
	},
	{
		x:0.70150000000000001,
		y:0.92349999999999999,
		supporter:"B"
	},
	{
		x:0.6855,
		y:0.92600000000000005,
		supporter:"B"
	},
	{
		x:0.68159999999999998,
		y:0.93689999999999996,
		supporter:"B"
	},
	{
		x:0.6825,
		y:0.89659999999999995,
		supporter:"B"
	},
	{
		x:0.69189999999999996,
		y:0.86770000000000003,
		supporter:"B"
	},
	{
		x:0.66149999999999998,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.77990000000000004,
		y:0.90649999999999997,
		supporter:"B"
	},
	{
		x:0.66569999999999996,
		y:0.91910000000000003,
		supporter:"B"
	},
	{
		x:0.75900000000000001,
		y:0.9244,
		supporter:"B"
	},
	{
		x:0.67600000000000005,
		y:0.88570000000000004,
		supporter:"B"
	},
	{
		x:0.66800000000000004,
		y:0.91059999999999997,
		supporter:"B"
	},
	{
		x:0.72130000000000005,
		y:0.89129999999999998,
		supporter:"B"
	},
	{
		x:0.79949999999999999,
		y:0.7923,
		supporter:"B"
	},
	{
		x:0.87729999999999997,
		y:0.91110000000000002,
		supporter:"B"
	},
	{
		x:0.67069999999999996,
		y:0.92949999999999999,
		supporter:"B"
	},
	{
		x:0.73660000000000003,
		y:0.88919999999999999,
		supporter:"B"
	},
	{
		x:0.79449999999999998,
		y:0.79559999999999997,
		supporter:"B"
	},
	{
		x:0.67149999999999999,
		y:0.91010000000000002,
		supporter:"B"
	},
	{
		x:0.80149999999999999,
		y:0.89900000000000002,
		supporter:"B"
	},
	{
		x:0.63790000000000002,
		y:0.90239999999999998,
		supporter:"B"
	},
	{
		x:0.65469999999999995,
		y:0.88190000000000002,
		supporter:"B"
	},
	{
		x:0.69510000000000005,
		y:0.92549999999999999,
		supporter:"B"
	},
	{
		x:0.73529999999999995,
		y:0.87380000000000002,
		supporter:"B"
	},
	{
		x:0.86619999999999997,
		y:0.90590000000000004,
		supporter:"B"
	},
	{
		x:0.93979999999999997,
		y:0.89370000000000005,
		supporter:"B"
	},
	{
		x:0.65169999999999995,
		y:0.92110000000000003,
		supporter:"B"
	},
	{
		x:0.70799999999999996,
		y:0.89649999999999996,
		supporter:"B"
	},
	{
		x:0.63360000000000005,
		y:0.92079999999999995,
		supporter:"B"
	},
	{
		x:0.73680000000000001,
		y:0.88739999999999997,
		supporter:"B"
	},
	{
		x:0.83209999999999995,
		y:0.90349999999999997,
		supporter:"B"
	},
	{
		x:0.78569999999999995,
		y:0.90629999999999999,
		supporter:"B"
	},
	{
		x:0.69020000000000004,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.65080000000000005,
		y:0.92989999999999995,
		supporter:"B"
	},
	{
		x:0.70779999999999998,
		y:0.89939999999999998,
		supporter:"B"
	},
	{
		x:0.63660000000000005,
		y:0.91120000000000001,
		supporter:"B"
	},
	{
		x:0.6845,
		y:0.87860000000000005,
		supporter:"B"
	},
	{
		x:0.6583,
		y:0.92130000000000001,
		supporter:"B"
	},
	{
		x:0.67469999999999997,
		y:0.9143,
		supporter:"B"
	},
	{
		x:0.87280000000000002,
		y:0.89690000000000003,
		supporter:"B"
	},
	{
		x:0.85599999999999998,
		y:0.91010000000000002,
		supporter:"B"
	},
	{
		x:0.63780000000000003,
		y:0.93240000000000001,
		supporter:"B"
	},
	{
		x:0.65790000000000004,
		y:0.91100000000000003,
		supporter:"B"
	},
	{
		x:0.77829999999999999,
		y:0.8921,
		supporter:"B"
	},
	{
		x:0.8599,
		y:0.8821,
		supporter:"B"
	},
	{
		x:0.66579999999999995,
		y:0.91590000000000005,
		supporter:"B"
	},
	{
		x:0.7167,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.86460000000000004,
		y:0.94140000000000001,
		supporter:"B"
	},
	{
		x:0.64500000000000002,
		y:0.92110000000000003,
		supporter:"B"
	},
	{
		x:0.65469999999999995,
		y:0.90329999999999999,
		supporter:"B"
	},
	{
		x:0.69440000000000002,
		y:0.91639999999999999,
		supporter:"B"
	},
	{
		x:0.63800000000000001,
		y:0.91169999999999995,
		supporter:"B"
	},
	{
		x:0.65820000000000001,
		y:0.92459999999999998,
		supporter:"B"
	},
	{
		x:0.79579999999999995,
		y:0.89059999999999995,
		supporter:"B"
	},
	{
		x:0.71109999999999995,
		y:0.89810000000000001,
		supporter:"B"
	},
	{
		x:0.67710000000000004,
		y:0.88580000000000003,
		supporter:"B"
	},
	{
		x:0.75429999999999997,
		y:0.81789999999999996,
		supporter:"B"
	},
	{
		x:0.67710000000000004,
		y:0.9234,
		supporter:"B"
	},
	{
		x:0.87190000000000001,
		y:0.90749999999999997,
		supporter:"B"
	},
	{
		x:0.65610000000000002,
		y:0.91790000000000005,
		supporter:"B"
	},
	{
		x:0.86680000000000001,
		y:0.9476,
		supporter:"B"
	},
	{
		x:0.74809999999999999,
		y:0.91200000000000003,
		supporter:"B"
	},
	{
		x:0.69279999999999997,
		y:0.88170000000000004,
		supporter:"B"
	},
	{
		x:0.67559999999999998,
		y:0.87849999999999995,
		supporter:"B"
	},
	{
		x:0.69469999999999998,
		y:0.93179999999999996,
		supporter:"B"
	},
	{
		x:0.66690000000000005,
		y:0.92969999999999997,
		supporter:"B"
	},
	{
		x:0.73580000000000001,
		y:0.92579999999999996,
		supporter:"B"
	},
	{
		x:0.73540000000000005,
		y:0.87509999999999999,
		supporter:"B"
	},
	{
		x:0.68020000000000003,
		y:0.91090000000000004,
		supporter:"B"
	},
	{
		x:0.66710000000000003,
		y:0.92079999999999995,
		supporter:"B"
	},
	{
		x:0.63060000000000005,
		y:0.91279999999999994,
		supporter:"B"
	},
	{
		x:0.63829999999999998,
		y:0.91300000000000003,
		supporter:"B"
	},
	{
		x:0.6472,
		y:0.90559999999999996,
		supporter:"B"
	},
	{
		x:0.71160000000000001,
		y:0.91859999999999997,
		supporter:"B"
	},
	{
		x:0.68179999999999996,
		y:0.90780000000000005,
		supporter:"B"
	},
	{
		x:0.64870000000000005,
		y:0.91069999999999995,
		supporter:"B"
	},
	{
		x:0.87229999999999996,
		y:0.94850000000000001,
		supporter:"B"
	},
	{
		x:0.63749999999999996,
		y:0.92920000000000003,
		supporter:"B"
	},
	{
		x:0.84430000000000005,
		y:0.91369999999999996,
		supporter:"B"
	},
	{
		x:0.66369999999999996,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.66790000000000005,
		y:0.92259999999999998,
		supporter:"B"
	},
	{
		x:0.66839999999999999,
		y:0.88390000000000002,
		supporter:"B"
	},
	{
		x:0.74250000000000005,
		y:0.9103,
		supporter:"B"
	},
	{
		x:0.75180000000000002,
		y:0.9194,
		supporter:"B"
	},
	{
		x:0.63009999999999999,
		y:0.91879999999999995,
		supporter:"B"
	},
	{
		x:0.75929999999999997,
		y:0.88249999999999995,
		supporter:"B"
	},
	{
		x:0.7208,
		y:0.9274,
		supporter:"B"
	},
	{
		x:0.65959999999999996,
		y:0.91320000000000001,
		supporter:"B"
	},
	{
		x:0.64990000000000003,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.71970000000000001,
		y:0.89159999999999995,
		supporter:"B"
	},
	{
		x:0.72629999999999995,
		y:0.91110000000000002,
		supporter:"B"
	},
	{
		x:0.6593,
		y:0.79300000000000004,
		supporter:"B"
	},
	{
		x:0.72089999999999999,
		y:0.89419999999999999,
		supporter:"B"
	},
	{
		x:0.80610000000000004,
		y:0.9022,
		supporter:"B"
	},
	{
		x:0.64059999999999995,
		y:0.89239999999999997,
		supporter:"B"
	},
	{
		x:0.65920000000000001,
		y:0.9224,
		supporter:"B"
	},
	{
		x:0.65069999999999995,
		y:0.90610000000000002,
		supporter:"B"
	},
	{
		x:0.6714,
		y:0.9244,
		supporter:"B"
	},
	{
		x:0.71430000000000005,
		y:0.89590000000000003,
		supporter:"B"
	},
	{
		x:0.63380000000000003,
		y:0.92589999999999995,
		supporter:"B"
	},
	{
		x:0.68230000000000002,
		y:0.89349999999999996,
		supporter:"B"
	},
	{
		x:0.72570000000000001,
		y:0.77769999999999995,
		supporter:"B"
	},
	{
		x:0.73099999999999998,
		y:0.91039999999999999,
		supporter:"B"
	},
	{
		x:0.64100000000000001,
		y:0.93110000000000004,
		supporter:"B"
	},
	{
		x:0.87519999999999998,
		y:0.91249999999999998,
		supporter:"B"
	},
	{
		x:0.72409999999999997,
		y:0.89680000000000004,
		supporter:"B"
	},
	{
		x:0.66269999999999996,
		y:0.8881,
		supporter:"B"
	},
	{
		x:0.63549999999999995,
		y:0.91420000000000001,
		supporter:"B"
	},
	{
		x:0.8629,
		y:0.9476,
		supporter:"B"
	},
	{
		x:0.69099999999999995,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.75919999999999999,
		y:0.80689999999999995,
		supporter:"B"
	},
	{
		x:0.66949999999999998,
		y:0.9274,
		supporter:"B"
	},
	{
		x:0.65300000000000002,
		y:0.90620000000000001,
		supporter:"B"
	},
	{
		x:0.87329999999999997,
		y:0.90710000000000002,
		supporter:"B"
	},
	{
		x:0.70579999999999998,
		y:0.91890000000000005,
		supporter:"B"
	},
	{
		x:0.68879999999999997,
		y:0.85850000000000004,
		supporter:"B"
	},
	{
		x:0.68589999999999995,
		y:0.90129999999999999,
		supporter:"B"
	},
	{
		x:0.76970000000000005,
		y:0.91139999999999999,
		supporter:"B"
	},
	{
		x:0.749,
		y:0.91790000000000005,
		supporter:"B"
	},
	{
		x:0.68510000000000004,
		y:0.90129999999999999,
		supporter:"B"
	},
	{
		x:0.69699999999999995,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.66039999999999999,
		y:0.9123,
		supporter:"B"
	},
	{
		x:0.71160000000000001,
		y:0.89739999999999998,
		supporter:"B"
	},
	{
		x:0.72709999999999997,
		y:0.90349999999999997,
		supporter:"B"
	},
	{
		x:0.71230000000000004,
		y:0.91549999999999998,
		supporter:"B"
	},
	{
		x:0.67310000000000003,
		y:0.90410000000000001,
		supporter:"B"
	},
	{
		x:0.63729999999999998,
		y:0.91069999999999995,
		supporter:"B"
	},
	{
		x:0.71950000000000003,
		y:0.92469999999999997,
		supporter:"B"
	},
	{
		x:0.70709999999999995,
		y:0.92530000000000001,
		supporter:"B"
	},
	{
		x:0.72750000000000004,
		y:0.89119999999999999,
		supporter:"B"
	},
	{
		x:0.69740000000000002,
		y:0.89729999999999999,
		supporter:"B"
	},
	{
		x:0.71579999999999999,
		y:0.92210000000000003,
		supporter:"B"
	},
	{
		x:0.70660000000000001,
		y:0.85270000000000001,
		supporter:"B"
	},
	{
		x:0.78120000000000001,
		y:0.92079999999999995,
		supporter:"B"
	},
	{
		x:0.80259999999999998,
		y:0.89290000000000003,
		supporter:"B"
	},
	{
		x:0.63619999999999999,
		y:0.91869999999999996,
		supporter:"B"
	},
	{
		x:0.67469999999999997,
		y:0.90310000000000001,
		supporter:"B"
	},
	{
		x:0.64410000000000001,
		y:0.90639999999999998,
		supporter:"B"
	},
	{
		x:0.78049999999999997,
		y:0.79139999999999999,
		supporter:"B"
	},
	{
		x:0.69240000000000002,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.68530000000000002,
		y:0.88300000000000001,
		supporter:"B"
	},
	{
		x:0.63170000000000004,
		y:0.90229999999999999,
		supporter:"B"
	},
	{
		x:0.76580000000000004,
		y:0.87760000000000005,
		supporter:"B"
	},
	{
		x:0.70069999999999999,
		y:0.92930000000000001,
		supporter:"B"
	},
	{
		x:0.87719999999999998,
		y:0.90749999999999997,
		supporter:"B"
	},
	{
		x:0.70730000000000004,
		y:0.92379999999999995,
		supporter:"B"
	},
	{
		x:0.74539999999999995,
		y:0.89559999999999995,
		supporter:"B"
	},
	{
		x:0.6431,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.86380000000000001,
		y:0.89980000000000004,
		supporter:"B"
	},
	{
		x:0.66679999999999995,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.86509999999999998,
		y:0.90159999999999996,
		supporter:"B"
	},
	{
		x:0.71060000000000001,
		y:0.88200000000000001,
		supporter:"B"
	},
	{
		x:0.74539999999999995,
		y:0.92369999999999997,
		supporter:"B"
	},
	{
		x:0.76190000000000002,
		y:0.8851,
		supporter:"B"
	},
	{
		x:0.72209999999999996,
		y:0.90669999999999995,
		supporter:"B"
	},
	{
		x:0.7056,
		y:0.91290000000000004,
		supporter:"B"
	},
	{
		x:0.86119999999999997,
		y:0.90310000000000001,
		supporter:"B"
	},
	{
		x:0.85399999999999998,
		y:0.90769999999999995,
		supporter:"B"
	},
	{
		x:0.65139999999999998,
		y:0.89890000000000003,
		supporter:"B"
	},
	{
		x:0.64239999999999997,
		y:0.78910000000000002,
		supporter:"B"
	},
	{
		x:0.66679999999999995,
		y:0.91279999999999994,
		supporter:"B"
	},
	{
		x:0.7006,
		y:0.92449999999999999,
		supporter:"B"
	},
	{
		x:0.69210000000000005,
		y:0.88749999999999996,
		supporter:"B"
	},
	{
		x:0.75280000000000002,
		y:0.88770000000000004,
		supporter:"B"
	},
	{
		x:0.65869999999999995,
		y:0.91320000000000001,
		supporter:"B"
	},
	{
		x:0.63280000000000003,
		y:0.92049999999999998,
		supporter:"B"
	},
	{
		x:0.74890000000000001,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.69259999999999999,
		y:0.89419999999999999,
		supporter:"B"
	},
	{
		x:0.6411,
		y:0.89600000000000002,
		supporter:"B"
	},
	{
		x:0.65169999999999995,
		y:0.90949999999999998,
		supporter:"B"
	},
	{
		x:0.68369999999999997,
		y:0.92230000000000001,
		supporter:"B"
	},
	{
		x:0.64290000000000003,
		y:0.90580000000000005,
		supporter:"B"
	},
	{
		x:0.73199999999999998,
		y:0.79349999999999998,
		supporter:"B"
	},
	{
		x:0.6794,
		y:0.91700000000000004,
		supporter:"B"
	},
	{
		x:0.71409999999999996,
		y:0.91549999999999998,
		supporter:"B"
	},
	{
		x:0.67530000000000001,
		y:0.89929999999999999,
		supporter:"B"
	},
	{
		x:0.86470000000000002,
		y:0.89770000000000005,
		supporter:"B"
	},
	{
		x:0.74129999999999996,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.79710000000000003,
		y:0.89690000000000003,
		supporter:"B"
	},
	{
		x:0.7077,
		y:0.89659999999999995,
		supporter:"B"
	},
	{
		x:0.64180000000000004,
		y:0.91339999999999999,
		supporter:"B"
	},
	{
		x:0.76180000000000003,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.64139999999999997,
		y:0.92810000000000004,
		supporter:"B"
	},
	{
		x:0.71079999999999999,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.66300000000000003,
		y:0.91739999999999999,
		supporter:"B"
	},
	{
		x:0.69679999999999997,
		y:0.874,
		supporter:"B"
	},
	{
		x:0.84819999999999995,
		y:0.91120000000000001,
		supporter:"B"
	},
	{
		x:0.68010000000000004,
		y:0.9113,
		supporter:"B"
	},
	{
		x:0.68600000000000005,
		y:0.92259999999999998,
		supporter:"B"
	},
	{
		x:0.70730000000000004,
		y:0.87529999999999997,
		supporter:"B"
	},
	{
		x:0.87039999999999995,
		y:0.91200000000000003,
		supporter:"B"
	},
	{
		x:0.69410000000000005,
		y:0.92779999999999996,
		supporter:"B"
	},
	{
		x:0.73170000000000002,
		y:0.91279999999999994,
		supporter:"B"
	},
	{
		x:0.85319999999999996,
		y:0.88759999999999994,
		supporter:"B"
	},
	{
		x:0.65449999999999997,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.88249999999999995,
		y:0.91049999999999998,
		supporter:"B"
	},
	{
		x:0.71460000000000001,
		y:0.8871,
		supporter:"B"
	},
	{
		x:0.67810000000000004,
		y:0.88170000000000004,
		supporter:"B"
	},
	{
		x:0.67679999999999996,
		y:0.90110000000000001,
		supporter:"B"
	},
	{
		x:0.75890000000000002,
		y:0.87090000000000001,
		supporter:"B"
	},
	{
		x:0.65090000000000003,
		y:0.91459999999999997,
		supporter:"B"
	},
	{
		x:0.6542,
		y:0.89759999999999995,
		supporter:"B"
	},
	{
		x:0.69620000000000004,
		y:0.77270000000000005,
		supporter:"B"
	},
	{
		x:0.64319999999999999,
		y:0.89529999999999998,
		supporter:"B"
	},
	{
		x:0.67720000000000002,
		y:0.90290000000000004,
		supporter:"B"
	},
	{
		x:0.68300000000000005,
		y:0.87519999999999998,
		supporter:"B"
	},
	{
		x:0.71679999999999999,
		y:0.9214,
		supporter:"B"
	},
	{
		x:0.86829999999999996,
		y:0.91639999999999999,
		supporter:"B"
	},
	{
		x:0.74929999999999997,
		y:0.91920000000000002,
		supporter:"B"
	},
	{
		x:0.63060000000000005,
		y:0.91259999999999997,
		supporter:"B"
	},
	{
		x:0.7581,
		y:0.88929999999999998,
		supporter:"B"
	},
	{
		x:0.65349999999999997,
		y:0.92849999999999999,
		supporter:"B"
	},
	{
		x:0.72330000000000005,
		y:0.86639999999999995,
		supporter:"B"
	},
	{
		x:0.6512,
		y:0.92269999999999996,
		supporter:"B"
	},
	{
		x:0.82279999999999998,
		y:0.91549999999999998,
		supporter:"B"
	},
	{
		x:0.65580000000000005,
		y:0.92530000000000001,
		supporter:"B"
	},
	{
		x:0.68200000000000005,
		y:0.86950000000000005,
		supporter:"B"
	},
	{
		x:0.72660000000000002,
		y:0.93200000000000005,
		supporter:"B"
	},
	{
		x:0.6542,
		y:0.90039999999999998,
		supporter:"B"
	},
	{
		x:0.85629999999999995,
		y:0.90410000000000001,
		supporter:"B"
	},
	{
		x:0.68400000000000005,
		y:0.90110000000000001,
		supporter:"B"
	},
	{
		x:0.71960000000000002,
		y:0.85899999999999999,
		supporter:"B"
	},
	{
		x:0.80769999999999997,
		y:0.94779999999999998,
		supporter:"B"
	},
	{
		x:0.66359999999999997,
		y:0.91969999999999996,
		supporter:"B"
	},
	{
		x:0.70020000000000004,
		y:0.9002,
		supporter:"B"
	},
	{
		x:0.66830000000000001,
		y:0.8992,
		supporter:"B"
	},
	{
		x:0.77410000000000001,
		y:0.8911,
		supporter:"B"
	},
	{
		x:0.66100000000000003,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.6603,
		y:0.89300000000000002,
		supporter:"B"
	},
	{
		x:0.72130000000000005,
		y:0.8861,
		supporter:"B"
	},
	{
		x:0.71879999999999999,
		y:0.873,
		supporter:"B"
	},
	{
		x:0.63139999999999996,
		y:0.91639999999999999,
		supporter:"B"
	},
	{
		x:0.71289999999999998,
		y:0.873,
		supporter:"B"
	},
	{
		x:0.69810000000000005,
		y:0.88239999999999996,
		supporter:"B"
	},
	{
		x:0.72740000000000005,
		y:0.93010000000000004,
		supporter:"B"
	},
	{
		x:0.76339999999999997,
		y:0.89590000000000003,
		supporter:"B"
	},
	{
		x:0.66379999999999995,
		y:0.92190000000000005,
		supporter:"B"
	},
	{
		x:0.86280000000000001,
		y:0.82699999999999996,
		supporter:"B"
	},
	{
		x:0.72799999999999998,
		y:0.90459999999999996,
		supporter:"B"
	},
	{
		x:0.63639999999999997,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.72550000000000003,
		y:0.91349999999999998,
		supporter:"B"
	},
	{
		x:0.78300000000000003,
		y:0.9073,
		supporter:"B"
	},
	{
		x:0.69359999999999999,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.65159999999999996,
		y:0.91590000000000005,
		supporter:"B"
	},
	{
		x:0.6663,
		y:0.91149999999999998,
		supporter:"B"
	},
	{
		x:0.70679999999999998,
		y:0.91510000000000002,
		supporter:"B"
	},
	{
		x:0.68669999999999998,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.7288,
		y:0.89239999999999997,
		supporter:"B"
	},
	{
		x:0.67530000000000001,
		y:0.91190000000000004,
		supporter:"B"
	},
	{
		x:0.73170000000000002,
		y:0.88080000000000003,
		supporter:"B"
	},
	{
		x:0.68830000000000002,
		y:0.90049999999999997,
		supporter:"B"
	},
	{
		x:0.69430000000000003,
		y:0.8992,
		supporter:"B"
	},
	{
		x:0.64600000000000002,
		y:0.91110000000000002,
		supporter:"B"
	},
	{
		x:0.82,
		y:0.90769999999999995,
		supporter:"B"
	},
	{
		x:0.81610000000000005,
		y:0.89229999999999998,
		supporter:"B"
	},
	{
		x:0.70050000000000001,
		y:0.87829999999999997,
		supporter:"B"
	},
	{
		x:0.67449999999999999,
		y:0.92879999999999996,
		supporter:"B"
	},
	{
		x:0.63859999999999995,
		y:0.91720000000000002,
		supporter:"B"
	},
	{
		x:0.93130000000000002,
		y:0.89729999999999999,
		supporter:"B"
	},
	{
		x:0.68179999999999996,
		y:0.79149999999999998,
		supporter:"B"
	},
	{
		x:0.7218,
		y:0.81220000000000003,
		supporter:"B"
	},
	{
		x:0.71579999999999999,
		y:0.88570000000000004,
		supporter:"B"
	},
	{
		x:0.64590000000000003,
		y:0.88129999999999997,
		supporter:"B"
	},
	{
		x:0.83740000000000003,
		y:0.9274,
		supporter:"B"
	},
	{
		x:0.64780000000000004,
		y:0.91549999999999998,
		supporter:"B"
	},
	{
		x:0.81950000000000001,
		y:0.79969999999999997,
		supporter:"B"
	},
	{
		x:0.71230000000000004,
		y:0.92749999999999999,
		supporter:"B"
	},
	{
		x:0.72330000000000005,
		y:0.91449999999999998,
		supporter:"B"
	},
	{
		x:0.80740000000000001,
		y:0.8911,
		supporter:"B"
	},
	{
		x:0.76339999999999997,
		y:0.89280000000000004,
		supporter:"B"
	},
	{
		x:0.64580000000000004,
		y:0.9163,
		supporter:"B"
	},
	{
		x:0.79179999999999995,
		y:0.94830000000000003,
		supporter:"B"
	},
	{
		x:0.81679999999999997,
		y:0.89529999999999998,
		supporter:"B"
	},
	{
		x:0.68140000000000001,
		y:0.79200000000000004,
		supporter:"B"
	},
	{
		x:0.7611,
		y:0.81169999999999998,
		supporter:"B"
	},
	{
		x:0.64059999999999995,
		y:0.78549999999999998,
		supporter:"B"
	},
	{
		x:0.84209999999999996,
		y:0.79600000000000004,
		supporter:"B"
	},
	{
		x:0.65649999999999997,
		y:0.90620000000000001,
		supporter:"B"
	},
	{
		x:0.81850000000000001,
		y:0.89139999999999997,
		supporter:"B"
	},
	{
		x:0.64729999999999999,
		y:0.90469999999999995,
		supporter:"B"
	},
	{
		x:0.67830000000000001,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.71750000000000003,
		y:0.89929999999999999,
		supporter:"B"
	},
	{
		x:0.64400000000000002,
		y:0.91559999999999997,
		supporter:"B"
	},
	{
		x:0.66620000000000001,
		y:0.81010000000000004,
		supporter:"B"
	},
	{
		x:0.63139999999999996,
		y:0.91400000000000003,
		supporter:"B"
	},
	{
		x:0.70930000000000004,
		y:0.90490000000000004,
		supporter:"B"
	},
	{
		x:0.65680000000000005,
		y:0.90810000000000002,
		supporter:"B"
	},
	{
		x:0.79459999999999997,
		y:0.89229999999999998,
		supporter:"B"
	},
	{
		x:0.72709999999999997,
		y:0.89800000000000002,
		supporter:"B"
	},
	{
		x:0.7087,
		y:0.93330000000000002,
		supporter:"B"
	},
	{
		x:0.85799999999999998,
		y:0.91559999999999997,
		supporter:"B"
	},
	{
		x:0.67179999999999995,
		y:0.92110000000000003,
		supporter:"B"
	},
	{
		x:0.67479999999999996,
		y:0.75129999999999997,
		supporter:"B"
	},
	{
		x:0.74270000000000003,
		y:0.93669999999999998,
		supporter:"B"
	},
	{
		x:0.87219999999999998,
		y:0.9052,
		supporter:"B"
	},
	{
		x:0.64510000000000001,
		y:0.81799999999999995,
		supporter:"B"
	},
	{
		x:0.72919999999999996,
		y:0.86829999999999996,
		supporter:"B"
	},
	{
		x:0.72360000000000002,
		y:0.91810000000000003,
		supporter:"B"
	},
	{
		x:0.68610000000000004,
		y:0.92100000000000004,
		supporter:"B"
	},
	{
		x:0.66159999999999997,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.88419999999999999,
		y:0.88759999999999994,
		supporter:"B"
	},
	{
		x:0.72589999999999999,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.63919999999999999,
		y:0.91100000000000003,
		supporter:"B"
	},
	{
		x:0.71809999999999996,
		y:0.78549999999999998,
		supporter:"B"
	},
	{
		x:0.65910000000000002,
		y:0.89539999999999997,
		supporter:"B"
	},
	{
		x:0.67059999999999997,
		y:0.9123,
		supporter:"B"
	},
	{
		x:0.73170000000000002,
		y:0.79420000000000002,
		supporter:"B"
	},
	{
		x:0.67869999999999997,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.79430000000000001,
		y:0.90659999999999996,
		supporter:"B"
	},
	{
		x:0.69199999999999995,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.77629999999999999,
		y:0.88839999999999997,
		supporter:"B"
	},
	{
		x:0.68089999999999995,
		y:0.8861,
		supporter:"B"
	},
	{
		x:0.65469999999999995,
		y:0.90669999999999995,
		supporter:"B"
	},
	{
		x:0.67479999999999996,
		y:0.91890000000000005,
		supporter:"B"
	},
	{
		x:0.63900000000000001,
		y:0.81379999999999997,
		supporter:"B"
	},
	{
		x:0.86709999999999998,
		y:0.91020000000000001,
		supporter:"B"
	},
	{
		x:0.75980000000000003,
		y:0.91790000000000005,
		supporter:"B"
	},
	{
		x:0.66180000000000005,
		y:0.9123,
		supporter:"B"
	},
	{
		x:0.75800000000000001,
		y:0.88890000000000002,
		supporter:"B"
	},
	{
		x:0.87429999999999997,
		y:0.90280000000000005,
		supporter:"B"
	},
	{
		x:0.81759999999999999,
		y:0.94840000000000002,
		supporter:"B"
	},
	{
		x:0.66439999999999999,
		y:0.90590000000000004,
		supporter:"B"
	},
	{
		x:0.67330000000000001,
		y:0.88100000000000001,
		supporter:"B"
	},
	{
		x:0.74739999999999995,
		y:0.88480000000000003,
		supporter:"B"
	},
	{
		x:0.8246,
		y:0.90349999999999997,
		supporter:"B"
	},
	{
		x:0.86080000000000001,
		y:0.79339999999999999,
		supporter:"B"
	},
	{
		x:0.78790000000000004,
		y:0.88570000000000004,
		supporter:"B"
	},
	{
		x:0.85309999999999997,
		y:0.78879999999999995,
		supporter:"B"
	},
	{
		x:0.73140000000000005,
		y:0.87629999999999997,
		supporter:"B"
	},
	{
		x:0.66180000000000005,
		y:0.92669999999999997,
		supporter:"B"
	},
	{
		x:0.72260000000000002,
		y:0.92390000000000005,
		supporter:"B"
	},
	{
		x:0.8236,
		y:0.78969999999999996,
		supporter:"B"
	},
	{
		x:0.64090000000000003,
		y:0.91869999999999996,
		supporter:"B"
	},
	{
		x:0.63529999999999998,
		y:0.80300000000000005,
		supporter:"B"
	},
	{
		x:0.69769999999999999,
		y:0.90720000000000001,
		supporter:"B"
	},
	{
		x:0.70079999999999998,
		y:0.92169999999999996,
		supporter:"B"
	},
	{
		x:0.86499999999999999,
		y:0.89570000000000005,
		supporter:"B"
	},
	{
		x:0.67290000000000005,
		y:0.91269999999999996,
		supporter:"B"
	},
	{
		x:0.78859999999999997,
		y:0.88749999999999996,
		supporter:"B"
	},
	{
		x:0.67669999999999997,
		y:0.8921,
		supporter:"B"
	},
	{
		x:0.70440000000000003,
		y:0.88460000000000005,
		supporter:"B"
	},
	{
		x:0.66569999999999996,
		y:0.84379999999999999,
		supporter:"B"
	},
	{
		x:0.85499999999999998,
		y:0.89600000000000002,
		supporter:"B"
	},
	{
		x:0.67779999999999996,
		y:0.90739999999999998,
		supporter:"B"
	},
	{
		x:0.68340000000000001,
		y:0.92869999999999997,
		supporter:"B"
	},
	{
		x:0.73860000000000003,
		y:0.90690000000000004,
		supporter:"B"
	},
	{
		x:0.754,
		y:0.87009999999999998,
		supporter:"B"
	},
	{
		x:0.74660000000000004,
		y:0.79259999999999997,
		supporter:"B"
	},
	{
		x:0.63580000000000003,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.74519999999999997,
		y:0.9042,
		supporter:"B"
	},
	{
		x:0.75570000000000004,
		y:0.87309999999999999,
		supporter:"B"
	},
	{
		x:0.6855,
		y:0.878,
		supporter:"B"
	},
	{
		x:0.6915,
		y:0.85699999999999998,
		supporter:"B"
	},
	{
		x:0.8528,
		y:0.90810000000000002,
		supporter:"B"
	},
	{
		x:0.69740000000000002,
		y:0.89329999999999998,
		supporter:"B"
	},
	{
		x:0.63460000000000005,
		y:0.9133,
		supporter:"B"
	},
	{
		x:0.72799999999999998,
		y:0.91559999999999997,
		supporter:"B"
	},
	{
		x:0.73399999999999999,
		y:0.91139999999999999,
		supporter:"B"
	},
	{
		x:0.64429999999999998,
		y:0.91639999999999999,
		supporter:"B"
	},
	{
		x:0.69720000000000004,
		y:0.92649999999999999,
		supporter:"B"
	},
	{
		x:0.71850000000000003,
		y:0.92059999999999997,
		supporter:"B"
	},
	{
		x:0.75670000000000004,
		y:0.91910000000000003,
		supporter:"B"
	},
	{
		x:0.64900000000000002,
		y:0.91849999999999998,
		supporter:"B"
	},
	{
		x:0.66600000000000004,
		y:0.85389999999999999,
		supporter:"B"
	},
	{
		x:0.84660000000000002,
		y:0.91390000000000005,
		supporter:"B"
	},
	{
		x:0.74329999999999996,
		y:0.92530000000000001,
		supporter:"B"
	},
	{
		x:0.75109999999999999,
		y:0.92979999999999996,
		supporter:"B"
	},
	{
		x:0.84470000000000001,
		y:0.91149999999999998,
		supporter:"B"
	},
	{
		x:0.85560000000000003,
		y:0.80869999999999997,
		supporter:"B"
	},
	{
		x:0.6552,
		y:0.89839999999999998,
		supporter:"B"
	},
	{
		x:0.80740000000000001,
		y:0.88529999999999998,
		supporter:"B"
	},
	{
		x:0.66490000000000005,
		y:0.90480000000000005,
		supporter:"B"
	},
	{
		x:0.6482,
		y:0.78439999999999999,
		supporter:"B"
	},
	{
		x:0.63229999999999997,
		y:0.78610000000000002,
		supporter:"B"
	},
	{
		x:0.68769999999999998,
		y:0.93010000000000004,
		supporter:"B"
	},
	{
		x:0.63529999999999998,
		y:0.9214,
		supporter:"B"
	},
	{
		x:0.63900000000000001,
		y:0.91369999999999996,
		supporter:"B"
	},
	{
		x:0.70230000000000004,
		y:0.88490000000000002,
		supporter:"B"
	},
	{
		x:0.81179999999999997,
		y:0.88349999999999995,
		supporter:"B"
	},
	{
		x:0.6623,
		y:0.9163,
		supporter:"B"
	},
	{
		x:0.7127,
		y:0.92469999999999997,
		supporter:"B"
	},
	{
		x:0.70250000000000001,
		y:0.89429999999999998,
		supporter:"B"
	},
	{
		x:0.87060000000000004,
		y:0.81399999999999995,
		supporter:"B"
	},
	{
		x:0.64090000000000003,
		y:0.90469999999999995,
		supporter:"B"
	},
	{
		x:0.7278,
		y:0.91520000000000001,
		supporter:"B"
	},
	{
		x:0.67420000000000002,
		y:0.79579999999999995,
		supporter:"B"
	},
	{
		x:0.68600000000000005,
		y:0.71460000000000001,
		supporter:"B"
	},
	{
		x:0.66490000000000005,
		y:0.88660000000000005,
		supporter:"B"
	},
	{
		x:0.81230000000000002,
		y:0.78139999999999998,
		supporter:"B"
	},
	{
		x:0.66020000000000001,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.72950000000000004,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.72019999999999995,
		y:0.87039999999999995,
		supporter:"B"
	},
	{
		x:0.72260000000000002,
		y:0.92330000000000001,
		supporter:"B"
	},
	{
		x:0.82950000000000002,
		y:0.89270000000000005,
		supporter:"B"
	},
	{
		x:0.78159999999999996,
		y:0.88500000000000001,
		supporter:"B"
	},
	{
		x:0.64639999999999997,
		y:0.91469999999999996,
		supporter:"B"
	},
	{
		x:0.67130000000000001,
		y:0.89139999999999997,
		supporter:"B"
	},
	{
		x:0.74139999999999995,
		y:0.79800000000000004,
		supporter:"B"
	},
	{
		x:0.68389999999999995,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.69910000000000005,
		y:0.87860000000000005,
		supporter:"B"
	},
	{
		x:0.7006,
		y:0.91849999999999998,
		supporter:"B"
	},
	{
		x:0.78810000000000002,
		y:0.88890000000000002,
		supporter:"B"
	},
	{
		x:0.85070000000000001,
		y:0.91479999999999995,
		supporter:"B"
	},
	{
		x:0.66779999999999995,
		y:0.9163,
		supporter:"B"
	},
	{
		x:0.65059999999999996,
		y:0.90459999999999996,
		supporter:"B"
	},
	{
		x:0.68,
		y:0.89849999999999997,
		supporter:"B"
	},
	{
		x:0.69899999999999995,
		y:0.9234,
		supporter:"B"
	},
	{
		x:0.67069999999999996,
		y:0.90790000000000004,
		supporter:"B"
	},
	{
		x:0.65769999999999995,
		y:0.91739999999999999,
		supporter:"B"
	},
	{
		x:0.79620000000000002,
		y:0.87539999999999996,
		supporter:"B"
	},
	{
		x:0.69,
		y:0.75429999999999997,
		supporter:"B"
	},
	{
		x:0.69369999999999998,
		y:0.91120000000000001,
		supporter:"B"
	},
	{
		x:0.78029999999999999,
		y:0.89939999999999998,
		supporter:"B"
	},
	{
		x:0.74650000000000005,
		y:0.91500000000000004,
		supporter:"B"
	},
	{
		x:0.65,
		y:0.91039999999999999,
		supporter:"B"
	},
	{
		x:0.71209999999999996,
		y:0.90920000000000001,
		supporter:"B"
	},
	{
		x:0.67510000000000003,
		y:0.88419999999999999,
		supporter:"B"
	},
	{
		x:0.68179999999999996,
		y:0.80900000000000005,
		supporter:"B"
	},
	{
		x:0.78990000000000005,
		y:0.91020000000000001,
		supporter:"B"
	},
	{
		x:0.70650000000000002,
		y:0.88729999999999998,
		supporter:"B"
	},
	{
		x:0.65959999999999996,
		y:0.91100000000000003,
		supporter:"B"
	},
	{
		x:0.69820000000000004,
		y:0.91249999999999998,
		supporter:"B"
	},
	{
		x:0.6956,
		y:0.90539999999999998,
		supporter:"B"
	},
	{
		x:0.69069999999999998,
		y:0.79500000000000004,
		supporter:"B"
	},
	{
		x:0.69489999999999996,
		y:0.89690000000000003,
		supporter:"B"
	},
	{
		x:0.7631,
		y:0.88449999999999995,
		supporter:"B"
	},
	{
		x:0.75970000000000004,
		y:0.91820000000000002,
		supporter:"B"
	},
	{
		x:0.68440000000000001,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.72360000000000002,
		y:0.90629999999999999,
		supporter:"B"
	},
	{
		x:0.72929999999999995,
		y:0.90910000000000002,
		supporter:"B"
	},
	{
		x:0.67290000000000005,
		y:0.92469999999999997,
		supporter:"B"
	},
	{
		x:0.7097,
		y:0.88739999999999997,
		supporter:"B"
	},
	{
		x:0.86760000000000004,
		y:0.78969999999999996,
		supporter:"B"
	},
	{
		x:0.67169999999999996,
		y:0.88900000000000001,
		supporter:"B"
	},
	{
		x:0.71250000000000002,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.68720000000000003,
		y:0.876,
		supporter:"B"
	},
	{
		x:0.70389999999999997,
		y:0.92079999999999995,
		supporter:"B"
	},
	{
		x:0.63819999999999999,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.66900000000000004,
		y:0.89829999999999999,
		supporter:"B"
	},
	{
		x:0.86170000000000002,
		y:0.81210000000000004,
		supporter:"B"
	},
	{
		x:0.63549999999999995,
		y:0.78810000000000002,
		supporter:"B"
	},
	{
		x:0.70820000000000005,
		y:0.8901,
		supporter:"B"
	},
	{
		x:0.70050000000000001,
		y:0.9103,
		supporter:"B"
	},
	{
		x:0.78859999999999997,
		y:0.87980000000000003,
		supporter:"B"
	},
	{
		x:0.69779999999999998,
		y:0.89139999999999997,
		supporter:"B"
	},
	{
		x:0.70309999999999995,
		y:0.88719999999999999,
		supporter:"B"
	},
	{
		x:0.81789999999999996,
		y:0.89039999999999997,
		supporter:"B"
	},
	{
		x:0.72699999999999998,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.64800000000000002,
		y:0.8952,
		supporter:"B"
	},
	{
		x:0.65549999999999997,
		y:0.91620000000000001,
		supporter:"B"
	},
	{
		x:0.73809999999999998,
		y:0.92220000000000002,
		supporter:"B"
	},
	{
		x:0.69630000000000003,
		y:0.90869999999999995,
		supporter:"B"
	},
	{
		x:0.63580000000000003,
		y:0.91549999999999998,
		supporter:"B"
	},
	{
		x:0.69450000000000001,
		y:0.88070000000000004,
		supporter:"B"
	},
	{
		x:0.75819999999999999,
		y:0.871,
		supporter:"B"
	},
	{
		x:0.73909999999999998,
		y:0.92049999999999998,
		supporter:"B"
	},
	{
		x:0.75619999999999998,
		y:0.90290000000000004,
		supporter:"B"
	},
	{
		x:0.73,
		y:0.79420000000000002,
		supporter:"B"
	},
	{
		x:0.71730000000000005,
		y:0.9143,
		supporter:"B"
	},
	{
		x:0.71479999999999999,
		y:0.91590000000000005,
		supporter:"B"
	},
	{
		x:0.73970000000000002,
		y:0.92779999999999996,
		supporter:"B"
	},
	{
		x:0.63390000000000002,
		y:0.91620000000000001,
		supporter:"B"
	},
	{
		x:0.64670000000000005,
		y:0.91769999999999996,
		supporter:"B"
	},
	{
		x:0.74299999999999999,
		y:0.88629999999999998,
		supporter:"B"
	},
	{
		x:0.72689999999999999,
		y:0.85970000000000002,
		supporter:"B"
	},
	{
		x:0.6482,
		y:0.91759999999999997,
		supporter:"B"
	},
	{
		x:0.66520000000000001,
		y:0.91439999999999999,
		supporter:"B"
	},
	{
		x:0.6381,
		y:0.90029999999999999,
		supporter:"B"
	},
	{
		x:0.65010000000000001,
		y:0.91469999999999996,
		supporter:"B"
	},
	{
		x:0.77510000000000001,
		y:0.92130000000000001,
		supporter:"B"
	},
	{
		x:0.81669999999999998,
		y:0.9042,
		supporter:"B"
	},
	{
		x:0.71789999999999998,
		y:0.91080000000000005,
		supporter:"B"
	},
	{
		x:0.6613,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.67859999999999998,
		y:0.92959999999999998,
		supporter:"B"
	},
	{
		x:0.67610000000000003,
		y:0.88160000000000005,
		supporter:"B"
	},
	{
		x:0.7369,
		y:0.90310000000000001,
		supporter:"B"
	},
	{
		x:0.63729999999999998,
		y:0.89800000000000002,
		supporter:"B"
	},
	{
		x:0.69489999999999996,
		y:0.91669999999999996,
		supporter:"B"
	},
	{
		x:0.70540000000000003,
		y:0.88170000000000004,
		supporter:"B"
	},
	{
		x:0.66479999999999995,
		y:0.90190000000000003,
		supporter:"B"
	},
	{
		x:0.72989999999999999,
		y:0.90780000000000005,
		supporter:"B"
	},
	{
		x:0.71289999999999998,
		y:0.92430000000000001,
		supporter:"B"
	},
	{
		x:0.78400000000000003,
		y:0.80389999999999995,
		supporter:"B"
	},
	{
		x:0.66039999999999999,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.67349999999999999,
		y:0.91410000000000002,
		supporter:"B"
	},
	{
		x:0.6794,
		y:0.91369999999999996,
		supporter:"B"
	},
	{
		x:0.6401,
		y:0.9103,
		supporter:"B"
	},
	{
		x:0.86140000000000005,
		y:0.90259999999999996,
		supporter:"B"
	},
	{
		x:0.67030000000000001,
		y:0.89129999999999998,
		supporter:"B"
	},
	{
		x:0.87009999999999998,
		y:0.90869999999999995,
		supporter:"B"
	},
	{
		x:0.70879999999999999,
		y:0.89939999999999998,
		supporter:"B"
	},
	{
		x:0.77400000000000002,
		y:0.87839999999999996,
		supporter:"B"
	},
	{
		x:0.71730000000000005,
		y:0.93589999999999995,
		supporter:"B"
	},
	{
		x:0.6472,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.70799999999999996,
		y:0.91379999999999995,
		supporter:"B"
	},
	{
		x:0.7419,
		y:0.74860000000000004,
		supporter:"B"
	},
	{
		x:0.79459999999999997,
		y:0.87239999999999995,
		supporter:"B"
	},
	{
		x:0.70879999999999999,
		y:0.92269999999999996,
		supporter:"B"
	},
	{
		x:0.79300000000000004,
		y:0.88719999999999999,
		supporter:"B"
	},
	{
		x:0.69240000000000002,
		y:0.88019999999999998,
		supporter:"B"
	},
	{
		x:0.72660000000000002,
		y:0.90259999999999996,
		supporter:"B"
	},
	{
		x:0.86839999999999995,
		y:0.91310000000000002,
		supporter:"B"
	},
	{
		x:0.63429999999999997,
		y:0.93310000000000004,
		supporter:"B"
	},
	{
		x:0.73709999999999998,
		y:0.90449999999999997,
		supporter:"B"
	},
	{
		x:0.70409999999999995,
		y:0.90920000000000001,
		supporter:"B"
	},
	{
		x:0.72540000000000004,
		y:0.92569999999999997,
		supporter:"B"
	},
	{
		x:0.67369999999999997,
		y:0.88429999999999997,
		supporter:"B"
	},
	{
		x:0.77959999999999996,
		y:0.90300000000000002,
		supporter:"B"
	},
	{
		x:0.65580000000000005,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.64510000000000001,
		y:0.91679999999999995,
		supporter:"B"
	},
	{
		x:0.65049999999999997,
		y:0.91449999999999998,
		supporter:"B"
	},
	{
		x:0.70809999999999995,
		y:0.89610000000000001,
		supporter:"B"
	},
	{
		x:0.71079999999999999,
		y:0.88300000000000001,
		supporter:"B"
	},
	{
		x:0.75209999999999999,
		y:0.91379999999999995,
		supporter:"B"
	},
	{
		x:0.72270000000000001,
		y:0.7903,
		supporter:"B"
	},
	{
		x:0.70860000000000001,
		y:0.88880000000000003,
		supporter:"B"
	},
	{
		x:0.8397,
		y:0.94820000000000004,
		supporter:"B"
	},
	{
		x:0.76819999999999999,
		y:0.89129999999999998,
		supporter:"B"
	},
	{
		x:0.80889999999999995,
		y:0.88949999999999996,
		supporter:"B"
	},
	{
		x:0.66930000000000001,
		y:0.91520000000000001,
		supporter:"B"
	},
	{
		x:0.76680000000000004,
		y:0.89029999999999998,
		supporter:"B"
	},
	{
		x:0.87619999999999998,
		y:0.9103,
		supporter:"B"
	},
	{
		x:0.8911,
		y:0.87050000000000005,
		supporter:"B"
	},
	{
		x:0.66220000000000001,
		y:0.92449999999999999,
		supporter:"B"
	},
	{
		x:0.66559999999999997,
		y:0.82909999999999995,
		supporter:"B"
	},
	{
		x:0.68010000000000004,
		y:0.89639999999999997,
		supporter:"B"
	},
	{
		x:0.67569999999999997,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.63639999999999997,
		y:0.87160000000000004,
		supporter:"B"
	},
	{
		x:0.65069999999999995,
		y:0.91859999999999997,
		supporter:"B"
	},
	{
		x:0.73899999999999999,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.77449999999999997,
		y:0.91339999999999999,
		supporter:"B"
	},
	{
		x:0.66159999999999997,
		y:0.90380000000000005,
		supporter:"B"
	},
	{
		x:0.68579999999999997,
		y:0.8881,
		supporter:"B"
	},
	{
		x:0.6663,
		y:0.91159999999999997,
		supporter:"B"
	},
	{
		x:0.69899999999999995,
		y:0.91459999999999997,
		supporter:"B"
	},
	{
		x:0.87639999999999996,
		y:0.90329999999999999,
		supporter:"B"
	},
	{
		x:0.73640000000000005,
		y:0.90710000000000002,
		supporter:"B"
	},
	{
		x:0.79269999999999996,
		y:0.91159999999999997,
		supporter:"B"
	},
	{
		x:0.66769999999999996,
		y:0.93030000000000002,
		supporter:"B"
	},
	{
		x:0.65690000000000004,
		y:0.90749999999999997,
		supporter:"B"
	},
	{
		x:0.70489999999999997,
		y:0.9052,
		supporter:"B"
	},
	{
		x:0.8679,
		y:0.91090000000000004,
		supporter:"B"
	},
	{
		x:0.71220000000000006,
		y:0.90710000000000002,
		supporter:"B"
	},
	{
		x:0.86260000000000003,
		y:0.80479999999999996,
		supporter:"B"
	},
	{
		x:0.75549999999999995,
		y:0.88600000000000001,
		supporter:"B"
	},
	{
		x:0.71540000000000004,
		y:0.92159999999999997,
		supporter:"B"
	},
	{
		x:0.86240000000000006,
		y:0.78949999999999998,
		supporter:"B"
	},
	{
		x:0.71509999999999996,
		y:0.76190000000000002,
		supporter:"B"
	},
	{
		x:0.6492,
		y:0.90139999999999998,
		supporter:"B"
	},
	{
		x:0.69140000000000001,
		y:0.91830000000000001,
		supporter:"B"
	},
	{
		x:0.66139999999999999,
		y:0.87409999999999999,
		supporter:"B"
	},
	{
		x:0.69589999999999996,
		y:0.92410000000000003,
		supporter:"B"
	},
	{
		x:0.7137,
		y:0.91920000000000002,
		supporter:"B"
	},
	{
		x:0.72250000000000003,
		y:0.88959999999999995,
		supporter:"B"
	},
	{
		x:0.70209999999999995,
		y:0.91339999999999999,
		supporter:"B"
	},
	{
		x:0.74819999999999998,
		y:0.92710000000000004,
		supporter:"B"
	},
	{
		x:0.70520000000000005,
		y:0.91,
		supporter:"B"
	},
	{
		x:0.78810000000000002,
		y:0.93600000000000005,
		supporter:"B"
	},
	{
		x:0.65790000000000004,
		y:0.91569999999999996,
		supporter:"B"
	},
	{
		x:0.64480000000000004,
		y:0.91539999999999999,
		supporter:"B"
	},
	{
		x:0.6331,
		y:0.79730000000000001,
		supporter:"B"
	},
	{
		x:0.63990000000000002,
		y:0.82879999999999998,
		supporter:"B"
	},
	{
		x:0.70889999999999997,
		y:0.92390000000000005,
		supporter:"B"
	},
	{
		x:0.70889999999999997,
		y:0.9204,
		supporter:"B"
	},
	{
		x:0.75449999999999995,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.65810000000000002,
		y:0.90559999999999996,
		supporter:"B"
	},
	{
		x:0.70320000000000005,
		y:0.92290000000000005,
		supporter:"B"
	},
	{
		x:0.67910000000000004,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.63500000000000001,
		y:0.91239999999999999,
		supporter:"B"
	},
	{
		x:0.69350000000000001,
		y:0.9,
		supporter:"B"
	},
	{
		x:0.75329999999999997,
		y:0.91339999999999999,
		supporter:"B"
	},
	{
		x:0.7016,
		y:0.88619999999999999,
		supporter:"B"
	},
	{
		x:0.63400000000000001,
		y:0.89839999999999998,
		supporter:"B"
	},
	{
		x:0.77690000000000003,
		y:0.92400000000000004,
		supporter:"B"
	},
	{
		x:0.69520000000000004,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.64329999999999998,
		y:0.78259999999999996,
		supporter:"B"
	},
	{
		x:0.65539999999999998,
		y:0.89639999999999997,
		supporter:"B"
	},
	{
		x:0.74450000000000005,
		y:0.91469999999999996,
		supporter:"B"
	},
	{
		x:0.67010000000000003,
		y:0.91720000000000002,
		supporter:"B"
	},
	{
		x:0.6845,
		y:0.91700000000000004,
		supporter:"B"
	},
	{
		x:0.72070000000000001,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.87280000000000002,
		y:0.90849999999999997,
		supporter:"B"
	},
	{
		x:0.64470000000000005,
		y:0.92789999999999995,
		supporter:"B"
	},
	{
		x:0.7319,
		y:0.87150000000000005,
		supporter:"B"
	},
	{
		x:0.63139999999999996,
		y:0.8972,
		supporter:"B"
	},
	{
		x:0.751,
		y:0.78049999999999997,
		supporter:"B"
	},
	{
		x:0.70479999999999998,
		y:0.87260000000000004,
		supporter:"B"
	},
	{
		x:0.70840000000000003,
		y:0.92430000000000001,
		supporter:"B"
	},
	{
		x:0.65029999999999999,
		y:0.91649999999999998,
		supporter:"B"
	},
	{
		x:0.69630000000000003,
		y:0.86960000000000004,
		supporter:"B"
	},
	{
		x:0.64810000000000001,
		y:0.90449999999999997,
		supporter:"B"
	},
	{
		x:0.65529999999999999,
		y:0.92479999999999996,
		supporter:"B"
	},
	{
		x:0.76390000000000002,
		y:0.78439999999999999,
		supporter:"B"
	},
	{
		x:0.77569999999999995,
		y:0.92420000000000002,
		supporter:"B"
	},
	{
		x:0.63949999999999996,
		y:0.80030000000000001,
		supporter:"B"
	},
	{
		x:0.66679999999999995,
		y:0.90939999999999999,
		supporter:"B"
	},
	{
		x:0.88429999999999997,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.65690000000000004,
		y:0.81120000000000003,
		supporter:"B"
	},
	{
		x:0.66290000000000004,
		y:0.89880000000000004,
		supporter:"B"
	},
	{
		x:0.65149999999999997,
		y:0.92259999999999998,
		supporter:"B"
	},
	{
		x:0.70989999999999998,
		y:0.91459999999999997,
		supporter:"B"
	},
	{
		x:0.6633,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.88360000000000005,
		y:0.90690000000000004,
		supporter:"B"
	},
	{
		x:0.63939999999999997,
		y:0.77659999999999996,
		supporter:"B"
	},
	{
		x:0.68720000000000003,
		y:0.8871,
		supporter:"B"
	},
	{
		x:0.66359999999999997,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.67859999999999998,
		y:0.86950000000000005,
		supporter:"B"
	},
	{
		x:0.75490000000000002,
		y:0.90010000000000001,
		supporter:"B"
	},
	{
		x:0.76790000000000003,
		y:0.89990000000000003,
		supporter:"B"
	},
	{
		x:0.63200000000000001,
		y:0.90969999999999995,
		supporter:"B"
	},
	{
		x:0.79649999999999999,
		y:0.94710000000000005,
		supporter:"B"
	},
	{
		x:0.67849999999999999,
		y:0.90910000000000002,
		supporter:"B"
	},
	{
		x:0.64180000000000004,
		y:0.76670000000000005,
		supporter:"B"
	},
	{
		x:0.70920000000000005,
		y:0.8901,
		supporter:"B"
	},
	{
		x:0.84809999999999997,
		y:0.91169999999999995,
		supporter:"B"
	},
	{
		x:0.69179999999999997,
		y:0.92479999999999996,
		supporter:"B"
	},
	{
		x:0.63900000000000001,
		y:0.92579999999999996,
		supporter:"B"
	},
	{
		x:0.749,
		y:0.86760000000000004,
		supporter:"B"
	},
	{
		x:0.65710000000000002,
		y:0.88949999999999996,
		supporter:"B"
	},
	{
		x:0.69040000000000001,
		y:0.9214,
		supporter:"B"
	},
	{
		x:0.67359999999999998,
		y:0.9214,
		supporter:"B"
	},
	{
		x:0.86150000000000004,
		y:0.91769999999999996,
		supporter:"B"
	},
	{
		x:0.70860000000000001,
		y:0.92349999999999999,
		supporter:"B"
	},
	{
		x:0.71360000000000001,
		y:0.91849999999999998,
		supporter:"B"
	},
	{
		x:0.74729999999999996,
		y:0.91180000000000005,
		supporter:"B"
	},
	{
		x:0.95169999999999999,
		y:0.90139999999999998,
		supporter:"B"
	},
	{
		x:0.76270000000000004,
		y:0.88370000000000004,
		supporter:"B"
	},
	{
		x:0.7177,
		y:0.92390000000000005,
		supporter:"B"
	},
	{
		x:0.76259999999999994,
		y:0.79149999999999998,
		supporter:"B"
	},
	{
		x:0.69420000000000004,
		y:0.9274,
		supporter:"B"
	},
	{
		x:0.79830000000000001,
		y:0.7752,
		supporter:"B"
	},
	{
		x:0.84160000000000001,
		y:0.90159999999999996,
		supporter:"B"
	},
	{
		x:0.7157,
		y:0.90180000000000005,
		supporter:"B"
	},
	{
		x:0.64759999999999995,
		y:0.92190000000000005,
		supporter:"B"
	},
	{
		x:0.63880000000000003,
		y:0.79790000000000005,
		supporter:"B"
	},
	{
		x:0.76659999999999995,
		y:0.88929999999999998,
		supporter:"B"
	},
	{
		x:0.75039999999999996,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.75749999999999995,
		y:0.90290000000000004,
		supporter:"B"
	},
	{
		x:0.69389999999999996,
		y:0.89290000000000003,
		supporter:"B"
	},
	{
		x:0.64600000000000002,
		y:0.90059999999999996,
		supporter:"B"
	},
	{
		x:0.70120000000000005,
		y:0.9264,
		supporter:"B"
	},
	{
		x:0.65759999999999996,
		y:0.89939999999999998,
		supporter:"B"
	},
	{
		x:0.69040000000000001,
		y:0.89659999999999995,
		supporter:"B"
	},
	{
		x:0.78749999999999998,
		y:0.9224,
		supporter:"B"
	},
	{
		x:0.63560000000000005,
		y:0.91369999999999996,
		supporter:"B"
	},
	{
		x:0.81869999999999998,
		y:0.8115,
		supporter:"B"
	},
	{
		x:0.84919999999999995,
		y:0.78769999999999996,
		supporter:"B"
	},
	{
		x:0.81589999999999996,
		y:0.89,
		supporter:"B"
	},
	{
		x:0.65920000000000001,
		y:0.90329999999999999,
		supporter:"B"
	},
	{
		x:0.87090000000000001,
		y:0.81140000000000001,
		supporter:"B"
	},
	{
		x:0.69440000000000002,
		y:0.89470000000000005,
		supporter:"B"
	},
	{
		x:0.83660000000000001,
		y:0.78790000000000004,
		supporter:"B"
	},
	{
		x:0.70579999999999998,
		y:0.78749999999999998,
		supporter:"B"
	},
	{
		x:0.63800000000000001,
		y:0.90910000000000002,
		supporter:"B"
	},
	{
		x:0.71609999999999996,
		y:0.90339999999999998,
		supporter:"B"
	},
	{
		x:0.65859999999999996,
		y:0.92630000000000001,
		supporter:"B"
	},
	{
		x:0.65349999999999997,
		y:0.91920000000000002,
		supporter:"B"
	},
	{
		x:0.75749999999999995,
		y:0.88890000000000002,
		supporter:"B"
	},
	{
		x:0.73180000000000001,
		y:0.90600000000000003,
		supporter:"B"
	},
	{
		x:0.63729999999999998,
		y:0.91320000000000001,
		supporter:"B"
	},
	{
		x:0.70689999999999997,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.67800000000000005,
		y:0.89119999999999999,
		supporter:"B"
	},
	{
		x:0.70320000000000005,
		y:0.92100000000000004,
		supporter:"B"
	},
	{
		x:0.86960000000000004,
		y:0.90939999999999999,
		supporter:"B"
	},
	{
		x:0.74829999999999997,
		y:0.7823,
		supporter:"B"
	},
	{
		x:0.86709999999999998,
		y:0.94550000000000001,
		supporter:"B"
	},
	{
		x:0.68600000000000005,
		y:0.89180000000000004,
		supporter:"B"
	},
	{
		x:0.65659999999999996,
		y:0.92230000000000001,
		supporter:"B"
	},
	{
		x:0.84030000000000005,
		y:0.79520000000000002,
		supporter:"B"
	},
	{
		x:0.7,
		y:0.92259999999999998,
		supporter:"B"
	},
	{
		x:0.68640000000000001,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.79459999999999997,
		y:0.90469999999999995,
		supporter:"B"
	},
	{
		x:0.72440000000000004,
		y:0.92810000000000004,
		supporter:"B"
	},
	{
		x:0.73380000000000001,
		y:0.8115,
		supporter:"B"
	},
	{
		x:0.74480000000000002,
		y:0.91110000000000002,
		supporter:"B"
	},
	{
		x:0.70799999999999996,
		y:0.78969999999999996,
		supporter:"B"
	},
	{
		x:0.6663,
		y:0.89729999999999999,
		supporter:"B"
	},
	{
		x:0.65659999999999996,
		y:0.90810000000000002,
		supporter:"B"
	},
	{
		x:0.81830000000000003,
		y:0.7742,
		supporter:"B"
	},
	{
		x:0.67120000000000002,
		y:0.89829999999999999,
		supporter:"B"
	},
	{
		x:0.76270000000000004,
		y:0.88380000000000003,
		supporter:"B"
	},
	{
		x:0.75160000000000005,
		y:0.91649999999999998,
		supporter:"B"
	},
	{
		x:0.79859999999999998,
		y:0.91220000000000001,
		supporter:"B"
	},
	{
		x:0.86150000000000004,
		y:0.84050000000000002,
		supporter:"B"
	},
	{
		x:0.65649999999999997,
		y:0.80289999999999995,
		supporter:"B"
	},
	{
		x:0.63549999999999995,
		y:0.92789999999999995,
		supporter:"B"
	},
	{
		x:0.67610000000000003,
		y:0.88480000000000003,
		supporter:"B"
	},
	{
		x:0.66879999999999995,
		y:0.78900000000000003,
		supporter:"B"
	},
	{
		x:0.6825,
		y:0.92230000000000001,
		supporter:"B"
	},
	{
		x:0.70440000000000003,
		y:0.89080000000000004,
		supporter:"B"
	},
	{
		x:0.72799999999999998,
		y:0.9224,
		supporter:"B"
	},
	{
		x:0.66959999999999997,
		y:0.89,
		supporter:"B"
	},
	{
		x:0.73089999999999999,
		y:0.91080000000000005,
		supporter:"B"
	},
	{
		x:0.70530000000000004,
		y:0.91010000000000002,
		supporter:"B"
	},
	{
		x:0.64670000000000005,
		y:0.91600000000000004,
		supporter:"B"
	},
	{
		x:0.95189999999999997,
		y:0.89870000000000005,
		supporter:"B"
	},
	{
		x:0.78300000000000003,
		y:0.79579999999999995,
		supporter:"B"
	},
	{
		x:0.70289999999999997,
		y:0.92500000000000004,
		supporter:"B"
	},
	{
		x:0.69269999999999998,
		y:0.90610000000000002,
		supporter:"B"
	},
	{
		x:0.63959999999999995,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.65710000000000002,
		y:0.82130000000000003,
		supporter:"B"
	},
	{
		x:0.71030000000000004,
		y:0.78380000000000005,
		supporter:"B"
	},
	{
		x:0.76749999999999996,
		y:0.88629999999999998,
		supporter:"B"
	},
	{
		x:0.74590000000000001,
		y:0.78539999999999999,
		supporter:"B"
	},
	{
		x:0.86119999999999997,
		y:0.91010000000000002,
		supporter:"B"
	},
	{
		x:0.69069999999999998,
		y:0.92689999999999995,
		supporter:"B"
	},
	{
		x:0.65129999999999999,
		y:0.9113,
		supporter:"B"
	},
	{
		x:0.63460000000000005,
		y:0.91090000000000004,
		supporter:"B"
	},
	{
		x:0.78859999999999997,
		y:0.80249999999999999,
		supporter:"B"
	},
	{
		x:0.88560000000000005,
		y:0.91,
		supporter:"B"
	},
	{
		x:0.69110000000000005,
		y:0.89400000000000002,
		supporter:"B"
	},
	{
		x:0.86560000000000004,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.63449999999999995,
		y:0.90090000000000003,
		supporter:"B"
	},
	{
		x:0.86680000000000001,
		y:0.81089999999999995,
		supporter:"B"
	},
	{
		x:0.70020000000000004,
		y:0.92100000000000004,
		supporter:"B"
	},
	{
		x:0.79590000000000005,
		y:0.91459999999999997,
		supporter:"B"
	},
	{
		x:0.85850000000000004,
		y:0.90939999999999999,
		supporter:"B"
	},
	{
		x:0.66039999999999999,
		y:0.78649999999999998,
		supporter:"B"
	},
	{
		x:0.65720000000000001,
		y:0.90339999999999998,
		supporter:"B"
	},
	{
		x:0.69510000000000005,
		y:0.90600000000000003,
		supporter:"B"
	},
	{
		x:0.64859999999999995,
		y:0.9254,
		supporter:"B"
	},
	{
		x:0.69140000000000001,
		y:0.9274,
		supporter:"B"
	},
	{
		x:0.63170000000000004,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.64370000000000005,
		y:0.92169999999999996,
		supporter:"B"
	},
	{
		x:0.83360000000000001,
		y:0.88660000000000005,
		supporter:"B"
	},
	{
		x:0.68679999999999997,
		y:0.90600000000000003,
		supporter:"B"
	},
	{
		x:0.67390000000000005,
		y:0.90849999999999997,
		supporter:"B"
	},
	{
		x:0.73709999999999998,
		y:0.7994,
		supporter:"B"
	},
	{
		x:0.65720000000000001,
		y:0.90439999999999998,
		supporter:"B"
	},
	{
		x:0.64739999999999998,
		y:0.80869999999999997,
		supporter:"B"
	},
	{
		x:0.68,
		y:0.87109999999999999,
		supporter:"B"
	},
	{
		x:0.85919999999999996,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.77029999999999998,
		y:0.90710000000000002,
		supporter:"B"
	},
	{
		x:0.85760000000000003,
		y:0.90380000000000005,
		supporter:"B"
	},
	{
		x:0.88249999999999995,
		y:0.9083,
		supporter:"B"
	},
	{
		x:0.6915,
		y:0.91139999999999999,
		supporter:"B"
	},
	{
		x:0.6986,
		y:0.88200000000000001,
		supporter:"B"
	},
	{
		x:0.66620000000000001,
		y:0.90300000000000002,
		supporter:"B"
	},
	{
		x:0.7843,
		y:0.91879999999999995,
		supporter:"B"
	},
	{
		x:0.68540000000000001,
		y:0.92579999999999996,
		supporter:"B"
	},
	{
		x:0.77339999999999998,
		y:0.79300000000000004,
		supporter:"B"
	},
	{
		x:0.69499999999999995,
		y:0.9022,
		supporter:"B"
	},
	{
		x:0.77359999999999995,
		y:0.90149999999999997,
		supporter:"B"
	},
	{
		x:0.78680000000000005,
		y:0.89990000000000003,
		supporter:"B"
	},
	{
		x:0.70350000000000001,
		y:0.91659999999999997,
		supporter:"B"
	},
	{
		x:0.71079999999999999,
		y:0.91420000000000001,
		supporter:"B"
	},
	{
		x:0.85880000000000001,
		y:0.91310000000000002,
		supporter:"B"
	},
	{
		x:0.87060000000000004,
		y:0.89870000000000005,
		supporter:"B"
	},
	{
		x:0.66359999999999997,
		y:0.91069999999999995,
		supporter:"B"
	},
	{
		x:0.66420000000000001,
		y:0.87619999999999998,
		supporter:"B"
	},
	{
		x:0.65880000000000005,
		y:0.92169999999999996,
		supporter:"B"
	},
	{
		x:0.71099999999999997,
		y:0.89270000000000005,
		supporter:"B"
	},
	{
		x:0.68430000000000002,
		y:0.89480000000000004,
		supporter:"B"
	},
	{
		x:0.63790000000000002,
		y:0.90910000000000002,
		supporter:"B"
	},
	{
		x:0.76780000000000004,
		y:0.93720000000000003,
		supporter:"B"
	},
	{
		x:0.7611,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.8,
		y:0.88560000000000005,
		supporter:"B"
	},
	{
		x:0.73140000000000005,
		y:0.8095,
		supporter:"B"
	},
	{
		x:0.64739999999999998,
		y:0.91749999999999998,
		supporter:"B"
	},
	{
		x:0.76249999999999996,
		y:0.77929999999999999,
		supporter:"B"
	},
	{
		x:0.73799999999999999,
		y:0.92400000000000004,
		supporter:"B"
	},
	{
		x:0.70809999999999995,
		y:0.91890000000000005,
		supporter:"B"
	},
	{
		x:0.70130000000000003,
		y:0.89729999999999999,
		supporter:"B"
	},
	{
		x:0.7117,
		y:0.92190000000000005,
		supporter:"B"
	},
	{
		x:0.68289999999999995,
		y:0.90749999999999997,
		supporter:"B"
	},
	{
		x:0.63019999999999998,
		y:0.90980000000000005,
		supporter:"B"
	},
	{
		x:0.71689999999999998,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.63580000000000003,
		y:0.91300000000000003,
		supporter:"B"
	},
	{
		x:0.70279999999999998,
		y:0.87909999999999999,
		supporter:"B"
	},
	{
		x:0.64019999999999999,
		y:0.9153,
		supporter:"B"
	},
	{
		x:0.70020000000000004,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.66669999999999996,
		y:0.90459999999999996,
		supporter:"B"
	},
	{
		x:0.71719999999999995,
		y:0.92959999999999998,
		supporter:"B"
	},
	{
		x:0.68759999999999999,
		y:0.92169999999999996,
		supporter:"B"
	},
	{
		x:0.70669999999999999,
		y:0.92459999999999998,
		supporter:"B"
	},
	{
		x:0.73319999999999996,
		y:0.90780000000000005,
		supporter:"B"
	},
	{
		x:0.67659999999999998,
		y:0.91180000000000005,
		supporter:"B"
	},
	{
		x:0.86129999999999995,
		y:0.90329999999999999,
		supporter:"B"
	},
	{
		x:0.86270000000000002,
		y:0.90139999999999998,
		supporter:"B"
	},
	{
		x:0.6724,
		y:0.91310000000000002,
		supporter:"B"
	},
	{
		x:0.84719999999999995,
		y:0.8075,
		supporter:"B"
	},
	{
		x:0.83420000000000005,
		y:0.91110000000000002,
		supporter:"B"
	},
	{
		x:0.77139999999999997,
		y:0.88560000000000005,
		supporter:"B"
	},
	{
		x:0.69710000000000005,
		y:0.92149999999999999,
		supporter:"B"
	},
	{
		x:0.84630000000000005,
		y:0.90429999999999999,
		supporter:"B"
	},
	{
		x:0.70209999999999995,
		y:0.92049999999999998,
		supporter:"B"
	},
	{
		x:0.63519999999999999,
		y:0.92359999999999998,
		supporter:"B"
	},
	{
		x:0.6502,
		y:0.77949999999999997,
		supporter:"B"
	},
	{
		x:0.68620000000000003,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.68979999999999997,
		y:0.8881,
		supporter:"B"
	},
	{
		x:0.70709999999999995,
		y:0.91830000000000001,
		supporter:"B"
	},
	{
		x:0.67610000000000003,
		y:0.91300000000000003,
		supporter:"B"
	},
	{
		x:0.94020000000000004,
		y:0.89300000000000002,
		supporter:"B"
	},
	{
		x:0.75900000000000001,
		y:0.92369999999999997,
		supporter:"B"
	},
	{
		x:0.63109999999999999,
		y:0.92920000000000003,
		supporter:"B"
	},
	{
		x:0.6825,
		y:0.79079999999999995,
		supporter:"B"
	},
	{
		x:0.70779999999999998,
		y:0.89,
		supporter:"B"
	},
	{
		x:0.65339999999999998,
		y:0.89770000000000005,
		supporter:"B"
	},
	{
		x:0.70479999999999998,
		y:0.92379999999999995,
		supporter:"B"
	},
	{
		x:0.86770000000000003,
		y:0.90539999999999998,
		supporter:"B"
	},
	{
		x:0.6774,
		y:0.90469999999999995,
		supporter:"B"
	},
	{
		x:0.63249999999999995,
		y:0.88619999999999999,
		supporter:"B"
	},
	{
		x:0.68300000000000005,
		y:0.91739999999999999,
		supporter:"B"
	},
	{
		x:0.68220000000000003,
		y:0.92320000000000002,
		supporter:"B"
	},
	{
		x:0.84840000000000004,
		y:0.90510000000000002,
		supporter:"B"
	},
	{
		x:0.66669999999999996,
		y:0.91200000000000003,
		supporter:"B"
	},
	{
		x:0.65710000000000002,
		y:0.80610000000000004,
		supporter:"B"
	},
	{
		x:0.69350000000000001,
		y:0.91400000000000003,
		supporter:"B"
	},
	{
		x:0.73719999999999997,
		y:0.89659999999999995,
		supporter:"B"
	},
	{
		x:0.71609999999999996,
		y:0.92669999999999997,
		supporter:"B"
	},
	{
		x:0.78720000000000001,
		y:0.89859999999999995,
		supporter:"B"
	},
	{
		x:0.68569999999999998,
		y:0.89849999999999997,
		supporter:"B"
	},
	{
		x:0.74529999999999996,
		y:0.91659999999999997,
		supporter:"B"
	},
	{
		x:0.71289999999999998,
		y:0.88219999999999998,
		supporter:"B"
	},
	{
		x:0.65629999999999999,
		y:0.78249999999999997,
		supporter:"B"
	},
	{
		x:0.77949999999999997,
		y:0.92500000000000004,
		supporter:"B"
	},
	{
		x:0.65769999999999995,
		y:0.90690000000000004,
		supporter:"B"
	},
	{
		x:0.77010000000000001,
		y:0.88080000000000003,
		supporter:"B"
	},
	{
		x:0.87160000000000004,
		y:0.90080000000000005,
		supporter:"B"
	},
	{
		x:0.72899999999999998,
		y:0.92749999999999999,
		supporter:"B"
	},
	{
		x:0.77080000000000004,
		y:0.90149999999999997,
		supporter:"B"
	},
	{
		x:0.70569999999999999,
		y:0.79500000000000004,
		supporter:"B"
	},
	{
		x:0.85089999999999999,
		y:0.88539999999999996,
		supporter:"B"
	},
	{
		x:0.68340000000000001,
		y:0.89929999999999999,
		supporter:"B"
	},
	{
		x:0.67530000000000001,
		y:0.88160000000000005,
		supporter:"B"
	},
	{
		x:0.68710000000000004,
		y:0.91910000000000003,
		supporter:"B"
	},
	{
		x:0.66890000000000005,
		y:0.9052,
		supporter:"B"
	},
	{
		x:0.77929999999999999,
		y:0.89490000000000003,
		supporter:"B"
	},
	{
		x:0.69350000000000001,
		y:0.88280000000000003,
		supporter:"B"
	},
	{
		x:0.85719999999999996,
		y:0.90939999999999999,
		supporter:"B"
	},
	{
		x:0.69589999999999996,
		y:0.91259999999999997,
		supporter:"B"
	},
	{
		x:0.65880000000000005,
		y:0.78890000000000005,
		supporter:"B"
	},
	{
		x:0.66539999999999999,
		y:0.8851,
		supporter:"B"
	},
	{
		x:0.67179999999999995,
		y:0.80720000000000003,
		supporter:"B"
	},
	{
		x:0.69199999999999995,
		y:0.88880000000000003,
		supporter:"B"
	},
	{
		x:0.77110000000000001,
		y:0.89019999999999999,
		supporter:"B"
	},
	{
		x:0.76439999999999997,
		y:0.87209999999999999,
		supporter:"B"
	},
	{
		x:0.70689999999999997,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.65910000000000002,
		y:0.90810000000000002,
		supporter:"B"
	},
	{
		x:0.64729999999999999,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.64470000000000005,
		y:0.91769999999999996,
		supporter:"B"
	},
	{
		x:0.76590000000000003,
		y:0.88700000000000001,
		supporter:"B"
	},
	{
		x:0.69769999999999999,
		y:0.9093,
		supporter:"B"
	},
	{
		x:0.64959999999999996,
		y:0.8992,
		supporter:"B"
	},
	{
		x:0.86780000000000002,
		y:0.90480000000000005,
		supporter:"B"
	},
	{
		x:0.75370000000000004,
		y:0.88529999999999998,
		supporter:"B"
	},
	{
		x:0.71840000000000004,
		y:0.86709999999999998,
		supporter:"B"
	},
	{
		x:0.70030000000000003,
		y:0.89370000000000005,
		supporter:"B"
	},
	{
		x:0.66469999999999996,
		y:0.91879999999999995,
		supporter:"B"
	},
	{
		x:0.65029999999999999,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.73529999999999995,
		y:0.87949999999999995,
		supporter:"B"
	},
	{
		x:0.7107,
		y:0.92730000000000001,
		supporter:"B"
	},
	{
		x:0.66710000000000003,
		y:0.91239999999999999,
		supporter:"B"
	},
	{
		x:0.6986,
		y:0.7994,
		supporter:"B"
	},
	{
		x:0.6401,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.63890000000000002,
		y:0.90259999999999996,
		supporter:"B"
	},
	{
		x:0.6321,
		y:0.9143,
		supporter:"B"
	},
	{
		x:0.82310000000000005,
		y:0.89870000000000005,
		supporter:"B"
	},
	{
		x:0.69940000000000002,
		y:0.93100000000000005,
		supporter:"B"
	},
	{
		x:0.82879999999999998,
		y:0.91710000000000003,
		supporter:"B"
	},
	{
		x:0.71150000000000002,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.70860000000000001,
		y:0.91779999999999995,
		supporter:"B"
	},
	{
		x:0.88959999999999995,
		y:0.91390000000000005,
		supporter:"B"
	},
	{
		x:0.67410000000000003,
		y:0.90059999999999996,
		supporter:"B"
	},
	{
		x:0.71530000000000005,
		y:0.90280000000000005,
		supporter:"B"
	},
	{
		x:0.66769999999999996,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.67749999999999999,
		y:0.91180000000000005,
		supporter:"B"
	},
	{
		x:0.87619999999999998,
		y:0.9073,
		supporter:"B"
	},
	{
		x:0.84360000000000002,
		y:0.90580000000000005,
		supporter:"B"
	},
	{
		x:0.68610000000000004,
		y:0.7883,
		supporter:"B"
	},
	{
		x:0.63759999999999994,
		y:0.86380000000000001,
		supporter:"B"
	},
	{
		x:0.71509999999999996,
		y:0.91039999999999999,
		supporter:"B"
	},
	{
		x:0.65949999999999998,
		y:0.91839999999999999,
		supporter:"B"
	},
	{
		x:0.752,
		y:0.89149999999999996,
		supporter:"B"
	},
	{
		x:0.71560000000000001,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.69110000000000005,
		y:0.86529999999999996,
		supporter:"B"
	},
	{
		x:0.83420000000000005,
		y:0.90859999999999996,
		supporter:"B"
	},
	{
		x:0.87549999999999994,
		y:0.91359999999999997,
		supporter:"B"
	},
	{
		x:0.68130000000000002,
		y:0.92869999999999997,
		supporter:"B"
	},
	{
		x:0.76490000000000002,
		y:0.88980000000000004,
		supporter:"B"
	},
	{
		x:0.6784,
		y:0.88719999999999999,
		supporter:"B"
	},
	{
		x:0.64419999999999999,
		y:0.91690000000000005,
		supporter:"B"
	},
	{
		x:0.69569999999999999,
		y:0.91100000000000003,
		supporter:"B"
	},
	{
		x:0.66649999999999998,
		y:0.84930000000000005,
		supporter:"B"
	},
	{
		x:0.81279999999999997,
		y:0.91090000000000004,
		supporter:"B"
	},
	{
		x:0.69889999999999997,
		y:0.90990000000000004,
		supporter:"B"
	},
	{
		x:0.66759999999999997,
		y:0.91279999999999994,
		supporter:"B"
	},
	{
		x:0.86829999999999996,
		y:0.91110000000000002,
		supporter:"B"
	},
	{
		x:0.72499999999999998,
		y:0.81230000000000002,
		supporter:"B"
	},
	{
		x:0.65529999999999999,
		y:0.79759999999999998,
		supporter:"B"
	},
	{
		x:0.87360000000000004,
		y:0.9042,
		supporter:"B"
	},
	{
		x:0.68920000000000003,
		y:0.91820000000000002,
		supporter:"B"
	},
	{
		x:0.65790000000000004,
		y:0.80420000000000003,
		supporter:"B"
	},
	{
		x:0.66900000000000004,
		y:0.88639999999999997,
		supporter:"B"
	},
	{
		x:0.71379999999999999,
		y:0.91759999999999997,
		supporter:"B"
	},
	{
		x:0.82699999999999996,
		y:0.88670000000000004,
		supporter:"B"
	},
	{
		x:0.64239999999999997,
		y:0.90439999999999998,
		supporter:"B"
	},
	{
		x:0.80820000000000003,
		y:0.93930000000000002,
		supporter:"B"
	},
	{
		x:0.67749999999999999,
		y:0.91679999999999995,
		supporter:"B"
	},
	{
		x:0.69540000000000002,
		y:0.89080000000000004,
		supporter:"B"
	},
	{
		x:0.6472,
		y:0.91600000000000004,
		supporter:"B"
	},
	{
		x:0.85370000000000001,
		y:0.83279999999999998,
		supporter:"B"
	},
	{
		x:0.72829999999999995,
		y:0.91710000000000003,
		supporter:"B"
	},
	{
		x:0.80840000000000001,
		y:0.8901,
		supporter:"B"
	},
	{
		x:0.63970000000000005,
		y:0.77229999999999999,
		supporter:"B"
	},
	{
		x:0.6613,
		y:0.88280000000000003,
		supporter:"B"
	},
	{
		x:0.68620000000000003,
		y:0.9204,
		supporter:"B"
	},
	{
		x:0.66180000000000005,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.63990000000000002,
		y:0.91579999999999995,
		supporter:"B"
	},
	{
		x:0.71919999999999995,
		y:0.91279999999999994,
		supporter:"B"
	},
	{
		x:0.66639999999999999,
		y:0.9194,
		supporter:"B"
	},
	{
		x:0.64790000000000003,
		y:0.9224,
		supporter:"B"
	},
	{
		x:0.71,
		y:0.89339999999999997,
		supporter:"B"
	},
	{
		x:0.81,
		y:0.87250000000000005,
		supporter:"B"
	},
	{
		x:0.71330000000000005,
		y:0.92769999999999997,
		supporter:"B"
	},
	{
		x:0.65249999999999997,
		y:0.89239999999999997,
		supporter:"B"
	},
	{
		x:0.63370000000000004,
		y:0.92169999999999996,
		supporter:"B"
	},
	{
		x:0.69920000000000004,
		y:0.91290000000000004,
		supporter:"B"
	},
	{
		x:0.73760000000000003,
		y:0.86539999999999995,
		supporter:"B"
	},
	{
		x:0.69799999999999995,
		y:0.93359999999999999,
		supporter:"B"
	},
	{
		x:0.66049999999999998,
		y:0.90590000000000004,
		supporter:"B"
	},
	{
		x:0.71399999999999997,
		y:0.89149999999999996,
		supporter:"B"
	},
	{
		x:0.6835,
		y:0.90820000000000001,
		supporter:"B"
	},
	{
		x:0.68149999999999999,
		y:0.91679999999999995,
		supporter:"B"
	},
	{
		x:0.81540000000000001,
		y:0.77239999999999998,
		supporter:"B"
	},
	{
		x:0.82199999999999995,
		y:0.89400000000000002,
		supporter:"B"
	},
	{
		x:0.67589999999999995,
		y:0.90180000000000005,
		supporter:"B"
	},
	{
		x:0.68730000000000002,
		y:0.92400000000000004,
		supporter:"B"
	},
	{
		x:0.64929999999999999,
		y:0.91759999999999997,
		supporter:"B"
	},
	{
		x:0.68959999999999999,
		y:0.90559999999999996,
		supporter:"B"
	},
	{
		x:0.88080000000000003,
		y:0.90439999999999998,
		supporter:"B"
	},
	{
		x:0.72340000000000004,
		y:0.91620000000000001,
		supporter:"B"
	},
	{
		x:0.64259999999999995,
		y:0.9173,
		supporter:"B"
	},
	{
		x:0.69069999999999998,
		y:0.92279999999999995,
		supporter:"B"
	},
	{
		x:0.69289999999999996,
		y:0.90849999999999997,
		supporter:"B"
	},
	{
		x:0.77400000000000002,
		y:0.87519999999999998,
		supporter:"B"
	},
	{
		x:0.7631,
		y:0.85940000000000005,
		supporter:"B"
	},
	{
		x:0.71020000000000005,
		y:0.90510000000000002,
		supporter:"B"
	},
	{
		x:0.68789999999999996,
		y:0.91849999999999998,
		supporter:"B"
	},
	{
		x:0.6764,
		y:0.92259999999999998,
		supporter:"B"
	},
	{
		x:0.745,
		y:0.89680000000000004,
		supporter:"B"
	},
	{
		x:0.67430000000000001,
		y:0.89700000000000002,
		supporter:"B"
	},
	{
		x:0.67879999999999996,
		y:0.91869999999999996,
		supporter:"B"
	},
	{
		x:0.71579999999999999,
		y:0.92120000000000002,
		supporter:"B"
	},
	{
		x:0.67479999999999996,
		y:0.89190000000000003,
		supporter:"B"
	},
	{
		x:0.67079999999999995,
		y:0.92110000000000003,
		supporter:"B"
	},
	{
		x:0.77059999999999995,
		y:0.88380000000000003,
		supporter:"B"
	},
	{
		x:0.7097,
		y:0.92100000000000004,
		supporter:"B"
	},
	{
		x:0.75109999999999999,
		y:0.92110000000000003,
		supporter:"B"
	},
	{
		x:0.70430000000000004,
		y:0.89270000000000005,
		supporter:"B"
	},
	{
		x:0.72819999999999996,
		y:0.92649999999999999,
		supporter:"B"
	},
	{
		x:0.7571,
		y:0.8175,
		supporter:"B"
	},
	{
		x:0.69740000000000002,
		y:0.88060000000000005,
		supporter:"B"
	},
	{
		x:0.63249999999999995,
		y:0.92520000000000002,
		supporter:"B"
	},
	{
		x:0.74339999999999995,
		y:0.91239999999999999,
		supporter:"B"
	},
	{
		x:0.63629999999999998,
		y:0.92130000000000001,
		supporter:"B"
	},
	{
		x:0.69569999999999999,
		y:0.93059999999999998,
		supporter:"B"
	},
	{
		x:0.68759999999999999,
		y:0.91190000000000004,
		supporter:"B"
	},
	{
		x:0.875,
		y:0.91379999999999995,
		supporter:"B"
	},
	{
		x:0.71589999999999998,
		y:0.92390000000000005,
		supporter:"B"
	},
	{
		x:0.71950000000000003,
		y:0.74660000000000004,
		supporter:"B"
	},
	{
		x:0.72960000000000003,
		y:0.90269999999999995,
		supporter:"B"
	},
	{
		x:0.70450000000000002,
		y:0.876,
		supporter:"B"
	},
	{
		x:0.79269999999999996,
		y:0.90780000000000005,
		supporter:"B"
	},
	{
		x:0.70599999999999996,
		y:0.88229999999999997,
		supporter:"B"
	},
	{
		x:0.66710000000000003,
		y:0.79820000000000002,
		supporter:"B"
	},
	{
		x:0.73709999999999998,
		y:0.90749999999999997,
		supporter:"B"
	},
	{
		x:0.64,
		y:0.91900000000000004,
		supporter:"B"
	},
	{
		x:0.72899999999999998,
		y:0.77839999999999998,
		supporter:"B"
	},
	{
		x:0.67610000000000003,
		y:0.89570000000000005,
		supporter:"B"
	},
	{
		x:0.64290000000000003,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.68920000000000003,
		y:0.90100000000000002,
		supporter:"B"
	},
	{
		x:0.79059999999999997,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.68389999999999995,
		y:0.9234,
		supporter:"B"
	},
	{
		x:0.66590000000000005,
		y:0.80910000000000004,
		supporter:"B"
	},
	{
		x:0.80920000000000003,
		y:0.79430000000000001,
		supporter:"B"
	},
	{
		x:0.7278,
		y:0.89959999999999996,
		supporter:"B"
	},
	{
		x:0.70320000000000005,
		y:0.93230000000000002,
		supporter:"B"
	},
	{
		x:0.82530000000000003,
		y:0.94840000000000002,
		supporter:"B"
	},
	{
		x:0.71399999999999997,
		y:0.91500000000000004,
		supporter:"B"
	},
	{
		x:0.66169999999999995,
		y:0.90100000000000002,
		supporter:"B"
	},
	{
		x:0.72309999999999997,
		y:0.87760000000000005,
		supporter:"B"
	},
	{
		x:0.63329999999999997,
		y:0.90810000000000002,
		supporter:"B"
	},
	{
		x:0.65780000000000005,
		y:0.81030000000000002,
		supporter:"B"
	},
	{
		x:0.8004,
		y:0.874,
		supporter:"B"
	},
	{
		x:0.78069999999999995,
		y:0.91590000000000005,
		supporter:"B"
	},
	{
		x:0.86619999999999997,
		y:0.9476,
		supporter:"B"
	},
	{
		x:0.74199999999999999,
		y:0.91710000000000003,
		supporter:"B"
	},
	{
		x:0.64039999999999997,
		y:0.89280000000000004,
		supporter:"B"
	},
	{
		x:0.77700000000000002,
		y:0.93479999999999996,
		supporter:"B"
	},
	{
		x:0.74280000000000002,
		y:0.85770000000000002,
		supporter:"B"
	},
	{
		x:0.68369999999999997,
		y:0.87680000000000002,
		supporter:"B"
	},
	{
		x:0.76859999999999995,
		y:0.90269999999999995,
		supporter:"B"
	},
	{
		x:0.68200000000000005,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.66930000000000001,
		y:0.90949999999999998,
		supporter:"B"
	},
	{
		x:0.63570000000000004,
		y:0.9073,
		supporter:"B"
	},
	{
		x:0.87670000000000003,
		y:0.90620000000000001,
		supporter:"B"
	},
	{
		x:0.65529999999999999,
		y:0.90549999999999997,
		supporter:"B"
	},
	{
		x:0.87029999999999996,
		y:0.90529999999999999,
		supporter:"B"
	},
	{
		x:0.77200000000000002,
		y:0.88449999999999995,
		supporter:"B"
	},
	{
		x:0.85050000000000003,
		y:0.92179999999999995,
		supporter:"B"
	},
	{
		x:0.72899999999999998,
		y:0.89810000000000001,
		supporter:"B"
	},
	{
		x:0.66710000000000003,
		y:0.92090000000000005,
		supporter:"B"
	},
	{
		x:0.84240000000000004,
		y:0.91410000000000002,
		supporter:"B"
	},
	{
		x:0.6643,
		y:0.86960000000000004,
		supporter:"B"
	},
	{
		x:0.81940000000000002,
		y:0.78459999999999996,
		supporter:"B"
	},
	{
		x:0.70030000000000003,
		y:0.90300000000000002,
		supporter:"B"
	},
	{
		x:0.66990000000000005,
		y:0.80389999999999995,
		supporter:"B"
	},
	{
		x:0.72450000000000003,
		y:0.91949999999999998,
		supporter:"B"
	},
	{
		x:0.77800000000000002,
		y:0.92569999999999997,
		supporter:"B"
	},
	{
		x:0.70369999999999999,
		y:0.88690000000000002,
		supporter:"B"
	},
	{
		x:0.7278,
		y:0.88600000000000001,
		supporter:"B"
	},
	{
		x:0.8569,
		y:0.90700000000000003,
		supporter:"B"
	},
	{
		x:0.63629999999999998,
		y:0.82699999999999996,
		supporter:"B"
	},
	{
		x:0.68279999999999996,
		y:0.80349999999999999,
		supporter:"B"
	},
	{
		x:0.71530000000000005,
		y:0.8931,
		supporter:"B"
	},
	{
		x:0.65869999999999995,
		y:0.90920000000000001,
		supporter:"B"
	},
	{
		x:0.69479999999999997,
		y:0.92269999999999996,
		supporter:"B"
	},
	{
		x:0.65859999999999996,
		y:0.80379999999999996,
		supporter:"B"
	},
	{
		x:0.68020000000000003,
		y:0.87509999999999999,
		supporter:"B"
	},
	{
		x:0.63160000000000005,
		y:0.90439999999999998,
		supporter:"B"
	},
	{
		x:0.6956,
		y:0.79320000000000002,
		supporter:"B"
	},
	{
		x:0.68310000000000004,
		y:0.90390000000000004,
		supporter:"B"
	},
	{
		x:0.65190000000000003,
		y:0.91479999999999995,
		supporter:"B"
	},
	{
		x:0.64339999999999997,
		y:0.92310000000000003,
		supporter:"B"
	},
	{
		x:0.65329999999999999,
		y:0.9173,
		supporter:"B"
	},
	{
		x:0.63749999999999996,
		y:0.78649999999999998,
		supporter:"B"
	},
	{
		x:0.6542,
		y:0.91339999999999999,
		supporter:"B"
	},
	{
		x:0.65969999999999995,
		y:0.91310000000000002,
		supporter:"B"
	},
	{
		x:0.71760000000000002,
		y:0.92600000000000005,
		supporter:"B"
	},
	{
		x:0.89070000000000005,
		y:0.91059999999999997,
		supporter:"B"
	},
	{
		x:0.72030000000000005,
		y:0.92190000000000005,
		supporter:"B"
	},
	{
		x:0.63200000000000001,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.71199999999999997,
		y:0.8962,
		supporter:"B"
	},
	{
		x:0.68259999999999998,
		y:0.88580000000000003,
		supporter:"B"
	},
	{
		x:0.67900000000000005,
		y:0.89870000000000005,
		supporter:"B"
	},
	{
		x:0.70069999999999999,
		y:0.84530000000000005,
		supporter:"B"
	},
	{
		x:0.77110000000000001,
		y:0.80089999999999995,
		supporter:"B"
	},
	{
		x:0.64459999999999995,
		y:0.79220000000000002,
		supporter:"B"
	},
	{
		x:0.75780000000000003,
		y:0.79800000000000004,
		supporter:"B"
	},
	{
		x:0.85719999999999996,
		y:0.8952,
		supporter:"B"
	},
	{
		x:0.69899999999999995,
		y:0.90969999999999995,
		supporter:"B"
	},
	{
		x:0.65759999999999996,
		y:0.91859999999999997,
		supporter:"B"
	},
	{
		x:0.70940000000000003,
		y:0.93079999999999996,
		supporter:"B"
	},
	{
		x:0.74070000000000003,
		y:0.77859999999999996,
		supporter:"B"
	},
	{
		x:0.85680000000000001,
		y:0.79849999999999999,
		supporter:"B"
	},
	{
		x:0.79520000000000002,
		y:0.88190000000000002,
		supporter:"B"
	},
	{
		x:0.87709999999999999,
		y:0.90480000000000005,
		supporter:"B"
	},
	{
		x:0.85199999999999998,
		y:0.90710000000000002,
		supporter:"B"
	},
	{
		x:0.73129999999999995,
		y:0.82189999999999996,
		supporter:"B"
	},
	{
		x:0.68830000000000002,
		y:0.79020000000000001,
		supporter:"B"
	},
	{
		x:0.74680000000000002,
		y:0.90969999999999995,
		supporter:"B"
	},
	{
		x:0.64049999999999996,
		y:0.91279999999999994,
		supporter:"B"
	},
	{
		x:0.72770000000000001,
		y:0.78049999999999997,
		supporter:"B"
	},
	{
		x:0.73899999999999999,
		y:0.90629999999999999,
		supporter:"B"
	},
	{
		x:0.81340000000000001,
		y:0.79200000000000004,
		supporter:"B"
	},
	{
		x:0.73609999999999998,
		y:0.91520000000000001,
		supporter:"B"
	},
	{
		x:0.68069999999999997,
		y:0.92490000000000006,
		supporter:"B"
	},
	{
		x:0.6401,
		y:0.92510000000000003,
		supporter:"B"
	},
	{
		x:0.68140000000000001,
		y:0.90620000000000001,
		supporter:"B"
	},
	{
		x:0.72509999999999997,
		y:0.92279999999999995,
		supporter:"B"
	},
	{
		x:0.74819999999999998,
		y:0.90110000000000001,
		supporter:"B"
	},
	{
		x:0.74219999999999997,
		y:0.90959999999999996,
		supporter:"B"
	},
	{
		x:0.76470000000000005,
		y:0.91920000000000002,
		supporter:"B"
	},
	{
		x:0.67,
		y:0.88880000000000003,
		supporter:"B"
	},
	{
		x:0.65780000000000005,
		y:0.91159999999999997,
		supporter:"B"
	},
	{
		x:0.72589999999999999,
		y:0.8911,
		supporter:"B"
	},
	{
		x:0.72460000000000002,
		y:0.90359999999999996,
		supporter:"B"
	},
	{
		x:0.78810000000000002,
		y:0.88280000000000003,
		supporter:"B"
	},
	{
		x:0.66520000000000001,
		y:0.87119999999999997,
		supporter:"B"
	},
	{
		x:0.65469999999999995,
		y:0.81789999999999996,
		supporter:"B"
	},
	{
		x:0.77449999999999997,
		y:0.78029999999999999,
		supporter:"B"
	},
	{
		x:0.63839999999999997,
		y:0.88770000000000004,
		supporter:"B"
	},
	{
		x:0.68269999999999997,
		y:0.92659999999999998,
		supporter:"B"
	},
	{
		x:0.64970000000000006,
		y:0.92490000000000006,
		supporter:"B"
	},
	{
		x:0.66080000000000005,
		y:0.81540000000000001,
		supporter:"B"
	},
	{
		x:0.7792,
		y:0.91910000000000003,
		supporter:"B"
	},
	{
		x:0.6321,
		y:0.93420000000000003,
		supporter:"B"
	},
	{
		x:0.66010000000000002,
		y:0.91400000000000003,
		supporter:"B"
	},
	{
		x:0.63170000000000004,
		y:0.7954,
		supporter:"B"
	},
	{
		x:0.86580000000000001,
		y:0.90939999999999999,
		supporter:"B"
	},
	{
		x:0.66839999999999999,
		y:0.92730000000000001,
		supporter:"B"
	},
	{
		x:0.74809999999999999,
		y:0.92530000000000001,
		supporter:"B"
	},
	{
		x:0.71389999999999998,
		y:0.79049999999999998,
		supporter:"B"
	},
	{
		x:0.74339999999999995,
		y:0.90880000000000005,
		supporter:"B"
	},
	{
		x:0.67149999999999999,
		y:0.89449999999999996,
		supporter:"B"
	},
	{
		x:0.64729999999999999,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.67179999999999995,
		y:0.91710000000000003,
		supporter:"B"
	},
	{
		x:0.73640000000000005,
		y:0.88729999999999998,
		supporter:"B"
	},
	{
		x:0.86550000000000005,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.68920000000000003,
		y:0.92100000000000004,
		supporter:"B"
	},
	{
		x:0.7036,
		y:0.87690000000000001,
		supporter:"B"
	},
	{
		x:0.69530000000000003,
		y:0.92410000000000003,
		supporter:"B"
	},
	{
		x:0.84389999999999998,
		y:0.90369999999999995,
		supporter:"B"
	},
	{
		x:0.86119999999999997,
		y:0.78839999999999999,
		supporter:"B"
	},
	{
		x:0.84709999999999996,
		y:0.90749999999999997,
		supporter:"B"
	},
	{
		x:0.66810000000000003,
		y:0.89710000000000001,
		supporter:"B"
	},
	{
		x:0.71209999999999996,
		y:0.87609999999999999,
		supporter:"B"
	},
	{
		x:0.86629999999999996,
		y:0.90759999999999996,
		supporter:"B"
	},
	{
		x:0.78800000000000003,
		y:0.88770000000000004,
		supporter:"B"
	},
	{
		x:0.70960000000000001,
		y:0.92510000000000003,
		supporter:"B"
	},
	{
		x:0.65349999999999997,
		y:0.91090000000000004,
		supporter:"B"
	},
	{
		x:0.67669999999999997,
		y:0.9083,
		supporter:"B"
	},
	{
		x:0.67720000000000002,
		y:0.90269999999999995,
		supporter:"B"
	},
	{
		x:0.73899999999999999,
		y:0.91549999999999998,
		supporter:"B"
	},
	{
		x:0.74409999999999998,
		y:0.91010000000000002,
		supporter:"B"
	},
	{
		x:0.68979999999999997,
		y:0.89690000000000003,
		supporter:"B"
	},
	{
		x:0.68220000000000003,
		y:0.92330000000000001,
		supporter:"B"
	},
	{
		x:0.67700000000000005,
		y:0.92749999999999999,
		supporter:"B"
	},
	{
		x:0.67,
		y:0.89600000000000002,
		supporter:"B"
	},
	{
		x:0.70730000000000004,
		y:0.92200000000000004,
		supporter:"B"
	},
	{
		x:0.64680000000000004,
		y:0.91520000000000001,
		supporter:"B"
	},
	{
		x:0.64600000000000002,
		y:0.91569999999999996,
		supporter:"B"
	},
	{
		x:0.72309999999999997,
		y:0.92169999999999996,
		supporter:"B"
	},
	{
		x:0.74550000000000005,
		y:0.88449999999999995,
		supporter:"B"
	},
	{
		x:0.64429999999999998,
		y:0.90700000000000003,
		supporter:"B"
	},
	{
		x:0.72750000000000004,
		y:0.9113,
		supporter:"B"
	},
	{
		x:0.6623,
		y:0.90659999999999996,
		supporter:"B"
	},
	{
		x:0.68830000000000002,
		y:0.80840000000000001,
		supporter:"B"
	},
	{
		x:0.63700000000000001,
		y:0.90980000000000005,
		supporter:"B"
	},
	{
		x:0.88380000000000003,
		y:0.90669999999999995,
		supporter:"B"
	},
	{
		x:0.66490000000000005,
		y:0.91790000000000005,
		supporter:"B"
	},
	{
		x:0.69510000000000005,
		y:0.92,
		supporter:"B"
	},
	{
		x:0.68659999999999999,
		y:0.91910000000000003,
		supporter:"B"
	},
	{
		x:0.7056,
		y:0.91339999999999999,
		supporter:"B"
	},
	{
		x:0.74990000000000001,
		y:0.77549999999999997,
		supporter:"B"
	},
	{
		x:0.64680000000000004,
		y:0.89549999999999996,
		supporter:"B"
	},
	{
		x:0.86350000000000005,
		y:0.90790000000000004,
		supporter:"B"
	},
	{
		x:0.76870000000000005,
		y:0.87339999999999995,
		supporter:"B"
	},
	{
		x:0.74299999999999999,
		y:0.91600000000000004,
		supporter:"B"
	},
	{
		x:0.67279999999999995,
		y:0.90339999999999998,
		supporter:"B"
	},
	{
		x:0.70389999999999997,
		y:0.91479999999999995,
		supporter:"B"
	},
	{
		x:0.64529999999999998,
		y:0.91349999999999998,
		supporter:"B"
	},
	{
		x:0.89710000000000001,
		y:0.9073,
		supporter:"B"
	},
	{
		x:0.75719999999999998,
		y:0.93320000000000003,
		supporter:"B"
	},
	{
		x:0.63890000000000002,
		y:0.9113,
		supporter:"B"
	},
	{
		x:0.63870000000000005,
		y:0.91539999999999999,
		supporter:"B"
	},
	{
		x:0.6381,
		y:0.9214,
		supporter:"B"
	},
	{
		x:0.63959999999999995,
		y:0.91359999999999997,
		supporter:"B"
	},
	{
		x:0.70920000000000005,
		y:0.92259999999999998,
		supporter:"B"
	},
	{
		x:0.71819999999999995,
		y:0.82809999999999995,
		supporter:"B"
	},
	{
		x:0.68030000000000002,
		y:0.88660000000000005,
		supporter:"B"
	},
	{
		x:0.65759999999999996,
		y:0.80779999999999996,
		supporter:"B"
	},
	{
		x:0.73919999999999997,
		y:0.9163,
		supporter:"B"
	},
	{
		x:0.63219999999999998,
		y:0.89259999999999995,
		supporter:"B"
	},
	{
		x:0.68959999999999999,
		y:0.88290000000000002,
		supporter:"B"
	},
	{
		x:0.68769999999999998,
		y:0.91239999999999999,
		supporter:"B"
	},
	{
		x:0.80100000000000005,
		y:0.94840000000000002,
		supporter:"B"
	},
	{
		x:0.64759999999999995,
		y:0.89949999999999997,
		supporter:"B"
	},
	{
		x:0.75139999999999996,
		y:0.78820000000000001,
		supporter:"B"
	},
	{
		x:0.71189999999999998,
		y:0.88990000000000002,
		supporter:"B"
	},
	{
		x:0.78500000000000003,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.79630000000000001,
		y:0.89280000000000004,
		supporter:"B"
	},
	{
		x:0.72929999999999995,
		y:0.87570000000000003,
		supporter:"B"
	},
	{
		x:0.6875,
		y:0.86580000000000001,
		supporter:"B"
	},
	{
		x:0.80900000000000005,
		y:0.8841,
		supporter:"B"
	},
	{
		x:0.76039999999999996,
		y:0.79810000000000003,
		supporter:"B"
	},
	{
		x:0.71950000000000003,
		y:0.89500000000000002,
		supporter:"B"
	},
	{
		x:0.75129999999999997,
		y:0.92420000000000002,
		supporter:"B"
	},
	{
		x:0.69550000000000001,
		y:0.91479999999999995,
		supporter:"B"
	},
	{
		x:0.68069999999999997,
		y:0.90310000000000001,
		supporter:"B"
	},
	{
		x:0.75219999999999998,
		y:0.92249999999999999,
		supporter:"B"
	},
	{
		x:0.8609,
		y:0.80789999999999995,
		supporter:"B"
	},
	{
		x:0.67549999999999999,
		y:0.91920000000000002,
		supporter:"B"
	},
	{
		x:0.73170000000000002,
		y:0.79490000000000005,
		supporter:"B"
	},
	{
		x:0.87339999999999995,
		y:0.91859999999999997,
		supporter:"B"
	},
	{
		x:0.73029999999999995,
		y:0.91420000000000001,
		supporter:"B"
	},
	{
		x:0.66510000000000002,
		y:0.90469999999999995,
		supporter:"B"
	},
	{
		x:0.86539999999999995,
		y:0.91659999999999997,
		supporter:"B"
	},
	{
		x:0.68459999999999999,
		y:0.90400000000000003,
		supporter:"B"
	},
	{
		x:0.76580000000000004,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.65449999999999997,
		y:0.90949999999999998,
		supporter:"B"
	},
	{
		x:0.74860000000000004,
		y:0.91800000000000004,
		supporter:"B"
	},
	{
		x:0.88229999999999997,
		y:0.90790000000000004,
		supporter:"B"
	},
	{
		x:0.63770000000000004,
		y:0.91269999999999996,
		supporter:"B"
	},
	{
		x:0.63170000000000004,
		y:0.78969999999999996,
		supporter:"B"
	},
	{
		x:0.70909999999999995,
		y:0.78569999999999995,
		supporter:"B"
	},
	{
		x:0.67500000000000004,
		y:0.88749999999999996,
		supporter:"B"
	},
	{
		x:0.69140000000000001,
		y:0.89810000000000001,
		supporter:"B"
	},
	{
		x:0.70689999999999997,
		y:0.90110000000000001,
		supporter:"B"
	},
	{
		x:0.7127,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.77490000000000003,
		y:0.78690000000000004,
		supporter:"B"
	},
	{
		x:0.77139999999999997,
		y:0.89329999999999998,
		supporter:"B"
	},
	{
		x:0.71619999999999995,
		y:0.86860000000000004,
		supporter:"B"
	},
	{
		x:0.65210000000000001,
		y:0.92069999999999996,
		supporter:"B"
	},
	{
		x:0.86180000000000001,
		y:0.88619999999999999,
		supporter:"B"
	},
	{
		x:0.64139999999999997,
		y:0.91990000000000005,
		supporter:"B"
	},
	{
		x:0.63280000000000003,
		y:0.90639999999999998,
		supporter:"B"
	},
	{
		x:0.63980000000000004,
		y:0.90849999999999997,
		supporter:"B"
	},
	{
		x:0.68500000000000005,
		y:0.88590000000000002,
		supporter:"B"
	},
	{
		x:0.7319,
		y:0.91020000000000001,
		supporter:"B"
	},
	{
		x:0.6421,
		y:0.91659999999999997,
		supporter:"B"
	},
	{
		x:0.64580000000000004,
		y:0.92169999999999996,
		supporter:"B"
	},
	{
		x:0.65700000000000003,
		y:0.91539999999999999,
		supporter:"B"
	},
	{
		x:0.68530000000000002,
		y:0.92079999999999995,
		supporter:"B"
	},
	{
		x:0.79369999999999996,
		y:0.80549999999999999,
		supporter:"B"
	},
	{
		x:0.7833,
		y:0.92800000000000005,
		supporter:"B"
	},
	{
		x:0.6895,
		y:0.90790000000000004,
		supporter:"B"
	},
	{
		x:0.71330000000000005,
		y:0.9254,
		supporter:"B"
	},
	{
		x:0.73060000000000003,
		y:0.91269999999999996,
		supporter:"B"
	},
	{
		x:0.71460000000000001,
		y:0.9264,
		supporter:"B"
	},
	{
		x:0.84219999999999995,
		y:0.78300000000000003,
		supporter:"B"
	},
	{
		x:0.69630000000000003,
		y:0.90390000000000004,
		supporter:"B"
	},
	{
		x:0.79569999999999996,
		y:0.89400000000000002,
		supporter:"B"
	},
	{
		x:0.66959999999999997,
		y:0.92130000000000001,
		supporter:"B"
	},
	{
		x:0.65459999999999996,
		y:0.91539999999999999,
		supporter:"B"
	},
	{
		x:0.75860000000000005,
		y:0.88790000000000002,
		supporter:"B"
	},
	{
		x:0.76570000000000005,
		y:0.90029999999999999,
		supporter:"B"
	},
	{
		x:0.69579999999999997,
		y:0.91279999999999994,
		supporter:"B"
	},
	{
		x:0.66449999999999998,
		y:0.8972,
		supporter:"B"
	},
	{
		x:0.75380000000000003,
		y:0.91759999999999997,
		supporter:"B"
	},
	{
		x:0.71789999999999998,
		y:0.91600000000000004,
		supporter:"B"
	},
	{
		x:0.72160000000000002,
		y:0.80230000000000001,
		supporter:"B"
	},
	{
		x:0.6573,
		y:0.89949999999999997,
		supporter:"B"
	},
	{
		x:0.64439999999999997,
		y:0.91359999999999997,
		supporter:"B"
	},
	{
		x:0.79449999999999998,
		y:0.90539999999999998,
		supporter:"B"
	},
	{
		x:0.77969999999999995,
		y:0.89629999999999999,
		supporter:"B"
	},
	{
		x:0.68940000000000001,
		y:0.89990000000000003,
		supporter:"B"
	},
	{
		x:0.64470000000000005,
		y:0.91659999999999997,
		supporter:"B"
	},
	{
		x:0.86109999999999998,
		y:0.92020000000000002,
		supporter:"B"
	},
	{
		x:0.89059999999999995,
		y:0.91679999999999995,
		supporter:"B"
	},
	{
		x:0.74119999999999997,
		y:0.78839999999999999,
		supporter:"B"
	},
	{
		x:0.78059999999999996,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.64710000000000001,
		y:0.90429999999999999,
		supporter:"B"
	},
	{
		x:0.6774,
		y:0.91049999999999998,
		supporter:"B"
	},
	{
		x:0.67169999999999996,
		y:0.9042,
		supporter:"B"
	},
	{
		x:0.66390000000000005,
		y:0.91959999999999997,
		supporter:"B"
	},
	{
		x:0.7833,
		y:0.89,
		supporter:"B"
	},
	{
		x:0.63070000000000004,
		y:0.79700000000000004,
		supporter:"B"
	},
	{
		x:0.67510000000000003,
		y:0.90880000000000005,
		supporter:"B"
	},
	{
		x:0.6744,
		y:0.89659999999999995,
		supporter:"B"
	},
	{
		x:0.64039999999999997,
		y:0.83709999999999996,
		supporter:"B"
	},
	{
		x:0.64580000000000004,
		y:0.9153,
		supporter:"B"
	},
	{
		x:0.69779999999999998,
		y:0.79579999999999995,
		supporter:"B"
	},
	{
		x:0.70320000000000005,
		y:0.88019999999999998,
		supporter:"B"
	},
	{
		x:0.68079999999999996,
		y:0.90500000000000003,
		supporter:"B"
	},
	{
		x:0.69059999999999999,
		y:0.91759999999999997,
		supporter:"B"
	},
	{
		x:0.87250000000000005,
		y:0.90859999999999996,
		supporter:"B"
	},
	{
		x:0.69589999999999996,
		y:0.81499999999999995,
		supporter:"B"
	},
	{
		x:0.65510000000000002,
		y:0.91549999999999998,
		supporter:"B"
	},
	{
		x:0.86660000000000004,
		y:0.90300000000000002,
		supporter:"B"
	},
	{
		x:0.76829999999999998,
		y:0.91769999999999996,
		supporter:"B"
	},
	{
		x:0.86560000000000004,
		y:0.91649999999999998,
		supporter:"B"
	},
	{
		x:0.77,
		y:0.88,
		supporter:"B"
	},
	{
		x:0.67630000000000001,
		y:0.90969999999999995,
		supporter:"B"
	},
	{
		x:0.63980000000000004,
		y:0.91600000000000004,
		supporter:"B"
	},
	{
		x:0.72299999999999998,
		y:0.92820000000000003,
		supporter:"B"
	},
	{
		x:0.77600000000000002,
		y:0.79,
		supporter:"B"
	},
	{
		x:0.78339999999999999,
		y:0.91059999999999997,
		supporter:"B"
	},
	{
		x:0.66610000000000003,
		y:0.90529999999999999,
		supporter:"B"
	},
	{
		x:0.70889999999999997,
		y:0.91900000000000004,
		supporter:"B"
	},
	{
		x:0.67190000000000005,
		y:0.9224,
		supporter:"B"
	},
	{
		x:0.89280000000000004,
		y:0.91649999999999998,
		supporter:"B"
	},
	{
		x:0.65720000000000001,
		y:0.92110000000000003,
		supporter:"B"
	},
	{
		x:0.64219999999999999,
		y:0.91690000000000005,
		supporter:"B"
	}
]

export const scatterC = [
	{
		x:0.62160000000000004,
		y:0.92749999999999999,
		supporter:"C"
	},
	{
		x:0.58320000000000005,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.5706,
		y:0.91959999999999997,
		supporter:"C"
	},
	{
		x:0.51929999999999998,
		y:0.94,
		supporter:"C"
	},
	{
		x:0.62529999999999997,
		y:0.91859999999999997,
		supporter:"C"
	},
	{
		x:0.62109999999999999,
		y:0.92959999999999998,
		supporter:"C"
	},
	{
		x:0.62239999999999995,
		y:0.91869999999999996,
		supporter:"C"
	},
	{
		x:0.54679999999999995,
		y:0.90469999999999995,
		supporter:"C"
	},
	{
		x:0.55459999999999998,
		y:0.90629999999999999,
		supporter:"C"
	},
	{
		x:0.58379999999999999,
		y:0.91959999999999997,
		supporter:"C"
	},
	{
		x:0.59419999999999995,
		y:0.94159999999999999,
		supporter:"C"
	},
	{
		x:0.57220000000000004,
		y:0.93210000000000004,
		supporter:"C"
	},
	{
		x:0.50119999999999998,
		y:0.92520000000000002,
		supporter:"C"
	},
	{
		x:0.53449999999999998,
		y:0.91679999999999995,
		supporter:"C"
	},
	{
		x:0.50249999999999995,
		y:0.90759999999999996,
		supporter:"C"
	},
	{
		x:0.61529999999999996,
		y:0.92330000000000001,
		supporter:"C"
	},
	{
		x:0.51270000000000004,
		y:0.90610000000000002,
		supporter:"C"
	},
	{
		x:0.62129999999999996,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.57430000000000003,
		y:0.91849999999999998,
		supporter:"C"
	},
	{
		x:0.53710000000000002,
		y:0.91690000000000005,
		supporter:"C"
	},
	{
		x:0.5978,
		y:0.92179999999999995,
		supporter:"C"
	},
	{
		x:0.54339999999999999,
		y:0.92300000000000004,
		supporter:"C"
	},
	{
		x:0.62790000000000001,
		y:0.91930000000000001,
		supporter:"C"
	},
	{
		x:0.60650000000000004,
		y:0.90169999999999995,
		supporter:"C"
	},
	{
		x:0.55310000000000004,
		y:0.92900000000000005,
		supporter:"C"
	},
	{
		x:0.61550000000000005,
		y:0.93089999999999995,
		supporter:"C"
	},
	{
		x:0.58909999999999996,
		y:0.91869999999999996,
		supporter:"C"
	},
	{
		x:0.55369999999999997,
		y:0.9153,
		supporter:"C"
	},
	{
		x:0.54279999999999995,
		y:0.93300000000000005,
		supporter:"C"
	},
	{
		x:0.62370000000000003,
		y:0.90169999999999995,
		supporter:"C"
	},
	{
		x:0.52669999999999995,
		y:0.9173,
		supporter:"C"
	},
	{
		x:0.60309999999999997,
		y:0.94769999999999999,
		supporter:"C"
	},
	{
		x:0.51900000000000002,
		y:0.93420000000000003,
		supporter:"C"
	},
	{
		x:0.61229999999999996,
		y:0.92849999999999999,
		supporter:"C"
	},
	{
		x:0.5423,
		y:0.90059999999999996,
		supporter:"C"
	},
	{
		x:0.59240000000000004,
		y:0.90290000000000004,
		supporter:"C"
	},
	{
		x:0.60040000000000004,
		y:0.92269999999999996,
		supporter:"C"
	},
	{
		x:0.623,
		y:0.92169999999999996,
		supporter:"C"
	},
	{
		x:0.62229999999999996,
		y:0.90210000000000001,
		supporter:"C"
	},
	{
		x:0.53380000000000005,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.62219999999999998,
		y:0.9083,
		supporter:"C"
	},
	{
		x:0.5,
		y:0.90769999999999995,
		supporter:"C"
	},
	{
		x:0.60750000000000004,
		y:0.91569999999999996,
		supporter:"C"
	},
	{
		x:0.62250000000000005,
		y:0.91810000000000003,
		supporter:"C"
	},
	{
		x:0.51190000000000002,
		y:0.90510000000000002,
		supporter:"C"
	},
	{
		x:0.60609999999999997,
		y:0.9395,
		supporter:"C"
	},
	{
		x:0.59819999999999995,
		y:0.92290000000000005,
		supporter:"C"
	},
	{
		x:0.50819999999999999,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.56920000000000004,
		y:0.91930000000000001,
		supporter:"C"
	},
	{
		x:0.54279999999999995,
		y:0.92710000000000004,
		supporter:"C"
	},
	{
		x:0.60150000000000003,
		y:0.91569999999999996,
		supporter:"C"
	},
	{
		x:0.56120000000000003,
		y:0.90400000000000003,
		supporter:"C"
	},
	{
		x:0.54479999999999995,
		y:0.93269999999999997,
		supporter:"C"
	},
	{
		x:0.57379999999999998,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.50749999999999995,
		y:0.92420000000000002,
		supporter:"C"
	},
	{
		x:0.57550000000000001,
		y:0.92279999999999995,
		supporter:"C"
	},
	{
		x:0.50839999999999996,
		y:0.9234,
		supporter:"C"
	},
	{
		x:0.5655,
		y:0.9012,
		supporter:"C"
	},
	{
		x:0.57999999999999996,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.50919999999999999,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.57089999999999996,
		y:0.93169999999999997,
		supporter:"C"
	},
	{
		x:0.50260000000000005,
		y:0.90810000000000002,
		supporter:"C"
	},
	{
		x:0.58120000000000005,
		y:0.90010000000000001,
		supporter:"C"
	},
	{
		x:0.62209999999999999,
		y:0.92630000000000001,
		supporter:"C"
	},
	{
		x:0.62829999999999997,
		y:0.92020000000000002,
		supporter:"C"
	},
	{
		x:0.59930000000000005,
		y:0.91969999999999996,
		supporter:"C"
	},
	{
		x:0.54049999999999998,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.51039999999999996,
		y:0.90439999999999998,
		supporter:"C"
	},
	{
		x:0.52410000000000001,
		y:0.91090000000000004,
		supporter:"C"
	},
	{
		x:0.53800000000000003,
		y:0.90029999999999999,
		supporter:"C"
	},
	{
		x:0.54900000000000004,
		y:0.90410000000000001,
		supporter:"C"
	},
	{
		x:0.6018,
		y:0.9224,
		supporter:"C"
	},
	{
		x:0.59689999999999999,
		y:0.9264,
		supporter:"C"
	},
	{
		x:0.50370000000000004,
		y:0.91039999999999999,
		supporter:"C"
	},
	{
		x:0.54149999999999998,
		y:0.90710000000000002,
		supporter:"C"
	},
	{
		x:0.57840000000000003,
		y:0.91169999999999995,
		supporter:"C"
	},
	{
		x:0.5272,
		y:0.91290000000000004,
		supporter:"C"
	},
	{
		x:0.60099999999999998,
		y:0.91469999999999996,
		supporter:"C"
	},
	{
		x:0.5,
		y:0.90910000000000002,
		supporter:"C"
	},
	{
		x:0.52590000000000003,
		y:0.92920000000000003,
		supporter:"C"
	},
	{
		x:0.60840000000000005,
		y:0.91610000000000003,
		supporter:"C"
	},
	{
		x:0.58809999999999996,
		y:0.92,
		supporter:"C"
	},
	{
		x:0.6139,
		y:0.91700000000000004,
		supporter:"C"
	},
	{
		x:0.58109999999999995,
		y:0.9264,
		supporter:"C"
	},
	{
		x:0.52159999999999995,
		y:0.92249999999999999,
		supporter:"C"
	},
	{
		x:0.56679999999999997,
		y:0.91679999999999995,
		supporter:"C"
	},
	{
		x:0.57089999999999996,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.58389999999999997,
		y:0.9173,
		supporter:"C"
	},
	{
		x:0.58750000000000002,
		y:0.94469999999999998,
		supporter:"C"
	},
	{
		x:0.53939999999999999,
		y:0.91310000000000002,
		supporter:"C"
	},
	{
		x:0.52310000000000001,
		y:0.90849999999999997,
		supporter:"C"
	},
	{
		x:0.55869999999999997,
		y:0.9093,
		supporter:"C"
	},
	{
		x:0.5696,
		y:0.91310000000000002,
		supporter:"C"
	},
	{
		x:0.54449999999999998,
		y:0.9083,
		supporter:"C"
	},
	{
		x:0.503,
		y:0.92589999999999995,
		supporter:"C"
	},
	{
		x:0.6149,
		y:0.92269999999999996,
		supporter:"C"
	},
	{
		x:0.59260000000000002,
		y:0.91930000000000001,
		supporter:"C"
	},
	{
		x:0.628,
		y:0.91669999999999996,
		supporter:"C"
	},
	{
		x:0.60499999999999998,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.61329999999999996,
		y:0.92410000000000003,
		supporter:"C"
	},
	{
		x:0.62970000000000004,
		y:0.90820000000000001,
		supporter:"C"
	},
	{
		x:0.55049999999999999,
		y:0.91439999999999999,
		supporter:"C"
	},
	{
		x:0.5786,
		y:0.94340000000000002,
		supporter:"C"
	},
	{
		x:0.51160000000000005,
		y:0.9042,
		supporter:"C"
	},
	{
		x:0.62719999999999998,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.5111,
		y:0.92179999999999995,
		supporter:"C"
	},
	{
		x:0.5302,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.58689999999999998,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.56489999999999996,
		y:0.90169999999999995,
		supporter:"C"
	},
	{
		x:0.59630000000000005,
		y:0.91490000000000005,
		supporter:"C"
	},
	{
		x:0.55610000000000004,
		y:0.92900000000000005,
		supporter:"C"
	},
	{
		x:0.62770000000000004,
		y:0.9123,
		supporter:"C"
	},
	{
		x:0.59870000000000001,
		y:0.91790000000000005,
		supporter:"C"
	},
	{
		x:0.51770000000000005,
		y:0.92879999999999996,
		supporter:"C"
	},
	{
		x:0.60870000000000002,
		y:0.91900000000000004,
		supporter:"C"
	},
	{
		x:0.60850000000000004,
		y:0.92730000000000001,
		supporter:"C"
	},
	{
		x:0.5494,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.62419999999999998,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.5202,
		y:0.93320000000000003,
		supporter:"C"
	},
	{
		x:0.56899999999999995,
		y:0.91739999999999999,
		supporter:"C"
	},
	{
		x:0.61080000000000001,
		y:0.91779999999999995,
		supporter:"C"
	},
	{
		x:0.52890000000000004,
		y:0.9042,
		supporter:"C"
	},
	{
		x:0.58630000000000004,
		y:0.93049999999999999,
		supporter:"C"
	},
	{
		x:0.51290000000000002,
		y:0.92079999999999995,
		supporter:"C"
	},
	{
		x:0.5272,
		y:0.91339999999999999,
		supporter:"C"
	},
	{
		x:0.57589999999999997,
		y:0.93030000000000002,
		supporter:"C"
	},
	{
		x:0.628,
		y:0.92359999999999998,
		supporter:"C"
	},
	{
		x:0.52029999999999998,
		y:0.91049999999999998,
		supporter:"C"
	},
	{
		x:0.61439999999999995,
		y:0.92720000000000002,
		supporter:"C"
	},
	{
		x:0.62870000000000004,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.60550000000000004,
		y:0.92049999999999998,
		supporter:"C"
	},
	{
		x:0.61270000000000002,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.57069999999999999,
		y:0.91969999999999996,
		supporter:"C"
	},
	{
		x:0.60040000000000004,
		y:0.91879999999999995,
		supporter:"C"
	},
	{
		x:0.5444,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.58079999999999998,
		y:0.91149999999999998,
		supporter:"C"
	},
	{
		x:0.5222,
		y:0.92259999999999998,
		supporter:"C"
	},
	{
		x:0.56599999999999995,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.50009999999999999,
		y:0.90469999999999995,
		supporter:"C"
	},
	{
		x:0.62139999999999995,
		y:0.91769999999999996,
		supporter:"C"
	},
	{
		x:0.51149999999999995,
		y:0.90639999999999998,
		supporter:"C"
	},
	{
		x:0.52729999999999999,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.52739999999999998,
		y:0.90639999999999998,
		supporter:"C"
	},
	{
		x:0.55669999999999997,
		y:0.90949999999999998,
		supporter:"C"
	},
	{
		x:0.50490000000000002,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.60640000000000005,
		y:0.91239999999999999,
		supporter:"C"
	},
	{
		x:0.501,
		y:0.92620000000000002,
		supporter:"C"
	},
	{
		x:0.51919999999999999,
		y:0.93189999999999995,
		supporter:"C"
	},
	{
		x:0.55589999999999995,
		y:0.90100000000000002,
		supporter:"C"
	},
	{
		x:0.51770000000000005,
		y:0.91239999999999999,
		supporter:"C"
	},
	{
		x:0.61119999999999997,
		y:0.92959999999999998,
		supporter:"C"
	},
	{
		x:0.51100000000000001,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.61150000000000004,
		y:0.93230000000000002,
		supporter:"C"
	},
	{
		x:0.50080000000000002,
		y:0.90820000000000001,
		supporter:"C"
	},
	{
		x:0.61629999999999996,
		y:0.91479999999999995,
		supporter:"C"
	},
	{
		x:0.57699999999999996,
		y:0.94120000000000004,
		supporter:"C"
	},
	{
		x:0.62890000000000001,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.51910000000000001,
		y:0.90739999999999998,
		supporter:"C"
	},
	{
		x:0.505,
		y:0.91080000000000005,
		supporter:"C"
	},
	{
		x:0.56920000000000004,
		y:0.90039999999999998,
		supporter:"C"
	},
	{
		x:0.50960000000000005,
		y:0.93400000000000005,
		supporter:"C"
	},
	{
		x:0.53790000000000004,
		y:0.90649999999999997,
		supporter:"C"
	},
	{
		x:0.52470000000000006,
		y:0.9103,
		supporter:"C"
	},
	{
		x:0.53510000000000002,
		y:0.91069999999999995,
		supporter:"C"
	},
	{
		x:0.53320000000000001,
		y:0.92459999999999998,
		supporter:"C"
	},
	{
		x:0.52110000000000001,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.50509999999999999,
		y:0.91459999999999997,
		supporter:"C"
	},
	{
		x:0.60909999999999997,
		y:0.9234,
		supporter:"C"
	},
	{
		x:0.51429999999999998,
		y:0.90339999999999998,
		supporter:"C"
	},
	{
		x:0.5786,
		y:0.94350000000000001,
		supporter:"C"
	},
	{
		x:0.51390000000000002,
		y:0.92530000000000001,
		supporter:"C"
	},
	{
		x:0.56530000000000002,
		y:0.92669999999999997,
		supporter:"C"
	},
	{
		x:0.62080000000000002,
		y:0.92869999999999997,
		supporter:"C"
	},
	{
		x:0.61570000000000003,
		y:0.92269999999999996,
		supporter:"C"
	},
	{
		x:0.50880000000000003,
		y:0.90400000000000003,
		supporter:"C"
	},
	{
		x:0.59079999999999999,
		y:0.9204,
		supporter:"C"
	},
	{
		x:0.50609999999999999,
		y:0.92220000000000002,
		supporter:"C"
	},
	{
		x:0.61750000000000005,
		y:0.92290000000000005,
		supporter:"C"
	},
	{
		x:0.54859999999999998,
		y:0.93400000000000005,
		supporter:"C"
	},
	{
		x:0.57999999999999996,
		y:0.94599999999999995,
		supporter:"C"
	},
	{
		x:0.50019999999999998,
		y:0.9244,
		supporter:"C"
	},
	{
		x:0.51500000000000001,
		y:0.92589999999999995,
		supporter:"C"
	},
	{
		x:0.62080000000000002,
		y:0.92300000000000004,
		supporter:"C"
	},
	{
		x:0.53359999999999996,
		y:0.91269999999999996,
		supporter:"C"
	},
	{
		x:0.54190000000000005,
		y:0.90169999999999995,
		supporter:"C"
	},
	{
		x:0.52759999999999996,
		y:0.90339999999999998,
		supporter:"C"
	},
	{
		x:0.52080000000000004,
		y:0.91510000000000002,
		supporter:"C"
	},
	{
		x:0.58899999999999997,
		y:0.90639999999999998,
		supporter:"C"
	},
	{
		x:0.51639999999999997,
		y:0.90580000000000005,
		supporter:"C"
	},
	{
		x:0.56989999999999996,
		y:0.90720000000000001,
		supporter:"C"
	},
	{
		x:0.51259999999999994,
		y:0.92310000000000003,
		supporter:"C"
	},
	{
		x:0.54320000000000002,
		y:0.90539999999999998,
		supporter:"C"
	},
	{
		x:0.52180000000000004,
		y:0.92700000000000005,
		supporter:"C"
	},
	{
		x:0.58560000000000001,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.56950000000000001,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.57379999999999998,
		y:0.90200000000000002,
		supporter:"C"
	},
	{
		x:0.54369999999999996,
		y:0.91990000000000005,
		supporter:"C"
	},
	{
		x:0.6109,
		y:0.92410000000000003,
		supporter:"C"
	},
	{
		x:0.57509999999999994,
		y:0.9194,
		supporter:"C"
	},
	{
		x:0.62670000000000003,
		y:0.92169999999999996,
		supporter:"C"
	},
	{
		x:0.52739999999999998,
		y:0.90769999999999995,
		supporter:"C"
	},
	{
		x:0.53400000000000003,
		y:0.90849999999999997,
		supporter:"C"
	},
	{
		x:0.61029999999999995,
		y:0.91920000000000002,
		supporter:"C"
	},
	{
		x:0.51319999999999999,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.60409999999999997,
		y:0.91859999999999997,
		supporter:"C"
	},
	{
		x:0.61129999999999995,
		y:0.91659999999999997,
		supporter:"C"
	},
	{
		x:0.60770000000000002,
		y:0.90080000000000005,
		supporter:"C"
	},
	{
		x:0.56899999999999995,
		y:0.9022,
		supporter:"C"
	},
	{
		x:0.61909999999999998,
		y:0.92459999999999998,
		supporter:"C"
	},
	{
		x:0.62190000000000001,
		y:0.92700000000000005,
		supporter:"C"
	},
	{
		x:0.61980000000000002,
		y:0.91190000000000004,
		supporter:"C"
	},
	{
		x:0.52249999999999996,
		y:0.90429999999999999,
		supporter:"C"
	},
	{
		x:0.54659999999999997,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.6038,
		y:0.92459999999999998,
		supporter:"C"
	},
	{
		x:0.60509999999999997,
		y:0.92520000000000002,
		supporter:"C"
	},
	{
		x:0.53769999999999996,
		y:0.9234,
		supporter:"C"
	},
	{
		x:0.55959999999999999,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.59730000000000005,
		y:0.92159999999999997,
		supporter:"C"
	},
	{
		x:0.60570000000000002,
		y:0.92549999999999999,
		supporter:"C"
	},
	{
		x:0.61909999999999998,
		y:0.91990000000000005,
		supporter:"C"
	},
	{
		x:0.54139999999999999,
		y:0.90059999999999996,
		supporter:"C"
	},
	{
		x:0.57969999999999999,
		y:0.92290000000000005,
		supporter:"C"
	},
	{
		x:0.51749999999999996,
		y:0.90749999999999997,
		supporter:"C"
	},
	{
		x:0.57630000000000003,
		y:0.91269999999999996,
		supporter:"C"
	},
	{
		x:0.6048,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.62849999999999995,
		y:0.93230000000000002,
		supporter:"C"
	},
	{
		x:0.54149999999999998,
		y:0.91930000000000001,
		supporter:"C"
	},
	{
		x:0.50760000000000005,
		y:0.91020000000000001,
		supporter:"C"
	},
	{
		x:0.58599999999999997,
		y:0.92710000000000004,
		supporter:"C"
	},
	{
		x:0.62470000000000003,
		y:0.91200000000000003,
		supporter:"C"
	},
	{
		x:0.55410000000000004,
		y:0.91549999999999998,
		supporter:"C"
	},
	{
		x:0.53680000000000005,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.58499999999999996,
		y:0.92459999999999998,
		supporter:"C"
	},
	{
		x:0.53059999999999996,
		y:0.90100000000000002,
		supporter:"C"
	},
	{
		x:0.60309999999999997,
		y:0.92,
		supporter:"C"
	},
	{
		x:0.51719999999999999,
		y:0.90539999999999998,
		supporter:"C"
	},
	{
		x:0.50460000000000005,
		y:0.9093,
		supporter:"C"
	},
	{
		x:0.60660000000000003,
		y:0.92410000000000003,
		supporter:"C"
	},
	{
		x:0.50180000000000002,
		y:0.93330000000000002,
		supporter:"C"
	},
	{
		x:0.55820000000000003,
		y:0.90210000000000001,
		supporter:"C"
	},
	{
		x:0.5494,
		y:0.90259999999999996,
		supporter:"C"
	},
	{
		x:0.50600000000000001,
		y:0.92190000000000005,
		supporter:"C"
	},
	{
		x:0.62719999999999998,
		y:0.91810000000000003,
		supporter:"C"
	},
	{
		x:0.54700000000000004,
		y:0.90590000000000004,
		supporter:"C"
	},
	{
		x:0.6028,
		y:0.92010000000000003,
		supporter:"C"
	},
	{
		x:0.51149999999999995,
		y:0.93240000000000001,
		supporter:"C"
	},
	{
		x:0.5746,
		y:0.90429999999999999,
		supporter:"C"
	},
	{
		x:0.6119,
		y:0.92989999999999995,
		supporter:"C"
	},
	{
		x:0.57199999999999995,
		y:0.91649999999999998,
		supporter:"C"
	},
	{
		x:0.51139999999999997,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.51039999999999996,
		y:0.90880000000000005,
		supporter:"C"
	},
	{
		x:0.56230000000000002,
		y:0.92069999999999996,
		supporter:"C"
	},
	{
		x:0.58430000000000004,
		y:0.91080000000000005,
		supporter:"C"
	},
	{
		x:0.50009999999999999,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.52780000000000005,
		y:0.90300000000000002,
		supporter:"C"
	},
	{
		x:0.57509999999999994,
		y:0.90990000000000004,
		supporter:"C"
	},
	{
		x:0.56669999999999998,
		y:0.94430000000000003,
		supporter:"C"
	},
	{
		x:0.56069999999999998,
		y:0.90449999999999997,
		supporter:"C"
	},
	{
		x:0.50380000000000003,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.51459999999999995,
		y:0.90239999999999998,
		supporter:"C"
	},
	{
		x:0.56399999999999995,
		y:0.90469999999999995,
		supporter:"C"
	},
	{
		x:0.53569999999999995,
		y:0.91490000000000005,
		supporter:"C"
	},
	{
		x:0.50360000000000005,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.53300000000000003,
		y:0.90069999999999995,
		supporter:"C"
	},
	{
		x:0.50560000000000005,
		y:0.92390000000000005,
		supporter:"C"
	},
	{
		x:0.55959999999999999,
		y:0.90649999999999997,
		supporter:"C"
	},
	{
		x:0.55210000000000004,
		y:0.91049999999999998,
		supporter:"C"
	},
	{
		x:0.58960000000000001,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.62970000000000004,
		y:0.92159999999999997,
		supporter:"C"
	},
	{
		x:0.51180000000000003,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.51529999999999998,
		y:0.92420000000000002,
		supporter:"C"
	},
	{
		x:0.61370000000000002,
		y:0.92600000000000005,
		supporter:"C"
	},
	{
		x:0.60340000000000005,
		y:0.91249999999999998,
		supporter:"C"
	},
	{
		x:0.62250000000000005,
		y:0.92910000000000004,
		supporter:"C"
	},
	{
		x:0.60760000000000003,
		y:0.94540000000000002,
		supporter:"C"
	},
	{
		x:0.5605,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.61799999999999999,
		y:0.92720000000000002,
		supporter:"C"
	},
	{
		x:0.5988,
		y:0.92330000000000001,
		supporter:"C"
	},
	{
		x:0.51229999999999998,
		y:0.92420000000000002,
		supporter:"C"
	},
	{
		x:0.54669999999999996,
		y:0.91549999999999998,
		supporter:"C"
	},
	{
		x:0.58730000000000004,
		y:0.91169999999999995,
		supporter:"C"
	},
	{
		x:0.60560000000000003,
		y:0.92490000000000006,
		supporter:"C"
	},
	{
		x:0.54669999999999996,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.5161,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.51239999999999997,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.61170000000000002,
		y:0.92249999999999999,
		supporter:"C"
	},
	{
		x:0.56200000000000006,
		y:0.90790000000000004,
		supporter:"C"
	},
	{
		x:0.59150000000000003,
		y:0.91949999999999998,
		supporter:"C"
	},
	{
		x:0.60650000000000004,
		y:0.92479999999999996,
		supporter:"C"
	},
	{
		x:0.53649999999999998,
		y:0.90039999999999998,
		supporter:"C"
	},
	{
		x:0.51959999999999995,
		y:0.90180000000000005,
		supporter:"C"
	},
	{
		x:0.53510000000000002,
		y:0.92600000000000005,
		supporter:"C"
	},
	{
		x:0.50660000000000005,
		y:0.93300000000000005,
		supporter:"C"
	},
	{
		x:0.53420000000000001,
		y:0.91149999999999998,
		supporter:"C"
	},
	{
		x:0.5665,
		y:0.93049999999999999,
		supporter:"C"
	},
	{
		x:0.61950000000000005,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.55900000000000005,
		y:0.90659999999999996,
		supporter:"C"
	},
	{
		x:0.60209999999999997,
		y:0.92169999999999996,
		supporter:"C"
	},
	{
		x:0.55389999999999995,
		y:0.91679999999999995,
		supporter:"C"
	},
	{
		x:0.5343,
		y:0.91069999999999995,
		supporter:"C"
	},
	{
		x:0.57150000000000001,
		y:0.90610000000000002,
		supporter:"C"
	},
	{
		x:0.6,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.51029999999999998,
		y:0.90080000000000005,
		supporter:"C"
	},
	{
		x:0.50919999999999999,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.51090000000000002,
		y:0.90500000000000003,
		supporter:"C"
	},
	{
		x:0.55320000000000003,
		y:0.91579999999999995,
		supporter:"C"
	},
	{
		x:0.60980000000000001,
		y:0.92579999999999996,
		supporter:"C"
	},
	{
		x:0.55210000000000004,
		y:0.90739999999999998,
		supporter:"C"
	},
	{
		x:0.60680000000000001,
		y:0.91679999999999995,
		supporter:"C"
	},
	{
		x:0.50719999999999998,
		y:0.92430000000000001,
		supporter:"C"
	},
	{
		x:0.60370000000000001,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.58189999999999997,
		y:0.91900000000000004,
		supporter:"C"
	},
	{
		x:0.58899999999999997,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.55710000000000004,
		y:0.90790000000000004,
		supporter:"C"
	},
	{
		x:0.51100000000000001,
		y:0.91800000000000004,
		supporter:"C"
	},
	{
		x:0.59830000000000005,
		y:0.94630000000000003,
		supporter:"C"
	},
	{
		x:0.58599999999999997,
		y:0.92530000000000001,
		supporter:"C"
	},
	{
		x:0.5746,
		y:0.90669999999999995,
		supporter:"C"
	},
	{
		x:0.52800000000000002,
		y:0.90059999999999996,
		supporter:"C"
	},
	{
		x:0.53749999999999998,
		y:0.91279999999999994,
		supporter:"C"
	},
	{
		x:0.53580000000000005,
		y:0.93279999999999996,
		supporter:"C"
	},
	{
		x:0.62,
		y:0.91269999999999996,
		supporter:"C"
	},
	{
		x:0.56210000000000004,
		y:0.94140000000000001,
		supporter:"C"
	},
	{
		x:0.51939999999999997,
		y:0.92010000000000003,
		supporter:"C"
	},
	{
		x:0.58169999999999999,
		y:0.92949999999999999,
		supporter:"C"
	},
	{
		x:0.53710000000000002,
		y:0.90790000000000004,
		supporter:"C"
	},
	{
		x:0.62719999999999998,
		y:0.91690000000000005,
		supporter:"C"
	},
	{
		x:0.55669999999999997,
		y:0.90859999999999996,
		supporter:"C"
	},
	{
		x:0.62319999999999998,
		y:0.91279999999999994,
		supporter:"C"
	},
	{
		x:0.60899999999999999,
		y:0.92269999999999996,
		supporter:"C"
	},
	{
		x:0.50109999999999999,
		y:0.92290000000000005,
		supporter:"C"
	},
	{
		x:0.56120000000000003,
		y:0.90800000000000003,
		supporter:"C"
	},
	{
		x:0.52659999999999996,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.60970000000000002,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.58799999999999997,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.50719999999999998,
		y:0.90880000000000005,
		supporter:"C"
	},
	{
		x:0.62929999999999997,
		y:0.92730000000000001,
		supporter:"C"
	},
	{
		x:0.61339999999999995,
		y:0.92230000000000001,
		supporter:"C"
	},
	{
		x:0.51739999999999997,
		y:0.90900000000000003,
		supporter:"C"
	},
	{
		x:0.57840000000000003,
		y:0.94450000000000001,
		supporter:"C"
	},
	{
		x:0.56669999999999998,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.58899999999999997,
		y:0.92279999999999995,
		supporter:"C"
	},
	{
		x:0.51690000000000003,
		y:0.90059999999999996,
		supporter:"C"
	},
	{
		x:0.62709999999999999,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.50449999999999995,
		y:0.9224,
		supporter:"C"
	},
	{
		x:0.51500000000000001,
		y:0.9032,
		supporter:"C"
	},
	{
		x:0.50990000000000002,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.53810000000000002,
		y:0.90710000000000002,
		supporter:"C"
	},
	{
		x:0.61260000000000003,
		y:0.91490000000000005,
		supporter:"C"
	},
	{
		x:0.55530000000000002,
		y:0.91949999999999998,
		supporter:"C"
	},
	{
		x:0.51319999999999999,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.58030000000000004,
		y:0.91339999999999999,
		supporter:"C"
	},
	{
		x:0.55269999999999997,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.54590000000000005,
		y:0.90290000000000004,
		supporter:"C"
	},
	{
		x:0.52580000000000005,
		y:0.90959999999999996,
		supporter:"C"
	},
	{
		x:0.50180000000000002,
		y:0.90810000000000002,
		supporter:"C"
	},
	{
		x:0.50460000000000005,
		y:0.91200000000000003,
		supporter:"C"
	},
	{
		x:0.52500000000000002,
		y:0.90190000000000003,
		supporter:"C"
	},
	{
		x:0.59399999999999997,
		y:0.92549999999999999,
		supporter:"C"
	},
	{
		x:0.59099999999999997,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.53169999999999995,
		y:0.9022,
		supporter:"C"
	},
	{
		x:0.50960000000000005,
		y:0.91149999999999998,
		supporter:"C"
	},
	{
		x:0.56359999999999999,
		y:0.90359999999999996,
		supporter:"C"
	},
	{
		x:0.61529999999999996,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.60019999999999996,
		y:0.92479999999999996,
		supporter:"C"
	},
	{
		x:0.50660000000000005,
		y:0.90090000000000003,
		supporter:"C"
	},
	{
		x:0.51649999999999996,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.62060000000000004,
		y:0.91320000000000001,
		supporter:"C"
	},
	{
		x:0.57630000000000003,
		y:0.90659999999999996,
		supporter:"C"
	},
	{
		x:0.59399999999999997,
		y:0.91959999999999997,
		supporter:"C"
	},
	{
		x:0.53710000000000002,
		y:0.92430000000000001,
		supporter:"C"
	},
	{
		x:0.55689999999999995,
		y:0.91110000000000002,
		supporter:"C"
	},
	{
		x:0.58240000000000003,
		y:0.92010000000000003,
		supporter:"C"
	},
	{
		x:0.51549999999999996,
		y:0.90329999999999999,
		supporter:"C"
	},
	{
		x:0.62429999999999997,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.5554,
		y:0.92530000000000001,
		supporter:"C"
	},
	{
		x:0.52290000000000003,
		y:0.93059999999999998,
		supporter:"C"
	},
	{
		x:0.61080000000000001,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.60980000000000001,
		y:0.91959999999999997,
		supporter:"C"
	},
	{
		x:0.56710000000000005,
		y:0.90669999999999995,
		supporter:"C"
	},
	{
		x:0.59079999999999999,
		y:0.92390000000000005,
		supporter:"C"
	},
	{
		x:0.51,
		y:0.92769999999999997,
		supporter:"C"
	},
	{
		x:0.59250000000000003,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.50509999999999999,
		y:0.91359999999999997,
		supporter:"C"
	},
	{
		x:0.58279999999999998,
		y:0.92300000000000004,
		supporter:"C"
	},
	{
		x:0.58160000000000001,
		y:0.94350000000000001,
		supporter:"C"
	},
	{
		x:0.62050000000000005,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.62139999999999995,
		y:0.9204,
		supporter:"C"
	},
	{
		x:0.6018,
		y:0.92,
		supporter:"C"
	},
	{
		x:0.56179999999999997,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.50719999999999998,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.58489999999999998,
		y:0.9133,
		supporter:"C"
	},
	{
		x:0.58879999999999999,
		y:0.90990000000000004,
		supporter:"C"
	},
	{
		x:0.50119999999999998,
		y:0.9244,
		supporter:"C"
	},
	{
		x:0.54179999999999995,
		y:0.90980000000000005,
		supporter:"C"
	},
	{
		x:0.62209999999999999,
		y:0.94930000000000003,
		supporter:"C"
	},
	{
		x:0.50639999999999996,
		y:0.91600000000000004,
		supporter:"C"
	},
	{
		x:0.52680000000000005,
		y:0.90159999999999996,
		supporter:"C"
	},
	{
		x:0.51759999999999995,
		y:0.91090000000000004,
		supporter:"C"
	},
	{
		x:0.57589999999999997,
		y:0.94499999999999995,
		supporter:"C"
	},
	{
		x:0.53059999999999996,
		y:0.90500000000000003,
		supporter:"C"
	},
	{
		x:0.5575,
		y:0.91620000000000001,
		supporter:"C"
	},
	{
		x:0.61890000000000001,
		y:0.91239999999999999,
		supporter:"C"
	},
	{
		x:0.55689999999999995,
		y:0.90649999999999997,
		supporter:"C"
	},
	{
		x:0.5645,
		y:0.9093,
		supporter:"C"
	},
	{
		x:0.59650000000000003,
		y:0.91710000000000003,
		supporter:"C"
	},
	{
		x:0.58640000000000003,
		y:0.92330000000000001,
		supporter:"C"
	},
	{
		x:0.52439999999999998,
		y:0.9254,
		supporter:"C"
	},
	{
		x:0.50549999999999995,
		y:0.9244,
		supporter:"C"
	},
	{
		x:0.57350000000000001,
		y:0.92310000000000003,
		supporter:"C"
	},
	{
		x:0.52810000000000001,
		y:0.93940000000000001,
		supporter:"C"
	},
	{
		x:0.59919999999999995,
		y:0.92649999999999999,
		supporter:"C"
	},
	{
		x:0.51519999999999999,
		y:0.91259999999999997,
		supporter:"C"
	},
	{
		x:0.55610000000000004,
		y:0.90800000000000003,
		supporter:"C"
	},
	{
		x:0.56620000000000004,
		y:0.94840000000000002,
		supporter:"C"
	},
	{
		x:0.5444,
		y:0.92730000000000001,
		supporter:"C"
	},
	{
		x:0.50790000000000002,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.50119999999999998,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.51359999999999995,
		y:0.90610000000000002,
		supporter:"C"
	},
	{
		x:0.6089,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.51170000000000004,
		y:0.92210000000000003,
		supporter:"C"
	},
	{
		x:0.52159999999999995,
		y:0.92910000000000004,
		supporter:"C"
	},
	{
		x:0.52529999999999999,
		y:0.90039999999999998,
		supporter:"C"
	},
	{
		x:0.51190000000000002,
		y:0.90039999999999998,
		supporter:"C"
	},
	{
		x:0.54179999999999995,
		y:0.90710000000000002,
		supporter:"C"
	},
	{
		x:0.57579999999999998,
		y:0.92800000000000005,
		supporter:"C"
	},
	{
		x:0.62029999999999996,
		y:0.92230000000000001,
		supporter:"C"
	},
	{
		x:0.55989999999999995,
		y:0.9,
		supporter:"C"
	},
	{
		x:0.6089,
		y:0.90849999999999997,
		supporter:"C"
	},
	{
		x:0.59179999999999999,
		y:0.92200000000000004,
		supporter:"C"
	},
	{
		x:0.53649999999999998,
		y:0.90900000000000003,
		supporter:"C"
	},
	{
		x:0.56310000000000004,
		y:0.90480000000000005,
		supporter:"C"
	},
	{
		x:0.58509999999999995,
		y:0.92169999999999996,
		supporter:"C"
	},
	{
		x:0.62419999999999998,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.59530000000000005,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.54039999999999999,
		y:0.90980000000000005,
		supporter:"C"
	},
	{
		x:0.51370000000000005,
		y:0.92220000000000002,
		supporter:"C"
	},
	{
		x:0.58089999999999997,
		y:0.91969999999999996,
		supporter:"C"
	},
	{
		x:0.56779999999999997,
		y:0.9284,
		supporter:"C"
	},
	{
		x:0.50109999999999999,
		y:0.93049999999999999,
		supporter:"C"
	},
	{
		x:0.59930000000000005,
		y:0.92500000000000004,
		supporter:"C"
	},
	{
		x:0.51259999999999994,
		y:0.93830000000000002,
		supporter:"C"
	},
	{
		x:0.53280000000000005,
		y:0.90010000000000001,
		supporter:"C"
	},
	{
		x:0.62460000000000004,
		y:0.92989999999999995,
		supporter:"C"
	},
	{
		x:0.50760000000000005,
		y:0.92320000000000002,
		supporter:"C"
	},
	{
		x:0.56589999999999996,
		y:0.92310000000000003,
		supporter:"C"
	},
	{
		x:0.62809999999999999,
		y:0.90839999999999999,
		supporter:"C"
	},
	{
		x:0.59960000000000002,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.51500000000000001,
		y:0.90300000000000002,
		supporter:"C"
	},
	{
		x:0.53559999999999997,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.56269999999999998,
		y:0.91679999999999995,
		supporter:"C"
	},
	{
		x:0.52259999999999995,
		y:0.90210000000000001,
		supporter:"C"
	},
	{
		x:0.53800000000000003,
		y:0.90080000000000005,
		supporter:"C"
	},
	{
		x:0.51419999999999999,
		y:0.9083,
		supporter:"C"
	},
	{
		x:0.55410000000000004,
		y:0.93379999999999996,
		supporter:"C"
	},
	{
		x:0.59199999999999997,
		y:0.9244,
		supporter:"C"
	},
	{
		x:0.53310000000000002,
		y:0.90620000000000001,
		supporter:"C"
	},
	{
		x:0.58140000000000003,
		y:0.92669999999999997,
		supporter:"C"
	},
	{
		x:0.60609999999999997,
		y:0.90139999999999998,
		supporter:"C"
	},
	{
		x:0.59760000000000002,
		y:0.91259999999999997,
		supporter:"C"
	},
	{
		x:0.57989999999999997,
		y:0.92159999999999997,
		supporter:"C"
	},
	{
		x:0.61209999999999998,
		y:0.92479999999999996,
		supporter:"C"
	},
	{
		x:0.57169999999999999,
		y:0.92349999999999999,
		supporter:"C"
	},
	{
		x:0.5786,
		y:0.92669999999999997,
		supporter:"C"
	},
	{
		x:0.55820000000000003,
		y:0.93110000000000004,
		supporter:"C"
	},
	{
		x:0.57840000000000003,
		y:0.92269999999999996,
		supporter:"C"
	},
	{
		x:0.61040000000000005,
		y:0.91379999999999995,
		supporter:"C"
	},
	{
		x:0.5333,
		y:0.91279999999999994,
		supporter:"C"
	},
	{
		x:0.52200000000000002,
		y:0.91080000000000005,
		supporter:"C"
	},
	{
		x:0.5403,
		y:0.90959999999999996,
		supporter:"C"
	},
	{
		x:0.59509999999999996,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.52480000000000004,
		y:0.91,
		supporter:"C"
	},
	{
		x:0.58479999999999999,
		y:0.92600000000000005,
		supporter:"C"
	},
	{
		x:0.60709999999999997,
		y:0.92290000000000005,
		supporter:"C"
	},
	{
		x:0.6079,
		y:0.90859999999999996,
		supporter:"C"
	},
	{
		x:0.59799999999999998,
		y:0.92720000000000002,
		supporter:"C"
	},
	{
		x:0.57030000000000003,
		y:0.91790000000000005,
		supporter:"C"
	},
	{
		x:0.52629999999999999,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.58389999999999997,
		y:0.90620000000000001,
		supporter:"C"
	},
	{
		x:0.56799999999999995,
		y:0.9173,
		supporter:"C"
	},
	{
		x:0.51270000000000004,
		y:0.90149999999999997,
		supporter:"C"
	},
	{
		x:0.60589999999999999,
		y:0.91879999999999995,
		supporter:"C"
	},
	{
		x:0.51749999999999996,
		y:0.90210000000000001,
		supporter:"C"
	},
	{
		x:0.58130000000000004,
		y:0.92349999999999999,
		supporter:"C"
	},
	{
		x:0.5101,
		y:0.90480000000000005,
		supporter:"C"
	},
	{
		x:0.61070000000000002,
		y:0.92190000000000005,
		supporter:"C"
	},
	{
		x:0.53500000000000003,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.51280000000000003,
		y:0.90720000000000001,
		supporter:"C"
	},
	{
		x:0.54490000000000005,
		y:0.90669999999999995,
		supporter:"C"
	},
	{
		x:0.51519999999999999,
		y:0.90680000000000005,
		supporter:"C"
	},
	{
		x:0.55959999999999999,
		y:0.90649999999999997,
		supporter:"C"
	},
	{
		x:0.62439999999999996,
		y:0.92330000000000001,
		supporter:"C"
	},
	{
		x:0.5333,
		y:0.91190000000000004,
		supporter:"C"
	},
	{
		x:0.62849999999999995,
		y:0.91610000000000003,
		supporter:"C"
	},
	{
		x:0.5867,
		y:0.94450000000000001,
		supporter:"C"
	},
	{
		x:0.50900000000000001,
		y:0.9022,
		supporter:"C"
	},
	{
		x:0.57079999999999997,
		y:0.91520000000000001,
		supporter:"C"
	},
	{
		x:0.55130000000000001,
		y:0.93179999999999996,
		supporter:"C"
	},
	{
		x:0.52049999999999996,
		y:0.92210000000000003,
		supporter:"C"
	},
	{
		x:0.54259999999999997,
		y:0.94020000000000004,
		supporter:"C"
	},
	{
		x:0.54369999999999996,
		y:0.91990000000000005,
		supporter:"C"
	},
	{
		x:0.59609999999999996,
		y:0.92230000000000001,
		supporter:"C"
	},
	{
		x:0.53029999999999999,
		y:0.9032,
		supporter:"C"
	},
	{
		x:0.59089999999999998,
		y:0.9173,
		supporter:"C"
	},
	{
		x:0.5262,
		y:0.90939999999999999,
		supporter:"C"
	},
	{
		x:0.6139,
		y:0.94620000000000004,
		supporter:"C"
	},
	{
		x:0.51890000000000003,
		y:0.90190000000000003,
		supporter:"C"
	},
	{
		x:0.55800000000000005,
		y:0.90110000000000001,
		supporter:"C"
	},
	{
		x:0.53120000000000001,
		y:0.90629999999999999,
		supporter:"C"
	},
	{
		x:0.55169999999999997,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.55479999999999996,
		y:0.90590000000000004,
		supporter:"C"
	},
	{
		x:0.60529999999999995,
		y:0.91930000000000001,
		supporter:"C"
	},
	{
		x:0.55879999999999996,
		y:0.90629999999999999,
		supporter:"C"
	},
	{
		x:0.61480000000000001,
		y:0.92110000000000003,
		supporter:"C"
	},
	{
		x:0.57230000000000003,
		y:0.9113,
		supporter:"C"
	},
	{
		x:0.54969999999999997,
		y:0.90600000000000003,
		supporter:"C"
	},
	{
		x:0.60240000000000005,
		y:0.90990000000000004,
		supporter:"C"
	},
	{
		x:0.5071,
		y:0.90469999999999995,
		supporter:"C"
	},
	{
		x:0.51249999999999996,
		y:0.90700000000000003,
		supporter:"C"
	},
	{
		x:0.51339999999999997,
		y:0.90839999999999999,
		supporter:"C"
	},
	{
		x:0.53069999999999995,
		y:0.90990000000000004,
		supporter:"C"
	},
	{
		x:0.52859999999999996,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.55230000000000001,
		y:0.91120000000000001,
		supporter:"C"
	},
	{
		x:0.54959999999999998,
		y:0.91849999999999998,
		supporter:"C"
	},
	{
		x:0.58179999999999998,
		y:0.9294,
		supporter:"C"
	},
	{
		x:0.5202,
		y:0.91739999999999999,
		supporter:"C"
	},
	{
		x:0.53359999999999996,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.5081,
		y:0.92689999999999995,
		supporter:"C"
	},
	{
		x:0.62890000000000001,
		y:0.91920000000000002,
		supporter:"C"
	},
	{
		x:0.50290000000000001,
		y:0.91020000000000001,
		supporter:"C"
	},
	{
		x:0.62729999999999997,
		y:0.91369999999999996,
		supporter:"C"
	},
	{
		x:0.60429999999999995,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.50490000000000002,
		y:0.90339999999999998,
		supporter:"C"
	},
	{
		x:0.55759999999999998,
		y:0.91149999999999998,
		supporter:"C"
	},
	{
		x:0.56730000000000003,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.5827,
		y:0.92710000000000004,
		supporter:"C"
	},
	{
		x:0.59179999999999999,
		y:0.92500000000000004,
		supporter:"C"
	},
	{
		x:0.59470000000000001,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.59699999999999998,
		y:0.94910000000000005,
		supporter:"C"
	},
	{
		x:0.53890000000000005,
		y:0.90329999999999999,
		supporter:"C"
	},
	{
		x:0.55779999999999996,
		y:0.92410000000000003,
		supporter:"C"
	},
	{
		x:0.5524,
		y:0.90259999999999996,
		supporter:"C"
	},
	{
		x:0.6008,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.51200000000000001,
		y:0.90800000000000003,
		supporter:"C"
	},
	{
		x:0.62129999999999996,
		y:0.90580000000000005,
		supporter:"C"
	},
	{
		x:0.55720000000000003,
		y:0.91400000000000003,
		supporter:"C"
	},
	{
		x:0.61880000000000002,
		y:0.91100000000000003,
		supporter:"C"
	},
	{
		x:0.61899999999999999,
		y:0.90939999999999999,
		supporter:"C"
	},
	{
		x:0.55800000000000005,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.52890000000000004,
		y:0.91039999999999999,
		supporter:"C"
	},
	{
		x:0.57750000000000001,
		y:0.91639999999999999,
		supporter:"C"
	},
	{
		x:0.55079999999999996,
		y:0.90510000000000002,
		supporter:"C"
	},
	{
		x:0.53990000000000005,
		y:0.93210000000000004,
		supporter:"C"
	},
	{
		x:0.53100000000000003,
		y:0.92479999999999996,
		supporter:"C"
	},
	{
		x:0.53190000000000004,
		y:0.90900000000000003,
		supporter:"C"
	},
	{
		x:0.61150000000000004,
		y:0.93300000000000005,
		supporter:"C"
	},
	{
		x:0.55710000000000004,
		y:0.91800000000000004,
		supporter:"C"
	},
	{
		x:0.61960000000000004,
		y:0.92220000000000002,
		supporter:"C"
	},
	{
		x:0.57079999999999997,
		y:0.93079999999999996,
		supporter:"C"
	},
	{
		x:0.52139999999999997,
		y:0.90139999999999998,
		supporter:"C"
	},
	{
		x:0.59450000000000003,
		y:0.90720000000000001,
		supporter:"C"
	},
	{
		x:0.5232,
		y:0.92849999999999999,
		supporter:"C"
	},
	{
		x:0.51639999999999997,
		y:0.92589999999999995,
		supporter:"C"
	},
	{
		x:0.62209999999999999,
		y:0.91069999999999995,
		supporter:"C"
	},
	{
		x:0.6169,
		y:0.91849999999999998,
		supporter:"C"
	},
	{
		x:0.59630000000000005,
		y:0.90559999999999996,
		supporter:"C"
	},
	{
		x:0.57930000000000004,
		y:0.91830000000000001,
		supporter:"C"
	},
	{
		x:0.59330000000000005,
		y:0.92120000000000002,
		supporter:"C"
	},
	{
		x:0.52969999999999995,
		y:0.90449999999999997,
		supporter:"C"
	},
	{
		x:0.54859999999999998,
		y:0.93069999999999997,
		supporter:"C"
	},
	{
		x:0.58260000000000001,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.55659999999999998,
		y:0.91759999999999997,
		supporter:"C"
	},
	{
		x:0.52880000000000005,
		y:0.90790000000000004,
		supporter:"C"
	},
	{
		x:0.60170000000000001,
		y:0.92220000000000002,
		supporter:"C"
	},
	{
		x:0.54110000000000003,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.6149,
		y:0.92649999999999999,
		supporter:"C"
	},
	{
		x:0.62629999999999997,
		y:0.91620000000000001,
		supporter:"C"
	},
	{
		x:0.58409999999999995,
		y:0.92320000000000002,
		supporter:"C"
	},
	{
		x:0.53859999999999997,
		y:0.91590000000000005,
		supporter:"C"
	},
	{
		x:0.50180000000000002,
		y:0.91149999999999998,
		supporter:"C"
	},
	{
		x:0.62050000000000005,
		y:0.91549999999999998,
		supporter:"C"
	},
	{
		x:0.59219999999999995,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.62709999999999999,
		y:0.92049999999999998,
		supporter:"C"
	},
	{
		x:0.5968,
		y:0.91830000000000001,
		supporter:"C"
	},
	{
		x:0.62450000000000006,
		y:0.90290000000000004,
		supporter:"C"
	},
	{
		x:0.50680000000000003,
		y:0.90769999999999995,
		supporter:"C"
	},
	{
		x:0.51539999999999997,
		y:0.90349999999999997,
		supporter:"C"
	},
	{
		x:0.55559999999999998,
		y:0.92159999999999997,
		supporter:"C"
	},
	{
		x:0.62119999999999997,
		y:0.9234,
		supporter:"C"
	},
	{
		x:0.51600000000000001,
		y:0.91120000000000001,
		supporter:"C"
	},
	{
		x:0.62439999999999996,
		y:0.9254,
		supporter:"C"
	},
	{
		x:0.52549999999999997,
		y:0.90290000000000004,
		supporter:"C"
	},
	{
		x:0.52869999999999995,
		y:0.92459999999999998,
		supporter:"C"
	},
	{
		x:0.54479999999999995,
		y:0.94450000000000001,
		supporter:"C"
	},
	{
		x:0.60929999999999995,
		y:0.91310000000000002,
		supporter:"C"
	},
	{
		x:0.62980000000000003,
		y:0.91090000000000004,
		supporter:"C"
	},
	{
		x:0.53049999999999997,
		y:0.92849999999999999,
		supporter:"C"
	},
	{
		x:0.61829999999999996,
		y:0.92600000000000005,
		supporter:"C"
	},
	{
		x:0.5444,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.55710000000000004,
		y:0.9163,
		supporter:"C"
	},
	{
		x:0.60350000000000004,
		y:0.94550000000000001,
		supporter:"C"
	},
	{
		x:0.57920000000000005,
		y:0.9456,
		supporter:"C"
	},
	{
		x:0.61040000000000005,
		y:0.91720000000000002,
		supporter:"C"
	},
	{
		x:0.50919999999999999,
		y:0.92420000000000002,
		supporter:"C"
	},
	{
		x:0.60340000000000005,
		y:0.92349999999999999,
		supporter:"C"
	},
	{
		x:0.5847,
		y:0.94340000000000002,
		supporter:"C"
	},
	{
		x:0.56859999999999999,
		y:0.91890000000000005,
		supporter:"C"
	},
	{
		x:0.56310000000000004,
		y:0.90649999999999997,
		supporter:"C"
	},
	{
		x:0.62960000000000005,
		y:0.92589999999999995,
		supporter:"C"
	},
	{
		x:0.52090000000000003,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.61070000000000002,
		y:0.92979999999999996,
		supporter:"C"
	},
	{
		x:0.53680000000000005,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.62729999999999997,
		y:0.94869999999999999,
		supporter:"C"
	},
	{
		x:0.61519999999999997,
		y:0.92200000000000004,
		supporter:"C"
	},
	{
		x:0.59130000000000005,
		y:0.91120000000000001,
		supporter:"C"
	},
	{
		x:0.55659999999999998,
		y:0.90600000000000003,
		supporter:"C"
	},
	{
		x:0.51919999999999999,
		y:0.90620000000000001,
		supporter:"C"
	},
	{
		x:0.60029999999999994,
		y:0.92010000000000003,
		supporter:"C"
	},
	{
		x:0.52710000000000001,
		y:0.91100000000000003,
		supporter:"C"
	},
	{
		x:0.59370000000000001,
		y:0.91669999999999996,
		supporter:"C"
	},
	{
		x:0.56879999999999997,
		y:0.91349999999999998,
		supporter:"C"
	},
	{
		x:0.61860000000000004,
		y:0.92789999999999995,
		supporter:"C"
	},
	{
		x:0.57330000000000003,
		y:0.92120000000000002,
		supporter:"C"
	},
	{
		x:0.57079999999999997,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.56930000000000003,
		y:0.92710000000000004,
		supporter:"C"
	},
	{
		x:0.54210000000000003,
		y:0.9083,
		supporter:"C"
	},
	{
		x:0.62639999999999996,
		y:0.91269999999999996,
		supporter:"C"
	},
	{
		x:0.62470000000000003,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.54969999999999997,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.61450000000000005,
		y:0.92069999999999996,
		supporter:"C"
	},
	{
		x:0.58409999999999995,
		y:0.94620000000000004,
		supporter:"C"
	},
	{
		x:0.52190000000000003,
		y:0.90649999999999997,
		supporter:"C"
	},
	{
		x:0.51959999999999995,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.56530000000000002,
		y:0.90869999999999995,
		supporter:"C"
	},
	{
		x:0.50860000000000005,
		y:0.9224,
		supporter:"C"
	},
	{
		x:0.54449999999999998,
		y:0.93020000000000003,
		supporter:"C"
	},
	{
		x:0.59599999999999997,
		y:0.91679999999999995,
		supporter:"C"
	},
	{
		x:0.50039999999999996,
		y:0.91920000000000002,
		supporter:"C"
	},
	{
		x:0.51039999999999996,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.50219999999999998,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.6149,
		y:0.9284,
		supporter:"C"
	},
	{
		x:0.6089,
		y:0.92269999999999996,
		supporter:"C"
	},
	{
		x:0.54649999999999999,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.58020000000000005,
		y:0.9133,
		supporter:"C"
	},
	{
		x:0.60419999999999996,
		y:0.9204,
		supporter:"C"
	},
	{
		x:0.5988,
		y:0.92330000000000001,
		supporter:"C"
	},
	{
		x:0.50580000000000003,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.56740000000000002,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.57969999999999999,
		y:0.92159999999999997,
		supporter:"C"
	},
	{
		x:0.61629999999999996,
		y:0.92390000000000005,
		supporter:"C"
	},
	{
		x:0.54449999999999998,
		y:0.90369999999999995,
		supporter:"C"
	},
	{
		x:0.52529999999999999,
		y:0.92669999999999997,
		supporter:"C"
	},
	{
		x:0.62549999999999994,
		y:0.91149999999999998,
		supporter:"C"
	},
	{
		x:0.57799999999999996,
		y:0.91439999999999999,
		supporter:"C"
	},
	{
		x:0.59619999999999995,
		y:0.90429999999999999,
		supporter:"C"
	},
	{
		x:0.60299999999999998,
		y:0.91469999999999996,
		supporter:"C"
	},
	{
		x:0.6038,
		y:0.91039999999999999,
		supporter:"C"
	},
	{
		x:0.58520000000000005,
		y:0.91859999999999997,
		supporter:"C"
	},
	{
		x:0.54600000000000004,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.57920000000000005,
		y:0.91930000000000001,
		supporter:"C"
	},
	{
		x:0.50390000000000001,
		y:0.91010000000000002,
		supporter:"C"
	},
	{
		x:0.56820000000000004,
		y:0.9466,
		supporter:"C"
	},
	{
		x:0.62370000000000003,
		y:0.9254,
		supporter:"C"
	},
	{
		x:0.54400000000000004,
		y:0.91379999999999995,
		supporter:"C"
	},
	{
		x:0.50919999999999999,
		y:0.91,
		supporter:"C"
	},
	{
		x:0.53480000000000005,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.57830000000000004,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.54969999999999997,
		y:0.90090000000000003,
		supporter:"C"
	},
	{
		x:0.55020000000000002,
		y:0.9244,
		supporter:"C"
	},
	{
		x:0.59789999999999999,
		y:0.92079999999999995,
		supporter:"C"
	},
	{
		x:0.60399999999999998,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.51459999999999995,
		y:0.90500000000000003,
		supporter:"C"
	},
	{
		x:0.57440000000000002,
		y:0.92079999999999995,
		supporter:"C"
	},
	{
		x:0.50819999999999999,
		y:0.90780000000000005,
		supporter:"C"
	},
	{
		x:0.58479999999999999,
		y:0.92110000000000003,
		supporter:"C"
	},
	{
		x:0.60819999999999996,
		y:0.90649999999999997,
		supporter:"C"
	},
	{
		x:0.61870000000000003,
		y:0.91679999999999995,
		supporter:"C"
	},
	{
		x:0.58330000000000004,
		y:0.92100000000000004,
		supporter:"C"
	},
	{
		x:0.57699999999999996,
		y:0.91890000000000005,
		supporter:"C"
	},
	{
		x:0.51559999999999995,
		y:0.90529999999999999,
		supporter:"C"
	},
	{
		x:0.6028,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.61209999999999998,
		y:0.92800000000000005,
		supporter:"C"
	},
	{
		x:0.51670000000000005,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.54090000000000005,
		y:0.91239999999999999,
		supporter:"C"
	},
	{
		x:0.57940000000000003,
		y:0.92179999999999995,
		supporter:"C"
	},
	{
		x:0.60670000000000002,
		y:0.91539999999999999,
		supporter:"C"
	},
	{
		x:0.51039999999999996,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.59450000000000003,
		y:0.92379999999999995,
		supporter:"C"
	},
	{
		x:0.58420000000000005,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.61150000000000004,
		y:0.91920000000000002,
		supporter:"C"
	},
	{
		x:0.53469999999999995,
		y:0.90990000000000004,
		supporter:"C"
	},
	{
		x:0.5766,
		y:0.91110000000000002,
		supporter:"C"
	},
	{
		x:0.51890000000000003,
		y:0.9093,
		supporter:"C"
	},
	{
		x:0.56079999999999997,
		y:0.91420000000000001,
		supporter:"C"
	},
	{
		x:0.61929999999999996,
		y:0.91420000000000001,
		supporter:"C"
	},
	{
		x:0.62770000000000004,
		y:0.91020000000000001,
		supporter:"C"
	},
	{
		x:0.61699999999999999,
		y:0.91969999999999996,
		supporter:"C"
	},
	{
		x:0.5645,
		y:0.91810000000000003,
		supporter:"C"
	},
	{
		x:0.59030000000000005,
		y:0.92079999999999995,
		supporter:"C"
	},
	{
		x:0.5262,
		y:0.90369999999999995,
		supporter:"C"
	},
	{
		x:0.56020000000000003,
		y:0.90010000000000001,
		supporter:"C"
	},
	{
		x:0.61890000000000001,
		y:0.91110000000000002,
		supporter:"C"
	},
	{
		x:0.60729999999999995,
		y:0.90410000000000001,
		supporter:"C"
	},
	{
		x:0.56499999999999995,
		y:0.92049999999999998,
		supporter:"C"
	},
	{
		x:0.56659999999999999,
		y:0.90580000000000005,
		supporter:"C"
	},
	{
		x:0.5252,
		y:0.90369999999999995,
		supporter:"C"
	},
	{
		x:0.54920000000000002,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.62429999999999997,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.59040000000000004,
		y:0.91920000000000002,
		supporter:"C"
	},
	{
		x:0.59940000000000004,
		y:0.91249999999999998,
		supporter:"C"
	},
	{
		x:0.50919999999999999,
		y:0.90449999999999997,
		supporter:"C"
	},
	{
		x:0.56530000000000002,
		y:0.92800000000000005,
		supporter:"C"
	},
	{
		x:0.62160000000000004,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.51290000000000002,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.52529999999999999,
		y:0.92979999999999996,
		supporter:"C"
	},
	{
		x:0.52339999999999998,
		y:0.90180000000000005,
		supporter:"C"
	},
	{
		x:0.62260000000000004,
		y:0.92849999999999999,
		supporter:"C"
	},
	{
		x:0.58779999999999999,
		y:0.90480000000000005,
		supporter:"C"
	},
	{
		x:0.5514,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.60060000000000002,
		y:0.91959999999999997,
		supporter:"C"
	},
	{
		x:0.56610000000000005,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.58889999999999998,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.62309999999999999,
		y:0.90910000000000002,
		supporter:"C"
	},
	{
		x:0.56389999999999996,
		y:0.91010000000000002,
		supporter:"C"
	},
	{
		x:0.5766,
		y:0.92230000000000001,
		supporter:"C"
	},
	{
		x:0.60419999999999996,
		y:0.91739999999999999,
		supporter:"C"
	},
	{
		x:0.59370000000000001,
		y:0.92310000000000003,
		supporter:"C"
	},
	{
		x:0.55549999999999999,
		y:0.90849999999999997,
		supporter:"C"
	},
	{
		x:0.5252,
		y:0.90369999999999995,
		supporter:"C"
	},
	{
		x:0.50370000000000004,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.61260000000000003,
		y:0.92020000000000002,
		supporter:"C"
	},
	{
		x:0.62519999999999998,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.53459999999999996,
		y:0.90869999999999995,
		supporter:"C"
	},
	{
		x:0.51580000000000004,
		y:0.93989999999999996,
		supporter:"C"
	},
	{
		x:0.5645,
		y:0.91669999999999996,
		supporter:"C"
	},
	{
		x:0.5857,
		y:0.94379999999999997,
		supporter:"C"
	},
	{
		x:0.58889999999999998,
		y:0.92669999999999997,
		supporter:"C"
	},
	{
		x:0.53720000000000001,
		y:0.9133,
		supporter:"C"
	},
	{
		x:0.6139,
		y:0.92230000000000001,
		supporter:"C"
	},
	{
		x:0.51090000000000002,
		y:0.91069999999999995,
		supporter:"C"
	},
	{
		x:0.61819999999999997,
		y:0.91720000000000002,
		supporter:"C"
	},
	{
		x:0.56299999999999994,
		y:0.90169999999999995,
		supporter:"C"
	},
	{
		x:0.59130000000000005,
		y:0.91620000000000001,
		supporter:"C"
	},
	{
		x:0.55820000000000003,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.6099,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.55869999999999997,
		y:0.91439999999999999,
		supporter:"C"
	},
	{
		x:0.6018,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.62139999999999995,
		y:0.92430000000000001,
		supporter:"C"
	},
	{
		x:0.5756,
		y:0.91700000000000004,
		supporter:"C"
	},
	{
		x:0.55910000000000004,
		y:0.93069999999999997,
		supporter:"C"
	},
	{
		x:0.62209999999999999,
		y:0.90790000000000004,
		supporter:"C"
	},
	{
		x:0.62670000000000003,
		y:0.91310000000000002,
		supporter:"C"
	},
	{
		x:0.5726,
		y:0.92359999999999998,
		supporter:"C"
	},
	{
		x:0.56320000000000003,
		y:0.90059999999999996,
		supporter:"C"
	},
	{
		x:0.57010000000000005,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.5242,
		y:0.90539999999999998,
		supporter:"C"
	},
	{
		x:0.58299999999999996,
		y:0.91790000000000005,
		supporter:"C"
	},
	{
		x:0.59360000000000002,
		y:0.91739999999999999,
		supporter:"C"
	},
	{
		x:0.53700000000000003,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.51119999999999999,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.51129999999999998,
		y:0.90959999999999996,
		supporter:"C"
	},
	{
		x:0.56499999999999995,
		y:0.90769999999999995,
		supporter:"C"
	},
	{
		x:0.58350000000000002,
		y:0.91830000000000001,
		supporter:"C"
	},
	{
		x:0.52710000000000001,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.51649999999999996,
		y:0.93010000000000004,
		supporter:"C"
	},
	{
		x:0.61140000000000005,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.53180000000000005,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.58930000000000005,
		y:0.92020000000000002,
		supporter:"C"
	},
	{
		x:0.53549999999999998,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.53,
		y:0.90310000000000001,
		supporter:"C"
	},
	{
		x:0.58809999999999996,
		y:0.92449999999999999,
		supporter:"C"
	},
	{
		x:0.5585,
		y:0.91169999999999995,
		supporter:"C"
	},
	{
		x:0.50880000000000003,
		y:0.90229999999999999,
		supporter:"C"
	},
	{
		x:0.54279999999999995,
		y:0.90629999999999999,
		supporter:"C"
	},
	{
		x:0.50249999999999995,
		y:0.91039999999999999,
		supporter:"C"
	},
	{
		x:0.5827,
		y:0.94510000000000005,
		supporter:"C"
	},
	{
		x:0.52270000000000005,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.5121,
		y:0.93120000000000003,
		supporter:"C"
	},
	{
		x:0.5081,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.51329999999999998,
		y:0.91459999999999997,
		supporter:"C"
	},
	{
		x:0.53420000000000001,
		y:0.90059999999999996,
		supporter:"C"
	},
	{
		x:0.60570000000000002,
		y:0.92789999999999995,
		supporter:"C"
	},
	{
		x:0.50439999999999996,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.59050000000000002,
		y:0.92200000000000004,
		supporter:"C"
	},
	{
		x:0.61470000000000002,
		y:0.90910000000000002,
		supporter:"C"
	},
	{
		x:0.61850000000000005,
		y:0.91720000000000002,
		supporter:"C"
	},
	{
		x:0.60550000000000004,
		y:0.91830000000000001,
		supporter:"C"
	},
	{
		x:0.54120000000000001,
		y:0.9022,
		supporter:"C"
	},
	{
		x:0.54290000000000005,
		y:0.92300000000000004,
		supporter:"C"
	},
	{
		x:0.6008,
		y:0.91700000000000004,
		supporter:"C"
	},
	{
		x:0.57040000000000002,
		y:0.92,
		supporter:"C"
	},
	{
		x:0.5091,
		y:0.90749999999999997,
		supporter:"C"
	},
	{
		x:0.61880000000000002,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.62880000000000003,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.62380000000000002,
		y:0.9,
		supporter:"C"
	},
	{
		x:0.52569999999999995,
		y:0.91020000000000001,
		supporter:"C"
	},
	{
		x:0.6018,
		y:0.92359999999999998,
		supporter:"C"
	},
	{
		x:0.61329999999999996,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.61950000000000005,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.54510000000000003,
		y:0.91010000000000002,
		supporter:"C"
	},
	{
		x:0.53420000000000001,
		y:0.91479999999999995,
		supporter:"C"
	},
	{
		x:0.51129999999999998,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.51160000000000005,
		y:0.90639999999999998,
		supporter:"C"
	},
	{
		x:0.61299999999999999,
		y:0.93110000000000004,
		supporter:"C"
	},
	{
		x:0.50980000000000003,
		y:0.91339999999999999,
		supporter:"C"
	},
	{
		x:0.55300000000000005,
		y:0.90400000000000003,
		supporter:"C"
	},
	{
		x:0.59770000000000001,
		y:0.92120000000000002,
		supporter:"C"
	},
	{
		x:0.50900000000000001,
		y:0.92830000000000001,
		supporter:"C"
	},
	{
		x:0.53779999999999994,
		y:0.91610000000000003,
		supporter:"C"
	},
	{
		x:0.504,
		y:0.92779999999999996,
		supporter:"C"
	},
	{
		x:0.55389999999999995,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.59940000000000004,
		y:0.94940000000000002,
		supporter:"C"
	},
	{
		x:0.54600000000000004,
		y:0.91249999999999998,
		supporter:"C"
	},
	{
		x:0.59889999999999999,
		y:0.92469999999999997,
		supporter:"C"
	},
	{
		x:0.54469999999999996,
		y:0.90669999999999995,
		supporter:"C"
	},
	{
		x:0.54510000000000003,
		y:0.90710000000000002,
		supporter:"C"
	},
	{
		x:0.51680000000000004,
		y:0.93110000000000004,
		supporter:"C"
	},
	{
		x:0.5071,
		y:0.91700000000000004,
		supporter:"C"
	},
	{
		x:0.61750000000000005,
		y:0.90110000000000001,
		supporter:"C"
	},
	{
		x:0.61429999999999996,
		y:0.9022,
		supporter:"C"
	},
	{
		x:0.58299999999999996,
		y:0.92859999999999998,
		supporter:"C"
	},
	{
		x:0.55030000000000001,
		y:0.9153,
		supporter:"C"
	},
	{
		x:0.53080000000000005,
		y:0.92390000000000005,
		supporter:"C"
	},
	{
		x:0.50380000000000003,
		y:0.91169999999999995,
		supporter:"C"
	},
	{
		x:0.5363,
		y:0.92620000000000002,
		supporter:"C"
	},
	{
		x:0.60540000000000005,
		y:0.92359999999999998,
		supporter:"C"
	},
	{
		x:0.6079,
		y:0.91790000000000005,
		supporter:"C"
	},
	{
		x:0.60150000000000003,
		y:0.91410000000000002,
		supporter:"C"
	},
	{
		x:0.55110000000000003,
		y:0.92110000000000003,
		supporter:"C"
	},
	{
		x:0.52139999999999997,
		y:0.91010000000000002,
		supporter:"C"
	},
	{
		x:0.62409999999999999,
		y:0.92379999999999995,
		supporter:"C"
	},
	{
		x:0.55549999999999999,
		y:0.90229999999999999,
		supporter:"C"
	},
	{
		x:0.5454,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.58099999999999996,
		y:0.92079999999999995,
		supporter:"C"
	},
	{
		x:0.53959999999999997,
		y:0.91579999999999995,
		supporter:"C"
	},
	{
		x:0.57120000000000004,
		y:0.91800000000000004,
		supporter:"C"
	},
	{
		x:0.58650000000000002,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.55720000000000003,
		y:0.90680000000000005,
		supporter:"C"
	},
	{
		x:0.51490000000000002,
		y:0.90029999999999999,
		supporter:"C"
	},
	{
		x:0.52239999999999998,
		y:0.90459999999999996,
		supporter:"C"
	},
	{
		x:0.56879999999999997,
		y:0.91369999999999996,
		supporter:"C"
	},
	{
		x:0.51929999999999998,
		y:0.90139999999999998,
		supporter:"C"
	},
	{
		x:0.50109999999999999,
		y:0.92710000000000004,
		supporter:"C"
	},
	{
		x:0.54059999999999997,
		y:0.90649999999999997,
		supporter:"C"
	},
	{
		x:0.60619999999999996,
		y:0.92120000000000002,
		supporter:"C"
	},
	{
		x:0.5272,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.59340000000000004,
		y:0.94359999999999999,
		supporter:"C"
	},
	{
		x:0.5444,
		y:0.90549999999999997,
		supporter:"C"
	},
	{
		x:0.51780000000000004,
		y:0.90310000000000001,
		supporter:"C"
	},
	{
		x:0.60670000000000002,
		y:0.94740000000000002,
		supporter:"C"
	},
	{
		x:0.60460000000000003,
		y:0.91920000000000002,
		supporter:"C"
	},
	{
		x:0.62150000000000005,
		y:0.90310000000000001,
		supporter:"C"
	},
	{
		x:0.61470000000000002,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.54700000000000004,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.5968,
		y:0.91359999999999997,
		supporter:"C"
	},
	{
		x:0.61750000000000005,
		y:0.91969999999999996,
		supporter:"C"
	},
	{
		x:0.54630000000000001,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.52439999999999998,
		y:0.91010000000000002,
		supporter:"C"
	},
	{
		x:0.54310000000000003,
		y:0.92210000000000003,
		supporter:"C"
	},
	{
		x:0.52139999999999997,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.61499999999999999,
		y:0.93010000000000004,
		supporter:"C"
	},
	{
		x:0.51659999999999995,
		y:0.91320000000000001,
		supporter:"C"
	},
	{
		x:0.51339999999999997,
		y:0.92520000000000002,
		supporter:"C"
	},
	{
		x:0.59489999999999998,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.55600000000000005,
		y:0.91210000000000002,
		supporter:"C"
	},
	{
		x:0.57789999999999997,
		y:0.92569999999999997,
		supporter:"C"
	},
	{
		x:0.57050000000000001,
		y:0.90620000000000001,
		supporter:"C"
	},
	{
		x:0.58750000000000002,
		y:0.94640000000000002,
		supporter:"C"
	},
	{
		x:0.50080000000000002,
		y:0.90769999999999995,
		supporter:"C"
	},
	{
		x:0.5403,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.61950000000000005,
		y:0.9012,
		supporter:"C"
	},
	{
		x:0.51439999999999997,
		y:0.93030000000000002,
		supporter:"C"
	},
	{
		x:0.54559999999999997,
		y:0.91200000000000003,
		supporter:"C"
	},
	{
		x:0.50619999999999998,
		y:0.9042,
		supporter:"C"
	},
	{
		x:0.54720000000000002,
		y:0.91100000000000003,
		supporter:"C"
	},
	{
		x:0.52869999999999995,
		y:0.90839999999999999,
		supporter:"C"
	},
	{
		x:0.50090000000000001,
		y:0.92220000000000002,
		supporter:"C"
	},
	{
		x:0.51839999999999997,
		y:0.9032,
		supporter:"C"
	},
	{
		x:0.51759999999999995,
		y:0.91180000000000005,
		supporter:"C"
	},
	{
		x:0.61599999999999999,
		y:0.93,
		supporter:"C"
	},
	{
		x:0.51719999999999999,
		y:0.91820000000000002,
		supporter:"C"
	},
	{
		x:0.58520000000000005,
		y:0.94740000000000002,
		supporter:"C"
	},
	{
		x:0.58230000000000004,
		y:0.91869999999999996,
		supporter:"C"
	},
	{
		x:0.58309999999999995,
		y:0.92830000000000001,
		supporter:"C"
	},
	{
		x:0.51249999999999996,
		y:0.90110000000000001,
		supporter:"C"
	},
	{
		x:0.53759999999999997,
		y:0.90880000000000005,
		supporter:"C"
	},
	{
		x:0.62719999999999998,
		y:0.90749999999999997,
		supporter:"C"
	},
	{
		x:0.62590000000000001,
		y:0.92300000000000004,
		supporter:"C"
	},
	{
		x:0.53720000000000001,
		y:0.91279999999999994,
		supporter:"C"
	},
	{
		x:0.54300000000000004,
		y:0.91659999999999997,
		supporter:"C"
	},
	{
		x:0.55489999999999995,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.50649999999999995,
		y:0.90190000000000003,
		supporter:"C"
	},
	{
		x:0.57140000000000002,
		y:0.91149999999999998,
		supporter:"C"
	},
	{
		x:0.55730000000000002,
		y:0.91439999999999999,
		supporter:"C"
	},
	{
		x:0.60940000000000005,
		y:0.92,
		supporter:"C"
	},
	{
		x:0.56059999999999999,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.59930000000000005,
		y:0.90459999999999996,
		supporter:"C"
	},
	{
		x:0.55149999999999999,
		y:0.91249999999999998,
		supporter:"C"
	},
	{
		x:0.61809999999999998,
		y:0.90949999999999998,
		supporter:"C"
	},
	{
		x:0.55110000000000003,
		y:0.90269999999999995,
		supporter:"C"
	},
	{
		x:0.52790000000000004,
		y:0.91590000000000005,
		supporter:"C"
	},
	{
		x:0.62139999999999995,
		y:0.92820000000000003,
		supporter:"C"
	},
	{
		x:0.54079999999999995,
		y:0.9032,
		supporter:"C"
	},
	{
		x:0.60270000000000001,
		y:0.92390000000000005,
		supporter:"C"
	},
	{
		x:0.50249999999999995,
		y:0.90069999999999995,
		supporter:"C"
	},
	{
		x:0.57050000000000001,
		y:0.91469999999999996,
		supporter:"C"
	},
	{
		x:0.51080000000000003,
		y:0.90459999999999996,
		supporter:"C"
	},
	{
		x:0.61960000000000004,
		y:0.92169999999999996,
		supporter:"C"
	},
	{
		x:0.53759999999999997,
		y:0.9012,
		supporter:"C"
	},
	{
		x:0.56440000000000001,
		y:0.90580000000000005,
		supporter:"C"
	},
	{
		x:0.5635,
		y:0.91520000000000001,
		supporter:"C"
	},
	{
		x:0.52759999999999996,
		y:0.91210000000000002,
		supporter:"C"
	},
	{
		x:0.51100000000000001,
		y:0.91,
		supporter:"C"
	},
	{
		x:0.61370000000000002,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.50090000000000001,
		y:0.90680000000000005,
		supporter:"C"
	},
	{
		x:0.53549999999999998,
		y:0.90700000000000003,
		supporter:"C"
	},
	{
		x:0.56669999999999998,
		y:0.91720000000000002,
		supporter:"C"
	},
	{
		x:0.52359999999999995,
		y:0.93330000000000002,
		supporter:"C"
	},
	{
		x:0.62409999999999999,
		y:0.90680000000000005,
		supporter:"C"
	},
	{
		x:0.52429999999999999,
		y:0.90339999999999998,
		supporter:"C"
	},
	{
		x:0.51900000000000002,
		y:0.90100000000000002,
		supporter:"C"
	},
	{
		x:0.56369999999999998,
		y:0.91449999999999998,
		supporter:"C"
	},
	{
		x:0.60040000000000004,
		y:0.91920000000000002,
		supporter:"C"
	},
	{
		x:0.52059999999999995,
		y:0.91500000000000004,
		supporter:"C"
	},
	{
		x:0.60899999999999999,
		y:0.90310000000000001,
		supporter:"C"
	},
	{
		x:0.51570000000000005,
		y:0.90529999999999999,
		supporter:"C"
	},
	{
		x:0.58240000000000003,
		y:0.94330000000000003,
		supporter:"C"
	},
	{
		x:0.51080000000000003,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.52159999999999995,
		y:0.9032,
		supporter:"C"
	},
	{
		x:0.62890000000000001,
		y:0.90610000000000002,
		supporter:"C"
	},
	{
		x:0.50190000000000001,
		y:0.92620000000000002,
		supporter:"C"
	},
	{
		x:0.5655,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.50670000000000004,
		y:0.90739999999999998,
		supporter:"C"
	},
	{
		x:0.57940000000000003,
		y:0.90459999999999996,
		supporter:"C"
	},
	{
		x:0.57699999999999996,
		y:0.91320000000000001,
		supporter:"C"
	},
	{
		x:0.60270000000000001,
		y:0.93069999999999997,
		supporter:"C"
	},
	{
		x:0.51549999999999996,
		y:0.90769999999999995,
		supporter:"C"
	},
	{
		x:0.52100000000000002,
		y:0.90820000000000001,
		supporter:"C"
	},
	{
		x:0.62450000000000006,
		y:0.90959999999999996,
		supporter:"C"
	},
	{
		x:0.5776,
		y:0.9093,
		supporter:"C"
	},
	{
		x:0.62409999999999999,
		y:0.91269999999999996,
		supporter:"C"
	},
	{
		x:0.5675,
		y:0.90290000000000004,
		supporter:"C"
	},
	{
		x:0.62760000000000005,
		y:0.91420000000000001,
		supporter:"C"
	},
	{
		x:0.60199999999999998,
		y:0.90839999999999999,
		supporter:"C"
	},
	{
		x:0.61229999999999996,
		y:0.91439999999999999,
		supporter:"C"
	},
	{
		x:0.62609999999999999,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.60870000000000002,
		y:0.91539999999999999,
		supporter:"C"
	},
	{
		x:0.62060000000000004,
		y:0.9254,
		supporter:"C"
	},
	{
		x:0.55710000000000004,
		y:0.90100000000000002,
		supporter:"C"
	},
	{
		x:0.52580000000000005,
		y:0.92549999999999999,
		supporter:"C"
	},
	{
		x:0.56989999999999996,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.59560000000000002,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.59689999999999999,
		y:0.91549999999999998,
		supporter:"C"
	},
	{
		x:0.55410000000000004,
		y:0.9133,
		supporter:"C"
	},
	{
		x:0.62,
		y:0.91,
		supporter:"C"
	},
	{
		x:0.56879999999999997,
		y:0.91210000000000002,
		supporter:"C"
	},
	{
		x:0.52900000000000003,
		y:0.91049999999999998,
		supporter:"C"
	},
	{
		x:0.53,
		y:0.92830000000000001,
		supporter:"C"
	},
	{
		x:0.50449999999999995,
		y:0.90910000000000002,
		supporter:"C"
	},
	{
		x:0.60670000000000002,
		y:0.92549999999999999,
		supporter:"C"
	},
	{
		x:0.52059999999999995,
		y:0.92330000000000001,
		supporter:"C"
	},
	{
		x:0.6129,
		y:0.90949999999999998,
		supporter:"C"
	},
	{
		x:0.58609999999999995,
		y:0.92279999999999995,
		supporter:"C"
	},
	{
		x:0.57430000000000003,
		y:0.91210000000000002,
		supporter:"C"
	},
	{
		x:0.52690000000000003,
		y:0.92220000000000002,
		supporter:"C"
	},
	{
		x:0.56420000000000003,
		y:0.90880000000000005,
		supporter:"C"
	},
	{
		x:0.58660000000000001,
		y:0.92279999999999995,
		supporter:"C"
	},
	{
		x:0.54649999999999999,
		y:0.91110000000000002,
		supporter:"C"
	},
	{
		x:0.57440000000000002,
		y:0.91039999999999999,
		supporter:"C"
	},
	{
		x:0.52639999999999998,
		y:0.92730000000000001,
		supporter:"C"
	},
	{
		x:0.54720000000000002,
		y:0.90800000000000003,
		supporter:"C"
	},
	{
		x:0.52280000000000004,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.60950000000000004,
		y:0.92210000000000003,
		supporter:"C"
	},
	{
		x:0.57179999999999997,
		y:0.90449999999999997,
		supporter:"C"
	},
	{
		x:0.61760000000000004,
		y:0.92300000000000004,
		supporter:"C"
	},
	{
		x:0.5454,
		y:0.91310000000000002,
		supporter:"C"
	},
	{
		x:0.51359999999999995,
		y:0.91,
		supporter:"C"
	},
	{
		x:0.59530000000000005,
		y:0.94750000000000001,
		supporter:"C"
	},
	{
		x:0.60270000000000001,
		y:0.91520000000000001,
		supporter:"C"
	},
	{
		x:0.57220000000000004,
		y:0.90629999999999999,
		supporter:"C"
	},
	{
		x:0.57179999999999997,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.62360000000000004,
		y:0.92789999999999995,
		supporter:"C"
	},
	{
		x:0.5081,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.60109999999999997,
		y:0.94910000000000005,
		supporter:"C"
	},
	{
		x:0.54190000000000005,
		y:0.90559999999999996,
		supporter:"C"
	},
	{
		x:0.55349999999999999,
		y:0.91859999999999997,
		supporter:"C"
	},
	{
		x:0.58930000000000005,
		y:0.92679999999999996,
		supporter:"C"
	},
	{
		x:0.59609999999999996,
		y:0.9486,
		supporter:"C"
	},
	{
		x:0.53890000000000005,
		y:0.9012,
		supporter:"C"
	},
	{
		x:0.57440000000000002,
		y:0.90239999999999998,
		supporter:"C"
	},
	{
		x:0.51490000000000002,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.58940000000000003,
		y:0.91700000000000004,
		supporter:"C"
	},
	{
		x:0.5474,
		y:0.91539999999999999,
		supporter:"C"
	},
	{
		x:0.51359999999999995,
		y:0.91510000000000002,
		supporter:"C"
	},
	{
		x:0.54669999999999996,
		y:0.9123,
		supporter:"C"
	},
	{
		x:0.62649999999999995,
		y:0.92220000000000002,
		supporter:"C"
	},
	{
		x:0.52139999999999997,
		y:0.92579999999999996,
		supporter:"C"
	},
	{
		x:0.51200000000000001,
		y:0.91249999999999998,
		supporter:"C"
	},
	{
		x:0.55059999999999998,
		y:0.9113,
		supporter:"C"
	},
	{
		x:0.52710000000000001,
		y:0.92800000000000005,
		supporter:"C"
	},
	{
		x:0.57020000000000004,
		y:0.91930000000000001,
		supporter:"C"
	},
	{
		x:0.6099,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.54310000000000003,
		y:0.91010000000000002,
		supporter:"C"
	},
	{
		x:0.51980000000000004,
		y:0.90759999999999996,
		supporter:"C"
	},
	{
		x:0.54759999999999998,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.54510000000000003,
		y:0.90129999999999999,
		supporter:"C"
	},
	{
		x:0.53,
		y:0.90810000000000002,
		supporter:"C"
	},
	{
		x:0.54559999999999997,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.54669999999999996,
		y:0.91100000000000003,
		supporter:"C"
	},
	{
		x:0.50619999999999998,
		y:0.93059999999999998,
		supporter:"C"
	},
	{
		x:0.5877,
		y:0.9254,
		supporter:"C"
	},
	{
		x:0.60409999999999997,
		y:0.92200000000000004,
		supporter:"C"
	},
	{
		x:0.57010000000000005,
		y:0.91190000000000004,
		supporter:"C"
	},
	{
		x:0.51729999999999998,
		y:0.90790000000000004,
		supporter:"C"
	},
	{
		x:0.53100000000000003,
		y:0.92959999999999998,
		supporter:"C"
	},
	{
		x:0.57869999999999999,
		y:0.91739999999999999,
		supporter:"C"
	},
	{
		x:0.59550000000000003,
		y:0.92049999999999998,
		supporter:"C"
	},
	{
		x:0.50060000000000004,
		y:0.92779999999999996,
		supporter:"C"
	},
	{
		x:0.501,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.51,
		y:0.90469999999999995,
		supporter:"C"
	},
	{
		x:0.54979999999999996,
		y:0.9143,
		supporter:"C"
	},
	{
		x:0.60189999999999999,
		y:0.94879999999999998,
		supporter:"C"
	},
	{
		x:0.5101,
		y:0.9274,
		supporter:"C"
	},
	{
		x:0.6048,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.56330000000000002,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.55910000000000004,
		y:0.91169999999999995,
		supporter:"C"
	},
	{
		x:0.61250000000000004,
		y:0.91759999999999997,
		supporter:"C"
	},
	{
		x:0.51659999999999995,
		y:0.91639999999999999,
		supporter:"C"
	},
	{
		x:0.59709999999999996,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.51900000000000002,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.51519999999999999,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.51019999999999999,
		y:0.90039999999999998,
		supporter:"C"
	},
	{
		x:0.61880000000000002,
		y:0.91959999999999997,
		supporter:"C"
	},
	{
		x:0.56189999999999996,
		y:0.90169999999999995,
		supporter:"C"
	},
	{
		x:0.50670000000000004,
		y:0.90290000000000004,
		supporter:"C"
	},
	{
		x:0.53890000000000005,
		y:0.91910000000000003,
		supporter:"C"
	},
	{
		x:0.55189999999999995,
		y:0.90959999999999996,
		supporter:"C"
	},
	{
		x:0.62090000000000001,
		y:0.91420000000000001,
		supporter:"C"
	},
	{
		x:0.59650000000000003,
		y:0.92110000000000003,
		supporter:"C"
	},
	{
		x:0.50480000000000003,
		y:0.92469999999999997,
		supporter:"C"
	},
	{
		x:0.52710000000000001,
		y:0.90349999999999997,
		supporter:"C"
	},
	{
		x:0.623,
		y:0.92320000000000002,
		supporter:"C"
	},
	{
		x:0.6159,
		y:0.91879999999999995,
		supporter:"C"
	},
	{
		x:0.54339999999999999,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.58760000000000001,
		y:0.92549999999999999,
		supporter:"C"
	},
	{
		x:0.50409999999999999,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.58779999999999999,
		y:0.92020000000000002,
		supporter:"C"
	},
	{
		x:0.52549999999999997,
		y:0.92749999999999999,
		supporter:"C"
	},
	{
		x:0.52659999999999996,
		y:0.91,
		supporter:"C"
	},
	{
		x:0.53510000000000002,
		y:0.93420000000000003,
		supporter:"C"
	},
	{
		x:0.50719999999999998,
		y:0.9224,
		supporter:"C"
	},
	{
		x:0.60680000000000001,
		y:0.91649999999999998,
		supporter:"C"
	},
	{
		x:0.50719999999999998,
		y:0.9173,
		supporter:"C"
	},
	{
		x:0.53349999999999997,
		y:0.91459999999999997,
		supporter:"C"
	},
	{
		x:0.51219999999999999,
		y:0.92479999999999996,
		supporter:"C"
	},
	{
		x:0.5232,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.60870000000000002,
		y:0.9224,
		supporter:"C"
	},
	{
		x:0.5212,
		y:0.90259999999999996,
		supporter:"C"
	},
	{
		x:0.52149999999999996,
		y:0.93269999999999997,
		supporter:"C"
	},
	{
		x:0.60829999999999995,
		y:0.92979999999999996,
		supporter:"C"
	},
	{
		x:0.61370000000000002,
		y:0.90700000000000003,
		supporter:"C"
	},
	{
		x:0.5907,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.52710000000000001,
		y:0.90469999999999995,
		supporter:"C"
	},
	{
		x:0.53600000000000003,
		y:0.92420000000000002,
		supporter:"C"
	},
	{
		x:0.54139999999999999,
		y:0.91490000000000005,
		supporter:"C"
	},
	{
		x:0.5998,
		y:0.91879999999999995,
		supporter:"C"
	},
	{
		x:0.50980000000000003,
		y:0.90820000000000001,
		supporter:"C"
	},
	{
		x:0.58199999999999996,
		y:0.92710000000000004,
		supporter:"C"
	},
	{
		x:0.50070000000000003,
		y:0.9294,
		supporter:"C"
	},
	{
		x:0.56720000000000004,
		y:0.91349999999999998,
		supporter:"C"
	},
	{
		x:0.50280000000000002,
		y:0.93589999999999995,
		supporter:"C"
	},
	{
		x:0.61209999999999998,
		y:0.94740000000000002,
		supporter:"C"
	},
	{
		x:0.61109999999999998,
		y:0.90759999999999996,
		supporter:"C"
	},
	{
		x:0.51,
		y:0.90290000000000004,
		supporter:"C"
	},
	{
		x:0.54249999999999998,
		y:0.90669999999999995,
		supporter:"C"
	},
	{
		x:0.61570000000000003,
		y:0.91959999999999997,
		supporter:"C"
	},
	{
		x:0.62729999999999997,
		y:0.9173,
		supporter:"C"
	},
	{
		x:0.53300000000000003,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.58889999999999998,
		y:0.91779999999999995,
		supporter:"C"
	},
	{
		x:0.5907,
		y:0.92100000000000004,
		supporter:"C"
	},
	{
		x:0.56910000000000005,
		y:0.91390000000000005,
		supporter:"C"
	},
	{
		x:0.61780000000000002,
		y:0.92749999999999999,
		supporter:"C"
	},
	{
		x:0.60060000000000002,
		y:0.91349999999999998,
		supporter:"C"
	},
	{
		x:0.52259999999999995,
		y:0.90159999999999996,
		supporter:"C"
	},
	{
		x:0.54249999999999998,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.56000000000000005,
		y:0.90249999999999997,
		supporter:"C"
	},
	{
		x:0.51639999999999997,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.62309999999999999,
		y:0.95240000000000002,
		supporter:"C"
	},
	{
		x:0.52810000000000001,
		y:0.90180000000000005,
		supporter:"C"
	},
	{
		x:0.61,
		y:0.90839999999999999,
		supporter:"C"
	},
	{
		x:0.57320000000000004,
		y:0.91539999999999999,
		supporter:"C"
	},
	{
		x:0.59499999999999997,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.55489999999999995,
		y:0.90700000000000003,
		supporter:"C"
	},
	{
		x:0.62129999999999996,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.54930000000000001,
		y:0.91539999999999999,
		supporter:"C"
	},
	{
		x:0.62829999999999997,
		y:0.91449999999999998,
		supporter:"C"
	},
	{
		x:0.50090000000000001,
		y:0.90780000000000005,
		supporter:"C"
	},
	{
		x:0.57530000000000003,
		y:0.90959999999999996,
		supporter:"C"
	},
	{
		x:0.61060000000000003,
		y:0.9153,
		supporter:"C"
	},
	{
		x:0.52390000000000003,
		y:0.90800000000000003,
		supporter:"C"
	},
	{
		x:0.54369999999999996,
		y:0.92459999999999998,
		supporter:"C"
	},
	{
		x:0.61939999999999995,
		y:0.91,
		supporter:"C"
	},
	{
		x:0.53210000000000002,
		y:0.92369999999999997,
		supporter:"C"
	},
	{
		x:0.50270000000000004,
		y:0.93289999999999995,
		supporter:"C"
	},
	{
		x:0.51619999999999999,
		y:0.90859999999999996,
		supporter:"C"
	},
	{
		x:0.62470000000000003,
		y:0.9093,
		supporter:"C"
	},
	{
		x:0.53659999999999997,
		y:0.92379999999999995,
		supporter:"C"
	},
	{
		x:0.57540000000000002,
		y:0.91769999999999996,
		supporter:"C"
	},
	{
		x:0.50749999999999995,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.56810000000000005,
		y:0.90290000000000004,
		supporter:"C"
	},
	{
		x:0.54190000000000005,
		y:0.90780000000000005,
		supporter:"C"
	},
	{
		x:0.58750000000000002,
		y:0.92120000000000002,
		supporter:"C"
	},
	{
		x:0.53559999999999997,
		y:0.9103,
		supporter:"C"
	},
	{
		x:0.62450000000000006,
		y:0.90849999999999997,
		supporter:"C"
	},
	{
		x:0.59279999999999999,
		y:0.92400000000000004,
		supporter:"C"
	},
	{
		x:0.57340000000000002,
		y:0.91439999999999999,
		supporter:"C"
	},
	{
		x:0.62080000000000002,
		y:0.92889999999999995,
		supporter:"C"
	},
	{
		x:0.60929999999999995,
		y:0.91320000000000001,
		supporter:"C"
	},
	{
		x:0.50470000000000004,
		y:0.9113,
		supporter:"C"
	},
	{
		x:0.62670000000000003,
		y:0.91739999999999999,
		supporter:"C"
	},
	{
		x:0.61450000000000005,
		y:0.92200000000000004,
		supporter:"C"
	},
	{
		x:0.54559999999999997,
		y:0.90369999999999995,
		supporter:"C"
	},
	{
		x:0.52959999999999996,
		y:0.90439999999999998,
		supporter:"C"
	},
	{
		x:0.5474,
		y:0.90620000000000001,
		supporter:"C"
	},
	{
		x:0.51170000000000004,
		y:0.91210000000000002,
		supporter:"C"
	},
	{
		x:0.58030000000000004,
		y:0.90739999999999998,
		supporter:"C"
	},
	{
		x:0.53490000000000004,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.61570000000000003,
		y:0.93230000000000002,
		supporter:"C"
	},
	{
		x:0.56789999999999996,
		y:0.91449999999999998,
		supporter:"C"
	},
	{
		x:0.53100000000000003,
		y:0.90190000000000003,
		supporter:"C"
	},
	{
		x:0.61580000000000001,
		y:0.93030000000000002,
		supporter:"C"
	},
	{
		x:0.60799999999999998,
		y:0.90610000000000002,
		supporter:"C"
	},
	{
		x:0.55330000000000001,
		y:0.91080000000000005,
		supporter:"C"
	},
	{
		x:0.56530000000000002,
		y:0.91459999999999997,
		supporter:"C"
	},
	{
		x:0.56479999999999997,
		y:0.9103,
		supporter:"C"
	},
	{
		x:0.53080000000000005,
		y:0.91110000000000002,
		supporter:"C"
	},
	{
		x:0.5998,
		y:0.91869999999999996,
		supporter:"C"
	},
	{
		x:0.58020000000000005,
		y:0.90180000000000005,
		supporter:"C"
	},
	{
		x:0.52349999999999997,
		y:0.90249999999999997,
		supporter:"C"
	},
	{
		x:0.60809999999999997,
		y:0.94730000000000003,
		supporter:"C"
	},
	{
		x:0.57150000000000001,
		y:0.90480000000000005,
		supporter:"C"
	},
	{
		x:0.50800000000000001,
		y:0.91039999999999999,
		supporter:"C"
	},
	{
		x:0.58140000000000003,
		y:0.92290000000000005,
		supporter:"C"
	},
	{
		x:0.52710000000000001,
		y:0.91220000000000001,
		supporter:"C"
	},
	{
		x:0.61199999999999999,
		y:0.90780000000000005,
		supporter:"C"
	},
	{
		x:0.54079999999999995,
		y:0.91279999999999994,
		supporter:"C"
	},
	{
		x:0.52690000000000003,
		y:0.92249999999999999,
		supporter:"C"
	},
	{
		x:0.50619999999999998,
		y:0.9113,
		supporter:"C"
	},
	{
		x:0.52710000000000001,
		y:0.90249999999999997,
		supporter:"C"
	},
	{
		x:0.59040000000000004,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.57089999999999996,
		y:0.91869999999999996,
		supporter:"C"
	},
	{
		x:0.60419999999999996,
		y:0.91879999999999995,
		supporter:"C"
	},
	{
		x:0.55059999999999998,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.60189999999999999,
		y:0.92100000000000004,
		supporter:"C"
	},
	{
		x:0.54649999999999999,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.50149999999999995,
		y:0.92200000000000004,
		supporter:"C"
	},
	{
		x:0.51429999999999998,
		y:0.90249999999999997,
		supporter:"C"
	},
	{
		x:0.53100000000000003,
		y:0.91090000000000004,
		supporter:"C"
	},
	{
		x:0.56859999999999999,
		y:0.9133,
		supporter:"C"
	},
	{
		x:0.54190000000000005,
		y:0.91679999999999995,
		supporter:"C"
	},
	{
		x:0.56969999999999998,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.52600000000000002,
		y:0.90239999999999998,
		supporter:"C"
	},
	{
		x:0.56410000000000005,
		y:0.91190000000000004,
		supporter:"C"
	},
	{
		x:0.53420000000000001,
		y:0.90359999999999996,
		supporter:"C"
	},
	{
		x:0.62109999999999999,
		y:0.90910000000000002,
		supporter:"C"
	},
	{
		x:0.51349999999999996,
		y:0.91310000000000002,
		supporter:"C"
	},
	{
		x:0.52949999999999997,
		y:0.90980000000000005,
		supporter:"C"
	},
	{
		x:0.53100000000000003,
		y:0.90849999999999997,
		supporter:"C"
	},
	{
		x:0.5181,
		y:0.91520000000000001,
		supporter:"C"
	},
	{
		x:0.51439999999999997,
		y:0.92900000000000005,
		supporter:"C"
	},
	{
		x:0.57520000000000004,
		y:0.90580000000000005,
		supporter:"C"
	},
	{
		x:0.58440000000000003,
		y:0.92649999999999999,
		supporter:"C"
	},
	{
		x:0.5746,
		y:0.90610000000000002,
		supporter:"C"
	},
	{
		x:0.62380000000000002,
		y:0.9194,
		supporter:"C"
	},
	{
		x:0.51200000000000001,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.629,
		y:0.90329999999999999,
		supporter:"C"
	},
	{
		x:0.628,
		y:0.92800000000000005,
		supporter:"C"
	},
	{
		x:0.5282,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.50449999999999995,
		y:0.93579999999999997,
		supporter:"C"
	},
	{
		x:0.52039999999999997,
		y:0.92520000000000002,
		supporter:"C"
	},
	{
		x:0.59179999999999999,
		y:0.91639999999999999,
		supporter:"C"
	},
	{
		x:0.52159999999999995,
		y:0.91110000000000002,
		supporter:"C"
	},
	{
		x:0.58599999999999997,
		y:0.93130000000000002,
		supporter:"C"
	},
	{
		x:0.57640000000000002,
		y:0.91900000000000004,
		supporter:"C"
	},
	{
		x:0.57130000000000003,
		y:0.94679999999999997,
		supporter:"C"
	},
	{
		x:0.61929999999999996,
		y:0.92330000000000001,
		supporter:"C"
	},
	{
		x:0.60829999999999995,
		y:0.92630000000000001,
		supporter:"C"
	},
	{
		x:0.56289999999999996,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.51359999999999995,
		y:0.90510000000000002,
		supporter:"C"
	},
	{
		x:0.53949999999999998,
		y:0.90910000000000002,
		supporter:"C"
	},
	{
		x:0.50590000000000002,
		y:0.90700000000000003,
		supporter:"C"
	},
	{
		x:0.59230000000000005,
		y:0.92549999999999999,
		supporter:"C"
	},
	{
		x:0.50970000000000004,
		y:0.91180000000000005,
		supporter:"C"
	},
	{
		x:0.57520000000000004,
		y:0.90720000000000001,
		supporter:"C"
	},
	{
		x:0.50660000000000005,
		y:0.93089999999999995,
		supporter:"C"
	},
	{
		x:0.56179999999999997,
		y:0.90090000000000003,
		supporter:"C"
	},
	{
		x:0.58489999999999998,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.57499999999999996,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.57579999999999998,
		y:0.91400000000000003,
		supporter:"C"
	},
	{
		x:0.53200000000000003,
		y:0.91090000000000004,
		supporter:"C"
	},
	{
		x:0.55489999999999995,
		y:0.90790000000000004,
		supporter:"C"
	},
	{
		x:0.60219999999999996,
		y:0.91459999999999997,
		supporter:"C"
	},
	{
		x:0.53259999999999996,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.55959999999999999,
		y:0.91020000000000001,
		supporter:"C"
	},
	{
		x:0.59850000000000003,
		y:0.91239999999999999,
		supporter:"C"
	},
	{
		x:0.60709999999999997,
		y:0.91790000000000005,
		supporter:"C"
	},
	{
		x:0.53700000000000003,
		y:0.91239999999999999,
		supporter:"C"
	},
	{
		x:0.51300000000000001,
		y:0.90439999999999998,
		supporter:"C"
	},
	{
		x:0.60189999999999999,
		y:0.92259999999999998,
		supporter:"C"
	},
	{
		x:0.51990000000000003,
		y:0.92100000000000004,
		supporter:"C"
	},
	{
		x:0.51329999999999998,
		y:0.93,
		supporter:"C"
	},
	{
		x:0.54200000000000004,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.50560000000000005,
		y:0.90159999999999996,
		supporter:"C"
	},
	{
		x:0.53420000000000001,
		y:0.91369999999999996,
		supporter:"C"
	},
	{
		x:0.51839999999999997,
		y:0.90769999999999995,
		supporter:"C"
	},
	{
		x:0.58620000000000005,
		y:0.92879999999999996,
		supporter:"C"
	},
	{
		x:0.50670000000000004,
		y:0.90359999999999996,
		supporter:"C"
	},
	{
		x:0.58179999999999998,
		y:0.91949999999999998,
		supporter:"C"
	},
	{
		x:0.52390000000000003,
		y:0.90239999999999998,
		supporter:"C"
	},
	{
		x:0.60370000000000001,
		y:0.90580000000000005,
		supporter:"C"
	},
	{
		x:0.57550000000000001,
		y:0.90549999999999997,
		supporter:"C"
	},
	{
		x:0.57210000000000005,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.50360000000000005,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.50360000000000005,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.59309999999999996,
		y:0.92669999999999997,
		supporter:"C"
	},
	{
		x:0.5,
		y:0.93200000000000005,
		supporter:"C"
	},
	{
		x:0.53669999999999995,
		y:0.92400000000000004,
		supporter:"C"
	},
	{
		x:0.54190000000000005,
		y:0.91849999999999998,
		supporter:"C"
	},
	{
		x:0.54779999999999995,
		y:0.90469999999999995,
		supporter:"C"
	},
	{
		x:0.58879999999999999,
		y:0.90139999999999998,
		supporter:"C"
	},
	{
		x:0.54459999999999997,
		y:0.92759999999999998,
		supporter:"C"
	},
	{
		x:0.55830000000000002,
		y:0.92779999999999996,
		supporter:"C"
	},
	{
		x:0.51249999999999996,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.53169999999999995,
		y:0.92530000000000001,
		supporter:"C"
	},
	{
		x:0.59150000000000003,
		y:0.92379999999999995,
		supporter:"C"
	},
	{
		x:0.54320000000000002,
		y:0.90949999999999998,
		supporter:"C"
	},
	{
		x:0.53510000000000002,
		y:0.91769999999999996,
		supporter:"C"
	},
	{
		x:0.60860000000000003,
		y:0.91180000000000005,
		supporter:"C"
	},
	{
		x:0.55689999999999995,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.57750000000000001,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.53300000000000003,
		y:0.90559999999999996,
		supporter:"C"
	},
	{
		x:0.5867,
		y:0.94469999999999998,
		supporter:"C"
	},
	{
		x:0.58650000000000002,
		y:0.93400000000000005,
		supporter:"C"
	},
	{
		x:0.60760000000000003,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.57069999999999999,
		y:0.90949999999999998,
		supporter:"C"
	},
	{
		x:0.5988,
		y:0.92620000000000002,
		supporter:"C"
	},
	{
		x:0.56720000000000004,
		y:0.91300000000000003,
		supporter:"C"
	},
	{
		x:0.50390000000000001,
		y:0.90529999999999999,
		supporter:"C"
	},
	{
		x:0.58499999999999996,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.6099,
		y:0.94720000000000004,
		supporter:"C"
	},
	{
		x:0.50539999999999996,
		y:0.90310000000000001,
		supporter:"C"
	},
	{
		x:0.50309999999999999,
		y:0.90110000000000001,
		supporter:"C"
	},
	{
		x:0.55259999999999998,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.60140000000000005,
		y:0.92200000000000004,
		supporter:"C"
	},
	{
		x:0.51129999999999998,
		y:0.91659999999999997,
		supporter:"C"
	},
	{
		x:0.56399999999999995,
		y:0.92169999999999996,
		supporter:"C"
	},
	{
		x:0.54379999999999995,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.5847,
		y:0.92359999999999998,
		supporter:"C"
	},
	{
		x:0.53659999999999997,
		y:0.90759999999999996,
		supporter:"C"
	},
	{
		x:0.5514,
		y:0.9012,
		supporter:"C"
	},
	{
		x:0.61850000000000005,
		y:0.91720000000000002,
		supporter:"C"
	},
	{
		x:0.51849999999999996,
		y:0.9093,
		supporter:"C"
	},
	{
		x:0.50849999999999995,
		y:0.90469999999999995,
		supporter:"C"
	},
	{
		x:0.60109999999999997,
		y:0.94269999999999998,
		supporter:"C"
	},
	{
		x:0.5544,
		y:0.91039999999999999,
		supporter:"C"
	},
	{
		x:0.60460000000000003,
		y:0.91900000000000004,
		supporter:"C"
	},
	{
		x:0.622,
		y:0.9234,
		supporter:"C"
	},
	{
		x:0.58779999999999999,
		y:0.94550000000000001,
		supporter:"C"
	},
	{
		x:0.61199999999999999,
		y:0.92920000000000003,
		supporter:"C"
	},
	{
		x:0.6129,
		y:0.91520000000000001,
		supporter:"C"
	},
	{
		x:0.58579999999999999,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.61119999999999997,
		y:0.90439999999999998,
		supporter:"C"
	},
	{
		x:0.6179,
		y:0.93369999999999997,
		supporter:"C"
	},
	{
		x:0.53059999999999996,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.52910000000000001,
		y:0.91449999999999998,
		supporter:"C"
	},
	{
		x:0.51559999999999995,
		y:0.93110000000000004,
		supporter:"C"
	},
	{
		x:0.55659999999999998,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.61060000000000003,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.53910000000000002,
		y:0.90039999999999998,
		supporter:"C"
	},
	{
		x:0.62549999999999994,
		y:0.92110000000000003,
		supporter:"C"
	},
	{
		x:0.57079999999999997,
		y:0.90259999999999996,
		supporter:"C"
	},
	{
		x:0.60150000000000003,
		y:0.92779999999999996,
		supporter:"C"
	},
	{
		x:0.51160000000000005,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.54430000000000001,
		y:0.90010000000000001,
		supporter:"C"
	},
	{
		x:0.55020000000000002,
		y:0.90029999999999999,
		supporter:"C"
	},
	{
		x:0.5968,
		y:0.91590000000000005,
		supporter:"C"
	},
	{
		x:0.52600000000000002,
		y:0.91,
		supporter:"C"
	},
	{
		x:0.53859999999999997,
		y:0.90249999999999997,
		supporter:"C"
	},
	{
		x:0.58440000000000003,
		y:0.92100000000000004,
		supporter:"C"
	},
	{
		x:0.52239999999999998,
		y:0.93989999999999996,
		supporter:"C"
	},
	{
		x:0.61550000000000005,
		y:0.91020000000000001,
		supporter:"C"
	},
	{
		x:0.54890000000000005,
		y:0.90249999999999997,
		supporter:"C"
	},
	{
		x:0.54079999999999995,
		y:0.92200000000000004,
		supporter:"C"
	},
	{
		x:0.55320000000000003,
		y:0.90780000000000005,
		supporter:"C"
	},
	{
		x:0.60760000000000003,
		y:0.92,
		supporter:"C"
	},
	{
		x:0.54690000000000005,
		y:0.90510000000000002,
		supporter:"C"
	},
	{
		x:0.61870000000000003,
		y:0.91420000000000001,
		supporter:"C"
	},
	{
		x:0.61040000000000005,
		y:0.92179999999999995,
		supporter:"C"
	},
	{
		x:0.55410000000000004,
		y:0.9274,
		supporter:"C"
	},
	{
		x:0.60189999999999999,
		y:0.91490000000000005,
		supporter:"C"
	},
	{
		x:0.51400000000000001,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.58350000000000002,
		y:0.91810000000000003,
		supporter:"C"
	},
	{
		x:0.61350000000000005,
		y:0.92079999999999995,
		supporter:"C"
	},
	{
		x:0.60419999999999996,
		y:0.90990000000000004,
		supporter:"C"
	},
	{
		x:0.50419999999999998,
		y:0.92300000000000004,
		supporter:"C"
	},
	{
		x:0.56620000000000004,
		y:0.9133,
		supporter:"C"
	},
	{
		x:0.59740000000000004,
		y:0.94299999999999995,
		supporter:"C"
	},
	{
		x:0.55469999999999997,
		y:0.91569999999999996,
		supporter:"C"
	},
	{
		x:0.50939999999999996,
		y:0.90610000000000002,
		supporter:"C"
	},
	{
		x:0.56830000000000003,
		y:0.91690000000000005,
		supporter:"C"
	},
	{
		x:0.50849999999999995,
		y:0.9022,
		supporter:"C"
	},
	{
		x:0.52470000000000006,
		y:0.9083,
		supporter:"C"
	},
	{
		x:0.53600000000000003,
		y:0.90629999999999999,
		supporter:"C"
	},
	{
		x:0.53380000000000005,
		y:0.9163,
		supporter:"C"
	},
	{
		x:0.5917,
		y:0.91379999999999995,
		supporter:"C"
	},
	{
		x:0.58130000000000004,
		y:0.90590000000000004,
		supporter:"C"
	},
	{
		x:0.52559999999999996,
		y:0.91820000000000002,
		supporter:"C"
	},
	{
		x:0.61199999999999999,
		y:0.91859999999999997,
		supporter:"C"
	},
	{
		x:0.58940000000000003,
		y:0.92310000000000003,
		supporter:"C"
	},
	{
		x:0.5857,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.56110000000000004,
		y:0.9153,
		supporter:"C"
	},
	{
		x:0.60489999999999999,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.61050000000000004,
		y:0.90010000000000001,
		supporter:"C"
	},
	{
		x:0.622,
		y:0.9254,
		supporter:"C"
	},
	{
		x:0.52649999999999997,
		y:0.90480000000000005,
		supporter:"C"
	},
	{
		x:0.60360000000000003,
		y:0.91369999999999996,
		supporter:"C"
	},
	{
		x:0.51659999999999995,
		y:0.91949999999999998,
		supporter:"C"
	},
	{
		x:0.50570000000000004,
		y:0.90939999999999999,
		supporter:"C"
	},
	{
		x:0.59419999999999995,
		y:0.91739999999999999,
		supporter:"C"
	},
	{
		x:0.62660000000000005,
		y:0.91290000000000004,
		supporter:"C"
	},
	{
		x:0.52410000000000001,
		y:0.92500000000000004,
		supporter:"C"
	},
	{
		x:0.52129999999999999,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.50139999999999996,
		y:0.92100000000000004,
		supporter:"C"
	},
	{
		x:0.57289999999999996,
		y:0.91600000000000004,
		supporter:"C"
	},
	{
		x:0.624,
		y:0.90849999999999997,
		supporter:"C"
	},
	{
		x:0.56310000000000004,
		y:0.9153,
		supporter:"C"
	},
	{
		x:0.5726,
		y:0.91639999999999999,
		supporter:"C"
	},
	{
		x:0.52170000000000005,
		y:0.91590000000000005,
		supporter:"C"
	},
	{
		x:0.52270000000000005,
		y:0.90839999999999999,
		supporter:"C"
	},
	{
		x:0.59770000000000001,
		y:0.92820000000000003,
		supporter:"C"
	},
	{
		x:0.54010000000000002,
		y:0.90900000000000003,
		supporter:"C"
	},
	{
		x:0.54220000000000002,
		y:0.90720000000000001,
		supporter:"C"
	},
	{
		x:0.59389999999999998,
		y:0.91549999999999998,
		supporter:"C"
	},
	{
		x:0.60950000000000004,
		y:0.92,
		supporter:"C"
	},
	{
		x:0.55000000000000004,
		y:0.90749999999999997,
		supporter:"C"
	},
	{
		x:0.61729999999999996,
		y:0.92630000000000001,
		supporter:"C"
	},
	{
		x:0.52170000000000005,
		y:0.92600000000000005,
		supporter:"C"
	},
	{
		x:0.56769999999999998,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.53010000000000002,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.57040000000000002,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.60140000000000005,
		y:0.94389999999999996,
		supporter:"C"
	},
	{
		x:0.5575,
		y:0.90010000000000001,
		supporter:"C"
	},
	{
		x:0.53810000000000002,
		y:0.9194,
		supporter:"C"
	},
	{
		x:0.50939999999999996,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.59770000000000001,
		y:0.92379999999999995,
		supporter:"C"
	},
	{
		x:0.62970000000000004,
		y:0.92179999999999995,
		supporter:"C"
	},
	{
		x:0.50009999999999999,
		y:0.91410000000000002,
		supporter:"C"
	},
	{
		x:0.53900000000000003,
		y:0.90600000000000003,
		supporter:"C"
	},
	{
		x:0.5071,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.50960000000000005,
		y:0.90749999999999997,
		supporter:"C"
	},
	{
		x:0.59030000000000005,
		y:0.91590000000000005,
		supporter:"C"
	},
	{
		x:0.54469999999999996,
		y:0.90669999999999995,
		supporter:"C"
	},
	{
		x:0.61919999999999997,
		y:0.91339999999999999,
		supporter:"C"
	},
	{
		x:0.53600000000000003,
		y:0.90720000000000001,
		supporter:"C"
	},
	{
		x:0.55840000000000001,
		y:0.9133,
		supporter:"C"
	},
	{
		x:0.50060000000000004,
		y:0.90559999999999996,
		supporter:"C"
	},
	{
		x:0.62660000000000005,
		y:0.90669999999999995,
		supporter:"C"
	},
	{
		x:0.51049999999999995,
		y:0.92230000000000001,
		supporter:"C"
	},
	{
		x:0.5958,
		y:0.92920000000000003,
		supporter:"C"
	},
	{
		x:0.58660000000000001,
		y:0.92469999999999997,
		supporter:"C"
	},
	{
		x:0.53520000000000001,
		y:0.90580000000000005,
		supporter:"C"
	},
	{
		x:0.51880000000000004,
		y:0.90059999999999996,
		supporter:"C"
	},
	{
		x:0.57730000000000004,
		y:0.92410000000000003,
		supporter:"C"
	},
	{
		x:0.58430000000000004,
		y:0.92420000000000002,
		supporter:"C"
	},
	{
		x:0.5161,
		y:0.92900000000000005,
		supporter:"C"
	},
	{
		x:0.51049999999999995,
		y:0.92320000000000002,
		supporter:"C"
	},
	{
		x:0.5242,
		y:0.91359999999999997,
		supporter:"C"
	},
	{
		x:0.6109,
		y:0.91920000000000002,
		supporter:"C"
	},
	{
		x:0.60470000000000002,
		y:0.92020000000000002,
		supporter:"C"
	},
	{
		x:0.52569999999999995,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.56599999999999995,
		y:0.93030000000000002,
		supporter:"C"
	},
	{
		x:0.52439999999999998,
		y:0.90369999999999995,
		supporter:"C"
	},
	{
		x:0.56159999999999999,
		y:0.90759999999999996,
		supporter:"C"
	},
	{
		x:0.54930000000000001,
		y:0.91439999999999999,
		supporter:"C"
	},
	{
		x:0.56389999999999996,
		y:0.9425,
		supporter:"C"
	},
	{
		x:0.60150000000000003,
		y:0.91739999999999999,
		supporter:"C"
	},
	{
		x:0.54220000000000002,
		y:0.90629999999999999,
		supporter:"C"
	},
	{
		x:0.52729999999999999,
		y:0.90590000000000004,
		supporter:"C"
	},
	{
		x:0.60870000000000002,
		y:0.91649999999999998,
		supporter:"C"
	},
	{
		x:0.57689999999999997,
		y:0.92769999999999997,
		supporter:"C"
	},
	{
		x:0.51600000000000001,
		y:0.90680000000000005,
		supporter:"C"
	},
	{
		x:0.53649999999999998,
		y:0.9274,
		supporter:"C"
	},
	{
		x:0.5605,
		y:0.93110000000000004,
		supporter:"C"
	},
	{
		x:0.50329999999999997,
		y:0.92179999999999995,
		supporter:"C"
	},
	{
		x:0.60219999999999996,
		y:0.91459999999999997,
		supporter:"C"
	},
	{
		x:0.503,
		y:0.90080000000000005,
		supporter:"C"
	},
	{
		x:0.62849999999999995,
		y:0.92069999999999996,
		supporter:"C"
	},
	{
		x:0.50109999999999999,
		y:0.91210000000000002,
		supporter:"C"
	},
	{
		x:0.59309999999999996,
		y:0.92369999999999997,
		supporter:"C"
	},
	{
		x:0.52880000000000005,
		y:0.90600000000000003,
		supporter:"C"
	},
	{
		x:0.54649999999999999,
		y:0.91849999999999998,
		supporter:"C"
	},
	{
		x:0.53129999999999999,
		y:0.91290000000000004,
		supporter:"C"
	},
	{
		x:0.51649999999999996,
		y:0.91069999999999995,
		supporter:"C"
	},
	{
		x:0.52880000000000005,
		y:0.90949999999999998,
		supporter:"C"
	},
	{
		x:0.60550000000000004,
		y:0.92449999999999999,
		supporter:"C"
	},
	{
		x:0.50149999999999995,
		y:0.93410000000000004,
		supporter:"C"
	},
	{
		x:0.58299999999999996,
		y:0.91300000000000003,
		supporter:"C"
	},
	{
		x:0.62870000000000004,
		y:0.90939999999999999,
		supporter:"C"
	},
	{
		x:0.52070000000000005,
		y:0.90339999999999998,
		supporter:"C"
	},
	{
		x:0.52829999999999999,
		y:0.91069999999999995,
		supporter:"C"
	},
	{
		x:0.56730000000000003,
		y:0.94230000000000003,
		supporter:"C"
	},
	{
		x:0.61250000000000004,
		y:0.91890000000000005,
		supporter:"C"
	},
	{
		x:0.51649999999999996,
		y:0.92500000000000004,
		supporter:"C"
	},
	{
		x:0.51300000000000001,
		y:0.92310000000000003,
		supporter:"C"
	},
	{
		x:0.57769999999999999,
		y:0.92100000000000004,
		supporter:"C"
	},
	{
		x:0.50019999999999998,
		y:0.92249999999999999,
		supporter:"C"
	},
	{
		x:0.5081,
		y:0.90139999999999998,
		supporter:"C"
	},
	{
		x:0.58299999999999996,
		y:0.91949999999999998,
		supporter:"C"
	},
	{
		x:0.53610000000000002,
		y:0.90920000000000001,
		supporter:"C"
	},
	{
		x:0.62280000000000002,
		y:0.92849999999999999,
		supporter:"C"
	},
	{
		x:0.51119999999999999,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.54200000000000004,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.57509999999999994,
		y:0.90629999999999999,
		supporter:"C"
	},
	{
		x:0.50690000000000002,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.61560000000000004,
		y:0.91949999999999998,
		supporter:"C"
	},
	{
		x:0.60609999999999997,
		y:0.91949999999999998,
		supporter:"C"
	},
	{
		x:0.5242,
		y:0.92420000000000002,
		supporter:"C"
	},
	{
		x:0.50739999999999996,
		y:0.90459999999999996,
		supporter:"C"
	},
	{
		x:0.51039999999999996,
		y:0.90439999999999998,
		supporter:"C"
	},
	{
		x:0.60629999999999995,
		y:0.91559999999999997,
		supporter:"C"
	},
	{
		x:0.52859999999999996,
		y:0.90159999999999996,
		supporter:"C"
	},
	{
		x:0.55210000000000004,
		y:0.91410000000000002,
		supporter:"C"
	},
	{
		x:0.5706,
		y:0.93069999999999997,
		supporter:"C"
	},
	{
		x:0.59060000000000001,
		y:0.92130000000000001,
		supporter:"C"
	},
	{
		x:0.62619999999999998,
		y:0.93240000000000001,
		supporter:"C"
	},
	{
		x:0.5081,
		y:0.90869999999999995,
		supporter:"C"
	},
	{
		x:0.50160000000000005,
		y:0.90410000000000001,
		supporter:"C"
	},
	{
		x:0.61160000000000003,
		y:0.92479999999999996,
		supporter:"C"
	},
	{
		x:0.62790000000000001,
		y:0.91579999999999995,
		supporter:"C"
	},
	{
		x:0.62260000000000004,
		y:0.90249999999999997,
		supporter:"C"
	},
	{
		x:0.50990000000000002,
		y:0.90269999999999995,
		supporter:"C"
	},
	{
		x:0.62209999999999999,
		y:0.9234,
		supporter:"C"
	},
	{
		x:0.51259999999999994,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.57809999999999995,
		y:0.91639999999999999,
		supporter:"C"
	},
	{
		x:0.61509999999999998,
		y:0.9274,
		supporter:"C"
	},
	{
		x:0.504,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.51749999999999996,
		y:0.92459999999999998,
		supporter:"C"
	},
	{
		x:0.58399999999999996,
		y:0.93259999999999998,
		supporter:"C"
	},
	{
		x:0.60519999999999996,
		y:0.91900000000000004,
		supporter:"C"
	},
	{
		x:0.55800000000000005,
		y:0.90590000000000004,
		supporter:"C"
	},
	{
		x:0.61580000000000001,
		y:0.91930000000000001,
		supporter:"C"
	},
	{
		x:0.51959999999999995,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.59219999999999995,
		y:0.93059999999999998,
		supporter:"C"
	},
	{
		x:0.51229999999999998,
		y:0.90910000000000002,
		supporter:"C"
	},
	{
		x:0.56210000000000004,
		y:0.93079999999999996,
		supporter:"C"
	},
	{
		x:0.61570000000000003,
		y:0.91830000000000001,
		supporter:"C"
	},
	{
		x:0.53349999999999997,
		y:0.92249999999999999,
		supporter:"C"
	},
	{
		x:0.56100000000000005,
		y:0.92410000000000003,
		supporter:"C"
	},
	{
		x:0.57230000000000003,
		y:0.91369999999999996,
		supporter:"C"
	},
	{
		x:0.59230000000000005,
		y:0.91810000000000003,
		supporter:"C"
	},
	{
		x:0.60699999999999998,
		y:0.90810000000000002,
		supporter:"C"
	},
	{
		x:0.55910000000000004,
		y:0.91069999999999995,
		supporter:"C"
	},
	{
		x:0.54120000000000001,
		y:0.90480000000000005,
		supporter:"C"
	},
	{
		x:0.54320000000000002,
		y:0.90500000000000003,
		supporter:"C"
	},
	{
		x:0.52,
		y:0.91279999999999994,
		supporter:"C"
	},
	{
		x:0.56930000000000003,
		y:0.94740000000000002,
		supporter:"C"
	},
	{
		x:0.62009999999999998,
		y:0.9204,
		supporter:"C"
	},
	{
		x:0.57330000000000003,
		y:0.90990000000000004,
		supporter:"C"
	},
	{
		x:0.55410000000000004,
		y:0.90800000000000003,
		supporter:"C"
	},
	{
		x:0.52510000000000001,
		y:0.90859999999999996,
		supporter:"C"
	},
	{
		x:0.54749999999999999,
		y:0.92579999999999996,
		supporter:"C"
	},
	{
		x:0.58799999999999997,
		y:0.91720000000000002,
		supporter:"C"
	},
	{
		x:0.60140000000000005,
		y:0.90790000000000004,
		supporter:"C"
	},
	{
		x:0.50560000000000005,
		y:0.90669999999999995,
		supporter:"C"
	},
	{
		x:0.55469999999999997,
		y:0.91239999999999999,
		supporter:"C"
	},
	{
		x:0.60309999999999997,
		y:0.92969999999999997,
		supporter:"C"
	},
	{
		x:0.58040000000000003,
		y:0.91800000000000004,
		supporter:"C"
	},
	{
		x:0.60680000000000001,
		y:0.92230000000000001,
		supporter:"C"
	},
	{
		x:0.5554,
		y:0.90529999999999999,
		supporter:"C"
	},
	{
		x:0.54859999999999998,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.51400000000000001,
		y:0.92279999999999995,
		supporter:"C"
	},
	{
		x:0.57850000000000001,
		y:0.91790000000000005,
		supporter:"C"
	},
	{
		x:0.52780000000000005,
		y:0.90249999999999997,
		supporter:"C"
	},
	{
		x:0.56820000000000004,
		y:0.91080000000000005,
		supporter:"C"
	},
	{
		x:0.50580000000000003,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.60470000000000002,
		y:0.9496,
		supporter:"C"
	},
	{
		x:0.58609999999999995,
		y:0.93120000000000003,
		supporter:"C"
	},
	{
		x:0.53049999999999997,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.59160000000000001,
		y:0.92490000000000006,
		supporter:"C"
	},
	{
		x:0.55910000000000004,
		y:0.9083,
		supporter:"C"
	},
	{
		x:0.59340000000000004,
		y:0.92479999999999996,
		supporter:"C"
	},
	{
		x:0.5302,
		y:0.90620000000000001,
		supporter:"C"
	},
	{
		x:0.53590000000000004,
		y:0.93140000000000001,
		supporter:"C"
	},
	{
		x:0.51790000000000003,
		y:0.90439999999999998,
		supporter:"C"
	},
	{
		x:0.60499999999999998,
		y:0.9304,
		supporter:"C"
	},
	{
		x:0.57799999999999996,
		y:0.92759999999999998,
		supporter:"C"
	},
	{
		x:0.5847,
		y:0.90459999999999996,
		supporter:"C"
	},
	{
		x:0.61360000000000003,
		y:0.93169999999999997,
		supporter:"C"
	},
	{
		x:0.61040000000000005,
		y:0.92720000000000002,
		supporter:"C"
	},
	{
		x:0.61719999999999997,
		y:0.94779999999999998,
		supporter:"C"
	},
	{
		x:0.59260000000000002,
		y:0.91700000000000004,
		supporter:"C"
	},
	{
		x:0.54530000000000001,
		y:0.91339999999999999,
		supporter:"C"
	},
	{
		x:0.6018,
		y:0.94269999999999998,
		supporter:"C"
	},
	{
		x:0.53590000000000004,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.58230000000000004,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.57450000000000001,
		y:0.91759999999999997,
		supporter:"C"
	},
	{
		x:0.50339999999999996,
		y:0.90359999999999996,
		supporter:"C"
	},
	{
		x:0.51729999999999998,
		y:0.90839999999999999,
		supporter:"C"
	},
	{
		x:0.56189999999999996,
		y:0.91930000000000001,
		supporter:"C"
	},
	{
		x:0.51139999999999997,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.51639999999999997,
		y:0.9083,
		supporter:"C"
	},
	{
		x:0.62,
		y:0.91200000000000003,
		supporter:"C"
	},
	{
		x:0.59719999999999995,
		y:0.91039999999999999,
		supporter:"C"
	},
	{
		x:0.56999999999999995,
		y:0.94299999999999995,
		supporter:"C"
	},
	{
		x:0.59079999999999999,
		y:0.92669999999999997,
		supporter:"C"
	},
	{
		x:0.52880000000000005,
		y:0.91710000000000003,
		supporter:"C"
	},
	{
		x:0.54159999999999997,
		y:0.92900000000000005,
		supporter:"C"
	},
	{
		x:0.54620000000000002,
		y:0.90680000000000005,
		supporter:"C"
	},
	{
		x:0.62180000000000002,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.5302,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.60509999999999997,
		y:0.95120000000000005,
		supporter:"C"
	},
	{
		x:0.58609999999999995,
		y:0.91390000000000005,
		supporter:"C"
	},
	{
		x:0.5766,
		y:0.9153,
		supporter:"C"
	},
	{
		x:0.55840000000000001,
		y:0.91490000000000005,
		supporter:"C"
	},
	{
		x:0.62060000000000004,
		y:0.91239999999999999,
		supporter:"C"
	},
	{
		x:0.51019999999999999,
		y:0.9022,
		supporter:"C"
	},
	{
		x:0.624,
		y:0.9304,
		supporter:"C"
	},
	{
		x:0.59619999999999995,
		y:0.92400000000000004,
		supporter:"C"
	},
	{
		x:0.61299999999999999,
		y:0.91910000000000003,
		supporter:"C"
	},
	{
		x:0.52729999999999999,
		y:0.90049999999999997,
		supporter:"C"
	},
	{
		x:0.50790000000000002,
		y:0.90720000000000001,
		supporter:"C"
	},
	{
		x:0.58489999999999998,
		y:0.91100000000000003,
		supporter:"C"
	},
	{
		x:0.55779999999999996,
		y:0.90480000000000005,
		supporter:"C"
	},
	{
		x:0.5746,
		y:0.90480000000000005,
		supporter:"C"
	},
	{
		x:0.57569999999999999,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.58630000000000004,
		y:0.93679999999999997,
		supporter:"C"
	},
	{
		x:0.50649999999999995,
		y:0.91010000000000002,
		supporter:"C"
	},
	{
		x:0.60270000000000001,
		y:0.92420000000000002,
		supporter:"C"
	},
	{
		x:0.56789999999999996,
		y:0.94210000000000005,
		supporter:"C"
	},
	{
		x:0.50380000000000003,
		y:0.93059999999999998,
		supporter:"C"
	},
	{
		x:0.51600000000000001,
		y:0.91,
		supporter:"C"
	},
	{
		x:0.61240000000000006,
		y:0.91080000000000005,
		supporter:"C"
	},
	{
		x:0.52749999999999997,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.59079999999999999,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.50219999999999998,
		y:0.91479999999999995,
		supporter:"C"
	},
	{
		x:0.56159999999999999,
		y:0.9,
		supporter:"C"
	},
	{
		x:0.61839999999999995,
		y:0.91910000000000003,
		supporter:"C"
	},
	{
		x:0.62239999999999995,
		y:0.91300000000000003,
		supporter:"C"
	},
	{
		x:0.59209999999999996,
		y:0.92710000000000004,
		supporter:"C"
	},
	{
		x:0.59940000000000004,
		y:0.91810000000000003,
		supporter:"C"
	},
	{
		x:0.6048,
		y:0.91820000000000002,
		supporter:"C"
	},
	{
		x:0.61850000000000005,
		y:0.91910000000000003,
		supporter:"C"
	},
	{
		x:0.59940000000000004,
		y:0.92300000000000004,
		supporter:"C"
	},
	{
		x:0.51070000000000004,
		y:0.92510000000000003,
		supporter:"C"
	},
	{
		x:0.56389999999999996,
		y:0.94299999999999995,
		supporter:"C"
	},
	{
		x:0.50480000000000003,
		y:0.92879999999999996,
		supporter:"C"
	},
	{
		x:0.52290000000000003,
		y:0.90710000000000002,
		supporter:"C"
	},
	{
		x:0.53259999999999996,
		y:0.90429999999999999,
		supporter:"C"
	},
	{
		x:0.59370000000000001,
		y:0.90910000000000002,
		supporter:"C"
	},
	{
		x:0.52159999999999995,
		y:0.92889999999999995,
		supporter:"C"
	},
	{
		x:0.55279999999999996,
		y:0.90510000000000002,
		supporter:"C"
	},
	{
		x:0.55189999999999995,
		y:0.94210000000000005,
		supporter:"C"
	},
	{
		x:0.52739999999999998,
		y:0.90780000000000005,
		supporter:"C"
	},
	{
		x:0.54110000000000003,
		y:0.90590000000000004,
		supporter:"C"
	},
	{
		x:0.51549999999999996,
		y:0.91400000000000003,
		supporter:"C"
	},
	{
		x:0.62109999999999999,
		y:0.90890000000000004,
		supporter:"C"
	},
	{
		x:0.56130000000000002,
		y:0.90410000000000001,
		supporter:"C"
	},
	{
		x:0.62870000000000004,
		y:0.90259999999999996,
		supporter:"C"
	},
	{
		x:0.52680000000000005,
		y:0.90859999999999996,
		supporter:"C"
	},
	{
		x:0.54730000000000001,
		y:0.91410000000000002,
		supporter:"C"
	},
	{
		x:0.59760000000000002,
		y:0.94330000000000003,
		supporter:"C"
	},
	{
		x:0.57850000000000001,
		y:0.92169999999999996,
		supporter:"C"
	},
	{
		x:0.57630000000000003,
		y:0.92500000000000004,
		supporter:"C"
	},
	{
		x:0.54700000000000004,
		y:0.92559999999999998,
		supporter:"C"
	},
	{
		x:0.5675,
		y:0.93089999999999995,
		supporter:"C"
	},
	{
		x:0.50870000000000004,
		y:0.90029999999999999,
		supporter:"C"
	},
	{
		x:0.51929999999999998,
		y:0.92879999999999996,
		supporter:"C"
	},
	{
		x:0.60399999999999998,
		y:0.92989999999999995,
		supporter:"C"
	},
	{
		x:0.61360000000000003,
		y:0.92400000000000004,
		supporter:"C"
	},
	{
		x:0.51259999999999994,
		y:0.90239999999999998,
		supporter:"C"
	},
	{
		x:0.51129999999999998,
		y:0.93459999999999999,
		supporter:"C"
	},
	{
		x:0.53910000000000002,
		y:0.90429999999999999,
		supporter:"C"
	},
	{
		x:0.57369999999999999,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.60050000000000003,
		y:0.92500000000000004,
		supporter:"C"
	},
	{
		x:0.60399999999999998,
		y:0.92449999999999999,
		supporter:"C"
	},
	{
		x:0.57979999999999998,
		y:0.94530000000000003,
		supporter:"C"
	},
	{
		x:0.53180000000000005,
		y:0.90880000000000005,
		supporter:"C"
	},
	{
		x:0.6048,
		y:0.92120000000000002,
		supporter:"C"
	},
	{
		x:0.50949999999999995,
		y:0.90310000000000001,
		supporter:"C"
	},
	{
		x:0.58740000000000003,
		y:0.94359999999999999,
		supporter:"C"
	},
	{
		x:0.54169999999999996,
		y:0.90759999999999996,
		supporter:"C"
	},
	{
		x:0.5141,
		y:0.9083,
		supporter:"C"
	},
	{
		x:0.62519999999999998,
		y:0.91639999999999999,
		supporter:"C"
	},
	{
		x:0.5171,
		y:0.92630000000000001,
		supporter:"C"
	},
	{
		x:0.50360000000000005,
		y:0.90449999999999997,
		supporter:"C"
	},
	{
		x:0.60819999999999996,
		y:0.92530000000000001,
		supporter:"C"
	},
	{
		x:0.62339999999999995,
		y:0.91169999999999995,
		supporter:"C"
	},
	{
		x:0.50619999999999998,
		y:0.91049999999999998,
		supporter:"C"
	},
	{
		x:0.61229999999999996,
		y:0.92420000000000002,
		supporter:"C"
	},
	{
		x:0.50670000000000004,
		y:0.92830000000000001,
		supporter:"C"
	},
	{
		x:0.51429999999999998,
		y:0.92169999999999996,
		supporter:"C"
	},
	{
		x:0.54159999999999997,
		y:0.90759999999999996,
		supporter:"C"
	},
	{
		x:0.61370000000000002,
		y:0.91690000000000005,
		supporter:"C"
	},
	{
		x:0.56610000000000005,
		y:0.92010000000000003,
		supporter:"C"
	},
	{
		x:0.5897,
		y:0.91449999999999998,
		supporter:"C"
	},
	{
		x:0.50660000000000005,
		y:0.93179999999999996,
		supporter:"C"
	},
	{
		x:0.54259999999999997,
		y:0.92930000000000001,
		supporter:"C"
	},
	{
		x:0.51570000000000005,
		y:0.90210000000000001,
		supporter:"C"
	},
	{
		x:0.50929999999999997,
		y:0.90720000000000001,
		supporter:"C"
	},
	{
		x:0.61380000000000001,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.55710000000000004,
		y:0.90900000000000003,
		supporter:"C"
	},
	{
		x:0.52969999999999995,
		y:0.92900000000000005,
		supporter:"C"
	},
	{
		x:0.57730000000000004,
		y:0.91520000000000001,
		supporter:"C"
	},
	{
		x:0.53310000000000002,
		y:0.90449999999999997,
		supporter:"C"
	},
	{
		x:0.5464,
		y:0.92730000000000001,
		supporter:"C"
	},
	{
		x:0.53029999999999999,
		y:0.90200000000000002,
		supporter:"C"
	},
	{
		x:0.53920000000000001,
		y:0.91320000000000001,
		supporter:"C"
	},
	{
		x:0.5323,
		y:0.9123,
		supporter:"C"
	},
	{
		x:0.57289999999999996,
		y:0.91239999999999999,
		supporter:"C"
	},
	{
		x:0.52459999999999996,
		y:0.90400000000000003,
		supporter:"C"
	},
	{
		x:0.50780000000000003,
		y:0.92300000000000004,
		supporter:"C"
	},
	{
		x:0.50190000000000001,
		y:0.91490000000000005,
		supporter:"C"
	},
	{
		x:0.59809999999999997,
		y:0.91649999999999998,
		supporter:"C"
	},
	{
		x:0.53249999999999997,
		y:0.92600000000000005,
		supporter:"C"
	},
	{
		x:0.55589999999999995,
		y:0.90680000000000005,
		supporter:"C"
	},
	{
		x:0.51829999999999998,
		y:0.91010000000000002,
		supporter:"C"
	},
	{
		x:0.51149999999999995,
		y:0.91059999999999997,
		supporter:"C"
	},
	{
		x:0.60589999999999999,
		y:0.90359999999999996,
		supporter:"C"
	},
	{
		x:0.50829999999999997,
		y:0.9254,
		supporter:"C"
	},
	{
		x:0.62480000000000002,
		y:0.92520000000000002,
		supporter:"C"
	},
	{
		x:0.52100000000000002,
		y:0.90939999999999999,
		supporter:"C"
	},
	{
		x:0.50449999999999995,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.58740000000000003,
		y:0.91710000000000003,
		supporter:"C"
	},
	{
		x:0.53249999999999997,
		y:0.90439999999999998,
		supporter:"C"
	},
	{
		x:0.55559999999999998,
		y:0.9294,
		supporter:"C"
	},
	{
		x:0.58279999999999998,
		y:0.92400000000000004,
		supporter:"C"
	},
	{
		x:0.55979999999999996,
		y:0.90100000000000002,
		supporter:"C"
	},
	{
		x:0.57540000000000002,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.57579999999999998,
		y:0.91049999999999998,
		supporter:"C"
	},
	{
		x:0.59650000000000003,
		y:0.92390000000000005,
		supporter:"C"
	},
	{
		x:0.60670000000000002,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.50700000000000001,
		y:0.91290000000000004,
		supporter:"C"
	},
	{
		x:0.54920000000000002,
		y:0.9244,
		supporter:"C"
	},
	{
		x:0.57699999999999996,
		y:0.92410000000000003,
		supporter:"C"
	},
	{
		x:0.59219999999999995,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.57379999999999998,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.5494,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.5171,
		y:0.90380000000000005,
		supporter:"C"
	},
	{
		x:0.58040000000000003,
		y:0.91620000000000001,
		supporter:"C"
	},
	{
		x:0.61719999999999997,
		y:0.91500000000000004,
		supporter:"C"
	},
	{
		x:0.6038,
		y:0.91400000000000003,
		supporter:"C"
	},
	{
		x:0.57979999999999998,
		y:0.92200000000000004,
		supporter:"C"
	},
	{
		x:0.51790000000000003,
		y:0.90600000000000003,
		supporter:"C"
	},
	{
		x:0.61709999999999998,
		y:0.92369999999999997,
		supporter:"C"
	},
	{
		x:0.621,
		y:0.92269999999999996,
		supporter:"C"
	},
	{
		x:0.54520000000000002,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.59470000000000001,
		y:0.94840000000000002,
		supporter:"C"
	},
	{
		x:0.53790000000000004,
		y:0.92779999999999996,
		supporter:"C"
	},
	{
		x:0.61199999999999999,
		y:0.92949999999999999,
		supporter:"C"
	},
	{
		x:0.52300000000000002,
		y:0.90720000000000001,
		supporter:"C"
	},
	{
		x:0.59640000000000004,
		y:0.91990000000000005,
		supporter:"C"
	},
	{
		x:0.53949999999999998,
		y:0.90880000000000005,
		supporter:"C"
	},
	{
		x:0.56979999999999997,
		y:0.9042,
		supporter:"C"
	},
	{
		x:0.59860000000000002,
		y:0.91879999999999995,
		supporter:"C"
	},
	{
		x:0.59199999999999997,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.5181,
		y:0.90949999999999998,
		supporter:"C"
	},
	{
		x:0.62419999999999998,
		y:0.9224,
		supporter:"C"
	},
	{
		x:0.57450000000000001,
		y:0.92330000000000001,
		supporter:"C"
	},
	{
		x:0.55969999999999998,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.6,
		y:0.90080000000000005,
		supporter:"C"
	},
	{
		x:0.5716,
		y:0.9173,
		supporter:"C"
	},
	{
		x:0.51570000000000005,
		y:0.90980000000000005,
		supporter:"C"
	},
	{
		x:0.57520000000000004,
		y:0.92749999999999999,
		supporter:"C"
	},
	{
		x:0.62419999999999998,
		y:0.95189999999999997,
		supporter:"C"
	},
	{
		x:0.5242,
		y:0.90659999999999996,
		supporter:"C"
	},
	{
		x:0.56410000000000005,
		y:0.91569999999999996,
		supporter:"C"
	},
	{
		x:0.55649999999999999,
		y:0.91620000000000001,
		supporter:"C"
	},
	{
		x:0.58279999999999998,
		y:0.90739999999999998,
		supporter:"C"
	},
	{
		x:0.60140000000000005,
		y:0.92679999999999996,
		supporter:"C"
	},
	{
		x:0.52010000000000001,
		y:0.92679999999999996,
		supporter:"C"
	},
	{
		x:0.5615,
		y:0.93630000000000002,
		supporter:"C"
	},
	{
		x:0.5958,
		y:0.9224,
		supporter:"C"
	},
	{
		x:0.50080000000000002,
		y:0.93530000000000002,
		supporter:"C"
	},
	{
		x:0.59040000000000004,
		y:0.92179999999999995,
		supporter:"C"
	},
	{
		x:0.57540000000000002,
		y:0.91020000000000001,
		supporter:"C"
	},
	{
		x:0.54279999999999995,
		y:0.90600000000000003,
		supporter:"C"
	},
	{
		x:0.60540000000000005,
		y:0.94510000000000005,
		supporter:"C"
	},
	{
		x:0.53139999999999998,
		y:0.9022,
		supporter:"C"
	},
	{
		x:0.56689999999999996,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.53739999999999999,
		y:0.90429999999999999,
		supporter:"C"
	},
	{
		x:0.59840000000000004,
		y:0.9234,
		supporter:"C"
	},
	{
		x:0.62590000000000001,
		y:0.90939999999999999,
		supporter:"C"
	},
	{
		x:0.62270000000000003,
		y:0.90690000000000004,
		supporter:"C"
	},
	{
		x:0.51819999999999999,
		y:0.92520000000000002,
		supporter:"C"
	},
	{
		x:0.58030000000000004,
		y:0.92149999999999999,
		supporter:"C"
	},
	{
		x:0.5353,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.6089,
		y:0.90139999999999998,
		supporter:"C"
	},
	{
		x:0.59089999999999998,
		y:0.94410000000000005,
		supporter:"C"
	},
	{
		x:0.50829999999999997,
		y:0.9052,
		supporter:"C"
	},
	{
		x:0.5423,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.51829999999999998,
		y:0.90149999999999997,
		supporter:"C"
	},
	{
		x:0.54920000000000002,
		y:0.91379999999999995,
		supporter:"C"
	},
	{
		x:0.50349999999999995,
		y:0.91139999999999999,
		supporter:"C"
	},
	{
		x:0.59899999999999998,
		y:0.95330000000000004,
		supporter:"C"
	},
	{
		x:0.56020000000000003,
		y:0.91110000000000002,
		supporter:"C"
	},
	{
		x:0.58299999999999996,
		y:0.92479999999999996,
		supporter:"C"
	},
	{
		x:0.61319999999999997,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.61450000000000005,
		y:0.91200000000000003,
		supporter:"C"
	},
	{
		x:0.50760000000000005,
		y:0.90069999999999995,
		supporter:"C"
	},
	{
		x:0.61399999999999999,
		y:0.9214,
		supporter:"C"
	},
	{
		x:0.59540000000000004,
		y:0.92979999999999996,
		supporter:"C"
	},
	{
		x:0.58540000000000003,
		y:0.92510000000000003,
		supporter:"C"
	},
	{
		x:0.54869999999999997,
		y:0.93069999999999997,
		supporter:"C"
	},
	{
		x:0.59489999999999998,
		y:0.92179999999999995,
		supporter:"C"
	},
	{
		x:0.52200000000000002,
		y:0.92830000000000001,
		supporter:"C"
	},
	{
		x:0.51049999999999995,
		y:0.90249999999999997,
		supporter:"C"
	},
	{
		x:0.51800000000000002,
		y:0.92449999999999999,
		supporter:"C"
	},
	{
		x:0.50900000000000001,
		y:0.92269999999999996,
		supporter:"C"
	},
	{
		x:0.5806,
		y:0.94410000000000005,
		supporter:"C"
	},
	{
		x:0.58740000000000003,
		y:0.94340000000000002,
		supporter:"C"
	},
	{
		x:0.52910000000000001,
		y:0.90490000000000004,
		supporter:"C"
	},
	{
		x:0.56899999999999995,
		y:0.90610000000000002,
		supporter:"C"
	},
	{
		x:0.58030000000000004,
		y:0.9173,
		supporter:"C"
	},
	{
		x:0.59550000000000003,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.50339999999999996,
		y:0.9204,
		supporter:"C"
	},
	{
		x:0.54900000000000004,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.55959999999999999,
		y:0.90300000000000002,
		supporter:"C"
	},
	{
		x:0.51859999999999995,
		y:0.93210000000000004,
		supporter:"C"
	},
	{
		x:0.58979999999999999,
		y:0.92059999999999997,
		supporter:"C"
	},
	{
		x:0.52049999999999996,
		y:0.92659999999999998,
		supporter:"C"
	},
	{
		x:0.6048,
		y:0.91469999999999996,
		supporter:"C"
	},
	{
		x:0.50619999999999998,
		y:0.93010000000000004,
		supporter:"C"
	},
	{
		x:0.5464,
		y:0.90769999999999995,
		supporter:"C"
	},
	{
		x:0.55379999999999996,
		y:0.91700000000000004,
		supporter:"C"
	},
	{
		x:0.54200000000000004,
		y:0.92390000000000005,
		supporter:"C"
	},
	{
		x:0.62109999999999999,
		y:0.91700000000000004,
		supporter:"C"
	},
	{
		x:0.59030000000000005,
		y:0.92269999999999996,
		supporter:"C"
	},
	{
		x:0.61970000000000003,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.5796,
		y:0.91949999999999998,
		supporter:"C"
	},
	{
		x:0.56710000000000005,
		y:0.90110000000000001,
		supporter:"C"
	},
	{
		x:0.59770000000000001,
		y:0.91779999999999995,
		supporter:"C"
	},
	{
		x:0.61050000000000004,
		y:0.92949999999999999,
		supporter:"C"
	},
	{
		x:0.50309999999999999,
		y:0.9032,
		supporter:"C"
	},
	{
		x:0.57999999999999996,
		y:0.94499999999999995,
		supporter:"C"
	},
	{
		x:0.55349999999999999,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.58160000000000001,
		y:0.94140000000000001,
		supporter:"C"
	},
	{
		x:0.61680000000000001,
		y:0.91820000000000002,
		supporter:"C"
	},
	{
		x:0.53810000000000002,
		y:0.91049999999999998,
		supporter:"C"
	},
	{
		x:0.56740000000000002,
		y:0.9073,
		supporter:"C"
	},
	{
		x:0.58340000000000003,
		y:0.92800000000000005,
		supporter:"C"
	},
	{
		x:0.53769999999999996,
		y:0.90339999999999998,
		supporter:"C"
	},
	{
		x:0.57899999999999996,
		y:0.9446,
		supporter:"C"
	},
	{
		x:0.56859999999999999,
		y:0.90849999999999997,
		supporter:"C"
	},
	{
		x:0.57769999999999999,
		y:0.92210000000000003,
		supporter:"C"
	},
	{
		x:0.56469999999999998,
		y:0.92,
		supporter:"C"
	},
	{
		x:0.53849999999999998,
		y:0.92459999999999998,
		supporter:"C"
	},
	{
		x:0.55279999999999996,
		y:0.91669999999999996,
		supporter:"C"
	},
	{
		x:0.60650000000000004,
		y:0.92720000000000002,
		supporter:"C"
	},
	{
		x:0.53800000000000003,
		y:0.91039999999999999,
		supporter:"C"
	},
	{
		x:0.59199999999999997,
		y:0.94530000000000003,
		supporter:"C"
	},
	{
		x:0.5282,
		y:0.90969999999999995,
		supporter:"C"
	},
	{
		x:0.50019999999999998,
		y:0.90239999999999998,
		supporter:"C"
	},
	{
		x:0.52139999999999997,
		y:0.93,
		supporter:"C"
	},
	{
		x:0.5867,
		y:0.92500000000000004,
		supporter:"C"
	},
	{
		x:0.5101,
		y:0.90869999999999995,
		supporter:"C"
	},
	{
		x:0.60580000000000001,
		y:0.91790000000000005,
		supporter:"C"
	},
	{
		x:0.58440000000000003,
		y:0.91120000000000001,
		supporter:"C"
	},
	{
		x:0.62039999999999995,
		y:0.93,
		supporter:"C"
	},
	{
		x:0.59440000000000004,
		y:0.91959999999999997,
		supporter:"C"
	},
	{
		x:0.54959999999999998,
		y:0.90169999999999995,
		supporter:"C"
	},
	{
		x:0.58109999999999995,
		y:0.94169999999999998,
		supporter:"C"
	},
	{
		x:0.61499999999999999,
		y:0.91830000000000001,
		supporter:"C"
	},
	{
		x:0.55569999999999997,
		y:0.91749999999999998,
		supporter:"C"
	},
	{
		x:0.55720000000000003,
		y:0.92410000000000003,
		supporter:"C"
	},
	{
		x:0.54690000000000005,
		y:0.91200000000000003,
		supporter:"C"
	},
	{
		x:0.56420000000000003,
		y:0.91969999999999996,
		supporter:"C"
	},
	{
		x:0.5413,
		y:0.90359999999999996,
		supporter:"C"
	},
	{
		x:0.51649999999999996,
		y:0.92500000000000004,
		supporter:"C"
	},
	{
		x:0.59370000000000001,
		y:0.92030000000000001,
		supporter:"C"
	},
	{
		x:0.53400000000000003,
		y:0.90959999999999996,
		supporter:"C"
	},
	{
		x:0.62960000000000005,
		y:0.91359999999999997,
		supporter:"C"
	},
	{
		x:0.61439999999999995,
		y:0.92800000000000005,
		supporter:"C"
	},
	{
		x:0.51559999999999995,
		y:0.90849999999999997,
		supporter:"C"
	},
	{
		x:0.54190000000000005,
		y:0.91080000000000005,
		supporter:"C"
	},
	{
		x:0.5847,
		y:0.94669999999999999,
		supporter:"C"
	},
	{
		x:0.50080000000000002,
		y:0.9,
		supporter:"C"
	},
	{
		x:0.5917,
		y:0.94850000000000001,
		supporter:"C"
	},
	{
		x:0.61729999999999996,
		y:0.91910000000000003,
		supporter:"C"
	},
	{
		x:0.54220000000000002,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.52769999999999995,
		y:0.90680000000000005,
		supporter:"C"
	},
	{
		x:0.5423,
		y:0.90290000000000004,
		supporter:"C"
	},
	{
		x:0.51729999999999998,
		y:0.9002,
		supporter:"C"
	},
	{
		x:0.58150000000000002,
		y:0.91549999999999998,
		supporter:"C"
	},
	{
		x:0.61329999999999996,
		y:0.91249999999999998,
		supporter:"C"
	},
	{
		x:0.60289999999999999,
		y:0.9173,
		supporter:"C"
	},
	{
		x:0.61980000000000002,
		y:0.91810000000000003,
		supporter:"C"
	},
	{
		x:0.58120000000000005,
		y:0.94589999999999996,
		supporter:"C"
	},
	{
		x:0.51200000000000001,
		y:0.92169999999999996,
		supporter:"C"
	},
	{
		x:0.54479999999999995,
		y:0.90859999999999996,
		supporter:"C"
	},
	{
		x:0.52249999999999996,
		y:0.90349999999999997,
		supporter:"C"
	},
	{
		x:0.57499999999999996,
		y:0.90280000000000005,
		supporter:"C"
	},
	{
		x:0.53410000000000002,
		y:0.90429999999999999,
		supporter:"C"
	},
	{
		x:0.50719999999999998,
		y:0.92959999999999998,
		supporter:"C"
	},
	{
		x:0.60499999999999998,
		y:0.93059999999999998,
		supporter:"C"
	},
	{
		x:0.51790000000000003,
		y:0.9032,
		supporter:"C"
	},
	{
		x:0.59409999999999996,
		y:0.91869999999999996,
		supporter:"C"
	},
	{
		x:0.54010000000000002,
		y:0.90990000000000004,
		supporter:"C"
	},
	{
		x:0.51319999999999999,
		y:0.90790000000000004,
		supporter:"C"
	},
	{
		x:0.56769999999999998,
		y:0.92069999999999996,
		supporter:"C"
	},
	{
		x:0.52890000000000004,
		y:0.90649999999999997,
		supporter:"C"
	},
	{
		x:0.60060000000000002,
		y:0.91820000000000002,
		supporter:"C"
	},
	{
		x:0.57089999999999996,
		y:0.91759999999999997,
		supporter:"C"
	},
	{
		x:0.54320000000000002,
		y:0.90800000000000003,
		supporter:"C"
	},
	{
		x:0.57909999999999995,
		y:0.91479999999999995,
		supporter:"C"
	},
	{
		x:0.52180000000000004,
		y:0.90600000000000003,
		supporter:"C"
	},
	{
		x:0.55979999999999996,
		y:0.91639999999999999,
		supporter:"C"
	},
	{
		x:0.61799999999999999,
		y:0.91839999999999999,
		supporter:"C"
	},
	{
		x:0.59770000000000001,
		y:0.92090000000000005,
		supporter:"C"
	},
	{
		x:0.57789999999999997,
		y:0.91490000000000005,
		supporter:"C"
	},
	{
		x:0.61240000000000006,
		y:0.92710000000000004,
		supporter:"C"
	},
	{
		x:0.53129999999999999,
		y:0.92710000000000004,
		supporter:"C"
	},
	{
		x:0.62990000000000002,
		y:0.91020000000000001,
		supporter:"C"
	},
	{
		x:0.58389999999999997,
		y:0.91010000000000002,
		supporter:"C"
	},
	{
		x:0.54459999999999997,
		y:0.91449999999999998,
		supporter:"C"
	},
	{
		x:0.54290000000000005,
		y:0.9123,
		supporter:"C"
	}
]

export const scatterD = [
	{
		x:0.49490000000000001,
		y:0.90459999999999996,
		supporter:"D"
	},
	{
		x:0.58069999999999999,
		y:0.85,
		supporter:"D"
	},
	{
		x:0.38250000000000001,
		y:0.85029999999999994,
		supporter:"D"
	},
	{
		x:0.52210000000000001,
		y:0.88600000000000001,
		supporter:"D"
	},
	{
		x:0.42249999999999999,
		y:0.91869999999999996,
		supporter:"D"
	},
	{
		x:0.60850000000000004,
		y:0.89559999999999995,
		supporter:"D"
	},
	{
		x:0.49430000000000002,
		y:0.92330000000000001,
		supporter:"D"
	},
	{
		x:0.50249999999999995,
		y:0.88600000000000001,
		supporter:"D"
	},
	{
		x:0.3196,
		y:0.91180000000000005,
		supporter:"D"
	},
	{
		x:0.4768,
		y:0.88139999999999996,
		supporter:"D"
	},
	{
		x:0.3629,
		y:0.9083,
		supporter:"D"
	},
	{
		x:0.49120000000000003,
		y:0.90169999999999995,
		supporter:"D"
	},
	{
		x:0.56200000000000006,
		y:0.85729999999999995,
		supporter:"D"
	},
	{
		x:0.47439999999999999,
		y:0.91690000000000005,
		supporter:"D"
	},
	{
		x:0.43990000000000001,
		y:0.90429999999999999,
		supporter:"D"
	},
	{
		x:0.61480000000000001,
		y:0.80769999999999997,
		supporter:"D"
	},
	{
		x:0.5262,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.49519999999999997,
		y:0.92110000000000003,
		supporter:"D"
	},
	{
		x:0.38030000000000003,
		y:0.90859999999999996,
		supporter:"D"
	},
	{
		x:0.52380000000000004,
		y:0.79730000000000001,
		supporter:"D"
	},
	{
		x:0.31869999999999998,
		y:0.88980000000000004,
		supporter:"D"
	},
	{
		x:0.4945,
		y:0.93110000000000004,
		supporter:"D"
	},
	{
		x:0.46739999999999998,
		y:0.93259999999999998,
		supporter:"D"
	},
	{
		x:0.32619999999999999,
		y:0.89829999999999999,
		supporter:"D"
	},
	{
		x:0.61199999999999999,
		y:0.85329999999999995,
		supporter:"D"
	},
	{
		x:0.30819999999999997,
		y:0.89229999999999998,
		supporter:"D"
	},
	{
		x:0.5171,
		y:0.89170000000000005,
		supporter:"D"
	},
	{
		x:0.49990000000000001,
		y:0.90510000000000002,
		supporter:"D"
	},
	{
		x:0.47510000000000002,
		y:0.92010000000000003,
		supporter:"D"
	},
	{
		x:0.4834,
		y:0.9284,
		supporter:"D"
	},
	{
		x:0.31950000000000001,
		y:0.90839999999999999,
		supporter:"D"
	},
	{
		x:0.49959999999999999,
		y:0.90969999999999995,
		supporter:"D"
	},
	{
		x:0.37169999999999997,
		y:0.90159999999999996,
		supporter:"D"
	},
	{
		x:0.53839999999999999,
		y:0.8992,
		supporter:"D"
	},
	{
		x:0.53900000000000003,
		y:0.87729999999999997,
		supporter:"D"
	},
	{
		x:0.46650000000000003,
		y:0.89970000000000006,
		supporter:"D"
	},
	{
		x:0.44700000000000001,
		y:0.92059999999999997,
		supporter:"D"
	},
	{
		x:0.3402,
		y:0.91339999999999999,
		supporter:"D"
	},
	{
		x:0.43630000000000002,
		y:0.90339999999999998,
		supporter:"D"
	},
	{
		x:0.4662,
		y:0.88419999999999999,
		supporter:"D"
	},
	{
		x:0.49659999999999999,
		y:0.9123,
		supporter:"D"
	},
	{
		x:0.4738,
		y:0.92,
		supporter:"D"
	},
	{
		x:0.40460000000000002,
		y:0.91459999999999997,
		supporter:"D"
	},
	{
		x:0.45569999999999999,
		y:0.90410000000000001,
		supporter:"D"
	},
	{
		x:0.53410000000000002,
		y:0.89029999999999998,
		supporter:"D"
	},
	{
		x:0.4577,
		y:0.92159999999999997,
		supporter:"D"
	},
	{
		x:0.48039999999999999,
		y:0.8911,
		supporter:"D"
	},
	{
		x:0.44119999999999998,
		y:0.92310000000000003,
		supporter:"D"
	},
	{
		x:0.44650000000000001,
		y:0.92969999999999997,
		supporter:"D"
	},
	{
		x:0.54649999999999999,
		y:0.8952,
		supporter:"D"
	},
	{
		x:0.41810000000000003,
		y:0.85089999999999999,
		supporter:"D"
	},
	{
		x:0.29570000000000002,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.51259999999999994,
		y:0.8639,
		supporter:"D"
	},
	{
		x:0.45200000000000001,
		y:0.92430000000000001,
		supporter:"D"
	},
	{
		x:0.49890000000000001,
		y:0.9113,
		supporter:"D"
	},
	{
		x:0.38279999999999997,
		y:0.88859999999999995,
		supporter:"D"
	},
	{
		x:0.51870000000000005,
		y:0.89639999999999997,
		supporter:"D"
	},
	{
		x:0.50460000000000005,
		y:0.88729999999999998,
		supporter:"D"
	},
	{
		x:0.3805,
		y:0.90539999999999998,
		supporter:"D"
	},
	{
		x:0.4844,
		y:0.89470000000000005,
		supporter:"D"
	},
	{
		x:0.49199999999999999,
		y:0.88859999999999995,
		supporter:"D"
	},
	{
		x:0.51659999999999995,
		y:0.89590000000000003,
		supporter:"D"
	},
	{
		x:0.4884,
		y:0.88729999999999998,
		supporter:"D"
	},
	{
		x:0.57969999999999999,
		y:0.878,
		supporter:"D"
	},
	{
		x:0.44740000000000002,
		y:0.89039999999999997,
		supporter:"D"
	},
	{
		x:0.34239999999999998,
		y:0.90790000000000004,
		supporter:"D"
	},
	{
		x:0.47620000000000001,
		y:0.92789999999999995,
		supporter:"D"
	},
	{
		x:0.47620000000000001,
		y:0.91200000000000003,
		supporter:"D"
	},
	{
		x:0.29020000000000001,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.4602,
		y:0.79610000000000003,
		supporter:"D"
	},
	{
		x:0.60070000000000001,
		y:0.86880000000000002,
		supporter:"D"
	},
	{
		x:0.48220000000000002,
		y:0.92459999999999998,
		supporter:"D"
	},
	{
		x:0.43609999999999999,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.60970000000000002,
		y:0.82850000000000001,
		supporter:"D"
	},
	{
		x:0.60229999999999995,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.56930000000000003,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.40949999999999998,
		y:0.8478,
		supporter:"D"
	},
	{
		x:0.40949999999999998,
		y:0.89259999999999995,
		supporter:"D"
	},
	{
		x:0.33310000000000001,
		y:0.90500000000000003,
		supporter:"D"
	},
	{
		x:0.29089999999999999,
		y:0.90349999999999997,
		supporter:"D"
	},
	{
		x:0.36430000000000001,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.48859999999999998,
		y:0.8962,
		supporter:"D"
	},
	{
		x:0.59240000000000004,
		y:0.82530000000000003,
		supporter:"D"
	},
	{
		x:0.38450000000000001,
		y:0.84260000000000002,
		supporter:"D"
	},
	{
		x:0.32079999999999997,
		y:0.90659999999999996,
		supporter:"D"
	},
	{
		x:0.32379999999999998,
		y:0.91039999999999999,
		supporter:"D"
	},
	{
		x:0.37040000000000001,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.4168,
		y:0.85,
		supporter:"D"
	},
	{
		x:0.46400000000000002,
		y:0.87770000000000004,
		supporter:"D"
	},
	{
		x:0.4219,
		y:0.83289999999999997,
		supporter:"D"
	},
	{
		x:0.4083,
		y:0.85699999999999998,
		supporter:"D"
	},
	{
		x:0.35260000000000002,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.4672,
		y:0.90100000000000002,
		supporter:"D"
	},
	{
		x:0.54830000000000001,
		y:0.88329999999999997,
		supporter:"D"
	},
	{
		x:0.62239999999999995,
		y:0.89800000000000002,
		supporter:"D"
	},
	{
		x:0.54969999999999997,
		y:0.88249999999999995,
		supporter:"D"
	},
	{
		x:0.59589999999999999,
		y:0.87980000000000003,
		supporter:"D"
	},
	{
		x:0.37180000000000002,
		y:0.90949999999999998,
		supporter:"D"
	},
	{
		x:0.3639,
		y:0.91049999999999998,
		supporter:"D"
	},
	{
		x:0.3649,
		y:0.90639999999999998,
		supporter:"D"
	},
	{
		x:0.3604,
		y:0.8508,
		supporter:"D"
	},
	{
		x:0.49719999999999998,
		y:0.89480000000000004,
		supporter:"D"
	},
	{
		x:0.43959999999999999,
		y:0.91910000000000003,
		supporter:"D"
	},
	{
		x:0.5887,
		y:0.7913,
		supporter:"D"
	},
	{
		x:0.44719999999999999,
		y:0.92330000000000001,
		supporter:"D"
	},
	{
		x:0.30969999999999998,
		y:0.90290000000000004,
		supporter:"D"
	},
	{
		x:0.48649999999999999,
		y:0.90990000000000004,
		supporter:"D"
	},
	{
		x:0.50029999999999997,
		y:0.89390000000000003,
		supporter:"D"
	},
	{
		x:0.44379999999999997,
		y:0.91339999999999999,
		supporter:"D"
	},
	{
		x:0.48320000000000002,
		y:0.88629999999999998,
		supporter:"D"
	},
	{
		x:0.40770000000000001,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.2984,
		y:0.90710000000000002,
		supporter:"D"
	},
	{
		x:0.36420000000000002,
		y:0.90720000000000001,
		supporter:"D"
	},
	{
		x:0.47060000000000002,
		y:0.93069999999999997,
		supporter:"D"
	},
	{
		x:0.54849999999999999,
		y:0.87019999999999997,
		supporter:"D"
	},
	{
		x:0.52480000000000004,
		y:0.89949999999999997,
		supporter:"D"
	},
	{
		x:0.27610000000000001,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.31169999999999998,
		y:0.90610000000000002,
		supporter:"D"
	},
	{
		x:0.60419999999999996,
		y:0.84760000000000002,
		supporter:"D"
	},
	{
		x:0.44629999999999997,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.4798,
		y:0.90280000000000005,
		supporter:"D"
	},
	{
		x:0.49419999999999997,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.51160000000000005,
		y:0.89419999999999999,
		supporter:"D"
	},
	{
		x:0.48170000000000002,
		y:0.90629999999999999,
		supporter:"D"
	},
	{
		x:0.58560000000000001,
		y:0.83050000000000002,
		supporter:"D"
	},
	{
		x:0.54949999999999999,
		y:0.89870000000000005,
		supporter:"D"
	},
	{
		x:0.53600000000000003,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.51570000000000005,
		y:0.87439999999999996,
		supporter:"D"
	},
	{
		x:0.61360000000000003,
		y:0.86939999999999995,
		supporter:"D"
	},
	{
		x:0.4924,
		y:0.80700000000000005,
		supporter:"D"
	},
	{
		x:0.35830000000000001,
		y:0.90510000000000002,
		supporter:"D"
	},
	{
		x:0.46239999999999998,
		y:0.90910000000000002,
		supporter:"D"
	},
	{
		x:0.35139999999999999,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.56669999999999998,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.54330000000000001,
		y:0.89739999999999998,
		supporter:"D"
	},
	{
		x:0.51449999999999996,
		y:0.88829999999999998,
		supporter:"D"
	},
	{
		x:0.48249999999999998,
		y:0.8891,
		supporter:"D"
	},
	{
		x:0.29110000000000003,
		y:0.89680000000000004,
		supporter:"D"
	},
	{
		x:0.37,
		y:0.90349999999999997,
		supporter:"D"
	},
	{
		x:0.50370000000000004,
		y:0.88749999999999996,
		supporter:"D"
	},
	{
		x:0.46289999999999998,
		y:0.9294,
		supporter:"D"
	},
	{
		x:0.49220000000000003,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.55789999999999995,
		y:0.89959999999999996,
		supporter:"D"
	},
	{
		x:0.36459999999999998,
		y:0.84909999999999997,
		supporter:"D"
	},
	{
		x:0.51180000000000003,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.3755,
		y:0.90869999999999995,
		supporter:"D"
	},
	{
		x:0.58540000000000003,
		y:0.86890000000000001,
		supporter:"D"
	},
	{
		x:0.34939999999999999,
		y:0.88390000000000002,
		supporter:"D"
	},
	{
		x:0.57620000000000005,
		y:0.88290000000000002,
		supporter:"D"
	},
	{
		x:0.6109,
		y:0.89349999999999996,
		supporter:"D"
	},
	{
		x:0.40250000000000002,
		y:0.86040000000000005,
		supporter:"D"
	},
	{
		x:0.52110000000000001,
		y:0.89329999999999998,
		supporter:"D"
	},
	{
		x:0.32800000000000001,
		y:0.90380000000000005,
		supporter:"D"
	},
	{
		x:0.5887,
		y:0.89559999999999995,
		supporter:"D"
	},
	{
		x:0.4279,
		y:0.91800000000000004,
		supporter:"D"
	},
	{
		x:0.59240000000000004,
		y:0.87219999999999998,
		supporter:"D"
	},
	{
		x:0.51759999999999995,
		y:0.8982,
		supporter:"D"
	},
	{
		x:0.31119999999999998,
		y:0.90969999999999995,
		supporter:"D"
	},
	{
		x:0.48509999999999998,
		y:0.88470000000000004,
		supporter:"D"
	},
	{
		x:0.50170000000000003,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.42870000000000003,
		y:0.90900000000000003,
		supporter:"D"
	},
	{
		x:0.498,
		y:0.90359999999999996,
		supporter:"D"
	},
	{
		x:0.49440000000000001,
		y:0.92310000000000003,
		supporter:"D"
	},
	{
		x:0.51639999999999997,
		y:0.87529999999999997,
		supporter:"D"
	},
	{
		x:0.33979999999999999,
		y:0.90739999999999998,
		supporter:"D"
	},
	{
		x:0.48399999999999999,
		y:0.85199999999999998,
		supporter:"D"
	},
	{
		x:0.59570000000000001,
		y:0.88839999999999997,
		supporter:"D"
	},
	{
		x:0.35920000000000002,
		y:0.90839999999999999,
		supporter:"D"
	},
	{
		x:0.61109999999999998,
		y:0.88949999999999996,
		supporter:"D"
	},
	{
		x:0.44280000000000003,
		y:0.90090000000000003,
		supporter:"D"
	},
	{
		x:0.43580000000000002,
		y:0.92079999999999995,
		supporter:"D"
	},
	{
		x:0.4889,
		y:0.90410000000000001,
		supporter:"D"
	},
	{
		x:0.58950000000000002,
		y:0.88219999999999998,
		supporter:"D"
	},
	{
		x:0.53769999999999996,
		y:0.86939999999999995,
		supporter:"D"
	},
	{
		x:0.4713,
		y:0.89990000000000003,
		supporter:"D"
	},
	{
		x:0.47310000000000002,
		y:0.80769999999999997,
		supporter:"D"
	},
	{
		x:0.48420000000000002,
		y:0.92169999999999996,
		supporter:"D"
	},
	{
		x:0.35580000000000001,
		y:0.8861,
		supporter:"D"
	},
	{
		x:0.44059999999999999,
		y:0.90010000000000001,
		supporter:"D"
	},
	{
		x:0.33600000000000002,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.4541,
		y:0.90329999999999999,
		supporter:"D"
	},
	{
		x:0.50919999999999999,
		y:0.88400000000000001,
		supporter:"D"
	},
	{
		x:0.50619999999999998,
		y:0.80589999999999995,
		supporter:"D"
	},
	{
		x:0.4783,
		y:0.93089999999999995,
		supporter:"D"
	},
	{
		x:0.30520000000000003,
		y:0.91039999999999999,
		supporter:"D"
	},
	{
		x:0.496,
		y:0.92500000000000004,
		supporter:"D"
	},
	{
		x:0.33989999999999998,
		y:0.90280000000000005,
		supporter:"D"
	},
	{
		x:0.3921,
		y:0.89249999999999996,
		supporter:"D"
	},
	{
		x:0.55069999999999997,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.46479999999999999,
		y:0.88300000000000001,
		supporter:"D"
	},
	{
		x:0.48230000000000001,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.47239999999999999,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.37180000000000002,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.4647,
		y:0.9022,
		supporter:"D"
	},
	{
		x:0.5363,
		y:0.87509999999999999,
		supporter:"D"
	},
	{
		x:0.45669999999999999,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.23699999999999999,
		y:0.88859999999999995,
		supporter:"D"
	},
	{
		x:0.45939999999999998,
		y:0.92569999999999997,
		supporter:"D"
	},
	{
		x:0.4657,
		y:0.92220000000000002,
		supporter:"D"
	},
	{
		x:0.6018,
		y:0.89659999999999995,
		supporter:"D"
	},
	{
		x:0.433,
		y:0.89829999999999999,
		supporter:"D"
	},
	{
		x:0.49220000000000003,
		y:0.93,
		supporter:"D"
	},
	{
		x:0.61539999999999995,
		y:0.88300000000000001,
		supporter:"D"
	},
	{
		x:0.34310000000000002,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.32029999999999997,
		y:0.89500000000000002,
		supporter:"D"
	},
	{
		x:0.56979999999999997,
		y:0.87529999999999997,
		supporter:"D"
	},
	{
		x:0.56989999999999996,
		y:0.85950000000000004,
		supporter:"D"
	},
	{
		x:0.44790000000000002,
		y:0.92710000000000004,
		supporter:"D"
	},
	{
		x:0.36449999999999999,
		y:0.90339999999999998,
		supporter:"D"
	},
	{
		x:0.57550000000000001,
		y:0.8579,
		supporter:"D"
	},
	{
		x:0.46949999999999997,
		y:0.92169999999999996,
		supporter:"D"
	},
	{
		x:0.35470000000000002,
		y:0.90790000000000004,
		supporter:"D"
	},
	{
		x:0.47870000000000001,
		y:0.91149999999999998,
		supporter:"D"
	},
	{
		x:0.48759999999999998,
		y:0.93189999999999995,
		supporter:"D"
	},
	{
		x:0.33810000000000001,
		y:0.90380000000000005,
		supporter:"D"
	},
	{
		x:0.57820000000000005,
		y:0.84760000000000002,
		supporter:"D"
	},
	{
		x:0.59509999999999996,
		y:0.89480000000000004,
		supporter:"D"
	},
	{
		x:0.49809999999999999,
		y:0.93559999999999999,
		supporter:"D"
	},
	{
		x:0.41980000000000001,
		y:0.91669999999999996,
		supporter:"D"
	},
	{
		x:0.32650000000000001,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.32529999999999998,
		y:0.91210000000000002,
		supporter:"D"
	},
	{
		x:0.41249999999999998,
		y:0.90200000000000002,
		supporter:"D"
	},
	{
		x:0.49220000000000003,
		y:0.88439999999999996,
		supporter:"D"
	},
	{
		x:0.31119999999999998,
		y:0.89459999999999995,
		supporter:"D"
	},
	{
		x:0.54579999999999995,
		y:0.8931,
		supporter:"D"
	},
	{
		x:0.44729999999999998,
		y:0.80130000000000001,
		supporter:"D"
	},
	{
		x:0.56679999999999997,
		y:0.88949999999999996,
		supporter:"D"
	},
	{
		x:0.57509999999999994,
		y:0.82530000000000003,
		supporter:"D"
	},
	{
		x:0.57699999999999996,
		y:0.83909999999999996,
		supporter:"D"
	},
	{
		x:0.48220000000000002,
		y:0.92779999999999996,
		supporter:"D"
	},
	{
		x:0.44690000000000002,
		y:0.91700000000000004,
		supporter:"D"
	},
	{
		x:0.3846,
		y:0.84650000000000003,
		supporter:"D"
	},
	{
		x:0.51929999999999998,
		y:0.86770000000000003,
		supporter:"D"
	},
	{
		x:0.45050000000000001,
		y:0.91869999999999996,
		supporter:"D"
	},
	{
		x:0.37830000000000003,
		y:0.90039999999999998,
		supporter:"D"
	},
	{
		x:0.46550000000000002,
		y:0.92520000000000002,
		supporter:"D"
	},
	{
		x:0.35399999999999998,
		y:0.90290000000000004,
		supporter:"D"
	},
	{
		x:0.34360000000000002,
		y:0.90369999999999995,
		supporter:"D"
	},
	{
		x:0.46089999999999998,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.37569999999999998,
		y:0.9133,
		supporter:"D"
	},
	{
		x:0.28499999999999998,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.47049999999999997,
		y:0.9032,
		supporter:"D"
	},
	{
		x:0.47220000000000001,
		y:0.92649999999999999,
		supporter:"D"
	},
	{
		x:0.62609999999999999,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.60019999999999996,
		y:0.89839999999999998,
		supporter:"D"
	},
	{
		x:0.35299999999999998,
		y:0.90649999999999997,
		supporter:"D"
	},
	{
		x:0.441,
		y:0.86199999999999999,
		supporter:"D"
	},
	{
		x:0.53449999999999998,
		y:0.86380000000000001,
		supporter:"D"
	},
	{
		x:0.58279999999999998,
		y:0.87239999999999995,
		supporter:"D"
	},
	{
		x:0.46,
		y:0.90959999999999996,
		supporter:"D"
	},
	{
		x:0.40960000000000002,
		y:0.90569999999999995,
		supporter:"D"
	},
	{
		x:0.39629999999999999,
		y:0.90990000000000004,
		supporter:"D"
	},
	{
		x:0.4733,
		y:0.90490000000000004,
		supporter:"D"
	},
	{
		x:0.57709999999999995,
		y:0.85289999999999999,
		supporter:"D"
	},
	{
		x:0.48120000000000002,
		y:0.90369999999999995,
		supporter:"D"
	},
	{
		x:0.48599999999999999,
		y:0.90680000000000005,
		supporter:"D"
	},
	{
		x:0.48520000000000002,
		y:0.88170000000000004,
		supporter:"D"
	},
	{
		x:0.59370000000000001,
		y:0.81940000000000002,
		supporter:"D"
	},
	{
		x:0.50349999999999995,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.62539999999999996,
		y:0.83,
		supporter:"D"
	},
	{
		x:0.46029999999999999,
		y:0.91790000000000005,
		supporter:"D"
	},
	{
		x:0.58779999999999999,
		y:0.86009999999999998,
		supporter:"D"
	},
	{
		x:0.41799999999999998,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.35680000000000001,
		y:0.90649999999999997,
		supporter:"D"
	},
	{
		x:0.60909999999999997,
		y:0.80820000000000003,
		supporter:"D"
	},
	{
		x:0.49149999999999999,
		y:0.88759999999999994,
		supporter:"D"
	},
	{
		x:0.39460000000000001,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.34760000000000002,
		y:0.91269999999999996,
		supporter:"D"
	},
	{
		x:0.54210000000000003,
		y:0.86060000000000003,
		supporter:"D"
	},
	{
		x:0.53890000000000005,
		y:0.84730000000000005,
		supporter:"D"
	},
	{
		x:0.58320000000000005,
		y:0.86040000000000005,
		supporter:"D"
	},
	{
		x:0.55710000000000004,
		y:0.87129999999999996,
		supporter:"D"
	},
	{
		x:0.56040000000000001,
		y:0.8609,
		supporter:"D"
	},
	{
		x:0.56499999999999995,
		y:0.87150000000000005,
		supporter:"D"
	},
	{
		x:0.50219999999999998,
		y:0.87350000000000005,
		supporter:"D"
	},
	{
		x:0.3624,
		y:0.90759999999999996,
		supporter:"D"
	},
	{
		x:0.4002,
		y:0.85219999999999996,
		supporter:"D"
	},
	{
		x:0.55700000000000005,
		y:0.87080000000000002,
		supporter:"D"
	},
	{
		x:0.54969999999999997,
		y:0.86609999999999998,
		supporter:"D"
	},
	{
		x:0.54269999999999996,
		y:0.88439999999999996,
		supporter:"D"
	},
	{
		x:0.33050000000000002,
		y:0.91220000000000001,
		supporter:"D"
	},
	{
		x:0.50229999999999997,
		y:0.89219999999999999,
		supporter:"D"
	},
	{
		x:0.43359999999999999,
		y:0.9214,
		supporter:"D"
	},
	{
		x:0.48820000000000002,
		y:0.92949999999999999,
		supporter:"D"
	},
	{
		x:0.31090000000000001,
		y:0.90490000000000004,
		supporter:"D"
	},
	{
		x:0.53749999999999998,
		y:0.8639,
		supporter:"D"
	},
	{
		x:0.4299,
		y:0.91420000000000001,
		supporter:"D"
	},
	{
		x:0.4894,
		y:0.88349999999999995,
		supporter:"D"
	},
	{
		x:0.48649999999999999,
		y:0.93169999999999997,
		supporter:"D"
	},
	{
		x:0.49580000000000002,
		y:0.91159999999999997,
		supporter:"D"
	},
	{
		x:0.37319999999999998,
		y:0.90239999999999998,
		supporter:"D"
	},
	{
		x:0.58220000000000005,
		y:0.86319999999999997,
		supporter:"D"
	},
	{
		x:0.3508,
		y:0.90439999999999998,
		supporter:"D"
	},
	{
		x:0.45729999999999998,
		y:0.92810000000000004,
		supporter:"D"
	},
	{
		x:0.48570000000000002,
		y:0.92410000000000003,
		supporter:"D"
	},
	{
		x:0.374,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.41560000000000002,
		y:0.84589999999999999,
		supporter:"D"
	},
	{
		x:0.48220000000000002,
		y:0.90069999999999995,
		supporter:"D"
	},
	{
		x:0.39300000000000002,
		y:0.91039999999999999,
		supporter:"D"
	},
	{
		x:0.4975,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.48380000000000001,
		y:0.86060000000000003,
		supporter:"D"
	},
	{
		x:0.59,
		y:0.89780000000000004,
		supporter:"D"
	},
	{
		x:0.57579999999999998,
		y:0.87780000000000002,
		supporter:"D"
	},
	{
		x:0.52339999999999998,
		y:0.89359999999999995,
		supporter:"D"
	},
	{
		x:0.58679999999999999,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.35820000000000002,
		y:0.90080000000000005,
		supporter:"D"
	},
	{
		x:0.46639999999999998,
		y:0.92190000000000005,
		supporter:"D"
	},
	{
		x:0.57389999999999997,
		y:0.87829999999999997,
		supporter:"D"
	},
	{
		x:0.52110000000000001,
		y:0.85950000000000004,
		supporter:"D"
	},
	{
		x:0.52410000000000001,
		y:0.87219999999999998,
		supporter:"D"
	},
	{
		x:0.62880000000000003,
		y:0.81279999999999997,
		supporter:"D"
	},
	{
		x:0.48909999999999998,
		y:0.90759999999999996,
		supporter:"D"
	},
	{
		x:0.45639999999999997,
		y:0.91690000000000005,
		supporter:"D"
	},
	{
		x:0.59240000000000004,
		y:0.77659999999999996,
		supporter:"D"
	},
	{
		x:0.29459999999999997,
		y:0.89139999999999997,
		supporter:"D"
	},
	{
		x:0.45979999999999999,
		y:0.92820000000000003,
		supporter:"D"
	},
	{
		x:0.40589999999999998,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.36630000000000001,
		y:0.90380000000000005,
		supporter:"D"
	},
	{
		x:0.4788,
		y:0.93259999999999998,
		supporter:"D"
	},
	{
		x:0.49209999999999998,
		y:0.84719999999999995,
		supporter:"D"
	},
	{
		x:0.41799999999999998,
		y:0.90759999999999996,
		supporter:"D"
	},
	{
		x:0.44190000000000002,
		y:0.91820000000000002,
		supporter:"D"
	},
	{
		x:0.4637,
		y:0.90200000000000002,
		supporter:"D"
	},
	{
		x:0.61719999999999997,
		y:0.77280000000000004,
		supporter:"D"
	},
	{
		x:0.2999,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.54600000000000004,
		y:0.87709999999999999,
		supporter:"D"
	},
	{
		x:0.49459999999999998,
		y:0.92800000000000005,
		supporter:"D"
	},
	{
		x:0.47399999999999998,
		y:0.92120000000000002,
		supporter:"D"
	},
	{
		x:0.32329999999999998,
		y:0.90669999999999995,
		supporter:"D"
	},
	{
		x:0.46820000000000001,
		y:0.91720000000000002,
		supporter:"D"
	},
	{
		x:0.43659999999999999,
		y:0.90839999999999999,
		supporter:"D"
	},
	{
		x:0.30480000000000002,
		y:0.91379999999999995,
		supporter:"D"
	},
	{
		x:0.42199999999999999,
		y:0.91379999999999995,
		supporter:"D"
	},
	{
		x:0.4904,
		y:0.90680000000000005,
		supporter:"D"
	},
	{
		x:0.48970000000000002,
		y:0.89790000000000003,
		supporter:"D"
	},
	{
		x:0.40310000000000001,
		y:0.86029999999999995,
		supporter:"D"
	},
	{
		x:0.51680000000000004,
		y:0.89390000000000003,
		supporter:"D"
	},
	{
		x:0.49030000000000001,
		y:0.9234,
		supporter:"D"
	},
	{
		x:0.50719999999999998,
		y:0.88859999999999995,
		supporter:"D"
	},
	{
		x:0.30919999999999997,
		y:0.90410000000000001,
		supporter:"D"
	},
	{
		x:0.40810000000000002,
		y:0.90920000000000001,
		supporter:"D"
	},
	{
		x:0.55900000000000005,
		y:0.89100000000000001,
		supporter:"D"
	},
	{
		x:0.49669999999999997,
		y:0.92320000000000002,
		supporter:"D"
	},
	{
		x:0.47760000000000002,
		y:0.91590000000000005,
		supporter:"D"
	},
	{
		x:0.31819999999999998,
		y:0.89780000000000004,
		supporter:"D"
	},
	{
		x:0.44540000000000002,
		y:0.85470000000000002,
		supporter:"D"
	},
	{
		x:0.43219999999999997,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.61260000000000003,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.50329999999999997,
		y:0.85250000000000004,
		supporter:"D"
	},
	{
		x:0.57110000000000005,
		y:0.89490000000000003,
		supporter:"D"
	},
	{
		x:0.52249999999999996,
		y:0.87539999999999996,
		supporter:"D"
	},
	{
		x:0.2782,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.61399999999999999,
		y:0.86319999999999997,
		supporter:"D"
	},
	{
		x:0.53779999999999994,
		y:0.87970000000000004,
		supporter:"D"
	},
	{
		x:0.6018,
		y:0.8145,
		supporter:"D"
	},
	{
		x:0.36359999999999998,
		y:0.85770000000000002,
		supporter:"D"
	},
	{
		x:0.37159999999999999,
		y:0.84599999999999997,
		supporter:"D"
	},
	{
		x:0.441,
		y:0.93020000000000003,
		supporter:"D"
	},
	{
		x:0.45929999999999999,
		y:0.90249999999999997,
		supporter:"D"
	},
	{
		x:0.5282,
		y:0.89980000000000004,
		supporter:"D"
	},
	{
		x:0.51870000000000005,
		y:0.86029999999999995,
		supporter:"D"
	},
	{
		x:0.3826,
		y:0.91669999999999996,
		supporter:"D"
	},
	{
		x:0.48830000000000001,
		y:0.91010000000000002,
		supporter:"D"
	},
	{
		x:0.60529999999999995,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.3236,
		y:0.90469999999999995,
		supporter:"D"
	},
	{
		x:0.34860000000000002,
		y:0.90469999999999995,
		supporter:"D"
	},
	{
		x:0.5121,
		y:0.88819999999999999,
		supporter:"D"
	},
	{
		x:0.54239999999999999,
		y:0.86070000000000002,
		supporter:"D"
	},
	{
		x:0.45450000000000002,
		y:0.92079999999999995,
		supporter:"D"
	},
	{
		x:0.49759999999999999,
		y:0.92100000000000004,
		supporter:"D"
	},
	{
		x:0.49780000000000002,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.53439999999999999,
		y:0.81520000000000004,
		supporter:"D"
	},
	{
		x:0.36070000000000002,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.49890000000000001,
		y:0.93030000000000002,
		supporter:"D"
	},
	{
		x:0.41570000000000001,
		y:0.90700000000000003,
		supporter:"D"
	},
	{
		x:0.52039999999999997,
		y:0.8034,
		supporter:"D"
	},
	{
		x:0.44679999999999997,
		y:0.92759999999999998,
		supporter:"D"
	},
	{
		x:0.48670000000000002,
		y:0.79830000000000001,
		supporter:"D"
	},
	{
		x:0.35420000000000001,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.51980000000000004,
		y:0.89029999999999998,
		supporter:"D"
	},
	{
		x:0.49270000000000003,
		y:0.91190000000000004,
		supporter:"D"
	},
	{
		x:0.48139999999999999,
		y:0.92320000000000002,
		supporter:"D"
	},
	{
		x:0.49320000000000003,
		y:0.90800000000000003,
		supporter:"D"
	},
	{
		x:0.48609999999999998,
		y:0.79220000000000002,
		supporter:"D"
	},
	{
		x:0.50760000000000005,
		y:0.8962,
		supporter:"D"
	},
	{
		x:0.56779999999999997,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.29399999999999998,
		y:0.90249999999999997,
		supporter:"D"
	},
	{
		x:0.37540000000000001,
		y:0.90769999999999995,
		supporter:"D"
	},
	{
		x:0.4526,
		y:0.91749999999999998,
		supporter:"D"
	},
	{
		x:0.47360000000000002,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.48980000000000001,
		y:0.90129999999999999,
		supporter:"D"
	},
	{
		x:0.33129999999999998,
		y:0.90029999999999999,
		supporter:"D"
	},
	{
		x:0.432,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.3281,
		y:0.90949999999999998,
		supporter:"D"
	},
	{
		x:0.56240000000000001,
		y:0.89739999999999998,
		supporter:"D"
	},
	{
		x:0.4874,
		y:0.91320000000000001,
		supporter:"D"
	},
	{
		x:0.44109999999999999,
		y:0.91890000000000005,
		supporter:"D"
	},
	{
		x:0.44059999999999999,
		y:0.92390000000000005,
		supporter:"D"
	},
	{
		x:0.48659999999999998,
		y:0.93240000000000001,
		supporter:"D"
	},
	{
		x:0.45529999999999998,
		y:0.91949999999999998,
		supporter:"D"
	},
	{
		x:0.27950000000000003,
		y:0.89459999999999995,
		supporter:"D"
	},
	{
		x:0.62239999999999995,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.58320000000000005,
		y:0.82379999999999998,
		supporter:"D"
	},
	{
		x:0.56320000000000003,
		y:0.81010000000000004,
		supporter:"D"
	},
	{
		x:0.60140000000000005,
		y:0.88839999999999997,
		supporter:"D"
	},
	{
		x:0.60499999999999998,
		y:0.7843,
		supporter:"D"
	},
	{
		x:0.38969999999999999,
		y:0.84899999999999998,
		supporter:"D"
	},
	{
		x:0.43259999999999998,
		y:0.92669999999999997,
		supporter:"D"
	},
	{
		x:0.40450000000000003,
		y:0.85,
		supporter:"D"
	},
	{
		x:0.35260000000000002,
		y:0.85829999999999995,
		supporter:"D"
	},
	{
		x:0.45390000000000003,
		y:0.93289999999999995,
		supporter:"D"
	},
	{
		x:0.46089999999999998,
		y:0.88170000000000004,
		supporter:"D"
	},
	{
		x:0.41589999999999999,
		y:0.90900000000000003,
		supporter:"D"
	},
	{
		x:0.36320000000000002,
		y:0.9103,
		supporter:"D"
	},
	{
		x:0.44719999999999999,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.3095,
		y:0.91110000000000002,
		supporter:"D"
	},
	{
		x:0.5897,
		y:0.87509999999999999,
		supporter:"D"
	},
	{
		x:0.50260000000000005,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.55579999999999996,
		y:0.86409999999999998,
		supporter:"D"
	},
	{
		x:0.57020000000000004,
		y:0.87990000000000002,
		supporter:"D"
	},
	{
		x:0.49220000000000003,
		y:0.91200000000000003,
		supporter:"D"
	},
	{
		x:0.49680000000000002,
		y:0.88539999999999996,
		supporter:"D"
	},
	{
		x:0.35730000000000001,
		y:0.90300000000000002,
		supporter:"D"
	},
	{
		x:0.40279999999999999,
		y:0.90069999999999995,
		supporter:"D"
	},
	{
		x:0.54469999999999996,
		y:0.89490000000000003,
		supporter:"D"
	},
	{
		x:0.49209999999999998,
		y:0.9103,
		supporter:"D"
	},
	{
		x:0.55769999999999997,
		y:0.85880000000000001,
		supporter:"D"
	},
	{
		x:0.30049999999999999,
		y:0.90359999999999996,
		supporter:"D"
	},
	{
		x:0.50380000000000003,
		y:0.86560000000000004,
		supporter:"D"
	},
	{
		x:0.44790000000000002,
		y:0.80220000000000002,
		supporter:"D"
	},
	{
		x:0.47649999999999998,
		y:0.92169999999999996,
		supporter:"D"
	},
	{
		x:0.47810000000000002,
		y:0.89900000000000002,
		supporter:"D"
	},
	{
		x:0.41549999999999998,
		y:0.90329999999999999,
		supporter:"D"
	},
	{
		x:0.55389999999999995,
		y:0.86080000000000001,
		supporter:"D"
	},
	{
		x:0.46629999999999999,
		y:0.79020000000000001,
		supporter:"D"
	},
	{
		x:0.47210000000000002,
		y:0.93089999999999995,
		supporter:"D"
	},
	{
		x:0.47249999999999998,
		y:0.92959999999999998,
		supporter:"D"
	},
	{
		x:0.42830000000000001,
		y:0.90180000000000005,
		supporter:"D"
	},
	{
		x:0.45150000000000001,
		y:0.90559999999999996,
		supporter:"D"
	},
	{
		x:0.436,
		y:0.92449999999999999,
		supporter:"D"
	},
	{
		x:0.58730000000000004,
		y:0.88229999999999997,
		supporter:"D"
	},
	{
		x:0.46529999999999999,
		y:0.92279999999999995,
		supporter:"D"
	},
	{
		x:0.49719999999999998,
		y:0.93200000000000005,
		supporter:"D"
	},
	{
		x:0.2923,
		y:0.89219999999999999,
		supporter:"D"
	},
	{
		x:0.34239999999999998,
		y:0.90439999999999998,
		supporter:"D"
	},
	{
		x:0.5212,
		y:0.88329999999999997,
		supporter:"D"
	},
	{
		x:0.56240000000000001,
		y:0.85809999999999997,
		supporter:"D"
	},
	{
		x:0.48870000000000002,
		y:0.8075,
		supporter:"D"
	},
	{
		x:0.46660000000000001,
		y:0.93540000000000001,
		supporter:"D"
	},
	{
		x:0.34289999999999998,
		y:0.89490000000000003,
		supporter:"D"
	},
	{
		x:0.501,
		y:0.89259999999999995,
		supporter:"D"
	},
	{
		x:0.39529999999999998,
		y:0.91539999999999999,
		supporter:"D"
	},
	{
		x:0.56379999999999997,
		y:0.86040000000000005,
		supporter:"D"
	},
	{
		x:0.54179999999999995,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.45700000000000002,
		y:0.91810000000000003,
		supporter:"D"
	},
	{
		x:0.28210000000000002,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.39650000000000002,
		y:0.90449999999999997,
		supporter:"D"
	},
	{
		x:0.42430000000000001,
		y:0.85840000000000005,
		supporter:"D"
	},
	{
		x:0.38019999999999998,
		y:0.90010000000000001,
		supporter:"D"
	},
	{
		x:0.28720000000000001,
		y:0.89990000000000003,
		supporter:"D"
	},
	{
		x:0.46,
		y:0.90059999999999996,
		supporter:"D"
	},
	{
		x:0.55189999999999995,
		y:0.87060000000000004,
		supporter:"D"
	},
	{
		x:0.48110000000000003,
		y:0.9103,
		supporter:"D"
	},
	{
		x:0.49399999999999999,
		y:0.92330000000000001,
		supporter:"D"
	},
	{
		x:0.32819999999999999,
		y:0.9032,
		supporter:"D"
	},
	{
		x:0.59650000000000003,
		y:0.86040000000000005,
		supporter:"D"
	},
	{
		x:0.49380000000000002,
		y:0.91,
		supporter:"D"
	},
	{
		x:0.29559999999999997,
		y:0.88480000000000003,
		supporter:"D"
	},
	{
		x:0.53320000000000001,
		y:0.84719999999999995,
		supporter:"D"
	},
	{
		x:0.52010000000000001,
		y:0.85589999999999999,
		supporter:"D"
	},
	{
		x:0.29580000000000001,
		y:0.90180000000000005,
		supporter:"D"
	},
	{
		x:0.52769999999999995,
		y:0.86070000000000002,
		supporter:"D"
	},
	{
		x:0.48809999999999998,
		y:0.9325,
		supporter:"D"
	},
	{
		x:0.3165,
		y:0.90659999999999996,
		supporter:"D"
	},
	{
		x:0.34620000000000001,
		y:0.91479999999999995,
		supporter:"D"
	},
	{
		x:0.36759999999999998,
		y:0.9012,
		supporter:"D"
	},
	{
		x:0.48209999999999997,
		y:0.88070000000000004,
		supporter:"D"
	},
	{
		x:0.44309999999999999,
		y:0.90280000000000005,
		supporter:"D"
	},
	{
		x:0.60140000000000005,
		y:0.80889999999999995,
		supporter:"D"
	},
	{
		x:0.37030000000000002,
		y:0.9113,
		supporter:"D"
	},
	{
		x:0.34760000000000002,
		y:0.91410000000000002,
		supporter:"D"
	},
	{
		x:0.45069999999999999,
		y:0.92920000000000003,
		supporter:"D"
	},
	{
		x:0.47870000000000001,
		y:0.92600000000000005,
		supporter:"D"
	},
	{
		x:0.53720000000000001,
		y:0.8992,
		supporter:"D"
	},
	{
		x:0.34260000000000002,
		y:0.90810000000000002,
		supporter:"D"
	},
	{
		x:0.38419999999999999,
		y:0.91180000000000005,
		supporter:"D"
	},
	{
		x:0.38919999999999999,
		y:0.91449999999999998,
		supporter:"D"
	},
	{
		x:0.43540000000000001,
		y:0.91800000000000004,
		supporter:"D"
	},
	{
		x:0.52129999999999999,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.4375,
		y:0.90800000000000003,
		supporter:"D"
	},
	{
		x:0.58360000000000001,
		y:0.85429999999999995,
		supporter:"D"
	},
	{
		x:0.53859999999999997,
		y:0.89700000000000002,
		supporter:"D"
	},
	{
		x:0.34060000000000001,
		y:0.90469999999999995,
		supporter:"D"
	},
	{
		x:0.48039999999999999,
		y:0.88249999999999995,
		supporter:"D"
	},
	{
		x:0.43930000000000002,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.4546,
		y:0.91659999999999997,
		supporter:"D"
	},
	{
		x:0.44890000000000002,
		y:0.90300000000000002,
		supporter:"D"
	},
	{
		x:0.4012,
		y:0.85929999999999995,
		supporter:"D"
	},
	{
		x:0.48399999999999999,
		y:0.88660000000000005,
		supporter:"D"
	},
	{
		x:0.49790000000000001,
		y:0.90900000000000003,
		supporter:"D"
	},
	{
		x:0.52149999999999996,
		y:0.80649999999999999,
		supporter:"D"
	},
	{
		x:0.48320000000000002,
		y:0.93200000000000005,
		supporter:"D"
	},
	{
		x:0.4662,
		y:0.90759999999999996,
		supporter:"D"
	},
	{
		x:0.31,
		y:0.90369999999999995,
		supporter:"D"
	},
	{
		x:0.58130000000000004,
		y:0.86299999999999999,
		supporter:"D"
	},
	{
		x:0.5625,
		y:0.85450000000000004,
		supporter:"D"
	},
	{
		x:0.4924,
		y:0.92920000000000003,
		supporter:"D"
	},
	{
		x:0.52600000000000002,
		y:0.88190000000000002,
		supporter:"D"
	},
	{
		x:0.52629999999999999,
		y:0.86550000000000005,
		supporter:"D"
	},
	{
		x:0.4884,
		y:0.88180000000000003,
		supporter:"D"
	},
	{
		x:0.55379999999999996,
		y:0.87129999999999996,
		supporter:"D"
	},
	{
		x:0.50170000000000003,
		y:0.89410000000000001,
		supporter:"D"
	},
	{
		x:0.48070000000000002,
		y:0.88549999999999995,
		supporter:"D"
	},
	{
		x:0.39389999999999997,
		y:0.90959999999999996,
		supporter:"D"
	},
	{
		x:0.48609999999999998,
		y:0.9274,
		supporter:"D"
	},
	{
		x:0.49509999999999998,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.36799999999999999,
		y:0.89800000000000002,
		supporter:"D"
	},
	{
		x:0.58819999999999995,
		y:0.88090000000000002,
		supporter:"D"
	},
	{
		x:0.4194,
		y:0.88270000000000004,
		supporter:"D"
	},
	{
		x:0.4839,
		y:0.80600000000000005,
		supporter:"D"
	},
	{
		x:0.44379999999999997,
		y:0.92520000000000002,
		supporter:"D"
	},
	{
		x:0.33150000000000002,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.48159999999999997,
		y:0.91020000000000001,
		supporter:"D"
	},
	{
		x:0.43540000000000001,
		y:0.9254,
		supporter:"D"
	},
	{
		x:0.35759999999999997,
		y:0.89780000000000004,
		supporter:"D"
	},
	{
		x:0.4733,
		y:0.90039999999999998,
		supporter:"D"
	},
	{
		x:0.54049999999999998,
		y:0.87619999999999998,
		supporter:"D"
	},
	{
		x:0.30669999999999997,
		y:0.90329999999999999,
		supporter:"D"
	},
	{
		x:0.51859999999999995,
		y:0.89690000000000003,
		supporter:"D"
	},
	{
		x:0.48049999999999998,
		y:0.92759999999999998,
		supporter:"D"
	},
	{
		x:0.43380000000000002,
		y:0.92530000000000001,
		supporter:"D"
	},
	{
		x:0.3453,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.47760000000000002,
		y:0.88680000000000003,
		supporter:"D"
	},
	{
		x:0.4269,
		y:0.92989999999999995,
		supporter:"D"
	},
	{
		x:0.52790000000000004,
		y:0.80400000000000005,
		supporter:"D"
	},
	{
		x:0.47489999999999999,
		y:0.90310000000000001,
		supporter:"D"
	},
	{
		x:0.41470000000000001,
		y:0.86419999999999997,
		supporter:"D"
	},
	{
		x:0.60370000000000001,
		y:0.86729999999999996,
		supporter:"D"
	},
	{
		x:0.37690000000000001,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.2923,
		y:0.90290000000000004,
		supporter:"D"
	},
	{
		x:0.46200000000000002,
		y:0.92630000000000001,
		supporter:"D"
	},
	{
		x:0.49280000000000002,
		y:0.93430000000000002,
		supporter:"D"
	},
	{
		x:0.48659999999999998,
		y:0.89439999999999997,
		supporter:"D"
	},
	{
		x:0.3256,
		y:0.89370000000000005,
		supporter:"D"
	},
	{
		x:0.55989999999999995,
		y:0.87250000000000005,
		supporter:"D"
	},
	{
		x:0.40479999999999999,
		y:0.83919999999999995,
		supporter:"D"
	},
	{
		x:0.49919999999999998,
		y:0.90780000000000005,
		supporter:"D"
	},
	{
		x:0.33069999999999999,
		y:0.90900000000000003,
		supporter:"D"
	},
	{
		x:0.46929999999999999,
		y:0.89090000000000003,
		supporter:"D"
	},
	{
		x:0.40770000000000001,
		y:0.9,
		supporter:"D"
	},
	{
		x:0.39090000000000003,
		y:0.89880000000000004,
		supporter:"D"
	},
	{
		x:0.39660000000000001,
		y:0.88849999999999996,
		supporter:"D"
	},
	{
		x:0.59279999999999999,
		y:0.82879999999999998,
		supporter:"D"
	},
	{
		x:0.53600000000000003,
		y:0.879,
		supporter:"D"
	},
	{
		x:0.54469999999999996,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.37340000000000001,
		y:0.8488,
		supporter:"D"
	},
	{
		x:0.61339999999999995,
		y:0.79649999999999999,
		supporter:"D"
	},
	{
		x:0.60040000000000004,
		y:0.89410000000000001,
		supporter:"D"
	},
	{
		x:0.56759999999999999,
		y:0.85650000000000004,
		supporter:"D"
	},
	{
		x:0.49590000000000001,
		y:0.93159999999999998,
		supporter:"D"
	},
	{
		x:0.54679999999999995,
		y:0.89859999999999995,
		supporter:"D"
	},
	{
		x:0.48980000000000001,
		y:0.9284,
		supporter:"D"
	},
	{
		x:0.52639999999999998,
		y:0.86960000000000004,
		supporter:"D"
	},
	{
		x:0.4556,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.5968,
		y:0.87480000000000002,
		supporter:"D"
	},
	{
		x:0.46410000000000001,
		y:0.90700000000000003,
		supporter:"D"
	},
	{
		x:0.371,
		y:0.91239999999999999,
		supporter:"D"
	},
	{
		x:0.30449999999999999,
		y:0.90049999999999997,
		supporter:"D"
	},
	{
		x:0.43669999999999998,
		y:0.92589999999999995,
		supporter:"D"
	},
	{
		x:0.30909999999999999,
		y:0.90349999999999997,
		supporter:"D"
	},
	{
		x:0.4703,
		y:0.92649999999999999,
		supporter:"D"
	},
	{
		x:0.52569999999999995,
		y:0.89980000000000004,
		supporter:"D"
	},
	{
		x:0.61899999999999999,
		y:0.86560000000000004,
		supporter:"D"
	},
	{
		x:0.3352,
		y:0.91239999999999999,
		supporter:"D"
	},
	{
		x:0.4854,
		y:0.88849999999999996,
		supporter:"D"
	},
	{
		x:0.44269999999999998,
		y:0.92010000000000003,
		supporter:"D"
	},
	{
		x:0.39450000000000002,
		y:0.89739999999999998,
		supporter:"D"
	},
	{
		x:0.52500000000000002,
		y:0.89370000000000005,
		supporter:"D"
	},
	{
		x:0.49380000000000002,
		y:0.92249999999999999,
		supporter:"D"
	},
	{
		x:0.36009999999999998,
		y:0.9143,
		supporter:"D"
	},
	{
		x:0.30559999999999998,
		y:0.89739999999999998,
		supporter:"D"
	},
	{
		x:0.29349999999999998,
		y:0.90439999999999998,
		supporter:"D"
	},
	{
		x:0.49080000000000001,
		y:0.90090000000000003,
		supporter:"D"
	},
	{
		x:0.35849999999999999,
		y:0.90549999999999997,
		supporter:"D"
	},
	{
		x:0.62019999999999997,
		y:0.8165,
		supporter:"D"
	},
	{
		x:0.51449999999999996,
		y:0.85850000000000004,
		supporter:"D"
	},
	{
		x:0.42149999999999999,
		y:0.92069999999999996,
		supporter:"D"
	},
	{
		x:0.29570000000000002,
		y:0.91010000000000002,
		supporter:"D"
	},
	{
		x:0.43640000000000001,
		y:0.92720000000000002,
		supporter:"D"
	},
	{
		x:0.496,
		y:0.90139999999999998,
		supporter:"D"
	},
	{
		x:0.42359999999999998,
		y:0.90710000000000002,
		supporter:"D"
	},
	{
		x:0.45689999999999997,
		y:0.90559999999999996,
		supporter:"D"
	},
	{
		x:0.28289999999999998,
		y:0.90210000000000001,
		supporter:"D"
	},
	{
		x:0.51559999999999995,
		y:0.84950000000000003,
		supporter:"D"
	},
	{
		x:0.58499999999999996,
		y:0.8246,
		supporter:"D"
	},
	{
		x:0.39229999999999998,
		y:0.89029999999999998,
		supporter:"D"
	},
	{
		x:0.35120000000000001,
		y:0.90049999999999997,
		supporter:"D"
	},
	{
		x:0.41489999999999999,
		y:0.85760000000000003,
		supporter:"D"
	},
	{
		x:0.53180000000000005,
		y:0.86339999999999995,
		supporter:"D"
	},
	{
		x:0.48349999999999999,
		y:0.92589999999999995,
		supporter:"D"
	},
	{
		x:0.56920000000000004,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.30070000000000002,
		y:0.90369999999999995,
		supporter:"D"
	},
	{
		x:0.5726,
		y:0.8569,
		supporter:"D"
	},
	{
		x:0.38440000000000002,
		y:0.85809999999999997,
		supporter:"D"
	},
	{
		x:0.46,
		y:0.88770000000000004,
		supporter:"D"
	},
	{
		x:0.41970000000000002,
		y:0.92210000000000003,
		supporter:"D"
	},
	{
		x:0.59260000000000002,
		y:0.81489999999999996,
		supporter:"D"
	},
	{
		x:0.57069999999999999,
		y:0.86070000000000002,
		supporter:"D"
	},
	{
		x:0.55820000000000003,
		y:0.88149999999999995,
		supporter:"D"
	},
	{
		x:0.56100000000000005,
		y:0.87670000000000003,
		supporter:"D"
	},
	{
		x:0.33800000000000002,
		y:0.91090000000000004,
		supporter:"D"
	},
	{
		x:0.52200000000000002,
		y:0.89839999999999998,
		supporter:"D"
	},
	{
		x:0.49249999999999999,
		y:0.92130000000000001,
		supporter:"D"
	},
	{
		x:0.40550000000000003,
		y:0.84819999999999995,
		supporter:"D"
	},
	{
		x:0.51239999999999997,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.36899999999999999,
		y:0.91139999999999999,
		supporter:"D"
	},
	{
		x:0.4728,
		y:0.90439999999999998,
		supporter:"D"
	},
	{
		x:0.4481,
		y:0.92820000000000003,
		supporter:"D"
	},
	{
		x:0.49020000000000002,
		y:0.88200000000000001,
		supporter:"D"
	},
	{
		x:0.51449999999999996,
		y:0.86960000000000004,
		supporter:"D"
	},
	{
		x:0.3569,
		y:0.90569999999999995,
		supporter:"D"
	},
	{
		x:0.55389999999999995,
		y:0.89859999999999995,
		supporter:"D"
	},
	{
		x:0.46100000000000002,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.3009,
		y:0.89290000000000003,
		supporter:"D"
	},
	{
		x:0.4965,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.4839,
		y:0.92259999999999998,
		supporter:"D"
	},
	{
		x:0.5423,
		y:0.80079999999999996,
		supporter:"D"
	},
	{
		x:0.42109999999999997,
		y:0.89870000000000005,
		supporter:"D"
	},
	{
		x:0.29720000000000002,
		y:0.89959999999999996,
		supporter:"D"
	},
	{
		x:0.53990000000000005,
		y:0.87629999999999997,
		supporter:"D"
	},
	{
		x:0.4995,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.58199999999999996,
		y:0.88800000000000001,
		supporter:"D"
	},
	{
		x:0.31680000000000003,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.46729999999999999,
		y:0.92600000000000005,
		supporter:"D"
	},
	{
		x:0.31490000000000001,
		y:0.90190000000000003,
		supporter:"D"
	},
	{
		x:0.4622,
		y:0.92,
		supporter:"D"
	},
	{
		x:0.4819,
		y:0.90680000000000005,
		supporter:"D"
	},
	{
		x:0.32679999999999998,
		y:0.90739999999999998,
		supporter:"D"
	},
	{
		x:0.45400000000000001,
		y:0.92710000000000004,
		supporter:"D"
	},
	{
		x:0.49330000000000002,
		y:0.90049999999999997,
		supporter:"D"
	},
	{
		x:0.54530000000000001,
		y:0.88119999999999998,
		supporter:"D"
	},
	{
		x:0.53069999999999995,
		y:0.89749999999999996,
		supporter:"D"
	},
	{
		x:0.4945,
		y:0.87229999999999996,
		supporter:"D"
	},
	{
		x:0.47149999999999997,
		y:0.92630000000000001,
		supporter:"D"
	},
	{
		x:0.48749999999999999,
		y:0.80279999999999996,
		supporter:"D"
	},
	{
		x:0.43359999999999999,
		y:0.91610000000000003,
		supporter:"D"
	},
	{
		x:0.53539999999999999,
		y:0.89139999999999997,
		supporter:"D"
	},
	{
		x:0.53990000000000005,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.49930000000000002,
		y:0.90959999999999996,
		supporter:"D"
	},
	{
		x:0.48010000000000003,
		y:0.9052,
		supporter:"D"
	},
	{
		x:0.443,
		y:0.92079999999999995,
		supporter:"D"
	},
	{
		x:0.3014,
		y:0.91059999999999997,
		supporter:"D"
	},
	{
		x:0.51619999999999999,
		y:0.89990000000000003,
		supporter:"D"
	},
	{
		x:0.53859999999999997,
		y:0.85740000000000005,
		supporter:"D"
	},
	{
		x:0.54890000000000005,
		y:0.86099999999999999,
		supporter:"D"
	},
	{
		x:0.35210000000000002,
		y:0.91390000000000005,
		supporter:"D"
	},
	{
		x:0.57479999999999998,
		y:0.81640000000000001,
		supporter:"D"
	},
	{
		x:0.30259999999999998,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.46179999999999999,
		y:0.92359999999999998,
		supporter:"D"
	},
	{
		x:0.3029,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.3226,
		y:0.90780000000000005,
		supporter:"D"
	},
	{
		x:0.49830000000000002,
		y:0.90210000000000001,
		supporter:"D"
	},
	{
		x:0.62560000000000004,
		y:0.82579999999999998,
		supporter:"D"
	},
	{
		x:0.38340000000000002,
		y:0.85429999999999995,
		supporter:"D"
	},
	{
		x:0.45079999999999998,
		y:0.93130000000000002,
		supporter:"D"
	},
	{
		x:0.4662,
		y:0.92330000000000001,
		supporter:"D"
	},
	{
		x:0.49530000000000002,
		y:0.9224,
		supporter:"D"
	},
	{
		x:0.57330000000000003,
		y:0.85509999999999997,
		supporter:"D"
	},
	{
		x:0.42799999999999999,
		y:0.85560000000000003,
		supporter:"D"
	},
	{
		x:0.53580000000000005,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.32169999999999999,
		y:0.90939999999999999,
		supporter:"D"
	},
	{
		x:0.62290000000000001,
		y:0.89990000000000003,
		supporter:"D"
	},
	{
		x:0.42330000000000001,
		y:0.90600000000000003,
		supporter:"D"
	},
	{
		x:0.34370000000000001,
		y:0.90149999999999997,
		supporter:"D"
	},
	{
		x:0.48620000000000002,
		y:0.92410000000000003,
		supporter:"D"
	},
	{
		x:0.4854,
		y:0.90869999999999995,
		supporter:"D"
	},
	{
		x:0.5272,
		y:0.87409999999999999,
		supporter:"D"
	},
	{
		x:0.31209999999999999,
		y:0.90620000000000001,
		supporter:"D"
	},
	{
		x:0.32340000000000002,
		y:0.90439999999999998,
		supporter:"D"
	},
	{
		x:0.3679,
		y:0.89659999999999995,
		supporter:"D"
	},
	{
		x:0.35039999999999999,
		y:0.91449999999999998,
		supporter:"D"
	},
	{
		x:0.47810000000000002,
		y:0.92679999999999996,
		supporter:"D"
	},
	{
		x:0.44240000000000002,
		y:0.89470000000000005,
		supporter:"D"
	},
	{
		x:0.3352,
		y:0.91059999999999997,
		supporter:"D"
	},
	{
		x:0.62829999999999997,
		y:0.88829999999999998,
		supporter:"D"
	},
	{
		x:0.499,
		y:0.91579999999999995,
		supporter:"D"
	},
	{
		x:0.54459999999999997,
		y:0.84019999999999995,
		supporter:"D"
	},
	{
		x:0.56020000000000003,
		y:0.85070000000000001,
		supporter:"D"
	},
	{
		x:0.49480000000000002,
		y:0.91090000000000004,
		supporter:"D"
	},
	{
		x:0.45350000000000001,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.32600000000000001,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.58520000000000005,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.47949999999999998,
		y:0.92910000000000004,
		supporter:"D"
	},
	{
		x:0.54669999999999996,
		y:0.87350000000000005,
		supporter:"D"
	},
	{
		x:0.55430000000000001,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.56699999999999995,
		y:0.89539999999999997,
		supporter:"D"
	},
	{
		x:0.53359999999999996,
		y:0.87849999999999995,
		supporter:"D"
	},
	{
		x:0.46929999999999999,
		y:0.90329999999999999,
		supporter:"D"
	},
	{
		x:0.41299999999999998,
		y:0.89170000000000005,
		supporter:"D"
	},
	{
		x:0.44069999999999998,
		y:0.92130000000000001,
		supporter:"D"
	},
	{
		x:0.34720000000000001,
		y:0.91420000000000001,
		supporter:"D"
	},
	{
		x:0.4879,
		y:0.89980000000000004,
		supporter:"D"
	},
	{
		x:0.5696,
		y:0.81069999999999998,
		supporter:"D"
	},
	{
		x:0.61209999999999998,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.53890000000000005,
		y:0.87549999999999994,
		supporter:"D"
	},
	{
		x:0.38019999999999998,
		y:0.89219999999999999,
		supporter:"D"
	},
	{
		x:0.44840000000000002,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.499,
		y:0.88870000000000005,
		supporter:"D"
	},
	{
		x:0.34370000000000001,
		y:0.90169999999999995,
		supporter:"D"
	},
	{
		x:0.49490000000000001,
		y:0.91910000000000003,
		supporter:"D"
	},
	{
		x:0.57130000000000003,
		y:0.87870000000000004,
		supporter:"D"
	},
	{
		x:0.35360000000000003,
		y:0.89859999999999995,
		supporter:"D"
	},
	{
		x:0.33289999999999997,
		y:0.90849999999999997,
		supporter:"D"
	},
	{
		x:0.37169999999999997,
		y:0.90639999999999998,
		supporter:"D"
	},
	{
		x:0.54610000000000003,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.54569999999999996,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.38569999999999999,
		y:0.90059999999999996,
		supporter:"D"
	},
	{
		x:0.36930000000000002,
		y:0.89419999999999999,
		supporter:"D"
	},
	{
		x:0.3574,
		y:0.91459999999999997,
		supporter:"D"
	},
	{
		x:0.51270000000000004,
		y:0.87549999999999994,
		supporter:"D"
	},
	{
		x:0.44900000000000001,
		y:0.9244,
		supporter:"D"
	},
	{
		x:0.43930000000000002,
		y:0.91569999999999996,
		supporter:"D"
	},
	{
		x:0.49359999999999998,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.47149999999999997,
		y:0.85009999999999997,
		supporter:"D"
	},
	{
		x:0.4451,
		y:0.92879999999999996,
		supporter:"D"
	},
	{
		x:0.57720000000000005,
		y:0.88700000000000001,
		supporter:"D"
	},
	{
		x:0.57469999999999999,
		y:0.8518,
		supporter:"D"
	},
	{
		x:0.50949999999999995,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.55579999999999996,
		y:0.87729999999999997,
		supporter:"D"
	},
	{
		x:0.49409999999999998,
		y:0.90759999999999996,
		supporter:"D"
	},
	{
		x:0.43959999999999999,
		y:0.92759999999999998,
		supporter:"D"
	},
	{
		x:0.36009999999999998,
		y:0.90380000000000005,
		supporter:"D"
	},
	{
		x:0.57840000000000003,
		y:0.85680000000000001,
		supporter:"D"
	},
	{
		x:0.38940000000000002,
		y:0.91110000000000002,
		supporter:"D"
	},
	{
		x:0.55789999999999995,
		y:0.87590000000000001,
		supporter:"D"
	},
	{
		x:0.4929,
		y:0.89090000000000003,
		supporter:"D"
	},
	{
		x:0.3634,
		y:0.90559999999999996,
		supporter:"D"
	},
	{
		x:0.29070000000000001,
		y:0.90869999999999995,
		supporter:"D"
	},
	{
		x:0.33539999999999998,
		y:0.91020000000000001,
		supporter:"D"
	},
	{
		x:0.61629999999999996,
		y:0.80579999999999996,
		supporter:"D"
	},
	{
		x:0.34649999999999997,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.56920000000000004,
		y:0.89900000000000002,
		supporter:"D"
	},
	{
		x:0.61370000000000002,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.45119999999999999,
		y:0.90739999999999998,
		supporter:"D"
	},
	{
		x:0.42980000000000002,
		y:0.92659999999999998,
		supporter:"D"
	},
	{
		x:0.32479999999999998,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.56589999999999996,
		y:0.87539999999999996,
		supporter:"D"
	},
	{
		x:0.44840000000000002,
		y:0.91969999999999996,
		supporter:"D"
	},
	{
		x:0.35780000000000001,
		y:0.90539999999999998,
		supporter:"D"
	},
	{
		x:0.4456,
		y:0.92820000000000003,
		supporter:"D"
	},
	{
		x:0.47689999999999999,
		y:0.92530000000000001,
		supporter:"D"
	},
	{
		x:0.47920000000000001,
		y:0.89980000000000004,
		supporter:"D"
	},
	{
		x:0.35899999999999999,
		y:0.89900000000000002,
		supporter:"D"
	},
	{
		x:0.46100000000000002,
		y:0.9284,
		supporter:"D"
	},
	{
		x:0.59589999999999999,
		y:0.86480000000000001,
		supporter:"D"
	},
	{
		x:0.45660000000000001,
		y:0.88009999999999999,
		supporter:"D"
	},
	{
		x:0.59309999999999996,
		y:0.88149999999999995,
		supporter:"D"
	},
	{
		x:0.49740000000000001,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.45479999999999998,
		y:0.91149999999999998,
		supporter:"D"
	},
	{
		x:0.47339999999999999,
		y:0.91949999999999998,
		supporter:"D"
	},
	{
		x:0.61050000000000004,
		y:0.87290000000000001,
		supporter:"D"
	},
	{
		x:0.35370000000000001,
		y:0.90810000000000002,
		supporter:"D"
	},
	{
		x:0.34570000000000001,
		y:0.90710000000000002,
		supporter:"D"
	},
	{
		x:0.36030000000000001,
		y:0.90300000000000002,
		supporter:"D"
	},
	{
		x:0.50329999999999997,
		y:0.89,
		supporter:"D"
	},
	{
		x:0.34250000000000003,
		y:0.91090000000000004,
		supporter:"D"
	},
	{
		x:0.50860000000000005,
		y:0.85060000000000002,
		supporter:"D"
	},
	{
		x:0.58789999999999998,
		y:0.77070000000000005,
		supporter:"D"
	},
	{
		x:0.37719999999999998,
		y:0.84630000000000005,
		supporter:"D"
	},
	{
		x:0.57609999999999995,
		y:0.89449999999999996,
		supporter:"D"
	},
	{
		x:0.49,
		y:0.9133,
		supporter:"D"
	},
	{
		x:0.29060000000000002,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.38129999999999997,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.49769999999999998,
		y:0.91849999999999998,
		supporter:"D"
	},
	{
		x:0.47099999999999997,
		y:0.9113,
		supporter:"D"
	},
	{
		x:0.49309999999999998,
		y:0.91169999999999995,
		supporter:"D"
	},
	{
		x:0.41820000000000002,
		y:0.92520000000000002,
		supporter:"D"
	},
	{
		x:0.55269999999999997,
		y:0.89610000000000001,
		supporter:"D"
	},
	{
		x:0.38,
		y:0.91020000000000001,
		supporter:"D"
	},
	{
		x:0.4279,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.59419999999999995,
		y:0.86329999999999996,
		supporter:"D"
	},
	{
		x:0.40010000000000001,
		y:0.85250000000000004,
		supporter:"D"
	},
	{
		x:0.50800000000000001,
		y:0.84799999999999998,
		supporter:"D"
	},
	{
		x:0.46939999999999998,
		y:0.9173,
		supporter:"D"
	},
	{
		x:0.28760000000000002,
		y:0.8982,
		supporter:"D"
	},
	{
		x:0.56289999999999996,
		y:0.86850000000000005,
		supporter:"D"
	},
	{
		x:0.44819999999999999,
		y:0.91159999999999997,
		supporter:"D"
	},
	{
		x:0.46589999999999998,
		y:0.90190000000000003,
		supporter:"D"
	},
	{
		x:0.34329999999999999,
		y:0.91180000000000005,
		supporter:"D"
	},
	{
		x:0.38690000000000002,
		y:0.91169999999999995,
		supporter:"D"
	},
	{
		x:0.41660000000000003,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.49709999999999999,
		y:0.92020000000000002,
		supporter:"D"
	},
	{
		x:0.53849999999999998,
		y:0.89259999999999995,
		supporter:"D"
	},
	{
		x:0.43959999999999999,
		y:0.80600000000000005,
		supporter:"D"
	},
	{
		x:0.40010000000000001,
		y:0.89280000000000004,
		supporter:"D"
	},
	{
		x:0.53969999999999996,
		y:0.87390000000000001,
		supporter:"D"
	},
	{
		x:0.45679999999999998,
		y:0.90669999999999995,
		supporter:"D"
	},
	{
		x:0.45429999999999998,
		y:0.91100000000000003,
		supporter:"D"
	},
	{
		x:0.47710000000000002,
		y:0.93140000000000001,
		supporter:"D"
	},
	{
		x:0.37069999999999997,
		y:0.90600000000000003,
		supporter:"D"
	},
	{
		x:0.42780000000000001,
		y:0.92049999999999998,
		supporter:"D"
	},
	{
		x:0.51639999999999997,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.44729999999999998,
		y:0.86429999999999996,
		supporter:"D"
	},
	{
		x:0.62450000000000006,
		y:0.88690000000000002,
		supporter:"D"
	},
	{
		x:0.48430000000000001,
		y:0.86939999999999995,
		supporter:"D"
	},
	{
		x:0.46689999999999998,
		y:0.91490000000000005,
		supporter:"D"
	},
	{
		x:0.41399999999999998,
		y:0.8679,
		supporter:"D"
	},
	{
		x:0.28860000000000002,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.33489999999999998,
		y:0.9032,
		supporter:"D"
	},
	{
		x:0.56130000000000002,
		y:0.81540000000000001,
		supporter:"D"
	},
	{
		x:0.47710000000000002,
		y:0.93110000000000004,
		supporter:"D"
	},
	{
		x:0.33110000000000001,
		y:0.89500000000000002,
		supporter:"D"
	},
	{
		x:0.46010000000000001,
		y:0.93610000000000004,
		supporter:"D"
	},
	{
		x:0.3795,
		y:0.85670000000000002,
		supporter:"D"
	},
	{
		x:0.41449999999999998,
		y:0.90529999999999999,
		supporter:"D"
	},
	{
		x:0.54159999999999997,
		y:0.87949999999999995,
		supporter:"D"
	},
	{
		x:0.4446,
		y:0.91900000000000004,
		supporter:"D"
	},
	{
		x:0.48349999999999999,
		y:0.92830000000000001,
		supporter:"D"
	},
	{
		x:0.4632,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.47810000000000002,
		y:0.92420000000000002,
		supporter:"D"
	},
	{
		x:0.55549999999999999,
		y:0.88329999999999997,
		supporter:"D"
	},
	{
		x:0.38719999999999999,
		y:0.90139999999999998,
		supporter:"D"
	},
	{
		x:0.44419999999999998,
		y:0.92959999999999998,
		supporter:"D"
	},
	{
		x:0.39660000000000001,
		y:0.86250000000000004,
		supporter:"D"
	},
	{
		x:0.45390000000000003,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.43380000000000002,
		y:0.89270000000000005,
		supporter:"D"
	},
	{
		x:0.36699999999999999,
		y:0.84609999999999996,
		supporter:"D"
	},
	{
		x:0.34510000000000002,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.56810000000000005,
		y:0.85060000000000002,
		supporter:"D"
	},
	{
		x:0.32990000000000003,
		y:0.90769999999999995,
		supporter:"D"
	},
	{
		x:0.34599999999999997,
		y:0.91139999999999999,
		supporter:"D"
	},
	{
		x:0.51680000000000004,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.4012,
		y:0.9103,
		supporter:"D"
	},
	{
		x:0.51910000000000001,
		y:0.89390000000000003,
		supporter:"D"
	},
	{
		x:0.44,
		y:0.8962,
		supporter:"D"
	},
	{
		x:0.5161,
		y:0.89670000000000005,
		supporter:"D"
	},
	{
		x:0.4572,
		y:0.92430000000000001,
		supporter:"D"
	},
	{
		x:0.35560000000000003,
		y:0.9042,
		supporter:"D"
	},
	{
		x:0.48320000000000002,
		y:0.92179999999999995,
		supporter:"D"
	},
	{
		x:0.54500000000000004,
		y:0.87490000000000001,
		supporter:"D"
	},
	{
		x:0.3377,
		y:0.90400000000000003,
		supporter:"D"
	},
	{
		x:0.30959999999999999,
		y:0.90710000000000002,
		supporter:"D"
	},
	{
		x:0.45829999999999999,
		y:0.92269999999999996,
		supporter:"D"
	},
	{
		x:0.48709999999999998,
		y:0.9214,
		supporter:"D"
	},
	{
		x:0.48430000000000001,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.45429999999999998,
		y:0.93910000000000005,
		supporter:"D"
	},
	{
		x:0.55959999999999999,
		y:0.87980000000000003,
		supporter:"D"
	},
	{
		x:0.41160000000000002,
		y:0.90129999999999999,
		supporter:"D"
	},
	{
		x:0.39579999999999999,
		y:0.91559999999999997,
		supporter:"D"
	},
	{
		x:0.36609999999999998,
		y:0.90610000000000002,
		supporter:"D"
	},
	{
		x:0.33710000000000001,
		y:0.90359999999999996,
		supporter:"D"
	},
	{
		x:0.37109999999999999,
		y:0.90449999999999997,
		supporter:"D"
	},
	{
		x:0.50029999999999997,
		y:0.8901,
		supporter:"D"
	},
	{
		x:0.3352,
		y:0.90159999999999996,
		supporter:"D"
	},
	{
		x:0.52139999999999997,
		y:0.88849999999999996,
		supporter:"D"
	},
	{
		x:0.48110000000000003,
		y:0.92490000000000006,
		supporter:"D"
	},
	{
		x:0.53129999999999999,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.30780000000000002,
		y:0.90610000000000002,
		supporter:"D"
	},
	{
		x:0.32629999999999998,
		y:0.90969999999999995,
		supporter:"D"
	},
	{
		x:0.51770000000000005,
		y:0.89039999999999997,
		supporter:"D"
	},
	{
		x:0.4965,
		y:0.92789999999999995,
		supporter:"D"
	},
	{
		x:0.2838,
		y:0.90800000000000003,
		supporter:"D"
	},
	{
		x:0.52239999999999998,
		y:0.88949999999999996,
		supporter:"D"
	},
	{
		x:0.45600000000000002,
		y:0.90910000000000002,
		supporter:"D"
	},
	{
		x:0.57579999999999998,
		y:0.84619999999999995,
		supporter:"D"
	},
	{
		x:0.3175,
		y:0.90190000000000003,
		supporter:"D"
	},
	{
		x:0.4743,
		y:0.92020000000000002,
		supporter:"D"
	},
	{
		x:0.41449999999999998,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.48520000000000002,
		y:0.93169999999999997,
		supporter:"D"
	},
	{
		x:0.49559999999999998,
		y:0.9244,
		supporter:"D"
	},
	{
		x:0.3785,
		y:0.85150000000000003,
		supporter:"D"
	},
	{
		x:0.29520000000000002,
		y:0.90249999999999997,
		supporter:"D"
	},
	{
		x:0.6179,
		y:0.78380000000000005,
		supporter:"D"
	},
	{
		x:0.40970000000000001,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.61909999999999998,
		y:0.78510000000000002,
		supporter:"D"
	},
	{
		x:0.504,
		y:0.89900000000000002,
		supporter:"D"
	},
	{
		x:0.31909999999999999,
		y:0.9022,
		supporter:"D"
	},
	{
		x:0.2918,
		y:0.89129999999999998,
		supporter:"D"
	},
	{
		x:0.46389999999999998,
		y:0.88490000000000002,
		supporter:"D"
	},
	{
		x:0.53510000000000002,
		y:0.87390000000000001,
		supporter:"D"
	},
	{
		x:0.3029,
		y:0.90490000000000004,
		supporter:"D"
	},
	{
		x:0.38529999999999998,
		y:0.85580000000000001,
		supporter:"D"
	},
	{
		x:0.41649999999999998,
		y:0.92510000000000003,
		supporter:"D"
	},
	{
		x:0.31159999999999999,
		y:0.90159999999999996,
		supporter:"D"
	},
	{
		x:0.40679999999999999,
		y:0.85450000000000004,
		supporter:"D"
	},
	{
		x:0.55579999999999996,
		y:0.85729999999999995,
		supporter:"D"
	},
	{
		x:0.54690000000000005,
		y:0.89339999999999997,
		supporter:"D"
	},
	{
		x:0.34739999999999999,
		y:0.90639999999999998,
		supporter:"D"
	},
	{
		x:0.5595,
		y:0.87570000000000003,
		supporter:"D"
	},
	{
		x:0.34860000000000002,
		y:0.90880000000000005,
		supporter:"D"
	},
	{
		x:0.50929999999999997,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.32869999999999999,
		y:0.90459999999999996,
		supporter:"D"
	},
	{
		x:0.30170000000000002,
		y:0.90759999999999996,
		supporter:"D"
	},
	{
		x:0.49890000000000001,
		y:0.90910000000000002,
		supporter:"D"
	},
	{
		x:0.4662,
		y:0.90500000000000003,
		supporter:"D"
	},
	{
		x:0.50160000000000005,
		y:0.86270000000000002,
		supporter:"D"
	},
	{
		x:0.53859999999999997,
		y:0.89800000000000002,
		supporter:"D"
	},
	{
		x:0.48180000000000001,
		y:0.88390000000000002,
		supporter:"D"
	},
	{
		x:0.47960000000000003,
		y:0.81240000000000001,
		supporter:"D"
	},
	{
		x:0.4894,
		y:0.89180000000000004,
		supporter:"D"
	},
	{
		x:0.30370000000000003,
		y:0.90229999999999999,
		supporter:"D"
	},
	{
		x:0.47020000000000001,
		y:0.92720000000000002,
		supporter:"D"
	},
	{
		x:0.45300000000000001,
		y:0.92889999999999995,
		supporter:"D"
	},
	{
		x:0.5343,
		y:0.88829999999999998,
		supporter:"D"
	},
	{
		x:0.58450000000000002,
		y:0.80200000000000005,
		supporter:"D"
	},
	{
		x:0.56679999999999997,
		y:0.8911,
		supporter:"D"
	},
	{
		x:0.41980000000000001,
		y:0.91620000000000001,
		supporter:"D"
	},
	{
		x:0.44529999999999997,
		y:0.92920000000000003,
		supporter:"D"
	},
	{
		x:0.54859999999999998,
		y:0.89600000000000002,
		supporter:"D"
	},
	{
		x:0.44869999999999999,
		y:0.9103,
		supporter:"D"
	},
	{
		x:0.4229,
		y:0.92610000000000003,
		supporter:"D"
	},
	{
		x:0.31480000000000002,
		y:0.90639999999999998,
		supporter:"D"
	},
	{
		x:0.54569999999999996,
		y:0.86839999999999995,
		supporter:"D"
	},
	{
		x:0.52259999999999995,
		y:0.89429999999999998,
		supporter:"D"
	},
	{
		x:0.53769999999999996,
		y:0.8417,
		supporter:"D"
	},
	{
		x:0.50419999999999998,
		y:0.83450000000000002,
		supporter:"D"
	},
	{
		x:0.58020000000000005,
		y:0.85950000000000004,
		supporter:"D"
	},
	{
		x:0.52300000000000002,
		y:0.88170000000000004,
		supporter:"D"
	},
	{
		x:0.38819999999999999,
		y:0.83730000000000004,
		supporter:"D"
	},
	{
		x:0.31259999999999999,
		y:0.90190000000000003,
		supporter:"D"
	},
	{
		x:0.50900000000000001,
		y:0.88990000000000002,
		supporter:"D"
	},
	{
		x:0.5151,
		y:0.82250000000000001,
		supporter:"D"
	},
	{
		x:0.44779999999999998,
		y:0.92589999999999995,
		supporter:"D"
	},
	{
		x:0.49959999999999999,
		y:0.89049999999999996,
		supporter:"D"
	},
	{
		x:0.58640000000000003,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.49299999999999999,
		y:0.86680000000000001,
		supporter:"D"
	},
	{
		x:0.29580000000000001,
		y:0.90129999999999999,
		supporter:"D"
	},
	{
		x:0.48330000000000001,
		y:0.89690000000000003,
		supporter:"D"
	},
	{
		x:0.53469999999999995,
		y:0.88670000000000004,
		supporter:"D"
	},
	{
		x:0.54400000000000004,
		y:0.89190000000000003,
		supporter:"D"
	},
	{
		x:0.33289999999999997,
		y:0.9093,
		supporter:"D"
	},
	{
		x:0.57709999999999995,
		y:0.84419999999999995,
		supporter:"D"
	},
	{
		x:0.48199999999999998,
		y:0.89800000000000002,
		supporter:"D"
	},
	{
		x:0.55649999999999999,
		y:0.85050000000000003,
		supporter:"D"
	},
	{
		x:0.32979999999999998,
		y:0.90580000000000005,
		supporter:"D"
	},
	{
		x:0.53739999999999999,
		y:0.79579999999999995,
		supporter:"D"
	},
	{
		x:0.37769999999999998,
		y:0.90229999999999999,
		supporter:"D"
	},
	{
		x:0.32740000000000002,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.47260000000000002,
		y:0.84050000000000002,
		supporter:"D"
	},
	{
		x:0.42449999999999999,
		y:0.92510000000000003,
		supporter:"D"
	},
	{
		x:0.46200000000000002,
		y:0.92100000000000004,
		supporter:"D"
	},
	{
		x:0.51459999999999995,
		y:0.89349999999999996,
		supporter:"D"
	},
	{
		x:0.4854,
		y:0.92879999999999996,
		supporter:"D"
	},
	{
		x:0.3967,
		y:0.90349999999999997,
		supporter:"D"
	},
	{
		x:0.34799999999999998,
		y:0.90969999999999995,
		supporter:"D"
	},
	{
		x:0.4924,
		y:0.92320000000000002,
		supporter:"D"
	},
	{
		x:0.47639999999999999,
		y:0.79600000000000004,
		supporter:"D"
	},
	{
		x:0.52470000000000006,
		y:0.89080000000000004,
		supporter:"D"
	},
	{
		x:0.36430000000000001,
		y:0.90559999999999996,
		supporter:"D"
	},
	{
		x:0.41120000000000001,
		y:0.90959999999999996,
		supporter:"D"
	},
	{
		x:0.42620000000000002,
		y:0.86619999999999997,
		supporter:"D"
	},
	{
		x:0.56320000000000003,
		y:0.89170000000000005,
		supporter:"D"
	},
	{
		x:0.51359999999999995,
		y:0.88539999999999996,
		supporter:"D"
	},
	{
		x:0.4783,
		y:0.81,
		supporter:"D"
	},
	{
		x:0.34439999999999998,
		y:0.90639999999999998,
		supporter:"D"
	},
	{
		x:0.4637,
		y:0.8851,
		supporter:"D"
	},
	{
		x:0.47439999999999999,
		y:0.92410000000000003,
		supporter:"D"
	},
	{
		x:0.33910000000000001,
		y:0.90920000000000001,
		supporter:"D"
	},
	{
		x:0.502,
		y:0.88829999999999998,
		supporter:"D"
	},
	{
		x:0.53510000000000002,
		y:0.89259999999999995,
		supporter:"D"
	},
	{
		x:0.377,
		y:0.90710000000000002,
		supporter:"D"
	},
	{
		x:0.49380000000000002,
		y:0.92249999999999999,
		supporter:"D"
	},
	{
		x:0.52329999999999999,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.46439999999999998,
		y:0.92490000000000006,
		supporter:"D"
	},
	{
		x:0.38390000000000002,
		y:0.85299999999999998,
		supporter:"D"
	},
	{
		x:0.62080000000000002,
		y:0.79800000000000004,
		supporter:"D"
	},
	{
		x:0.47670000000000001,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.44069999999999998,
		y:0.92220000000000002,
		supporter:"D"
	},
	{
		x:0.47989999999999999,
		y:0.88280000000000003,
		supporter:"D"
	},
	{
		x:0.50780000000000003,
		y:0.88890000000000002,
		supporter:"D"
	},
	{
		x:0.54520000000000002,
		y:0.87180000000000002,
		supporter:"D"
	},
	{
		x:0.30249999999999999,
		y:0.89800000000000002,
		supporter:"D"
	},
	{
		x:0.48359999999999997,
		y:0.92830000000000001,
		supporter:"D"
	},
	{
		x:0.28670000000000001,
		y:0.89539999999999997,
		supporter:"D"
	},
	{
		x:0.54079999999999995,
		y:0.878,
		supporter:"D"
	},
	{
		x:0.4718,
		y:0.90549999999999997,
		supporter:"D"
	},
	{
		x:0.54759999999999998,
		y:0.85650000000000004,
		supporter:"D"
	},
	{
		x:0.49109999999999998,
		y:0.90939999999999999,
		supporter:"D"
	},
	{
		x:0.39700000000000002,
		y:0.85599999999999998,
		supporter:"D"
	},
	{
		x:0.47470000000000001,
		y:0.90800000000000003,
		supporter:"D"
	},
	{
		x:0.53159999999999996,
		y:0.877,
		supporter:"D"
	},
	{
		x:0.44800000000000001,
		y:0.91110000000000002,
		supporter:"D"
	},
	{
		x:0.33800000000000002,
		y:0.90669999999999995,
		supporter:"D"
	},
	{
		x:0.31659999999999999,
		y:0.90969999999999995,
		supporter:"D"
	},
	{
		x:0.46439999999999998,
		y:0.90680000000000005,
		supporter:"D"
	},
	{
		x:0.4521,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.43319999999999997,
		y:0.89500000000000002,
		supporter:"D"
	},
	{
		x:0.40029999999999999,
		y:0.85629999999999995,
		supporter:"D"
	},
	{
		x:0.52339999999999998,
		y:0.86719999999999997,
		supporter:"D"
	},
	{
		x:0.35620000000000002,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.41520000000000001,
		y:0.90990000000000004,
		supporter:"D"
	},
	{
		x:0.38390000000000002,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.44600000000000001,
		y:0.92510000000000003,
		supporter:"D"
	},
	{
		x:0.44750000000000001,
		y:0.92359999999999998,
		supporter:"D"
	},
	{
		x:0.46610000000000001,
		y:0.89049999999999996,
		supporter:"D"
	},
	{
		x:0.29139999999999999,
		y:0.90069999999999995,
		supporter:"D"
	},
	{
		x:0.49,
		y:0.86829999999999996,
		supporter:"D"
	},
	{
		x:0.50329999999999997,
		y:0.89129999999999998,
		supporter:"D"
	},
	{
		x:0.44519999999999998,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.49930000000000002,
		y:0.9214,
		supporter:"D"
	},
	{
		x:0.49859999999999999,
		y:0.89200000000000002,
		supporter:"D"
	},
	{
		x:0.45150000000000001,
		y:0.85519999999999996,
		supporter:"D"
	},
	{
		x:0.56410000000000005,
		y:0.87639999999999996,
		supporter:"D"
	},
	{
		x:0.39079999999999998,
		y:0.91190000000000004,
		supporter:"D"
	},
	{
		x:0.3216,
		y:0.90959999999999996,
		supporter:"D"
	},
	{
		x:0.60770000000000002,
		y:0.79069999999999996,
		supporter:"D"
	},
	{
		x:0.41470000000000001,
		y:0.91080000000000005,
		supporter:"D"
	},
	{
		x:0.50380000000000003,
		y:0.88139999999999996,
		supporter:"D"
	},
	{
		x:0.45229999999999998,
		y:0.91859999999999997,
		supporter:"D"
	},
	{
		x:0.44290000000000002,
		y:0.93200000000000005,
		supporter:"D"
	},
	{
		x:0.38190000000000002,
		y:0.91090000000000004,
		supporter:"D"
	},
	{
		x:0.62129999999999996,
		y:0.8629,
		supporter:"D"
	},
	{
		x:0.57099999999999995,
		y:0.87719999999999998,
		supporter:"D"
	},
	{
		x:0.28289999999999998,
		y:0.90410000000000001,
		supporter:"D"
	},
	{
		x:0.4965,
		y:0.90949999999999998,
		supporter:"D"
	},
	{
		x:0.4713,
		y:0.92530000000000001,
		supporter:"D"
	},
	{
		x:0.38450000000000001,
		y:0.8508,
		supporter:"D"
	},
	{
		x:0.61519999999999997,
		y:0.878,
		supporter:"D"
	},
	{
		x:0.47989999999999999,
		y:0.88749999999999996,
		supporter:"D"
	},
	{
		x:0.32919999999999999,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.57979999999999998,
		y:0.8306,
		supporter:"D"
	},
	{
		x:0.49559999999999998,
		y:0.91049999999999998,
		supporter:"D"
	},
	{
		x:0.372,
		y:0.85509999999999997,
		supporter:"D"
	},
	{
		x:0.52400000000000002,
		y:0.89090000000000003,
		supporter:"D"
	},
	{
		x:0.4723,
		y:0.8992,
		supporter:"D"
	},
	{
		x:0.51690000000000003,
		y:0.87409999999999999,
		supporter:"D"
	},
	{
		x:0.58179999999999998,
		y:0.85660000000000003,
		supporter:"D"
	},
	{
		x:0.53769999999999996,
		y:0.88949999999999996,
		supporter:"D"
	},
	{
		x:0.50049999999999994,
		y:0.84750000000000003,
		supporter:"D"
	},
	{
		x:0.42430000000000001,
		y:0.88129999999999997,
		supporter:"D"
	},
	{
		x:0.54369999999999996,
		y:0.81740000000000002,
		supporter:"D"
	},
	{
		x:0.499,
		y:0.91190000000000004,
		supporter:"D"
	},
	{
		x:0.39079999999999998,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.44890000000000002,
		y:0.89,
		supporter:"D"
	},
	{
		x:0.53990000000000005,
		y:0.89390000000000003,
		supporter:"D"
	},
	{
		x:0.44190000000000002,
		y:0.92069999999999996,
		supporter:"D"
	},
	{
		x:0.30630000000000002,
		y:0.90059999999999996,
		supporter:"D"
	},
	{
		x:0.54339999999999999,
		y:0.89219999999999999,
		supporter:"D"
	},
	{
		x:0.56950000000000001,
		y:0.86780000000000002,
		supporter:"D"
	},
	{
		x:0.34660000000000002,
		y:0.90880000000000005,
		supporter:"D"
	},
	{
		x:0.28560000000000002,
		y:0.89510000000000001,
		supporter:"D"
	},
	{
		x:0.40670000000000001,
		y:0.91190000000000004,
		supporter:"D"
	},
	{
		x:0.57630000000000003,
		y:0.89690000000000003,
		supporter:"D"
	},
	{
		x:0.4677,
		y:0.90249999999999997,
		supporter:"D"
	},
	{
		x:0.50029999999999997,
		y:0.89549999999999996,
		supporter:"D"
	},
	{
		x:0.55410000000000004,
		y:0.86280000000000001,
		supporter:"D"
	},
	{
		x:0.35599999999999998,
		y:0.90880000000000005,
		supporter:"D"
	},
	{
		x:0.32950000000000002,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.47760000000000002,
		y:0.93,
		supporter:"D"
	},
	{
		x:0.55559999999999998,
		y:0.87970000000000004,
		supporter:"D"
	},
	{
		x:0.44130000000000003,
		y:0.91549999999999998,
		supporter:"D"
	},
	{
		x:0.55589999999999995,
		y:0.87250000000000005,
		supporter:"D"
	},
	{
		x:0.59650000000000003,
		y:0.78480000000000005,
		supporter:"D"
	},
	{
		x:0.56869999999999998,
		y:0.81840000000000002,
		supporter:"D"
	},
	{
		x:0.38879999999999998,
		y:0.89339999999999997,
		supporter:"D"
	},
	{
		x:0.49249999999999999,
		y:0.88900000000000001,
		supporter:"D"
	},
	{
		x:0.52390000000000003,
		y:0.87709999999999999,
		supporter:"D"
	},
	{
		x:0.52829999999999999,
		y:0.89739999999999998,
		supporter:"D"
	},
	{
		x:0.49890000000000001,
		y:0.8054,
		supporter:"D"
	},
	{
		x:0.57640000000000002,
		y:0.87219999999999998,
		supporter:"D"
	},
	{
		x:0.40389999999999998,
		y:0.8538,
		supporter:"D"
	},
	{
		x:0.51280000000000003,
		y:0.87560000000000004,
		supporter:"D"
	},
	{
		x:0.49519999999999997,
		y:0.88790000000000002,
		supporter:"D"
	},
	{
		x:0.31309999999999999,
		y:0.90710000000000002,
		supporter:"D"
	},
	{
		x:0.55759999999999998,
		y:0.85680000000000001,
		supporter:"D"
	},
	{
		x:0.5827,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.48909999999999998,
		y:0.90669999999999995,
		supporter:"D"
	},
	{
		x:0.48259999999999997,
		y:0.91149999999999998,
		supporter:"D"
	},
	{
		x:0.48599999999999999,
		y:0.88490000000000002,
		supporter:"D"
	},
	{
		x:0.38190000000000002,
		y:0.90859999999999996,
		supporter:"D"
	},
	{
		x:0.55189999999999995,
		y:0.87970000000000004,
		supporter:"D"
	},
	{
		x:0.48280000000000001,
		y:0.90410000000000001,
		supporter:"D"
	},
	{
		x:0.51839999999999997,
		y:0.88619999999999999,
		supporter:"D"
	},
	{
		x:0.40749999999999997,
		y:0.85299999999999998,
		supporter:"D"
	},
	{
		x:0.40279999999999999,
		y:0.91439999999999999,
		supporter:"D"
	},
	{
		x:0.37909999999999999,
		y:0.91210000000000002,
		supporter:"D"
	},
	{
		x:0.60529999999999995,
		y:0.81389999999999996,
		supporter:"D"
	},
	{
		x:0.29699999999999999,
		y:0.90269999999999995,
		supporter:"D"
	},
	{
		x:0.61150000000000004,
		y:0.81420000000000003,
		supporter:"D"
	},
	{
		x:0.51239999999999997,
		y:0.89410000000000001,
		supporter:"D"
	},
	{
		x:0.43259999999999998,
		y:0.84870000000000001,
		supporter:"D"
	},
	{
		x:0.52249999999999996,
		y:0.80679999999999996,
		supporter:"D"
	},
	{
		x:0.4879,
		y:0.93659999999999999,
		supporter:"D"
	},
	{
		x:0.5081,
		y:0.89029999999999998,
		supporter:"D"
	},
	{
		x:0.29959999999999998,
		y:0.90669999999999995,
		supporter:"D"
	},
	{
		x:0.4819,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.54900000000000004,
		y:0.85470000000000002,
		supporter:"D"
	},
	{
		x:0.60780000000000001,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.46210000000000001,
		y:0.92589999999999995,
		supporter:"D"
	},
	{
		x:0.32619999999999999,
		y:0.89359999999999995,
		supporter:"D"
	},
	{
		x:0.55279999999999996,
		y:0.81569999999999998,
		supporter:"D"
	},
	{
		x:0.30620000000000003,
		y:0.90190000000000003,
		supporter:"D"
	},
	{
		x:0.47560000000000002,
		y:0.93279999999999996,
		supporter:"D"
	},
	{
		x:0.38979999999999998,
		y:0.90580000000000005,
		supporter:"D"
	},
	{
		x:0.56030000000000002,
		y:0.81479999999999997,
		supporter:"D"
	},
	{
		x:0.41120000000000001,
		y:0.85850000000000004,
		supporter:"D"
	},
	{
		x:0.42109999999999997,
		y:0.92649999999999999,
		supporter:"D"
	},
	{
		x:0.435,
		y:0.93220000000000003,
		supporter:"D"
	},
	{
		x:0.4889,
		y:0.88819999999999999,
		supporter:"D"
	},
	{
		x:0.39300000000000002,
		y:0.8599,
		supporter:"D"
	},
	{
		x:0.52610000000000001,
		y:0.89390000000000003,
		supporter:"D"
	},
	{
		x:0.59760000000000002,
		y:0.87250000000000005,
		supporter:"D"
	},
	{
		x:0.42259999999999998,
		y:0.90639999999999998,
		supporter:"D"
	},
	{
		x:0.50860000000000005,
		y:0.89510000000000001,
		supporter:"D"
	},
	{
		x:0.48659999999999998,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.3755,
		y:0.85070000000000001,
		supporter:"D"
	},
	{
		x:0.47510000000000002,
		y:0.92889999999999995,
		supporter:"D"
	},
	{
		x:0.6089,
		y:0.89490000000000003,
		supporter:"D"
	},
	{
		x:0.37340000000000001,
		y:0.90200000000000002,
		supporter:"D"
	},
	{
		x:0.48499999999999999,
		y:0.91320000000000001,
		supporter:"D"
	},
	{
		x:0.59630000000000005,
		y:0.81110000000000004,
		supporter:"D"
	},
	{
		x:0.46150000000000002,
		y:0.93069999999999997,
		supporter:"D"
	},
	{
		x:0.42559999999999998,
		y:0.85960000000000003,
		supporter:"D"
	},
	{
		x:0.53859999999999997,
		y:0.8548,
		supporter:"D"
	},
	{
		x:0.57620000000000005,
		y:0.88229999999999997,
		supporter:"D"
	},
	{
		x:0.53700000000000003,
		y:0.88759999999999994,
		supporter:"D"
	},
	{
		x:0.56869999999999998,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.57020000000000004,
		y:0.86980000000000002,
		supporter:"D"
	},
	{
		x:0.57689999999999997,
		y:0.89629999999999999,
		supporter:"D"
	},
	{
		x:0.3004,
		y:0.90959999999999996,
		supporter:"D"
	},
	{
		x:0.5514,
		y:0.89290000000000003,
		supporter:"D"
	},
	{
		x:0.35239999999999999,
		y:0.89970000000000006,
		supporter:"D"
	},
	{
		x:0.58079999999999998,
		y:0.89100000000000001,
		supporter:"D"
	},
	{
		x:0.33239999999999997,
		y:0.90710000000000002,
		supporter:"D"
	},
	{
		x:0.32519999999999999,
		y:0.90529999999999999,
		supporter:"D"
	},
	{
		x:0.44900000000000001,
		y:0.92,
		supporter:"D"
	},
	{
		x:0.49330000000000002,
		y:0.90720000000000001,
		supporter:"D"
	},
	{
		x:0.46870000000000001,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.54820000000000002,
		y:0.89739999999999998,
		supporter:"D"
	},
	{
		x:0.53749999999999998,
		y:0.89529999999999998,
		supporter:"D"
	},
	{
		x:0.36309999999999998,
		y:0.90559999999999996,
		supporter:"D"
	},
	{
		x:0.3805,
		y:0.84850000000000003,
		supporter:"D"
	},
	{
		x:0.33200000000000002,
		y:0.91,
		supporter:"D"
	},
	{
		x:0.51580000000000004,
		y:0.89119999999999999,
		supporter:"D"
	},
	{
		x:0.46389999999999998,
		y:0.90439999999999998,
		supporter:"D"
	},
	{
		x:0.51029999999999998,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.49540000000000001,
		y:0.93559999999999999,
		supporter:"D"
	},
	{
		x:0.3427,
		y:0.89349999999999996,
		supporter:"D"
	},
	{
		x:0.54279999999999995,
		y:0.80500000000000005,
		supporter:"D"
	},
	{
		x:0.59709999999999996,
		y:0.89790000000000003,
		supporter:"D"
	},
	{
		x:0.38090000000000002,
		y:0.91690000000000005,
		supporter:"D"
	},
	{
		x:0.62580000000000002,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.35809999999999997,
		y:0.90680000000000005,
		supporter:"D"
	},
	{
		x:0.51959999999999995,
		y:0.88949999999999996,
		supporter:"D"
	},
	{
		x:0.49859999999999999,
		y:0.88880000000000003,
		supporter:"D"
	},
	{
		x:0.46250000000000002,
		y:0.90410000000000001,
		supporter:"D"
	},
	{
		x:0.5131,
		y:0.89839999999999998,
		supporter:"D"
	},
	{
		x:0.56179999999999997,
		y:0.87739999999999996,
		supporter:"D"
	},
	{
		x:0.3034,
		y:0.90100000000000002,
		supporter:"D"
	},
	{
		x:0.50229999999999997,
		y:0.84630000000000005,
		supporter:"D"
	},
	{
		x:0.43180000000000002,
		y:0.91700000000000004,
		supporter:"D"
	},
	{
		x:0.51239999999999997,
		y:0.87949999999999995,
		supporter:"D"
	},
	{
		x:0.29370000000000002,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.47199999999999998,
		y:0.9012,
		supporter:"D"
	},
	{
		x:0.57340000000000002,
		y:0.87139999999999995,
		supporter:"D"
	},
	{
		x:0.42970000000000003,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.42170000000000002,
		y:0.80159999999999998,
		supporter:"D"
	},
	{
		x:0.54420000000000002,
		y:0.89439999999999997,
		supporter:"D"
	},
	{
		x:0.47789999999999999,
		y:0.90439999999999998,
		supporter:"D"
	},
	{
		x:0.49719999999999998,
		y:0.90059999999999996,
		supporter:"D"
	},
	{
		x:0.4834,
		y:0.89039999999999997,
		supporter:"D"
	},
	{
		x:0.3261,
		y:0.89459999999999995,
		supporter:"D"
	},
	{
		x:0.47620000000000001,
		y:0.93489999999999995,
		supporter:"D"
	},
	{
		x:0.4526,
		y:0.92030000000000001,
		supporter:"D"
	},
	{
		x:0.48349999999999999,
		y:0.90210000000000001,
		supporter:"D"
	},
	{
		x:0.45669999999999999,
		y:0.9264,
		supporter:"D"
	},
	{
		x:0.29780000000000001,
		y:0.90910000000000002,
		supporter:"D"
	},
	{
		x:0.49199999999999999,
		y:0.88890000000000002,
		supporter:"D"
	},
	{
		x:0.55020000000000002,
		y:0.86909999999999998,
		supporter:"D"
	},
	{
		x:0.57509999999999994,
		y:0.85680000000000001,
		supporter:"D"
	},
	{
		x:0.39650000000000002,
		y:0.85319999999999996,
		supporter:"D"
	},
	{
		x:0.38059999999999999,
		y:0.90849999999999997,
		supporter:"D"
	},
	{
		x:0.44729999999999998,
		y:0.93049999999999999,
		supporter:"D"
	},
	{
		x:0.48280000000000001,
		y:0.92059999999999997,
		supporter:"D"
	},
	{
		x:0.52890000000000004,
		y:0.86450000000000005,
		supporter:"D"
	},
	{
		x:0.43340000000000001,
		y:0.85229999999999995,
		supporter:"D"
	},
	{
		x:0.4471,
		y:0.90469999999999995,
		supporter:"D"
	},
	{
		x:0.62050000000000005,
		y:0.88649999999999995,
		supporter:"D"
	},
	{
		x:0.40620000000000001,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.46729999999999999,
		y:0.92749999999999999,
		supporter:"D"
	},
	{
		x:0.53810000000000002,
		y:0.84830000000000005,
		supporter:"D"
	},
	{
		x:0.3306,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.57920000000000005,
		y:0.87119999999999997,
		supporter:"D"
	},
	{
		x:0.52539999999999998,
		y:0.8458,
		supporter:"D"
	},
	{
		x:0.31009999999999999,
		y:0.89700000000000002,
		supporter:"D"
	},
	{
		x:0.57499999999999996,
		y:0.89029999999999998,
		supporter:"D"
	},
	{
		x:0.61009999999999998,
		y:0.87339999999999995,
		supporter:"D"
	},
	{
		x:0.46310000000000001,
		y:0.92320000000000002,
		supporter:"D"
	},
	{
		x:0.317,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.55640000000000001,
		y:0.86319999999999997,
		supporter:"D"
	},
	{
		x:0.58289999999999997,
		y:0.81679999999999997,
		supporter:"D"
	},
	{
		x:0.57320000000000004,
		y:0.87590000000000001,
		supporter:"D"
	},
	{
		x:0.47739999999999999,
		y:0.9133,
		supporter:"D"
	},
	{
		x:0.39629999999999999,
		y:0.85440000000000005,
		supporter:"D"
	},
	{
		x:0.55400000000000005,
		y:0.87360000000000004,
		supporter:"D"
	},
	{
		x:0.62629999999999997,
		y:0.81720000000000004,
		supporter:"D"
	},
	{
		x:0.41770000000000002,
		y:0.90510000000000002,
		supporter:"D"
	},
	{
		x:0.37640000000000001,
		y:0.90680000000000005,
		supporter:"D"
	},
	{
		x:0.3574,
		y:0.90510000000000002,
		supporter:"D"
	},
	{
		x:0.53139999999999998,
		y:0.85099999999999998,
		supporter:"D"
	},
	{
		x:0.27700000000000002,
		y:0.90080000000000005,
		supporter:"D"
	},
	{
		x:0.3044,
		y:0.90269999999999995,
		supporter:"D"
	},
	{
		x:0.58830000000000005,
		y:0.86019999999999996,
		supporter:"D"
	},
	{
		x:0.62570000000000003,
		y:0.81200000000000006,
		supporter:"D"
	},
	{
		x:0.48870000000000002,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.33729999999999999,
		y:0.90510000000000002,
		supporter:"D"
	},
	{
		x:0.50639999999999996,
		y:0.88780000000000003,
		supporter:"D"
	},
	{
		x:0.4849,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.49459999999999998,
		y:0.90159999999999996,
		supporter:"D"
	},
	{
		x:0.41489999999999999,
		y:0.9143,
		supporter:"D"
	},
	{
		x:0.50180000000000002,
		y:0.88019999999999998,
		supporter:"D"
	},
	{
		x:0.58679999999999999,
		y:0.81779999999999997,
		supporter:"D"
	},
	{
		x:0.36020000000000002,
		y:0.89629999999999999,
		supporter:"D"
	},
	{
		x:0.4083,
		y:0.91739999999999999,
		supporter:"D"
	},
	{
		x:0.44840000000000002,
		y:0.91120000000000001,
		supporter:"D"
	},
	{
		x:0.45500000000000002,
		y:0.87570000000000003,
		supporter:"D"
	},
	{
		x:0.30120000000000002,
		y:0.90390000000000004,
		supporter:"D"
	},
	{
		x:0.45100000000000001,
		y:0.92110000000000003,
		supporter:"D"
	},
	{
		x:0.47770000000000001,
		y:0.87219999999999998,
		supporter:"D"
	},
	{
		x:0.54349999999999998,
		y:0.88119999999999998,
		supporter:"D"
	},
	{
		x:0.32850000000000001,
		y:0.90580000000000005,
		supporter:"D"
	},
	{
		x:0.55700000000000005,
		y:0.87819999999999998,
		supporter:"D"
	},
	{
		x:0.35349999999999998,
		y:0.90880000000000005,
		supporter:"D"
	},
	{
		x:0.53790000000000004,
		y:0.878,
		supporter:"D"
	},
	{
		x:0.28139999999999998,
		y:0.91510000000000002,
		supporter:"D"
	},
	{
		x:0.49149999999999999,
		y:0.88449999999999995,
		supporter:"D"
	},
	{
		x:0.37319999999999998,
		y:0.91169999999999995,
		supporter:"D"
	},
	{
		x:0.495,
		y:0.876,
		supporter:"D"
	},
	{
		x:0.49909999999999999,
		y:0.89680000000000004,
		supporter:"D"
	},
	{
		x:0.61539999999999995,
		y:0.77490000000000003,
		supporter:"D"
	},
	{
		x:0.50160000000000005,
		y:0.89980000000000004,
		supporter:"D"
	},
	{
		x:0.48120000000000002,
		y:0.89839999999999998,
		supporter:"D"
	},
	{
		x:0.44690000000000002,
		y:0.92569999999999997,
		supporter:"D"
	},
	{
		x:0.28570000000000001,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.52610000000000001,
		y:0.89170000000000005,
		supporter:"D"
	},
	{
		x:0.45610000000000001,
		y:0.91830000000000001,
		supporter:"D"
	},
	{
		x:0.47,
		y:0.91369999999999996,
		supporter:"D"
	},
	{
		x:0.4279,
		y:0.92249999999999999,
		supporter:"D"
	},
	{
		x:0.48060000000000003,
		y:0.92290000000000005,
		supporter:"D"
	},
	{
		x:0.45660000000000001,
		y:0.90080000000000005,
		supporter:"D"
	},
	{
		x:0.48370000000000002,
		y:0.93930000000000002,
		supporter:"D"
	},
	{
		x:0.57709999999999995,
		y:0.80400000000000005,
		supporter:"D"
	},
	{
		x:0.30570000000000003,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.60460000000000003,
		y:0.89959999999999996,
		supporter:"D"
	},
	{
		x:0.41110000000000002,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.44600000000000001,
		y:0.92689999999999995,
		supporter:"D"
	},
	{
		x:0.47789999999999999,
		y:0.92420000000000002,
		supporter:"D"
	},
	{
		x:0.30170000000000002,
		y:0.89280000000000004,
		supporter:"D"
	},
	{
		x:0.48730000000000001,
		y:0.8891,
		supporter:"D"
	},
	{
		x:0.57250000000000001,
		y:0.86729999999999996,
		supporter:"D"
	},
	{
		x:0.46860000000000002,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.41460000000000002,
		y:0.90459999999999996,
		supporter:"D"
	},
	{
		x:0.4178,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.46050000000000002,
		y:0.89900000000000002,
		supporter:"D"
	},
	{
		x:0.46829999999999999,
		y:0.92649999999999999,
		supporter:"D"
	},
	{
		x:0.39250000000000002,
		y:0.8498,
		supporter:"D"
	},
	{
		x:0.43530000000000002,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.4763,
		y:0.91490000000000005,
		supporter:"D"
	},
	{
		x:0.49109999999999998,
		y:0.92249999999999999,
		supporter:"D"
	},
	{
		x:0.56940000000000002,
		y:0.87890000000000001,
		supporter:"D"
	},
	{
		x:0.38119999999999998,
		y:0.85540000000000005,
		supporter:"D"
	},
	{
		x:0.38850000000000001,
		y:0.91559999999999997,
		supporter:"D"
	},
	{
		x:0.44800000000000001,
		y:0.92490000000000006,
		supporter:"D"
	},
	{
		x:0.28539999999999999,
		y:0.88460000000000005,
		supporter:"D"
	},
	{
		x:0.53080000000000005,
		y:0.87709999999999999,
		supporter:"D"
	},
	{
		x:0.4546,
		y:0.88329999999999997,
		supporter:"D"
	},
	{
		x:0.52249999999999996,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.32029999999999997,
		y:0.90849999999999997,
		supporter:"D"
	},
	{
		x:0.47039999999999998,
		y:0.90700000000000003,
		supporter:"D"
	},
	{
		x:0.35580000000000001,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.47170000000000001,
		y:0.81259999999999999,
		supporter:"D"
	},
	{
		x:0.47899999999999998,
		y:0.92010000000000003,
		supporter:"D"
	},
	{
		x:0.46450000000000002,
		y:0.8871,
		supporter:"D"
	},
	{
		x:0.499,
		y:0.92010000000000003,
		supporter:"D"
	},
	{
		x:0.47020000000000001,
		y:0.80269999999999997,
		supporter:"D"
	},
	{
		x:0.46870000000000001,
		y:0.92920000000000003,
		supporter:"D"
	},
	{
		x:0.2964,
		y:0.89990000000000003,
		supporter:"D"
	},
	{
		x:0.47920000000000001,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.49930000000000002,
		y:0.92730000000000001,
		supporter:"D"
	},
	{
		x:0.42759999999999998,
		y:0.9022,
		supporter:"D"
	},
	{
		x:0.48959999999999998,
		y:0.8992,
		supporter:"D"
	},
	{
		x:0.55089999999999995,
		y:0.872,
		supporter:"D"
	},
	{
		x:0.53159999999999996,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.49099999999999999,
		y:0.90539999999999998,
		supporter:"D"
	},
	{
		x:0.39979999999999999,
		y:0.91690000000000005,
		supporter:"D"
	},
	{
		x:0.4214,
		y:0.89159999999999995,
		supporter:"D"
	},
	{
		x:0.48309999999999997,
		y:0.88580000000000003,
		supporter:"D"
	},
	{
		x:0.46029999999999999,
		y:0.92169999999999996,
		supporter:"D"
	},
	{
		x:0.3705,
		y:0.91090000000000004,
		supporter:"D"
	},
	{
		x:0.62480000000000002,
		y:0.81630000000000003,
		supporter:"D"
	},
	{
		x:0.33050000000000002,
		y:0.89500000000000002,
		supporter:"D"
	},
	{
		x:0.41789999999999999,
		y:0.91620000000000001,
		supporter:"D"
	},
	{
		x:0.37569999999999998,
		y:0.90139999999999998,
		supporter:"D"
	},
	{
		x:0.42949999999999999,
		y:0.86260000000000003,
		supporter:"D"
	},
	{
		x:0.57730000000000004,
		y:0.88119999999999998,
		supporter:"D"
	},
	{
		x:0.40550000000000003,
		y:0.86170000000000002,
		supporter:"D"
	},
	{
		x:0.32419999999999999,
		y:0.90529999999999999,
		supporter:"D"
	},
	{
		x:0.3931,
		y:0.90200000000000002,
		supporter:"D"
	},
	{
		x:0.57999999999999996,
		y:0.80349999999999999,
		supporter:"D"
	},
	{
		x:0.38200000000000001,
		y:0.84989999999999999,
		supporter:"D"
	},
	{
		x:0.49530000000000002,
		y:0.91500000000000004,
		supporter:"D"
	},
	{
		x:0.59030000000000005,
		y:0.87770000000000004,
		supporter:"D"
	},
	{
		x:0.3876,
		y:0.90410000000000001,
		supporter:"D"
	},
	{
		x:0.49320000000000003,
		y:0.92889999999999995,
		supporter:"D"
	},
	{
		x:0.60329999999999995,
		y:0.89829999999999999,
		supporter:"D"
	},
	{
		x:0.44819999999999999,
		y:0.90800000000000003,
		supporter:"D"
	},
	{
		x:0.49619999999999997,
		y:0.90280000000000005,
		supporter:"D"
	},
	{
		x:0.49890000000000001,
		y:0.90310000000000001,
		supporter:"D"
	},
	{
		x:0.4819,
		y:0.93069999999999997,
		supporter:"D"
	},
	{
		x:0.45069999999999999,
		y:0.87890000000000001,
		supporter:"D"
	},
	{
		x:0.48580000000000001,
		y:0.91790000000000005,
		supporter:"D"
	},
	{
		x:0.48320000000000002,
		y:0.88629999999999998,
		supporter:"D"
	},
	{
		x:0.61609999999999998,
		y:0.82350000000000001,
		supporter:"D"
	},
	{
		x:0.55189999999999995,
		y:0.86970000000000003,
		supporter:"D"
	},
	{
		x:0.45529999999999998,
		y:0.90249999999999997,
		supporter:"D"
	},
	{
		x:0.41710000000000003,
		y:0.90469999999999995,
		supporter:"D"
	},
	{
		x:0.4637,
		y:0.92149999999999999,
		supporter:"D"
	},
	{
		x:0.58289999999999997,
		y:0.86360000000000003,
		supporter:"D"
	},
	{
		x:0.4536,
		y:0.90269999999999995,
		supporter:"D"
	},
	{
		x:0.3034,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.46700000000000003,
		y:0.88339999999999996,
		supporter:"D"
	},
	{
		x:0.47470000000000001,
		y:0.9294,
		supporter:"D"
	},
	{
		x:0.46410000000000001,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.55600000000000005,
		y:0.88009999999999999,
		supporter:"D"
	},
	{
		x:0.45290000000000002,
		y:0.87970000000000004,
		supporter:"D"
	},
	{
		x:0.51339999999999997,
		y:0.86199999999999999,
		supporter:"D"
	},
	{
		x:0.59119999999999995,
		y:0.78890000000000005,
		supporter:"D"
	},
	{
		x:0.496,
		y:0.91069999999999995,
		supporter:"D"
	},
	{
		x:0.45369999999999999,
		y:0.91639999999999999,
		supporter:"D"
	},
	{
		x:0.56530000000000002,
		y:0.87539999999999996,
		supporter:"D"
	},
	{
		x:0.51780000000000004,
		y:0.80610000000000004,
		supporter:"D"
	},
	{
		x:0.53839999999999999,
		y:0.88700000000000001,
		supporter:"D"
	},
	{
		x:0.315,
		y:0.90529999999999999,
		supporter:"D"
	},
	{
		x:0.46679999999999999,
		y:0.91549999999999998,
		supporter:"D"
	},
	{
		x:0.60750000000000004,
		y:0.8952,
		supporter:"D"
	},
	{
		x:0.53120000000000001,
		y:0.89039999999999997,
		supporter:"D"
	},
	{
		x:0.49020000000000002,
		y:0.93020000000000003,
		supporter:"D"
	},
	{
		x:0.38529999999999998,
		y:0.85099999999999998,
		supporter:"D"
	},
	{
		x:0.49370000000000003,
		y:0.90229999999999999,
		supporter:"D"
	},
	{
		x:0.48520000000000002,
		y:0.90490000000000004,
		supporter:"D"
	},
	{
		x:0.41520000000000001,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.4995,
		y:0.91020000000000001,
		supporter:"D"
	},
	{
		x:0.37490000000000001,
		y:0.84940000000000004,
		supporter:"D"
	},
	{
		x:0.3846,
		y:0.86119999999999997,
		supporter:"D"
	},
	{
		x:0.49819999999999998,
		y:0.88160000000000005,
		supporter:"D"
	},
	{
		x:0.33910000000000001,
		y:0.90810000000000002,
		supporter:"D"
	},
	{
		x:0.56469999999999998,
		y:0.89529999999999998,
		supporter:"D"
	},
	{
		x:0.28820000000000001,
		y:0.90249999999999997,
		supporter:"D"
	},
	{
		x:0.53069999999999995,
		y:0.87549999999999994,
		supporter:"D"
	},
	{
		x:0.30399999999999999,
		y:0.91190000000000004,
		supporter:"D"
	},
	{
		x:0.434,
		y:0.91490000000000005,
		supporter:"D"
	},
	{
		x:0.56669999999999998,
		y:0.87570000000000003,
		supporter:"D"
	},
	{
		x:0.28220000000000001,
		y:0.89990000000000003,
		supporter:"D"
	},
	{
		x:0.48270000000000002,
		y:0.87949999999999995,
		supporter:"D"
	},
	{
		x:0.54630000000000001,
		y:0.88029999999999997,
		supporter:"D"
	},
	{
		x:0.4637,
		y:0.91739999999999999,
		supporter:"D"
	},
	{
		x:0.30470000000000003,
		y:0.90539999999999998,
		supporter:"D"
	},
	{
		x:0.58819999999999995,
		y:0.88880000000000003,
		supporter:"D"
	},
	{
		x:0.50790000000000002,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.47560000000000002,
		y:0.90600000000000003,
		supporter:"D"
	},
	{
		x:0.36280000000000001,
		y:0.90529999999999999,
		supporter:"D"
	},
	{
		x:0.4713,
		y:0.90600000000000003,
		supporter:"D"
	},
	{
		x:0.49109999999999998,
		y:0.91049999999999998,
		supporter:"D"
	},
	{
		x:0.2959,
		y:0.9052,
		supporter:"D"
	},
	{
		x:0.28149999999999997,
		y:0.91059999999999997,
		supporter:"D"
	},
	{
		x:0.496,
		y:0.92469999999999997,
		supporter:"D"
	},
	{
		x:0.5696,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.43190000000000001,
		y:0.90559999999999996,
		supporter:"D"
	},
	{
		x:0.41970000000000002,
		y:0.89080000000000004,
		supporter:"D"
	},
	{
		x:0.44469999999999998,
		y:0.86450000000000005,
		supporter:"D"
	},
	{
		x:0.40679999999999999,
		y:0.90190000000000003,
		supporter:"D"
	},
	{
		x:0.36890000000000001,
		y:0.90059999999999996,
		supporter:"D"
	},
	{
		x:0.37540000000000001,
		y:0.89949999999999997,
		supporter:"D"
	},
	{
		x:0.44540000000000002,
		y:0.91439999999999999,
		supporter:"D"
	},
	{
		x:0.41799999999999998,
		y:0.89529999999999998,
		supporter:"D"
	},
	{
		x:0.5494,
		y:0.88190000000000002,
		supporter:"D"
	},
	{
		x:0.3266,
		y:0.9083,
		supporter:"D"
	},
	{
		x:0.51980000000000004,
		y:0.84870000000000001,
		supporter:"D"
	},
	{
		x:0.54090000000000005,
		y:0.88429999999999997,
		supporter:"D"
	},
	{
		x:0.58309999999999995,
		y:0.82250000000000001,
		supporter:"D"
	},
	{
		x:0.44429999999999997,
		y:0.90629999999999999,
		supporter:"D"
	},
	{
		x:0.48299999999999998,
		y:0.91769999999999996,
		supporter:"D"
	},
	{
		x:0.32240000000000002,
		y:0.90529999999999999,
		supporter:"D"
	},
	{
		x:0.60770000000000002,
		y:0.85670000000000002,
		supporter:"D"
	},
	{
		x:0.34960000000000002,
		y:0.89539999999999997,
		supporter:"D"
	},
	{
		x:0.49830000000000002,
		y:0.9143,
		supporter:"D"
	},
	{
		x:0.3669,
		y:0.90300000000000002,
		supporter:"D"
	},
	{
		x:0.3679,
		y:0.89829999999999999,
		supporter:"D"
	},
	{
		x:0.30499999999999999,
		y:0.91269999999999996,
		supporter:"D"
	},
	{
		x:0.43630000000000002,
		y:0.85270000000000001,
		supporter:"D"
	},
	{
		x:0.45169999999999999,
		y:0.92779999999999996,
		supporter:"D"
	},
	{
		x:0.48270000000000002,
		y:0.92320000000000002,
		supporter:"D"
	},
	{
		x:0.53649999999999998,
		y:0.89270000000000005,
		supporter:"D"
	},
	{
		x:0.58819999999999995,
		y:0.78400000000000003,
		supporter:"D"
	},
	{
		x:0.49220000000000003,
		y:0.90880000000000005,
		supporter:"D"
	},
	{
		x:0.52280000000000004,
		y:0.88639999999999997,
		supporter:"D"
	},
	{
		x:0.55330000000000001,
		y:0.85640000000000005,
		supporter:"D"
	},
	{
		x:0.4662,
		y:0.89980000000000004,
		supporter:"D"
	},
	{
		x:0.56579999999999997,
		y:0.85150000000000003,
		supporter:"D"
	},
	{
		x:0.45950000000000002,
		y:0.90429999999999999,
		supporter:"D"
	},
	{
		x:0.40189999999999998,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.47899999999999998,
		y:0.9325,
		supporter:"D"
	},
	{
		x:0.36349999999999999,
		y:0.85270000000000001,
		supporter:"D"
	},
	{
		x:0.54630000000000001,
		y:0.87729999999999997,
		supporter:"D"
	},
	{
		x:0.55879999999999996,
		y:0.88339999999999996,
		supporter:"D"
	},
	{
		x:0.55089999999999995,
		y:0.87219999999999998,
		supporter:"D"
	},
	{
		x:0.45879999999999999,
		y:0.92120000000000002,
		supporter:"D"
	},
	{
		x:0.3221,
		y:0.90990000000000004,
		supporter:"D"
	},
	{
		x:0.30659999999999998,
		y:0.90380000000000005,
		supporter:"D"
	},
	{
		x:0.48670000000000002,
		y:0.90139999999999998,
		supporter:"D"
	},
	{
		x:0.49830000000000002,
		y:0.93389999999999995,
		supporter:"D"
	},
	{
		x:0.46450000000000002,
		y:0.85299999999999998,
		supporter:"D"
	},
	{
		x:0.44009999999999999,
		y:0.93110000000000004,
		supporter:"D"
	},
	{
		x:0.5423,
		y:0.80640000000000001,
		supporter:"D"
	},
	{
		x:0.34720000000000001,
		y:0.91049999999999998,
		supporter:"D"
	},
	{
		x:0.51290000000000002,
		y:0.88590000000000002,
		supporter:"D"
	},
	{
		x:0.29580000000000001,
		y:0.90349999999999997,
		supporter:"D"
	},
	{
		x:0.4486,
		y:0.92430000000000001,
		supporter:"D"
	},
	{
		x:0.4642,
		y:0.92410000000000003,
		supporter:"D"
	},
	{
		x:0.2898,
		y:0.89959999999999996,
		supporter:"D"
	},
	{
		x:0.45600000000000002,
		y:0.89590000000000003,
		supporter:"D"
	},
	{
		x:0.3372,
		y:0.90600000000000003,
		supporter:"D"
	},
	{
		x:0.60250000000000004,
		y:0.82820000000000005,
		supporter:"D"
	},
	{
		x:0.49270000000000003,
		y:0.84279999999999999,
		supporter:"D"
	},
	{
		x:0.39319999999999999,
		y:0.91500000000000004,
		supporter:"D"
	},
	{
		x:0.47339999999999999,
		y:0.92349999999999999,
		supporter:"D"
	},
	{
		x:0.29239999999999999,
		y:0.90129999999999999,
		supporter:"D"
	},
	{
		x:0.57830000000000004,
		y:0.85950000000000004,
		supporter:"D"
	},
	{
		x:0.43169999999999997,
		y:0.92559999999999998,
		supporter:"D"
	},
	{
		x:0.4536,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.59099999999999997,
		y:0.8135,
		supporter:"D"
	},
	{
		x:0.47289999999999999,
		y:0.91979999999999995,
		supporter:"D"
	},
	{
		x:0.37740000000000001,
		y:0.89419999999999999,
		supporter:"D"
	},
	{
		x:0.43369999999999997,
		y:0.92430000000000001,
		supporter:"D"
	},
	{
		x:0.49049999999999999,
		y:0.92569999999999997,
		supporter:"D"
	},
	{
		x:0.55110000000000003,
		y:0.81089999999999995,
		supporter:"D"
	},
	{
		x:0.4965,
		y:0.8992,
		supporter:"D"
	},
	{
		x:0.4405,
		y:0.85919999999999996,
		supporter:"D"
	},
	{
		x:0.5091,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.46160000000000001,
		y:0.92090000000000005,
		supporter:"D"
	},
	{
		x:0.51349999999999996,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.49390000000000001,
		y:0.91290000000000004,
		supporter:"D"
	},
	{
		x:0.56059999999999999,
		y:0.89629999999999999,
		supporter:"D"
	},
	{
		x:0.49309999999999998,
		y:0.93259999999999998,
		supporter:"D"
	},
	{
		x:0.54120000000000001,
		y:0.87919999999999998,
		supporter:"D"
	},
	{
		x:0.61719999999999997,
		y:0.89500000000000002,
		supporter:"D"
	},
	{
		x:0.52039999999999997,
		y:0.88990000000000002,
		supporter:"D"
	},
	{
		x:0.31040000000000001,
		y:0.89780000000000004,
		supporter:"D"
	},
	{
		x:0.4486,
		y:0.92359999999999998,
		supporter:"D"
	},
	{
		x:0.48920000000000002,
		y:0.92889999999999995,
		supporter:"D"
	},
	{
		x:0.55620000000000003,
		y:0.88319999999999999,
		supporter:"D"
	},
	{
		x:0.3523,
		y:0.84619999999999995,
		supporter:"D"
	},
	{
		x:0.50529999999999997,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.4854,
		y:0.92069999999999996,
		supporter:"D"
	},
	{
		x:0.5595,
		y:0.87119999999999997,
		supporter:"D"
	},
	{
		x:0.53759999999999997,
		y:0.87190000000000001,
		supporter:"D"
	},
	{
		x:0.32179999999999997,
		y:0.90539999999999998,
		supporter:"D"
	},
	{
		x:0.42420000000000002,
		y:0.90059999999999996,
		supporter:"D"
	},
	{
		x:0.38440000000000002,
		y:0.85740000000000005,
		supporter:"D"
	},
	{
		x:0.47799999999999998,
		y:0.91900000000000004,
		supporter:"D"
	},
	{
		x:0.41510000000000002,
		y:0.85770000000000002,
		supporter:"D"
	},
	{
		x:0.59299999999999997,
		y:0.77729999999999999,
		supporter:"D"
	},
	{
		x:0.3463,
		y:0.89839999999999998,
		supporter:"D"
	},
	{
		x:0.4652,
		y:0.9123,
		supporter:"D"
	},
	{
		x:0.55420000000000003,
		y:0.89390000000000003,
		supporter:"D"
	},
	{
		x:0.4521,
		y:0.90259999999999996,
		supporter:"D"
	},
	{
		x:0.3382,
		y:0.90310000000000001,
		supporter:"D"
	},
	{
		x:0.46760000000000002,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.47160000000000002,
		y:0.90559999999999996,
		supporter:"D"
	},
	{
		x:0.56479999999999997,
		y:0.8044,
		supporter:"D"
	},
	{
		x:0.44379999999999997,
		y:0.91879999999999995,
		supporter:"D"
	},
	{
		x:0.49680000000000002,
		y:0.88660000000000005,
		supporter:"D"
	},
	{
		x:0.42730000000000001,
		y:0.90869999999999995,
		supporter:"D"
	},
	{
		x:0.3422,
		y:0.91369999999999996,
		supporter:"D"
	},
	{
		x:0.40450000000000003,
		y:0.86099999999999999,
		supporter:"D"
	},
	{
		x:0.4819,
		y:0.90639999999999998,
		supporter:"D"
	},
	{
		x:0.43819999999999998,
		y:0.92310000000000003,
		supporter:"D"
	},
	{
		x:0.4929,
		y:0.88549999999999995,
		supporter:"D"
	},
	{
		x:0.32379999999999998,
		y:0.90769999999999995,
		supporter:"D"
	},
	{
		x:0.32900000000000001,
		y:0.90859999999999996,
		supporter:"D"
	},
	{
		x:0.51470000000000005,
		y:0.88949999999999996,
		supporter:"D"
	},
	{
		x:0.4199,
		y:0.91810000000000003,
		supporter:"D"
	},
	{
		x:0.61539999999999995,
		y:0.79510000000000003,
		supporter:"D"
	},
	{
		x:0.29430000000000001,
		y:0.90069999999999995,
		supporter:"D"
	},
	{
		x:0.50700000000000001,
		y:0.89200000000000002,
		supporter:"D"
	},
	{
		x:0.30649999999999999,
		y:0.90710000000000002,
		supporter:"D"
	},
	{
		x:0.49559999999999998,
		y:0.8599,
		supporter:"D"
	},
	{
		x:0.40500000000000003,
		y:0.85109999999999997,
		supporter:"D"
	},
	{
		x:0.498,
		y:0.8901,
		supporter:"D"
	},
	{
		x:0.48830000000000001,
		y:0.79790000000000005,
		supporter:"D"
	},
	{
		x:0.5131,
		y:0.89090000000000003,
		supporter:"D"
	},
	{
		x:0.4632,
		y:0.92700000000000005,
		supporter:"D"
	},
	{
		x:0.45619999999999999,
		y:0.92120000000000002,
		supporter:"D"
	},
	{
		x:0.51670000000000005,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.53639999999999999,
		y:0.876,
		supporter:"D"
	},
	{
		x:0.4708,
		y:0.90739999999999998,
		supporter:"D"
	},
	{
		x:0.55079999999999996,
		y:0.79890000000000005,
		supporter:"D"
	},
	{
		x:0.55500000000000005,
		y:0.85399999999999998,
		supporter:"D"
	},
	{
		x:0.30430000000000001,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.37740000000000001,
		y:0.90900000000000003,
		supporter:"D"
	},
	{
		x:0.40210000000000001,
		y:0.90429999999999999,
		supporter:"D"
	},
	{
		x:0.56910000000000005,
		y:0.89990000000000003,
		supporter:"D"
	},
	{
		x:0.32919999999999999,
		y:0.91080000000000005,
		supporter:"D"
	},
	{
		x:0.47389999999999999,
		y:0.88300000000000001,
		supporter:"D"
	},
	{
		x:0.41959999999999997,
		y:0.85409999999999997,
		supporter:"D"
	},
	{
		x:0.39929999999999999,
		y:0.8458,
		supporter:"D"
	},
	{
		x:0.318,
		y:0.90200000000000002,
		supporter:"D"
	},
	{
		x:0.32540000000000002,
		y:0.90639999999999998,
		supporter:"D"
	},
	{
		x:0.57099999999999995,
		y:0.81240000000000001,
		supporter:"D"
	},
	{
		x:0.4501,
		y:0.92200000000000004,
		supporter:"D"
	},
	{
		x:0.38340000000000002,
		y:0.85519999999999996,
		supporter:"D"
	},
	{
		x:0.40689999999999998,
		y:0.91320000000000001,
		supporter:"D"
	},
	{
		x:0.4854,
		y:0.91910000000000003,
		supporter:"D"
	},
	{
		x:0.46829999999999999,
		y:0.91469999999999996,
		supporter:"D"
	},
	{
		x:0.41909999999999997,
		y:0.91,
		supporter:"D"
	},
	{
		x:0.42970000000000003,
		y:0.89959999999999996,
		supporter:"D"
	},
	{
		x:0.49020000000000002,
		y:0.80130000000000001,
		supporter:"D"
	},
	{
		x:0.34449999999999997,
		y:0.91710000000000003,
		supporter:"D"
	},
	{
		x:0.33679999999999999,
		y:0.89690000000000003,
		supporter:"D"
	},
	{
		x:0.43619999999999998,
		y:0.91149999999999998,
		supporter:"D"
	},
	{
		x:0.38040000000000002,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.62609999999999999,
		y:0.80179999999999996,
		supporter:"D"
	},
	{
		x:0.4652,
		y:0.90149999999999997,
		supporter:"D"
	},
	{
		x:0.46610000000000001,
		y:0.92320000000000002,
		supporter:"D"
	},
	{
		x:0.33610000000000001,
		y:0.90429999999999999,
		supporter:"D"
	},
	{
		x:0.30730000000000002,
		y:0.89880000000000004,
		supporter:"D"
	},
	{
		x:0.50560000000000005,
		y:0.80569999999999997,
		supporter:"D"
	},
	{
		x:0.3075,
		y:0.90500000000000003,
		supporter:"D"
	},
	{
		x:0.52590000000000003,
		y:0.89970000000000006,
		supporter:"D"
	},
	{
		x:0.48199999999999998,
		y:0.88690000000000002,
		supporter:"D"
	},
	{
		x:0.32740000000000002,
		y:0.90190000000000003,
		supporter:"D"
	},
	{
		x:0.38300000000000001,
		y:0.90529999999999999,
		supporter:"D"
	},
	{
		x:0.39329999999999998,
		y:0.91369999999999996,
		supporter:"D"
	},
	{
		x:0.48899999999999999,
		y:0.92569999999999997,
		supporter:"D"
	},
	{
		x:0.4032,
		y:0.84330000000000005,
		supporter:"D"
	},
	{
		x:0.50129999999999997,
		y:0.88800000000000001,
		supporter:"D"
	},
	{
		x:0.4168,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.28910000000000002,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.57140000000000002,
		y:0.80840000000000001,
		supporter:"D"
	},
	{
		x:0.44579999999999997,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.47370000000000001,
		y:0.92959999999999998,
		supporter:"D"
	},
	{
		x:0.52380000000000004,
		y:0.84819999999999995,
		supporter:"D"
	},
	{
		x:0.56140000000000001,
		y:0.8589,
		supporter:"D"
	},
	{
		x:0.46920000000000001,
		y:0.92490000000000006,
		supporter:"D"
	},
	{
		x:0.50900000000000001,
		y:0.88619999999999999,
		supporter:"D"
	},
	{
		x:0.40550000000000003,
		y:0.91090000000000004,
		supporter:"D"
	},
	{
		x:0.50160000000000005,
		y:0.88759999999999994,
		supporter:"D"
	},
	{
		x:0.40539999999999998,
		y:0.90910000000000002,
		supporter:"D"
	},
	{
		x:0.32740000000000002,
		y:0.91059999999999997,
		supporter:"D"
	},
	{
		x:0.60570000000000002,
		y:0.82540000000000002,
		supporter:"D"
	},
	{
		x:0.61009999999999998,
		y:0.80620000000000003,
		supporter:"D"
	},
	{
		x:0.45619999999999999,
		y:0.91979999999999995,
		supporter:"D"
	},
	{
		x:0.35659999999999997,
		y:0.82509999999999994,
		supporter:"D"
	},
	{
		x:0.47389999999999999,
		y:0.92889999999999995,
		supporter:"D"
	},
	{
		x:0.29010000000000002,
		y:0.90490000000000004,
		supporter:"D"
	},
	{
		x:0.41210000000000002,
		y:0.90339999999999998,
		supporter:"D"
	},
	{
		x:0.46600000000000003,
		y:0.91,
		supporter:"D"
	},
	{
		x:0.50029999999999997,
		y:0.89100000000000001,
		supporter:"D"
	},
	{
		x:0.54039999999999999,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.49719999999999998,
		y:0.80310000000000004,
		supporter:"D"
	},
	{
		x:0.57220000000000004,
		y:0.89859999999999995,
		supporter:"D"
	},
	{
		x:0.38240000000000002,
		y:0.86160000000000003,
		supporter:"D"
	},
	{
		x:0.29409999999999997,
		y:0.91290000000000004,
		supporter:"D"
	},
	{
		x:0.59650000000000003,
		y:0.83020000000000005,
		supporter:"D"
	},
	{
		x:0.49509999999999998,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.50480000000000003,
		y:0.89949999999999997,
		supporter:"D"
	},
	{
		x:0.39560000000000001,
		y:0.91720000000000002,
		supporter:"D"
	},
	{
		x:0.40949999999999998,
		y:0.89070000000000005,
		supporter:"D"
	},
	{
		x:0.52890000000000004,
		y:0.89500000000000002,
		supporter:"D"
	},
	{
		x:0.45490000000000003,
		y:0.90839999999999999,
		supporter:"D"
	},
	{
		x:0.4491,
		y:0.92689999999999995,
		supporter:"D"
	},
	{
		x:0.52900000000000003,
		y:0.88009999999999999,
		supporter:"D"
	},
	{
		x:0.45639999999999997,
		y:0.92989999999999995,
		supporter:"D"
	},
	{
		x:0.36630000000000001,
		y:0.90780000000000005,
		supporter:"D"
	},
	{
		x:0.46550000000000002,
		y:0.92879999999999996,
		supporter:"D"
	},
	{
		x:0.58030000000000004,
		y:0.89549999999999996,
		supporter:"D"
	},
	{
		x:0.4778,
		y:0.90069999999999995,
		supporter:"D"
	},
	{
		x:0.4748,
		y:0.92110000000000003,
		supporter:"D"
	},
	{
		x:0.36370000000000002,
		y:0.9123,
		supporter:"D"
	},
	{
		x:0.3095,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.43090000000000001,
		y:0.92210000000000003,
		supporter:"D"
	},
	{
		x:0.61419999999999997,
		y:0.79600000000000004,
		supporter:"D"
	},
	{
		x:0.46360000000000001,
		y:0.88560000000000005,
		supporter:"D"
	},
	{
		x:0.43709999999999999,
		y:0.91920000000000002,
		supporter:"D"
	},
	{
		x:0.49170000000000003,
		y:0.92659999999999998,
		supporter:"D"
	},
	{
		x:0.49270000000000003,
		y:0.88719999999999999,
		supporter:"D"
	},
	{
		x:0.48680000000000001,
		y:0.88490000000000002,
		supporter:"D"
	},
	{
		x:0.54830000000000001,
		y:0.85219999999999996,
		supporter:"D"
	},
	{
		x:0.35070000000000001,
		y:0.90920000000000001,
		supporter:"D"
	},
	{
		x:0.40100000000000002,
		y:0.90580000000000005,
		supporter:"D"
	},
	{
		x:0.5655,
		y:0.876,
		supporter:"D"
	},
	{
		x:0.312,
		y:0.88959999999999995,
		supporter:"D"
	},
	{
		x:0.46050000000000002,
		y:0.92490000000000006,
		supporter:"D"
	},
	{
		x:0.43830000000000002,
		y:0.89419999999999999,
		supporter:"D"
	},
	{
		x:0.44109999999999999,
		y:0.85450000000000004,
		supporter:"D"
	},
	{
		x:0.36549999999999999,
		y:0.90839999999999999,
		supporter:"D"
	},
	{
		x:0.42009999999999997,
		y:0.91469999999999996,
		supporter:"D"
	},
	{
		x:0.45729999999999998,
		y:0.9214,
		supporter:"D"
	},
	{
		x:0.49049999999999999,
		y:0.89,
		supporter:"D"
	},
	{
		x:0.432,
		y:0.92179999999999995,
		supporter:"D"
	},
	{
		x:0.53739999999999999,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.44669999999999999,
		y:0.91859999999999997,
		supporter:"D"
	},
	{
		x:0.39250000000000002,
		y:0.89959999999999996,
		supporter:"D"
	},
	{
		x:0.44440000000000002,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.41980000000000001,
		y:0.84970000000000001,
		supporter:"D"
	},
	{
		x:0.5998,
		y:0.89949999999999997,
		supporter:"D"
	},
	{
		x:0.52070000000000005,
		y:0.86170000000000002,
		supporter:"D"
	},
	{
		x:0.35659999999999997,
		y:0.84950000000000003,
		supporter:"D"
	},
	{
		x:0.39319999999999999,
		y:0.90159999999999996,
		supporter:"D"
	},
	{
		x:0.307,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.52090000000000003,
		y:0.88680000000000003,
		supporter:"D"
	},
	{
		x:0.52259999999999995,
		y:0.876,
		supporter:"D"
	},
	{
		x:0.46820000000000001,
		y:0.9093,
		supporter:"D"
	},
	{
		x:0.32079999999999997,
		y:0.90869999999999995,
		supporter:"D"
	},
	{
		x:0.48759999999999998,
		y:0.9123,
		supporter:"D"
	},
	{
		x:0.59699999999999998,
		y:0.82869999999999999,
		supporter:"D"
	},
	{
		x:0.60880000000000001,
		y:0.89859999999999995,
		supporter:"D"
	},
	{
		x:0.3085,
		y:0.90649999999999997,
		supporter:"D"
	},
	{
		x:0.56489999999999996,
		y:0.80469999999999997,
		supporter:"D"
	},
	{
		x:0.499,
		y:0.82410000000000005,
		supporter:"D"
	},
	{
		x:0.48970000000000002,
		y:0.9042,
		supporter:"D"
	},
	{
		x:0.52739999999999998,
		y:0.87370000000000003,
		supporter:"D"
	},
	{
		x:0.47670000000000001,
		y:0.85599999999999998,
		supporter:"D"
	},
	{
		x:0.29820000000000002,
		y:0.90090000000000003,
		supporter:"D"
	},
	{
		x:0.39679999999999999,
		y:0.90390000000000004,
		supporter:"D"
	},
	{
		x:0.40229999999999999,
		y:0.90990000000000004,
		supporter:"D"
	},
	{
		x:0.4718,
		y:0.92059999999999997,
		supporter:"D"
	},
	{
		x:0.35880000000000001,
		y:0.89200000000000002,
		supporter:"D"
	},
	{
		x:0.4577,
		y:0.88119999999999998,
		supporter:"D"
	},
	{
		x:0.38009999999999999,
		y:0.85289999999999999,
		supporter:"D"
	},
	{
		x:0.4325,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.3402,
		y:0.90869999999999995,
		supporter:"D"
	},
	{
		x:0.40849999999999997,
		y:0.89829999999999999,
		supporter:"D"
	},
	{
		x:0.3876,
		y:0.91120000000000001,
		supporter:"D"
	},
	{
		x:0.51670000000000005,
		y:0.89980000000000004,
		supporter:"D"
	},
	{
		x:0.46929999999999999,
		y:0.92979999999999996,
		supporter:"D"
	},
	{
		x:0.39879999999999999,
		y:0.85809999999999997,
		supporter:"D"
	},
	{
		x:0.52159999999999995,
		y:0.86939999999999995,
		supporter:"D"
	},
	{
		x:0.51990000000000003,
		y:0.88449999999999995,
		supporter:"D"
	},
	{
		x:0.46560000000000001,
		y:0.92730000000000001,
		supporter:"D"
	},
	{
		x:0.33,
		y:0.91,
		supporter:"D"
	},
	{
		x:0.42280000000000001,
		y:0.90249999999999997,
		supporter:"D"
	},
	{
		x:0.49780000000000002,
		y:0.88560000000000005,
		supporter:"D"
	},
	{
		x:0.49099999999999999,
		y:0.93730000000000002,
		supporter:"D"
	},
	{
		x:0.40089999999999998,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.48039999999999999,
		y:0.90010000000000001,
		supporter:"D"
	},
	{
		x:0.4723,
		y:0.93,
		supporter:"D"
	},
	{
		x:0.47310000000000002,
		y:0.93989999999999996,
		supporter:"D"
	},
	{
		x:0.5343,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.39950000000000002,
		y:0.90920000000000001,
		supporter:"D"
	},
	{
		x:0.47439999999999999,
		y:0.93610000000000004,
		supporter:"D"
	},
	{
		x:0.49959999999999999,
		y:0.90159999999999996,
		supporter:"D"
	},
	{
		x:0.57199999999999995,
		y:0.86529999999999996,
		supporter:"D"
	},
	{
		x:0.36890000000000001,
		y:0.89549999999999996,
		supporter:"D"
	},
	{
		x:0.56579999999999997,
		y:0.86939999999999995,
		supporter:"D"
	},
	{
		x:0.48170000000000002,
		y:0.92749999999999999,
		supporter:"D"
	},
	{
		x:0.48930000000000001,
		y:0.90869999999999995,
		supporter:"D"
	},
	{
		x:0.35599999999999998,
		y:0.84399999999999997,
		supporter:"D"
	},
	{
		x:0.52659999999999996,
		y:0.88370000000000004,
		supporter:"D"
	},
	{
		x:0.56579999999999997,
		y:0.87580000000000002,
		supporter:"D"
	},
	{
		x:0.51060000000000005,
		y:0.88729999999999998,
		supporter:"D"
	},
	{
		x:0.48010000000000003,
		y:0.92700000000000005,
		supporter:"D"
	},
	{
		x:0.2878,
		y:0.90449999999999997,
		supporter:"D"
	},
	{
		x:0.33779999999999999,
		y:0.91059999999999997,
		supporter:"D"
	},
	{
		x:0.28749999999999998,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.38750000000000001,
		y:0.91590000000000005,
		supporter:"D"
	},
	{
		x:0.49049999999999999,
		y:0.92769999999999997,
		supporter:"D"
	},
	{
		x:0.4798,
		y:0.88680000000000003,
		supporter:"D"
	},
	{
		x:0.3967,
		y:0.9012,
		supporter:"D"
	},
	{
		x:0.30399999999999999,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.4395,
		y:0.92159999999999997,
		supporter:"D"
	},
	{
		x:0.36159999999999998,
		y:0.83399999999999996,
		supporter:"D"
	},
	{
		x:0.35909999999999997,
		y:0.90469999999999995,
		supporter:"D"
	},
	{
		x:0.61299999999999999,
		y:0.88239999999999996,
		supporter:"D"
	},
	{
		x:0.4874,
		y:0.91790000000000005,
		supporter:"D"
	},
	{
		x:0.59079999999999999,
		y:0.89390000000000003,
		supporter:"D"
	},
	{
		x:0.48099999999999998,
		y:0.92889999999999995,
		supporter:"D"
	},
	{
		x:0.3286,
		y:0.90900000000000003,
		supporter:"D"
	},
	{
		x:0.4889,
		y:0.90939999999999999,
		supporter:"D"
	},
	{
		x:0.36209999999999998,
		y:0.9123,
		supporter:"D"
	},
	{
		x:0.56410000000000005,
		y:0.80549999999999999,
		supporter:"D"
	},
	{
		x:0.48870000000000002,
		y:0.9264,
		supporter:"D"
	},
	{
		x:0.36649999999999999,
		y:0.90539999999999998,
		supporter:"D"
	},
	{
		x:0.31719999999999998,
		y:0.90129999999999999,
		supporter:"D"
	},
	{
		x:0.48959999999999998,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.46150000000000002,
		y:0.91259999999999997,
		supporter:"D"
	},
	{
		x:0.4597,
		y:0.90339999999999998,
		supporter:"D"
	},
	{
		x:0.44640000000000002,
		y:0.91959999999999997,
		supporter:"D"
	},
	{
		x:0.4677,
		y:0.88460000000000005,
		supporter:"D"
	},
	{
		x:0.54459999999999997,
		y:0.86819999999999997,
		supporter:"D"
	},
	{
		x:0.51949999999999996,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.4768,
		y:0.93120000000000003,
		supporter:"D"
	},
	{
		x:0.54649999999999999,
		y:0.88049999999999995,
		supporter:"D"
	},
	{
		x:0.61509999999999998,
		y:0.89410000000000001,
		supporter:"D"
	},
	{
		x:0.44540000000000002,
		y:0.92269999999999996,
		supporter:"D"
	},
	{
		x:0.45739999999999997,
		y:0.92710000000000004,
		supporter:"D"
	},
	{
		x:0.48720000000000002,
		y:0.90500000000000003,
		supporter:"D"
	},
	{
		x:0.54279999999999995,
		y:0.86140000000000005,
		supporter:"D"
	},
	{
		x:0.5101,
		y:0.89049999999999996,
		supporter:"D"
	},
	{
		x:0.39810000000000001,
		y:0.84709999999999996,
		supporter:"D"
	},
	{
		x:0.3533,
		y:0.90580000000000005,
		supporter:"D"
	},
	{
		x:0.46860000000000002,
		y:0.92659999999999998,
		supporter:"D"
	},
	{
		x:0.51029999999999998,
		y:0.86129999999999995,
		supporter:"D"
	},
	{
		x:0.30980000000000002,
		y:0.90549999999999997,
		supporter:"D"
	},
	{
		x:0.58309999999999995,
		y:0.86539999999999995,
		supporter:"D"
	},
	{
		x:0.437,
		y:0.92600000000000005,
		supporter:"D"
	},
	{
		x:0.39240000000000003,
		y:0.85229999999999995,
		supporter:"D"
	},
	{
		x:0.49590000000000001,
		y:0.90200000000000002,
		supporter:"D"
	},
	{
		x:0.45569999999999999,
		y:0.91890000000000005,
		supporter:"D"
	},
	{
		x:0.31230000000000002,
		y:0.9,
		supporter:"D"
	},
	{
		x:0.5252,
		y:0.88959999999999995,
		supporter:"D"
	},
	{
		x:0.3755,
		y:0.84650000000000003,
		supporter:"D"
	},
	{
		x:0.308,
		y:0.90290000000000004,
		supporter:"D"
	},
	{
		x:0.3725,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.57589999999999997,
		y:0.89419999999999999,
		supporter:"D"
	},
	{
		x:0.61739999999999995,
		y:0.79339999999999999,
		supporter:"D"
	},
	{
		x:0.3649,
		y:0.84840000000000004,
		supporter:"D"
	},
	{
		x:0.48060000000000003,
		y:0.9042,
		supporter:"D"
	},
	{
		x:0.5242,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.50129999999999997,
		y:0.88739999999999997,
		supporter:"D"
	},
	{
		x:0.50060000000000004,
		y:0.89239999999999997,
		supporter:"D"
	},
	{
		x:0.4178,
		y:0.85050000000000003,
		supporter:"D"
	},
	{
		x:0.45390000000000003,
		y:0.92959999999999998,
		supporter:"D"
	},
	{
		x:0.498,
		y:0.90669999999999995,
		supporter:"D"
	},
	{
		x:0.46679999999999999,
		y:0.89459999999999995,
		supporter:"D"
	},
	{
		x:0.44719999999999999,
		y:0.92569999999999997,
		supporter:"D"
	},
	{
		x:0.46500000000000002,
		y:0.90669999999999995,
		supporter:"D"
	},
	{
		x:0.35680000000000001,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.53280000000000005,
		y:0.86480000000000001,
		supporter:"D"
	},
	{
		x:0.55000000000000004,
		y:0.86819999999999997,
		supporter:"D"
	},
	{
		x:0.29139999999999999,
		y:0.89239999999999997,
		supporter:"D"
	},
	{
		x:0.45700000000000002,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.53580000000000005,
		y:0.84279999999999999,
		supporter:"D"
	},
	{
		x:0.41260000000000002,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.43990000000000001,
		y:0.92559999999999998,
		supporter:"D"
	},
	{
		x:0.36709999999999998,
		y:0.88800000000000001,
		supporter:"D"
	},
	{
		x:0.37809999999999999,
		y:0.90610000000000002,
		supporter:"D"
	},
	{
		x:0.49109999999999998,
		y:0.88829999999999998,
		supporter:"D"
	},
	{
		x:0.39290000000000003,
		y:0.85880000000000001,
		supporter:"D"
	},
	{
		x:0.52280000000000004,
		y:0.87909999999999999,
		supporter:"D"
	},
	{
		x:0.56599999999999995,
		y:0.89470000000000005,
		supporter:"D"
	},
	{
		x:0.39250000000000002,
		y:0.90359999999999996,
		supporter:"D"
	},
	{
		x:0.61299999999999999,
		y:0.89059999999999995,
		supporter:"D"
	},
	{
		x:0.60189999999999999,
		y:0.81769999999999998,
		supporter:"D"
	},
	{
		x:0.49440000000000001,
		y:0.80740000000000001,
		supporter:"D"
	},
	{
		x:0.43719999999999998,
		y:0.84989999999999999,
		supporter:"D"
	},
	{
		x:0.59670000000000001,
		y:0.81540000000000001,
		supporter:"D"
	},
	{
		x:0.5514,
		y:0.89400000000000002,
		supporter:"D"
	},
	{
		x:0.55449999999999999,
		y:0.87670000000000003,
		supporter:"D"
	},
	{
		x:0.50029999999999997,
		y:0.88670000000000004,
		supporter:"D"
	},
	{
		x:0.48220000000000002,
		y:0.90259999999999996,
		supporter:"D"
	},
	{
		x:0.45960000000000001,
		y:0.89970000000000006,
		supporter:"D"
	},
	{
		x:0.4375,
		y:0.92,
		supporter:"D"
	},
	{
		x:0.45760000000000001,
		y:0.92830000000000001,
		supporter:"D"
	},
	{
		x:0.36880000000000002,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.42509999999999998,
		y:0.90159999999999996,
		supporter:"D"
	},
	{
		x:0.57709999999999995,
		y:0.87350000000000005,
		supporter:"D"
	},
	{
		x:0.55210000000000004,
		y:0.86470000000000002,
		supporter:"D"
	},
	{
		x:0.51819999999999999,
		y:0.89359999999999995,
		supporter:"D"
	},
	{
		x:0.31019999999999998,
		y:0.90859999999999996,
		supporter:"D"
	},
	{
		x:0.46949999999999997,
		y:0.91869999999999996,
		supporter:"D"
	},
	{
		x:0.39069999999999999,
		y:0.84940000000000004,
		supporter:"D"
	},
	{
		x:0.5917,
		y:0.80469999999999997,
		supporter:"D"
	},
	{
		x:0.50829999999999997,
		y:0.8972,
		supporter:"D"
	},
	{
		x:0.40799999999999997,
		y:0.88560000000000005,
		supporter:"D"
	},
	{
		x:0.51119999999999999,
		y:0.88270000000000004,
		supporter:"D"
	},
	{
		x:0.4884,
		y:0.90239999999999998,
		supporter:"D"
	},
	{
		x:0.5806,
		y:0.85980000000000001,
		supporter:"D"
	},
	{
		x:0.36320000000000002,
		y:0.90859999999999996,
		supporter:"D"
	},
	{
		x:0.62949999999999995,
		y:0.8831,
		supporter:"D"
	},
	{
		x:0.48649999999999999,
		y:0.90269999999999995,
		supporter:"D"
	},
	{
		x:0.624,
		y:0.88280000000000003,
		supporter:"D"
	},
	{
		x:0.48370000000000002,
		y:0.90129999999999999,
		supporter:"D"
	},
	{
		x:0.31840000000000002,
		y:0.90980000000000005,
		supporter:"D"
	},
	{
		x:0.49880000000000002,
		y:0.93300000000000005,
		supporter:"D"
	},
	{
		x:0.44550000000000001,
		y:0.92649999999999999,
		supporter:"D"
	},
	{
		x:0.43109999999999998,
		y:0.9103,
		supporter:"D"
	},
	{
		x:0.53320000000000001,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.57550000000000001,
		y:0.87180000000000002,
		supporter:"D"
	},
	{
		x:0.4768,
		y:0.92530000000000001,
		supporter:"D"
	},
	{
		x:0.48530000000000001,
		y:0.91369999999999996,
		supporter:"D"
	},
	{
		x:0.44890000000000002,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.54590000000000005,
		y:0.89639999999999997,
		supporter:"D"
	},
	{
		x:0.30599999999999999,
		y:0.90229999999999999,
		supporter:"D"
	},
	{
		x:0.45600000000000002,
		y:0.92410000000000003,
		supporter:"D"
	},
	{
		x:0.57709999999999995,
		y:0.82489999999999997,
		supporter:"D"
	},
	{
		x:0.51149999999999995,
		y:0.89190000000000003,
		supporter:"D"
	},
	{
		x:0.32590000000000002,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.58130000000000004,
		y:0.87460000000000004,
		supporter:"D"
	},
	{
		x:0.4365,
		y:0.89739999999999998,
		supporter:"D"
	},
	{
		x:0.40479999999999999,
		y:0.90680000000000005,
		supporter:"D"
	},
	{
		x:0.44540000000000002,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.51449999999999996,
		y:0.89829999999999999,
		supporter:"D"
	},
	{
		x:0.45129999999999998,
		y:0.91830000000000001,
		supporter:"D"
	},
	{
		x:0.47989999999999999,
		y:0.92220000000000002,
		supporter:"D"
	},
	{
		x:0.42970000000000003,
		y:0.9143,
		supporter:"D"
	},
	{
		x:0.3856,
		y:0.85840000000000005,
		supporter:"D"
	},
	{
		x:0.41299999999999998,
		y:0.91569999999999996,
		supporter:"D"
	},
	{
		x:0.48220000000000002,
		y:0.90059999999999996,
		supporter:"D"
	},
	{
		x:0.56200000000000006,
		y:0.87639999999999996,
		supporter:"D"
	},
	{
		x:0.57599999999999996,
		y:0.86109999999999998,
		supporter:"D"
	},
	{
		x:0.52639999999999998,
		y:0.89970000000000006,
		supporter:"D"
	},
	{
		x:0.50460000000000005,
		y:0.88849999999999996,
		supporter:"D"
	},
	{
		x:0.47289999999999999,
		y:0.91020000000000001,
		supporter:"D"
	},
	{
		x:0.5141,
		y:0.88029999999999997,
		supporter:"D"
	},
	{
		x:0.30769999999999997,
		y:0.90159999999999996,
		supporter:"D"
	},
	{
		x:0.5111,
		y:0.8488,
		supporter:"D"
	},
	{
		x:0.42730000000000001,
		y:0.90329999999999999,
		supporter:"D"
	},
	{
		x:0.30690000000000001,
		y:0.91159999999999997,
		supporter:"D"
	},
	{
		x:0.54820000000000002,
		y:0.87860000000000005,
		supporter:"D"
	},
	{
		x:0.30730000000000002,
		y:0.90369999999999995,
		supporter:"D"
	},
	{
		x:0.57799999999999996,
		y:0.86040000000000005,
		supporter:"D"
	},
	{
		x:0.53239999999999998,
		y:0.88360000000000005,
		supporter:"D"
	},
	{
		x:0.58499999999999996,
		y:0.79830000000000001,
		supporter:"D"
	},
	{
		x:0.38619999999999999,
		y:0.85660000000000003,
		supporter:"D"
	},
	{
		x:0.41889999999999999,
		y:0.9073,
		supporter:"D"
	},
	{
		x:0.37419999999999998,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.50739999999999996,
		y:0.88829999999999998,
		supporter:"D"
	},
	{
		x:0.42720000000000002,
		y:0.92720000000000002,
		supporter:"D"
	},
	{
		x:0.51370000000000005,
		y:0.88739999999999997,
		supporter:"D"
	},
	{
		x:0.56320000000000003,
		y:0.89490000000000003,
		supporter:"D"
	},
	{
		x:0.46150000000000002,
		y:0.90680000000000005,
		supporter:"D"
	},
	{
		x:0.61270000000000002,
		y:0.82150000000000001,
		supporter:"D"
	},
	{
		x:0.52170000000000005,
		y:0.89280000000000004,
		supporter:"D"
	},
	{
		x:0.32690000000000002,
		y:0.90939999999999999,
		supporter:"D"
	},
	{
		x:0.45390000000000003,
		y:0.92230000000000001,
		supporter:"D"
	},
	{
		x:0.41930000000000001,
		y:0.90049999999999997,
		supporter:"D"
	},
	{
		x:0.47849999999999998,
		y:0.88790000000000002,
		supporter:"D"
	},
	{
		x:0.4501,
		y:0.92800000000000005,
		supporter:"D"
	},
	{
		x:0.3528,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.56499999999999995,
		y:0.81100000000000005,
		supporter:"D"
	},
	{
		x:0.49330000000000002,
		y:0.93069999999999997,
		supporter:"D"
	},
	{
		x:0.41560000000000002,
		y:0.89439999999999997,
		supporter:"D"
	},
	{
		x:0.55400000000000005,
		y:0.88180000000000003,
		supporter:"D"
	},
	{
		x:0.44390000000000002,
		y:0.89749999999999996,
		supporter:"D"
	},
	{
		x:0.30740000000000001,
		y:0.90269999999999995,
		supporter:"D"
	},
	{
		x:0.44740000000000002,
		y:0.90620000000000001,
		supporter:"D"
	},
	{
		x:0.56950000000000001,
		y:0.89419999999999999,
		supporter:"D"
	},
	{
		x:0.29520000000000002,
		y:0.90269999999999995,
		supporter:"D"
	},
	{
		x:0.28810000000000002,
		y:0.90180000000000005,
		supporter:"D"
	},
	{
		x:0.31890000000000002,
		y:0.90229999999999999,
		supporter:"D"
	},
	{
		x:0.30180000000000001,
		y:0.90659999999999996,
		supporter:"D"
	},
	{
		x:0.45679999999999998,
		y:0.91779999999999995,
		supporter:"D"
	},
	{
		x:0.41610000000000003,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.54810000000000003,
		y:0.89490000000000003,
		supporter:"D"
	},
	{
		x:0.45600000000000002,
		y:0.92249999999999999,
		supporter:"D"
	},
	{
		x:0.58099999999999996,
		y:0.86860000000000004,
		supporter:"D"
	},
	{
		x:0.4672,
		y:0.88990000000000002,
		supporter:"D"
	},
	{
		x:0.60589999999999999,
		y:0.89149999999999996,
		supporter:"D"
	},
	{
		x:0.52959999999999996,
		y:0.85399999999999998,
		supporter:"D"
	},
	{
		x:0.47889999999999999,
		y:0.88690000000000002,
		supporter:"D"
	},
	{
		x:0.46760000000000002,
		y:0.92889999999999995,
		supporter:"D"
	},
	{
		x:0.51019999999999999,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.48659999999999998,
		y:0.90739999999999998,
		supporter:"D"
	},
	{
		x:0.49030000000000001,
		y:0.92789999999999995,
		supporter:"D"
	},
	{
		x:0.51739999999999997,
		y:0.87509999999999999,
		supporter:"D"
	},
	{
		x:0.49349999999999999,
		y:0.80359999999999998,
		supporter:"D"
	},
	{
		x:0.31509999999999999,
		y:0.89229999999999998,
		supporter:"D"
	},
	{
		x:0.59399999999999997,
		y:0.80059999999999998,
		supporter:"D"
	},
	{
		x:0.53580000000000005,
		y:0.8982,
		supporter:"D"
	},
	{
		x:0.32800000000000001,
		y:0.90429999999999999,
		supporter:"D"
	},
	{
		x:0.52510000000000001,
		y:0.89239999999999997,
		supporter:"D"
	},
	{
		x:0.47899999999999998,
		y:0.90029999999999999,
		supporter:"D"
	},
	{
		x:0.47070000000000001,
		y:0.91879999999999995,
		supporter:"D"
	},
	{
		x:0.32850000000000001,
		y:0.91039999999999999,
		supporter:"D"
	},
	{
		x:0.46660000000000001,
		y:0.93310000000000004,
		supporter:"D"
	},
	{
		x:0.36809999999999998,
		y:0.91469999999999996,
		supporter:"D"
	},
	{
		x:0.46139999999999998,
		y:0.93259999999999998,
		supporter:"D"
	},
	{
		x:0.45279999999999998,
		y:0.85899999999999999,
		supporter:"D"
	},
	{
		x:0.54769999999999996,
		y:0.8589,
		supporter:"D"
	},
	{
		x:0.62429999999999997,
		y:0.88219999999999998,
		supporter:"D"
	},
	{
		x:0.56569999999999998,
		y:0.8579,
		supporter:"D"
	},
	{
		x:0.49569999999999997,
		y:0.8911,
		supporter:"D"
	},
	{
		x:0.29370000000000002,
		y:0.90629999999999999,
		supporter:"D"
	},
	{
		x:0.45040000000000002,
		y:0.92710000000000004,
		supporter:"D"
	},
	{
		x:0.4793,
		y:0.8972,
		supporter:"D"
	},
	{
		x:0.40379999999999999,
		y:0.85250000000000004,
		supporter:"D"
	},
	{
		x:0.50209999999999999,
		y:0.87350000000000005,
		supporter:"D"
	},
	{
		x:0.53890000000000005,
		y:0.88329999999999997,
		supporter:"D"
	},
	{
		x:0.59079999999999999,
		y:0.86760000000000004,
		supporter:"D"
	},
	{
		x:0.49170000000000003,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.46700000000000003,
		y:0.91110000000000002,
		supporter:"D"
	},
	{
		x:0.38469999999999999,
		y:0.8528,
		supporter:"D"
	},
	{
		x:0.40550000000000003,
		y:0.9052,
		supporter:"D"
	},
	{
		x:0.44419999999999998,
		y:0.91959999999999997,
		supporter:"D"
	},
	{
		x:0.52080000000000004,
		y:0.87070000000000003,
		supporter:"D"
	},
	{
		x:0.49419999999999997,
		y:0.90559999999999996,
		supporter:"D"
	},
	{
		x:0.48060000000000003,
		y:0.90149999999999997,
		supporter:"D"
	},
	{
		x:0.32769999999999999,
		y:0.90400000000000003,
		supporter:"D"
	},
	{
		x:0.44180000000000003,
		y:0.92649999999999999,
		supporter:"D"
	},
	{
		x:0.56589999999999996,
		y:0.85960000000000003,
		supporter:"D"
	},
	{
		x:0.36249999999999999,
		y:0.91080000000000005,
		supporter:"D"
	},
	{
		x:0.49780000000000002,
		y:0.93869999999999998,
		supporter:"D"
	},
	{
		x:0.47110000000000002,
		y:0.88470000000000004,
		supporter:"D"
	},
	{
		x:0.4854,
		y:0.9264,
		supporter:"D"
	},
	{
		x:0.56040000000000001,
		y:0.8155,
		supporter:"D"
	},
	{
		x:0.52459999999999996,
		y:0.80479999999999996,
		supporter:"D"
	},
	{
		x:0.41039999999999999,
		y:0.89870000000000005,
		supporter:"D"
	},
	{
		x:0.42180000000000001,
		y:0.9123,
		supporter:"D"
	},
	{
		x:0.45519999999999999,
		y:0.91879999999999995,
		supporter:"D"
	},
	{
		x:0.3085,
		y:0.90790000000000004,
		supporter:"D"
	},
	{
		x:0.45600000000000002,
		y:0.92479999999999996,
		supporter:"D"
	},
	{
		x:0.58240000000000003,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.56530000000000002,
		y:0.85650000000000004,
		supporter:"D"
	},
	{
		x:0.53400000000000003,
		y:0.85240000000000005,
		supporter:"D"
	},
	{
		x:0.56889999999999996,
		y:0.82569999999999999,
		supporter:"D"
	},
	{
		x:0.62829999999999997,
		y:0.89690000000000003,
		supporter:"D"
	},
	{
		x:0.51559999999999995,
		y:0.86519999999999997,
		supporter:"D"
	},
	{
		x:0.35349999999999998,
		y:0.89119999999999999,
		supporter:"D"
	},
	{
		x:0.47049999999999997,
		y:0.8518,
		supporter:"D"
	},
	{
		x:0.44940000000000002,
		y:0.89859999999999995,
		supporter:"D"
	},
	{
		x:0.58020000000000005,
		y:0.8962,
		supporter:"D"
	},
	{
		x:0.51180000000000003,
		y:0.86219999999999997,
		supporter:"D"
	},
	{
		x:0.4929,
		y:0.91239999999999999,
		supporter:"D"
	},
	{
		x:0.38690000000000002,
		y:0.89839999999999998,
		supporter:"D"
	},
	{
		x:0.42730000000000001,
		y:0.86099999999999999,
		supporter:"D"
	},
	{
		x:0.498,
		y:0.8871,
		supporter:"D"
	},
	{
		x:0.54510000000000003,
		y:0.87809999999999999,
		supporter:"D"
	},
	{
		x:0.56820000000000004,
		y:0.78559999999999997,
		supporter:"D"
	},
	{
		x:0.49590000000000001,
		y:0.90920000000000001,
		supporter:"D"
	},
	{
		x:0.3322,
		y:0.91600000000000004,
		supporter:"D"
	},
	{
		x:0.47639999999999999,
		y:0.92989999999999995,
		supporter:"D"
	},
	{
		x:0.42730000000000001,
		y:0.92100000000000004,
		supporter:"D"
	},
	{
		x:0.29330000000000001,
		y:0.90849999999999997,
		supporter:"D"
	},
	{
		x:0.49869999999999998,
		y:0.88549999999999995,
		supporter:"D"
	},
	{
		x:0.4824,
		y:0.93620000000000003,
		supporter:"D"
	},
	{
		x:0.4405,
		y:0.92330000000000001,
		supporter:"D"
	},
	{
		x:0.57879999999999998,
		y:0.79859999999999998,
		supporter:"D"
	},
	{
		x:0.29220000000000002,
		y:0.90569999999999995,
		supporter:"D"
	},
	{
		x:0.5343,
		y:0.88549999999999995,
		supporter:"D"
	},
	{
		x:0.51529999999999998,
		y:0.86560000000000004,
		supporter:"D"
	},
	{
		x:0.4612,
		y:0.92249999999999999,
		supporter:"D"
	},
	{
		x:0.49080000000000001,
		y:0.94089999999999996,
		supporter:"D"
	},
	{
		x:0.57589999999999997,
		y:0.89359999999999995,
		supporter:"D"
	},
	{
		x:0.49509999999999998,
		y:0.9224,
		supporter:"D"
	},
	{
		x:0.47199999999999998,
		y:0.92820000000000003,
		supporter:"D"
	},
	{
		x:0.45290000000000002,
		y:0.90429999999999999,
		supporter:"D"
	},
	{
		x:0.3231,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.53280000000000005,
		y:0.87309999999999999,
		supporter:"D"
	},
	{
		x:0.54990000000000006,
		y:0.8125,
		supporter:"D"
	},
	{
		x:0.47789999999999999,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.31280000000000002,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.34289999999999998,
		y:0.9042,
		supporter:"D"
	},
	{
		x:0.46260000000000001,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.56010000000000004,
		y:0.89639999999999997,
		supporter:"D"
	},
	{
		x:0.49959999999999999,
		y:0.92090000000000005,
		supporter:"D"
	},
	{
		x:0.48570000000000002,
		y:0.93,
		supporter:"D"
	},
	{
		x:0.41789999999999999,
		y:0.9012,
		supporter:"D"
	},
	{
		x:0.3528,
		y:0.90690000000000004,
		supporter:"D"
	},
	{
		x:0.50529999999999997,
		y:0.89200000000000002,
		supporter:"D"
	},
	{
		x:0.41139999999999999,
		y:0.9153,
		supporter:"D"
	},
	{
		x:0.30420000000000003,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.49769999999999998,
		y:0.92789999999999995,
		supporter:"D"
	},
	{
		x:0.34250000000000003,
		y:0.89990000000000003,
		supporter:"D"
	},
	{
		x:0.48099999999999998,
		y:0.87590000000000001,
		supporter:"D"
	},
	{
		x:0.4803,
		y:0.87990000000000002,
		supporter:"D"
	},
	{
		x:0.44890000000000002,
		y:0.92100000000000004,
		supporter:"D"
	},
	{
		x:0.51290000000000002,
		y:0.8911,
		supporter:"D"
	},
	{
		x:0.43609999999999999,
		y:0.92710000000000004,
		supporter:"D"
	},
	{
		x:0.48089999999999999,
		y:0.88439999999999996,
		supporter:"D"
	},
	{
		x:0.36599999999999999,
		y:0.84889999999999999,
		supporter:"D"
	},
	{
		x:0.32679999999999998,
		y:0.90149999999999997,
		supporter:"D"
	},
	{
		x:0.41820000000000002,
		y:0.85699999999999998,
		supporter:"D"
	},
	{
		x:0.61380000000000001,
		y:0.88660000000000005,
		supporter:"D"
	},
	{
		x:0.51559999999999995,
		y:0.89829999999999999,
		supporter:"D"
	},
	{
		x:0.57909999999999995,
		y:0.85450000000000004,
		supporter:"D"
	},
	{
		x:0.53690000000000004,
		y:0.87609999999999999,
		supporter:"D"
	},
	{
		x:0.44359999999999999,
		y:0.9224,
		supporter:"D"
	},
	{
		x:0.3735,
		y:0.90400000000000003,
		supporter:"D"
	},
	{
		x:0.3967,
		y:0.84919999999999995,
		supporter:"D"
	},
	{
		x:0.55130000000000001,
		y:0.85299999999999998,
		supporter:"D"
	},
	{
		x:0.5645,
		y:0.88400000000000001,
		supporter:"D"
	},
	{
		x:0.54349999999999998,
		y:0.85370000000000001,
		supporter:"D"
	},
	{
		x:0.51470000000000005,
		y:0.89070000000000005,
		supporter:"D"
	},
	{
		x:0.52500000000000002,
		y:0.89480000000000004,
		supporter:"D"
	},
	{
		x:0.47160000000000002,
		y:0.9234,
		supporter:"D"
	},
	{
		x:0.3175,
		y:0.90720000000000001,
		supporter:"D"
	},
	{
		x:0.5726,
		y:0.87760000000000005,
		supporter:"D"
	},
	{
		x:0.48180000000000001,
		y:0.92520000000000002,
		supporter:"D"
	},
	{
		x:0.46610000000000001,
		y:0.90100000000000002,
		supporter:"D"
	},
	{
		x:0.497,
		y:0.80220000000000002,
		supporter:"D"
	},
	{
		x:0.28010000000000002,
		y:0.91039999999999999,
		supporter:"D"
	},
	{
		x:0.47799999999999998,
		y:0.90900000000000003,
		supporter:"D"
	},
	{
		x:0.4975,
		y:0.90100000000000002,
		supporter:"D"
	},
	{
		x:0.60740000000000005,
		y:0.81599999999999995,
		supporter:"D"
	},
	{
		x:0.53010000000000002,
		y:0.88629999999999998,
		supporter:"D"
	},
	{
		x:0.52029999999999998,
		y:0.88600000000000001,
		supporter:"D"
	},
	{
		x:0.47839999999999999,
		y:0.88900000000000001,
		supporter:"D"
	},
	{
		x:0.57179999999999997,
		y:0.87490000000000001,
		supporter:"D"
	},
	{
		x:0.621,
		y:0.79469999999999996,
		supporter:"D"
	},
	{
		x:0.52300000000000002,
		y:0.89480000000000004,
		supporter:"D"
	},
	{
		x:0.35120000000000001,
		y:0.9173,
		supporter:"D"
	},
	{
		x:0.33839999999999998,
		y:0.90200000000000002,
		supporter:"D"
	},
	{
		x:0.434,
		y:0.85970000000000002,
		supporter:"D"
	},
	{
		x:0.35930000000000001,
		y:0.90869999999999995,
		supporter:"D"
	},
	{
		x:0.49330000000000002,
		y:0.92600000000000005,
		supporter:"D"
	},
	{
		x:0.41289999999999999,
		y:0.90659999999999996,
		supporter:"D"
	},
	{
		x:0.33700000000000002,
		y:0.90400000000000003,
		supporter:"D"
	},
	{
		x:0.37340000000000001,
		y:0.85389999999999999,
		supporter:"D"
	},
	{
		x:0.3296,
		y:0.90280000000000005,
		supporter:"D"
	},
	{
		x:0.47689999999999999,
		y:0.90200000000000002,
		supporter:"D"
	},
	{
		x:0.35210000000000002,
		y:0.90269999999999995,
		supporter:"D"
	},
	{
		x:0.56340000000000001,
		y:0.81069999999999998,
		supporter:"D"
	},
	{
		x:0.46439999999999998,
		y:0.88859999999999995,
		supporter:"D"
	},
	{
		x:0.31990000000000002,
		y:0.90610000000000002,
		supporter:"D"
	},
	{
		x:0.46350000000000002,
		y:0.93389999999999995,
		supporter:"D"
	},
	{
		x:0.49270000000000003,
		y:0.91159999999999997,
		supporter:"D"
	},
	{
		x:0.47599999999999998,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.47289999999999999,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.53480000000000005,
		y:0.84909999999999997,
		supporter:"D"
	},
	{
		x:0.44990000000000002,
		y:0.9093,
		supporter:"D"
	},
	{
		x:0.60850000000000004,
		y:0.81610000000000005,
		supporter:"D"
	},
	{
		x:0.53310000000000002,
		y:0.87570000000000003,
		supporter:"D"
	},
	{
		x:0.35260000000000002,
		y:0.90790000000000004,
		supporter:"D"
	},
	{
		x:0.35630000000000001,
		y:0.89749999999999996,
		supporter:"D"
	},
	{
		x:0.60350000000000004,
		y:0.84230000000000005,
		supporter:"D"
	},
	{
		x:0.42480000000000001,
		y:0.91320000000000001,
		supporter:"D"
	},
	{
		x:0.49759999999999999,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.58460000000000001,
		y:0.80800000000000005,
		supporter:"D"
	},
	{
		x:0.62609999999999999,
		y:0.88790000000000002,
		supporter:"D"
	},
	{
		x:0.47520000000000001,
		y:0.9,
		supporter:"D"
	},
	{
		x:0.29559999999999997,
		y:0.89390000000000003,
		supporter:"D"
	},
	{
		x:0.44390000000000002,
		y:0.89780000000000004,
		supporter:"D"
	},
	{
		x:0.29549999999999998,
		y:0.90710000000000002,
		supporter:"D"
	},
	{
		x:0.37309999999999999,
		y:0.90269999999999995,
		supporter:"D"
	},
	{
		x:0.52390000000000003,
		y:0.87119999999999997,
		supporter:"D"
	},
	{
		x:0.4264,
		y:0.89170000000000005,
		supporter:"D"
	},
	{
		x:0.59309999999999996,
		y:0.79910000000000003,
		supporter:"D"
	},
	{
		x:0.48820000000000002,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.53500000000000003,
		y:0.86929999999999996,
		supporter:"D"
	},
	{
		x:0.5131,
		y:0.89539999999999997,
		supporter:"D"
	},
	{
		x:0.47799999999999998,
		y:0.92120000000000002,
		supporter:"D"
	},
	{
		x:0.45879999999999999,
		y:0.92620000000000002,
		supporter:"D"
	},
	{
		x:0.44569999999999999,
		y:0.92059999999999997,
		supporter:"D"
	},
	{
		x:0.54520000000000002,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.46239999999999998,
		y:0.85270000000000001,
		supporter:"D"
	},
	{
		x:0.43,
		y:0.8478,
		supporter:"D"
	},
	{
		x:0.4924,
		y:0.9294,
		supporter:"D"
	},
	{
		x:0.36009999999999998,
		y:0.85260000000000002,
		supporter:"D"
	},
	{
		x:0.4884,
		y:0.91200000000000003,
		supporter:"D"
	},
	{
		x:0.56899999999999995,
		y:0.85660000000000003,
		supporter:"D"
	},
	{
		x:0.47989999999999999,
		y:0.92449999999999999,
		supporter:"D"
	},
	{
		x:0.42199999999999999,
		y:0.86060000000000003,
		supporter:"D"
	},
	{
		x:0.57509999999999994,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.29039999999999999,
		y:0.90990000000000004,
		supporter:"D"
	},
	{
		x:0.49609999999999999,
		y:0.88219999999999998,
		supporter:"D"
	},
	{
		x:0.34960000000000002,
		y:0.91839999999999999,
		supporter:"D"
	},
	{
		x:0.3967,
		y:0.85089999999999999,
		supporter:"D"
	},
	{
		x:0.47099999999999997,
		y:0.91910000000000003,
		supporter:"D"
	},
	{
		x:0.50319999999999998,
		y:0.89749999999999996,
		supporter:"D"
	},
	{
		x:0.59379999999999999,
		y:0.81979999999999997,
		supporter:"D"
	},
	{
		x:0.49419999999999997,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.45979999999999999,
		y:0.92230000000000001,
		supporter:"D"
	},
	{
		x:0.44230000000000003,
		y:0.9,
		supporter:"D"
	},
	{
		x:0.57779999999999998,
		y:0.80869999999999997,
		supporter:"D"
	},
	{
		x:0.53680000000000005,
		y:0.88660000000000005,
		supporter:"D"
	},
	{
		x:0.48870000000000002,
		y:0.88949999999999996,
		supporter:"D"
	},
	{
		x:0.49270000000000003,
		y:0.93179999999999996,
		supporter:"D"
	},
	{
		x:0.61880000000000002,
		y:0.89710000000000001,
		supporter:"D"
	},
	{
		x:0.61799999999999999,
		y:0.89159999999999995,
		supporter:"D"
	},
	{
		x:0.43640000000000001,
		y:0.91379999999999995,
		supporter:"D"
	},
	{
		x:0.47739999999999999,
		y:0.92010000000000003,
		supporter:"D"
	},
	{
		x:0.49270000000000003,
		y:0.92810000000000004,
		supporter:"D"
	},
	{
		x:0.56289999999999996,
		y:0.87649999999999995,
		supporter:"D"
	},
	{
		x:0.57699999999999996,
		y:0.87439999999999996,
		supporter:"D"
	},
	{
		x:0.59279999999999999,
		y:0.86050000000000004,
		supporter:"D"
	},
	{
		x:0.56220000000000003,
		y:0.85289999999999999,
		supporter:"D"
	},
	{
		x:0.4395,
		y:0.92320000000000002,
		supporter:"D"
	},
	{
		x:0.46100000000000002,
		y:0.91910000000000003,
		supporter:"D"
	},
	{
		x:0.49209999999999998,
		y:0.90300000000000002,
		supporter:"D"
	},
	{
		x:0.33610000000000001,
		y:0.90769999999999995,
		supporter:"D"
	},
	{
		x:0.46639999999999998,
		y:0.90039999999999998,
		supporter:"D"
	},
	{
		x:0.54490000000000005,
		y:0.81110000000000004,
		supporter:"D"
	},
	{
		x:0.49459999999999998,
		y:0.91080000000000005,
		supporter:"D"
	},
	{
		x:0.48870000000000002,
		y:0.90839999999999999,
		supporter:"D"
	},
	{
		x:0.50939999999999996,
		y:0.83889999999999998,
		supporter:"D"
	},
	{
		x:0.48039999999999999,
		y:0.92630000000000001,
		supporter:"D"
	},
	{
		x:0.46060000000000001,
		y:0.90659999999999996,
		supporter:"D"
	},
	{
		x:0.31509999999999999,
		y:0.9113,
		supporter:"D"
	},
	{
		x:0.35070000000000001,
		y:0.90969999999999995,
		supporter:"D"
	},
	{
		x:0.34439999999999998,
		y:0.9032,
		supporter:"D"
	},
	{
		x:0.50660000000000005,
		y:0.86229999999999996,
		supporter:"D"
	},
	{
		x:0.59760000000000002,
		y:0.80259999999999998,
		supporter:"D"
	},
	{
		x:0.51780000000000004,
		y:0.81520000000000004,
		supporter:"D"
	},
	{
		x:0.62909999999999999,
		y:0.79100000000000004,
		supporter:"D"
	},
	{
		x:0.62450000000000006,
		y:0.8962,
		supporter:"D"
	},
	{
		x:0.47720000000000001,
		y:0.92269999999999996,
		supporter:"D"
	},
	{
		x:0.3397,
		y:0.89980000000000004,
		supporter:"D"
	},
	{
		x:0.49120000000000003,
		y:0.90839999999999999,
		supporter:"D"
	},
	{
		x:0.42570000000000002,
		y:0.9153,
		supporter:"D"
	},
	{
		x:0.49769999999999998,
		y:0.91300000000000003,
		supporter:"D"
	},
	{
		x:0.44269999999999998,
		y:0.92490000000000006,
		supporter:"D"
	},
	{
		x:0.43640000000000001,
		y:0.9002,
		supporter:"D"
	},
	{
		x:0.41889999999999999,
		y:0.92020000000000002,
		supporter:"D"
	},
	{
		x:0.46879999999999999,
		y:0.89639999999999997,
		supporter:"D"
	},
	{
		x:0.46479999999999999,
		y:0.88149999999999995,
		supporter:"D"
	},
	{
		x:0.4708,
		y:0.92220000000000002,
		supporter:"D"
	},
	{
		x:0.4632,
		y:0.92430000000000001,
		supporter:"D"
	},
	{
		x:0.44600000000000001,
		y:0.92120000000000002,
		supporter:"D"
	},
	{
		x:0.35620000000000002,
		y:0.90490000000000004,
		supporter:"D"
	},
	{
		x:0.48830000000000001,
		y:0.90549999999999997,
		supporter:"D"
	},
	{
		x:0.2898,
		y:0.90059999999999996,
		supporter:"D"
	},
	{
		x:0.43640000000000001,
		y:0.91690000000000005,
		supporter:"D"
	},
	{
		x:0.55649999999999999,
		y:0.80789999999999995,
		supporter:"D"
	},
	{
		x:0.45019999999999999,
		y:0.89749999999999996,
		supporter:"D"
	},
	{
		x:0.58450000000000002,
		y:0.89270000000000005,
		supporter:"D"
	},
	{
		x:0.34160000000000001,
		y:0.90680000000000005,
		supporter:"D"
	},
	{
		x:0.49619999999999997,
		y:0.89780000000000004,
		supporter:"D"
	},
	{
		x:0.49299999999999999,
		y:0.88700000000000001,
		supporter:"D"
	},
	{
		x:0.38569999999999999,
		y:0.84740000000000004,
		supporter:"D"
	},
	{
		x:0.34110000000000001,
		y:0.90139999999999998,
		supporter:"D"
	},
	{
		x:0.57540000000000002,
		y:0.89670000000000005,
		supporter:"D"
	},
	{
		x:0.56930000000000003,
		y:0.85940000000000005,
		supporter:"D"
	},
	{
		x:0.61890000000000001,
		y:0.81059999999999999,
		supporter:"D"
	},
	{
		x:0.3755,
		y:0.90659999999999996,
		supporter:"D"
	},
	{
		x:0.41499999999999998,
		y:0.86,
		supporter:"D"
	},
	{
		x:0.54510000000000003,
		y:0.86990000000000001,
		supporter:"D"
	},
	{
		x:0.42649999999999999,
		y:0.85499999999999998,
		supporter:"D"
	},
	{
		x:0.623,
		y:0.82369999999999999,
		supporter:"D"
	},
	{
		x:0.31730000000000003,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.44350000000000001,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.3296,
		y:0.90890000000000004,
		supporter:"D"
	},
	{
		x:0.37259999999999999,
		y:0.89439999999999997,
		supporter:"D"
	},
	{
		x:0.31440000000000001,
		y:0.90510000000000002,
		supporter:"D"
	},
	{
		x:0.4778,
		y:0.8458,
		supporter:"D"
	},
	{
		x:0.50619999999999998,
		y:0.83750000000000002,
		supporter:"D"
	},
	{
		x:0.51819999999999999,
		y:0.85880000000000001,
		supporter:"D"
	},
	{
		x:0.5927,
		y:0.77639999999999998,
		supporter:"D"
	},
	{
		x:0.50390000000000001,
		y:0.8498,
		supporter:"D"
	},
	{
		x:0.34560000000000002,
		y:0.90610000000000002,
		supporter:"D"
	},
	{
		x:0.3488,
		y:0.91700000000000004,
		supporter:"D"
	},
	{
		x:0.45319999999999999,
		y:0.90859999999999996,
		supporter:"D"
	},
	{
		x:0.43840000000000001,
		y:0.86,
		supporter:"D"
	},
	{
		x:0.53710000000000002,
		y:0.85799999999999998,
		supporter:"D"
	},
	{
		x:0.499,
		y:0.91379999999999995,
		supporter:"D"
	},
	{
		x:0.43890000000000001,
		y:0.91820000000000002,
		supporter:"D"
	},
	{
		x:0.59089999999999998,
		y:0.86899999999999999,
		supporter:"D"
	},
	{
		x:0.37940000000000002,
		y:0.91359999999999997,
		supporter:"D"
	},
	{
		x:0.54890000000000005,
		y:0.88129999999999997,
		supporter:"D"
	},
	{
		x:0.44529999999999997,
		y:0.93300000000000005,
		supporter:"D"
	},
	{
		x:0.42520000000000002,
		y:0.91820000000000002,
		supporter:"D"
	},
	{
		x:0.4178,
		y:0.89259999999999995,
		supporter:"D"
	},
	{
		x:0.56240000000000001,
		y:0.86380000000000001,
		supporter:"D"
	},
	{
		x:0.52039999999999997,
		y:0.88660000000000005,
		supporter:"D"
	},
	{
		x:0.3876,
		y:0.90100000000000002,
		supporter:"D"
	},
	{
		x:0.57840000000000003,
		y:0.86439999999999995,
		supporter:"D"
	},
	{
		x:0.31840000000000002,
		y:0.90700000000000003,
		supporter:"D"
	},
	{
		x:0.50660000000000005,
		y:0.88759999999999994,
		supporter:"D"
	},
	{
		x:0.54910000000000003,
		y:0.89590000000000003,
		supporter:"D"
	},
	{
		x:0.4985,
		y:0.88300000000000001,
		supporter:"D"
	},
	{
		x:0.32479999999999998,
		y:0.90620000000000001,
		supporter:"D"
	},
	{
		x:0.48520000000000002,
		y:0.9234,
		supporter:"D"
	},
	{
		x:0.60960000000000003,
		y:0.82150000000000001,
		supporter:"D"
	},
	{
		x:0.4677,
		y:0.88119999999999998,
		supporter:"D"
	},
	{
		x:0.35470000000000002,
		y:0.88970000000000005,
		supporter:"D"
	},
	{
		x:0.37030000000000002,
		y:0.90990000000000004,
		supporter:"D"
	},
	{
		x:0.35770000000000002,
		y:0.84119999999999995,
		supporter:"D"
	},
	{
		x:0.46689999999999998,
		y:0.91759999999999997,
		supporter:"D"
	},
	{
		x:0.34839999999999999,
		y:0.91779999999999995,
		supporter:"D"
	},
	{
		x:0.54649999999999999,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.47549999999999998,
		y:0.87919999999999998,
		supporter:"D"
	},
	{
		x:0.378,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.3306,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.43459999999999999,
		y:0.89839999999999998,
		supporter:"D"
	},
	{
		x:0.40400000000000003,
		y:0.89839999999999998,
		supporter:"D"
	},
	{
		x:0.4501,
		y:0.92249999999999999,
		supporter:"D"
	},
	{
		x:0.54220000000000002,
		y:0.89580000000000004,
		supporter:"D"
	},
	{
		x:0.53420000000000001,
		y:0.88029999999999997,
		supporter:"D"
	},
	{
		x:0.52339999999999998,
		y:0.86150000000000004,
		supporter:"D"
	},
	{
		x:0.4405,
		y:0.92410000000000003,
		supporter:"D"
	},
	{
		x:0.27029999999999998,
		y:0.89139999999999997,
		supporter:"D"
	},
	{
		x:0.54749999999999999,
		y:0.875,
		supporter:"D"
	},
	{
		x:0.52400000000000002,
		y:0.89790000000000003,
		supporter:"D"
	},
	{
		x:0.46839999999999998,
		y:0.89200000000000002,
		supporter:"D"
	},
	{
		x:0.60019999999999996,
		y:0.83779999999999999,
		supporter:"D"
	},
	{
		x:0.49709999999999999,
		y:0.91910000000000003,
		supporter:"D"
	},
	{
		x:0.48609999999999998,
		y:0.88590000000000002,
		supporter:"D"
	},
	{
		x:0.29430000000000001,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.30080000000000001,
		y:0.90539999999999998,
		supporter:"D"
	},
	{
		x:0.57240000000000002,
		y:0.85840000000000005,
		supporter:"D"
	},
	{
		x:0.62080000000000002,
		y:0.88370000000000004,
		supporter:"D"
	},
	{
		x:0.52700000000000002,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.41820000000000002,
		y:0.85709999999999997,
		supporter:"D"
	},
	{
		x:0.29380000000000001,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.5,
		y:0.88800000000000001,
		supporter:"D"
	},
	{
		x:0.62849999999999995,
		y:0.77669999999999995,
		supporter:"D"
	},
	{
		x:0.48309999999999997,
		y:0.88580000000000003,
		supporter:"D"
	},
	{
		x:0.49819999999999998,
		y:0.92959999999999998,
		supporter:"D"
	},
	{
		x:0.48509999999999998,
		y:0.92459999999999998,
		supporter:"D"
	},
	{
		x:0.34989999999999999,
		y:0.91610000000000003,
		supporter:"D"
	},
	{
		x:0.47349999999999998,
		y:0.92490000000000006,
		supporter:"D"
	},
	{
		x:0.41270000000000001,
		y:0.85360000000000003,
		supporter:"D"
	},
	{
		x:0.41539999999999999,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.49480000000000002,
		y:0.92330000000000001,
		supporter:"D"
	},
	{
		x:0.43309999999999998,
		y:0.92520000000000002,
		supporter:"D"
	},
	{
		x:0.35339999999999999,
		y:0.90100000000000002,
		supporter:"D"
	},
	{
		x:0.43209999999999998,
		y:0.92710000000000004,
		supporter:"D"
	},
	{
		x:0.62570000000000003,
		y:0.88959999999999995,
		supporter:"D"
	},
	{
		x:0.39500000000000002,
		y:0.86950000000000005,
		supporter:"D"
	},
	{
		x:0.58930000000000005,
		y:0.82169999999999999,
		supporter:"D"
	},
	{
		x:0.30280000000000001,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.40289999999999998,
		y:0.85819999999999996,
		supporter:"D"
	},
	{
		x:0.46839999999999998,
		y:0.89549999999999996,
		supporter:"D"
	},
	{
		x:0.48270000000000002,
		y:0.90249999999999997,
		supporter:"D"
	},
	{
		x:0.54269999999999996,
		y:0.875,
		supporter:"D"
	},
	{
		x:0.34510000000000002,
		y:0.90790000000000004,
		supporter:"D"
	},
	{
		x:0.33090000000000003,
		y:0.90769999999999995,
		supporter:"D"
	},
	{
		x:0.43690000000000001,
		y:0.9032,
		supporter:"D"
	},
	{
		x:0.46439999999999998,
		y:0.92900000000000005,
		supporter:"D"
	},
	{
		x:0.52510000000000001,
		y:0.81559999999999999,
		supporter:"D"
	},
	{
		x:0.49120000000000003,
		y:0.88490000000000002,
		supporter:"D"
	},
	{
		x:0.34760000000000002,
		y:0.90139999999999998,
		supporter:"D"
	},
	{
		x:0.52480000000000004,
		y:0.8952,
		supporter:"D"
	},
	{
		x:0.4536,
		y:0.92500000000000004,
		supporter:"D"
	},
	{
		x:0.47799999999999998,
		y:0.91139999999999999,
		supporter:"D"
	},
	{
		x:0.44019999999999998,
		y:0.89500000000000002,
		supporter:"D"
	},
	{
		x:0.49890000000000001,
		y:0.91679999999999995,
		supporter:"D"
	},
	{
		x:0.54530000000000001,
		y:0.87090000000000001,
		supporter:"D"
	},
	{
		x:0.4839,
		y:0.85289999999999999,
		supporter:"D"
	},
	{
		x:0.39679999999999999,
		y:0.90069999999999995,
		supporter:"D"
	},
	{
		x:0.5333,
		y:0.89829999999999999,
		supporter:"D"
	},
	{
		x:0.45240000000000002,
		y:0.92490000000000006,
		supporter:"D"
	},
	{
		x:0.2697,
		y:0.89690000000000003,
		supporter:"D"
	},
	{
		x:0.32279999999999998,
		y:0.91369999999999996,
		supporter:"D"
	},
	{
		x:0.49409999999999998,
		y:0.90329999999999999,
		supporter:"D"
	},
	{
		x:0.53420000000000001,
		y:0.89659999999999995,
		supporter:"D"
	},
	{
		x:0.49580000000000002,
		y:0.88500000000000001,
		supporter:"D"
	},
	{
		x:0.34250000000000003,
		y:0.90859999999999996,
		supporter:"D"
	},
	{
		x:0.60429999999999995,
		y:0.87260000000000004,
		supporter:"D"
	},
	{
		x:0.45450000000000002,
		y:0.88060000000000005,
		supporter:"D"
	},
	{
		x:0.48970000000000002,
		y:0.91159999999999997,
		supporter:"D"
	},
	{
		x:0.57540000000000002,
		y:0.86619999999999997,
		supporter:"D"
	},
	{
		x:0.51910000000000001,
		y:0.89970000000000006,
		supporter:"D"
	},
	{
		x:0.44,
		y:0.87909999999999999,
		supporter:"D"
	},
	{
		x:0.37780000000000002,
		y:0.84560000000000002,
		supporter:"D"
	},
	{
		x:0.45390000000000003,
		y:0.90029999999999999,
		supporter:"D"
	},
	{
		x:0.47399999999999998,
		y:0.93159999999999998,
		supporter:"D"
	},
	{
		x:0.44790000000000002,
		y:0.92149999999999999,
		supporter:"D"
	},
	{
		x:0.47160000000000002,
		y:0.91279999999999994,
		supporter:"D"
	},
	{
		x:0.56830000000000003,
		y:0.89529999999999998,
		supporter:"D"
	},
	{
		x:0.29289999999999999,
		y:0.90249999999999997,
		supporter:"D"
	},
	{
		x:0.4587,
		y:0.93089999999999995,
		supporter:"D"
	},
	{
		x:0.55840000000000001,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.52070000000000005,
		y:0.86580000000000001,
		supporter:"D"
	},
	{
		x:0.32529999999999998,
		y:0.91290000000000004,
		supporter:"D"
	},
	{
		x:0.54779999999999995,
		y:0.88449999999999995,
		supporter:"D"
	},
	{
		x:0.2944,
		y:0.90429999999999999,
		supporter:"D"
	},
	{
		x:0.5353,
		y:0.88749999999999996,
		supporter:"D"
	},
	{
		x:0.49299999999999999,
		y:0.92479999999999996,
		supporter:"D"
	},
	{
		x:0.34689999999999999,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.57830000000000004,
		y:0.85329999999999995,
		supporter:"D"
	},
	{
		x:0.39960000000000001,
		y:0.90639999999999998,
		supporter:"D"
	},
	{
		x:0.31419999999999998,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.36969999999999997,
		y:0.91149999999999998,
		supporter:"D"
	},
	{
		x:0.32600000000000001,
		y:0.90939999999999999,
		supporter:"D"
	},
	{
		x:0.27060000000000001,
		y:0.89259999999999995,
		supporter:"D"
	},
	{
		x:0.37109999999999999,
		y:0.90029999999999999,
		supporter:"D"
	},
	{
		x:0.45569999999999999,
		y:0.92769999999999997,
		supporter:"D"
	},
	{
		x:0.62539999999999996,
		y:0.8609,
		supporter:"D"
	},
	{
		x:0.48430000000000001,
		y:0.89970000000000006,
		supporter:"D"
	},
	{
		x:0.44240000000000002,
		y:0.90200000000000002,
		supporter:"D"
	},
	{
		x:0.3014,
		y:0.89729999999999999,
		supporter:"D"
	},
	{
		x:0.47510000000000002,
		y:0.91110000000000002,
		supporter:"D"
	},
	{
		x:0.45850000000000002,
		y:0.9042,
		supporter:"D"
	},
	{
		x:0.4269,
		y:0.90229999999999999,
		supporter:"D"
	},
	{
		x:0.44669999999999999,
		y:0.88329999999999997,
		supporter:"D"
	},
	{
		x:0.32179999999999997,
		y:0.90920000000000001,
		supporter:"D"
	},
	{
		x:0.36120000000000002,
		y:0.91039999999999999,
		supporter:"D"
	},
	{
		x:0.3876,
		y:0.90659999999999996,
		supporter:"D"
	},
	{
		x:0.38519999999999999,
		y:0.90610000000000002,
		supporter:"D"
	},
	{
		x:0.35049999999999998,
		y:0.90039999999999998,
		supporter:"D"
	},
	{
		x:0.47189999999999999,
		y:0.90369999999999995,
		supporter:"D"
	},
	{
		x:0.52470000000000006,
		y:0.89890000000000003,
		supporter:"D"
	},
	{
		x:0.4652,
		y:0.92159999999999997,
		supporter:"D"
	},
	{
		x:0.32690000000000002,
		y:0.90259999999999996,
		supporter:"D"
	},
	{
		x:0.46560000000000001,
		y:0.92230000000000001,
		supporter:"D"
	},
	{
		x:0.47970000000000002,
		y:0.91539999999999999,
		supporter:"D"
	},
	{
		x:0.59230000000000005,
		y:0.85229999999999995,
		supporter:"D"
	},
	{
		x:0.4929,
		y:0.93089999999999995,
		supporter:"D"
	},
	{
		x:0.35920000000000002,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.30990000000000001,
		y:0.91139999999999999,
		supporter:"D"
	},
	{
		x:0.46989999999999998,
		y:0.92,
		supporter:"D"
	},
	{
		x:0.46800000000000003,
		y:0.90529999999999999,
		supporter:"D"
	},
	{
		x:0.60919999999999996,
		y:0.85819999999999996,
		supporter:"D"
	},
	{
		x:0.40289999999999998,
		y:0.90310000000000001,
		supporter:"D"
	},
	{
		x:0.60550000000000004,
		y:0.85950000000000004,
		supporter:"D"
	},
	{
		x:0.43690000000000001,
		y:0.85570000000000002,
		supporter:"D"
	},
	{
		x:0.59650000000000003,
		y:0.89029999999999998,
		supporter:"D"
	},
	{
		x:0.50409999999999999,
		y:0.89880000000000004,
		supporter:"D"
	},
	{
		x:0.53100000000000003,
		y:0.88100000000000001,
		supporter:"D"
	},
	{
		x:0.60019999999999996,
		y:0.87760000000000005,
		supporter:"D"
	},
	{
		x:0.5736,
		y:0.89349999999999996,
		supporter:"D"
	},
	{
		x:0.49270000000000003,
		y:0.90010000000000001,
		supporter:"D"
	},
	{
		x:0.26979999999999998,
		y:0.91059999999999997,
		supporter:"D"
	},
	{
		x:0.52539999999999998,
		y:0.89100000000000001,
		supporter:"D"
	},
	{
		x:0.30969999999999998,
		y:0.90380000000000005,
		supporter:"D"
	},
	{
		x:0.28899999999999998,
		y:0.89990000000000003,
		supporter:"D"
	},
	{
		x:0.4672,
		y:0.92579999999999996,
		supporter:"D"
	},
	{
		x:0.4829,
		y:0.88549999999999995,
		supporter:"D"
	},
	{
		x:0.36430000000000001,
		y:0.91259999999999997,
		supporter:"D"
	},
	{
		x:0.4541,
		y:0.92749999999999999,
		supporter:"D"
	},
	{
		x:0.45600000000000002,
		y:0.85550000000000004,
		supporter:"D"
	},
	{
		x:0.44519999999999998,
		y:0.90480000000000005,
		supporter:"D"
	},
	{
		x:0.56759999999999999,
		y:0.89300000000000002,
		supporter:"D"
	},
	{
		x:0.52449999999999997,
		y:0.89649999999999996,
		supporter:"D"
	},
	{
		x:0.46479999999999999,
		y:0.90629999999999999,
		supporter:"D"
	},
	{
		x:0.49919999999999998,
		y:0.92330000000000001,
		supporter:"D"
	},
	{
		x:0.61380000000000001,
		y:0.82979999999999998,
		supporter:"D"
	},
	{
		x:0.45490000000000003,
		y:0.91590000000000005,
		supporter:"D"
	},
	{
		x:0.52370000000000005,
		y:0.89149999999999996,
		supporter:"D"
	},
	{
		x:0.51519999999999999,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.3831,
		y:0.91400000000000003,
		supporter:"D"
	},
	{
		x:0.48780000000000001,
		y:0.92989999999999995,
		supporter:"D"
	},
	{
		x:0.49569999999999997,
		y:0.92569999999999997,
		supporter:"D"
	},
	{
		x:0.42620000000000002,
		y:0.90959999999999996,
		supporter:"D"
	},
	{
		x:0.48380000000000001,
		y:0.90659999999999996,
		supporter:"D"
	},
	{
		x:0.5393,
		y:0.87229999999999996,
		supporter:"D"
	},
	{
		x:0.47449999999999998,
		y:0.89949999999999997,
		supporter:"D"
	},
	{
		x:0.43630000000000002,
		y:0.92169999999999996,
		supporter:"D"
	},
	{
		x:0.4904,
		y:0.90900000000000003,
		supporter:"D"
	},
	{
		x:0.45679999999999998,
		y:0.88660000000000005,
		supporter:"D"
	},
	{
		x:0.56759999999999999,
		y:0.89829999999999999,
		supporter:"D"
	},
	{
		x:0.29020000000000001,
		y:0.89739999999999998,
		supporter:"D"
	},
	{
		x:0.48609999999999998,
		y:0.88190000000000002,
		supporter:"D"
	},
	{
		x:0.53839999999999999,
		y:0.88119999999999998,
		supporter:"D"
	},
	{
		x:0.48820000000000002,
		y:0.92859999999999998,
		supporter:"D"
	},
	{
		x:0.49249999999999999,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.41410000000000002,
		y:0.91510000000000002,
		supporter:"D"
	},
	{
		x:0.4728,
		y:0.92079999999999995,
		supporter:"D"
	},
	{
		x:0.42609999999999998,
		y:0.9123,
		supporter:"D"
	},
	{
		x:0.30709999999999998,
		y:0.90800000000000003,
		supporter:"D"
	},
	{
		x:0.43909999999999999,
		y:0.84460000000000002,
		supporter:"D"
	},
	{
		x:0.57499999999999996,
		y:0.88200000000000001,
		supporter:"D"
	},
	{
		x:0.29349999999999998,
		y:0.90229999999999999,
		supporter:"D"
	},
	{
		x:0.41920000000000002,
		y:0.90739999999999998,
		supporter:"D"
	},
	{
		x:0.42149999999999999,
		y:0.92830000000000001,
		supporter:"D"
	},
	{
		x:0.49890000000000001,
		y:0.93149999999999999,
		supporter:"D"
	},
	{
		x:0.41239999999999999,
		y:0.85250000000000004,
		supporter:"D"
	},
	{
		x:0.53969999999999996,
		y:0.89100000000000001,
		supporter:"D"
	},
	{
		x:0.55200000000000005,
		y:0.89949999999999997,
		supporter:"D"
	},
	{
		x:0.62370000000000003,
		y:0.81169999999999998,
		supporter:"D"
	},
	{
		x:0.53210000000000002,
		y:0.89070000000000005,
		supporter:"D"
	},
	{
		x:0.36270000000000002,
		y:0.89600000000000002,
		supporter:"D"
	},
	{
		x:0.58009999999999995,
		y:0.84899999999999998,
		supporter:"D"
	},
	{
		x:0.35260000000000002,
		y:0.90559999999999996,
		supporter:"D"
	},
	{
		x:0.49809999999999999,
		y:0.8982,
		supporter:"D"
	},
	{
		x:0.46660000000000001,
		y:0.91090000000000004,
		supporter:"D"
	},
	{
		x:0.53639999999999999,
		y:0.86770000000000003,
		supporter:"D"
	},
	{
		x:0.46339999999999998,
		y:0.91790000000000005,
		supporter:"D"
	},
	{
		x:0.49790000000000001,
		y:0.90769999999999995,
		supporter:"D"
	},
	{
		x:0.61570000000000003,
		y:0.80959999999999999,
		supporter:"D"
	},
	{
		x:0.41770000000000002,
		y:0.89690000000000003,
		supporter:"D"
	},
	{
		x:0.57569999999999999,
		y:0.88239999999999996,
		supporter:"D"
	},
	{
		x:0.46300000000000002,
		y:0.92900000000000005,
		supporter:"D"
	},
	{
		x:0.44490000000000002,
		y:0.92600000000000005,
		supporter:"D"
	},
	{
		x:0.28310000000000002,
		y:0.89629999999999999,
		supporter:"D"
	},
	{
		x:0.3982,
		y:0.85089999999999999,
		supporter:"D"
	},
	{
		x:0.62239999999999995,
		y:0.77259999999999995,
		supporter:"D"
	},
	{
		x:0.4819,
		y:0.9093,
		supporter:"D"
	},
	{
		x:0.36570000000000003,
		y:0.91600000000000004,
		supporter:"D"
	},
	{
		x:0.48280000000000001,
		y:0.90300000000000002,
		supporter:"D"
	},
	{
		x:0.30049999999999999,
		y:0.91300000000000003,
		supporter:"D"
	},
	{
		x:0.37040000000000001,
		y:0.90229999999999999,
		supporter:"D"
	},
	{
		x:0.51060000000000005,
		y:0.8972,
		supporter:"D"
	},
	{
		x:0.51780000000000004,
		y:0.88719999999999999,
		supporter:"D"
	},
	{
		x:0.61219999999999997,
		y:0.81769999999999998,
		supporter:"D"
	},
	{
		x:0.29199999999999998,
		y:0.90449999999999997,
		supporter:"D"
	},
	{
		x:0.59060000000000001,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.59740000000000004,
		y:0.87660000000000005,
		supporter:"D"
	},
	{
		x:0.43759999999999999,
		y:0.90439999999999998,
		supporter:"D"
	},
	{
		x:0.56240000000000001,
		y:0.89380000000000004,
		supporter:"D"
	},
	{
		x:0.43809999999999999,
		y:0.90210000000000001,
		supporter:"D"
	},
	{
		x:0.46729999999999999,
		y:0.89880000000000004,
		supporter:"D"
	},
	{
		x:0.38040000000000002,
		y:0.85540000000000005,
		supporter:"D"
	},
	{
		x:0.4511,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.34799999999999998,
		y:0.90790000000000004,
		supporter:"D"
	},
	{
		x:0.33040000000000003,
		y:0.90090000000000003,
		supporter:"D"
	},
	{
		x:0.29580000000000001,
		y:0.90539999999999998,
		supporter:"D"
	},
	{
		x:0.32929999999999998,
		y:0.90449999999999997,
		supporter:"D"
	},
	{
		x:0.46279999999999999,
		y:0.89690000000000003,
		supporter:"D"
	},
	{
		x:0.38840000000000002,
		y:0.85340000000000005,
		supporter:"D"
	},
	{
		x:0.49480000000000002,
		y:0.88049999999999995,
		supporter:"D"
	},
	{
		x:0.53720000000000001,
		y:0.87709999999999999,
		supporter:"D"
	},
	{
		x:0.32619999999999999,
		y:0.90569999999999995,
		supporter:"D"
	},
	{
		x:0.58150000000000002,
		y:0.88239999999999996,
		supporter:"D"
	},
	{
		x:0.3453,
		y:0.90839999999999999,
		supporter:"D"
	},
	{
		x:0.2979,
		y:0.90059999999999996,
		supporter:"D"
	},
	{
		x:0.3705,
		y:0.84770000000000001,
		supporter:"D"
	},
	{
		x:0.44479999999999997,
		y:0.91669999999999996,
		supporter:"D"
	},
	{
		x:0.3967,
		y:0.85860000000000003,
		supporter:"D"
	},
	{
		x:0.39190000000000003,
		y:0.90310000000000001,
		supporter:"D"
	},
	{
		x:0.4919,
		y:0.90569999999999995,
		supporter:"D"
	},
	{
		x:0.47110000000000002,
		y:0.9224,
		supporter:"D"
	},
	{
		x:0.56069999999999998,
		y:0.87770000000000004,
		supporter:"D"
	},
	{
		x:0.41070000000000001,
		y:0.85350000000000004,
		supporter:"D"
	},
	{
		x:0.29580000000000001,
		y:0.90090000000000003,
		supporter:"D"
	},
	{
		x:0.48909999999999998,
		y:0.93300000000000005,
		supporter:"D"
	},
	{
		x:0.5444,
		y:0.88019999999999998,
		supporter:"D"
	},
	{
		x:0.58069999999999999,
		y:0.88239999999999996,
		supporter:"D"
	},
	{
		x:0.49540000000000001,
		y:0.9204,
		supporter:"D"
	},
	{
		x:0.42249999999999999,
		y:0.9163,
		supporter:"D"
	},
	{
		x:0.57899999999999996,
		y:0.84370000000000001,
		supporter:"D"
	},
	{
		x:0.3584,
		y:0.85709999999999997,
		supporter:"D"
	},
	{
		x:0.29360000000000003,
		y:0.90010000000000001,
		supporter:"D"
	},
	{
		x:0.52059999999999995,
		y:0.8992,
		supporter:"D"
	},
	{
		x:0.39700000000000002,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.5081,
		y:0.89849999999999997,
		supporter:"D"
	},
	{
		x:0.49530000000000002,
		y:0.9133,
		supporter:"D"
	},
	{
		x:0.30449999999999999,
		y:0.90110000000000001,
		supporter:"D"
	},
	{
		x:0.34089999999999998,
		y:0.9173,
		supporter:"D"
	},
	{
		x:0.49840000000000001,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.39879999999999999,
		y:0.88419999999999999,
		supporter:"D"
	},
	{
		x:0.49170000000000003,
		y:0.90429999999999999,
		supporter:"D"
	},
	{
		x:0.51629999999999998,
		y:0.88600000000000001,
		supporter:"D"
	},
	{
		x:0.49170000000000003,
		y:0.92569999999999997,
		supporter:"D"
	},
	{
		x:0.36330000000000001,
		y:0.91220000000000001,
		supporter:"D"
	},
	{
		x:0.57750000000000001,
		y:0.89529999999999998,
		supporter:"D"
	},
	{
		x:0.41570000000000001,
		y:0.92,
		supporter:"D"
	},
	{
		x:0.5615,
		y:0.85570000000000002,
		supporter:"D"
	},
	{
		x:0.40710000000000002,
		y:0.92269999999999996,
		supporter:"D"
	},
	{
		x:0.58789999999999998,
		y:0.87980000000000003,
		supporter:"D"
	},
	{
		x:0.53459999999999996,
		y:0.88560000000000005,
		supporter:"D"
	},
	{
		x:0.4788,
		y:0.90439999999999998,
		supporter:"D"
	},
	{
		x:0.54020000000000001,
		y:0.89129999999999998,
		supporter:"D"
	},
	{
		x:0.48139999999999999,
		y:0.9093,
		supporter:"D"
	},
	{
		x:0.52759999999999996,
		y:0.89910000000000001,
		supporter:"D"
	},
	{
		x:0.46660000000000001,
		y:0.8518,
		supporter:"D"
	},
	{
		x:0.60509999999999997,
		y:0.81820000000000004,
		supporter:"D"
	},
	{
		x:0.4879,
		y:0.91959999999999997,
		supporter:"D"
	},
	{
		x:0.46939999999999998,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.50519999999999998,
		y:0.80189999999999995,
		supporter:"D"
	},
	{
		x:0.45839999999999997,
		y:0.9153,
		supporter:"D"
	},
	{
		x:0.4657,
		y:0.92210000000000003,
		supporter:"D"
	},
	{
		x:0.47049999999999997,
		y:0.89929999999999999,
		supporter:"D"
	},
	{
		x:0.314,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.497,
		y:0.9022,
		supporter:"D"
	},
	{
		x:0.46389999999999998,
		y:0.93189999999999995,
		supporter:"D"
	},
	{
		x:0.42549999999999999,
		y:0.91979999999999995,
		supporter:"D"
	},
	{
		x:0.36109999999999998,
		y:0.89800000000000002,
		supporter:"D"
	},
	{
		x:0.38250000000000001,
		y:0.85960000000000003,
		supporter:"D"
	},
	{
		x:0.29270000000000002,
		y:0.89539999999999997,
		supporter:"D"
	},
	{
		x:0.58009999999999995,
		y:0.89949999999999997,
		supporter:"D"
	},
	{
		x:0.53500000000000003,
		y:0.87980000000000003,
		supporter:"D"
	},
	{
		x:0.49159999999999998,
		y:0.8921,
		supporter:"D"
	},
	{
		x:0.40720000000000001,
		y:0.89229999999999998,
		supporter:"D"
	},
	{
		x:0.48530000000000001,
		y:0.92259999999999998,
		supporter:"D"
	},
	{
		x:0.58930000000000005,
		y:0.88280000000000003,
		supporter:"D"
	},
	{
		x:0.47270000000000001,
		y:0.93189999999999995,
		supporter:"D"
	},
	{
		x:0.31940000000000002,
		y:0.91080000000000005,
		supporter:"D"
	},
	{
		x:0.60429999999999995,
		y:0.80069999999999997,
		supporter:"D"
	},
	{
		x:0.53090000000000004,
		y:0.85780000000000001,
		supporter:"D"
	},
	{
		x:0.38740000000000002,
		y:0.90780000000000005,
		supporter:"D"
	},
	{
		x:0.55520000000000003,
		y:0.84730000000000005,
		supporter:"D"
	},
	{
		x:0.47820000000000001,
		y:0.88190000000000002,
		supporter:"D"
	},
	{
		x:0.39229999999999998,
		y:0.89770000000000005,
		supporter:"D"
	},
	{
		x:0.4622,
		y:0.92479999999999996,
		supporter:"D"
	},
	{
		x:0.54090000000000005,
		y:0.87439999999999996,
		supporter:"D"
	},
	{
		x:0.44579999999999997,
		y:0.9254,
		supporter:"D"
	},
	{
		x:0.46539999999999998,
		y:0.90580000000000005,
		supporter:"D"
	},
	{
		x:0.52700000000000002,
		y:0.82650000000000001,
		supporter:"D"
	},
	{
		x:0.42080000000000001,
		y:0.91149999999999998,
		supporter:"D"
	},
	{
		x:0.39439999999999997,
		y:0.89810000000000001,
		supporter:"D"
	},
	{
		x:0.57179999999999997,
		y:0.89510000000000001,
		supporter:"D"
	},
	{
		x:0.3851,
		y:0.8528,
		supporter:"D"
	},
	{
		x:0.62160000000000004,
		y:0.88639999999999997,
		supporter:"D"
	},
	{
		x:0.44040000000000001,
		y:0.92010000000000003,
		supporter:"D"
	},
	{
		x:0.29570000000000002,
		y:0.90390000000000004,
		supporter:"D"
	},
	{
		x:0.3584,
		y:0.90910000000000002,
		supporter:"D"
	},
	{
		x:0.50539999999999996,
		y:0.89959999999999996,
		supporter:"D"
	},
	{
		x:0.39400000000000002,
		y:0.91500000000000004,
		supporter:"D"
	},
	{
		x:0.34010000000000001,
		y:0.8992,
		supporter:"D"
	},
	{
		x:0.60599999999999998,
		y:0.81769999999999998,
		supporter:"D"
	},
	{
		x:0.49980000000000002,
		y:0.89549999999999996,
		supporter:"D"
	},
	{
		x:0.46879999999999999,
		y:0.92430000000000001,
		supporter:"D"
	},
	{
		x:0.51219999999999999,
		y:0.81259999999999999,
		supporter:"D"
	},
	{
		x:0.4642,
		y:0.92059999999999997,
		supporter:"D"
	},
	{
		x:0.30740000000000001,
		y:0.91369999999999996,
		supporter:"D"
	},
	{
		x:0.4995,
		y:0.89429999999999998,
		supporter:"D"
	},
	{
		x:0.48949999999999999,
		y:0.92020000000000002,
		supporter:"D"
	},
	{
		x:0.41570000000000001,
		y:0.90359999999999996,
		supporter:"D"
	},
	{
		x:0.62470000000000003,
		y:0.89410000000000001,
		supporter:"D"
	},
	{
		x:0.3044,
		y:0.90300000000000002,
		supporter:"D"
	},
	{
		x:0.32140000000000002,
		y:0.90959999999999996,
		supporter:"D"
	},
	{
		x:0.318,
		y:0.90590000000000004,
		supporter:"D"
	},
	{
		x:0.34660000000000002,
		y:0.91349999999999998,
		supporter:"D"
	},
	{
		x:0.36820000000000003,
		y:0.89949999999999997,
		supporter:"D"
	},
	{
		x:0.36940000000000001,
		y:0.91369999999999996,
		supporter:"D"
	},
	{
		x:0.61260000000000003,
		y:0.84719999999999995,
		supporter:"D"
	},
	{
		x:0.53720000000000001,
		y:0.89939999999999998,
		supporter:"D"
	},
	{
		x:0.50239999999999996,
		y:0.89080000000000004,
		supporter:"D"
	},
	{
		x:0.3322,
		y:0.90739999999999998,
		supporter:"D"
	},
	{
		x:0.43930000000000002,
		y:0.89200000000000002,
		supporter:"D"
	},
	{
		x:0.39560000000000001,
		y:0.91069999999999995,
		supporter:"D"
	},
	{
		x:0.49390000000000001,
		y:0.92090000000000005,
		supporter:"D"
	},
	{
		x:0.39750000000000002,
		y:0.90649999999999997,
		supporter:"D"
	},
	{
		x:0.49459999999999998,
		y:0.92290000000000005,
		supporter:"D"
	},
	{
		x:0.4965,
		y:0.92730000000000001,
		supporter:"D"
	},
	{
		x:0.35389999999999999,
		y:0.90510000000000002,
		supporter:"D"
	},
	{
		x:0.51970000000000005,
		y:0.89,
		supporter:"D"
	},
	{
		x:0.47,
		y:0.90949999999999998,
		supporter:"D"
	},
	{
		x:0.49969999999999998,
		y:0.89980000000000004,
		supporter:"D"
	},
	{
		x:0.35970000000000002,
		y:0.91039999999999999,
		supporter:"D"
	},
	{
		x:0.4985,
		y:0.88349999999999995,
		supporter:"D"
	},
	{
		x:0.34639999999999999,
		y:0.90580000000000005,
		supporter:"D"
	},
	{
		x:0.53959999999999997,
		y:0.86029999999999995,
		supporter:"D"
	},
	{
		x:0.47089999999999999,
		y:0.92249999999999999,
		supporter:"D"
	},
	{
		x:0.56169999999999998,
		y:0.86029999999999995,
		supporter:"D"
	},
	{
		x:0.61450000000000005,
		y:0.89690000000000003,
		supporter:"D"
	},
	{
		x:0.495,
		y:0.93100000000000005,
		supporter:"D"
	},
	{
		x:0.2772,
		y:0.89900000000000002,
		supporter:"D"
	},
	{
		x:0.47039999999999998,
		y:0.92889999999999995,
		supporter:"D"
	},
	{
		x:0.46820000000000001,
		y:0.91169999999999995,
		supporter:"D"
	},
	{
		x:0.40699999999999997,
		y:0.86060000000000003,
		supporter:"D"
	},
	{
		x:0.55649999999999999,
		y:0.88319999999999999,
		supporter:"D"
	},
	{
		x:0.49840000000000001,
		y:0.88670000000000004,
		supporter:"D"
	},
	{
		x:0.4859,
		y:0.8841,
		supporter:"D"
	},
	{
		x:0.62080000000000002,
		y:0.82550000000000001,
		supporter:"D"
	},
	{
		x:0.51870000000000005,
		y:0.86199999999999999,
		supporter:"D"
	},
	{
		x:0.48039999999999999,
		y:0.88639999999999997,
		supporter:"D"
	},
	{
		x:0.3639,
		y:0.91020000000000001,
		supporter:"D"
	},
	{
		x:0.58299999999999996,
		y:0.81130000000000002,
		supporter:"D"
	},
	{
		x:0.48330000000000001,
		y:0.90229999999999999,
		supporter:"D"
	},
	{
		x:0.52039999999999997,
		y:0.8972,
		supporter:"D"
	},
	{
		x:0.34429999999999999,
		y:0.90369999999999995,
		supporter:"D"
	},
	{
		x:0.48080000000000001,
		y:0.90749999999999997,
		supporter:"D"
	},
	{
		x:0.4798,
		y:0.9042,
		supporter:"D"
	},
	{
		x:0.4703,
		y:0.92290000000000005,
		supporter:"D"
	},
	{
		x:0.39760000000000001,
		y:0.90169999999999995,
		supporter:"D"
	},
	{
		x:0.51319999999999999,
		y:0.89700000000000002,
		supporter:"D"
	},
	{
		x:0.39800000000000002,
		y:0.84330000000000005,
		supporter:"D"
	},
	{
		x:0.49780000000000002,
		y:0.88980000000000004,
		supporter:"D"
	},
	{
		x:0.47270000000000001,
		y:0.90969999999999995,
		supporter:"D"
	},
	{
		x:0.48039999999999999,
		y:0.90280000000000005,
		supporter:"D"
	},
	{
		x:0.47399999999999998,
		y:0.90510000000000002,
		supporter:"D"
	},
	{
		x:0.34129999999999999,
		y:0.91220000000000001,
		supporter:"D"
	},
	{
		x:0.47049999999999997,
		y:0.91490000000000005,
		supporter:"D"
	},
	{
		x:0.40189999999999998,
		y:0.85880000000000001,
		supporter:"D"
	},
	{
		x:0.41909999999999997,
		y:0.92659999999999998,
		supporter:"D"
	},
	{
		x:0.50719999999999998,
		y:0.89759999999999995,
		supporter:"D"
	},
	{
		x:0.53,
		y:0.89429999999999998,
		supporter:"D"
	},
	{
		x:0.40010000000000001,
		y:0.85899999999999999,
		supporter:"D"
	},
	{
		x:0.57909999999999995,
		y:0.89949999999999997,
		supporter:"D"
	},
	{
		x:0.55979999999999996,
		y:0.86799999999999999,
		supporter:"D"
	},
	{
		x:0.41160000000000002,
		y:0.85909999999999997,
		supporter:"D"
	},
	{
		x:0.58030000000000004,
		y:0.81159999999999999,
		supporter:"D"
	},
	{
		x:0.49640000000000001,
		y:0.90820000000000001,
		supporter:"D"
	},
	{
		x:0.32619999999999999,
		y:0.91020000000000001,
		supporter:"D"
	}
]

