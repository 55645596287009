import React, { Fragment } from "react";
import "styles/components/charts.css";

const HorizontalSupporterDark = ({ data, filter }) => {
  return (
    <Fragment>
      {data.length > 0 &&
        data.map((item, key) => {
          return (
            <div className="row" key={key}>
              <div className="col-md-3 text-right"><span style={{color: 'white'}}>{item.title}</span></div>
              <div className="col-md-9">
                <div className="horizontal-100-chart scoring-chart">
                  <div className="chart-items">
                    {item.data.map((d, j) => {
                      let style;
                      if (j !== 0) {
                        if (j == 2) {
                          if (d.percentage < 9) {
                            style = { left: -50 + "px" };
                          } else {
                            if (
                              item.data[1].percentage < 9 &&
                              item.data[0].percentage >= 9
                            ) {
                              style = { left: 50 + "px" };
                            }
                            if (
                              item.data[1].percentage < 9 &&
                              item.data[0].percentage < 9
                            ) {
                              style = { left: 100 + "px" };
                            }
                          }
                        } else if (j == 1) {
                          if (d.percentage < 9) {
                            if (item.data[j + 1].percentage < 9) {
                              style = { left: -50 + "px" };
                            } else if (item.data[j - 1].percentage < 9) {
                              style = { left: 50 + "px" };
                            }
                          } else {
                            if (d.percentage < 50) {
                              if (item.data[j - 1].percentage < 9) {
                                style = { left: 50 + "px" };
                                if (item.data[2].percentage < 9) {
                                  style = { left: -50 + "px" };
                                }
                              } 
                            }else{
                              if (item.data[0].percentage < 9) {
                                style = { left: 50 + "px" };
                              }
                            }
                          }
                        }
                      }
                      return (
                        <span
                          key={j}
                          style={{
                            width: d.percentage + "%",
                            backgroundColor: d.color
                          }}
                        >
                          <span className="hover-item" style={style}>
                            {d.percentage.toFixed(2)}%
                          </span>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </Fragment>
  );
};

export default HorizontalSupporterDark;
