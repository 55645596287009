import React from "react";
import { connect } from "react-redux";
import "styles/components/map-info.css";
import StackedBarCenterAxis from "components/shared/charts/StackedBarCenterAxis";
import PieChart from "components/shared/charts/PieChart";
import NumberEasing from "che-react-number-easing";
import SESPie from "components/shared/charts/SESPie";
import Bar100Horizontal from "components/shared/charts/Bar100Horizontal";
import Bar100HorizontalCrowd from "components/shared/charts/Bar100HorizontalCrowd";
import Bar100HorizontalRelawan from "components/shared/charts/Bar100HorizontalRelawan";
import BarChartHRechart from "components/shared/charts/BarChartHRechart"; 

// import { Card } from '@material-ui/core';

const sesColorScale =[
  "#007b4a",
  "#ffaa55",
  "#9335f3",
  "#0097a5",
  "#a91922"
]

const SideInfoRelawan = props => { 
    console.log ('xxxxx SideInfoRelawan');
    console.log(props)

return (
  <div className={`side-map-info-stack ${props.show ? "active" : ""}`}>
    {/* {props.info.gender.show && (
      <div className="stack-item active" style={{background:'#2C2C2C', borderRadius: '2px', padding:'7px 0px'}}>
        <div className="row">
          <div className="col-md-6 align-self-center">
            <h4 style={{paddingLeft: '15px', fontSize: '14px'}}>Populations</h4>
          </div>
          <div className="col-md-6 text-right align-self-centerr">
            <span className="stack-total-gender" style={{color:'#FFD77D', paddingRight: '15px'}}>
              <NumberEasing
                value={ (props.info.gender.value.total_male + props.info.gender.value.total_male) }
                speed={3000}
                ease="quintInOut"
                useLocaleString={true}
                precision={0}
              />
            </span>
          </div>
        </div>
      </div>
    )} */}
    {props.info.gender.show && (
      <div>
        <div className="row" style={{background:'#2C2C2C', borderRadius: '2px', margin:'7px 0px'}}>
          <div className="col-md-6 align-self-center">
            <h4 style={{color:'white', padding: '3px 2px', fontSize: '14px'}}>Gender</h4>
          </div>
        </div>
        <div className="stack-item active" style={{margin:'7px 12px 20px 12px'}}>
          <div className="stack-two">
            <div className="stack-two-items">
              <h4>Pria</h4>
              <span className="stack-total-gender pria">
                <NumberEasing
                  value={props.info.gender.value.total_male}
                  speed={3000}
                  ease="quintInOut"
                  useLocaleString={true}
                  precision={0}
                />
              </span>
            </div>
            <div className="stack-two-items">
              <h4>Wanita</h4>
              <span className="stack-total-gender wanita">
                <NumberEasing
                  value={props.info.gender.value.total_female}
                  speed={3000}
                  ease="quintInOut"
                  useLocaleString={true}
                  precision={0}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    )}
    <div className="stack-item active">
      <div className="row" style={{background:'#2C2C2C', borderRadius: '2px', margin:'7px 0px'}}>
        <div className="col-md-6 align-self-center">
          <h4 style={{padding: '3px 2px', fontSize: '14px'}}>Football Class</h4>
        </div>
      </div>
      <Bar100HorizontalRelawan region={props.region}/>
    </div>
    {props.info.age.show && (
      <div className="stack-item active">
        <div className="row" style={{background:'#2C2C2C', borderRadius: '2px', margin:'7px 0px'}}>
          <div className="col-md-6 align-self-center">
            <h4 style={{padding: '3px 2px', fontSize: '14px'}}>Kelompok Umur</h4>
          </div>
        </div>
        <StackedBarCenterAxis age={props.info.age.value} />
      </div>
    )}
    {props.info.agama.show && (
      <div className="stack-item active">
        <div className="row" style={{background:'#2C2C2C', borderRadius: '2px', margin:'7px 0px'}}>
          <div className="col-md-6 align-self-center">
            <h4 style={{padding: '3px 2px', fontSize: '14px'}}>Religi</h4>
          </div>
        </div>
        <PieChart agama={props.info.agama.value} />
      </div>
    )}
    {props.info.pendidikan.show && (
      <div className="stack-item active">
        <div className="row" style={{background:'#2C2C2C', borderRadius: '2px', margin:'7px 0px'}}>
          <div className="col-md-6 align-self-center">
            <h4 style={{padding: '3px 2px', fontSize: '14px'}}>Pendidikan</h4>
          </div>
        </div>
        <Bar100Horizontal pendidikan={props.info.pendidikan.value} />
      </div>
    )}
    {props.info.ses.show && (
      <div className="stack-item active">
        <div className="row" style={{background:'#2C2C2C', borderRadius: '2px', margin:'7px 0px'}}>
          <div className="col-md-6 align-self-center">
            <h4 style={{padding: '3px 2px', fontSize: '14px'}}>Status Sosial Ekonomi </h4>
          </div>
        </div>
        <SESPie ses={props.info.ses.value} />
        <div className="stack-small social-economy-status">
          {props.info.ses.value.data.map((d, i) => {
            let total_data = d.total_data;
            if (d.total_data === 'undefined') {
              total_data = (d.left_data + d.right_data)
            }
            // console.log(((total_data / props.info.ses.value.total_value) * 100).toFixed(2))
            return (
            <p key={i}>
              <span>
                {((total_data / props.info.ses.value.total_value) * 100).toFixed(2)} %
              </span>
              <span style={{ color: sesColorScale[i] }}>{d.order_name}</span> :{" "}
              <span>{d.order_value}</span>
            </p>
          )})}

         
        </div>
      </div>
    )}

    <div className="stack-item active">
      <div className="row" style={{background:'#2C2C2C', borderRadius: '2px', margin:'7px 0px'}}>
        <div className="col-md-6 align-self-center">
          <h4 style={{padding: '3px 2px', fontSize: '14px'}}>Issue Area</h4>
        </div>
      </div>
      <Bar100HorizontalCrowd region={props.region}/>
    </div>

    {/* <div className="stack-item active">
      <div className="row" style={{background:'#2C2C2C', borderRadius: '2px', margin:'7px 0px'}}>
        <div className="col-md-6 align-self-center">
          <h4 style={{padding: '3px 2px', fontSize: '14px'}}>Football Class</h4>
        </div>
      </div>
      <Bar100HorizontalRelawan region={props.region}/>
    </div> */}
  </div>
);}

const mapStateToProps = state => ({
  info: state.map
});

export default connect(mapStateToProps)(SideInfoRelawan);
