import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { closeModal } from "actions";
import { signUp } from "actions/authActions";
import { Formik, Form, Field, ErrorMessage } from "formik";

class ModalRegister extends Component {
  handleSubmitForm = values => {
    this.props.signUp(values);
  };

  render() {
    const { modal } = this.props;
    const { handleSubmitForm } = this;

    return (
      <ReactModal isOpen={modal.showRegister} contentLabel="Add User">
        <span
          class="button-close-diagonal"
          onClick={() => this.props.closeModal()}
        >
          <i class="icon dripicons-cross" />
        </span>
        <div class="row modal-register">
          <div class="col-lg-12">
            <Formik
              initialValues={{ name: "",gender: "",address:"", email: "", password: "", phone: "", role:{id : ""} }}
              validate={values => {
                let errors = {};
                if (!values.email) {
                  errors.email = "Email Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid Email";
                }

                if (!values.password) {
                  errors.password = "Password Required";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                //tambah state is submitting di redux
                handleSubmitForm(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="register-form">
                  <div className="content">
                    <div className="input-group input-lg">
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Full Name"
                      />
                      <ErrorMessage name="name" component="div" />
                    </div>
                    <div className="input-group input-lg">
                      <Field
                        component="select"
                        name="gender"
                        className="form-control"
                        placeholder="gender"
                      >
                        <option value="0" className="select-placeholder">Gender</option>
                        <option value="L">Laki - laki</option>
                        <option value="P">Perempuan</option>
                      </Field>
                      <ErrorMessage name="address" component="div" />
                    </div>
                    <div className="input-group input-lg">
                      <Field
                        component="textarea"
                        name="address"
                        className="form-control"
                        placeholder="Address"
                      />
                      <ErrorMessage name="email" component="div" />
                    </div>
                    <div className="input-group input-lg">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                      />
                      <ErrorMessage name="email" component="div" />
                    </div>
                    <div className="input-group input-lg">
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="form-control"
                      />
                      <ErrorMessage name="password" component="div" />
                    </div>
                    <div className="input-group input-lg">
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className="form-control"
                      />
                      <ErrorMessage name="confirmPassword" component="div" />
                    </div>
                    <div className="input-group input-lg">
                      <Field
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        className="form-control"
                      />
                      <ErrorMessage name="phone" component="div" />
                    </div>
                    <div className="input-group input-lg">
                      <Field
                        component="select"
                        name="role.id"
                        className="form-control"
                      >
                        <option value="0" className="select-placeholder">Role</option>
                        <option value="1">Admin</option>
                        <option value="9">Tester</option>
                        <option value="10">Super</option>
                      </Field>
                      <ErrorMessage name="role.id" component="div" />
                    </div>
                  </div>
                  <div className="footer text-right">
                    <div className="form-action">
                      <input
                        type="submit"
                        className="btn btn-primary btn-block "
                        value="Add User"
                        // onClick={() => toastr.success("Success Adding User!")}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.modal
});

const mapDispatchToProps = {
  closeModal, signUp
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalRegister);
