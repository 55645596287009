import React, { Fragment, Component } from "react";
import toastr from "toastr";
import { GET } from "API";
import { connect } from "react-redux";

import SidebarPoiRelawan from "components/layout/sidebar/SidebarPoiRelawan";
// import Map from "./map";
import MapPoiRelawan from "components/shared/map/MapPoiRelawan";
import Iframe from 'react-iframe';
import { Modal, ModalBody, Button } from "reactstrap";
import { reAuth } from "actions/authActions";
import MetaTags from 'react-meta-tags';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

import ModalDialogSupporter from "components/shared/ModalDialogSupporter";

import "styles/components/poi-info.css";

const API_HOST = process.env.REACT_APP_API_HOST;

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'kelurahan',
    headerName: 'Kelurahan',
    width: 150,
    editable: true,
  },
  {
    field: 'kecamatan',
    headerName: 'Kecamatan',
    width: 150,
    editable: true,
  },
  {
    field: 'province',
    headerName: 'Province',
    // type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'mmtype',
    headerName: 'Matcmaking Type',
    description: 'Matchmaking between Supporter vs Definitely Vote',
    sortable: false,
    width: 160,
    // valueGetter: (params) =>
    //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
];

const rows = [
  { id: 1, kelurahan: 'KARANGANYAR', kecamatan: 'ASTANA ANYAR', province: 'JAWA BARAT', mmtype: 'Die Hard Support' },
  { id: 2, kelurahan: 'CIBULAKAN', kecamatan: 'CUGENANG', province: 'JAWA BARAT', mmtype: 'Die Hard Support' },
  { id: 3, kelurahan: 'SETIAMEKAR', kecamatan: 'TAMBUN SELATAN', province: 'JAWA BARAT', mmtype: 'Die Hard Support' },
  { id: 4, kelurahan: 'GARUDA', kecamatan: 'ANDIR', province: 'JAWA BARAT', mmtype: 'Die Hard Support' },
  { id: 5, kelurahan: 'CURUG', kecamatan: 'GUNUNG SINDUR', province: 'JAWA BARAT', mmtype: 'Die Hard Support' }
];

class POIRelawan extends Component {
  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    poiList: {
      category: {},
      summary: {
        business: [],
        // products: [],
        // lifestyle: [],
        health: [],
        // travel: []
      }
    },
    isInfoActive: false,
    isSatellite: false,
    isMapOccupancy: false,
    modal: false
  };

  toggle = this.toggle.bind(this);

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  handleToggleInfo = () => {
    this.setState({ isInfoActive: !this.state.isInfoActive });
  };

  handleCloseInfo = () => {
    this.setState({ isInfoActive: false });
  };

  handleOpenInfo = () => {
    this.setState({ isInfoActive: true });
  };

  handleToggleSatellite = () => {
    this.setState({ isSatellite: !this.state.isSatellite})
  }

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          console.log(response.data.summary);
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            },
            poiList: {
              ...this.state.poiList,
              summary: {
                business: response.data.summary["Business"],
                // products: response.data.summary["Products & Services"],
                // lifestyle: response.data.summary["Lifestyle"],
                health: response.data.summary["Health"],
                // travel: response.data.summary["Travel"]
              }
            },
            isInfoActive: true
          });
        })
        .catch(err => {
          console.log(err);
          // err.response.status === 401
          //   ? this.props.reAuth()
          //   : toastr.error(err.response.statusText);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
    this.setState({
      isMapOccupancy: false
    });
    // fetch pulau on mount component, then setState to region
    await GET(API_HOST + "/sental/master/pulau")
      .then(res =>
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            pulau: { ...this.state.region.pulau, data: res.data },
            step: 1
          }
        })
      )
      .catch(err => {
        err.response.status === 401
          ? this.props.reAuth()
          : toastr.error(err.response.statusText);
      });
    await GET(API_HOST + "/sental/geo/master/poi")
      .then(res => {
        // console.log("xxx");
        // console.log(res.data.data);
        this.setState({
          ...this.state,
          poiList: {
            category: res.data.data,
            summary: {
              business: res.data.summary["Business"],
              health: res.data.summary["Health"],
              // lifestyle: res.data.summary["Lifestyle"],
              // products: res.data.summary["Products & Services"],
              // travel: res.data.summary["Travel"]
            }
          }
        })
      }
      )
      .catch(err => {
        // err.response.status === 401
        //   ? this.props.reAuth()
        //   : toastr.error(err.response.statusText);
        console.log(err);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.map !== this.props.map) {
      console.log(this.props.map.poi);
      if(this.props.map.poi !== undefined && this.props.map.poi !== null) {
        let type_poi = this.props.map.poi.type
        if(type_poi == 'HE-4-1') {
          // console.log('occupancy poi')
          this.setState({
            isMapOccupancy: true
          });
        }
      }
    }
  }

  render() {
    const { isInfoActive, poiList, isSatellite, isMapOccupancy } = this.state;
    const {
      handleToggleInfo,
      handleCloseInfo,
      handleOpenInfo,
      handleGetSubMenu,
      handleToggleSatellite
    } = this;

    let map_bottom;
    let modal;
    
    if(!isMapOccupancy) {
      map_bottom = <MapPoiRelawan source="turnout" type="poi_zonasi" handleBorderMapClick={()=>{}} />
      modal = <Fragment></Fragment>
    } else {
      map_bottom = <MapPoiRelawan source="turnout" type="occupancy" handleBorderMapClick={()=>{}} />
      modal = <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalBody>
          <Iframe url="http://yankes.kemkes.go.id/app/siranap/rumah_sakit?jenis=1&propinsi=11prop&kabkota="
                  width="100%"
                  height="800px"
                  id="occupancy-modal"
                  className="iframe-occupancy"
                  // styles={{marginTop: "20%"}}
                  position="relative"/>
        </ModalBody>
    </Modal>
    }

    return (
      <Fragment>
        {/* <MetaTags>
            <title>Page 1</title>
            <meta property="http-equiv" content="Content-Security-Policy" />
            <meta property="content" content="upgrade-insecure-requests" />
        </MetaTags> */}
        <SidebarPoiRelawan
          handleClose={handleCloseInfo}
          handleGetSubMenu={handleGetSubMenu}
          handleOpen={handleOpenInfo}
          handleToggleSatellite={handleToggleSatellite}
          state={this.state}
        />
        {/* <Map isSatellite={isSatellite} />
         */}
        {/* <MapPoi source="turnout" type="poi_zonasi" handleBorderMapClick={()=>{}} /> */}
        <div>
          {map_bottom}
        </div>

        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>

        {/* {modal} */}
        {/* <div className={`POI-info ${isInfoActive ? "info-active" : ""}`}>
          <div className="info-container">
            <button className="close-bottom" onClick={() => handleToggleInfo()}>
              <i
                className={`zmdi zmdi-chevron-${isInfoActive ? "down" : "up"}`}
              />
            </button>
            <div className="container-fluid">
              <div className="row">
                {poiList.summary.business && (
                  <div className="col">
                    <i
                      className="icon icon-skyview"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL
                        }/img/icon/BU.png)`
                      }}
                    />
                    <ul>
                      {poiList.summary.business &&
                        poiList.summary.business.map((d, i) => (
                          <li key={i}>
                            <a href="#">
                              <span>{d.sub_category}</span>{" "}
                              <span>{d.total}</span>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {poiList.summary.products && (
                  <div className="col">
                    <i
                      className="icon icon-skyview"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL
                        }/img/icon/HE.png)`
                      }}
                    />
                    <ul>
                      {poiList.summary.products &&
                        poiList.summary.products.map((d, i) => (
                          <li key={i}>
                            <a href="#">
                              <span>{d.sub_category}</span>{" "}
                              <span>{d.total}</span>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {poiList.summary.lifestyle && (
                  <div className="col">
                    <i
                      className="icon icon-skyview"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL
                        }/img/icon/LS.png)`
                      }}
                    />
                    <ul>
                      {poiList.summary.lifestyle &&
                        poiList.summary.lifestyle.map((d, i) => (
                          <li key={i}>
                            <a href="#">
                              <span>{d.sub_category}</span>{" "}
                              <span>{d.total}</span>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {poiList.summary.health && (
                  <div className="col">
                    <i
                      className="icon icon-skyview"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL
                        }/img/icon/PS.png)`
                      }}
                    />
                    <ul>
                      {poiList.summary.health &&
                        poiList.summary.health.map((d, i) => (
                          <li key={i}>
                            <a href="#">
                              <span>{d.sub_category}</span>{" "}
                              <span>{d.total}</span>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {poiList.summary.travel && (
                  <div className="col">
                    <i
                      className="icon icon-skyview"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL
                        }/img/icon/TR.png)`
                      }}
                    />
                    <ul>
                      {poiList.summary.travel &&
                        poiList.summary.travel.map((d, i) => (
                          <li key={i}>
                            <a href="#">
                              <span>{d.sub_category}</span>{" "}
                              <span>{d.total}</span>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div> */}
        <ModalDialogSupporter />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  map: state.map
});

const mapDispatchToProps = {
  reAuth
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(POIRelawan);
