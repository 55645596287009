const initState = {
  show: false,
  showRegister: false,
  showTopic: false,
  data: ""
};

export default function modalReducer(state = initState, action) {
  switch (action.type) {
    case "SET_MODAL":
      return {
        show: true,
        showRegister: false,
        data: action.payload
      };
    case "SET_MODAL_REGISTER":
      return {
        show: false,
        showRegister: true,
        showTopic: false,
        data: ""
      };
    case "SET_MODAL_TOPIC":
      return {
        show: false,
        showRegister: false,
        showTopic: true,
        data: action.payload
      };
    case "CLOSE_MODAL":
      return {
        show: false,
        showRegister: false,
        showTopic: false,
        data: ""
      };
    default:
      return state;
  }
}
