import React, { PureComponent } from "react";
import { VictoryPie } from "victory";
import CustomLabel from "./CustomLabel";

export default class SESPie extends PureComponent {
  state = { data: [] };
  async componentDidMount() {
    const data = await this.props.ses.data.map(d => {
      return {
        x: d.order_id,
        y: (d.total_data / this.props.ses.total_value) * 100,
        label: `${d.order_name} \n ${d.total_data.toLocaleString()}`
      };
    });
    this.setState({ data });
  }
  render() {
    const { data } = this.state;
    return (
      <VictoryPie
        style={{
          labels: {
            fontSize: 18,
          }
        }}
        width={400}
        height={400}
        colorScale={["#007b4a", "#ffaa55", "#9335f3", "#0097a5", "#a91922"]}
        innerRadius={80}
        data={data}
        padding={50}
        padAngle={3}
        labelRadius={120}
        labelComponent={<CustomLabel />}
        events={[
          {
            target: "data",
            eventHandlers: {
              onMouseOver: () => {
                return [
                  {
                    target: "data",
                    mutation: () => ({
                      innerRadius: 75
                    })
                  },
                  {
                    target: "labels",
                    mutation: () => ({ active: true })
                  }
                ];
              },
              onMouseOut: () => {
                return [
                  {
                    target: "data",
                    mutation: () => {}
                  },
                  {
                    target: "labels",
                    mutation: () => ({ active: false })
                  }
                ];
              }
            }
          }
        ]}
      />
    );
  }
}
