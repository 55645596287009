import React, { Component } from "react";
import TopicCloud from "components/shared/charts/TopicCloud";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import TopicPie from "components/shared/charts/TopicPieChartJS2";
import ModalTopic from "components/shared/ModalTopic";
import { getSentiment } from "API";
import moment from "moment";
import { Graph } from "react-d3-graph";
import { graphData } from "./dummy";
import { myConfig } from "helpers/graphConfig";
import loading from "media/img/components/loading.gif";

import profile1 from "media/img/avatars/paslon1.png";
import profile2 from "media/img/avatars/paslon2.png";

import "styles/components/whatson.css";
import BorderMap from "components/shared/map/BorderMapWhatson";

import { connect } from "react-redux";
import { getProvince } from "actions/mapActions";
import { setModalTopic } from "actions";

const RadioCheck = ({ number, isActiveOne, isActiveTwo }) => (
  <ul className="radio-check">
    <li className={isActiveOne === 1 || isActiveTwo === 1 ? "active" : ""}>
      <a href="#">Semua</a>
    </li>
    <li className={isActiveOne === 2 || isActiveTwo === 2 ? "active" : ""}>
      <a href="#">Positif</a>
    </li>
    <li className={isActiveOne === 3 || isActiveTwo === 3 ? "active" : ""}>
      <a href="#">Negatif</a>
    </li>
    <li className={isActiveOne === 4 || isActiveTwo === 4 ? "active" : ""}>
      <a href="#">Netral</a>
    </li>
  </ul>
);

class TopicMapping extends Component {
  state = {
    openSubOne: {
      active: 1,
      opened: false
    },
    openSubTwo: {
      active: 1,
      opened: false
    },
    typeOne: "sentiment",
    typeTwo: "sentiment",
    dataOne: {},
    dataTwo: {},
    isActiveOne: {
      sentiment: 1,
      coverage: 1,
      topic: 1
    },
    isActiveTwo: {
      sentiment: 1,
      coverage: 1,
      topic: 1
    },
    isLoadingOne: true,
    isLoadingTwo: true
  };

  handleSubOne = type => {
    if (this.state.openSubOne.active !== type) {
      this.setState({
        ...this.state,
        openSubOne: {
          active: type,
          opened: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        openSubOne: {
          active: type,
          opened: !this.state.openSubOne.opened
        }
      });
    }
  };

  handleSubTwo = type => {
    if (this.state.openSubTwo.active !== type) {
      this.setState({
        ...this.state,
        openSubTwo: {
          active: type,
          opened: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        openSubTwo: {
          active: type,
          opened: !this.state.openSubTwo.opened
        }
      });
    }
  };

  handleBorderMapClick = data => {
    this.props.setModalTopic({
      type: "map",
      // path: `whatson/${data.type === "coverage" ? data.type : "sentimen"}/data`,
      path: `whatson/${"sentimen"}/data`,
      params: { candidate: data.candidate, location_code: data.kode_prop }
    });
  };

  handlePieClick = ({type, candidate, sentiment_type}) => {
    this.props.setModalTopic({
      type: "pie",
      // path: `whatson/${type === "coverage" ? type : "sentimen"}/data`,
      path: `whatson/${"sentimen"}/data`,
      params: { candidate, sentiment_type }
    });
  };

  handleHashtagClick = text => {
    this.props.setModalTopic({
      type: "hashtag",
      // path: `whatson/wordcloud/data`,
      path: `whatson/${"sentimen"}/data`,
      params: { keyword: text }
    });
  };

  async componentDidMount() {
    const responseOne = await getSentiment("sentimen", "jokowi");
    const responseTwo = await getSentiment("sentimen", "prabowo");
    this.setState({
      dataOne: responseOne.data.data,
      dataTwo: responseTwo.data.data,
      isLoadingOne: false,
      isLoadingTwo: false
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.openSubOne.active !== 2 &&
      this.state.openSubOne.active === 2
    ) {
      const responseOne = await getSentiment("coverage", "jokowi");
      this.setState({
        ...this.state,
        typeOne: "coverage",
        dataOne: responseOne.data.data,
        isLoadingOne: false
      });
    } else if (
      prevState.openSubOne.active !== 1 &&
      this.state.openSubOne.active === 1
    ) {
      const responseOne = await getSentiment("sentimen", "jokowi");
      this.setState({
        ...this.state,
        typeOne: "sentiment",
        dataOne: responseOne.data.data,
        isLoadingOne: false
      });
    } else if (
      prevState.openSubTwo.active !== 2 &&
      this.state.openSubTwo.active === 2
    ) {
      const responseTwo = await getSentiment("coverage", "prabowo");
      this.setState({
        ...this.state,
        typeTwo: "coverage",
        dataTwo: responseTwo.data.data,
        isLoadingTwo: false
      });
    } else if (
      prevState.openSubTwo.active !== 1 &&
      this.state.openSubTwo.active === 1
    ) {
      const responseTwo = await getSentiment("sentimen", "prabowo");
      this.setState({
        ...this.state,
        typeTwo: "sentiment",
        dataTwo: responseTwo.data.data,
        isLoadingTwo: false
      });
    }
  }

  render() {
    const { map } = this.props;
    const {
      openSubOne,
      openSubTwo,
      typeOne,
      typeTwo,
      dataOne,
      dataTwo,
      isActiveOne,
      isActiveTwo,
      isLoadingOne,
      isLoadingTwo
    } = this.state;
    const { handleBorderMapClick, handlePieClick, handleHashtagClick } = this;
    return (
      <React.Fragment>
        <div className="full-width dark-bg overview-legend">
          <section className="page-content container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="overview-header">
                  Sentiment, Coverage, and Topic Mapping
                </h4>
              </div>
            </div>
          </section>
        </div>
        <section className="container-fluid overview-content">
          <div className="overview-right">
            {/* <div className="row" style={{ borderBottom: "1px dotted #c2bdbd" }}>
              <div className="col-lg-5">
                <div className="row">
                  <div className="col-lg-12 overview-legend topic-mapping">
                    <div className="candidate-info-header left paslon1">
                      <img
                        src={profile1}
                        className="rounded-circle"
                        alt="Candidate Name"
                      />
                      <div className="candidate-name">
                        <h2>Erick Thohir - SOV</h2>
                        {dataOne.start_date && (
                          <span>
                            Weekly (
                            {moment(dataOne.start_date).format("DD MMM YYYY")} -{" "}
                            {moment(dataOne.end_date).format("DD MMM YYYY")})
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <ul className="sliding-menu">
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState(
                              {
                                ...this.state,
                                isLoadingOne: true
                              },
                              () => this.handleSubOne(1)
                            );
                          }}
                          className={` ${
                            openSubOne.active === 1 ? "selected" : ""
                          }`}
                        >
                          Sentiment
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState(
                              {
                                ...this.state,
                                isLoadingOne: true
                              },
                              () => this.handleSubOne(2)
                            );
                          }}
                          className={` ${
                            openSubOne.active === 2 ? "selected" : ""
                          }`}
                        >
                          Coverage
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.handleSubOne(3);
                          }}
                          className={` ${
                            openSubOne.active === 3 ? "selected" : ""
                          }`}
                        >
                          Topic
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <Row>
                  <Col>
                    <div className="pieTopic">
                      {!isLoadingOne && (
                        <TopicPie
                          candidate="jokowi"
                          type={typeOne}
                          handlePieClick={handlePieClick}
                          data={dataOne}
                        />
                      )}
                      {isLoadingOne && (
                        <Row style={{ minHeight: 406 + "px" }}>
                          <Col className="text-center">
                            <img src={loading} alt="loading" />
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="col-lg-7">
                {(this.state.openSubOne.active === 1 ||
                  this.state.openSubOne.active === 2) &&
                  !isLoadingOne && (
                    <BorderMap
                      data={dataOne}
                      handleBorderMapClick={handleBorderMapClick}
                      candidate="jokowi"
                      type={typeOne}
                    />
                  )}
                {this.state.openSubOne.active === 3 && (
                  <div className="padding-extra wordcloud">
                    <TopicCloud
                      candidate="jokowi"
                      handleHashtagClick={handleHashtagClick}
                    />
                  </div>
                )}
                {this.state.openSubOne.active === 4 && !isLoadingOne && (
                  <Graph
                    id="graph-id" // id is mandatory, if no id is defined rd3g will throw an error
                    data={graphData}
                    config={myConfig}
                  />
                )}
                {isLoadingOne && this.state.openSubOne.active !== 3 && (
                  <Row style={{ minHeight: 406 + "px" }}>
                    <Col className="text-center">
                      <img src={loading} alt="loading" />
                    </Col>
                  </Row>
                )}
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-5">
                <div className="row">
                  <div className="col-lg-12 overview-legend topic-mapping">
                    <div className="candidate-info-header left paslon2">
                      <img
                        src={profile2}
                        className="rounded-circle"
                        alt="Candidate Name"
                      />
                      <div className="candidate-name">
                        <h2>Covid and Vaccinated - SOV</h2>
                        {dataTwo.start_date && (
                          <span>
                            Weekly (
                            {moment(dataTwo.start_date).format("DD MMM YYYY")} -{" "}
                            {moment(dataTwo.end_date).format("DD MMM YYYY")})
                          </span>
                        )}
                        <p><strong>source:</strong> News/Media, Twitter, IG, Facebook</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <ul className="sliding-menu">
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState(
                              {
                                ...this.state,
                                isLoading: true
                              },
                              () => this.handleSubTwo(1)
                            );
                          }}
                          className={` ${
                            openSubTwo.active === 1 ? "selected" : ""
                          }`}
                        >
                          Sentiment
                        </a>
                        {/* <div
                          className={`sliding-checklist ${
                            openSubTwo.active === 1 && openSubTwo.opened
                              ? "active"
                              : ""
                          }`}
                        >
                            <ul className="radio-check">
                              <li className={isActiveTwo.sentiment === 1 ? 'active' : ''}><a href="#">Semua</a></li>
                              <li className={isActiveTwo.sentiment === 2 ? 'active' : ''}><a href="#">Positif</a></li>
                              <li className={isActiveTwo.sentiment === 3 ? 'active' : ''}><a href="#">Negatif</a></li>
                              <li className={isActiveTwo.sentiment === 4 ? 'active' : ''}><a href="#">Netral</a></li>
                            </ul>
                        </div> */}
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState(
                              {
                                ...this.state,
                                isLoadingTwo: true
                              },
                              () => this.handleSubTwo(2)
                            );
                          }}
                          className={` ${
                            openSubTwo.active === 2 ? "selected" : ""
                          }`}
                        >
                          Coverage
                        </a>
                        {/* <div
                          className={`sliding-checklist ${
                            openSubTwo.active === 2 && openSubTwo.opened
                              ? "active"
                              : ""
                          }`}
                        >
                            <ul className="radio-check">
                              <li className={isActiveTwo.coverage === 1 ? 'active' : ''}><a href="#">Semua</a></li>
                              <li className={isActiveTwo.coverage === 2 ? 'active' : ''}><a href="#">Positif</a></li>
                              <li className={isActiveTwo.coverage === 3 ? 'active' : ''}><a href="#">Negatif</a></li>
                              <li className={isActiveTwo.coverage === 4 ? 'active' : ''}><a href="#">Netral</a></li>
                            </ul>
                        </div> */}
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.handleSubTwo(3);
                          }}
                          className={`${
                            openSubTwo.active === 3 ? "selected" : ""
                          }`}
                        >
                          Topic
                        </a>
                        {/* <div
                          className={`sliding-checklist ${
                            openSubTwo.active === 3 && openSubTwo.opened
                              ? "active"
                              : ""
                          }`}
                        >
                            <ul className="radio-check">
                              <li className={isActiveTwo.topic === 1 ? 'active' : ''}><a href="#">Semua</a></li>
                              <li className={isActiveTwo.topic === 2 ? 'active' : ''}><a href="#">Positif</a></li>
                              <li className={isActiveTwo.topic === 3 ? 'active' : ''}><a href="#">Negatif</a></li>
                              <li className={isActiveTwo.topic === 4 ? 'active' : ''}><a href="#">Netral</a></li>
                            </ul>
                        </div> */}
                      </li>
                    </ul>
                  </div>
                </div>

                <Row>
                  <Col>
                    <div className="pieTopic">
                      {!isLoadingTwo && (
                        <TopicPie
                          candidate="jokowi"
                          type={typeTwo}
                          handlePieClick={handlePieClick}
                          data={dataTwo}
                        />
                      )}
                      {isLoadingTwo && (
                        <Row style={{ minHeight: 406 + "px" }}>>
                          <Col className="text-center">
                            <img src={loading} alt="loading" />
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="col-lg-7">
                {this.state.openSubTwo.active !== 3 && !isLoadingTwo && (
                  <BorderMap
                    data={dataTwo}
                    handleBorderMapClick={handleBorderMapClick}
                    candidate="prabowo"
                    type={typeTwo}
                  />
                )}
                {this.state.openSubTwo.active === 3 && !isLoadingTwo && (
                  <div className="padding-extra wordcloud">
                    <TopicCloud
                      candidate="prabowo"
                      handleHashtagClick={handleHashtagClick}
                    />
                  </div>
                )}
                {isLoadingTwo && this.state.openSubOne.active !== 3 && (
                  <Row style={{ minHeight: 406 + "px" }}>
                    <Col className="text-center">
                      <img src={loading} alt="loading" />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </section>
        <ModalTopic />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  map: state.map,
  chart: state.chart
});

const mapDispatchToProps = {
  getProvince,
  setModalTopic
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicMapping);
