import React from "react";
import "styles/components/charts.css";

const Horizontal100 = ({ data, withpercent = false }) => (
  <div className="horizontal-100-chart">
    <h4 className="chart-header">{data.title}</h4>
    {withpercent && (
      <div className="percentage-popular">
        <span>{data.items[0].percentage}%</span>
        <span>{data.items[1].percentage}%</span>
      </div>
    )}
    <div className="chart-items">
      {data.items.length > 0 &&
        data.items.map((item, key) => (
          <span
            key={key}
            style={{
              width: item.percentage + "%",
              backgroundColor: item.color
            }}
          ><span className="hover-item">{item.percentage.toFixed(2)}%</span></span>
        ))}
    </div>
    {withpercent && (
      <div className="percentage">
        <span>0%</span>
        <span>25%</span>
        <span>50%</span>
        <span>75%</span>
      </div>
    )}
  </div>
);

export default Horizontal100;
