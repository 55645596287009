import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import registerServiceWorker from './registerServiceWorker';

import 'moment/locale/id';
import 'styles/vendor/bootstrap.css';
import 'styles/icons/line-awesome.min.css';
import 'styles/icons/dripicons.min.css';
import 'styles/icons/material-design-iconic-font.min.css';
import 'styles/common/main.css';
import 'styles/layouts/vertical/core/main.css';
import 'styles/layouts/vertical/menu-type/default.css';
import 'styles/layouts/vertical/themes/theme-j.css';
import 'styles/style.css';

import toastr from 'toastr';
toastr.options.preventDuplicates = true;

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();