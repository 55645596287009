import React, { Component } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  InfoWindow
} from "react-google-maps";
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import Poly from "./Poly";
import MarkerPoi from "./MarkerPoi";
import LegendsFix from "./LegendsFix";
import { setModal } from "actions";
import { connect } from "react-redux";

import { getProvince, getTurnout, getVPCandidate } from "actions/mapActions";
import { darkNormal, darkOnlyIna, darkWithoutLabel } from './styles'
import {dataDummyOverview, legendDummyOverview} from './dummy'
import ReactHtmlParser from 'react-html-parser'

const styleMap = darkWithoutLabel;

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB5QWo1-TD3qdYW1z7SAYialAfUE4HBzN4",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div className="mapHeight"/>
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    zoom={props.zoom}
    center={props.position}
    defaultOptions={{
      maxZoom: 8,
      minZoom: 5,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      styles: styleMap
    }}
    onZoomChanged={props.onZoomChanged}
    ref={props.mapref}
  >
    
    {props.isPolygon && props.polygon}
    {props.isPoi && props.dataPoi !== undefined  &&
      props.dataPoi.data.map((poi, key) => (
        <MarkerPoi key={key} data={poi} icon={props.dataPoi.icon} clickEvent={(props.handleClickMarker)}/>
      ))}
    {props.isHover &&
      (
        <InfoBox
          position={new window.google.maps.LatLng(parseFloat(props.infoData.position.lat), parseFloat(props.infoData.position.lng))} 
          options={{
            alignBottom: true,
            pane: 'mapPane',
            closeBoxURL: ``,
            enableEventPropagation: true
          }}
        >
          <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', padding: '10px', color: 'white' }}>
            <h4> {props.infoData.name} </h4>
            <span>Avg. SOV : {props.infoData.total_dpt.toLocaleString()} %</span>
            <br/><br/>
            <span>{ReactHtmlParser(props.infoData.info)}</span>
          </div>
        </InfoBox>)
    }
  </GoogleMap>
));

class MapBottom extends Component {
  state = {
    isPolygon: false,
    polygon: null,
    infoData: null,
    infoLocation: false,
    isHover: false,
    zoom: 5,
    position: { lat: -11.102532, lng: 119.6654844 },
    marker: null,
    dataLegend: null
  };

  constructor() {
    super();

    // get ref for google map
    this.map = React.createRef();

    // this.getRangeColor();
  }

  // getDataPOI = id => {
  //   let url;
  //   if (id === "rumah_sakit") {
  //     url = baseurl + "app_commands.php?getPOIRumahSakit";
  //   } else if (id === "universitas") {
  //     url = baseurl + "app_commands.php?getPOIUniversitas";
  //   }

  //   axios
  //     .get(url)
  //     .then(response => {
  //       this.setState({ dataPoi: response.data, isPoi: true, marker: id });
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // };

  // getDataPoly = id => {
  //   let url;
  //   if (id === "prop") {
  //     url = baseurl + "app_commands.php?getProvince";
  //   } else if (id === "kab") {
  //     url = baseurl + "app_commands.php?getKabupaten";
  //   } else if (id === "kec") {
  //     url = baseurl + "app_commands.php?getKecamatan";
  //   }

  //   axios
  //     .get(url)
  //     .then(response => {
  //       this.drawPolygon(response.data, id);
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // };

  // getRangeColor = () => {
  //   axios
  //     .get(baseurl + "app_commands.php?getRangeColor~prov_rumahsakit")
  //     .then(response => {
  //       this.setState({ dataLegend: response.data });
  //       this.getDataPoly("prop");
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // };

  drawPolygon = (dataPoly, source = null) => {
    let data_legend = this.props.map.province.legend;
    // if(source === 'overview') {
    //   data_legend = legendDummyOverview;
    //   console.log("XXX overview");
    //   console.log(data_legend)
    // }

    const polygon = dataPoly.length
      ? dataPoly.map((val, i) => {
          let coordinates = val.coordinates;
          coordinates.trim();
          
          // console.log("xxx draw poly..");
          // console.log(val.info);

          const shape_type =
            coordinates.toLowerCase().indexOf("multipolygon") >= 0
              ? "MULTI"
              : "SINGLE";

          coordinates = coordinates.replace(/\)\),\(\(/g, "|");
          coordinates = coordinates.replace(/[MULTIPOLYGON(((]/g, "");
          coordinates = coordinates.replace(/[POLYGON((]/g, "");
          coordinates = coordinates.replace(/[((]/g, "");
          coordinates = coordinates.replace(/[)))]/g, "");
          coordinates = coordinates.replace(/[)]/g, "");
          coordinates = coordinates.replace(/[(]/g, "");

          let coords = coordinates;
          coords = coords.trim();
          const checkCoords = coords.substring(coords.length - 1);
          if (checkCoords === ",")
            coords = coords.substring(0, coords.length - 1);
          let coordsArray = [];
          coordsArray = coords.split(",");

          const data = {
            name: val.value,
            lat: val.center_l_1,
            lng: val.center_l_2,
            kode_prop: val.kode_prop,
            scale: val.scale,
            value: val.value,
            total_dpt: val.total_dpt,
            info: val.info
          };

          let poly = [];

          if (shape_type === "MULTI") {
            var multiArr = coords.split("|");

            for (var xAr = 0; xAr < multiArr.length; xAr++) {
              var multiTemp = multiArr[xAr].split(",");

              var newCoord = [];
              for (var j = 0; j < multiTemp.length; j++) {
                var coordlatlng = multiTemp[j].split(" ");
                newCoord.push({
                  lat: parseFloat(coordlatlng[1]),
                  lng: parseFloat(coordlatlng[0])
                });
              }
              poly.push(
                <Poly
                  key={i + "j" + xAr}
                  triangleCoords={newCoord}
                  handlePolyClick={() => this.handlePolyClick(data, source)}
                  handlePolyHover={() => this.handlePolyHover(data)}
                  handlePolyMouseOut={() => this.handlePolyMouseOut()}
                  color={val.color}
                  jumlah={val.jumlah}
                  dataLegend={data_legend}
                  kode_prop={val.kode_prop}
                />
              );
            }
          } else if (shape_type === "SINGLE") {
            const triangleCoords = [];
            for (let i = 0; i < coordsArray.length; i++) {
              const coordlatlng = coordsArray[i].split(" ");
              triangleCoords.push({
                lat: parseFloat(coordlatlng[1]),
                lng: parseFloat(coordlatlng[0])
              });
            }

            poly.push(
              <Poly
                key={i}
                triangleCoords={triangleCoords}
                handlePolyClick={() => this.handlePolyClick(data, source)}
                handlePolyHover={() => this.handlePolyHover(data)}
                handlePolyMouseOut={() => this.handlePolyMouseOut()}
                color={val.color}
                jumlah={val.jumlah}
                dataLegend={data_legend}
                kode_prop={val.kode_prop}

              />
            );
          }

          return poly;
        })
      : console.log("Loadiiing");
    this.setState({ isPolygon: true, polygon });
  };

  handlePolyClick = (val, source = null) => {
    // this.map.current.panTo({ lat: parseFloat(val.lat), lng: parseFloat(val.lng) })
    const position = {
      lat: parseFloat(val.lat),
      lng: parseFloat(val.lng)
    },
    kode_prop = val.kode_prop,
    scale = val.scale,
    value = val.value,
    total_dpt = val.total_dpt;
    this.setState({
      infoData: {
        name: value,
        position,
        total_dpt
      },
      zoom: 7,
      position
    });
    if(source === "turnout"){
      // this.props.changeChart({ position, kode_prop, scale, value });
      console.log("this is turnout!!")
    }else{
      this.props.setModal({ position, kode_prop, scale, value });
    }

  };

  handlePolyHover = val => {
    const position = {
      lat: parseFloat(val.lat),
      lng: parseFloat(val.lng)
    },
    value = val.value,
    total_dpt = val.total_dpt;
    this.setState({
      infoData: {
        position,
        name: value,
        total_dpt,
        info: val.info
      },
      isHover: true
    });
    // console.log("XXX info data hover");
    // console.log(val)
  };

  handlePolyMouseOut = () => {
    this.setState({ isHover: false });
  };

  handleBorder = e => {
    this.setState({ polygon: null });
    this.getDataPoly(e.target.value);
  };

  handleZoomChanged = e => {
    this.setState({ zoom: this.map.current.getZoom() });
  };

  // handlePOI = e => {
  //   this.setState({ isPoi: false, dataPoi: null });
  //   this.getDataPOI(e.target.value);
  // };

  handleClickMarker = (lat, lng) => {
    const position = {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    }
    this.setState({ zoom: 8, position })

  }

  async componentDidMount(){
    if(this.props.source === "turnout"){
      await this.props.getTurnout(this.props.type);
    } else if (this.props.source === "vp-candidate") {
      await this.props.getVPCandidate(this.props.type);
    }
    else{
      await this.props.getProvince();
    }
  }

  async componentDidUpdate(prevProps){
    if(prevProps.map.province.legend !== this.props.map.province.legend){
      if(this.props.source == 'overview') {
        await this.drawPolygon(dataDummyOverview, this.props.source);
      } else {
        await this.drawPolygon(this.props.map.province.map, this.props.source);
      }
    } else if(prevProps.type !== this.props.type && this.props.source === "turnout"){
      await this.props.getTurnout(this.props.type);
    } else if(prevProps.type !== this.props.type && this.props.source === "vp-candidate"){
      await this.props.getVPCandidate(this.props.type);
    }
  }

  render() {
    const { setModal, map } = this.props;
    const {
      polygon,
      isPolygon,
      infoData,
      marker,
      zoom,
      position,
      isHover
    } = this.state;

    return (
      <div style={{ height: "100%" }}>
        <MyMapComponent
          polygon={polygon}
          isPolygon={isPolygon}
          dataPoi={map.poi.res}
          infoData={infoData}
          isPoi={map.poi.isPoi}
          marker={map.poi.marker}
          zoom={zoom}
          position={position}
          isHover={isHover}
          onZoomChanged={this.handleZoomChanged}
          mapref={this.map}
          setModal={setModal}
          handleClickMarker={this.handleClickMarker}
        />

        <LegendsFix dataLegend={map.province.legend} position="bottomLeft"/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  map : state.map
})

const mapDispatchToProps = {
  setModal, getProvince, getTurnout, getVPCandidate
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapBottom);
