// Example state

// var state = {
//   users: {
//     path: '/users',
//     params: {
//       order: 'email',
//       sort: 'asc',
//       skip: 0,
//       limit: 10,
//       filter: ''
//     },
//     isLoading: false,
//     error: null,
//     data: {}
//   }
// }

export default function APIReducer(state={}, action) {
  switch(action.type) {

    case 'INIT_API':
      return {
        ...state,
        [action.name]: {
          ...action.state
        }
      }
      
    case 'SET_API_LOADING':
      return {
          ...state,
          [action.name]: {
              ...state[action.name],
              isLoading: true
          }
      }

    case 'SET_API_DATA': 
      return {
          ...state,
          [action.name]: {
              ...state[action.name],
              data: action.data,
              error: null,
              isLoading: false,
          }
      }

    case 'SET_API_ERROR':
      return {
          ...state,
          [action.name]: {
              ...state[action.name],
              error: action.error,
              isLoading: false,
          }
      }

    case 'SET_API_PARAMS':
      let params = {};

      if (action.reset) params = action.params;
      else params = { ...state[action.name].params, ...action.params };

      for (let key in params) {
          if (!params[key]) delete params[key];
      }

      return {
          ...state,
          [action.name]: {
              ...state[action.name],
              params
          }
      }
    
    case 'RESET_API':
      return {
          ...state,
          [action.name]: {}
      }
    
    default:
      return state;
  }
}