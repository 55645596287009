import React, { Component } from "react";
import Horizontal100 from "components/shared/charts/Horizontal100";
import LineDot from "components/shared/charts/LineDot";
import moment from "moment";

import profile1 from "media/img/avatars/calon2.jpg";
import profile2 from "media/img/avatars/chosen_sandiaga.jpeg";
import profile3 from "media/img/avatars/gp_overview.jpeg";
import profile4 from "media/img/avatars/chosen_anies.jpeg";

import BorderMap from "components/shared/map/BorderMap";
import ShiftingChart from "components/shared/charts/ShiftingChartWhite";
import ModalOverview from "components/shared/ModalOverview";
import MapBottom from "components/shared/map/MapBottom";

import { connect } from "react-redux";
import { setModal } from "actions";

import { ArrowRight, ArrowUp, ArrowDown } from 'react-bootstrap-icons';

import { getTurnout } from "actions/mapActions";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap,
  // getOverview
} from "actions/mapActions";
import { reAuth } from "actions/authActions";

import { VictoryChart, VictoryBar, VictoryGroup, VictoryAxis } from "victory";


import "styles/components/map-submenu.css";

class Overview extends Component {
  state = {
    period: {
      type: "historical",
      value: "mar10",
      time: "10",
      filter: {
        value: "5050",
        time: "undecided_a"
      }
    },
    month: moment().format("YYYY-MM")
  };

  handleChangePeriod = val => {
    this.setState({
      period: {
        type: val.type,
        value: val.value,
        time: val.time,
        filter: {
          value:
            val.type === "predictive" ? this.state.period.filter.value : "5050",
          time:
            val.type === "predictive"
              ? this.state.period.filter.time
              : "undecided_a"
        }
      }
    });
  };

  handleChangeFilter = val => {
    this.setState({
      period: {
        ...this.state.period,
        filter: {
          value: val.value,
          time: val.time
        }
      }
    });
  };

  handleBorderMapClick = data => {
    this.props.setModal(data);
  };

  componentDidMount() {
    // this.props.getOverview(this.state.period.time);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.period !== this.state.period) {
      if (this.state.period.type === "historical") {
        // this.props.getOverview(this.state.period.time);
      } else {
        // this.props.getOverview(
        //   this.state.period.time,
        //   this.state.period.filter.time
        // );
      }
    }
  }

  render() {
    const { period, month } = this.state;
    const {
      handleChangePeriod,
      handleBorderMapClick,
      handleChangeFilter
    } = this;
    const { map } = this.props;

    const data_paslon_1_line_1 = [
        { x: 1, y: 35.2 },
        { x: 2, y: 31.64 },
        { x: 3, y: 31.88 },
        { x: 4, y: 50.6 },
        { x: 5, y: 47.98 },
        { x: 6, y: 48.69 }
      ],
      data_paslon_1_line_2 = [{ x: 6, y: 48.69 }, { x: 7, y: 54.85 }],
      data_paslon_2_line_1 = [
        { x: 1, y: 28.82 },
        { x: 2, y: 20.12 },
        { x: 3, y: 18.98 },
        { x: 4, y: 38.5 },
        { x: 5, y: 32.2 },
        { x: 6, y: 40.53 }
      ],
      data_paslon_2_line_2 = [{ x: 6, y: 40.53 }, { x: 7, y: 45.15 }],
      dataA = [
        {
          x: "Jokowi - Maruf",
          y: map.province.total_calon_1 ? map.province.total_calon_1 : 0,
          label: `${
            map.province.total_calon_1
              ? map.province.total_calon_1.toFixed(2)
              : 0
          } %`
        },
        {
          x: "Prabowo - Sandi",
          y: map.province.total_calon_2 ? map.province.total_calon_2 : 0,
          label: `${
            map.province.total_calon_2
              ? map.province.total_calon_2.toFixed(2)
              : 0
          } %`
        },
        {
          x: "Undecided",
          y: map.province.total_undecided ? map.province.total_undecided : 0,
          label: `${
            map.province.total_undecided
              ? map.province.total_undecided.toFixed(2)
              : 0
          } %`
        }
      ];

    const paslon_1_line_1 = map.province.elektabilitas
        ? map.province.elektabilitas.paslon_1.line_1
        : data_paslon_1_line_1,
      paslon_1_line_2 = map.province.elektabilitas
        ? map.province.elektabilitas.paslon_1.line_2
        : data_paslon_1_line_2,
      paslon_2_line_1 = map.province.elektabilitas
        ? map.province.elektabilitas.paslon_2.line_1
        : data_paslon_2_line_1,
      paslon_2_line_2 = map.province.elektabilitas
        ? map.province.elektabilitas.paslon_2_line_2
        : data_paslon_2_line_2;

    const data = {
      suara_popular: {
        title: "Suara Polling",
        items: [
          {
            percentage: 25.74,
            color: "#d0021b"
          },
          {
            percentage: 17.80,
            color: "rgb(6, 0, 194)"
          },
          {
            percentage: 19.08,
            color: "rgb(219, 199, 20)"
          },
          {
            percentage: 7.71,
            color: "rgb(20, 219, 80)"
          },
          {
            percentage: 29.67,
            color: "#bbb"
          }
        ]
      }
    };

    //dummy data trendline
    let trend_candidate_1 = [
      {
        "x": 1,
        "y": 22.19
      },
      {
        "x": 2,
        "y": 20.95
      },
      {
        "x": 3,
        "y": 25.74
      }
    ];

    let trend_candidate_1_pred = [
      {
        "x": 8,
        "y": 14
      },
      {
        "x": 9,
        "y": 15
      }
    ];

    let trend_candidate_2 = [
      {
        "x": 19.54,
        "y": 16
      },
      {
        "x": 2,
        "y": 17.81
      },
      {
        "x": 3,
        "y": 17.8
      }
    ];

    let trend_candidate_2_pred = [
      {
        "x": 8,
        "y": 23
      },
      {
        "x": 9,
        "y": 25.2
      }
    ];

    let trend_candidate_3 = [
      {
        "x": 28.45,
        "y": 16
      },
      {
        "x": 2,
        "y": 27.19
      },
      {
        "x": 3,
        "y": 19.08
      }
    ];

    let trend_candidate_3_pred = [
      {
        "x": 8,
        "y": 29
      },
      {
        "x": 9,
        "y": 31
      }
    ];

    let trend_candidate_4 = [
      {
        "x": 15.29,
        "y": 14
      },
      {
        "x": 2,
        "y": 12.24
      },
      {
        "x": 3,
        "y": 7.71
      }
    ];

    let trend_candidate_4_pred = [
      {
        "x": 8,
        "y": 25
      },
      {
        "x": 9,
        "y": 22
      }
    ];

    let dataRespondenVoters = [
      {
        "x": "Prabowo Subianto",
        "y": 25.74,
        "label": "25.74 %"
      },
      {
        "x": "Ganjar Pranowo",
        "y": 17.80,
        "label": "17.80 %"
      },
      {
        "x": "Anies Baswedan",
        "y": 19.08,
        "label": "19.08 %"
      },
      // {
      //   "x": "Mahmud MD",
      //   "y": 7.71,
      //   "label": "7.71 %"
      // }
    ];

    console.log(period);

    if(period.type === "predictive" && period.time === "A") {
      trend_candidate_1_pred = [
        {
          "x": 4,
          "y": 13
        },
        {
          "x": 5,
          "y": 14.5
        }
      ];

      trend_candidate_2_pred = [
        {
          "x": 4,
          "y": 25
        },
        {
          "x": 5,
          "y": 27.1
        }
      ];

      trend_candidate_3_pred = [
        {
          "x": 4,
          "y": 16
        },
        {
          "x": 5,
          "y": 14.2
        }
      ];

      trend_candidate_4_pred = [
        {
          "x": 4,
          "y": 11
        },
        {
          "x": 5,
          "y": 10.3
        }
      ];

    } else if(period.type === "predictive" && period.time === "B") {

      trend_candidate_1_pred = [
        {
          "x": 4,
          "y": 13
        },
        {
          "x": 5,
          "y": 14.5
        },
        {
          "x": 6,
          "y": 15.3
        }
      ];

      trend_candidate_2_pred = [
        {
          "x": 4,
          "y": 25
        },
        {
          "x": 5,
          "y": 27.1
        },
        {
          "x": 6,
          "y": 24.2
        }
      ];

      trend_candidate_3_pred = [
        {
          "x": 4,
          "y": 16
        },
        {
          "x": 5,
          "y": 14.2
        },
        {
          "x": 6,
          "y": 15.1
        }
      ];

      trend_candidate_4_pred = [
        {
          "x": 4,
          "y": 11
        },
        {
          "x": 5,
          "y": 10.3
        },
        {
          "x": 6,
          "y": 9
        }
      ];

    } else if(period.type === "predictive" && period.time === "C") {
      trend_candidate_1_pred = [
        {
          "x": 4,
          "y": 13
        },
        {
          "x": 5,
          "y": 14.5
        },
        {
          "x": 6,
          "y": 16.1
        },
        {
          "x": 7,
          "y": 15.7
        }
      ];

      trend_candidate_2_pred = [
        {
          "x": 4,
          "y": 25
        },
        {
          "x": 5,
          "y": 27.1
        },
        {
          "x": 6,
          "y": 26.2
        },
        {
          "x": 7,
          "y": 25.2
        }
      ];

      trend_candidate_3_pred = [
        {
          "x": 4,
          "y": 16
        },
        {
          "x": 5,
          "y": 14.2
        },
        {
          "x": 6,
          "y": 15.1
        },
        {
          "x": 7,
          "y": 14.1
        }
      ];

      trend_candidate_4_pred = [
        {
          "x": 4,
          "y": 11
        },
        {
          "x": 5,
          "y": 10.3
        },
        {
          "x": 6,
          "y": 9
        },
        {
          "x": 7,
          "y": 8.5
        }
      ];
    }

    let data_shifting_1 = [
      {
        "x": 50,
        "y": 51,
        "label": "[2019 Voters]: Jokowi",
        "id": "32"
      },
      {
        "x": 50,
        "y": 45,
        "label": "[2019 Voters]: Prabowo",
        "id": "33"
      },
      {
        "x": 50,
        "y": 48,
        "label": "[2019 Voters]: Not Vote",
        "id": "35"
      },
      {
        "x": 50,
        "y": 39,
        "label": "[Age]: 60+",
        "id": "36"
      },
      {
        "x": 50,
        "y": 50,
        "label": "[Age]: 50-59",
        "id": "12"
      },
      {
        "x": 50,
        "y": 45,
        "label": "[Age]: 40-49",
        "id": "31"
      },
      {
        "x": 50,
        "y": 55,
        "label": "[Age]: 30-39",
        "id": "61"
      },
      {
        "x": 50,
        "y": 52,
        "label": "[Age]: 23-29",
        "id": "18"
      },
      {
        "x": 50,
        "y": 53,
        "label": "[Age]: < 23",
        "id": "14"
      },
      {
        "x": 50,
        "y": 46,
        "label": "[Island] Other",
        "id": "91"
      },
      {
        "x": 50,
        "y": 60,
        "label": "[Island] Kalimantan",
        "id": "64"
      },
      {
        "x": 50,
        "y": 58,
        "label": "[Island] Sulawesi",
        "id": "64"
      },
      {
        "x": 50,
        "y": 53,
        "label": "[Island] Sumatra",
        "id": "53"
      },
      {
        "x": 50,
        "y": 50,
        "label": "[Prov] Jakarta",
        "id": "51"
      },
      {
        "x": 50,
        "y": 29,
        "label": "[Prov] Banten",
        "id": "11"
      },
      {
        "x": 50,
        "y": 54,
        "label": "[Prov] Sumut",
        "id": "81"
      },
      {
        "x": 50,
        "y": 30,
        "label": "[Prov] Jateng",
        "id": "62"
      },
      {
        "x": 50,
        "y": 48,
        "label": "[Prov] Jatim",
        "id": "74"
      },
      {
        "x": 50,
        "y": 52,
        "label": "[Prov] Jabar",
        "id": "15"
      },
      {
        "x": 50,
        "y": 49,
        "label": "[Religion] Christian",
        "id": "19"
      },
      {
        "x": 50,
        "y": 44,
        "label": "[Religion] Muslin",
        "id": "76"
      },
      {
        "x": 50,
        "y": 47,
        "label": "[Rural]",
        "id": "63"
      },
      {
        "x": 50,
        "y": 49,
        "label": "[Urban]",
        "id": "75"
      },
      {
        "x": 50,
        "y": 50,
        "label": "[Gender] Women",
        "id": "13"
      },
      {
        "x": 50,
        "y": 51,
        "label": "[Gender] Men",
        "id": "71"
      }
    ];

    // console.log("SHIFTING.....");
    // console.log(JSON.stringify(dataA));
    // console.log(JSON.stringify(map.province.shifting));

    return (
      <React.Fragment>
        <div className="full-width year-pointer">
          <hr className="strike-through" />
          <div className="election-year-container">
            <ul>
              <li>
                <i className="zmdi zmdi-circle" /> Aug 2023
              </li>
              <li>
                <i className="zmdi zmdi-circle" /> Sept 2023
              </li>
              <li>
                <i className="zmdi zmdi-circle" /> Oct 2023
              </li>
              <li className={"active"}>
                <i className="zmdi zmdi-circle" /> Nov 2023
              </li>
            </ul>
          </div>
        </div>
        <div className="full-width dark-bg overview-legend">
          <section className="page-content container-fluid">
            <div className="row">
              <div className="col-lg-4">
                <div className="candidate-info-header left">
                  <img
                    src={profile1}
                    className="rounded-circle"
                    alt="Candidate Name"
                  />
                  <div className="candidate-name">
                    <span>Prabowo Subianto</span>
                    <h2>
                      {/* {map.province.total_calon_1
                        ? map.province.total_calon_1.toFixed(2)
                        : 0} */}
                      33.57%
                    </h2>
                  </div>
                  <div style={{padding: '5px', fontWeight: 'bold'}}>
                    <ArrowUp color="#42f5d7" size={24} />
                    {/* <ArrowDown color="#ff462e" size={24} /> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-2">
                <div className="candidate-info-header right right1">
                  <div className="candidate-name">
                    <span>Sandiaga Uno</span>
                    <h2>
                      17.80%
                    </h2>
                  </div>
                  <img
                    src={profile2}
                    className="rounded-circle"
                    alt="Candidate Name"
                  />
                  <div style={{padding: '5px', fontWeight: 'bold'}}>
                    <ArrowDown color="#ff462e" size={24} />
                </div>
                </div>
              </div> */}
              <div className="col-lg-4">
                <h4 className="overview-header">Vice President Candidate</h4>
              </div>
              <div className="col-lg-2">
                <div className="candidate-info-header left left2">
                <div style={{padding: '5px', fontWeight: 'bold'}}>
                    <ArrowUp color="#42f5d7" size={24} />
                    {/* <ArrowDown color="#ff462e" size={24} /> */}
                  </div>
                  <img
                    src={profile3}
                    className="rounded-circle"
                    alt="Candidate Name"
                  />
                  <div className="candidate-name">
                    <span>Ganjar Pranowo</span>
                    <h2>
                      {/* {map.province.total_calon_1
                        ? map.province.total_calon_1.toFixed(2)
                        : 0} */}
                      31.08%
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="candidate-info-header right right2">
                <div style={{padding: '5px', fontWeight: 'bold'}}>
                    <ArrowDown color="#ff462e" size={24} />
                    {/* <ArrowUp color="#42f5d7" size={24} /> */}
                  </div>
                  <div className="candidate-name">
                    <span>Anies Baswedan</span>
                    <h2>
                      {/* {map.province.total_calon_2
                        ? map.province.total_calon_2.toFixed(2)
                        : 0} */}
                      29.71%
                    </h2>
                  </div>
                  <img
                    src={profile4}
                    className="rounded-circle"
                    alt="Candidate Name"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="container-fluid overview-content">
          <div className="overview-right">
            <div className="row" style={{ marginBottom: 15 + "px" }}>
              <div className="col-lg-8">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    value="historical"
                    disabled
                    className={`btn btn-secondary btn-sm ${
                      period.type === "historical" ? "active" : ""
                    }`}
                  >
                    Historical
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        type: "historical",
                        value: "mar7",
                        time: "7"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "mar7" ? "active" : ""
                    }`}
                  >
                    Aug (2023)
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        type: "historical",
                        value: "mar8",
                        time: "8"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "mar8" ? "active" : ""
                    }`}
                  >
                    Sept (2023)
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        type: "historical",
                        value: "mar9",
                        time: "9"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "mar9" ? "active" : ""
                    }`}
                  >
                    Late Oct (2023)
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        type: "historical",
                        value: "mar10",
                        time: "10"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "mar10" ? "active" : ""
                    }`}
                  >
                    Nov (2023)
                  </button>
                </div>
              </div>
              {/* <div className="col-lg-4 text-right">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        type: "predictive",
                        value: "tomorrow",
                        time: "A"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "tomorrow" ? "active" : ""
                    }`}
                  >
                    Next Month
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        type: "predictive",
                        value: "nextWeek",
                        time: "B"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "nextWeek" ? "active" : ""
                    }`}
                  >
                    Next 2 Months
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        type: "predictive",
                        value: "nextMonth",
                        time: "C"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "nextMonth" ? "active" : ""
                    }`}
                  >
                    Next 3 Months
                  </button>
                  <button
                    type="button"
                    value="predictive"
                    disabled
                    className={`btn btn-secondary btn-sm ${
                      period.type === "predictive" ? "active" : ""
                    }`}
                  >
                    Predictive
                  </button>
                </div>
              </div> */}
            </div>
            {/* {period.type === "predictive" && (
              <div className="row">
                <div className="col-md-6" />
                <div className="col-md-6 text-right">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      onClick={e =>
                        handleChangeFilter({
                          value: "5050",
                          time: "undecided_a"
                        })
                      }
                      className={`btn btn-secondary btn-sm ${
                        period.filter.value === "5050" ? "active" : ""
                      }`}
                    >
                      50 : 50
                    </button>
                    <button
                      type="button"
                      onClick={e =>
                        handleChangeFilter({
                          value: "7030",
                          time: "undecided_b"
                        })
                      }
                      className={`btn btn-secondary btn-sm ${
                        period.filter.value === "7030" ? "active" : ""
                      }`}
                    >
                      30 : 70
                    </button>
                    <button
                      type="button"
                      onClick={e =>
                        handleChangeFilter({
                          value: "2080",
                          time: "undecided_c"
                        })
                      }
                      className={`btn btn-secondary btn-sm ${
                        period.filter.value === "2080" ? "active" : ""
                      }`}
                    >
                      20 : 80
                    </button>
                    <button
                      type="button"
                      onClick={e =>
                        handleChangeFilter({
                          value: "4060",
                          time: "undecided_d"
                        })
                      }
                      className={`btn btn-secondary btn-sm ${
                        period.filter.value === "4060" ? "active" : ""
                      }`}
                    >
                      40 : 60
                    </button>
                  </div>
                </div>
              </div>
            )} */}
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="text-center">Trend Elektabilitas</h4>
                  </div>
                  <div className="col-lg-12">
                    <h5 className="text-center" style={{textDecoration: 'underline', fontWeight: 'bold'}}>Prabowo Subianto</h5>
                    <LineDot
                      color={{
                        color1: "rgb(196, 58, 49)",
                        color2:
                          period.type === "historical"
                            ? "rgb(196, 58, 49)"
                            : "rgba(196, 58, 49, 0.3)"
                      }}
                      data={[trend_candidate_1, trend_candidate_1_pred]}
                      type={period.type}
                    />
                    
                  </div>
                  
                  {/* <div className="col-lg-6">
                    <h5 className="text-center" style={{textDecoration: 'underline', fontWeight: 'bold'}}>Sandiaga Uno</h5>
                    <LineDot
                      color={{
                        color1: "rgb(6, 0, 194)",
                        color2:
                          period.type === "historical"
                            ? "rgb(6, 0, 194)"
                            : "rgba(6, 0, 194, 0.3)"
                      }}
                      data={[trend_candidate_2, trend_candidate_2_pred]}
                      type={period.type}
                    />
                  </div> */}
                  <div className="col-lg-6">
                    <h5 className="text-center" style={{textDecoration: 'underline', fontWeight: 'bold'}}>Ganjar Pranowo</h5>
                    <LineDot
                      color={{
                        color1: "rgb(219, 199, 20)",
                        color2:
                          period.type === "historical"
                            ? "rgb(219, 199, 20)"
                            : "rgba(219, 199, 20, 0.3)"
                      }}
                      data={[trend_candidate_2, trend_candidate_2_pred]}
                      type={period.type}
                    />
                  </div>
                  <div className="col-lg-6">
                    <h5 className="text-center" style={{textDecoration: 'underline', fontWeight: 'bold'}}>Anies Baswedan</h5>
                    <LineDot
                      color={{
                        color1: "rgb(20, 219, 80)",
                        color2:
                          period.type === "historical"
                            ? "rgb(20, 219, 80)"
                            : "rgba(20, 219, 80, 0.3)"
                      }}
                      data={[trend_candidate_3, trend_candidate_3_pred]}
                      type={period.type}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <Horizontal100
                    data={data.suara_popular}
                    withpercent={false}
                  />
                </div>
                {/* <div className="col-lg-12">
                  <BorderMap
                    data={map.province.legends}
                    summary={map.province.summary}
                    position={"topRight"}
                    handleBorderMapClick={handleBorderMapClick}
                  />
                </div> */}
                {/* <div className="row"> */}
                  <div className="col-lg-12">
                  <h4 className="text-center">If the election for president were held tomorrow, would you vote for:</h4>
                    <VictoryChart
                      height={300}
                      width={1366}
                      domainPadding={100}
                      maxDomain={{ y: 50 }}
                    >
                      <VictoryBar
                        data={dataRespondenVoters}
                        style={{
                          data: {
                            fill: d =>
                              d.x === "Prabowo Subianto"
                                ? "#d0021b"
                                : d.x === "Ganjar Pranowo"
                                ? "rgb(6, 0, 194)"
                                : d.x === 'Anies Baswedan'
                                ? "#edbc34" : "#14db50",
                            width: 150
                          }
                        }}
                      />
                      <VictoryAxis
                        style={{
                          axis: { stroke: "none" }
                        }}
                      />
                    </VictoryChart>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-red table-bordered" style={{fontSize: '0.75rem'}}>
                        <thead>
                          <tr>
                            <th>M</th>
                            <th>F</th>
                            <th>Rural</th>
                            <th>Urban</th>
                            <th>17-22</th>
                            <th>23-29</th>
                            <th>30-39</th>
                            <th>40-49</th>
                            <th>50-59</th>
                            <th>60+</th>
                            <th>Muslim</th>
                            <th>Christian</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>15/23/62</td>
                            <td>16/27/57</td>
                            <td>16/26/58</td>
                            <td>15/23/62</td>
                            <td>18/24/58</td>
                            <td>16/24/60</td>
                            <td>20/23/57</td>
                            <td>11/28/61</td>
                            <td>17/29/54</td>
                            <td>10/17/73</td>
                            <td>14/24/62</td>
                            <td>21/25/54</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> */}
            {/* </div> */}
              </div>
              <div className="col-lg-4" style={{paddingLeft: '80px', paddingTop: '10px'}}>
                {/* <h4 className="text-center" style={{marginBottom: '-15px'}}>Shift Chart Electability ET among Voters?</h4> */}
                <h4 className="text-center" style={{marginBottom: '-15px'}}>PS Crosstabs Shift vs Past Survey</h4>
                <ShiftingChart data={data_shifting_1}/>
              </div>
            </div>
            
          
          </div>
        </section>
        {/* <ModalOverview /> */}

        <div className="map50-bottom-soe">
          <MapBottom source="turnout" type="electability" handleBorderMapClick={()=>{}} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  map: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  // getOverview,
  setModal,


  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview);
