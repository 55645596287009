import React from "react";

const SubmenuRegion = props => (
  <div className={`map-submenu ${props.data.openMap ? "sub-open" : ""}`}>
    <ul className="submenu-map map-select">
      {props.data.region.step > 1 &&
        props.data.region.provinsi.data.length > 0 && (
          <li>
            <a
              href="#"
              className="dropdown-toggle"
              onClick={e => {
                e.preventDefault();
                props.handleToggleSubMenu(2);
              }}
            >
              {props.data.region.provinsi.value !== null
                ? props.data.region.provinsi.value
                : "Provinsi"}
            </a>
            <ul
              className={`submenu-children ${
                props.data.isSubOpen === 2 ? "sub-child-open" : ""
              }`}
            >
              {props.data.region.provinsi.data.length > 0 &&
                props.data.region.provinsi.data.map((data, key) => (
                  <li key={key}>
                    <a
                      href="#"
                      className={
                        props.data.info.location === data.name ? "active" : ""
                      }
                      onClick={e => {
                        e.preventDefault();
                        props.handleGetSubMenu(
                          `pulau/${data.id}`,
                          "kabupaten",
                          data.name,
                          "provinsi",
                          3,
                          data.id
                        );
                        props.data.handleSubMenuChange(data)
                        props.handleCloseSubmenu();
                      }}
                    >
                      {data.name}
                    </a>
                  </li>
                ))}
            </ul>
          </li>
        )}
      <li>
        <a
          href="#"
          className="dropdown-toggle"
          onClick={e => {
            e.preventDefault();
            props.handleToggleSubMenu(1);
          }}
        >
          {props.data.region.pulau.value !== null
            ? props.data.region.pulau.value
            : "Pulau"}
        </a>
        <ul
          className={`submenu-children ${
            props.data.isSubOpen === 1 ? "sub-child-open" : ""
          }`}
        >
          {props.data.region.pulau.data.length > 0 &&
            props.data.region.pulau.data.map((data, key) => (
              <li key={key}>
                <a
                  href="#"
                  className={
                    props.data.info.location === data.name ? "active" : ""
                  }
                  onClick={e => {
                    e.preventDefault();
                    props.handleGetSubMenu(
                      data.id,
                      "provinsi",
                      data.name,
                      "pulau",
                      2
                    );
                    props.handleCloseSubmenu();
                  }}
                >
                  {data.name}
                </a>
              </li>
            ))}
        </ul>
      </li>

      {/* {props.data.region.step > 2 &&
        props.data.region.kabupaten.data.length > 0 && (
          <li>
            <a
              href="#"
              className="dropdown-toggle"
              onClick={e => {
                e.preventDefault();
                props.data.handleToggleSubMenu(3);
              }}
            >
              {props.data.region.kabupaten.value !== null
                ? props.data.region.kabupaten.value
                : "Kabupaten / Kota"}
            </a>
            <ul
              className={`submenu-children ${
                props.data.isSubOpen === 3 ? "sub-child-open" : ""
              }`}
            >
              {props.data.region.kabupaten.data.length > 0 &&
                props.data.region.kabupaten.data.map((data, key) => (
                  <li key={key}>
                    <a
                      href="#"
                      className={
                        props.data.info.location === data.name ? "active" : ""
                      }
                      onClick={e => {
                        e.preventDefault();
                        props.handleGetSubMenu(
                          `pulau/provinsi/${data.id}`,
                          "kecamatan",
                          data.name,
                          "kabupaten",
                          4
                        );
                      }}
                    >
                      {data.name}
                    </a>
                  </li>
                ))}
            </ul>
          </li>
        )} */}
      {/* {props.data.region.step > 3 &&
        props.data.region.kecamatan.data.length > 0 && (
          <li>
            <a
              href="#"
              className="dropdown-toggle"
              onClick={e => {
                e.preventDefault();
                props.data.handleToggleSubMenu(4);
              }}
            >
              {props.data.region.kecamatan.value !== null
                ? props.data.region.kecamatan.value
                : "Kecamatan / Desa"}
            </a>
            <ul
              className={`submenu-children ${
                props.data.isSubOpen === 4 ? "sub-child-open" : ""
              }`}
            >
              {props.data.region.kecamatan.data.length > 0 &&
                props.data.region.kecamatan.data.map((data, key) => (
                  <li key={key}>
                    <a
                      href="#"
                      className={
                        props.data.info.location === data.name ? "active" : ""
                      }
                      onClick={e => {
                        e.preventDefault();
                        props.handleGetSubMenu(
                          `pulau/provinsi/${data.id}`,
                          null,
                          data.name,
                          "kecamatan",
                          5
                        );
                      }}
                    >
                      {data.name}
                    </a>
                  </li>
                ))}
            </ul>
          </li>
        )} */}
    </ul>
  </div>
);

export default SubmenuRegion;
