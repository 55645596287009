const initState = {
  location: null,
  source: {
    info: "DEMOGRAPHY"
  },
  gender: {
    total_male: 0,
    total_female: 0,
    show: false,
    info: "all"
  },
  agama: {
    value: [],
    show: false,
    info: "all"
  },
  age: {
    value: [],
    show: false,
    info: "all"
  },
  pendidikan: {
    value: [],
    show: false,
    info: "all"
  },
  ses: {
    value: [],
    show: false,
    info: "all"
  },
  poi: {
    type: null,
    res: [],
    isPoi: false,
    summary: {
      business: [],
      products: [],
      lifestyle: [],
      health: [],
      travel: []
    }
  },
  subpoi: {
    info: null
  },
  province: {
    total_dpt: 0,
    legend: [],
    map: []
  },
  demography: {
    gender: {
      total_male: 0,
      total_female: 0,
      total_dpt: 0,
      percent_dpt: 0
    },
    kota_kab: [],
    age_details: {
      max_value: 0,
      data: []
    },
    kesejahteraan: {
      max_value: 0,
      data: []
    },
    pendidikan: {
      max_value: 0,
      data: []
    },
    isLoading: false
  },
  turnout: {},
  ishoverKab: "",
  zoom: 5,
  position: { lat: -1.197, lng: 117.644 }
};

export default function mapReducer(state = initState, action) {
  switch (action.type) {
    case "SET_INFO":
      return {
        ...state,
        [action.payload.type]: {
          value: action.payload.value,
          show: true,
          info: action.payload.info
        }
      };
    case "CLEAR_INFO":
      return {
        ...state,
        [action.payload]: { ...action.payload, show: false, info: null }
      };
    case "SET_POI":
      return {
        ...state,
        poi: { ...action.payload, summary: action.payload.res.summary }
      };
    case "CLEAR_POI":
      return {
        ...state,
        poi: {
          type: null,
          res: [],
          isPoi: false
        }
      };
    case "SET_MEDIA_TRENDING":
      return {
        ...state,
        province: action.payload
      };
    case "SET_PROVINCE":
      return {
        ...state,
        province: action.payload
      };
    case "SET_TURNOUT":
      return {
        ...state,
        turnout: action.payload
      };
    case "CLEAR_PROVINCE":
      return {
        ...state,
        province: null
      };
    case "SET_DEMOGRAPHY":
      return {
        ...state,
        demography: action.payload
      };
    case "CLEAR_DEMOGRAPHY":
      return {
        ...state,
        demography: {
          gender: {
            total_male: 0,
            total_female: 0,
            total_dpt: 0,
            percent_dpt: 0
          },
          kota_kab: [],
          age_details: {
            max_value: 0,
            data: []
          },
          kesejahteraan: {
            max_value: 0,
            data: []
          },
          pendidikan: {
            max_value: 0,
            data: []
          }
        }
      };
    case "SET_LEGEND":
      return {
        ...state,
        province: {
          ...state.province,
          legend: action.payload
        }
      };
    case "SET_HOVER_KAB":
      return {
        ...state,
        ishoverKab: action.payload
      };
    case "CLEAR_HOVER_KAB":
      return {
        ...state,
        ishoverKab: ""
      };
    case "SET_POSITION_MAP":
      return {
        ...state,
        position: { lat: action.payload.lat, lng: action.payload.lng },
        zoom: action.payload.zoom
      };
    case "CLEAR_POSITION_MAP":
      return {
        ...state,
        position: { lat: -1.197, lng: 117.644 },
        zoom: 5
      };

    case "SET_ZOOM_MAP":
      return {
        ...state,
        zoom: action.payload
      };
    default:
      return state;
  }
}
