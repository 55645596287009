import React, { Fragment, Component } from "react";

import Sidebar from "components/layout/sidebar/Sidebar";
import Map from "components/shared/map/Map";
import ModalDialog from "components/shared/ModalDialog";
import { Dark } from "components/shared/charts/themeVictory";
import SubmenuRegion from "components/layout/sidebar/SubmenuRegion";
import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";
import CustomLabel from "components/shared/charts/CustomLabel";
import GroupedBarTurnout from "components/shared/charts/GroupedBarTurnout";

import MapBottom from "components/shared/map/MapBottom";

import { VictoryChart, VictoryBar, VictoryStack, VictoryGroup, VictoryAxis, VictoryLegend, VictoryPie, VictoryTooltip } from "victory";
import CustomLabelOverview from "components/shared/charts/CustomLabelOverview";
import PieChartOverview from "components/shared/charts/PieChartOverview";

import { PieChart, Pie, Legend, Tooltip, Cell } from "recharts";
import PieRechart from "components/shared/charts/PieRechart";

import HorizontalSupporterDark from "components/shared/charts/HorizontalSupporterDark";

import { getTurnout } from "actions/mapActions";
import {
  horserace,
  reelect,
  undecided_a,
  undecided_b,
  turnout_binary,
  turnout_continuous
} from "components/turnoutscore/turnoutDummy";

import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import "styles/components/map-submenu.css";
import MapTurnout from "components/shared/map/MapTurnout";


const API_HOST = process.env.REACT_APP_API_HOST;

const data_pie = {
  max_value : 100,
  total_value : 100,
  data: [{
    order_id : 1,
    order_name: "Don't Know",
    total_data : 5
  },{
    order_id : 2,
    order_name : "I have lost my job or lost income due to the coronavirus public health and economic crisis",
    total_data : 18
  },{
    order_id : 3,
    order_name : "Someone else in my household or immediate family has lost their job or lost significant income due to the coronavirus public health and economic crisis",
    total_data : 45
  },{
    order_id : 4,
    order_name : "Neither I nor anyone in my household or immediate family has lost their job or lost significant income due to the coronavirus public health and economic crisis",
    total_data : 32
  }]
}

const data_pie_1_raw = [
  { x: 1, y: 5, label: "Don't Know" },
  { x: 2, y: 18, label: "I have lost my job or lost income" },
  { x: 3, y: 45, label: "Someone in family has lost their job" },
  { x: 2, y: 32, label: "All in family has lost their job" }
]

//survey 2
const data_pie_2_eco = {
  max_value : 100,
  total_value : 100,
  data: [{
    order_id : 1,
    order_name: "Already is",
    total_data : 8
  },{
    order_id : 2,
    order_name : "1-5 months",
    total_data : 11
  },{
    order_id : 3,
    order_name : "6-12 months",
    total_data : 21
  },{
    order_id : 4,
    order_name : "more than 12 months",
    total_data : 43
  },{
    order_id : 5,
    order_name : "Don't Know",
    total_data : 18
  }]
}

const data01 = [
  { name: "Yes", value: 42 },
  { name: "No", value: 38 },
  { name: "Don't Know", value: 19 }
];

const data11 = [
  { name: "State-owned enterprises should be expanded", value: 65 },
  { name: "There should be fewer state-owned enterprises", value: 8 },
  { name: "Don't Know", value: 17 },
  { name: "State-owned enterprises should not be expanded or reduced ", value: 11 },
];

const data21 = [
  { name: "Very important", value: 62 },
  { name: "Somewhat important", value: 22 },
  { name: "Not very or not at all important", value: 3 },
  { name: "Don’t know", value: 13 },
];

const data22 = [
  { name: "Major problem", value: 87 },
  { name: "Minor problem", value: 6 },
  { name: "Not a problem at all", value: 2 },
  { name: "Don't Know ", value: 5 },
];

const COLORS = [
  "#0088FE",
  "#ff2b2b",
  "#bdbdbd",
  "#ed80a1",
  "#f123ff",
  "#a1bb23"
];

const data_pie_2_raw = [
  { x: 1, y: 8, label: "Already is" },
  { x: 2, y: 11, label: "1-5 months" },
  { x: 3, y: 21, label: "6-12 months" },
  { x: 2, y: 43, label: "more than 12 months" },
  { x: 3, y: 18, label: "Don't Know" }
]

class SOE extends Component {
  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "Survey 5 (jan 2022)",
        map_type: "horserace",
        data: [
          { id: 1, name: "Survey 5 (jan 2022)" },
          { id: 2, name: "Survey 4 (jul 2021)" },
          { id: 3, name: "Survey 3 (jun 2021)" },
          { id: 4, name: "Survey 2 (mar 2021)" },
          { id: 5, name: "Survey 1 (nov 2020)" },
          // { id: 3, name: "undecided_a" },
          // { id: 4, name: "undecided_b" },
          // { id: 5, name: "turnout_binary" },
          // { id: 6, name: "turnout_continuous" }
        ]
      }
    },
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "Survey 5 (jan 2022)" },
            { id: 2, name: "Survey 4 (jul 2021)" },
            { id: 3, name: "Survey 3 (jun 2021)" },
            { id: 4, name: "Survey 2 (mar 2021)" },
            { id: 5, name: "Survey 1 (nov 2020)" },
            // { id: 3, name: "undecided_a" },
            // { id: 4, name: "undecided_b" },
            // { id: 5, name: "turnout_binary" },
            // { id: 6, name: "turnout_continuous" }
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
          // err.response.status === 401
          //   ? this.props.reAuth()
          //   : toastr.error(err.response.statusText);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
    // await GET(API_HOST + "/sental/master/pulau")
    //   .then(res =>
    //     this.setState({
    //       ...this.state,
    //       region: {
    //         ...this.state.region,
    //         pulau: { ...this.state.region.pulau, data: res.data },
    //         step: 1
    //       }
    //     })
    //   )
    //   .catch(err => {
    //     err.response.status === 401
    //       ? this.props.reAuth()
    //       : toastr.error(err.response.statusText);
    //   });
  }

  render() {
    const { info, state, handleToggleSatellite } = this.props;
    const {
      openMap,
      isSubOpen,
      openPOI,
      isPOISubOpen,
      region,
      turnout
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition,
      handleGetSubMenu,
      handleChangeMethod
    } = this;

    let dataSource;
    let survey_2_data = {
      max_value: 100,
      total_value: 100,
      data: [
        {
          order_id: 1,
          order_name: "Total Try",
          left_label: "Probably Try",
          right_label: "Definitely Try",
          left_data: 33,
          right_data: 29
        },
        {
          order_id: 2,
          order_name: "Total Not Try",
          left_label: "Probably Not Try",
          right_label: "Definitely Not Try",
          left_data: 9,
          right_data: 7
        },
        {
          order_id: 3,
          order_name: "Total Unsure",
          left_label: "Don't Know",
          right_label: "Might or might not",
          left_data: 6,
          right_data: 16
        },
      ]
  }

  let turnout_confidence_get_vaccinated = {
    max_value: 100,
    total_value: 100,
    data: [
      {
        order_id: 1,
        order_name: "Total confident",
        left_label: "Somewhat confident",
        right_label: "Very confident",
        left_data: 54,
        right_data: 7
      },
      {
        order_id: 2,
        order_name: "Total Not Confident",
        left_label: "Not very confident",
        right_label: "Not at all confident",
        left_data: 31,
        right_data: 2
      },
      {
        order_id: 3,
        order_name: "Total Unsure",
        left_label: "Don't Know",
        right_label: "Don't Know",
        left_data: 3,
        right_data: 3
      },
    ]
}

let horizontalChartData = {
  soe:[
  {
    data: [
      { percentage: 83, color: "rgba(2, 159, 176, .8)" },
      { percentage: 13, color: "rgba(60, 210, 240, .8)" },
      { percentage: 2, color: "rgba(240, 66, 60, .8)" },
      { percentage: 0, color: "rgba(148, 5, 0, .8)" },
      { percentage: 2, color: "rgba(184, 180, 180, .8)" }
    ],
    type: "job_approval",
    title: "Small businesses"
  },
  {
    data: [
      { percentage: 80, color: "rgba(2, 159, 176, .8)" },
      { percentage: 14, color: "rgba(60, 210, 240, .8)" },
      { percentage: 2, color: "rgba(240, 66, 60, .8)" },
      { percentage: 1, color: "rgba(148, 5, 0, .8)" },
      { percentage: 2, color: "rgba(184, 180, 180, .8)" }
    ],
    type: "job_approval",
    title: "State-owned enterprises"
  },
  {
    data: [
      { percentage: 59, color: "rgba(2, 159, 176, .8)" },
      { percentage: 30, color: "rgba(60, 210, 240, .8)" },
      { percentage: 7, color: "rgba(240, 66, 60, .8)" },
      { percentage: 1, color: "rgba(148, 5, 0, .8)" },
      { percentage: 3, color: "rgba(184, 180, 180, .8)" }
    ],
    type: "job_approval",
    title: "Large businesses"
  }]
}

let bar_chart;

const RADIAN = Math.PI / 180;
const labelPie01 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const labelPie11 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const labelPie21 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const labelPie22 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text style={{width:'20px !important'}} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${data22[index].name}: ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

let colors01 =  [
  "#0088FE",
  "#ff2b2b",
  "#bdbdbd"
];

let colors11 =  [
  "#0088FE",
  "#ff2b2b",
  "#a39d9d",
  "#e8e8e8"
];

let colors21 =  [
  "#352afa",
  "#0088FE",
  "#bdbdbd",
  "#e8e8e8"
];

let colors22 =  [
  "#ff2b2b",
  "#bdbdbd",
  "#d9d7fa",
  "#e8e8e8"
];

let pie_chart_top_01 = <PieRechart data={data01} colors={colors01}/>;
let pie_chart_top_11 = <PieRechart data={data11} colors={colors11}/>;
let pie_chart_bottom_21 = <PieRechart data={data21} colors={colors21}/>;
let pie_chart_bottom_22 = <PieRechart data={data22} colors={colors22}/>

bar_chart = 
  <VictoryChart>
    <VictoryStack
      colorScale={["#003996","#07aced","#ff9b0f","red","#9e9d9b"]}
    >
        <VictoryBar
          data = {[
            {x: "Wave 1", y:9, label:'Strongly Approved'},
            {x: "Wave 2", y:47, label:'Strongly Approved'},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        <VictoryBar
          data = {[
            {x: "Wave 1", y:65, label:'Somewhat Approved'},
            {x: "Wave 2", y:35, label:'Somewhat Approved'},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        <VictoryBar
          data = {[
            {x: "Wave 1", y:12, label:'Somewhat Disapproved'},
            {x: "Wave 2", y:6, label:'Somewhat Disapproved'},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        <VictoryBar
          data = {[
            {x: "Wave 1", y:1, label:'Strongly Disapproved'},
            {x: "Wave 2", y:2, label:'Strongly Disapproved'},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        <VictoryBar
          data = {[
            {x: "Wave 1", y:14, label:"don't know"},
            {x: "Wave 2", y:10, label:"don't know"},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        {/* <VictoryBar
          data={[
            { x: "Strongly Approved", y: 57, label: '57%' },
            { x: "Somewhat Approved", y: 26, label: '26%' },
            { x: "Somewhat Disapproved", y: 5, label: '5%' },
            { x: "Strongly Disapproved", y: 2, label: '2%' },
            { x: "don't know", y: 2, label: '2%' }
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
          // labels={["COVID-19", "Economic", "Health care", "Education", "Corruption" ]}
        /> */}
    </VictoryStack>
    <VictoryAxis
      style={{
        axis: { stroke: "transparent" },
        ticks: { stroke: "transparent" },
        axisLabel: { fill: "white" },
        tickLabels: { fill: "white" }
      }}
    />

    <VictoryLegend x={150} y={20}
      orientation="horizontal"
      gutter={20}
      style={{ 
        border: { stroke: "white" },
        labels: { fill: "white" }, 
      }}
      colorScale={["#0394fc","#fc034e"]}
      data={[
        { name: "Nov 2020" }, { name: "Mar 2021" }
      ]}
    />
  </VictoryChart>

let title_chart11 = <Fragment>
  Some people think that state-owned enterprises should be expanded to create more jobs and make the public sector a bigger part of the Indonesian economy. Other people think there should be fewer state- owned enterprises so that the private sector grows more. Which is closer to your view?
</Fragment>

let title_chart21 = <Fragment>
  How important do you think it is to make state-owned enterprises run more efficiently and more effectively with better management practices and smaller executive teams?
</Fragment>

let title_chart22 = <Fragment>
  Is corruption at state-owned enterprises (SOEs) in Indonesia a major problem, a minor problem, or not a problem at all?
</Fragment>

let data01_v3 = [
  { name: "Yes", value: 40 },
  { name: "No", value: 38 },
  { name: "Don't Know", value: 23 }
];
let data11_v3 = [
  { name: "Yes", value: 49 },
  { name: "No", value: 32 },
  { name: "Don't Know", value: 19 }
];
let data21_v3 = [
  { name: "Much more Favorable", value: 37 },
  { name: "Somewhat more favorable", value: 41 },
  { name: "Somewhat less favorable", value: 2 },
  { name: "Much less favorable", value: 1 },
  { name: "Don't Know", value: 19 }
];
let data22_v3 = [
  { name: "Yes", value: 35 },
  { name: "No", value: 40 },
  { name: "Don't Know", value: 22 }
];

colors21 =  [
  "#352afa",
  "#0088FE",
  "#ed0716",
  "#80030b"
];

let left_bottom_component = <Fragment>
  <div className="col-lg-6" style={{ height: "100%", marginTop: "80px", marginBottom: "20px", paddingRight: '50px' }}>  
    <div className="col-lg-12" style={{marginBottom: '20px'}}>
      <div className="row">
          <p style={{display: 'flex', fontSize:'0.9em',  justifyContent:'center', alignItems:'center', color:'white', padding: '10px', marginTop: '30px', marginBottom:'30px'}}>
          How important are each of the following to getting the Indonesian economy back on the right track?
          </p>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="scoring-legend">
            <span style={{color: 'white', fontSize: '0.6em'}}>
              <span className="legend-box soe-a" /> Very important
            </span>
            <span style={{color: 'white', fontSize: '0.6em'}}>
              <span className="legend-box soe-b" /> Somewhat important
            </span>
            <span style={{color: 'white', fontSize: '0.6em'}}> 
              <span className="legend-box soe-c" /> Not very important
            </span>
            <span style={{color: 'white', fontSize: '0.6em'}}>
              <span className="legend-box soe-b" /> Not at all important
            </span>
            <span style={{color: 'white', fontSize: '0.6em'}}>
              <span className="legend-box soe-c" /> Don't know
            </span>
          </div>
        </div>
      </div>
    </div>
    <HorizontalSupporterDark data={horizontalChartData.soe} />
  </div>
</Fragment>

switch (turnout.method.value) {
  case "Survey 5 (jan 2022)":
    let left_chart = 
      <VictoryChart>
        <VictoryGroup offset={15}
          colorScale={["#0394fc"]}
        >
        <VictoryBar
              data={[
                { x: "Pertamina", y: 26, label: '26%' },
                { x: "SOEs", y: 23, label: '23%' },
                { x: "Help SMEs", y: 12, label: '12%' },
                { x: "Economy", y: 8, label: '8%' },
                { x: "Corruption", y: 8, label: '8%' },
                { x: "PCR tests", y: 8, label: '8%' },
                // { x: "Businessman", y: 7, label: '7%' },
                // { x: "COVID response", y: 4, label: '4%' },
                // { x: "Humble, selfless, blusukan", y: 2, label: '2%' },
                // { x: "Garuda scandal", y: 2, label: '2%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
              // labels={["COVID-19", "Economic", "Health care", "Education", "Corruption" ]}
            />
            </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={150} y={20}
          orientation="horizontal"
          gutter={20}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#0394fc"]}
          data={[
            { name: "Wave 5 (jan 2022)" }
          ]}
        />
      </VictoryChart>

    bar_chart = <Fragment>
      <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-5px', marginTop:'25px'}}>
          Among those who have seen, heard or read about Erick Thohir, Pertamina toilet fees are the top story.
      </h5>
      {left_chart}
    </Fragment>

    const data_pie_1_raw = [
      { x: 1, y: 44, label: "Yes" },
      { x: 2, y: 46, label: "No" },
      { x: 3, y: 11, label: "Don't Know" },
    ]

    let pie_chart_overview = <Fragment>  
      <VictoryPie
          colorScale={"qualitative"}
          data={data_pie_1_raw}
          height={220}
          style={{
            data: {
              fillOpacity: 0.9, stroke: "white", strokeWidth: 2
            },
            labels: {
              fontSize: 8, fill: "white"
            }
          }}
        />
    </Fragment>

    left_bottom_component = <Fragment>
    <div className="col-lg-6" style={{ height: "100%", marginTop: "20px", marginBottom: "10px", paddingRight: '50px' }}>  
      <div className="col-lg-12">
        <div className="row">
            <p style={{display: 'flex', fontSize:'0.9em',  justifyContent:'center', alignItems:'center', color:'white', padding: '10px', marginTop: '10px', marginBottom:'5px'}}>
            Have you seen, heard or read anything about Erick Thohir lately? 
            </p>
        </div>

        <div className="row">
        <div className="col-lg-12">
        {pie_chart_overview}
          </div>
        </div>
      </div>
      
    </div>
    </Fragment>
  
    break;
  case "Survey 4 (jul 2021)":
    bar_chart = 
      <VictoryChart>
        <VictoryStack
          colorScale={["#003996","#07aced","#ff9b0f","red","#9e9d9b"]}
        >
            <VictoryBar
              data = {[
                {x: "Wave 1", y:9, label:'Strongly Approved'},
                {x: "Wave 2", y:47, label:'Strongly Approved'},
                {x: "Wave 3", y:30, label:'Strongly Approved'},
                {x: "Wave 4", y:44, label:'Strongly Approved'},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data = {[
                {x: "Wave 1", y:65, label:'Somewhat Approved'},
                {x: "Wave 2", y:35, label:'Somewhat Approved'},
                {x: "Wave 3", y:45, label:'Somewhat Approved'},
                {x: "Wave 4", y:39, label:'Somewhat Approved'},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data = {[
                {x: "Wave 1", y:12, label:'Somewhat Disapproved'},
                {x: "Wave 2", y:6, label:'Somewhat Disapproved'},
                {x: "Wave 3", y:10, label:'Somewhat Disapproved'},
                {x: "Wave 4", y:7, label:'Somewhat Disapproved'},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data = {[
                {x: "Wave 1", y:1, label:'Strongly Disapproved'},
                {x: "Wave 2", y:2, label:'Strongly Disapproved'},
                {x: "Wave 3", y:2, label:'Strongly Disapproved'},
                {x: "Wave 4", y:3, label:'Strongly Disapproved'},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data = {[
                {x: "Wave 1", y:14, label:"don't know"},
                {x: "Wave 2", y:10, label:"don't know"},
                {x: "Wave 3", y:13, label:"don't know"},
                {x: "Wave 4", y:5, label:"don't know"},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            {/* <VictoryBar
              data={[
                { x: "Strongly Approved", y: 57, label: '57%' },
                { x: "Somewhat Approved", y: 26, label: '26%' },
                { x: "Somewhat Disapproved", y: 5, label: '5%' },
                { x: "Strongly Disapproved", y: 2, label: '2%' },
                { x: "don't know", y: 2, label: '2%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
              // labels={["COVID-19", "Economic", "Health care", "Education", "Corruption" ]}
            /> */}
        </VictoryStack>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={92} y={10}
          orientation="horizontal"
          gutter={12}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#0394fc","#fc034e", "#faa34e", "#58e07c"]}
          data={[
            { name: "Nov 2020" }, { name: "Mar 2021" }, { name: "Jun 2021" }, { name: "Jul 2021" }
          ]}
        />
      </VictoryChart>

      pie_chart_top_01 = <PieRechart data={data01_v3} colors={colors01}/>;
      pie_chart_top_11 = <PieRechart data={data11_v3} colors={colors01}/>;
      pie_chart_bottom_21 = <PieRechart data={data21_v3} colors={colors21}/>
      pie_chart_bottom_22 = <PieRechart data={data22_v3} colors={colors01}/>

      title_chart11 = <Fragment>
        Have you seen, heard or read anything recently about microlending or small business loans from state-owned enterprises (SOEs), or other ways that state-owned enterprises (SOEs) support Indonesian small businesses?
      </Fragment>

      title_chart21 = <Fragment>
        After the Kimia Farma scandal, Erick Thohir, Minister of State-Owned Enterprises, fired the entire Board of Directors of Kimia Farma. Erick Thohir said that the corruption case required a rapid, professional and serious response?
      </Fragment>

      title_chart22 = <Fragment>
        Have you seen, heard or read anything recently about recent efforts to reduce corruption at state-owned enterprises?
      </Fragment>

      
    break;

  case "Survey 3 (jun 2021)":
    bar_chart = 
      <VictoryChart>
        <VictoryStack
          colorScale={["#003996","#07aced","#ff9b0f","red","#9e9d9b"]}
        >
            <VictoryBar
              data = {[
                {x: "Wave 1", y:9, label:'Strongly Approved'},
                {x: "Wave 2", y:47, label:'Strongly Approved'},
                {x: "Wave 3", y:30, label:'Strongly Approved'},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data = {[
                {x: "Wave 1", y:65, label:'Somewhat Approved'},
                {x: "Wave 2", y:35, label:'Somewhat Approved'},
                {x: "Wave 3", y:45, label:'Somewhat Approved'},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data = {[
                {x: "Wave 1", y:12, label:'Somewhat Disapproved'},
                {x: "Wave 2", y:6, label:'Somewhat Disapproved'},
                {x: "Wave 3", y:9, label:'Somewhat Disapproved'},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data = {[
                {x: "Wave 1", y:1, label:'Strongly Disapproved'},
                {x: "Wave 2", y:2, label:'Strongly Disapproved'},
                {x: "Wave 3", y:2, label:'Strongly Disapproved'},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data = {[
                {x: "Wave 1", y:14, label:"don't know"},
                {x: "Wave 2", y:10, label:"don't know"},
                {x: "Wave 3", y:13, label:"don't know"},
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            {/* <VictoryBar
              data={[
                { x: "Strongly Approved", y: 57, label: '57%' },
                { x: "Somewhat Approved", y: 26, label: '26%' },
                { x: "Somewhat Disapproved", y: 5, label: '5%' },
                { x: "Strongly Disapproved", y: 2, label: '2%' },
                { x: "don't know", y: 2, label: '2%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
              // labels={["COVID-19", "Economic", "Health care", "Education", "Corruption" ]}
            /> */}
        </VictoryStack>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={92} y={10}
          orientation="horizontal"
          gutter={12}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#0394fc","#fc034e", "#faa34e"]}
          data={[
            { name: "Nov 2020" }, { name: "Mar 2021" }, { name: "Jun 2021" }
          ]}
        />
      </VictoryChart>

      pie_chart_top_01 = <PieRechart data={data01_v3} colors={colors01}/>;
      pie_chart_top_11 = <PieRechart data={data11_v3} colors={colors01}/>;
      pie_chart_bottom_21 = <PieRechart data={data21_v3} colors={colors21}/>
      pie_chart_bottom_22 = <PieRechart data={data22_v3} colors={colors01}/>

      title_chart11 = <Fragment>
        Have you seen, heard or read anything recently about microlending or small business loans from state-owned enterprises (SOEs), or other ways that state-owned enterprises (SOEs) support Indonesian small businesses?
      </Fragment>

      title_chart21 = <Fragment>
        After the Kimia Farma scandal, Erick Thohir, Minister of State-Owned Enterprises, fired the entire Board of Directors of Kimia Farma. Erick Thohir said that the corruption case required a rapid, professional and serious response?
      </Fragment>

      title_chart22 = <Fragment>
        Have you seen, heard or read anything recently about recent efforts to reduce corruption at state-owned enterprises?
      </Fragment>

      
    break;
  case "Survey 2 (mar 2021)":
    break;
}

    return (
      <React.Fragment>
        <div className="full-width" style={{background: '#212529'}}>
            <div className="col-lg-12">
              <div className="sidebar sidebar-left">
                <div className="sidebar-content">
                  <nav className="main-menu">
                    <ul className="nav">
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="icon dripicons-graph-bar" />
                        </a>
                        <SubmenuTurnout
                          handleToggleInfo={handleToggleInfo}
                          handleGetSubMenu={handleGetSubMenu}
                          handleToggleSubMenu={handleTogglePOISubMenu}
                          handleCloseSubmenu={handleCloseSubmenu}
                          handleChangeMethod={handleChangeMethod}
                          placeholder="Select Scoring"
                          data={{
                            info,
                            openPOI,
                            isPOISubOpen,
                            handleSubMenuChange,
                            turnout
                          }}
                        />
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
        </div>
        <div className="row mapHeight" style={{borderBottom: '3px solid #932427', background: '#212529'}}>
          <div className="row col-lg-12" style={{height: '40%'}}>
            <div className="col-lg-6" style={{height: "100%",  marginTop: "80px", marginBottom: "20px" }}>            
              <h5 className="col-lg-12" 
                style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-5px'}}>
                PAK ERICK THOHIR JOB APPROVAL AS SOE
              </h5>
              {bar_chart}
            </div>
            <div className="col-lg-6" style={{height: "100%",  marginTop: "80px", marginBottom: "20px" }}> 
              <div className="row">
                <h5 className="col-lg-12" 
                  style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-15px'}}>
                  AWARENESS OF SOE
                </h5>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <p style={{display: 'flex', fontSize:'0.8em',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '30px', marginBottom:'-10px'}}>
                    Have you seen, heard or read anything recently about state-owned enterprises (SOEs)?
                  </p>
                </div>
                <div className="col-lg-6">
                  <p f style={{display: 'flex', fontSize:'0.5em', justifyContent:'center', alignItems:'center', color:'white', marginTop: '30px', marginBottom:'-10px'}}>
                  {title_chart11}
                  </p>
                </div>
              </div>
              <div className="row" style={{height: "100%"}}>
                <div className="col-lg-6">
                  {pie_chart_top_01}
                </div>
                <div className="col-lg-6">
                  {pie_chart_top_11}
                </div>
              </div>
            </div>
          </div>
          <div className="row col-lg-12" style={{height: '40%'}}>
            {left_bottom_component}
            <div className="col-lg-6" style={{ height: "100%", marginTop: "80px", marginBottom: "20px" }}> 
              <div className="row">
                <div className="col-lg-6">
                  <p style={{display: 'flex', fontSize:'0.6em',  justifyContent:'center', alignItems:'center', color:'white', marginTop: '30px', marginBottom:'-10px'}}>
                  {title_chart21}
                  </p>
                </div>
                <div className="col-lg-6">
                  <p f style={{display: 'flex', fontSize:'0.5em', justifyContent:'center', alignItems:'center', color:'white', marginTop: '30px', marginBottom:'-10px'}}>
                  {title_chart22}
                  </p>
                </div>
              </div>
              <div className="row" style={{height: "100%"}}>
                  <div className="col-lg-6">
                    {pie_chart_bottom_21}
                  </div>
                  <div className="col-lg-6">
                    {pie_chart_bottom_22}
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="map50-bottom-soe">
          <MapBottom source="turnout" type="soe" handleBorderMapClick={()=>{}} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SOE);
