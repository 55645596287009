import toastr from "toastr";
import { GET } from "API";
import { reAuth } from "./authActions";
import { setModal } from "actions";
import { dispatch } from "d3";

export const API_HOST = process.env.REACT_APP_API_HOST;

// Impure Functions to do api actions
// POI API
export function getPOI(type) {
  return async dispatch => {
    try {
      const response = await GET(`${API_HOST}/sental/geo/poi`,{poi_type: type});
      dispatch(setPOI(type, response.data, true));
    } catch (err) {
      toastr.error(err.message);
    }
  };
}

// POI API by province
export function getPOIprovince(type, kode_prop) {
  return async dispatch => {
    try {
      const response = await GET(`${API_HOST}/sental/geo/v2/poi/${kode_prop}`,{poi_type: type});
      dispatch(setPOI(type, response.data, true));
    } catch (err) {
      toastr.error(err.message);
    }
  };
}
// PROVINCE API
export function getProvince(source = '', params = '') {
  return dispatch => {
    GET(`${API_HOST}/sental/geo/getProvince${source}${params}`)
      .then(res => {
        dispatch(setProvince(res.data.data));
      })
      .catch(err => {
        console.log(err.response)
        err.response.status === 401
          ? dispatch(reAuth())
          : toastr.error(err.response.statusText);
      });
  };
}
// PROVINCE API
export function getOverview(type, filter) {
  return dispatch => {
    GET(`${API_HOST}/sental/overview/summary/aggregation?type=${type}${filter !== undefined ? '&filter=' + filter : ""}`)
      .then(res => {
        // console.log("XXXX");
        // console.log(res.data.data);
        dispatch(setProvince(res.data.data));
      })
      .catch(err => {
        console.log(err.response)
        err.response.status === 401
          ? dispatch(reAuth())
          : toastr.error(err.response.statusText);
      });
  };
}
// PROVINCE API
export function getMediaTrending(media) {
  return dispatch => {
    GET(`${API_HOST}/sental/overview/social-sources/trending?media=${media}`)
      .then(res => {
        // console.log("Media .... XXXX");
        // console.log(res.data.data);
        dispatch(setProvince(res.data.data));
      })
      .catch(err => {
        console.log(err.response)
        err.response.status === 401
          ? dispatch(reAuth())
          : toastr.error(err.response.statusText);
      });
  };
}
// INFO API
export function getInfo(type, info) {
  return dispatch => {
    GET(`${API_HOST}/sental/geo/data/${type}`)
      .then(res => {
        dispatch(setInfo(type, res.data.data, info));
      })
      .catch(err => {
        err.response.status === 401
          ? dispatch(reAuth())
          : toastr.error(err.response.statusText);
      });
  };
}

// DEMOGRAPHY API
export function getDemography(prov) {
  return dispatch => {
    GET(`${API_HOST}/sental/geo/demography/${prov}`)
      .then(res => {
        dispatch(setDemography(res.data.data));
      })
      .catch(err => {
        err.response.status === 401
          ? dispatch(reAuth())
          : toastr.error(err.response.statusText);
      });
  };
}

// PROVINCE API
export function getTurnout(type) {
  // console.log('xxx : ' + type);
  // if(type['vp-candidate'] !== undefined) {
  //   return getVPCandidate(type);
  // }

  return dispatch => {
    GET(`${API_HOST}/sental/turnout/map-score?type=${type}`)
      .then(res => {
        dispatch(setProvince(res.data.data));
      })
      .catch(err => {
        console.log(err.response)
        err.response.status === 401
          ? dispatch(reAuth())
          : toastr.error(err.response.statusText);
      });
  };
}

// VP 
export function getVPCandidate(type) {
  console.log(`on get VP candidtate : ${type}`)
  return dispatch => {
    GET(`${API_HOST}/sental/turnout/map-score?type=${type}`)
      .then(res => {
        dispatch(setProvince(res.data.data));
      })
      .catch(err => {
        console.log(err.response)
        err.response.status === 401
          ? dispatch(reAuth())
          : toastr.error(err.response.statusText);
      });
  };
}


// DEMOGRAPHY API
export function getDemographySidePanelMultiple(filterTable) {

  // let url = `${API_HOST}/sental/geo/getProvince/sidepanel/multiple/map_360_home_favorable_bottom;map_360_home_approval_bumn_bottom`;

  let url = `${API_HOST}/sental/geo/getProvince/sidepanel/multiple/${filterTable}`;

  return dispatch => {
    GET(url)
    .then(res => {
      dispatch(setProvince(res.data.data));
    })
    .catch(err => {
      console.log(err.response)
      err.response.status === 401
        ? dispatch(reAuth())
        : toastr.error(err.response.statusText);
    });
  };
}


// DEMOGRAPHY API
export function getDemographySidePanelDemography(prov) {
  return dispatch => {
    GET(`${API_HOST}/sental/geo/demography/${prov}`)
      .then(res => {
        console.log('aaaaa...');
        console.log(res.data.data);
        dispatch(setInfo(`gender`, res.data.data.gender, `all`));
        // dispatch(setInfo(`ses`, res.data.data.kesejahteraan, `all`));
        // dispatch(setInfo(`pendidikan`, res.data.data.pendidikan, `all`));
        dispatch(setInfo(`age`, res.data.data.age_details, `all`));
        // dispatch(setInfo(`agama`, res.data.data.agama, `all`));
      })
      .catch(err => {
        err.response.status === 401
          ? dispatch(reAuth())
          : toastr.error(err.response.statusText);
      });
  };
}

export function getDemographySidePanel(type, value) {

  let url = `${API_HOST}/sental/geo/getProvince`

  if(type === 'favorable' && value !== 0) {
    url = `${API_HOST}/sental/geo/getProvince/sidepanel/map_360_home_favorable`;

    if (value > 1) {
      url += '_bottom';
    }
    // url = `${API_HOST}/sental/geo/getProvince/sidepanel/multiple/map_360_home_favorable_bottom;map_360_home_approval_bumn_bottom`;
  } else if(type === 'approval_bumn' && value !== 0) {
    url = `${API_HOST}/sental/geo/getProvince/sidepanel/map_360_home_approval_bumn`;

    if (value > 1) {
      url += '_bottom';
    }
  } else if(type === 'approval_pssi' && value !== 0) {
    url = `${API_HOST}/sental/geo/getProvince/sidepanel/map_360_home_approval_pssi`;

    if (value > 1) {
      url += '_bottom';
    }
  } else if(type === 'electability' && value !== 0) {
    url = `${API_HOST}/sental/geo/getProvince/sidepanel/map_360_home_election`;

    if (value > 1) {
      url += '_bottom';
    }
  } else if(type === 'region' && value > 0) {
    
    // console.log('xxxx region')

    let position = {
      lat: parseFloat(-6.816131),
      lng: parseFloat(107.650177)
    }

    // dispatch(setModal({ position }));
    // /**
    //  * lat: -6.231888, lng: 106.846226}lat: -6.231888lng: 106.846226[[Prototype]]: Object '31' '70000' 'DKI JAKARTA'
    //  * {lat: -6.302644, lng: 106.200015} '36' '15000' 'BANTEN'
    //  * {lat: -6.816131, lng: 107.650177} '32' '8000' 'JAWA BARAT'
    //  * {lat: -7.287552, lng: 109.956063} '33' '8000' 'JAWA TENGAH'
    //  * {lat: -7.845196, lng: 110.40074} '34' '30000' 'DI YOGYAKARTA'
    //  * {lat: -7.768415, lng: 112.512732} '35' '7000' 'JAWA TIMUR'
    //  */

    // return;

    url = `${API_HOST}/sental/geo/getProvince/sidepanel/multiple/map_360_home_favorable/map_360_home_approval_bumn`;

    // if (value > 1) {
    //   url += '_bottom';
    // }
  }

  


  

  return dispatch => {
    GET(url)
    .then(res => {
      dispatch(setProvince(res.data.data));
    })
    .catch(err => {
      console.log(err.response)
      err.response.status === 401
        ? dispatch(reAuth())
        : toastr.error(err.response.statusText);
    });
  };
}

export function getLegends(type, info) {
  let resType;
  switch (type) {
    case "age":
      resType = "age_details";
      break;
    case "ses":
      resType = "kesejahteraan";
      break;
    default:
      resType = type;
      break;
  }
  return dispatch => {
    GET(`${API_HOST}/sental/geo/national/demography/${type}?filter=${info}`)
      .then(res => {
        dispatch(setInfo(type, res.data.data[resType], info));
        dispatch(setLegend(res.data.data.legend));
      })
      .catch(err => {
        err.response.status === 401
        ? dispatch(reAuth())
        : toastr.error(err.response.statusText);
      });
  };
}

export function getLegendsDemography(type, info) {
  let resType;
  switch (type) {
    case "age":
      resType = "age_details";
      break;
    case "ses":
      resType = "kesejahteraan";
      break;
    default:
      resType = type;
      break;
  }
  return dispatch => {
    GET(`${API_HOST}/sental/geo/national/demography/${type}?filter=${info}`)
      .then(res => {
        console.log(resType);
        console.log(res.data.data);
        
        if (type == 'unknown') {
          dispatch(setInfo(`gender`, res.data.data.gender, info));
          dispatch(setInfo(`ses`, res.data.data.kesejahteraan, info));
          dispatch(setInfo(`pendidikan`, res.data.data.pendidikan, info));
          dispatch(setInfo(`age`, res.data.data.age_details, info));
          dispatch(setInfo(`agama`, res.data.data.agama, info));
        } else {
          dispatch(setInfo(type, res.data.data[resType], info));
        }

        dispatch(setLegend(res.data.data.legend));
      })
      .catch(err => {
        err.response.status === 401
        ? dispatch(reAuth())
        : toastr.error(err.response.statusText);
      });
  };
}

// Pure functions to change reducer state
// export function setModal(payload) {
//   return {
//     type: 'SET_MODAL',
//     payload
//   }
// }

export function setPOI(type, res, isPoi) {
  return {
    type: "SET_POI",
    payload: { type, res, isPoi }
  };
}

export function clearPOI() {
  return {
    type: "CLEAR_POI"
  };
}

export function setInfo(type, value, info) {
  return {
    type: "SET_INFO",
    payload: { type, value, info }
  };
}

export function clearInfo(type) {
  return {
    type: "CLEAR_INFO",
    payload: type
  };
}

export function setMediaTrending(payload) {
  return {
    type: "SET_MEDIA_TRENDING",
    payload
  };
}

export function setProvince(payload) {
  return {
    type: "SET_PROVINCE",
    payload
  };
}

export function setTurnout(payload) {
  return {
    type: "SET_TURNOUT",
    payload
  };
}

export function clearProvince() {
  return {
    type: "CLEAR_PROVINCE"
  };
}

export function setDemography(payload) {
  return {
    type: "SET_DEMOGRAPHY",
    payload
  };
}

export function clearDemography(payload) {
  return {
    type: "CLEAR_DEMOGRAPHY"
  };
}

export function setLegend(payload) {
  return {
    type: "SET_LEGEND",
    payload
  };
}

export function setHoverKab(payload) {
  return {
    type: "SET_HOVER_KAB",
    payload
  };
}

export function clearHoverKab() {
  return {
    type: "CLEAR_HOVER_KAB",
  };
}

export function setPositionMap(lat, lng, zoom) {
  return {
    type: "SET_POSITION_MAP",
    payload: { lat, lng, zoom }
  };
}

export function clearPositionMap() {
  return {
    type: "CLEAR_POSITION_MAP",
  };
}

export function setZoomMap(payload) {
  return {
    type: "SET_ZOOM_MAP",
    payload
  };
}
