import React, { Component } from "react";
import { reAuth } from "actions/authActions";
// import Iframe from "@nicholasadamou/react-iframe";
import { connect } from "react-redux";
import GoogleTopicTrends from "components/topicmapping//GoogleTopicTrends";
import SidePanelDemography from "../mapping/SidePanelDemography";
import { Box, Button, TextField } from '@mui/material';
import axios from "axios";
import loading from "media/img/components/loading_blue_trans3.gif";
import Modal from 'react-modal';

import "styles/components/map-submenu.css";
import { index } from "d3-array";

const scrt = 'qWAiy9A6CfWZ7fwjP29nwQ4Tfbl4Hbvxk6lrZqr4gQYuCdDSLd4jrmGUcFS6f0Cm'
const masterProvince = [
  {
    "location_id": "32",
    "location_name": "Jawa Barat"
  },
  {
    "location_id": "75",
    "location_name": "Gorontalo"
  },
  {
    "location_id": "64",
    "location_name": "Kalimantan Timur"
  },
  {
    "location_id": "17",
    "location_name": "Bengkulu"
  },
  {
    "location_id": "19",
    "location_name": "Bangka Belitung"
  },
  {
    "location_id": "12",
    "location_name": "Sumatera Utara"
  },
  {
    "location_id": "63",
    "location_name": "Kalimantan Selatan"
  },
  {
    "location_id": "36",
    "location_name": "Banten"
  },
  {
    "location_id": "71",
    "location_name": "Sulawesi Utara"
  },
  {
    "location_id": "52",
    "location_name": "Nusa Tenggara Barat"
  },
  {
    "location_id": "11",
    "location_name": "Aceh"
  },
  {
    "location_id": "62",
    "location_name": "Kalimantan Tengah"
  },
  {
    "location_id": "34",
    "location_name": "Yogyakarta"
  },
  {
    "location_id": "72",
    "location_name": "Sulawesi Tengah"
  },
  {
    "location_id": "73",
    "location_name": "Sulawesi Selatan"
  },
  {
    "location_id": "76",
    "location_name": "Sulawesi Barat"
  },
  {
    "location_id": "64",
    "location_name": "Kalimantan Utara"
  },
  {
    "location_id": "15",
    "location_name": "Jambi"
  },
  {
    "location_id": "81",
    "location_name": "Maluku"
  },
  {
    "location_id": "33",
    "location_name": "Jawa Tengah"
  },
  {
    "location_id": "51",
    "location_name": "Bali"
  },
  {
    "location_id": "35",
    "location_name": "Jawa Timur"
  },
  {
    "location_id": "61",
    "location_name": "Kalimantan Barat"
  },
  {
    "location_id": "31",
    "location_name": "Jakarta"
  },
  {
    "location_id": "94",
    "location_name": "Papua"
  },
  {
    "location_id": "91",
    "location_name": "Papua Barat"
  },
  {
    "location_id": "53",
    "location_name": "Nusa Tenggara Timur"
  },
  {
    "location_id": "82",
    "location_name": "Maluku Utara"
  },
  {
    "location_id": "21",
    "location_name": "Kepulauan Riau"
  },
  {
    "location_id": "13",
    "location_name": "Sumatera Barat"
  },
  {
    "location_id": "18",
    "location_name": "Lampung"
  },
  {
    "location_id": "16",
    "location_name": "Sumatera Selatan"
  },
  {
    "location_id": "74",
    "location_name": "Sulawesi Tenggara"
  },
  {
    "location_id": "14",
    "location_name": "Riau"
  }
]

const refMasterTrend24 = {11: 'medan', 51: 'surabaya', 19: 'medan', 36: 'jakarta', 17: 'medan', 75: 'jakarta', 31: 'jakarta', 15: 'medan', 32: 'bandung', 33: 'semarang', 35: 'surabaya', 61: 'surabaya', 63: 'surabaya', 62: 'surabaya', 64: 'surabaya', 21: 'jakarta', 18: 'jakarta', 81: 'jakarta', 82: 'jakarta', 52: 'jakarta', 53: 'jakarta', 91: 'jakarta', 92: 'jakarta', 14: 'jakarta', 76: 'jakarta', 73: 'jakarta', 72: 'jakarta', 74: 'jakarta', 71: 'jakarta', 13: 'medan', 16: 'medan', 12: 'medan', 34: 'semarang', 93: 'jakarta', 94: 'jakarta', 95: 'jakarta', 96: 'jakarta'}

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

class OverviewCrowd extends Component {
  state = {
    isLoading: false,
    iframeRandomKey: 0,
    frameElement: null,
    frameDome: null,
    provinceFilter: 'national',
    urlIframe: 'https://besttime.app/api/v1/radar/filter?q=pasar+in+jawa+timur&map_z=9&api_key_private=pri_a560adcc7d334f6dbf52359f0b84183d&busy_conf=any&live_refresh=False',
    isIframeLoaded : false,
    inputPlaceName : null,
    inputAreaName : null,
    region : '31',
    urlTrend: "https://trends24.in/indonesia/",
    trends: JSON.parse(localStorage.getItem("trends24"))
  }

  // const handleFrameElement = React.useCallback(e => {
  //   setFrameElement(e.target);
  // }, []);

  

  handleFrameElement = val => {
    this.setState({
      frameElement: val,
      urlIframe: val.target.src,
      isIframeLoaded: true,
      // iframeRandomKey: this.state.iframeRandomKey + 1
    })

    // console.log(val.target);

    // console.log('yyyy');
    // let domSrc = val.target.contentWindow.location;
    // console.log('xxx : ' + domSrc);

    let domTarget = val.target.src;
    //   // .getElementById('clickbutton');
    console.log(domTarget);
  }

  handleInputPlace = val => {
    this.setState({
      inputPlaceName: val.target.value
    })
  }

  handleInputArea = val => {
    this.setState({
      inputAreaName: val.target.value
    })
  }

  handleButtonFilterLeft = val => {
    console.log('clicked xxx ');
    
    const urlBase = 'https://besttime.app/api/v1/radar/filter?map_z=9&api_key_private=pri_a560adcc7d334f6dbf52359f0b84183d&busy_conf=any&live_refresh=False';
    // let urlLocationPath = 'q=pasar+in+jawa+timur&';

    if (this.state.inputAreaName !== null && this.state.inputPlaceName !== null) {

      this.state.isLoading = true;

      // console.log('area to be search : ' + this.state.inputPlaceName + '  --> area : ' + this.state.inputAreaName); 
      const searchAreaPlace = this.state.inputPlaceName.replace(' ','+') + '+in+' + this.state.inputAreaName.replace(' ','+');
      console.log('area to be search : ' + searchAreaPlace);

      const urlNew = urlBase + '&q=' + searchAreaPlace;
      this.setState({
        urlIframe : urlNew,
        iframeRandomKey: this.state.iframeRandomKey + 1
      })

      const that = this;

      const urlSearchVenues = 'https://besttime.app/api/v1/venues/search'
      let params = new URLSearchParams({
        'api_key_private': 'pri_a560adcc7d334f6dbf52359f0b84183d',
        // 'q': 'pasar+in+surabaya',
        'q': searchAreaPlace,
        'num': 20,
        'fast': false
      });
      // bodyFormData.append('num','20')
      // bodyFormData.append('opened','all')
      // bodyFormData.append('fast', false)
      // bodyFormData.append('api_key_private', 'pri_1cbe7061e77d44db93bbbbe7285d1e51')
      // bodyFormData.append('q', 'pasar+in+surabaya')
      // bodyFormData.append('q', 'pasar+in+surabaya')
      // bodyFormData.append('csrf_token', 'IjVhZTgyMTk0MGQ5NmJkYmNiZTRmOGU3OGFlYTMxOWIwYjJhODIyYzAi.ZJYTvQ.PlD-PB7qR6vCIseSnNNYS6LeHsQ&q')

      fetch(`https://besttime.app/api/v1/venues/search?${params}`, {
        method: 'POST'
      }).then(function (response) {
        // that.state.isLoading = false;
        return response.json();
      }).then(async function(data) { 
        console.log('response...');
        console.log(data); 

        let link = data['_links']['venue_search_progress'];
        console.log(link);

        let isJobFinished = false;
        let innerData = null;
        while(!isJobFinished) {
          // let resp = await fetch(link, {method: 'GET'}).then(function (response) {
          //   return response.json();
          // }).then(function (data) {
          //   console.log('inner response...');
          //   console.log(data);

          //   // isJobFinished = data['job_finished'];
          //   // innerData = data;
          //   return data
          // })

          let resp = await fetch(link, {method: 'GET'}).then(function (response) {
            return response.json();
          });

          isJobFinished = resp['job_finished'];
          console.log('task finished : ' + isJobFinished);

          await delay(3000);

          innerData = resp;
        }

        that.state.isLoading = false;

        console.log('xxx final response : ');
        console.log(innerData);;

        if (innerData !== null) {
          const radarUrl = innerData['_links']['radar_tool']
          console.log('radar url : ' + radarUrl);

          that.setState({
            urlIframe : radarUrl,
            iframeRandomKey: that.state.iframeRandomKey + 1
          })

          /**
           * call url for right sidebar 
           */

          const urlMapGeocode = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyB5QWo1-TD3qdYW1z7SAYialAfUE4HBzN4&language=id&address=' + that.state.inputAreaName;
          let respGmap = await fetch(urlMapGeocode, {method: 'GET'}).then(function (response) {
            return response.json();
          });
          console.log('xxx gmap')
          console.log(respGmap)

          const addrComponents = respGmap['results'][0]['address_components'];
          let provinceName = null;
          addrComponents.forEach((addrDetail) => {
            if(addrDetail['types'][0] == 'administrative_area_level_1') {
              provinceName = addrDetail['long_name']
              console.log('province name is ' + provinceName)
            }
          })

          /**
           * find province id by prov name
           */
          if (provinceName !== null) {
            const searchProvName = provinceName.toLocaleLowerCase()
            let provId = null
            masterProvince.forEach((provDetail) => {
                console.log(provDetail)
                const provNameLower = provDetail['location_name'].toLocaleLowerCase()
                
                if (provNameLower == searchProvName) {
                  provId = provDetail['location_id']

                  console.log('xxx prov details')
                  console.log(provDetail)
                  return false
                } 

                return true
            })

            if (provId === null) {
              masterProvince.forEach((provDetail) => {
                console.log(provDetail)
                const provNameLower = provDetail['location_name'].toLocaleLowerCase()
                
                if (provNameLower !== searchProvName && provNameLower.includes(searchProvName)) {
                  provId = provDetail['location_id']
                  return false
                }
                
                if (provNameLower !== searchProvName && searchProvName.includes(provNameLower)) {
                  provId = provDetail['location_id']
  
                  return false
                }
  
                return true
              })
            }

            console.log("xxx search prov id : " + provId)
            that.state.region = String(provId);

            const urlT = "https://trends24.in/indonesia/" + refMasterTrend24[provId]
            console.log("xxx got url trend24 : " + urlT)
            that.state.urlTrend = urlT;

            console.log("fetch new data from trend24 hasura")
            const urlHasuraTrend = "https://fast-ghost-25.hasura.app/api/rest/gettrend24byregion/" + refMasterTrend24[provId]
            const dataHasura = await fetch(urlHasuraTrend, {method: 'GET',  headers: new Headers({
              'x-hasura-admin-secret': scrt
            })}).then(function (response) {
              console.log('xxx resp  prov:')
              const data = response.json();
              console.log(data)
              return data;
            });

            // axios({
            //   method: "GET",
            //   urlHasuraTrend,
            //   headers: {
            //     'x-hasura-admin-secret': scrt
            //   }
            // }).then(response => {
            //   const dataHasura = response;
            //   console.log('xxx data hasura prov : ' + dataHasura['trends_history']);
            //   this.setState({
            //     trends: dataHasura['trends_history']
            //   });
            // })
            // .catch(err => {
            //   console.log(err);
            // });

            console.log('xxx data hasura prov : ') 
            console.log(dataHasura['trends_history']);
            that.state.trends = dataHasura['trends_history']

            //close modal loading
            that.state.isLoading = false;
          }

        } else {
          //close modal loading
          that.state.isLoading = false;
        }
        
      }).catch((error) => {
        that.state.isLoading = false;

        console.log('crowd exception error!')
        console.log(error)
      }).finally(()=> {

        console.log('finish search crowd')
        that.state.isLoading = false;
      });

      // const response = axios({
      //   method: "post",
      //   url: urlSearchVenues,
      //   data: bodyFormData,
      //   headers: {
      //     'Referer':'https://besttime.app/api/v1/radar/filter?q=pasar+in+bandung&map_lat=-6.91&map_lng=107.61&lat_min=-6.9542689&lat_max=-6.8674831&lng_min=107.574891&lng_max=107.6448027&map_z=13&collection_id=col_0d970f1c4d324a04af2ebae0a536a35f&api_key_private=pri_1cbe7061e77d44db93bbbbe7285d1e51&busy_conf=any&live_refresh=False&lat=-6.91&lng=107.61&radius=22619',
      //     'Content-Type': "application/x-www-form-urlencoded",
      //     'Cookie': '_ga_T0V3N321G9=GS1.1.1687556421.21.1.1687558903.0.0.0; _ga=GA1.2.2145395581.1684596817; __stripe_mid=75a96292-21dc-46ff-bd29-70476e4f9e29eb36b7; remember_token=5267|cc64b10534c00c03f0c124fb31f8a9ceedf4bde236fde25c22183ac887826fa2720a9ddbe5b52c7cbbcaecfdad21605d81fe509964d0a4289b2fc8539c8b5f71; _clck=192tiou|2|fcp|0|1258; session=eyJfZnJlc2giOmZhbHNlLCJfdXNlcl9pZCI6IjUyNjciLCJjc3JmX3Rva2VuIjoiNWFlODIxOTQwZDk2YmRiY2JlNGY4ZTc4YWVhMzE5YjBiMmE4MjJjMCJ9.ZJYUcg.RL9eAwu06Y1_wlScVkRESStLvP0; SL_C_23361dd035530_SID={"c2f6c086696e911cee1fe4f798984693aa9c95c2":{"sessionId":"f-KOWBHlUCSEvMwQJ7-XO","visitorId":"qQ6Ame0ZDQpWOcUASvwX8"}}; _gid=GA1.2.1034914144.1687556423; _clsk=1nk7qvz|1687558741474|21|1|q.clarity.ms/collect'
      //   }
      // });

      
      // console.log(response);
    

      // console.log('xxx 1')
      // console.log(this.state.frameElement)

      // this.state.frameElement.target.src = urlNew

      // console.log('xxx 2')
      // console.log(this.state.frameElement)
    } else {
      this.state.isLoading = false;
    }
  }

  async componentDidMount() {
    console.log("fetch new data from trend24 hasura")
    const urlHasuraTrend = "https://fast-ghost-25.hasura.app/api/rest/gettrend24byregion/" + 'indonesia'
    
    const that = this;
    const dataHasura = await fetch(urlHasuraTrend, {method: 'GET',  headers: new Headers({
      'x-hasura-admin-secret': scrt
    })}).then(function (response) {
      console.log('xxx resp')
      const data = response.json();
      console.log(data)
     return data;
    });

    // const dataHasura = response;
    console.log('xxx data hasura : ');
    console.log(dataHasura['trends_history'])
    that.state.trends = dataHasura['trends_history']

    // axios({
    //   method: "GET",
    //   urlHasuraTrend,
    //   headers: {
    //     'x-hasura-admin-secret': scrt
    //   }
    // }).then(response => {
    //   const dataHasura = response;
    //   console.log('xxx data hasura : ' + dataHasura['trends_history']);
    //   this.setState({
    //     trends: dataHasura['trends_history']
    //   });
    // })
    // .catch(err => {
    //   console.log(err);
    // });
    

    
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('changes...')
  }

  render() {

    const { isLoading } = this.state;

    //document.cookie = "_ga_T0V3N321G9=GS1.1.1684627752.4.1.1684629006.0.0.0; _ga=GA1.1.2145395581.1684596817; _gid=GA1.2.784855966.1684596818; session=.eJwljktqBDEMBe_idRa29bPmMo1sScwQSKB7ZhVy93HItni8qp9y5BnXvdye5ys-yvHwcivoKVqnJc2qGDVbb1VsAROhGAAGsQoqQdaEDns2giWzRWNTYBjZ3bxxQCpFcyTvvVfrEVV8ckokxsq6VLmpJ5gwQB2OmmWHvK44_2uos2yyrjOP5_dnfP2xiNVYgoAdwcxivw-TUZO2KnM6ThAqv29ceUAG.ZGliBA.QUZNWo1IBGAwRWuFDalSoPMGbZ8; __cf_bm=9QLy0S6kToEE946CkfBCNT_jPQ0snF_v52T4tJJvUMg-1684627892-0-AbdPTJcYeuGQRt2JobDKOR6dPGhMMXjZgglB/9ziUmWOw0vgAvzEKXTPO85Sl+nBuqfgnbeRnfgltQIXu8lFink=; __stripe_sid=406afede-2211-47d0-8b1d-df20105c9c139b3221; __stripe_mid=75a96292-21dc-46ff-bd29-70476e4f9e29eb36b7; _gat_gtag_UA_165301827_1=1; SL_C_23361dd035530_SID=fZqe5vyIWMdYdeECtqN2_; SL_C_23361dd035530_VID=qQ6Ame0ZDQpWOcUASvwX8; SL_C_23361dd035530_KEY=c2f6c086696e911cee1fe4f798984693aa9c95c2";
    // document.cookie = "session=.eJwtzjlOBEEMBdC7VEzgpTbPZVpl17dASCB1z0SIu9MB6YveTznyxPVeHs_zhbdyfOzyKI3qrDImR_Qmtbn7qECEZRcKHxCqbsYp2cwjkasNbpG8yNRho3cRidRocxqtdLEaZE05J9x0uQpNgyV0AdtiYlis2jdQ7sjrwvm_kT5uievM4_n9ia_bJpGqOAPOPaeGt3VXENIxMDpz6t5cy-8f931BLA.ZLkEXw.ZjCW7A9_t3Yl6nslhcsQFLgxoyg; _ga_T0V3N321G9=GS1.1.1689846865.1.1.1689847012.0.0.0; _ga=GA1.1.1206855762.1689846866; _ga_J5JX8PNYZ0=GS1.1.1689846865.1.1.1689847012.30.0.0; _gid=GA1.2.1065218545.1689846866; _clck=r0vm5x|2|fdg|0|1296; _clsk=sf3s5n|1689846989414|5|1|q.clarity.ms/collect; remember_token=5267|cc64b10534c00c03f0c124fb31f8a9ceedf4bde236fde25c22183ac887826fa2720a9ddbe5b52c7cbbcaecfdad21605d81fe509964d0a4289b2fc8539c8b5f71; _gat_gtag_UA_165301827_1=1"

    let domIframe = document.getElementById('iframe');
    // .contentDocument.getElementById('identity')

    if (domIframe != null) {
      console.log(domIframe);
    }

    const modalStyles = {
      content: {
        right: 'auto',
        bottom: 'auto',
        width: '20%',
        height: '30%',
        marginTop: '19%',
        marginBottom: '12%',
        zIndex: '99999 !important'
      },
    };

    // if (this.frameElement != null) {
    //   console.log('frame xxx');
    //   let domUsername = domIframe.contentWindow.document.getElementById('clickbutton');
    //   console.log(domUsername);
    // }

    // https://besttime.app/api/v1/radar/filter?q=pasar+in+jawa+timur&map_lat=-7.6221222&map_lng=112.3300435&lat_min=-8.101064&lat_max=-7.1431804&lng_min=111.8810029&lng_max=112.7790842&map_z=9&collection_id=col_66a6df8766624726b1ca27fb324c4751&api_key_private=pri_1cbe7061e77d44db93bbbbe7285d1e51&busy_conf=any&live_refresh=False

    return (
      <React.Fragment>
        {this.state.isIframeLoaded && (
        <div style={{height: '148px', width: '32%', padding: '22px', color: 'white', background: '#191819', position:'absolute', top: '66px', left: '0%', zIndex: '101'}}>
          <div
              component="form"
              // sx={{
              //   '& > :not(style)': { m: 1, width: '25ch' },
              // }}
              noValidate
              autoComplete="off"
              style={{width: '100%', background: '#ddd !important', color: 'white !important', textColor: 'white !important', filter: 'invert(90%) hue-rotate(180deg)'}} 
            >
              <div style={{width: '70%'}}>
                <TextField 
                  style={{width: '100%'}} 
                  id="input-place-name" 
                  label="Place Name" 
                  defaultValue="Tempat Kumpul" 
                  variant="standard" 

                  onChange={this.handleInputPlace}
                />
              </div>
              <div style={{width: '100%', marginTop: '10px'}}>
                <TextField 
                  style={{width: '70%'}} 
                  id="input-area-name" 
                  label="Area Name" 
                  defaultValue="Jakarta" 
                  variant="standard" 

                  onChange={this.handleInputArea}
                />
                <Button 
                  style={{marginLeft: '28px', padding: '10px 30px'}} 
                  variant="contained" 
                  disableElevation

                  onClick={this.handleButtonFilterLeft}
                >
                  Submit
                </Button>
              </div>
          </div>
        </div>
        )}
        <iframe style={{height: '100%', marginLeft:'-5%', filter: 'invert(90%) hue-rotate(180deg)'}}
          key={this.state.iframeRandomKey}
          id="iframe"
          sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-top-navigation-by-user-activation"
          src={this.state.urlIframe}

          // src="https://besttime.app/api/v1/radar/filter?q=restaurant+in+bandung&map_lat=-6.89&map_lng=107.61&lat_min=-6.926698&lat_max=-6.8583723&lng_min=107.5845435&lng_max=107.638605&map_z=13&api_key_private=pri_1cbe7061e77d44db93bbbbe7285d1e51&busy_conf=any&live_refresh=False&lat=-6.89&lng=107.61&radius=27304"
          headers={{
            "X-Frame-Options": "allow-from *",
            "Access-Control-Allow-Origin": "*",
            // Cookie: "_ga_T0V3N321G9=GS1.1.1684627752.4.1.1684629006.0.0.0; _ga=GA1.1.2145395581.1684596817; _gid=GA1.2.784855966.1684596818; session=.eJwljktqBDEMBe_idRa29bPmMo1sScwQSKB7ZhVy93HItni8qp9y5BnXvdye5ys-yvHwcivoKVqnJc2qGDVbb1VsAROhGAAGsQoqQdaEDns2giWzRWNTYBjZ3bxxQCpFcyTvvVfrEVV8ckokxsq6VLmpJ5gwQB2OmmWHvK44_2uos2yyrjOP5_dnfP2xiNVYgoAdwcxivw-TUZO2KnM6ThAqv29ceUAG.ZGliBA.QUZNWo1IBGAwRWuFDalSoPMGbZ8; __cf_bm=9QLy0S6kToEE946CkfBCNT_jPQ0snF_v52T4tJJvUMg-1684627892-0-AbdPTJcYeuGQRt2JobDKOR6dPGhMMXjZgglB/9ziUmWOw0vgAvzEKXTPO85Sl+nBuqfgnbeRnfgltQIXu8lFink=; __stripe_sid=406afede-2211-47d0-8b1d-df20105c9c139b3221; __stripe_mid=75a96292-21dc-46ff-bd29-70476e4f9e29eb36b7; _gat_gtag_UA_165301827_1=1; SL_C_23361dd035530_SID=fZqe5vyIWMdYdeECtqN2_; SL_C_23361dd035530_VID=qQ6Ame0ZDQpWOcUASvwX8; SL_C_23361dd035530_KEY=c2f6c086696e911cee1fe4f798984693aa9c95c2"
          }}

          onLoad={this.handleFrameElement}
          />
        <div style={{height: '100%', overflowY: 'scroll', width: '390px', padding: '20px', background: '#282828', position:'absolute', top: '66px', right: '0%', zIndex: '99'}}>
          <SidePanelDemography region={this.state.region} urlTrend={this.state.urlTrend} trends={this.state.trends} />
        </div>
        {isLoading && (
          <Modal className="text-center"
          // style={{zIndex: '999999999999'}} 
            isOpen={isLoading}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={modalStyles}
          >
            <span style={{position:'relative', top:'30%', verticalAlign: 'middle'}}>
              <img style={{height: '100px'}} src={loading} alt="loading" />
              <h4>Sync real-time Footprint</h4>
              <h3>Please wait a minute...</h3>
            </span>
            
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

// const mapStateToProps = state => ({
//   map: state.map,
//   turnout: state.map.turnout
// });

// const mapDispatchToProps = {
  
// };
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(OverviewCrowd);

export default OverviewCrowd;
