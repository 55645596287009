import React, { Fragment } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { closeModal } from "actions";
import { getSocialMediaPost } from "actions/chartActions";
import { Container, Row, Col } from "reactstrap";
import loading from "media/img/components/loading.gif";
import ReactPaginate from "react-paginate";
// import normalize from 'normalize-text';

ReactModal.setAppElement("#root");

const limitPost = [5, 10, 25, 50, 100, 250];

class ModalTopic extends React.Component {
  state = {
    page: 1,
    limit: 5
  };

  handlePageClick = data => {
    let selected = data.selected + 1;

    this.setState({ ...this.state, page: selected });
  };

  handleLimitClick = limit => {
    this.setState({
      page: 1,
      limit
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.modal !== this.props.modal && this.props.modal.showTopic) {
      this.props.getSocialMediaPost(this.props.modal.data.type, this.props.modal.data.path, this.props.modal.data.params, 1, 5);
    } else if (prevState !== this.state) {
      this.props.getSocialMediaPost(
        this.props.modal.data.type, 
        this.props.modal.data.path,
        this.props.modal.data.params,
        this.state.page,
        this.state.limit
      );
    }
  }
  render() {
    const { modal, posts, isLoading } = this.props;
    const { page, limit } = this.state;
    return (
      <ReactModal
        isOpen={modal.showTopic}
        contentLabel="Modal Topic"
        className="modalTopic"
      >
        <span
          className="button-close-diagonal"
          onClick={e => {
            e.preventDefault();
            this.props.closeModal();
          }}
        >
          <i className="icon dripicons-cross" />
        </span>
        <Container>
          <Row className="topic-head">
            <Col md="6">
              <span className="topic-head-small">RESULTS</span>
              <span className="topic-head-big">
                {posts ? posts.totalElements : 0}
              </span>
              <span className="topic-head-small">SHOW</span>
              <ul className="topic-head-number">
                {limitPost.map((d, i) => (
                  <li
                    key={i}
                    className={limit === d ? "active" : ""}
                    onClick={() => this.handleLimitClick(d)}
                  >
                    <span>{d}</span>
                  </li>
                ))}
              </ul>
            </Col>
            <Col md="6" className="text-right">
              <span className="topic-head-small">PAGE</span>

              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={posts ? posts.totalPages - 1 : 0}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={data => {
                  this.setState({ page: data.selected + 1 });
                }}
                containerClassName={"topic pagination"}
                subContainerClassName={"pages pagination"}
                pageClassName={"topic-page"}
                activeLinkClassName={"active"}
                forcePage={page - 1}
              />
            </Col>
          </Row>
        </Container>
        <Container className="modal-topic-content">
          {isLoading && (
            <Row>
              <Col className="text-center">
                <img src={loading} alt="loading" />
              </Col>
            </Row>
          )}
          {posts &&
            !isLoading &&
            posts.content.map((d, i) => (
              <Row className="topic-list" key={i}>
                <Col md="2" className="topic-image">
                  <img
                    src={`http://${d.sourceLinkName}/favicon.ico`}
                    className="post-favicon"
                    alt="post-favicon"
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src =
                        process.env.PUBLIC_URL +
                        "/img/avatar/external-links.jpg";
                    }}
                  />
                  <img
                    src={
                      d.userAvatar
                        ? d.userAvatar
                        : process.env.PUBLIC_URL + "/img/avatar/news.jpg"
                    }
                    alt={d.username}
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src =
                        process.env.PUBLIC_URL + "/img/avatar/news.jpg";
                    }}
                  />
                </Col>
                <Col md="10">
                  <Row>
                    <Col>
                      <a href={d.sourceLink ? d.sourceLink : "#"}  target="_blank">
                        {d.username ? d.username : d.sourceName}
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{decodeURIComponent(escape(d.content.substr(0,350) + '...' ))}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <a href={d.sourceLink} target="_blank">Published on {d.published}</a> |{" "}
                      {d.country} |{" "}
                      <a href={d.sourceLink} target="_blank">{d.sourceLinkName}</a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          {!posts && !isLoading && (
            <h4 className="text-center">No data found</h4>
          )}
        </Container>
      </ReactModal>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.modal,
  posts: state.chart.posts,
  isLoading: state.chart.isLoading
});

const mapDispatchToProps = {
  closeModal,
  getSocialMediaPost
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalTopic);
