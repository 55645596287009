import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  clearPOI,
  getLegends,
  getProvince
} from "actions/mapActions";
import { reAuth } from "actions/authActions";

import "styles/components/map-submenu.css";
import genderImage from "media/img/icons/gender-white.png";
import religionImage from "media/img/icons/religion-white.png";

class Sidebar extends Component {
  state = {
    openMap: true,
    openGender: false,
    openAge: false,
    openReligion: false,
    openSource: true,
    openEducation: false,
    openSES: false,
    openPOI: false,
    isSubOpen: 0,
    isPOISubOpen: 0,
    isAllOpen: false,
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [] },
      kabupaten: { value: null, data: [] },
      kecamatan: { value: null, data: [] },
      step: 0
    },
    reqParams: {
      gender: { value: "all", filter: "" },
      age: { value: "all", filter: "" },
      pendidikan: { value: "all", filter: "" },
      ses: { value: "all", filter: "" },
      agama: { value: "all", filter: "" }
    }
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        isPOISubOpen: submenu
      });
    }
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        isSubOpen: 0
      });
    } else {
      this.setState({
        isSubOpen: submenu,
        openGender: false,
        openAge: false,
        openReligion: false,
        openSource: false,
        openEducation: false,
        openSES: false,
        openPOI: false
      });
    }
  };

  handleAddParams = (name, value) => {
    if (value !== "all") {
      this.setState({
        ...this.state,
        reqParams: {
          ...this.state.reqParams,
          [name]: { value, filter: `&${name}=${value}` }
        }
      });
    } else {
      this.setState({
        ...this.state,
        reqParams: {
          ...this.state.reqParams,
          [name]: { value, filter: "" }
        }
      });
    }
  };

  handleSubmitDPT = () => {
    const params =
      this.state.reqParams.gender.filter +
      this.state.reqParams.age.filter +
      this.state.reqParams.agama.filter +
      this.state.reqParams.pendidikan.filter +
      this.state.reqParams.ses.filter;
    this.props.getProvince("?source=dpt", params);
  };

  handleToggleInfo = (info, data, type) => {
    info === data
      ? this.props.clearInfo(type)
      : this.props.setInfo(type, null, data);
  };

  handleGetInfo = (info, data, type, legend = false) => {
    legend
      ? info === data
        ? this.props.clearInfo(`${type}`)
        : this.props.getLegends(type, data)
      : this.props.getInfo(type, data);
  };

  // handleTogglePOI = (info, type, url) => {
  //   info === type ? this.handleClearPOI() : this.props.getPOI(type, url);
  // };

  // handleClearPOI = () => {
  //   this.props.clearPOI();
  //   this.props.clearInfo("subpoi");
  //   this.setState({
  //     ...this.state,
  //     isPOISubOpen: 0
  //   });
  // };

  // handleGetSubMenu = (parentID, target, parentName, parent, step) => {
  //   if (parent !== "kecamatan") {
  //     GET(`${API_HOST}/sental/master/${parentID}/${target}`)
  //       .then(res =>
  //         this.setState({
  //           ...this.state,
  //           isSubOpen: 0,
  //           region: {
  //             ...this.state.region,
  //             [parent]: { ...this.state.region[parent], value: parentName },
  //             [target]: { value: null, data: res.data },
  //             step
  //           }
  //         })
  //       )
  //       .catch(err => {
  //         err.response.status === 401
  //           ? this.props.reAuth()
  //           : toastr.error(err.response.statusText);
  //       });
  //   } else {
  //     this.setState({
  //       ...this.state,
  //       isSubOpen: 0,
  //       region: {
  //         ...this.state.region,
  //         [parent]: { ...this.state.region[parent], value: parentName },
  //         step: 4
  //       }
  //     });
  //   }
  // };

  // componentDidMount() {
  //   // fetch pulau on mount component, then setState to region
  //   GET(API_HOST + "/sental/master/pulau").then(res =>
  //     this.setState({
  //       ...this.state,
  //       region: {
  //         ...this.state.region,
  //         pulau: { ...this.state.region.pulau, data: res.data },
  //         step: 1
  //       }
  //     })
  //   ).catch(err => {
  //     err.response.status === 401
  //       ? this.props.reAuth()
  //       : toastr.error(err.response.statusText);
  //   });
  // }

  componentDidUpdate() {
    if (
      this.props.info.age === null &&
      this.props.info.pendidikan === null &&
      this.props.info.gender === null &&
      this.props.info.location === null &&
      this.props.info.agama === null &&
      this.props.info.ses === null &&
      this.props.info.source === null
    ) {
      this.props.handleClose();
    } else {
      this.props.handleOpen();
    }
  }

  render() {
    const { info } = this.props;
    const {
      openMap,
      openGender,
      openAge,
      openReligion,
      openSource,
      openEducation,
      openSES,
      openPOI,
      isAllOpen,
      region,
      reqParams
    } = this.state;
    const {
      handleToggleSubMenu,
      handleToggleInfo,
      handleGetSubMenu,
      handleGetInfo,
      handleAddParams,
      handleSubmitDPT
    } = this;
    const gender = [
        { name: "Pria", filter: "male" },
        { name: "Wanita", filter: "female" },
        { name: "Semua", filter: "all" }
      ],
      age = [
        { name: "17-22", filter: "17-22" },
        { name: "23-29", filter: "23-29" },
        { name: "30-39", filter: "30-39" },
        { name: "40-49", filter: "40-49" },
        { name: "50-59", filter: "50-59" },
        { name: "60+", filter: "60" },
        { name: "Semua", filter: "all" }
      ],
      agama = [
        { name: "Islam", filter: "islam" },
        { name: "Protestan", filter: "protestan" },
        { name: "Katolik", filter: "katolik" },
        { name: "Buddha", filter: "buddha" },
        { name: "Kong Hu Cu", filter: "konghucu" },
        { name: "Lainnya", filter: "others" },
        { name: "Semua", filter: "all" }
      ],
      // source = ["JOKOWI 2019","ET BASELINE 12%"],
      source = ["DEMOGRAPHY"],
      pendidikan = [
        { name: "SD", filter: "sd" },
        { name: "SMP", filter: "smp" },
        { name: "SMA", filter: "sma" },
        { name: "Sarjana", filter: "sarjana" },
        { name: "Semua", filter: "all" }
      ],
      ses = [
        { name: "Kelas 1", filter: "class-a" },
        { name: "Kelas 2", filter: "class-b" },
        { name: "Kelas 3", filter: "class-c" },
        { name: "Kelas 4", filter: "class-d" },
        { name: "Semua", filter: "all" }
      ];

    // console.log(
    //   reqParams.gender.filter +
    //     reqParams.age.filter +
    //     reqParams.ses.filter +
    //     reqParams.pendidikan.filter +
    //     reqParams.agama.filter
    // );

    return (
      <div className="sidebar sidebar-left">
        <div className="sidebar-content">
          <nav className="main-menu">
            <ul className="nav">
              {/* <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openMap
                      ? this.setState({ openMap: false })
                      : this.setState({ openMap: true, isSubOpen: 0 });
                  }}
                >
                  <i className="icon dripicons-map" />
                </a>
                <SubmenuMap
                  handleToggleInfo={handleToggleInfo}
                  handleGetSubMenu={handleGetSubMenu}
                  data={{
                    info,
                    handleToggleSubMenu,
                    openMap,
                    isSubOpen,
                    region
                  }}
                />
              </li> */}
              <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openSource
                      ? this.setState({ openSource: false })
                      : this.setState({ openSource: true, isSubOpen: 0 });
                  }}
                >
                  <i className="icon dripicons-stack" />
                </a>
                <div className={`map-submenu ${openSource ? "sub-open" : ""}`}>
                  <ul className="submenu-map map-select">
                    {source.map((data, key) => (
                      <li key={key}>
                        <a
                          href="#"
                          className={info.source.info === data ? "active" : ""}
                          onClick={e => {
                            e.preventDefault();
                            handleToggleInfo(info.source, data, "source");
                            // if(data=='DPT') {
                            //   console.log("total DPT .... ");
                            // }
                          }}
                        >
                          {data}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openGender
                      ? this.setState({ openGender: false })
                      : this.setState({ openGender: true, isSubOpen: 0 });
                  }}
                >
                  <img src={genderImage} alt="gender" />
                </a>
                <div className={`map-submenu ${openGender ? "sub-open" : ""}`}>
                  <ul className="submenu-map">
                    {gender.map((data, key) => (
                      <li key={key}>
                        <a
                          href="#"
                          className={
                            info.gender.info === data.filter ? "active" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            // handleToggleInfo(info.gender, data, "gender");
                            handleGetInfo(
                              info.gender.info,
                              data.filter,
                              "gender"
                            );
                            handleAddParams("gender", data.filter);
                          }}
                        >
                          {data.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openAge
                      ? this.setState({ openAge: false })
                      : this.setState({ openAge: true, isSubOpen: 0 });
                  }}
                >
                  <i className="icon dripicons-hourglass" />
                </a>
                <div className={`map-submenu ${openAge ? "sub-open" : ""}`}>
                  <ul className="submenu-map">
                    {age.map((data, key) => (
                      <li key={key}>
                        <a
                          href="#"
                          className={
                            info.age.info === data.filter ? "active" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            handleGetInfo(
                              info.age.info,
                              data.filter,
                              "age",
                              true
                            );
                            handleAddParams("age", data.filter);
                          }}
                        >
                          {data.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openReligion
                      ? this.setState({ openReligion: false })
                      : this.setState({ openReligion: true, isSubOpen: 0 });
                  }}
                >
                  <img src={religionImage} alt="religion" />
                </a>
                <div
                  className={`map-submenu ${openReligion ? "sub-open" : ""}`}
                >
                  <ul className="submenu-map">
                    {agama.map((data, key) => (
                      <li key={key}>
                        <a
                          href="#"
                          className={
                            info.agama.info === data.filter ? "active" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            handleGetInfo(
                              info.agama.info,
                              data.filter,
                              "agama",
                              true
                            );
                            handleAddParams("agama", data.filter);
                          }}
                        >
                          {data.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openEducation
                      ? this.setState({ openEducation: false })
                      : this.setState({ openEducation: true, isSubOpen: 0 });
                  }}
                >
                  <i className="icon dripicons-graduation" />
                </a>
                <div
                  className={`map-submenu ${openEducation ? "sub-open" : ""}`}
                >
                  <ul>
                    {pendidikan.map((data, key) => (
                      <li key={key}>
                        <a
                          href="#"
                          className={
                            info.pendidikan.info === data.filter ? "active" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            handleGetInfo(
                              info.pendidikan.info,
                              data.filter,
                              "pendidikan",
                              true
                            );
                            handleAddParams("pendidikan", data.filter);
                          }}
                        >
                          {data.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openSES
                      ? this.setState({ openSES: false })
                      : this.setState({ openSES: true, isSubOpen: 0 });
                  }}
                >
                  <i className="icon dripicons-card" />
                </a>
                <div className={`map-submenu ${openSES ? "sub-open" : ""}`}>
                  <ul className="submenu-map">
                    {ses.map((data, key) => (
                      <li key={key}>
                        <a
                          href="#"
                          className={
                            info.ses.info === data.filter ? "active" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            handleGetInfo(
                              info.ses.info,
                              data.filter,
                              "ses",
                              true
                            );
                            handleAddParams("ses", data.filter);
                          }}
                        >
                          {data.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              {/* <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openPOI
                      ? this.setState({ openPOI: false })
                      : this.setState({ openPOI: true, isSubOpen: 0 });
                  }}
                >
                  <i className="icon dripicons-location" />
                </a>
                <div className={`map-submenu ${openPOI ? "sub-open" : ""}`}>
                  <ul className="submenu-map map-select">
                    {poiCat.map((data, key) => (
                      <li key={key}>
                        <a
                          href="#"
                          className="dropdown-toggle"
                          onClick={e => {
                            e.preventDefault();
                            handleTogglePOISubMenu(key + 1);
                          }}
                        >
                          {data.cat}
                        </a>
                        <ul
                          className={`submenu-children ${
                            isPOISubOpen === key + 1 ? "sub-child-open" : ""
                          }`}
                        >
                          {data.child.map((d, key) => (
                            <li key={key}>
                              <a
                                href="#"
                                className={
                                  info.subpoi.info === d.type ? "active" : ""
                                }
                                onClick={e => {
                                  e.preventDefault();
                                  handleToggleInfo(
                                    info.subpoi.info,
                                    d.type,
                                    "subpoi"
                                  );
                                  handleTogglePOI(
                                    info.subpoi.info,
                                    d.type,
                                    d.url
                                  );
                                }}
                              >
                                {d.type}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                    <li className="map-select-short">
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          handleClearPOI();
                        }}
                      >
                        <i className="zmdi zmdi-eye-off" />
                      </a>
                    </li>
                  </ul>
                </div>
              </li> */}
              <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openPOI &&
                    openAge &&
                    openEducation &&
                    openGender &&
                    openSES &&
                    openReligion &&
                    openSource
                      ? this.setState({
                          openPOI: false,
                          openAge: false,
                          openEducation: false,
                          openGender: false,
                          openReligion: false,
                          openSource: false,
                          openSES: false,
                          isAllOpen: false
                        })
                      : this.setState({
                          openPOI: true,
                          openAge: true,
                          openSES: true,
                          openEducation: true,
                          openGender: true,
                          openReligion: true,
                          openSource: true,
                          isAllOpen: true,
                          isSubOpen: 0
                        });
                  }}
                >
                  <i
                    className={`zmdi ${
                      isAllOpen ? "zmdi-eye-off" : "zmdi-eye"
                    }`}
                  />
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    handleToggleInfo(info.source, "ET BASELINE 12%", "source");
                    handleSubmitDPT();
                  }}
                >
                  <i className={`zmdi zmdi-search`} />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  clearPOI,
  getLegends,
  reAuth,
  getProvince
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
