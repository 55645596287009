import React, { Component, Fragment } from "react";
import {
  VictoryChart,
  VictoryTooltip,
  VictoryLine,
  Curve,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryLabel
} from "victory";
import loading from "media/img/components/loading.gif";
import { Row, Col } from "reactstrap";

class ShiftingLabel extends Component {

  render() {
    const { text, index } = this.props
    return (
      <g>
        <VictoryLabel
          {...this.props}
          text={text}
          dx={index == 0 ? -15 : 15}
          dy={15}
          style={{fill:'white', fontSize: 12, textAnchor: index == 0 ? 'end' : 'start'}}
        />
      </g>
    )
  }
}

class Arrow extends Component {
  render() {
    const { pathColor, pathKey } = this.props;
    return (
      <g>
        <defs>
          <marker
            id={`arrow-right-${pathKey}`}
            markerWidth="10"
            markerHeight="10"
            refX="0"
            refY="3"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,6 L5,3 z" fill={pathColor.rightSideColor} />
          </marker>
          <marker
            id={`arrow-left-${pathKey}`}
            markerWidth="10"
            markerHeight="10"
            refX="0"
            refY="3"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,6 L5,3 z" fill={pathColor.leftSideColor} />
          </marker>
        </defs>
        <Curve
          {...this.props}
          pathComponent={
            <path
              markerStart={
                this.props.direction === "left"
                  ? `url(#arrow-left-${pathKey})`
                  : ""
              }
              markerEnd={
                this.props.direction === "right"
                  ? `url(#arrow-right-${pathKey})`
                  : ""
              }
            />
          }
        />
      </g>
    );
  }
}

class ShiftingChart extends Component {
  render() {
    const {data} = this.props;
    if(data){
    return (
      <Fragment>
        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient id="myGradient" gradientUnits="userSpaceOnUse">
              <stop offset="0%" stopColor="rgba(27, 120, 191,1)" />
              <stop offset="55%" stopColor="rgba(27, 120, 191,0.5)" />
              <stop offset="85%" stopColor="rgba(208,2,27,0.5)" />
              <stop offset="100%" stopColor="rgba(208,2,27,1)" />
              {/* <stop offset="0%" stopColor="rgba(208,2,27,1)" />
              <stop offset="55%" stopColor="rgba(208,2,27,0.5)" />
              <stop offset="85%" stopColor="rgba(17,80,128,0.5)" />
              <stop offset="100%" stopColor="rgba(17,80,128,1)" /> */}
            </linearGradient>
          </defs>
        </svg>
        <VictoryChart
          height={700}
          domain={{ x: [-50, 150], y: [0, 34] }}
          domainPadding={20}
          padding={{ top: 0 }}
          margin={{ top: 0 }}
          animate={{duration: 1000}}
        >
          {data.map((d, i) => {
            const leftSide = d.x < d.y ? d.x : d.y;
            const leftSideColor =
              leftSide <= 50
                ? `rgba(27, 120, 191,${1 - leftSide / 100})`
                : `rgba(208,2,27,${leftSide / 100})`;
            const rightSide = d.x < d.y ? d.y : d.x;
            const rightSideColor =
              rightSide <= 50
                ? `rgba(17,80,128,${1 - rightSide / 100})`
                : `rgba(208,2,27,${rightSide / 100})`;
            const data = [
              {
                x: d.x,
                y: i + 1,
                label: d.label,
                symbol: "circle"
              },
              { x: d.y, y: i + 1, label: `${Math.abs(d.x - d.y)} %` }
            ];
            const margin = d.x - d.y;

            return (
              <VictoryLine
                dataComponent={
                  <Arrow
                    pathKey={i}
                    pathColor={{ leftSideColor, rightSideColor }}
                    direction={margin > 0 ? "left" : "right"}
                  />
                }
                style={{ data: { stroke: "url(#myGradient)", strokeWidth: 2 } }}
                key={i}
                data={data}
                labelComponent={<ShiftingLabel />}
              />
            );
          })}
          <VictoryAxis
            tickValues={[50]}
            style={{ axis: { stroke: "none" }, grid: { stroke: "white" }, tickLabels:{fill: 'none'} }}
          />
        </VictoryChart>
      </Fragment>
    );       
  }else{
    return (
      <Row>
        <Col className="text-center">
          <img src={loading} alt="loading" />
        </Col>
      </Row>
    );
  }
  }
}

export default ShiftingChart;
