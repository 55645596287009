import React, { Component } from "react";
import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryLabel
} from "victory";
import CustomLabel from "./CustomLabel";

const height = 250;

class StackedBarCenterAxis extends Component {
  state = {
    dataA: [],
    dataB: [],
    maxValue: 100000
  };

  componentDidMount() {
    let dataA = [],
      dataB = [],
      maxValue = this.props.age.max_value;
    this.props.age.data.map(d => {
      dataA.push({
        x: d.order_name,
        y: d.left_data,
        label: `${d.left_label} Usia ${
          d.order_name
        } \n ${d.left_data.toLocaleString()} Jiwa`
      });
      dataB.push({
        x: d.order_name,
        y: d.right_data,
        label: `${d.right_label} Usia ${
          d.order_name
        } \n ${d.right_data.toLocaleString()} Jiwa`
      });
    });

    this.setState({
      dataA,
      dataB,
      maxValue
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.age.data !== this.props.age.data) {
      let dataA = [],
        dataB = [],
        maxValue = this.props.age.max_value;
      this.props.age.data.map(d => {
        dataA.push({
          x: d.order_name,
          y: d.left_data,
          label: `${d.left_label} Usia ${
            d.order_name
          } \n ${d.left_data.toLocaleString()} Jiwa`
        });
        dataB.push({
          x: d.order_name,
          y: d.right_data,
          label: `${d.right_label} Usia ${
            d.order_name
          } \n ${d.right_data.toLocaleString()} Jiwa`
        });
      });

      this.setState({
        dataA,
        dataB,
        maxValue
      });
    }
  }

  render() {
    const { dataA, dataB, maxValue } = this.state;

    return (
      <VictoryChart animate={{duration: 1000}}>
        <VictoryStack
          horizontal
          standalone={false}
          /* setting a symmetric domain makes it much easier to center the axis  */
          domain={{ x: [-maxValue, maxValue] }}
          padding={0}
          height={height}
          width={450}
          style={{
            data: { width: 25 },
            labels: { fontSize: 22, fontWeight: 500 }
          }}
        >
          <VictoryBar
            style={{ data: { fill: "#00de78" } }}
            data={dataA}
            y={data => -Math.abs(data.y)}
            labelComponent={<CustomLabel />}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => ({
                          style: { fill: "rgba(255,255,255,0.2)", width: 25 }
                        })
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: true })
                      }
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => {}
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: false })
                      }
                    ];
                  }
                }
              }
            ]}
          />
          <VictoryBar
            style={{ data: { fill: "#ff5607" } }}
            data={dataB}
            labelComponent={<CustomLabel />}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => ({
                          style: { fill: "rgba(255,255,255,0.2)", width: 25 }
                        })
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: true })
                      }
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => {}
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: false })
                      }
                    ];
                  }
                }
              }
            ]}
          />
        </VictoryStack>

        <VictoryAxis
          dependentAxis
          height={height}
          width={450}
          padding={0}
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "#121212", fontWeight: "bold" }
          }}
          /*
        Use a custom tickLabelComponent with
        an absolutely positioned x value to position
        your tick labels in the center of the chart. The correct
        y values are still provided by VictoryAxis for each tick
      */
          tickLabelComponent={<VictoryLabel x={225} textAnchor="middle" />}
          tickValues={dataA.map(point => point.x).reverse()}
        />
      </VictoryChart>
    );
  }
}

export default StackedBarCenterAxis;
