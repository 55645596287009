import React, { Fragment } from "react";
import { connect } from "react-redux";
import "styles/components/map-info.css";
import StackedBarCenterAxis from "components/shared/charts/StackedBarCenterAxis";
import PieChart from "components/shared/charts/PieChart";
import NumberEasing from "che-react-number-easing";
import SESPie from "components/shared/charts/SESPie";
import Bar100Horizontal from "components/shared/charts/Bar100Horizontal";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import SidePanel from "./SidePanel";

import { Card, ButtonGroup, Button, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const sesColorScale =[
  "#007b4a",
  "#ffaa55",
  "#9335f3",
  "#0097a5",
  "#a91922"
]

let dropdownOpen = false;

const CustomTab = withStyles({
  root: {
    backgroundColor: '#282828',
    textTransform: 'capitalize'
  },
  selected: {
    backgroundColor: '#FF8355',
  },
})(Tab);

const MapStackedInfo = props => (
  <Fragment>
    <div className={`map-info-stack ${props.show ? "active" : ""}`}>
      <span
        className="button-close-diagonal"
        onClick={() => props.handleToggle()}
      >
        <i
          className={`icon dripicons-${props.show ? "cross" : "chevron-left"}`}
        />
      </span>
      <div className={`stack-item ${props.info.dpt !== null ? "active" : ""}`}>
        {(()=>{
          // // const total_turnout = props.info.province.total_dpt;
          // if(props.info.source.info == 'DPT') {
          //   // props.info.province.total_dpt = 192866254;
          //   return <h4>TOTAL JUMLAH DPT DI INDONESIA</h4>
          // } else {
          //   // props.info.province.total_dpt = total_turnout;
          //   return <h4>TOTAL TURNOUT MEMILIH di INDONESIA</h4>
          // }

          return <h4>TOTAL JUMLAH DPT DI INDONESIA</h4>
        }
        )()}
        <span className="stack-total-penduduk red">
          <NumberEasing
            // value={props.info.source.info == 'DPT' ? 192866254 : (props.info.province.total_dpt ? props.info.province.total_dpt : 0 ) }
            value={204807222}
            speed={3000}
            ease="quintInOut"
            useLocaleString={true}
            precision={0}
          />
        </span>
        {/* <span className="stack-total-penduduk">+ </span> */}
        <span className="stack-small"> Jiwa</span>
      </div>
      
      <SidePanel />
    </div>
  </Fragment>
);

const mapStateToProps = state => ({
  info: state.map
});

export default connect(mapStateToProps)(MapStackedInfo);
