import React, { Fragment, Component } from "react";

import Sidebar from "components/layout/sidebar/Sidebar";
import Map from "components/shared/map/Map";
import MapStackedInfo from "./MapStackedInfo";
import ModalDialog from "components/shared/ModalDialog";

class Mapping extends Component {
  state = {
    isInfoActive: true
  };

  handleToggleInfo = () => {
    this.state.isInfoActive === true
      ? this.setState({ isInfoActive: false })
      : this.setState({ isInfoActive: true });
  };

  handleCloseInfo = () => {
    this.setState({ isInfoActive: false });
  };

  handleOpenInfo = () => {
    this.setState({ isInfoActive: true });
  };

  render() {
    const { isActive, isInfoActive } = this.state;
    const { handleToggleInfo, handleCloseInfo, handleOpenInfo } = this;
    return (
      <Fragment>
        <Sidebar handleClose={handleCloseInfo} handleOpen={handleOpenInfo} />
        <Map />
        <MapStackedInfo
          show={isInfoActive}
          isActive={isActive}
          handleToggle={handleToggleInfo}
        />
        <ModalDialog />
      </Fragment>
    );
  }
}

export default Mapping;
