import React, { PureComponent } from "react";
import WordCloud from "react-d3-cloud";
import { GET } from "API";
import loading from "media/img/components/loading.gif";
import { Row, Col } from "reactstrap";

const API_HOST = process.env.REACT_APP_API_HOST,
  API_DIR = process.env.REACT_APP_API_DIR;

const fontSizeMapper = word => Math.log2(word.value) * 5;

class TopicCloud extends PureComponent {
  state = {
    weight: 500,
    data: [],
    isLoading: true
  };

  handleHover = () => {
    this.setState({
      weight: 700
    });
  };

  componentDidMount() {
    // GET(
    //   `${API_HOST}/${API_DIR}/whatson/wordcloud?candidate=${
    //     this.props.candidate
    //   }`
    // ).then(res => {
    //   this.setState({
    //     data: res.data.data,
    //     isLoading: false
    //   });
    // });
    this.setState({
      data: [{'text': 'vaksin covid', 'value': 136},
      {'text': 'vaksin dan', 'value': 4},
      {'text': 'dan vaksin', 'value': 647},
      {'text': 'kasus covid', 'value': 501},
      {'text': 'vaksinasi covid', 'value': 430},
      {'text': 'positif covid', 'value': 645},
      {'text': 'update covid', 'value': 18},
      {'text': 'terkait covid', 'value': 410},
      {'text': 'kematian covid', 'value': 271},
      {'text': 'vaksin itu', 'value': 598},
      {'text': 'setelah covid', 'value': 501},
      {'text': 'satgas covid', 'value': 994},
      {'text': 'penanganan covid', 'value': 367},
      {'text': 'melonjaknya covid', 'value': 497},
      {'text': 'terpapar covid', 'value': 839},
      {'text': 'terdampak covid', 'value': 413},
      {'text': 'penyintas covid', 'value': 632},
      {'text': 'penyeberan covid', 'value': 696},
      {'text': 'penyakit covid', 'value': 91},
      {'text': 'penularan covid', 'value': 552},
      {'text': 'pasien covid', 'value': 263},
      {'text': 'pandemi covid', 'value': 760},
      {'text': 'menghadapi covid', 'value': 333},
      {'text': 'mengenal covid', 'value': 626},
      {'text': 'lonjakan covid', 'value': 988},
      {'text': 'lawan covid', 'value': 743},
      {'text': 'kendalikan covid', 'value': 478},
      {'text': 'insyaallah covid', 'value': 34},
      {'text': 'infeksi covid', 'value': 742},
      {'text': 'dampak covid', 'value': 527},
      {'text': 'atasi covid', 'value': 568},
      {'text': 'alat covid', 'value': 974},
      {'text': 'vaksin pfizer', 'value': 98},
      {'text': 'covid', 'value': 16},
      {'text': 'vaksin china', 'value': 946},
      {'text': 'vaksin siap', 'value': 508},
      {'text': 'vaksin corona', 'value': 899},
      {'text': 'vaksin arab', 'value': 449},
      {'text': 'vaksin sinovac', 'value': 518},
      {'text': 'dan china', 'value': 362},
      {'text': 'dan bpom', 'value': 60},
      {'text': 'vaksin amerika', 'value': 765},
      {'text': 'sentimen vaksin', 'value': 399},
      {'text': 'saham asia', 'value': 914},
      {'text': 'puan mengatakan', 'value': 32},
      {'text': 'protokol kesehatan', 'value': 275},
      {'text': 'pfizer 2529', 'value': 428},
      {'text': 'order', 'value': 709},
      {'text': 'menurut wiku', 'value': 171},
      {'text': 'menurut khofifah', 'value': 531},
      {'text': 'menurut dani', 'value': 27},
      {'text': 'menurut astrid', 'value': 628},
      {'text': 'menteri bumn', 'value': 574},
      {'text': 'melonjaknya saham', 'value': 418},
      {'text': 'maskapai juga', 'value': 883},
      {'text': 'respons bpom', 'value': 359},
      {'text': 'republika tags', 'value': 194},
      {'text': 'pfizer', 'value': 238},
      {'text': 'pemerintah tak', 'value': 847},
      {'text': 'pada sabtu', 'value': 308},
      {'text': 'pada jumat', 'value': 529},
      {'text': 'namun wajib', 'value': 45},
      {'text': 'ketergantungan china', 'value': 790}],
      isLoading: false
    })
  }

  render() {
    const { data, isLoading } = this.state;
    if (isLoading) {
      return (
        <Row>
          <Col
            style={{
              width: 750,
              height: 450,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img src={loading} alt="loading" />
          </Col>
        </Row>
      );
    } else {
      return (
        <WordCloud
          data={data}
          width={750}
          font="Impact"
          padding={0.5}
          height={450}
          weight={500}
          onWordClick={word => {}}
          // onWordClick={word => this.props.handleHashtagClick(word.text)}
          fontSizeMapper={fontSizeMapper}
        />
      );
    }
  }
}

export default TopicCloud;
