import React, { PureComponent } from "react";
import {
  VictoryChart,
  VictoryGroup,
  VictoryTooltip,
  VictoryLine,
  VictoryScatter,
  VictoryAxis
} from "victory";

class LineDot extends PureComponent {
  render() {
    return (
      <VictoryChart height={200} width={600} animate={{duration: 1000}}>
        <VictoryGroup
          color={this.props.color.color1}
          labels={d => `${d.y}%`}
          labelComponent={<VictoryTooltip style={{ fontSize: 22 }} />}
          data={this.props.data[0]}
        >
          <VictoryLine />
          <VictoryScatter size={window.innerWidth < 1920 ? 5 : 2} />
        </VictoryGroup>
        {this.props.type === "predictive" && (
          <VictoryGroup
            color={this.props.color.color2}
            labels={d => `${d.y}%`}
            labelComponent={<VictoryTooltip style={{ fontSize: 22 }} />}
            data={this.props.data[1]}
          >
            <VictoryLine />
            <VictoryScatter size={window.innerWidth < 1920 ? 5 : 2} />
          </VictoryGroup>
        )}
        <VictoryAxis tickValues={[""]} style={{ axis: { stroke: "none" } }} />
      </VictoryChart>
    );
  }
}

export default LineDot;
