const dataWordCloud1 = [
  {
    "text": "erick thohir",
    "value": "241"
  },
  {
    "text": "menteri bumn",
    "value": "140"
  },
  {
    "text": "bumn erick",
    "value": "130"
  },
  {
    "text": "garuda indonesia",
    "value": "104"
  },
  {
    "text": "motor listrik",
    "value": "60"
  },
  {
    "text": "thohir apresiasi",
    "value": "55"
  },
  {
    "text": "evakuasi wni",
    "value": "46"
  },
  {
    "text": "dihasilkan generasi",
    "value": "43"
  },
  {
    "text": "jembatan nusantara",
    "value": "39"
  },
  {
    "text": "menjalankan misi",
    "value": "38"
  },
  {
    "text": "menyambut positif",
    "value": "36"
  },
  {
    "text": "pt jembatan",
    "value": "32"
  },
  {
    "text": "apresiasi garuda",
    "value": "32"
  },
  {
    "text": "pt asdp",
    "value": "31"
  },
  {
    "text": "pecat luhut",
    "value": "30"
  },
  {
    "text": "maksa gus",
    "value": "30"
  },
  {
    "text": "skrng maksa",
    "value": "30"
  },
  {
    "text": "luhut erick",
    "value": "30"
  },
  {
    "text": "rt chusnulch",
    "value": "30"
  },
  {
    "text": "jokowi pecat",
    "value": "30"
  },
  {
    "text": "maksa jokowi",
    "value": "30"
  },
  {
    "text": "kemarin maksa",
    "value": "30"
  },
  {
    "text": "chusnulch kemarin",
    "value": "30"
  },
  {
    "text": "yaqut dipecatmenteri",
    "value": "30"
  },
  {
    "text": "gus yaqut",
    "value": "30"
  },
  {
    "text": "thohir skrng",
    "value": "30"
  },
  {
    "text": "dipecatmenteri jkw",
    "value": "30"
  },
  {
    "text": "jkwsuka menghina",
    "value": "30"
  },
  {
    "text": "pny malu",
    "value": "30"
  },
  {
    "text": "ga pny",
    "value": "30"
  },
  {
    "text": "jkwkadrun ga",
    "value": "30"
  },
  {
    "text": "lengserkan jkwkadrun",
    "value": "30"
  },
  {
    "text": "jkwdari lengserkan",
    "value": "30"
  },
  {
    "text": "menghina jkwdari",
    "value": "30"
  },
  {
    "text": "presidenga jkwsuka",
    "value": "30"
  },
  {
    "text": "jkw singkirkanpdhl",
    "value": "30"
  },
  {
    "text": "sbg presidenga",
    "value": "30"
  },
  {
    "text": "jkw sbg",
    "value": "30"
  },
  {
    "text": "jkwga akui",
    "value": "30"
  },
  {
    "text": "pemilih jkwga",
    "value": "30"
  },
  {
    "text": "singkirkanpdhl pemilih",
    "value": "30"
  },
  {
    "text": "akui jkw",
    "value": "30"
  },
  {
    "text": "generasi indonesia",
    "value": "30"
  },
  {
    "text": "warga indonesia",
    "value": "30"
  },
  {
    "text": "berharap garuda",
    "value": "27"
  },
  {
    "text": "maskapai nasional",
    "value": "27"
  },
  {
    "text": "indonesia wni",
    "value": "27"
  },
  {
    "text": "wni ukraina",
    "value": "26"
  },
  {
    "text": "gagasan inovasi",
    "value": "25"
  },
  {
    "text": "mengapresiasi garuda",
    "value": "25"
  },
  {
    "text": "proses evakuasi",
    "value": "25"
  },
  {
    "text": "rt tonaytoni",
    "value": "24"
  },
  {
    "text": "positif gagasan",
    "value": "24"
  },
  {
    "text": "thohir mengapresiasi",
    "value": "24"
  },
  {
    "text": "thohir menyambut",
    "value": "23"
  },
  {
    "text": "memaksimalkan perannya",
    "value": "23"
  },
  {
    "text": "bumn terbuka",
    "value": "22"
  },
  {
    "text": "pelayanan transportasi",
    "value": "22"
  },
  {
    "text": "operator pelayanan",
    "value": "22"
  },
  {
    "text": "wujud transformasi",
    "value": "22"
  },
  {
    "text": "transportasi publik",
    "value": "22"
  },
  {
    "text": "inovasi dihasilkan",
    "value": "21"
  },
  {
    "text": "listrik anak",
    "value": "21"
  },
  {
    "text": "indonesia motor",
    "value": "21"
  },
  {
    "text": "bumn erickthohir",
    "value": "21"
  },
  {
    "text": "ferry indonesia",
    "value": "21"
  },
  {
    "text": "asdp ferry",
    "value": "21"
  },
  {
    "text": "mengakuisisi pt",
    "value": "21"
  },
  {
    "text": "resmi mengakuisisi",
    "value": "21"
  },
  {
    "text": "kendaraan listrik",
    "value": "20"
  },
  {
    "text": "perannya menjalankan",
    "value": "20"
  },
  {
    "text": "asdp operator",
    "value": "19"
  },
  {
    "text": "indonesia mengevakuasi",
    "value": "19"
  },
  {
    "text": "persero resmi",
    "value": "19"
  },
  {
    "text": "mengevakuasi warga",
    "value": "18"
  },
  {
    "text": "rt ndhalff",
    "value": "18"
  },
  {
    "text": "indonesia persero",
    "value": "18"
  },
  {
    "text": "mendeklarasikan dukungan",
    "value": "18"
  },
  {
    "text": "rt gelasbambu",
    "value": "18"
  },
  {
    "text": "andal mumpuni",
    "value": "17"
  },
  {
    "text": "indonesia berhasil",
    "value": "17"
  },
  {
    "text": "apresiasi misi",
    "value": "16"
  },
  {
    "text": "misi garuda",
    "value": "16"
  },
  {
    "text": "rt renamareta",
    "value": "16"
  },
  {
    "text": "indonesia maskapai",
    "value": "16"
  },
  {
    "text": "dlm menjalankan",
    "value": "16"
  },
  {
    "text": "misi kemanusiaan",
    "value": "15"
  },
  {
    "text": "indonesia yg",
    "value": "15"
  },
  {
    "text": "nasional memaksimalkan",
    "value": "15"
  },
  {
    "text": "wni ukrainadenganetmerdekaberdaulat",
    "value": "15"
  },
  {
    "text": "menjalankan proses",
    "value": "15"
  },
  {
    "text": "berhasil menjalankan",
    "value": "15"
  },
  {
    "text": "berhasil evakuasi",
    "value": "15"
  },
  {
    "text": "yg dihasilkan",
    "value": "14"
  },
  {
    "text": "erickthohir menyambut",
    "value": "14"
  },
  {
    "text": "kembangkan motor",
    "value": "14"
  },
  {
    "text": "rt chaterine",
    "value": "14"
  },
  {
    "text": "indonesia ambil",
    "value": "14"
  },
  {
    "text": "nusantara erick",
    "value": "13"
  },
  {
    "text": "renamareta menteri",
    "value": "13"
  },
  {
    "text": "denganetmerdekaberdaulat",
    "value": "337"
  },
  {
    "text": "denganetmerdekaberdaulatsaya",
    "value": "1"
  }
];

const dataWordCloud2 = [
  {
    "text": "sandiaga uno",
    "value": "386"
  },
  {
    "text": "hasil survei",
    "value": "67"
  },
  {
    "text": "erick thohir",
    "value": "56"
  },
  {
    "text": "menparekraf sandiaga",
    "value": "54"
  },
  {
    "text": "sosial kemasyarakatan",
    "value": "53"
  },
  {
    "text": "isu sosial",
    "value": "53"
  },
  {
    "text": "dinamika isu",
    "value": "53"
  },
  {
    "text": "bertajuk dinamika",
    "value": "53"
  },
  {
    "text": "kemasyarakatan konstelasi",
    "value": "53"
  },
  {
    "text": "konstelasi politik",
    "value": "53"
  },
  {
    "text": "kereta gantung",
    "value": "53"
  },
  {
    "text": "ipo merilis",
    "value": "47"
  },
  {
    "text": "merilis hasil",
    "value": "47"
  },
  {
    "text": "survei bertajuk",
    "value": "46"
  },
  {
    "text": "dianggap publik",
    "value": "45"
  },
  {
    "text": "sosok menteri",
    "value": "45"
  },
  {
    "text": "menteri dgn",
    "value": "40"
  },
  {
    "text": "dgn kinerja",
    "value": "40"
  },
  {
    "text": "publik sbg",
    "value": "39"
  },
  {
    "text": "menteri bumnerickthohir",
    "value": "39"
  },
  {
    "text": "bumnerickthohir dianggap",
    "value": "39"
  },
  {
    "text": "politik hasilnya",
    "value": "39"
  },
  {
    "text": "sbg sosok",
    "value": "38"
  },
  {
    "text": "persen denganetmerdekaberdaulathttpstcoxyqkpgjhg",
    "value": "37"
  },
  {
    "text": "hasilnya menteri",
    "value": "37"
  },
  {
    "text": "puncak bogor",
    "value": "33"
  },
  {
    "text": "ekonomi kreatif",
    "value": "33"
  },
  {
    "text": "menteri pariwisata",
    "value": "33"
  },
  {
    "text": "prabowo subianto",
    "value": "32"
  },
  {
    "text": "pariwisata ekonomi",
    "value": "31"
  },
  {
    "text": "kinerja dgn",
    "value": "28"
  },
  {
    "text": "dgn persen",
    "value": "28"
  },
  {
    "text": "dg kinerja",
    "value": "27"
  },
  {
    "text": "menteri dg",
    "value": "27"
  },
  {
    "text": "ridwan kamil",
    "value": "27"
  },
  {
    "text": "tiket motogp",
    "value": "25"
  },
  {
    "text": "menteri bumn",
    "value": "25"
  },
  {
    "text": "bumn erick",
    "value": "24"
  },
  {
    "text": "anies baswedan",
    "value": "24"
  },
  {
    "text": "rt detikcom",
    "value": "24"
  },
  {
    "text": "abadi puncak",
    "value": "23"
  },
  {
    "text": "macet abadi",
    "value": "23"
  },
  {
    "text": "ganjar pranowo",
    "value": "23"
  },
  {
    "text": "pemerintah provinsi",
    "value": "22"
  },
  {
    "text": "ntb mewajibkan",
    "value": "22"
  },
  {
    "text": "membeli tiket",
    "value": "22"
  },
  {
    "text": "langkah pemerintah",
    "value": "22"
  },
  {
    "text": "juragantajirrr erickthohir",
    "value": "22"
  },
  {
    "text": "pariwisata indonesia",
    "value": "22"
  },
  {
    "text": "mencoreng pariwisata",
    "value": "22"
  },
  {
    "text": "thohir sandiaga",
    "value": "22"
  },
  {
    "text": "negeri sipil",
    "value": "21"
  },
  {
    "text": "pegawai negeri",
    "value": "21"
  },
  {
    "text": "pns membeli",
    "value": "21"
  },
  {
    "text": "mewajibkan pegawai",
    "value": "21"
  },
  {
    "text": "sipil pns",
    "value": "21"
  },
  {
    "text": "bogor mencoreng",
    "value": "21"
  },
  {
    "text": "pembuatan kereta",
    "value": "21"
  },
  {
    "text": "gantung httpstcorxbbskwzv",
    "value": "21"
  },
  {
    "text": "indonesia menparekraf",
    "value": "21"
  },
  {
    "text": "uno solusinya",
    "value": "21"
  },
  {
    "text": "solusinya pembuatan",
    "value": "21"
  },
  {
    "text": "yg masuk",
    "value": "21"
  },
  {
    "text": "menteri kinerja",
    "value": "21"
  },
  {
    "text": "detikcom macet",
    "value": "20"
  },
  {
    "text": "popularitas menpar",
    "value": "20"
  },
  {
    "text": "menpar sandiaga",
    "value": "20"
  },
  {
    "text": "uno mensos",
    "value": "20"
  },
  {
    "text": "mensos tri",
    "value": "20"
  },
  {
    "text": "kinerja popularitas",
    "value": "20"
  },
  {
    "text": "tri rismaharinidenganetmerdekaberdaulat",
    "value": "20"
  },
  {
    "text": "rismaharinidenganetmerdekaberdaulat httpstcomferqtoy",
    "value": "20"
  },
  {
    "text": "masuk menteri",
    "value": "20"
  },
  {
    "text": "nama yg",
    "value": "20"
  },
  {
    "text": "subiantodan nama",
    "value": "20"
  },
  {
    "text": "menhan prabowo",
    "value": "20"
  },
  {
    "text": "survei menhan",
    "value": "20"
  },
  {
    "text": "urutan perolehan",
    "value": "20"
  },
  {
    "text": "thohir urutan",
    "value": "20"
  },
  {
    "text": "prabowo subiantodan",
    "value": "20"
  },
  {
    "text": "perolehan hasil",
    "value": "20"
  },
  {
    "text": "kreatif sandiaga",
    "value": "20"
  },
  {
    "text": "harimurti yudhoyono",
    "value": "20"
  },
  {
    "text": "pranowo anies",
    "value": "20"
  },
  {
    "text": "baswedan prabowo",
    "value": "20"
  },
  {
    "text": "agus harimurti",
    "value": "20"
  },
  {
    "text": "uno usul",
    "value": "20"
  },
  {
    "text": "rt itsfunnie",
    "value": "19"
  },
  {
    "text": "itsfunnie menteri",
    "value": "19"
  },
  {
    "text": "political opinion",
    "value": "19"
  },
  {
    "text": "uno menteri",
    "value": "19"
  },
  {
    "text": "kamil sandiaga",
    "value": "19"
  },
  {
    "text": "tjahaja purnama",
    "value": "18"
  },
  {
    "text": "purnama agus",
    "value": "18"
  },
  {
    "text": "subianto ridwan",
    "value": "18"
  },
  {
    "text": "unggul ganjar",
    "value": "18"
  },
  {
    "text": "yudhoyono httpstcofdpfam",
    "value": "18"
  },
  {
    "text": "uno basuki",
    "value": "18"
  },
  {
    "text": "basuki tjahaja",
    "value": "18"
  },
  {
    "text": "desa wisata",
    "value": "18"
  },
  {
    "text": "denganetmerdekaberdaulat",
    "value": "99"
  },
  {
    "text": "sandiagauno",
    "value": "37"
  },
  {
    "text": "pariwisata",
    "value": "19"
  },
  {
    "text": "kemenparekraf",
    "value": "17"
  },
  {
    "text": "crave2022",
    "value": "15"
  },
  {
    "text": "muhammadiyah",
    "value": "15"
  },
  {
    "text": "cabangrantingaward",
    "value": "15"
  },
  {
    "text": "emakemaksandiuno",
    "value": "11"
  },
  {
    "text": "motogp",
    "value": "10"
  },
  {
    "text": "umkm",
    "value": "10"
  },
  {
    "text": "sandiuno",
    "value": "10"
  },
  {
    "text": "tempobisnis",
    "value": "7"
  },
  {
    "text": "enterpreneur",
    "value": "6"
  },
  {
    "text": "sindonews",
    "value": "6"
  },
  {
    "text": "bukanberitabiasa",
    "value": "6"
  },
  {
    "text": "milenial",
    "value": "5"
  },
  {
    "text": "sukses",
    "value": "5"
  },
  {
    "text": "pebisnismuda",
    "value": "5"
  },
  {
    "text": "bogor",
    "value": "5"
  },
  {
    "text": "anakmuda",
    "value": "5"
  },
  {
    "text": "guyssandi",
    "value": "5"
  },
  {
    "text": "keretagantung",
    "value": "4"
  },
  {
    "text": "kerjakeras",
    "value": "4"
  },
  {
    "text": "puncak",
    "value": "4"
  },
  {
    "text": "novel",
    "value": "3"
  },
  {
    "text": "penulis",
    "value": "3"
  },
  {
    "text": "difabel",
    "value": "3"
  },
  {
    "text": "emakemak",
    "value": "3"
  },
  {
    "text": "beasiswaba",
    "value": "3"
  },
  {
    "text": "nft",
    "value": "3"
  },
  {
    "text": "tripcandynft",
    "value": "3"
  },
  {
    "text": "tripcandy",
    "value": "3"
  },
  {
    "text": "cryptotravel",
    "value": "3"
  },
  {
    "text": "tiktok",
    "value": "3"
  },
  {
    "text": "cryptotourism",
    "value": "3"
  },
  {
    "text": "rusia",
    "value": "3"
  },
  {
    "text": "infowisata",
    "value": "3"
  },
  {
    "text": "cariberitaditvone",
    "value": "3"
  },
  {
    "text": "newsone",
    "value": "3"
  },
  {
    "text": "jokowidodo",
    "value": "2"
  },
  {
    "text": "tempootomotif",
    "value": "2"
  },
  {
    "text": "nasional",
    "value": "2"
  },
  {
    "text": "puncakbogor",
    "value": "2"
  },
  {
    "text": "beritaterpopuler",
    "value": "2"
  },
  {
    "text": "turisasing",
    "value": "2"
  },
  {
    "text": "menteripariwisata",
    "value": "2"
  },
  {
    "text": "ff",
    "value": "2"
  },
  {
    "text": "menparekrafsandiagauno",
    "value": "2"
  },
  {
    "text": "metaverse",
    "value": "2"
  },
  {
    "text": "presidenjokowi",
    "value": "2"
  },
  {
    "text": "jokowi2periode",
    "value": "2"
  },
  {
    "text": "2019pilihankujokowimaruf",
    "value": "2"
  },
  {
    "text": "kumparannews",
    "value": "2"
  },
  {
    "text": "2019pilihjokowi",
    "value": "2"
  },
  {
    "text": "kumparantravel",
    "value": "2"
  },
  {
    "text": "menparekraf",
    "value": "2"
  },
  {
    "text": "mosi",
    "value": "1"
  },
  {
    "text": "pramborsnews",
    "value": "1"
  },
  {
    "text": "jalinkomunitas",
    "value": "1"
  },
  {
    "text": "menteri",
    "value": "1"
  },
  {
    "text": "breakingnews",
    "value": "1"
  },
  {
    "text": "publisherstory",
    "value": "1"
  },
  {
    "text": "bandarangurahrai",
    "value": "1"
  },
  {
    "text": "termometersosialmasakini",
    "value": "1"
  },
  {
    "text": "indonesiamaju",
    "value": "1"
  },
  {
    "text": "puanmaharani]maharani",
    "value": "1"
  },
  {
    "text": "macet",
    "value": "1"
  },
  {
    "text": "mostpopuler",
    "value": "1"
  },
  {
    "text": "aliansi",
    "value": "1"
  },
  {
    "text": "puanmaharani",
    "value": "1"
  },
  {
    "text": "kemacetanbogor",
    "value": "1"
  },
  {
    "text": "mnctvnews",
    "value": "1"
  },
  {
    "text": "beritasampit",
    "value": "1"
  },
  {
    "text": "russiaukraine",
    "value": "1"
  },
  {
    "text": "tempotravel",
    "value": "1"
  },
  {
    "text": "tempovideo",
    "value": "1"
  },
  {
    "text": "denpasar",
    "value": "1"
  },
  {
    "text": "bali",
    "value": "1"
  },
  {
    "text": "mandalika",
    "value": "1"
  },
  {
    "text": "emakemaksandiagauno",
    "value": "1"
  },
  {
    "text": "mudasukses",
    "value": "1"
  },
  {
    "text": "pengusaha",
    "value": "1"
  },
  {
    "text": "guyssandiuno",
    "value": "1"
  },
  {
    "text": "pilihbeda",
    "value": "1"
  },
  {
    "text": "inanews",
    "value": "1"
  },
  {
    "text": "jambimantap",
    "value": "1"
  },
  {
    "text": "ekonomi",
    "value": "1"
  },
  {
    "text": "harirayanyepi",
    "value": "1"
  },
  {
    "text": "pariwisataindonesia",
    "value": "1"
  },
  {
    "text": "cablecar",
    "value": "1"
  },
  {
    "text": "atasikemacetan",
    "value": "1"
  },
  {
    "text": "mataindonesia",
    "value": "1"
  },
  {
    "text": "deddycorbuzier",
    "value": "1"
  },
  {
    "text": "property",
    "value": "1"
  },
  {
    "text": "infoproperty",
    "value": "1"
  },
  {
    "text": "keuangan",
    "value": "1"
  },
  {
    "text": "buahmentega",
    "value": "1"
  },
  {
    "text": "lengkapcepatberitanya",
    "value": "1"
  },
  {
    "text": "lalulintas",
    "value": "1"
  },
  {
    "text": "jernihkanharapan",
    "value": "1"
  }
];

const dataWordCloud3 = [
    {
      "text": "ridwan kamil",
      "value": "416"
    },
    {
      "text": "jawa barat",
      "value": "113"
    },
    {
      "text": "gubernur jawa",
      "value": "106"
    },
    {
      "text": "barat ridwan",
      "value": "101"
    },
    {
      "text": "rs sengaja",
      "value": "88"
    },
    {
      "text": "sengaja mengcovidkan",
      "value": "88"
    },
    {
      "text": "mengcovidkan",
      "value": "87"
    },
    {
      "text": "mengaku rs",
      "value": "87"
    },
    {
      "text": "rt geloraco",
      "value": "87"
    },
    {
      "text": "geloraco ridwan",
      "value": "87"
    },
    {
      "text": "kamil mengaku",
      "value": "87"
    },
    {
      "text": "pemakaman jenazah",
      "value": "87"
    },
    {
      "text": "kamil langsung",
      "value": "79"
    },
    {
      "text": "menindak petugas",
      "value": "77"
    },
    {
      "text": "langsung maaf",
      "value": "77"
    },
    {
      "text": "maaf menindak",
      "value": "77"
    },
    {
      "text": "positif covid",
      "value": "77"
    },
    {
      "text": "covid gubernur",
      "value": "77"
    },
    {
      "text": "petugas tersebuthttpstcoppimeliogv",
      "value": "77"
    },
    {
      "text": "keluarganya positif",
      "value": "77"
    },
    {
      "text": "jenazah keluarganya",
      "value": "77"
    },
    {
      "text": "rupiah pemakaman",
      "value": "77"
    },
    {
      "text": "jutaan rupiah",
      "value": "77"
    },
    {
      "text": "pungli jutaan",
      "value": "77"
    },
    {
      "text": "mengaku pungli",
      "value": "77"
    },
    {
      "text": "barat mengaku",
      "value": "77"
    },
    {
      "text": "bandung jawa",
      "value": "77"
    },
    {
      "text": "warga bandung",
      "value": "77"
    },
    {
      "text": "rt tirtoid",
      "value": "76"
    },
    {
      "text": "tirtoid warga",
      "value": "76"
    },
    {
      "text": "ppkm darurat",
      "value": "63"
    },
    {
      "text": "tpu cikadut",
      "value": "46"
    },
    {
      "text": "bandung ridwan",
      "value": "32"
    },
    {
      "text": "rumah sakit",
      "value": "30"
    },
    {
      "text": "pungli tpu",
      "value": "29"
    },
    {
      "text": "kamil maaf",
      "value": "28"
    },
    {
      "text": "rt oposisicerdas",
      "value": "26"
    },
    {
      "text": "kamil tegur",
      "value": "26"
    },
    {
      "text": "pasien covidkan",
      "value": "23"
    },
    {
      "text": "rt catatanali",
      "value": "23"
    },
    {
      "text": "mengcovidkan pasien",
      "value": "22"
    },
    {
      "text": "timbul fitnah",
      "value": "22"
    },
    {
      "text": "pasien diseriusi",
      "value": "22"
    },
    {
      "text": "diseriusi masyarakat",
      "value": "22"
    },
    {
      "text": "biar timbul",
      "value": "22"
    },
    {
      "text": "gimana pasien",
      "value": "22"
    },
    {
      "text": "masyarakat tau",
      "value": "22"
    },
    {
      "text": "tau rs",
      "value": "22"
    },
    {
      "text": "rs mengcovidkan",
      "value": "22"
    },
    {
      "text": "mengcovidkan pakai",
      "value": "22"
    },
    {
      "text": "pakai obat",
      "value": "22"
    },
    {
      "text": "obat gimana",
      "value": "22"
    },
    {
      "text": "covidkan biar",
      "value": "22"
    },
    {
      "text": "tega mengcovidkan",
      "value": "22"
    },
    {
      "text": "kamil bupati",
      "value": "22"
    },
    {
      "text": "fitnah httpstcovluedhinn",
      "value": "22"
    },
    {
      "text": "catatanali ridwan",
      "value": "22"
    },
    {
      "text": "sakit tega",
      "value": "22"
    },
    {
      "text": "bupati banjarnegara",
      "value": "22"
    },
    {
      "text": "banjarnegara rumah",
      "value": "22"
    },
    {
      "text": "aturan ppkm",
      "value": "21"
    },
    {
      "text": "taat aturan",
      "value": "21"
    },
    {
      "text": "rt zonapriangan",
      "value": "20"
    },
    {
      "text": "cikadut bandung",
      "value": "18"
    },
    {
      "text": "jabar ridwan",
      "value": "18"
    },
    {
      "text": "tegur industri",
      "value": "18"
    },
    {
      "text": "industri taat",
      "value": "18"
    },
    {
      "text": "khusus covid",
      "value": "17"
    },
    {
      "text": "kamil imbau",
      "value": "16"
    },
    {
      "text": "maaf oknumnya",
      "value": "15"
    },
    {
      "text": "detikcom gubernur",
      "value": "14"
    },
    {
      "text": "rt detikcom",
      "value": "14"
    },
    {
      "text": "kecewa ridwan",
      "value": "14"
    },
    {
      "text": "kamil ppkm",
      "value": "14"
    },
    {
      "text": "idul adha",
      "value": "14"
    },
    {
      "text": "pemakaman tpu",
      "value": "14"
    },
    {
      "text": "heboh pungli",
      "value": "14"
    },
    {
      "text": "jenazah covid",
      "value": "14"
    },
    {
      "text": "sidak pabrik",
      "value": "14"
    },
    {
      "text": "rp juta",
      "value": "13"
    },
    {
      "text": "pungli pemakaman",
      "value": "13"
    },
    {
      "text": "masyarakat kurangi",
      "value": "13"
    },
    {
      "text": "oposisicerdas kecewa",
      "value": "12"
    },
    {
      "text": "darurat memuaskanhttpstcoyzsrgwadbq",
      "value": "12"
    },
    {
      "text": "langsung dipecat",
      "value": "12"
    },
    {
      "text": "kamil masyarakat",
      "value": "12"
    },
    {
      "text": "kurangi mobilitas",
      "value": "12"
    },
    {
      "text": "darurat rp",
      "value": "11"
    },
    {
      "text": "dijatuhkan penjual",
      "value": "11"
    },
    {
      "text": "bubur tasikmalaya",
      "value": "11"
    },
    {
      "text": "denda ppkm",
      "value": "11"
    },
    {
      "text": "penjual bubur",
      "value": "11"
    },
    {
      "text": "tasikmalaya httpstcotllnrppb",
      "value": "11"
    },
    {
      "text": "juta dijatuhkan",
      "value": "11"
    },
    {
      "text": "kamil menanggapi",
      "value": "11"
    },
    {
      "text": "menanggapi denda",
      "value": "11"
    },
    {
      "text": "kamil oknum",
      "value": "11"
    },
    {
      "text": "cikadut ridwan",
      "value": "11"
    },
    {
      "text": "oknumnya dipecathttpstcoeovaronsa",
      "value": "11"
    },
    {
      "text": "oknum langsung",
      "value": "11"
    },
    {
      "text": "ridwankamil",
      "value": "4"
    },
    {
      "text": "covid19",
      "value": "2"
    },
    {
      "text": "beritasonora",
      "value": "2"
    },
    {
      "text": "regional",
      "value": "2"
    },
    {
      "text": "pdiperjuangan",
      "value": "1"
    },
    {
      "text": "pemkot",
      "value": "1"
    },
    {
      "text": "bukanberitabiasa",
      "value": "1"
    },
    {
      "text": "sindonews",
      "value": "1"
    },
    {
      "text": "prmn",
      "value": "1"
    },
    {
      "text": "nasional",
      "value": "1"
    },
    {
      "text": "indonesia",
      "value": "1"
    },
    {
      "text": "korban",
      "value": "1"
    },
    {
      "text": "pemakaman",
      "value": "1"
    },
    {
      "text": "oded",
      "value": "1"
    },
    {
      "text": "jawabarat",
      "value": "1"
    },
    {
      "text": "bandung",
      "value": "1"
    },
    {
      "text": "gubernur",
      "value": "1"
    },
    {
      "text": "pdiperjuanganjabar",
      "value": "1"
    },
    {
      "text": "jabar",
      "value": "1"
    },
    {
      "text": "pemprov",
      "value": "1"
    },
    {
      "text": "cikadut",
      "value": "1"
    },
    {
      "text": "tpu",
      "value": "1"
    },
    {
      "text": "pungli",
      "value": "1"
    },
    {
      "text": "kumparannews",
      "value": "1"
    },
    {
      "text": "covid",
      "value": "1"
    },
    {
      "text": "terkait",
      "value": "1"
    },
    {
      "text": "cendekiaquotes",
      "value": "1"
    }
];

const dataWordCloud4 = [
    {
      "text": "anies baswedan",
      "value": "383"
    },
    {
      "text": "rt imcmushroom",
      "value": "83"
    },
    {
      "text": "baswedan rakyat",
      "value": "83"
    },
    {
      "text": "akhlak anies",
      "value": "82"
    },
    {
      "text": "setuju salfollan",
      "value": "82"
    },
    {
      "text": "negeri setuju",
      "value": "82"
    },
    {
      "text": "kondisi negeri",
      "value": "82"
    },
    {
      "text": "memperbaiki kondisi",
      "value": "82"
    },
    {
      "text": "rakyat memperbaiki",
      "value": "82"
    },
    {
      "text": "ummat revolusi",
      "value": "82"
    },
    {
      "text": "revolusi akhlak",
      "value": "82"
    },
    {
      "text": "hrs ummat",
      "value": "82"
    },
    {
      "text": "negaraib hrs",
      "value": "82"
    },
    {
      "text": "ummat negaraib",
      "value": "82"
    },
    {
      "text": "tokoh ummat",
      "value": "82"
    },
    {
      "text": "imcmushroom tokoh",
      "value": "82"
    },
    {
      "text": "salfollan httpstcogpqeclal",
      "value": "82"
    },
    {
      "text": "dki jakarta",
      "value": "60"
    },
    {
      "text": "jakarta anies",
      "value": "54"
    },
    {
      "text": "gubernur dki",
      "value": "44"
    },
    {
      "text": "equity life",
      "value": "34"
    },
    {
      "text": "disidak anies",
      "value": "33"
    },
    {
      "text": "pt equity",
      "value": "33"
    },
    {
      "text": "rt relawananies",
      "value": "32"
    },
    {
      "text": "political review",
      "value": "27"
    },
    {
      "text": "pandang bulu",
      "value": "27"
    },
    {
      "text": "bulu menegakkan",
      "value": "27"
    },
    {
      "text": "menegakkan hukum",
      "value": "27"
    },
    {
      "text": "hukum diapresiasi",
      "value": "27"
    },
    {
      "text": "diapresiasi direktur",
      "value": "27"
    },
    {
      "text": "eksekutif indonesia",
      "value": "27"
    },
    {
      "text": "indonesia political",
      "value": "27"
    },
    {
      "text": "direktur eksekutif",
      "value": "27"
    },
    {
      "text": "review ipr",
      "value": "27"
    },
    {
      "text": "ipr ujang",
      "value": "27"
    },
    {
      "text": "anies pandang",
      "value": "27"
    },
    {
      "text": "komarudin menurutnya",
      "value": "27"
    },
    {
      "text": "menurutnya sikap",
      "value": "27"
    },
    {
      "text": "anies patut",
      "value": "27"
    },
    {
      "text": "dijadikan contoh",
      "value": "27"
    },
    {
      "text": "contoh pihakhttpstcorxjfenecff",
      "value": "27"
    },
    {
      "text": "pihakhttpstcorxjfenecff httpstcoqbobmvgjr",
      "value": "27"
    },
    {
      "text": "ujang komarudin",
      "value": "27"
    },
    {
      "text": "patut dijadikan",
      "value": "27"
    },
    {
      "text": "sikap anies",
      "value": "27"
    },
    {
      "text": "covid gubernur",
      "value": "27"
    },
    {
      "text": "relawananies sikap",
      "value": "26"
    },
    {
      "text": "rt kebijakananies",
      "value": "26"
    },
    {
      "text": "rt ferdinandhaean",
      "value": "25"
    },
    {
      "text": "ppkm darurat",
      "value": "24"
    },
    {
      "text": "baswedan mengajak",
      "value": "24"
    },
    {
      "text": "penularan covid",
      "value": "24"
    },
    {
      "text": "warga dki",
      "value": "23"
    },
    {
      "text": "bosan menerapkan",
      "value": "23"
    },
    {
      "text": "menerapkan sebisa",
      "value": "23"
    },
    {
      "text": "sebisa membatasi",
      "value": "23"
    },
    {
      "text": "membatasi aktifitas",
      "value": "23"
    },
    {
      "text": "aktifitas rumah",
      "value": "23"
    },
    {
      "text": "rumah upaya",
      "value": "23"
    },
    {
      "text": "upaya mencegah",
      "value": "23"
    },
    {
      "text": "mencegah penularan",
      "value": "23"
    },
    {
      "text": "gubernur provinsi",
      "value": "23"
    },
    {
      "text": "provinsi dki",
      "value": "23"
    },
    {
      "text": "mengajak warga",
      "value": "23"
    },
    {
      "text": "warga disiplinprotokolkesehatan",
      "value": "23"
    },
    {
      "text": "disiplinprotokolkesehatan ppkmdarurat",
      "value": "23"
    },
    {
      "text": "ppkmdarurat httpstcoweiybgdlgd",
      "value": "23"
    },
    {
      "text": "baswedan kumparannews",
      "value": "22"
    },
    {
      "text": "kebijakananies bosan",
      "value": "22"
    },
    {
      "text": "jakarta terpapar",
      "value": "21"
    },
    {
      "text": "rt detikcom",
      "value": "21"
    },
    {
      "text": "baswedan jakarta",
      "value": "20"
    },
    {
      "text": "rt kumparan",
      "value": "20"
    },
    {
      "text": "cocok dipasangkan",
      "value": "18"
    },
    {
      "text": "dipasangkan anies",
      "value": "18"
    },
    {
      "text": "kumparannews httpstcorhmzrmct",
      "value": "18"
    },
    {
      "text": "pelanggaran ppkm",
      "value": "18"
    },
    {
      "text": "ppkm disidak",
      "value": "18"
    },
    {
      "text": "sosialnya pt",
      "value": "18"
    },
    {
      "text": "mengakui pelanggaran",
      "value": "18"
    },
    {
      "text": "life indonesia",
      "value": "18"
    },
    {
      "text": "media sosialnya",
      "value": "18"
    },
    {
      "text": "akun media",
      "value": "18"
    },
    {
      "text": "indonesia mengakui",
      "value": "18"
    },
    {
      "text": "lawakan ditengah",
      "value": "17"
    },
    {
      "text": "utk meredakan",
      "value": "17"
    },
    {
      "text": "ketegangan saraf",
      "value": "17"
    },
    {
      "text": "saraf politik",
      "value": "17"
    },
    {
      "text": "anies baswedanlumayan",
      "value": "17"
    },
    {
      "text": "baswedanlumayan hiburan",
      "value": "17"
    },
    {
      "text": "hiburan lawakan",
      "value": "17"
    },
    {
      "text": "politik httpstcouditzcrfmj",
      "value": "17"
    },
    {
      "text": "tensi politik",
      "value": "17"
    },
    {
      "text": "ditengah tingginya",
      "value": "17"
    },
    {
      "text": "tingginya tensi",
      "value": "17"
    },
    {
      "text": "politik nasional",
      "value": "17"
    },
    {
      "text": "nasional minimal",
      "value": "17"
    },
    {
      "text": "minimal tegang",
      "value": "17"
    },
    {
      "text": "tegang lawakan",
      "value": "17"
    },
    {
      "text": "lawakan yg",
      "value": "17"
    }
];

const dataWordCloud5 = [
  {
    "text": "ganjar pranowo",
    "value": "350"
  },
  {
    "text": "seni budaya",
    "value": "83"
  },
  {
    "text": "bersatu padu",
    "value": "81"
  },
  {
    "text": "menyerah hadapi",
    "value": "80"
  },
  {
    "text": "padu ganjar",
    "value": "80"
  },
  {
    "text": "royong mbangun",
    "value": "79"
  },
  {
    "text": "mbangun seni",
    "value": "79"
  },
  {
    "text": "rt kakekhalal",
    "value": "68"
  },
  {
    "text": "gotong royong",
    "value": "63"
  },
  {
    "text": "pranowo gotong",
    "value": "62"
  },
  {
    "text": "budaya menyerah",
    "value": "62"
  },
  {
    "text": "jawa ganjar",
    "value": "61"
  },
  {
    "text": "rt sahabatganjar",
    "value": "51"
  },
  {
    "text": "gubernur jawa",
    "value": "48"
  },
  {
    "text": "sahabatganjar ganjar",
    "value": "41"
  },
  {
    "text": "program maju",
    "value": "39"
  },
  {
    "text": "maju bareng",
    "value": "39"
  },
  {
    "text": "ganjarpranowo sahabatganjar",
    "value": "37"
  },
  {
    "text": "sbg ungkapan",
    "value": "36"
  },
  {
    "text": "unk ganjarkeren",
    "value": "35"
  },
  {
    "text": "tembang unk",
    "value": "35"
  },
  {
    "text": "kesenian luput",
    "value": "35"
  },
  {
    "text": "yahttpstcoikxajp httpstcozdayecyq",
    "value": "35"
  },
  {
    "text": "gub ganjar",
    "value": "35"
  },
  {
    "text": "dr dukungan",
    "value": "35"
  },
  {
    "text": "diciptakanlah tembang",
    "value": "35"
  },
  {
    "text": "ungkapan kasih",
    "value": "35"
  },
  {
    "text": "dukungan gub",
    "value": "35"
  },
  {
    "text": "ganjar sbg",
    "value": "35"
  },
  {
    "text": "kasih diciptakanlah",
    "value": "35"
  },
  {
    "text": "beragam kesenian",
    "value": "35"
  },
  {
    "text": "marabahayaberkembangnya beragam",
    "value": "35"
  },
  {
    "text": "hadapi marabahayaberkembangnya",
    "value": "35"
  },
  {
    "text": "ganjarkeren yahttpstcoikxajp",
    "value": "35"
  },
  {
    "text": "luput dr",
    "value": "35"
  },
  {
    "text": "pengentasan kemiskinan",
    "value": "34"
  },
  {
    "text": "chusnulch bersatu",
    "value": "34"
  },
  {
    "text": "rt chusnulch",
    "value": "34"
  },
  {
    "text": "pranowo ganjarpranowo",
    "value": "31"
  },
  {
    "text": "kakekhalal ganjar",
    "value": "30"
  },
  {
    "text": "kemiskinan ekstrem",
    "value": "29"
  },
  {
    "text": "air masuk",
    "value": "29"
  },
  {
    "text": "masuk kemiskinan",
    "value": "29"
  },
  {
    "text": "bantuan air",
    "value": "29"
  },
  {
    "text": "mengirimkan bantuan",
    "value": "29"
  },
  {
    "text": "ganjar ganjarpranowo",
    "value": "27"
  },
  {
    "text": "budaya tradisi",
    "value": "27"
  },
  {
    "text": "mendukung ganjar",
    "value": "27"
  },
  {
    "text": "ganjarpranowo ganjar",
    "value": "26"
  },
  {
    "text": "rt aanmuba",
    "value": "24"
  },
  {
    "text": "bantuan seperangkat",
    "value": "23"
  },
  {
    "text": "raya nyepi",
    "value": "23"
  },
  {
    "text": "perayaan raya",
    "value": "23"
  },
  {
    "text": "nyepi berjalan",
    "value": "23"
  },
  {
    "text": "pemprov jateng",
    "value": "22"
  },
  {
    "text": "seniman temanggung",
    "value": "21"
  },
  {
    "text": "mahasiswa milenial",
    "value": "21"
  },
  {
    "text": "berkontribusi gerakan",
    "value": "20"
  },
  {
    "text": "gerakan pengentasan",
    "value": "20"
  },
  {
    "text": "kemiskinan jawa",
    "value": "20"
  },
  {
    "text": "pranowo sembilan",
    "value": "20"
  },
  {
    "text": "jawa alhamdulillah",
    "value": "20"
  },
  {
    "text": "publik jawa",
    "value": "20"
  },
  {
    "text": "pelayanan publik",
    "value": "20"
  },
  {
    "text": "mall pelayanan",
    "value": "20"
  },
  {
    "text": "sembilan mall",
    "value": "20"
  },
  {
    "text": "alhamdulillah ngurus",
    "value": "20"
  },
  {
    "text": "latihan syair",
    "value": "20"
  },
  {
    "text": "adlh bunyi",
    "value": "20"
  },
  {
    "text": "terimakasih krn",
    "value": "20"
  },
  {
    "text": "pembuka latihan",
    "value": "20"
  },
  {
    "text": "ganjarpranowo httpstcofexlpfxa",
    "value": "20"
  },
  {
    "text": "sbg tanda",
    "value": "20"
  },
  {
    "text": "krn ganjar",
    "value": "20"
  },
  {
    "text": "marabahayaini adlh",
    "value": "20"
  },
  {
    "text": "tanda terimakasih",
    "value": "20"
  },
  {
    "text": "syair pembuka",
    "value": "20"
  },
  {
    "text": "bunyi syair",
    "value": "20"
  },
  {
    "text": "hadapi marabahayaini",
    "value": "20"
  },
  {
    "text": "syair sbg",
    "value": "20"
  },
  {
    "text": "jateng fokus",
    "value": "20"
  },
  {
    "text": "rt otnayramitsme",
    "value": "19"
  },
  {
    "text": "ganjarpilihanrakyatgantari httpstcocjzhabgm",
    "value": "19"
  },
  {
    "text": "kaleng ganjarpilihanrakyatgantari",
    "value": "19"
  },
  {
    "text": "ganjar kaleng",
    "value": "19"
  },
  {
    "text": "mudahprestasi ganjar",
    "value": "19"
  },
  {
    "text": "ngurus mudahprestasi",
    "value": "19"
  },
  {
    "text": "rt siticeriaselalu",
    "value": "19"
  },
  {
    "text": "siticeriaselalu bersatu",
    "value": "19"
  },
  {
    "text": "milenial berkonvoi",
    "value": "19"
  },
  {
    "text": "sumut deklarasi",
    "value": "19"
  },
  {
    "text": "milenial sumut",
    "value": "19"
  },
  {
    "text": "capres jumat",
    "value": "19"
  },
  {
    "text": "jumat deklarasi",
    "value": "19"
  },
  {
    "text": "deklarasi mahasiswa",
    "value": "19"
  },
  {
    "text": "deklarasi mendukung",
    "value": "19"
  },
  {
    "text": "berkonvoi ratusan",
    "value": "19"
  },
  {
    "text": "pranowo capres",
    "value": "19"
  },
  {
    "text": "ratusan bentorganjarpilihanrakyat",
    "value": "19"
  },
  {
    "text": "bentorganjarpilihanrakyat httpstcoidfqdppjz",
    "value": "19"
  },
  {
    "text": "sahabatganjar",
    "value": "123"
  },
  {
    "text": "ganjarpranowo",
    "value": "85"
  },
  {
    "text": "ganjar",
    "value": "72"
  },
  {
    "text": "gantariuntukganjar",
    "value": "68"
  },
  {
    "text": "ganjarpilihanrakyat",
    "value": "58"
  },
  {
    "text": "sumutforganjar",
    "value": "40"
  },
  {
    "text": "gantari🇮🇩",
    "value": "27"
  },
  {
    "text": "ganjarpenerusjokowi",
    "value": "21"
  },
  {
    "text": "gantari🇲🇨",
    "value": "19"
  },
  {
    "text": "baladamesumrijik",
    "value": "5"
  },
  {
    "text": "wadasmelawan",
    "value": "4"
  },
  {
    "text": "jumatbekah",
    "value": "3"
  },
  {
    "text": "2024ganjarpresiden",
    "value": "3"
  },
  {
    "text": "denganetmerdekaberdaulat",
    "value": "2"
  },
  {
    "text": "mahasiswa",
    "value": "2"
  },
  {
    "text": "pilpres2024",
    "value": "2"
  },
  {
    "text": "ad",
    "value": "2"
  },
  {
    "text": "jumatberkah",
    "value": "2"
  },
  {
    "text": "pranowo",
    "value": "1"
  },
  {
    "text": "produkdifabelmandiri",
    "value": "1"
  },
  {
    "text": "ganjarpranowobelajar",
    "value": "1"
  },
  {
    "text": "gpbelajar",
    "value": "1"
  },
  {
    "text": "ganjarpranowo2024",
    "value": "1"
  },
  {
    "text": "gp2024",
    "value": "1"
  },
  {
    "text": "gp",
    "value": "1"
  },
  {
    "text": "newsone",
    "value": "1"
  },
  {
    "text": "cariberitaditvone",
    "value": "1"
  },
  {
    "text": "belajar",
    "value": "1"
  },
  {
    "text": "pilpres",
    "value": "1"
  },
  {
    "text": "ibu-ibu",
    "value": "1"
  },
  {
    "text": "ganjarp",
    "value": "1"
  },
  {
    "text": "news",
    "value": "1"
  },
  {
    "text": "prioritaskan",
    "value": "1"
  },
  {
    "text": "dennysiregar",
    "value": "1"
  },
  {
    "text": "capres2024",
    "value": "1"
  },
  {
    "text": "belajarbersamaganjar",
    "value": "1"
  }
];

export {
    dataWordCloud1,
    dataWordCloud2,
    dataWordCloud3,
    dataWordCloud4,
    dataWordCloud5
}