import React, { PureComponent, Fragment } from "react";
import { Doughnut } from "react-chartjs-2";
import { getSentiment } from "API";
import loading from "media/img/components/loading.gif";
import { Row, Col } from "reactstrap";

const colorScale = ["#007b4a", "#ffaa55", "#9335f3"];

export default class TopicPie extends PureComponent {
  state = {
    data: {}
  };
  async componentDidMount() {
    const total =
      this.props.data.total_neutral +
      this.props.data.total_positive +
      this.props.data.total_negative;
    this.setState({
      data: {
        labels: [
          `Neutral \n ${((this.props.data.total_neutral / total) * 100).toFixed(
            2
          )}%`,
          `Positive \n ${(
            (this.props.data.total_positive / total) *
            100
          ).toFixed(2)}%`,
          `Negative \n ${(
            (this.props.data.total_negative / total) *
            100
          ).toFixed(2)}%`
        ],
        datasets: [
          {
            data: [
              this.props.data.total_neutral,
              this.props.data.total_positive,
              this.props.data.total_negative
            ],
            backgroundColor: ["#007b4a", "#ffaa55", "#9335f3"],
            hoverBackgroundColor: ["#004c2e", "#ba7b3d", "#6525a8"]
          }
        ],
        name: ["Neutral", "Positive", "Negative"],
        value: [0, 5, -5],
        type: this.props.type,
        candidate: this.props.candidate,
        handlePieClick: (data) => this.props.handlePieClick(data)
      }
    });
  }

  render() {
    const { data } = this.state;
    return (
      <Fragment>
        <h4>Share of {this.props.type}</h4>
        <Doughnut
          data={data}
          options={{
            legend: {
              display: true,
              position: "bottom",
              labels: {
                padding: 30
              }
            },
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 30,
                bottom: 10
              }
            },
            onClick: function(c, i) {
              const index = i[0]._index;
              this.data.handlePieClick({
                type: this.data.type,
                candidate: this.data.candidate,
                sentiment_type: this.data.value[index]
              });
            }
          }}
        />
      </Fragment>
    );
  }
}
