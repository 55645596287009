import React, { Fragment, Component } from "react";

import Sidebar from "components/layout/sidebar/Sidebar";
import Map from "components/shared/map/Map";
import ModalDialog from "components/shared/ModalDialog";
import { Dark } from "components/shared/charts/themeVictory";
import SubmenuRegion from "components/layout/sidebar/SubmenuRegion";
import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";
import CustomLabel from "components/shared/charts/CustomLabel";
import GroupedBarTurnout from "components/shared/charts/GroupedBarTurnout";

import { VictoryChart, VictoryBar, VictoryStack, VictoryGroup, VictoryAxis, VictoryLegend, VictoryPie, VictoryTooltip } from "victory";

import PieRechart from "components/shared/charts/PieRechart";

import { getTurnout } from "actions/mapActions";
import {
  horserace,
  reelect,
  undecided_a,
  undecided_b,
  turnout_binary,
  turnout_continuous
} from "components/turnoutscore/turnoutDummy";

import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import "styles/components/map-submenu.css";
import MapTurnout from "components/shared/map/MapTurnout";


const API_HOST = process.env.REACT_APP_API_HOST;

const data_pie = {
  max_value : 100,
  total_value : 100,
  data: [{
    order_id : 1,
    order_name: "Don't Know",
    total_data : 5
  },{
    order_id : 2,
    order_name : "I have lost my job or lost income due to the coronavirus public health and economic crisis",
    total_data : 18
  },{
    order_id : 3,
    order_name : "Someone else in my household or immediate family has lost their job or lost significant income due to the coronavirus public health and economic crisis",
    total_data : 45
  },{
    order_id : 4,
    order_name : "Neither I nor anyone in my household or immediate family has lost their job or lost significant income due to the coronavirus public health and economic crisis",
    total_data : 32
  }]
}

const data_pie_1_raw = [
  { x: 1, y: 5, label: "Don't Know" },
  { x: 2, y: 18, label: "I have lost my job or lost income" },
  { x: 3, y: 45, label: "Someone in family has lost their job" },
  { x: 2, y: 32, label: "All in family has lost their job" }
]

const data_pie_2_raw = [
  { x: 1, y: 8, label: "Already is" },
  { x: 2, y: 11, label: "1-5 months" },
  { x: 3, y: 21, label: "6-12 months" },
  { x: 2, y: 43, label: "more than 12 months" },
  { x: 3, y: 18, label: "Don't Know" }
]

const color_pie_rechart_3 =  [
  "#FFF8DC",
  "#1E90FF",
  "#B22222",
];

const color_pie_rechart =  [
  "#1E90FF",
  "#D8BFD8",
  "#800080",
  "#B22222",
  "#FFF8DC"
];

const color_pie_rechart2 =  [
  "#1E90FF",
  "#D8BFD8",
  "#800080",
  "#FFF8DC"
];

const data_wave1 = [
  { name: "I have lost my job or lost income", value: 5 },
  { name: "Someone in family has lost their job", value: 18 },
  { name: "All in family has lost their job", value: 45 },
  { name: "Don't Know", value: 32 }
];

const data_wave2 = [
  { name: "Already is", value: 8 },
  { name: "1-5 months", value: 11 },
  { name: "6-12 months", value: 21 },
  { name: "more than 12 months", value: 43 },
  { name: "Don't Know", value: 18 }
];

const data_wave3 = [
  { name: "Don't Know", value: 6 },
  { name: "Get the economy moving and put people back to work", value: 48 },
  { name: "Get more people vaccinated - the best way to save lives, get the economy moving and put people back to work", value: 46 }
];

const dataIssuesS5 = [
  {
    "x": "COVID-19",
    "y": 1.58,
    "label": "1.58 %"
  },
  {
    "x": "Corruption",
    "y": 18.51,
    "label": "18.51 %"
  },
  {
    "x": "Cost of living",
    "y": 21.44,
    "label": "21.44 %"
  },
  {
    "x": "Poverty",
    "y": 10.61,
    "label": "10.61 %"
  },
  {
    "x": "Economic growth",
    "y": 13.61,
    "label": "13.61 %"
  },
  {
    "x": "Economic inequality",
    "y": 11.01,
    "label": "11.01 %"
  },
  {
    "x": "Health care",
    "y": 2.09,
    "label": "2.09 %"
  },
  {
    "x": "Jobs",
    "y": 14.06,
    "label": "14.06 %"
  },
  {
    "x": "Education",
    "y": 3.64,
    "label": "3.64 %"
  },
  {
    "x": "Wages",
    "y": 1.83,
    "label": "1.83 %"
  },
  {
    "x": "Dont know",
    "y": 0.61,
    "label": "0.61 %"
  },
  {
    "x": "Other or SPECIFY",
    "y": 0.21,
    "label": "0.21 %"
  },
]

class OverviewDark extends Component {
  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "Survey May 2023",
        map_type: "horserace",
        data: [
          { id: 1, name: "Survey May 2023" },
          { id: 2, name: "Survey 5 (jan 2022)" },
          { id: 3, name: "Survey 4 (jul 2021)" },
          { id: 4, name: "Survey 3 (jun 2021)" },
          { id: 5, name: "Survey 2 (mar 2021)" },
          { id: 6, name: "Survey 1 (nov 2020)" },
        ]
      }
    },
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "Survey May 2023" },
            { id: 2, name: "Survey 5 (jan 2022)" },
            { id: 3, name: "Survey 4 (jul 2021)" },
            { id: 4, name: "Survey 3 (jun 2021)" },
            { id: 5, name: "Survey 2 (mar 2021)" },
            { id: 6, name: "Survey 1 (nov 2020)" },
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
          // err.response.status === 401
          //   ? this.props.reAuth()
          //   : toastr.error(err.response.statusText);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
    // await GET(API_HOST + "/sental/master/pulau")
    //   .then(res =>
    //     this.setState({
    //       ...this.state,
    //       region: {
    //         ...this.state.region,
    //         pulau: { ...this.state.region.pulau, data: res.data },
    //         step: 1
    //       }
    //     })
    //   )
    //   .catch(err => {
    //     err.response.status === 401
    //       ? this.props.reAuth()
    //       : toastr.error(err.response.statusText);
    //   });
  }

  render() {
    const { info, state, handleToggleSatellite } = this.props;
    const {
      openMap,
      isSubOpen,
      openPOI,
      isPOISubOpen,
      region,
      turnout
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition,
      handleGetSubMenu,
      handleChangeMethod
    } = this;

    let dataSource;
    let survey_2_data = {
      max_value: 100,
      total_value: 100,
      data: [
        {
          order_id: 1,
          order_name: "Total Try",
          left_label: "Probably Try",
          right_label: "Definitely Try",
          left_data: 33,
          right_data: 29
        },
        {
          order_id: 2,
          order_name: "Total Not Try",
          left_label: "Probably Not Try",
          right_label: "Definitely Not Try",
          left_data: 9,
          right_data: 7
        },
        {
          order_id: 3,
          order_name: "Total Unsure",
          left_label: "Don't Know",
          right_label: "Might or might not",
          left_data: 6,
          right_data: 16
        },
      ]
  }

  let turnout_confidence_get_vaccinated = {
    max_value: 100,
    total_value: 100,
    data: [
      {
        order_id: 1,
        order_name: "Total confident",
        left_label: "Somewhat confident",
        right_label: "Very confident",
        left_data: 54,
        right_data: 7
      },
      {
        order_id: 2,
        order_name: "Total Not Confident",
        left_label: "Not very confident",
        right_label: "Not at all confident",
        left_data: 31,
        right_data: 2
      },
      {
        order_id: 3,
        order_name: "Total Unsure",
        left_label: "Don't Know",
        right_label: "Don't Know",
        left_data: 3,
        right_data: 3
      },
    ]
}

let bar_chart;
let pie_chart_overview;
let map_details;
let bar_chart_right;
// let groupBarData = survey_2_data;
switch (turnout.method.value) {

  case "Survey May 2023":
    // map_details = <MapTurnout source="turnout" type="overview" />;
    map_details = <MapTurnout source="turnout" type="overview_wave1" />;
    bar_chart = 
      <VictoryChart>
        <VictoryGroup offset={10}
          colorScale={["#b6b7ba","#fc034e", "#6789f0", "#34e095", "#3b4f8c"]}
        >
            <VictoryBar
              data={[
                { x: "COVID-19", y: 61, label: '61%' },
                { x: "Economic", y: 26, label: '26%' },
                { x: "Health", y: 2, label: '2%' },
                { x: "Education", y: 2, label: '2%' },
                { x: "Corruption", y: 5, label: '5%' },
                { x: "Hoax", y: 0, label: '0%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 70, label: '70%' },
                { x: "Economic", y: 19, label: '19%' },
                { x: "Health", y: 1, label: '1%' },
                { x: "Education", y: 1, label: '1%' },
                { x: "Corruption", y: 3, label: '3%' },
                { x: "Hoax", y: 2, label: '2%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 35, label: '35%' },
                { x: "Economic", y: 51, label: '51%' },
                { x: "Health", y: 0, label: '0%' },
                { x: "Education", y: 1, label: '1%' },
                { x: "Corruption", y: 5, label: '5%' },
                { x: "Hoax", y: 1, label: '1%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 2, label: '2%' },
                { x: "Economic", y: 24, label: '24%' },
                { x: "Health", y: 2, label: '2%' },
                { x: "Education", y: 4, label: '4%' },
                { x: "Corruption", y: 18, label: '18%' },
                { x: "Hoax", y: 0, label: '0%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
        </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={10} y={12}
          orientation="horizontal"
          gutter={30}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#fc034e", "#6789f0", "#34e095", "#3b4f8c"]}
          data={[
            {name: "Jun 2021"}, {name: "Jul 2021"},  {name: "Jan 2022"}, {name: "May 2023"}
          ]}
        />
      </VictoryChart>

      bar_chart_right = 
      <VictoryChart
        height={340}
        width={1700}
        domainPadding={10}
        maxDomain={{ y: 26 }}
        // style={{color:'white !important'}}
      >
        <VictoryBar
          data={dataIssuesS5}
          style={{
            data: {
              fill: d =>
                d.x === "COVID-19"
                  ? "#d0021b"
                  : d.x === "Corruption"
                  ? "rgb(6, 0, 194)"
                  : d.x === 'Cost of living'
                  ? "#edbc34" 
                  : d.x === 'Poverty'
                  ? "#eb0202"
                  : d.x === 'Economic growth'
                  ? "#f4ff1c"
                  : d.x === 'Economic inequality'
                  ? "#fabf1c"
                  : d.x === 'Health care'
                  ? "#047522"
                  : d.x === 'Education'
                  ? "#042552"
                  : d.x === 'Wages'
                  ? "#ecac34"
                  : d.x === 'Dont know'
                  ? "#ddd"
                  : d.x === 'Andika Perkasa'
                  ? "#04c437"
                  : d.x === 'Jobs'
                  ? "#f59376" 
                  : "#eee",
              width: 120
            }
          }}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        <VictoryAxis
          style={{
            // axis: { stroke: "none" }
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />
      </VictoryChart>

      pie_chart_overview = <Fragment>
        <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'78px'}}>
          What do you think is the most important issue facing Indonesia today?
        </h5>
        {bar_chart_right }
      </Fragment>;
    break;

  

  case "Survey 5 (jan 2022)":
    // map_details = <MapTurnout source="turnout" type="overview" />;
    map_details = <MapTurnout source="turnout" type="overview_wave1" />;
    bar_chart = 
      <VictoryChart>
        <VictoryGroup offset={10}
          colorScale={["#b6b7ba","#fc034e", "#6789f0", "#34e095", "#3b4f8c"]}
        >
            <VictoryBar
              data={[
                { x: "COVID-19", y: 57, label: '57%' },
                { x: "Economic", y: 26, label: '26%' },
                { x: "Health", y: 5, label: '5%' },
                { x: "Education", y: 2, label: '2%' },
                { x: "Corruption", y: 2, label: '2%' },
                { x: "Hoax", y: 0, label: '0%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 45, label: '45%' },
                { x: "Economic", y: 36, label: '36%' },
                { x: "Health", y: 3, label: '3%' },
                { x: "Education", y: 5, label: '5%' },
                { x: "Corruption", y: 4, label: '4%' },
                { x: "Hoax", y: 0, label: '0%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 61, label: '61%' },
                { x: "Economic", y: 26, label: '26%' },
                { x: "Health", y: 2, label: '2%' },
                { x: "Education", y: 2, label: '2%' },
                { x: "Corruption", y: 5, label: '5%' },
                { x: "Hoax", y: 0, label: '0%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 70, label: '70%' },
                { x: "Economic", y: 19, label: '19%' },
                { x: "Health", y: 1, label: '1%' },
                { x: "Education", y: 1, label: '1%' },
                { x: "Corruption", y: 3, label: '3%' },
                { x: "Hoax", y: 2, label: '2%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 35, label: '35%' },
                { x: "Economic", y: 51, label: '51%' },
                { x: "Health", y: 0, label: '0%' },
                { x: "Education", y: 1, label: '1%' },
                { x: "Corruption", y: 5, label: '5%' },
                { x: "Hoax", y: 1, label: '1%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
        </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={-10} y={12}
          orientation="horizontal"
          gutter={30}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#b6b7ba","#fc034e", "#6789f0", "#34e095", "#3b4f8c"]}
          data={[
            { name: "Nov 2020" }, { name: "Mar 2021"}, {name: "Jun 2021"}, {name: "Jul 2021"},  {name: "Jan 2022"}
          ]}
        />
      </VictoryChart>

      bar_chart_right = 
      <VictoryChart>
        <VictoryGroup offset={12}
          colorScale={["#b6b7ba","#fc034e", "#6789f0", "#34e095", "#3b4f8c"]}
        >
            <VictoryBar
              data={[
                { x: "Cost of living", y: 1, label: '1%' },
                { x: "Poverty", y: 1, label: '1%' },
                { x: "Inequality", y: 2, label: '2%' },
                { x: "Jobs", y: 8, label: '8%' },
                { x: "Economic", y: 11, label: '11%' },
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "Cost of living", y: 4, label: '4%' },
                { x: "Poverty", y: 2, label: '2%' },
                { x: "Inequality", y: 5, label: '5%' },
                { x: "Jobs", y: 9, label: '9%' },
                { x: "Economic", y: 14, label: '14%' },
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "Cost of living", y: 2, label: '2%' },
                { x: "Poverty", y: 1, label: '1%' },
                { x: "Inequality", y: 3, label: '3%' },
                { x: "Jobs", y: 6, label: '6%' },
                { x: "Economic", y: 14, label: '14%' },
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "Cost of living", y: 4, label: '4%' },
                { x: "Poverty", y: 4, label: '4%' },
                { x: "Inequality", y: 4, label: '4%' },
                { x: "Jobs", y: 4, label: '4%' },
                { x: "Economic", y: 3, label: '3%' },
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "Cost of living", y: 20, label: '20%' },
                { x: "Poverty", y: 2, label: '2%' },
                { x: "Inequality", y: 6, label: '6%' },
                { x: "Jobs", y: 9, label: '9%' },
                { x: "Economic", y: 14, label: '14%' },
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
        </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={-20} y={12}
          orientation="horizontal"
          gutter={30}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#b6b7ba","#fc034e", "#6789f0", "#34e095", "#3b4f8c"]}
          data={[
            { name: "Nov 2020" }, { name: "Mar 2021"}, {name: "Jun 2021"}, {name: "Jul 2021"}, {name: "Jan 2022"}
          ]}
        />
      </VictoryChart>

      pie_chart_overview = <Fragment>
        <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'8px'}}>
          What do you think is the most important issue facing Indonesia today?
        </h5>
        {bar_chart_right }
      </Fragment>;
    break;

  
  case "Survey 4 (jul 2021)":
    // map_details = <MapTurnout source="turnout" type="overview" />;
    map_details = <MapTurnout source="turnout" type="overview_wave1" />;
    bar_chart = 
      <VictoryChart>
        <VictoryGroup offset={12}
          colorScale={["#0394fc","#fc034e", "#00dfe3", "#34e095"]}
        >
            <VictoryBar
              data={[
                { x: "COVID-19", y: 57, label: '57%' },
                { x: "Economic", y: 26, label: '26%' },
                { x: "Health", y: 5, label: '5%' },
                { x: "Education", y: 2, label: '2%' },
                { x: "Corruption", y: 2, label: '2%' },
                { x: "Hoax", y: 0, label: '0%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 45, label: '45%' },
                { x: "Economic", y: 36, label: '36%' },
                { x: "Health", y: 3, label: '3%' },
                { x: "Education", y: 5, label: '5%' },
                { x: "Corruption", y: 4, label: '4%' },
                { x: "Hoax", y: 0, label: '0%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 61, label: '61%' },
                { x: "Economic", y: 26, label: '26%' },
                { x: "Health", y: 2, label: '2%' },
                { x: "Education", y: 2, label: '2%' },
                { x: "Corruption", y: 5, label: '5%' },
                { x: "Hoax", y: 0, label: '0%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 70, label: '70%' },
                { x: "Economic", y: 19, label: '19%' },
                { x: "Health", y: 1, label: '1%' },
                { x: "Education", y: 1, label: '1%' },
                { x: "Corruption", y: 3, label: '3%' },
                { x: "Hoax", y: 2, label: '2%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
        </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={18} y={12}
          orientation="horizontal"
          gutter={30}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#0394fc","#fc034e", "#00dfe3", "#34e095"]}
          data={[
            { name: "Nov 2020" }, { name: "Mar 2021"}, {name: "Jun 2021"}, {name: "Jul 2021"}
          ]}
        />
      </VictoryChart>

      bar_chart_right = 
      <VictoryChart>
        <VictoryGroup offset={12}
          colorScale={["#e3e3e3","#e03d3d", "#30b9db", "#59d955"]}
        >
            <VictoryBar
              data={[
                { x: "Cost of living", y: 1, label: '1%' },
                { x: "Poverty", y: 1, label: '1%' },
                { x: "Inequality", y: 2, label: '2%' },
                { x: "Jobs", y: 8, label: '8%' },
                { x: "Economic", y: 11, label: '11%' },
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "Cost of living", y: 4, label: '4%' },
                { x: "Poverty", y: 2, label: '2%' },
                { x: "Inequality", y: 5, label: '5%' },
                { x: "Jobs", y: 9, label: '9%' },
                { x: "Economic", y: 14, label: '14%' },
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "Cost of living", y: 2, label: '2%' },
                { x: "Poverty", y: 1, label: '1%' },
                { x: "Inequality", y: 3, label: '3%' },
                { x: "Jobs", y: 6, label: '6%' },
                { x: "Economic", y: 14, label: '14%' },
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "Cost of living", y: 4, label: '4%' },
                { x: "Poverty", y: 4, label: '4%' },
                { x: "Inequality", y: 4, label: '4%' },
                { x: "Jobs", y: 4, label: '4%' },
                { x: "Economic", y: 3, label: '3%' },
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
        </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={18} y={12}
          orientation="horizontal"
          gutter={30}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#e3e3e3","#e03d3d", "#30b9db", "#59d955"]}
          data={[
            { name: "Nov 2020" }, { name: "Mar 2021"}, {name: "Jun 2021"}, {name: "Jul 2021"}
          ]}
        />
      </VictoryChart>

      pie_chart_overview = <Fragment>
        <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'8px'}}>
          What do you think is the most important issue facing Indonesia today?
        </h5>
        {bar_chart_right }
      </Fragment>;
    break;

  case "Survey 3 (jun 2021)":
    map_details = <MapTurnout source="turnout" type="overview" />;
    bar_chart = 
      <VictoryChart>
        <VictoryGroup offset={18}
          colorScale={["#0394fc","#fc034e", "#00dfe3"]}
        >
            <VictoryBar
              data={[
                { x: "COVID-19", y: 57, label: '57%' },
                { x: "Economic", y: 26, label: '26%' },
                { x: "Health care", y: 5, label: '5%' },
                { x: "Education", y: 2, label: '2%' },
                { x: "Corruption", y: 2, label: '2%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 45, label: '45%' },
                { x: "Economic", y: 36, label: '36%' },
                { x: "Health care", y: 3, label: '3%' },
                { x: "Education", y: 5, label: '5%' },
                { x: "Corruption", y: 4, label: '4%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 61, label: '61%' },
                { x: "Economic", y: 26, label: '26%' },
                { x: "Health care", y: 2, label: '2%' },
                { x: "Education", y: 2, label: '2%' },
                { x: "Corruption", y: 5, label: '5%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
        </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={78} y={12}
          orientation="horizontal"
          gutter={20}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#0394fc","#fc034e", "#00dfe3"]}
          data={[
            { name: "Nov 2020" }, { name: "Mar 2021"}, {name: "Jun 2021"}
          ]}
        />
      </VictoryChart>

      pie_chart_overview = <Fragment>
        <PieRechart data={data_wave3} colors={color_pie_rechart_3}/>
        <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'-36px'}}>
          Indonesians Are Evenly Divided on Prioritizing Vaccines or the Economy First?
        </h5>
      </Fragment>;
    break;

  case "Survey 2 (mar 2021)":

  map_details = <MapTurnout source="turnout" type="overview" />;

  pie_chart_overview = <Fragment>
    <PieRechart data={data_wave2} colors={color_pie_rechart}/>
    <h5 className="col-lg-12" 
      style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'-36px'}}>
      When do you think the economy in Indonesia will be on the right track?
    </h5>
  </Fragment>;

    // pie_chart_overview = <Fragment>
    //   <VictoryPie
    //           colorScale={"qualitative"}
    //           data={data_pie_2_raw}
    //           height={250}
    //           style={{
    //             data: {
    //               fillOpacity: 0.9, stroke: "white", strokeWidth: 2
    //             },
    //             labels: {
    //               fontSize: 12, fill: "white"
    //             }
    //           }}
    //         />
        // <h5 className="col-lg-12" 
        //   style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'-36px'}}>
        //   When do you think the economy in Indonesia will be on the right track?
        // </h5>
    //   </Fragment>

      bar_chart = 
      <VictoryChart>
        <VictoryGroup offset={25}
          colorScale={["#0394fc","#fc034e"]}
        >
            <VictoryBar
              data={[
                { x: "COVID-19", y: 57, label: '57%' },
                { x: "Economic", y: 26, label: '26%' },
                { x: "Health care", y: 5, label: '5%' },
                { x: "Education", y: 2, label: '2%' },
                { x: "Corruption", y: 2, label: '2%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
            <VictoryBar
              data={[
                { x: "COVID-19", y: 45, label: '45%' },
                { x: "Economic", y: 36, label: '36%' },
                { x: "Health care", y: 3, label: '3%' },
                { x: "Education", y: 5, label: '5%' },
                { x: "Corruption", y: 4, label: '4%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
            />
        </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={150} y={20}
          orientation="horizontal"
          gutter={20}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#0394fc","#fc034e"]}
          data={[
            { name: "Nov 2020" }, { name: "Mar 2021" }
          ]}
        />
      </VictoryChart>
    break;
  case "Survey 1 (nov 2020)":

    // let legend_pie = []
    // data_pie_1_raw.forEach((el, idx, ar) => {
    //   legend_pie.push({ name: el.label, symbol: { type: "square" } });
    // })

    // pie_chart_overview = <Fragment>
    //   <PieRechart data={data_wave1} colors={color_pie_rechart}/>
    //  <h5 className="col-lg-12" 
    //     style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'-36px'}}>
    //       Most Indonesians Are Personally Affected by Job/Income Loss?
    //   </h5>
    // </Fragment>;

    map_details = <MapTurnout source="turnout" type="overview_wave1" />;

    pie_chart_overview = <Fragment>  
      <VictoryPie
          colorScale={"qualitative"}
          data={data_pie_1_raw}
          height={220}
          style={{
            data: {
              fillOpacity: 0.9, stroke: "white", strokeWidth: 2
            },
            labels: {
              fontSize: 8, fill: "white"
            }
          }}
        />
      <h5 className="col-lg-12" 
          style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'-36px'}}>
          Most Indonesians Are Personally Affected by Job/Income Loss?
        </h5>
    </Fragment>

    bar_chart = <Fragment>
      <VictoryChart>
        <VictoryGroup offset={25}
          colorScale={["#0394fc"]}
        >
        <VictoryBar
              data={[
                { x: "COVID-19", y: 57, label: '57%' },
                { x: "Economic", y: 26, label: '26%' },
                { x: "Health care", y: 5, label: '5%' },
                { x: "Education", y: 2, label: '2%' },
                { x: "Corruption", y: 2, label: '2%' }
              ]}
              height={300}
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
              // labels={["COVID-19", "Economic", "Health care", "Education", "Corruption" ]}
            />
            </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            axisLabel: { fill: "white" },
            tickLabels: { fill: "white" }
          }}
        />

        <VictoryLegend x={150} y={20}
          orientation="horizontal"
          gutter={20}
          style={{ 
            border: { stroke: "white" },
            labels: { fill: "white" }, 
          }}
          colorScale={["#0394fc"]}
          data={[
            { name: "Nov 2020" }
          ]}
        />
      </VictoryChart>
      </Fragment>

    break;
  default:
    // console.log("on get default value...")
    // console.log(turnout.method.value)
    map_details = <MapTurnout source="turnout" type="overview" />;
    break;
  }

    return (
      <div className="map50">
        <div className="row heightTurnoutBar" style={{borderBottom: '3px solid #932427'}}>
          <div className="col-lg-12">
            <div className="sidebar sidebar-left">
              <div className="sidebar-content">
                <nav className="main-menu">
                  <ul className="nav">
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          // openMap
                          //   ? this.setState({ openMap: false })
                          //   : this.setState({
                          //       openMap: true,
                          //       isSubOpen: 0,
                          //       openPOI: false
                          //     });
                        }}
                      >
                        <i className="icon dripicons-graph-bar" />
                      </a>
                      <SubmenuTurnout
                        handleToggleInfo={handleToggleInfo}
                        handleGetSubMenu={handleGetSubMenu}
                        handleToggleSubMenu={handleTogglePOISubMenu}
                        handleCloseSubmenu={handleCloseSubmenu}
                        handleChangeMethod={handleChangeMethod}
                        placeholder="Select Scoring"
                        data={{
                          info,
                          openPOI,
                          isPOISubOpen,
                          handleSubMenuChange,
                          turnout
                        }}
                      />
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="col-lg-6" style={{ height: "80%", marginTop: "80px", marginBottom: "20px" }}>            
            <h5 className="col-lg-12" 
              style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginBottom:'-10px'}}>
              Most Important Issues in Indonesia
            </h5>
            {bar_chart}
          </div>
          <div className="col-lg-6" style={{ height: "95%", marginTop: "10px", marginBottom: "20px" }}> 
            {pie_chart_overview}
          </div>
        </div>
        {/* <MapBottom source="turnout" type="overview" handleBorderMapClick={()=>{}} /> */}
        {/* <MapTurnout source="turnout" type="overview" /> */}
        {map_details}
        <ModalDialog />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewDark);
