import React, { Fragment, Component } from "react";

// import Sidebar from "components/layout/sidebar/Sidebar";
// import Map from "components/shared/map/Map";
// import ModalDialog from "components/shared/ModalDialog";
// import { Dark } from "components/shared/charts/themeVictory";
// import SubmenuRegion from "components/layout/sidebar/SubmenuRegion";
import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";
// import CustomLabel from "components/shared/charts/CustomLabel";
// import GroupedBarTurnout from "components/shared/charts/GroupedBarTurnout";

import MapBottom from "components/shared/map/MapBottom";

import { VictoryChart, VictoryBar, VictoryStack, VictoryGroup, VictoryAxis, VictoryLegend, VictoryPie, VictoryTooltip } from "victory";
import CustomLabelOverview from "components/shared/charts/CustomLabelOverview";
import PieChartOverview from "components/shared/charts/PieChartOverview";

import { PieChart, Pie, Legend, Tooltip, Cell } from "recharts";
import PieRechart from "components/shared/charts/PieRechart";
import PieRechartLegend from "components/shared/charts/PieRechartLegend";

import BarGroupRechart from "components/shared/charts/BarGroupRechart";

import TopicCloudET from "components/shared/charts/TopicCloudET";
import { setModalTopic } from "actions";
import "styles/components/whatson_et.css";

import { getTurnout } from "actions/mapActions";

import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap,
  getProvince
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import "styles/components/map-submenu.css";
// import MapTurnout from "components/shared/map/MapTurnout";

import {dataWordCloud1, dataWordCloud2, dataWordCloud3, dataWordCloud4, dataWordCloud5 } from "./TopicDataDummy";


const API_HOST = process.env.REACT_APP_API_HOST;

const data01 = [
  { name: "Yes", value: 42 },
  { name: "No", value: 38 },
  { name: "Don't Know", value: 19 }
];

const data11 = [
  { name: "State-owned enterprises should be expanded", value: 65 },
  { name: "There should be fewer state-owned enterprises", value: 8 },
  { name: "Don't Know", value: 17 },
  { name: "State-owned enterprises should not be expanded or reduced ", value: 11 },
];

const data21 = [
  { name: "Very important", value: 62 },
  { name: "Somewhat important", value: 22 },
  { name: "Not very or not at all important", value: 3 },
  { name: "Don’t know", value: 13 },
];

const data22 = [
  { name: "Major problem", value: 87 },
  { name: "Minor problem", value: 6 },
  { name: "Not a problem at all", value: 2 },
  { name: "Don't Know ", value: 5 },
];

const dataPieNetworkBuzzSumo = [
  {
    "name": "Facebook",
    "value": 351
  },
  {
    "name": "Twitter",
    "value": 9
  },
  {
    "name": "Pinterest",
    "value": 0
  },
  {
    "name": "Reddit",
    "value": 0
  }
];

const dataPieTopDomainBuzzSumo = [
  {
    "name": "www.infopresiden.com",
    "value": 866209
  },
  {
    "name": "finance.detik.com",
    "value": 441156
  },
  {
    "name": "www.youtube.com",
    "value": 407836
  },
  {
    "name": "money.kompas.com",
    "value": 339732
  },
  {
    "name": "www.kompas.tv",
    "value": 293931
  },
  {
    "name": "www.cnnindonesia.com",
    "value": 276033
  },
  {
    "name": "bisnis.tempo.co",
    "value": 173906
  },
  {
    "name": "kumparan.com",
    "value": 161532
  },
  {
    "name": "www.indosport.com",
    "value": 88827
  },
  {
    "name": "www.cnbcindonesia.com",
    "value": 69076
  }
];

const COLORS = [
  "#0088FE",
  "#ff2b2b",
  "#bdbdbd",
  "#ed80a1",
  "#f123ff",
  "#a1bb23"
];

class TopicET extends Component {
  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "News Media",
        map_type: "horserace",
        data: [
          { id: 1, name: "News Media" },
          { id: 2, name: "Twitter" },
          { id: 3, name: "Facebook" }
        ]
      }
    },
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null,

    dataWordCloud: dataWordCloud1,

    openSubOne: {
      active: 1,
      opened: false
    },
    openSubTwo: {
      active: 1,
      opened: false
    },
    isLoadingOne: true,
    isLoadingTwo: true
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleHashtagClick = text => {
    this.props.setModalTopic({
      type: "hashtag",
      // path: `whatson/wordcloud/data`,
      path: `whatson/${"sentimen"}/data`,
      params: { keyword: text }
    });
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "News Media" },
            { id: 2, name: "Twitter" },
            { id: 3, name: "Facebook" }
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
    // await GET(API_HOST + "/sental/master/pulau")
    //   .then(res =>
    //     this.setState({
    //       ...this.state,
    //       region: {
    //         ...this.state.region,
    //         pulau: { ...this.state.region.pulau, data: res.data },
    //         step: 1
    //       }
    //     })
    //   )
    //   .catch(err => {
    //     err.response.status === 401
    //       ? this.props.reAuth()
    //       : toastr.error(err.response.statusText);
    //   });
  }

  render() {
    const { info, state, handleToggleSatellite } = this.props;
    const {
      openMap,
      isSubOpen,
      openPOI,
      isPOISubOpen,
      region,
      turnout,
      dataWordCloud,
      openSubOne,
      isLoadingOne
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition,
      handleGetSubMenu,
      handleChangeMethod,
      handleHashtagClick
    } = this;

  

let horizontalChartData = {
  soe:[
  {
    data: [
      { percentage: 83, color: "rgba(2, 159, 176, .8)" },
      { percentage: 13, color: "rgba(60, 210, 240, .8)" },
      { percentage: 2, color: "rgba(240, 66, 60, .8)" },
      { percentage: 0, color: "rgba(148, 5, 0, .8)" },
      { percentage: 2, color: "rgba(184, 180, 180, .8)" }
    ],
    type: "job_approval",
    title: "Small businesses"
  },
  {
    data: [
      { percentage: 80, color: "rgba(2, 159, 176, .8)" },
      { percentage: 14, color: "rgba(60, 210, 240, .8)" },
      { percentage: 2, color: "rgba(240, 66, 60, .8)" },
      { percentage: 1, color: "rgba(148, 5, 0, .8)" },
      { percentage: 2, color: "rgba(184, 180, 180, .8)" }
    ],
    type: "job_approval",
    title: "State-owned enterprises"
  },
  {
    data: [
      { percentage: 59, color: "rgba(2, 159, 176, .8)" },
      { percentage: 30, color: "rgba(60, 210, 240, .8)" },
      { percentage: 7, color: "rgba(240, 66, 60, .8)" },
      { percentage: 1, color: "rgba(148, 5, 0, .8)" },
      { percentage: 3, color: "rgba(184, 180, 180, .8)" }
    ],
    type: "job_approval",
    title: "Large businesses"
  }]
}

let bar_chart;

const RADIAN = Math.PI / 180;

let colors01 =  [
  "#0088FE",
  "#ff2b2b",
  "#bdbdbd"
];

let colors11 =  [
  "#0088FE",
  "#ff2b2b",
  "#a39d9d",
  "#e8e8e8"
];

let colors21 =  [
  "#352afa",
  "#0088FE",
  "#bdbdbd",
  "#e8e8e8"
];

let colors22 =  [
  "#ff2b2b",
  "#bdbdbd",
  "#d9d7fa",
  "#e8e8e8"
];

let colorBuzzSumo = [
  "#FF69B4",
  "#4B0082",
  "#FFFFF0",
  "#FFA07A",
  "#DB7093",
  "#DAA520",
  "#DCDCDC",
  "#7FFF00",
  "#808080",
  "#48D1CC",
  "#708090",
  "#8A2BE2"
]

let pie_chart_top_01 = <PieRechart data={data01} colors={colors01}/>;
let pie_chart_top_11 = <PieRechart data={data11} colors={colors11}/>;
let pie_chart_bottom_21 = <PieRechart data={data21} colors={colors21}/>;
let pie_chart_bottom_22 = <PieRechart data={data22} colors={colors22}/>

bar_chart = 
  <VictoryChart>
    <VictoryStack
      colorScale={["#003996","#07aced","#ff9b0f","red","#9e9d9b"]}
    >
        <VictoryBar
          data = {[
            {x: "Wave 1", y:9, label:'Strongly Approved'},
            {x: "Wave 2", y:47, label:'Strongly Approved'},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        <VictoryBar
          data = {[
            {x: "Wave 1", y:65, label:'Somewhat Approved'},
            {x: "Wave 2", y:35, label:'Somewhat Approved'},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        <VictoryBar
          data = {[
            {x: "Wave 1", y:12, label:'Somewhat Disapproved'},
            {x: "Wave 2", y:6, label:'Somewhat Disapproved'},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        <VictoryBar
          data = {[
            {x: "Wave 1", y:1, label:'Strongly Disapproved'},
            {x: "Wave 2", y:2, label:'Strongly Disapproved'},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
        <VictoryBar
          data = {[
            {x: "Wave 1", y:14, label:"don't know"},
            {x: "Wave 2", y:10, label:"don't know"},
          ]}
          height={300}
          labelComponent={<VictoryTooltip constrainToVisibleArea />}
        />
    </VictoryStack>
    <VictoryAxis
      style={{
        axis: { stroke: "transparent" },
        ticks: { stroke: "transparent" },
        axisLabel: { fill: "white" },
        tickLabels: { fill: "white" }
      }}
    />

    <VictoryLegend x={150} y={20}
      orientation="horizontal"
      gutter={20}
      style={{ 
        border: { stroke: "white" },
        labels: { fill: "white" }, 
      }}
      colorScale={["#0394fc","#fc034e"]}
      data={[
        { name: "Nov 2020" }, { name: "Mar 2021" }
      ]}
    />
  </VictoryChart>

    return (
      <React.Fragment>
        <div className="full-width" style={{background: '#212529'}}>
            <div className="col-lg-12">
              <div className="sidebar sidebar-left">
                <div className="sidebar-content">
                  <nav className="main-menu">
                    <ul className="nav">
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="icon dripicons-graph-bar" />
                        </a>
                        <SubmenuTurnout
                          handleToggleInfo={handleToggleInfo}
                          handleGetSubMenu={handleGetSubMenu}
                          handleToggleSubMenu={handleTogglePOISubMenu}
                          handleCloseSubmenu={handleCloseSubmenu}
                          handleChangeMethod={handleChangeMethod}
                          placeholder="Select Scoring"
                          data={{
                            info,
                            openPOI,
                            isPOISubOpen,
                            handleSubMenuChange,
                            turnout
                          }}
                        />
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
        </div>
        <div className="row mapHeight" style={{borderBottom: '3px solid #932427', background: '#212529'}}>
          <div className="row col-lg-12" style={{height: '40%'}}>
            <div className="col-lg-6" style={{height: "100%",  marginTop: "80px", marginBottom: "20px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/tokoh/et_topline.jpg`}
                    onClick={() => {}}
                    width="350"
                    style={{ marginLeft: "25px", marginRight: "15px", marginTop:"15px",marginBottom:"2px", padding:'5px' }}
                    alt=""
                  />
                  <p style={{display: 'flex', background: '#fc1c3a', fontSize:'1.5em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Number of Articles : <strong> 17,818</strong>
                  </p>
                  <p style={{display: 'flex', background: '#fc1c3a', fontSize:'1.5em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                  Total Engagement  : <strong> 2,016,172</strong>
                  </p>
                  <p style={{display: 'flex', background: '#fc1c3a', fontSize:'1.5em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                  Total Domains  : <strong> 4,575</strong>
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/tokoh/sandi_topline.jpg`}
                        onClick={() => {}}
                        width="90%"
                        style={{ marginLeft: "5px", marginRight: "5px", marginTop:"15px",marginBottom:"2px", padding:'1px' }}
                        alt=""
                      />
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Number of Articles : <strong> 7,436</strong>
                      </p>
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Total Engagement  : <strong> 237,771</strong>
                      </p>
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Total Domains  : <strong> 1,534</strong>
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/tokoh/anies_topline.jpg`}
                        onClick={() => {}}
                        width="90%"
                        style={{ marginLeft: "5px", marginRight: "5px", marginTop:"15px",marginBottom:"2px", padding:'1px' }}
                        alt=""
                      />
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Number of Articles : <strong> 16,960</strong>
                      </p>
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Total Engagement  : <strong> 2,299,100</strong>
                      </p>
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Total Domains  : <strong> 3,262</strong>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/tokoh/ridwan_topline.jpg`}
                        onClick={() => {}}
                        width="90%"
                        style={{ marginLeft: "5px", marginRight: "5px", marginTop:"15px",marginBottom:"2px", padding:'1px' }}
                        alt=""
                      />
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Number of Articles : <strong> 16,996</strong>
                      </p>
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Total Engagement  : <strong> 664,846</strong>
                      </p>
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Total Domains  : <strong> 3,344</strong>
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/tokoh/ganjar_topline.jpg`}
                        onClick={() => {}}
                        width="90%"
                        style={{ marginLeft: "5px", marginRight: "5px", marginTop:"15px",marginBottom:"2px", padding:'1px' }}
                        alt=""
                      />
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Number of Articles : <strong> 10,862</strong>
                      </p>
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Total Engagement  : <strong> 897,220</strong>
                      </p>
                      <p style={{display: 'flex', background: '#fc1c3a', fontSize:'0.7em',  justifyContent:'center', alignItems:'center', color:'white'}}>
                      Total Domains  : <strong> 1,755</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="col-lg-6" style={{height: "100%",  marginTop: "80px", marginBottom: "20px" }}> 
              <div className="row">
                <h3 className="col-lg-12" 
                  style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'-25px', marginBottom: '-15px'}}>
                  <strong>TOPIC</strong> 
                </h3>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                    <ul className="sliding-menu">
                      <li style={{width: 'calc(100%/5)'}}>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState(
                              {
                                ...this.state,
                                openSubOne: {
                                  active: 1,
                                  opened: false
                                },
                                isLoadingOne: true,
                                dataWordCloud: dataWordCloud1
                              },
                              // () => this.handleSubOne(1)
                            );
                          }}
                          className={` ${
                            openSubOne.active === 1 ? "selected" : ""
                          }`}
                        >
                          Pak Erick
                        </a>
                      </li>
                      <li style={{width: 'calc(100%/5)'}}>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            console.log("SUxxx...")
                            this.setState(
                              {
                                ...this.state,
                                openSubOne: {
                                  active: 2,
                                  opened: false
                                },
                                isLoadingOne: true,
                                dataWordCloud: dataWordCloud2
                              },
                              // () => this.handleSubOne(2)
                            );
                          }}
                          className={` ${
                            openSubOne.active === 2 ? "selected" : ""
                          }`}
                        >
                          Sandiaga U.
                        </a>
                      </li>
                      <li style={{width: 'calc(100%/5)'}}>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState(
                              {
                                ...this.state,
                                openSubOne: {
                                  active: 3,
                                  opened: false
                                },
                                isLoadingOne: true,
                                dataWordCloud: dataWordCloud3
                              },
                              // () => this.handleSubOne(2)
                            );
                          }}
                          className={` ${
                            openSubOne.active === 3 ? "selected" : ""
                          }`}
                        >
                          Ridwan K.
                        </a>
                      </li>
                      <li style={{width: 'calc(100%/5)'}}>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState(
                              {
                                ...this.state,
                                openSubOne: {
                                  active: 4,
                                  opened: false
                                },
                                isLoadingOne: true,
                                dataWordCloud: dataWordCloud4
                              },
                              // () => this.handleSubOne(2)
                            );
                          }}
                          className={` ${
                            openSubOne.active === 4 ? "selected" : ""
                          }`}
                        >
                          Anies B.
                        </a>
                      </li>
                      <li style={{width: 'calc(100%/5)'}}>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState(
                              {
                                ...this.state,
                                openSubOne: {
                                  active: 5,
                                  opened: false
                                },
                                isLoadingOne: true,
                                dataWordCloud: dataWordCloud5
                              },
                              // () => this.handleSubOne(2)
                            );
                          }}
                          className={` ${
                            openSubOne.active === 5 ? "selected" : ""
                          }`}
                        >
                          Ganjar P.
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              <div className="row" style={{height: "100%"}}>
                <div className="padding-extra wordcloud" style={{marginTop: '-35px'}}> 
                  <TopicCloudET
                      data={dataWordCloud}
                      handleHashtagClick={handleHashtagClick}
                    />
                </div>
              </div>
            </div>
          </div>
          <div className="row col-lg-12" style={{height: '40%'}}>
            <div className="col-lg-6" style={{ height: "100%", marginTop: "160px", marginBottom: "5px", paddingRight: '50px' }}>  
              <div className="row">
                <h6 className="col-lg-12" 
                  style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'65px', marginBottom: "-25px"}}>
                  Engagement &amp; content over time
                </h6>
              </div>
              <div className="row" style={{height: '80%', marginLeft: '20px', padding:'2px'}}>
                <BarGroupRechart/>
              </div>
            </div>
            <div className="col-lg-6" style={{ height: "100%", marginTop: "150px", marginBottom: "20px" }}> 
              <div className="row">
                <h5 className="col-lg-12" 
                  style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white', marginTop:'15px', marginBottom:'-25px'}}>
                  Top Domain Buzz Source Media &amp; Network
                </h5>
              </div>
              <div className="row" style={{height: "100%", marginTop: '-20px'}}>
                  <div className="col-lg-6">
                    <PieRechart data={dataPieTopDomainBuzzSumo} colors={colorBuzzSumo}/>
                  </div>
                  <div className="col-lg-6">
                  <PieRechart data={dataPieNetworkBuzzSumo} colors={colorBuzzSumo}/>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="map50-bottom-soe">
          <MapBottom source="turnout" type="overview" handleBorderMapClick={()=>{}} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout,
  setModalTopic
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicET);
