import React, { PureComponent, Fragment } from "react";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography
} from "react-simple-maps";
import Legends from "./Legends";
import Jakarta from "./JakartaBesar";
import loading from "media/img/components/loading.gif";
import { Row, Col } from "reactstrap";

class BorderMap extends PureComponent {
  state = {
    loaded: false,
    key: 1
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 500);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        key: this.state.key + 1
      });
    }
  }

  render() {
    return this.props.data && this.props.data.length > 0 ? (
      <Fragment>
        <Legends dataLegend={this.props.data} />

        {/* <div
          style={{
            position: "absolute",
            left: "40px",
            bottom: "10px",
            width: "96px",
            height: "105px",
            cursor: "pointer",
            border: "1px dotted #b2b2b2",
            borderRadius: "15px",
            overflowX: "visible",
            padding: "10px"
          }}
          onClick={() =>
            this.props.handleBorderMapClick({
              kode_prop: 31,
              position: { lat: "-6.231888", lng: "106.846226" },
              scale: 70000,
              value: "DKI JAKARTA"
            })
          }
        >
          <span
            style={{
              position: "absolute",
              left: "15px",
              bottom: "3px",
              fontSize: "0.7em"
            }}
          >
            DKI Jakarta
          </span>
          <Jakarta paslon={this.props.paslon} />
        </div> */}
        {this.state.loaded && (
          <ComposableMap
            projection="mercator"
            projectionConfig={{ scale: 1000 }}
            style={{
              width: "100%"
              //height: "auto"
            }}
            key={this.state.key}
          >
            <ZoomableGroup center={[118, 2]} disablePanning>
              <Geographies
                geography={`${process.env.PUBLIC_URL}/prop_ind_min.json`}
              >
                {(geographies, projection) =>
                  geographies.map((geography, i) => {
                    var fillBorder, summaryVal;

                    this.props.data.map((val, i) => {
                      val.location_code.map(value => {
                        if (value === geography.properties.kode_prop) {
                          fillBorder = val.color;
                        }
                      });
                    });

                    this.props.summary.map((val, i) => {
                      if (val.location_id === geography.properties.kode_prop) {
                        summaryVal = val;
                      }
                    });

                    return (
                      <Geography
                        key={i}
                        geography={geography}
                        projection={projection}
                        onClick={() =>
                          this.props.handleBorderMapClick({
                            kode_prop: geography.properties.kode_prop,
                            value: geography.properties.propinsi,
                            summary: summaryVal
                          })
                        }
                        style={{
                          default: {
                            fill: fillBorder,
                            fillOpacity: 1,
                            stroke: "#607D8B",
                            strokeWidth: 0.75,
                            outline: "none"
                          },
                          hover: {
                            fill: fillBorder,
                            fillOpacity: 0.7,
                            stroke: "#607D8B",
                            strokeWidth: 0.75,
                            outline: "none"
                          },
                          pressed: {
                            fill: "#FF5722",
                            stroke: "#607D8B",
                            strokeWidth: 0.75,
                            outline: "none"
                          }
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
        )}
      </Fragment>
    ) : (
      <Row>
        <Col
          style={{
            width: 650,
            height: 450,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img src={loading} alt="loading" />
        </Col>
      </Row>
    );
  }
}

export default BorderMap;
