import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';

import { reducer as formReducer } from 'redux-form';
import authReducer from 'reducers/authReducer';
import APIReducer from 'reducers/APIReducer';
import modalReducer from 'reducers/modalReducer';
import mapReducer from 'reducers/mapReducer';
import chartReducer from 'reducers/chartReducer';

const reducers = combineReducers({
  API: APIReducer,
  auth: authReducer,
  form: formReducer,
  modal: modalReducer,
  map: mapReducer,
  chart: chartReducer
});

let enhancers;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
if (process.env.NODE_ENV === 'production') {
  enhancers = compose(
    applyMiddleware(thunk)
  );
} else {
  enhancers =   compose(
    composeEnhancers(applyMiddleware(thunk))
  );
}

const store = createStore(
  reducers,
  enhancers
);

export default store;