import React, { Component } from "react";
import "styles/page/authentication.css";
import logo from "media/img/logo-white.png";
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { signIn } from 'actions/authActions';

class Login extends Component {
  handleSubmitForm = values => {
    this.props.signIn(values);
  };

  render() {
    const { auth } = this.props;
    const { handleSubmitForm } = this;

    return (
      <div className="authentication">
        <div className="container">
          <div className="col-md-12 content-center">
            <Formik
              initialValues={{ email: "", password: "" }}
              validate={values => {
                let errors = {};
                if (!values.email) {
                  errors.email = "Email Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid Email";
                }

                if(!values.password){
                  errors.password = "Password Required";
                } 
                return errors;
              }}
              onSubmit={(values) => { //tambah state is submitting di redux
                handleSubmitForm(values);
              }}
            >
              {() => (
                <Form className="login-form">
                  <div className="header">
                    <div className="logo-container">
                      <img src={logo} alt="logo" />
                    </div>
                    <h5>Member Log in</h5>
                  </div>
                  <div className="content">
                    <div className="input-group input-lg">
                      <span className="input-group-addon">
                        <i className="zmdi zmdi-account-circle" />
                      </span>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Your@email.com"
                      />
                    </div>
                    <div className="input-group input-lg">
                      <span className="input-group-addon">
                        <i className="zmdi zmdi-lock" />
                      </span>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="form-control"
                      />
                    </div>
                    <div className="input-group input-lg">
                      <ErrorMessage name="email" component="div" />
                      <ErrorMessage name="password" component="div" />
                    </div>
                  </div>
                  <div className="footer text-right">
                    <div className="form-action">
                      <input
                        type="submit"
                        disabled={auth.isLoading}
                        className="btn btn-primary btn-block "
                        value="Sign In"
                      />
                    </div>
                    {/* <h5>
                      <a href="forgot-password.html" className="link">
                        Lupa kata sandi anda?
                      </a>
                    </h5> */}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth : state.auth
})

const mapDispatchToProps = {
  signIn
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
