import React from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { closeModal } from "actions";
import BorderKab from "components/shared/map/BorderKab";
import StackedBarCenterAxis from "components/shared/charts/StackedBarCenterAxis";
import NumberEasing from "che-react-number-easing";
import { getDemography } from "actions/mapActions";
import GroupedBar from "./charts/GroupedBar";

import "styles/vendor/animate.css";

ReactModal.setAppElement("#root");

class ModalOverview extends React.Component {
  render() {
    const { modal, demography, total_dpt, ishoverKab } = this.props;
    console.log(modal.data.summary);
    return (
      <ReactModal
        isOpen={modal.show}
        contentLabel="Minimal Modal Example"
        className="modalOverview"
      >
        <span
          className="button-close-diagonal"
          onClick={e => {
            e.preventDefault();
            this.props.closeModal();
          }}
        >
          <i className="icon dripicons-cross" />
        </span>
        <div className="row overview-modal-percentage">
          <div className="col-lg-12 modal-map">
            <div className="stack-item active">
              <h3>{modal.data.value}</h3>
            </div>
          </div>
        </div>
        <div className="row overview-modal-percentage">
          <div className="col-lg-3">
            <div className="stack-item active">
              <h4>Jokowi - Ma'ruf</h4>
              <span>
                {modal.data.summary && modal.data.summary.total_calon_1}%
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="stack-item active">
              <h4>Prabowo - Sandi</h4>
              <span>
                {modal.data.summary && modal.data.summary.total_calon_2}%
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="stack-item active">
              <h4>Undecided</h4>
              <span>
                {modal.data.summary && modal.data.summary.total_undecided}%
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="stack-item active">
              <h4>Others</h4>
              <span>
                {modal.data.summary && modal.data.summary.total_others}%
              </span>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.modal,
  demography: state.map.demography,
  ishoverKab: state.map.ishoverKab,
  total_dpt: state.map.province.total_dpt
});

const mapDispatchToProps = {
  closeModal,
  getDemography
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalOverview);
