export const myConfig = {
  automaticRearrangeAfterDropNode: false,
  collapsible: false,
  height: window.innerHeight,
  highlightDegree: 1,
  highlightOpacity: 0.2,
  linkHighlightBehavior: true,
  maxZoom: 8,
  minZoom: 0.1,
  nodeHighlightBehavior: true,
  panAndZoom: false,
  staticGraph: false,
  width: window.innerWidth,
  d3: {
    gravity: -500
  },
  node: {
    color: "#d3d3d3",
    fontColor: "black",
    fontSize: 12,
    fontWeight: "normal",
    highlightColor: "red",
    highlightFontSize: 12,
    highlightFontWeight: "bold",
    highlightStrokeColor: "SAME",
    highlightStrokeWidth: 1.5,
    labelProperty: "name",
    mouseCursor: "pointer",
    opacity: 1,
    renderLabel: true,
    size: 450,
    strokeColor: "none",
    strokeWidth: 1.5,
    svg: "",
    symbolType: "circle"
  },
  link: {
    color: "#d3d3d3",
    labelProperty: 'label',
    directed: true,
    renderLabel: true,
    opacity: 1,
    semanticStrokeWidth: true,
    strokeWidth: 1,
    highlightColor: "blue",
    type: "CURVE_SMOOTH"
  }
};