import React from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { closeModal } from "actions";
import BorderKab from "components/shared/map/BorderKab";
import StackedBarCenterAxis from "components/shared/charts/StackedBarCenterAxis";
import NumberEasing from "che-react-number-easing";
import { getDemography } from "actions/mapActions";
import GroupedBar from "./charts/GroupedBar";

import "styles/vendor/animate.css";

ReactModal.setAppElement("#root");

class ModalDialog extends React.Component {
  state = {
    isHover: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.modal !== this.props.modal && this.props.modal.show) {
      this.props.getDemography(this.props.modal.data.kode_prop);
    }
  }
  render() {
    const { modal, demography, total_dpt, ishoverKab } = this.props;
    return (
      <ReactModal isOpen={modal.show} contentLabel="Minimal Modal Example">
        <span
          className="button-close-diagonal"
          onClick={e => {
            e.preventDefault();
            this.props.closeModal();
          }}
        >
          <i className="icon dripicons-cross" />
        </span>
        <div className="row">
          <div className="col-lg-6 modal-map">
            <div className="stack-item active">
              <h3>{modal.data.value}</h3>
            </div>
            <div className="stack-item active">
              <h4>TOTAL TURNOUT MEMILIH di {modal.data.value}</h4>
              <span className="stack-total-penduduk red">
                <NumberEasing
                  value={demography.gender.total_dpt}
                  speed={2500}
                  ease="quintInOut"
                  useLocaleString={true}
                  precision={0}
                />
              </span>
              <span className="stack-small">
                {" "}
                {demography.gender.percent_dpt.toFixed(3)}% dari DPT Nasional
              </span>
              <div
                className="kabupaten-box"
                onMouseOver={() => this.setState({ isHover: true })}
                onMouseOut={() => this.setState({ isHover: false })}
              >
                {demography.kota_kab.map((d, i) => (
                  <div
                    className="stack-small"
                    key={i}
                    style={{
                      backgroundColor: `${ishoverKab === d.id ? "rgba(166, 28, 29, 0.3)" : ""}`
                    }}
                  >
                    <span>{d.name}</span>
                    <span>{d.percent_total_dpt.toFixed(3)}%</span>
                  </div>
                ))}
                <i
                  className={`icon dripicons-chevron-down animated infinite bounce ${
                    this.state.isHover ? "hide" : ""
                  }`}
                />
              </div>
            </div>
            <div className="stack-item active">
              <div className="stack-two">
                <div className="stack-two-items">
                  <h4>Pria</h4>
                  <span className="stack-total-gender pria">
                    <NumberEasing
                      value={demography.gender.total_male}
                      speed={2500}
                      ease="quintInOut"
                      useLocaleString={true}
                      precision={0}
                    />
                  </span>
                </div>
                <div className="stack-two-items">
                  <h4>Wanita</h4>
                  <span className="stack-total-gender wanita">
                    <NumberEasing
                      value={demography.gender.total_female}
                      speed={2500}
                      ease="quintInOut"
                      useLocaleString={true}
                      precision={0}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 map-kabupaten">
            <BorderKab data={demography.kota_kab}/>
          </div>
          <div className="col-lg-4 modal-map map-three">
            <div className="stack-item active">
              <h4>Kelompok Umur</h4>
              <StackedBarCenterAxis age={demography.age_details} />
            </div>
          </div>
          <div className="col-lg-4 modal-map map-three">
            <div className="stack-item active">
              <h4>
                Jumlah Rumah Tangga dan Individu menurut Provinsi dan Status
                Kesejahteraan di Indonesia.
              </h4>
              <GroupedBar
                data={demography.kesejahteraan}
                color={["#bd10e0", "#f63936"]}
                total={total_dpt}
              />
            </div>
          </div>
          <div className="col-lg-4 modal-map map-three">
            <div className="stack-item active">
              <h4>
                Individu Yang Bersekolah Menurut Tingkat Pendidikan dan Jenis
                Kelamin.
              </h4>
              <GroupedBar
                data={demography.pendidikan}
                color={"qualitative"}
                total={total_dpt}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.modal,
  demography: state.map.demography,
  ishoverKab: state.map.ishoverKab,
  total_dpt: state.map.province.total_dpt
});

const mapDispatchToProps = {
  closeModal,
  getDemography
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDialog);
