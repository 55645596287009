import React from "react";
import { connect } from "react-redux";

import SideInfoDemography from "./SideInfoDemography";

import {
    getLegendsDemography,
    getDemographySidePanelDemography
  } from "actions/mapActions";

import { makeStyles } from '@material-ui/core/styles';
import { Card, Select  } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl'

// const provinceTest = [
//     {
//         "id": "-1",
//         "label": "National"
//     },
//     {
//         "id": "31",
//         "label": "DKI Jakarta"
//     },
//     {
//         "id": "32",
//         "label": "Jawa Barat"
//     },
//     {
//         "id": "33",
//         "label": "Jawa Tengah"
//     },
//     {
//         "id": "35",
//         "label": "Jawa Timur"
//     }
// ]

const masterProvince = [
    {
        "location_id": "-1",
        "location_name": "National"
    },
    {
      "location_id": "32",
      "location_name": "Jawa Barat"
    },
    {
      "location_id": "75",
      "location_name": "Gorontalo"
    },
    {
      "location_id": "64",
      "location_name": "Kalimantan Timur"
    },
    {
      "location_id": "17",
      "location_name": "Bengkulu"
    },
    {
      "location_id": "19",
      "location_name": "Bangka Belitung"
    },
    {
      "location_id": "12",
      "location_name": "Sumatera Utara"
    },
    {
      "location_id": "63",
      "location_name": "Kalimantan Selatan"
    },
    {
      "location_id": "36",
      "location_name": "Banten"
    },
    {
      "location_id": "71",
      "location_name": "Sulawesi Utara"
    },
    {
      "location_id": "52",
      "location_name": "Nusa Tenggara Barat"
    },
    {
      "location_id": "11",
      "location_name": "Aceh"
    },
    {
      "location_id": "62",
      "location_name": "Kalimantan Tengah"
    },
    {
      "location_id": "34",
      "location_name": "Yogyakarta"
    },
    {
      "location_id": "72",
      "location_name": "Sulawesi Tengah"
    },
    {
      "location_id": "73",
      "location_name": "Sulawesi Selatan"
    },
    {
      "location_id": "76",
      "location_name": "Sulawesi Barat"
    },
    {
      "location_id": "64",
      "location_name": "Kalimantan Utara"
    },
    {
      "location_id": "15",
      "location_name": "Jambi"
    },
    {
      "location_id": "81",
      "location_name": "Maluku"
    },
    {
      "location_id": "33",
      "location_name": "Jawa Tengah"
    },
    {
      "location_id": "51",
      "location_name": "Bali"
    },
    {
      "location_id": "35",
      "location_name": "Jawa Timur"
    },
    {
      "location_id": "61",
      "location_name": "Kalimantan Barat"
    },
    {
      "location_id": "31",
      "location_name": "Jakarta"
    },
    {
      "location_id": "94",
      "location_name": "Papua"
    },
    {
      "location_id": "91",
      "location_name": "Papua Barat"
    },
    {
      "location_id": "53",
      "location_name": "Nusa Tenggara Timur"
    },
    {
      "location_id": "82",
      "location_name": "Maluku Utara"
    },
    {
      "location_id": "21",
      "location_name": "Kepulauan Riau"
    },
    {
      "location_id": "13",
      "location_name": "Sumatera Barat"
    },
    {
      "location_id": "18",
      "location_name": "Lampung"
    },
    {
      "location_id": "16",
      "location_name": "Sumatera Selatan"
    },
    {
      "location_id": "74",
      "location_name": "Sulawesi Tenggara"
    },
    {
      "location_id": "14",
      "location_name": "Riau"
    }
  ]

  const refMasterTrend24 = {11: 'medan', 51: 'surabaya', 19: 'medan', 36: 'jakarta', 17: 'medan', 75: 'jakarta', 31: 'jakarta', 15: 'medan', 32: 'bandung', 33: 'semarang', 35: 'surabaya', 61: 'surabaya', 63: 'surabaya', 62: 'surabaya', 64: 'surabaya', 21: 'jakarta', 18: 'jakarta', 81: 'jakarta', 82: 'jakarta', 52: 'jakarta', 53: 'jakarta', 91: 'jakarta', 92: 'jakarta', 14: 'jakarta', 76: 'jakarta', 73: 'jakarta', 72: 'jakarta', 74: 'jakarta', 71: 'jakarta', 13: 'medan', 16: 'medan', 12: 'medan', 34: 'semarang', 93: 'jakarta', 94: 'jakarta', 95: 'jakarta', 96: 'jakarta'}

class SidePanelDemography extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        type: this.props.type,
        region: this.props.region,
        urlTrend: this.props.urlTrend,
        trends: this.props.trends
    };

    async componentDidMount() {
        // await this.props.getLegendsDemography('unknown', 'all');  
        await this.props.getDemographySidePanelDemography(this.state.region);

        if (this.props.trends !==  this.state.trends) {
          console.log('inside side panel componentDidMount..')
          console.log(this.props.trends);
          this.setState({
            trends: this.props.trends
          })
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if(this.props.region !== this.state.region) {
            this.setState({
                region: this.props.region
            })

            this.props.getDemographySidePanelDemography(this.props.region);
        } 

        if (this.props.trends !==  this.state.trends) {
          console.log('inside side panel..')
          console.log(this.props.trends);
          this.setState({
            trends: this.props.trends
          })

          localStorage.setItem("trends24", JSON.stringify(this.props.trends));
        }
    }

    handleChange = (event) => {
        const name = event.target.name;

        // if(event.target.value !== undefined) {
        //   const urlT = "https://trends24.in/indonesia/" + refMasterTrend24[parseInt(event.target.value)]
          
        //   console.log("xxx urlTrend : " + urlT)
        //   this.setState({
        //     region: event.target.value,
        //     urlTrend: urlT
        //   });
        // } else {
        //   this.setState({
        //       region: event.target.value,
        //   });
        // }

        const urlT = "https://trends24.in/indonesia/" + refMasterTrend24[parseInt(event.target.value)]
          
        console.log("xxx urlTrend : " + urlT)
        this.setState({
          region: event.target.value,
          urlTrend: urlT
        });

        //call api get province
        console.log(event.target.value);
        this.props.getDemographySidePanelDemography(event.target.value);  

    };
    

    render() {
        const { info } = this.props;
        const { region } = this.state;

        return (
            <>
                <div className="row" style={{marginTop: '10px', marginBottom: '20px'}}>
                    <div className="col-md-12">
                        <Card style={{background: '#282828', padding: '20px'}}>
                            <div className="row" style={{padding: '0px 20px', color: 'white', fontSize: '16px'}}>
                                <strong>Region</strong>
                            </div>
                            <div className="row" style={{padding: '5px 20px 20px 20px'}}>
                                <Select
                                    value={region}
                                    onChange={this.handleChange}
                                    label="National"
                                    fullWidth
                                    style={{background: '#4D4C51', color:"white", borderRadius: '4px', padding: '10px 20px', fontSize: '12px', textTransform:'capitalize'}}
                                >
                                    {
                                        masterProvince.map((val, idx) => (
                                            <MenuItem value={val.location_id} style={{color:"#4D4C51", padding: '5px 8px'}}>
                                                {val.location_name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                            
                        </Card>
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Card style={{background: '#282828'}}>
                            <SideInfoDemography region={this.state.region} urlTrend={this.state.urlTrend} trends={this.state.trends} />
                        </Card>
                    </div>
                </div>
                
            </>
        )
    }
}
  
const mapStateToProps = state => ({
    info: state.map
});
  
const mapDispatchToProps = {
    getLegendsDemography,
    getDemographySidePanelDemography
};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidePanelDemography);

// export default SidePanel;