import React, { Component, Fragment, PureComponent } from "react";
import * as d3 from "d3";
import "styles/components/horse-race.css";
import moment from "moment";

import profile1illust from "media/img/avatars/et1.png";
import profile2illust from "media/img/avatars/1-1.png";
import profileTopRight from "media/img/avatars/national_anies.png";
import profileTopLeft from "media/img/avatars/national_prabowo.png";

import { ArrowRight, ArrowUp, ArrowDown } from 'react-bootstrap-icons';

export class HorseRaceChart4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.isNational
        ? this.props.data.location_name
        : this.props.data.data[0].name,
      map_id: this.props.isNational
        ? this.props.data.location_id
        : this.props.data.data[0].id,
      dataSource: this.props.data.data,
      vote_1: this.props.isNational
        ? this.props.data.total_vote_1
        : this.props.data.data[0].vote_1,
      vote_2: this.props.isNational
        ? this.props.data.total_vote_2
        : this.props.data.data[0].vote_2,
      vote_1_percent: this.props.isNational
        ? this.props.data.total_vote_1_percent
        : this.props.data.data[0].vote_1_percent,
      vote_2_percent: this.props.isNational
        ? this.props.data.total_vote_2_percent
        : this.props.data.data[0].vote_2_percent,
      total_1_fav: this.props.isNational
        ? this.props.data.total_1_fav_national
        : this.props.data.data[0].total_1_fav,
      total_2_fav: this.props.isNational
        ? this.props.data.total_2_fav_national
        : this.props.data.data[0].total_2_fav
    };
  }

  handlePieHover = id => {
    // console.log("XXXXX id prov mouser over...");
    // console.log(this.state.dataSource[id].id);

    //set map Id to parent
    this.props.handleToUpdatePieHover(this.state.dataSource[id].id);

    this.setState({
      text: this.state.dataSource[id].name,
      map_id: this.props.isProv
        ? this.state.map_id
        : this.state.dataSource[id].id,
      vote_1: this.state.dataSource[id].vote_1,
      vote_2: this.state.dataSource[id].vote_2,
      vote_1_percent: this.state.dataSource[id].vote_1_percent,
      vote_2_percent: this.state.dataSource[id].vote_2_percent,
      total_1_fav: this.state.dataSource[id].total_1_fav,
      total_2_fav: this.state.dataSource[id].total_2_fav
    });
  };

  initState = () => {
    this.setState({
      ...this.state,
      dataSource: this.props.data.data,
      text: this.props.isNational
        ? this.props.data.location_name
        : this.props.data.data[0].name,
      map_id: this.props.isNational
        ? this.props.data.location_id
        : this.props.data.data[0].id,
      vote_1: this.props.isNational
        ? this.props.data.total_vote_1
        : this.props.data.data[0].vote_1,
      vote_2: this.props.isNational
        ? this.props.data.total_vote_2
        : this.props.data.data[0].vote_2,
      vote_1_percent: this.props.isNational
        ? this.props.data.total_vote_1_percent
        : this.props.data.data[0].vote_1_percent,
      vote_2_percent: this.props.isNational
        ? this.props.data.total_vote_2_percent
        : this.props.data.data[0].vote_2_percent,
      total_1_fav: this.props.isNational
        ? this.props.data.total_1_fav_national
        : this.props.data.data[0].total_1_fav,
      total_2_fav: this.props.isNational
        ? this.props.data.total_2_fav_national
        : this.props.data.data[0].total_2_fav
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.data !== this.props.data.data) {
      // this.setState({
      //   ...prevState,
      //   dataSource: this.props.data.data
      // })
      this.initState();
    }
  }

  render() {
    const chartWidth = (window.innerWidth / 2) * 1.2,
      width = chartWidth,
      height = chartWidth,
      radius = chartWidth / 2.5,
      outerRadius = radius * 1.02,
      innerRadius = radius * 0.8;
    const { handlePieClick, isProv, period } = this.props;
    const {
      dataSource,
      vote_1,
      vote_2,
      vote_1_percent,
      vote_2_percent,
      total_1_fav,
      total_2_fav,
      map_id
    } = this.state;
    const { handlePieHover, initState } = this;

    let dataInner = [];
    let dataCircle = [1, 1, 1, 1];

    for (let i = 0; i < dataSource.length; i++) {
      dataInner.push(1);
    }

    let pie = d3.pie()(dataInner);
    let halfPie = d3.pie()(dataCircle);
    let imgParent = chartWidth / 7;
    let imgPaslon = chartWidth / 3.5;
    let imgSize = imgPaslon;

    let textDate;
    switch (period.value) {
      case "pemilu2014":
        textDate = "TAHUN 2021";
        break;
      case "pilkada2017":
        textDate = "TAHUN 2021";
        break;
      case "pilkada2018":
        textDate = "TAHUN 2021";
        break;
      case "A":
        textDate = "TOMORROW";
        break;
      case "B":
        textDate = "NEXT WEEK";
        break;
      case "C":
        textDate = "17 APRIL";
        break;
      default:
        textDate = moment().format("DD MMM YYYY");
        break;
    }

    let additionalTopChart;
    let additionalInfoTopChart;
    let isNationalText = false;
    if(this.state.text == 'National') {
      isNationalText = true;

      additionalTopChart = <Fragment>
        <svg x={-imgPaslon - (imgPaslon / 30)} y={- 1.008 * imgPaslon} height={imgPaslon}>
              <image
                width={imgSize}
                height={imgSize}
                xlinkHref={profileTopLeft}
              />
            </svg>
            <svg
              x={(imgPaslon / 4) - (imgPaslon / 4.5)}
              y={- 1.008 * imgPaslon}
              width={imgPaslon}
              height={imgPaslon}
            >
              <image
                width={imgSize}
                height={imgSize}
                xlinkHref={profileTopRight}
              />
            </svg>
      </Fragment>

      additionalInfoTopChart = <Fragment>
        <text>
            <tspan
              x={-imgPaslon / 1.35}
              y={- 0.597 * imgPaslon}
              textAnchor="middle"
              fontSize={imgParent / 12}
              fill="#fff"
            >
              Electability
            </tspan>
            <tspan
              x={imgPaslon / 1.35}
              y={- 0.597 * imgPaslon}
              textAnchor="middle"
              fontSize={imgParent / 12}
              fill="#fff"
            >
              Electability
            </tspan>
          </text>
          <text>
            <tspan
              x={-imgPaslon / 1.35}
              y={- 0.497 * imgPaslon}
              textAnchor="middle"
              fontSize={imgParent / 5}
              fill="#fff"
            >
              16% 
            </tspan>
            <tspan
              x={imgPaslon / 1.35}
              y={- 0.497 * imgPaslon}
              textAnchor="middle"
              fontSize={imgParent / 5}
              fill="#fff"
            >
              10%
            </tspan>
          </text>
          <text>
            <tspan
              x={-imgPaslon / 1.25}
              y={- 0.377 * imgPaslon}
              textAnchor="middle"
              fontSize={imgParent / 6}
              fill="#fff"
            >
              #2
            </tspan>
            <tspan
              x={imgPaslon / 1.23}
              y={- 0.377 * imgPaslon}
              textAnchor="middle"
              fontSize={imgParent / 6}
              fill="#fff"
            >
              #5
            </tspan>
          </text>
          <text>
          <tspan
              x={-imgPaslon / 1.25}
              y={- 0.317 * imgPaslon}
              textAnchor="middle"
              fontSize={imgParent / 11}
              fill="#fff"
            >
              Rank
            </tspan>
            <tspan
              x={imgPaslon / 1.25}
              y={- 0.317 * imgPaslon}
              textAnchor="middle"
              fontSize={imgParent / 11}
              fill="#fff"
            >
              Rank
            </tspan>
          </text>
      </Fragment>
    } else {
      isNationalText = false;

      additionalTopChart = <Fragment>
        <image
            x={map_id == "-1" ?( -imgParent - (imgParent / 5)) : "-70"}
            y={-imgParent * 2}
            height={map_id == "-1" ? imgParent * 2 : imgParent}
            xlinkHref={`${process.env.PUBLIC_URL}/img/map/${map_id}.svg`}
          />
      </Fragment>

      additionalInfoTopChart = <Fragment></Fragment>
    }

    return (
      <svg height={height} width={width}>
        <g transform={`translate(${width / 2},${height / 2})`}>
          <circle r={outerRadius} fill={"white"} />

          <Slice
            chartDetail={{
              pie,
              dataSource,
              radius,
              handlePieClick,
              handlePieHover,
              initState,
              isProv
            }}
          />
          <InnerSlice chartDetail={{ halfPie, innerRadius, isNationalText }} />
          {additionalTopChart}
          <defs>
            <filter x="0" y="0" width="1" height="1" id="solid">
            {/* <feFlood flood-color="#932427" result="bg" /> */}
            <feFlood result="bg" />
            <feMerge>
                <feMergeNode in="bg"/>
                <feMergeNode in="SourceGraphic"/>
            </feMerge>
            </filter>
          </defs>
          <text fill="white" filter="url(#solid)">
            <tspan
              x="0"
              y="-30"
              textAnchor="middle"
              // fontWeight="bold"
              fontSize={
                this.state.text.length < 20 ? imgParent / 3 : imgParent / 4
              }
            >
              {this.state.text}
            </tspan>
            <tspan x="0" y="-7" textAnchor="middle" fontSize="16">
              {textDate}
            </tspan>
          </text>
          <svg
            x={(imgPaslon / 4) - (imgPaslon / 4.5)}
            y={imgPaslon / 30 + 5}
            width={imgPaslon}
            height={imgPaslon}
          >
            <image
              width={imgSize}
              height={imgSize}
              xlinkHref={`${process.env.PUBLIC_URL}/img/tokoh/${map_id}.png`}
            />
          </svg>
          <svg x={-imgPaslon - (imgPaslon / 30)} y={imgPaslon / 30 + 10} height={imgPaslon}>
            <image
              width={imgSize}
              height={imgSize}
              xlinkHref={profile1illust}
            />
            {/* <path fill="#42f5d7" fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/> */}
          </svg>
          {additionalInfoTopChart}
          <text>
            <tspan
              x={-imgPaslon / 4.1}
              y={imgPaslon / 12}
              textAnchor="middle"
              fontSize={imgParent / 12}
              fill="#fff"
            >
              Electability
            </tspan>
            <tspan
              x={imgPaslon / 1.2}
              y={imgPaslon / 12 }
              textAnchor="middle"
              fontSize={imgParent / 12}
              fill="#fff"
            >
              Electability
            </tspan>
          </text>
          <text>
            <tspan
              x={-imgPaslon / 4.1}
              y={imgPaslon / 5}
              textAnchor="middle"
              fontSize={imgParent / 4}
              fill="#fff"
            >
              {vote_1_percent}%
            </tspan>
            <tspan
              x={imgPaslon / 1.2}
              y={imgPaslon / 5}
              textAnchor="middle"
              fontSize={imgParent / 4}
              fill="#fff"
            >
              {vote_2_percent}%
            </tspan>
          </text>
          <text>
          <tspan
              x={-imgPaslon / 4.7}
              y={imgPaslon / 5 + 41}
              textAnchor="middle"
              fontSize={imgParent / 6}
              fill="#fff"
            >
              #3
            </tspan>
            <tspan
              x={imgPaslon / 1.2}
              y={imgPaslon / 5 + 41}
              textAnchor="middle"
              fontSize={imgParent / 6}
              fill="#fff"
            >
              #1
            </tspan>
          </text>
          <text>
          <tspan
              x={-imgPaslon / 4.7}
              y={imgPaslon / 5 + 62}
              textAnchor="middle"
              fontSize={imgParent / 11}
              fill="#fff"
            >
              Rank
            </tspan>
            <tspan
              x={imgPaslon / 1.2}
              y={imgPaslon / 5 + 62}
              textAnchor="middle"
              fontSize={imgParent / 11}
              fill="#fff"
            >
              Rank
            </tspan>
          </text>
        </g>
      </svg>
    );
  }
}

const InnerSlice = props => {
  let { halfPie, innerRadius, isNationalText } = props.chartDetail;

  let arc = d3
    .arc()
    .outerRadius(innerRadius * 0.98)
    .innerRadius(2)
    .padAngle(0.02)
    .cornerRadius(2);
  let sliceColor;
  if(isNationalText) {
    sliceColor = ["#1f496f", "#1f496f", "#932427", "#1f496f"];
  } else {
    sliceColor =  ["white", "#1f496f", "#932427", "white"];
  }

  return halfPie.map((slice, index) => {
    return <path key={index} d={arc(slice)} fill={sliceColor[index]} />;
  });
};

const Slice = props => {
  let {
    pie,
    dataSource,
    radius,
    handlePieClick,
    handlePieHover,
    initState,
    isProv
  } = props.chartDetail;

  let interpolateInner = d3.interpolateRgb("#eaaf79", "#bc3358");

  return pie.map((slice, index) => {
      // console.log(dataSource[index]);
    const color =
      dataSource[index].vote_1_percent > dataSource[index].vote_2_percent
        ? dataSource[index].color_1
        : dataSource[index].color_2;
    const kode_prop = dataSource[index].id;
    return (
      <Path
        key={index}
        pieID={index}
        kode_prop={kode_prop}
        radius={radius}
        isProv={isProv}
        interpolate={interpolateInner}
        slice={slice}
        sliceColor={color}
        onPieClick={handlePieClick}
        onPieHover={handlePieHover}
        initState={initState}
      />
    );
  });
};

class Path extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false
    };
  }

  onMouseOver = id => {
    this.props.onPieHover(id);
    this.setState({
      isHovered: true
    });
  };

  onMouseOut = () => {
    this.setState({
      isHovered: false
    });
    this.props.initState();
    // this.props.handleToUpdatePieHover('00')
  };

  render() {
    let {
      radius,
      slice,
      sliceColor,
      pieID,
      onPieClick,
      kode_prop,
      isProv
    } = this.props;

    const outerRadius = this.state.isHovered ? radius * 1.05 : radius;
    const innerRadius = radius * 0.8;

    const arc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .padAngle(0.01)
      .cornerRadius(2);

    return (
      <g className="svg-hover">
        <path
          d={arc(slice)}
          fill={sliceColor}
          onMouseOver={() => this.onMouseOver(pieID)}
          onMouseOut={this.onMouseOut}
          onClick={() => onPieClick(isProv ? "" : kode_prop, isProv)}
        />
      </g>
    );
  }
}
