import React, { PureComponent } from "react";
import { VictoryChart, VictoryBar, VictoryGroup, VictoryAxis } from "victory";
import { Dark } from "./themeVictory";
import CustomLabel from "./CustomLabel";

class GroupedBar extends PureComponent {
  state = {
    dataA: [],
    dataB: [],
    color: [],
    max_val: 100
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data.data !== this.props.data.data) {
      let dataA = [],
        dataB = [],
        max_val = (this.props.data.max_value / this.props.data.total_value) * 100;
      this.props.data.data.map(d => {
        dataA.push({
          x: d.order_name,
          y: (d.left_data / this.props.data.total_value) * 100,
          label: `${d.left_label} ${d.order_name} \n ${(
            (d.left_data / this.props.data.total_value) *
            100
          ).toFixed(1)}%`
        });
        dataB.push({
          x: d.order_name,
          y: (d.right_data / this.props.data.total_value) * 100,
          label: `${d.right_label} ${d.order_name} \n ${(
            (d.right_data / this.props.data.total_value) *
            100
          ).toFixed(1)}%`
        });
      });

      this.setState({
        dataA,
        dataB,
        color: this.props.color,
        max_val: Math.round(max_val + 10)
      });
    }
  }
  render() {
    const { dataA, dataB, color, max_val } = this.state;
    return (
      <VictoryChart theme={Dark} animate={{duration: 1000}}>
        <VictoryGroup
          offset={25}
          colorScale={color}
          style={{
            labels: { fontSize: 22, fill: "#121212", fontWeight: 500 }
          }}
        >
          <VictoryBar
            data={dataA}
            labelComponent={<CustomLabel />}
            domain={{ y: [0, max_val] }}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => ({
                          style: { fill: "#00de78", width: 17 }
                        })
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: true })
                      }
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => {}
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: false })
                      }
                    ];
                  }
                }
              }
            ]}
          />
          <VictoryBar
            data={dataB}
            domain={{ y: [0, max_val] }}
            labelComponent={<CustomLabel />}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => ({
                          style: { fill: "#ff5607", width: 17 }
                        })
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: true })
                      }
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => {}
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: false })
                      }
                    ];
                  }
                }
              }
            ]}
          />
        </VictoryGroup>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" }
          }}
        />
      </VictoryChart>
    );
  }
}

export default GroupedBar;
