const initState = {
  isLoading: false,
  profile: false
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case "START_AUTH":
      return {
        ...state,
        isLoading: true
      };
    case "SET_AUTH":
      return {
        ...state,
        profile: action.payload.profile,
        isLoading: false
      };
    case "UNSET_AUTH":
      return {
        profile: false,
        isLoading: false
      };
    default:
      return state;
  }
}
