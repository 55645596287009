import React, { PureComponent } from "react";
import { VictoryChart, VictoryBar, VictoryStack } from "victory";
import CustomLabelCrowd from "./CustomLabelCrowd";
import { Dark } from "./themeVictory";

const dataDefault = [
  {
    "Simpul": "A great deal",
    "Provinsi": "Aceh",
    "prov_id": "11",
    "total": 77
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Bali",
    "prov_id": "51",
    "total": 5
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Bangka Belitung",
    "prov_id": "19",
    "total": 24
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Banten",
    "prov_id": "36",
    "total": 81
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Bengkulu",
    "prov_id": "17",
    "total": 36
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Gorontalo",
    "prov_id": "75",
    "total": 23
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Jakarta (DKI)",
    "prov_id": "31",
    "total": 63
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Jambi",
    "prov_id": "15",
    "total": 24
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Jawa Barat (Java West)",
    "prov_id": "32",
    "total": 235
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Jawa Tengah (Java Central)",
    "prov_id": "33",
    "total": 178
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Jawa Timur (Java East)",
    "prov_id": "35",
    "total": 100
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Kalimantan Barat (Kalimantan West)",
    "prov_id": "61",
    "total": 20
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Kalimantan Selatan (Kalimantan South)",
    "prov_id": "63",
    "total": 39
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Kalimantan Tengah (Kalimantan Central)",
    "prov_id": "62",
    "total": 7
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Kalimantan Timur (Kalimantan East)",
    "prov_id": "64",
    "total": 20
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Kalimantan Utara (Kalimantan North)",
    "prov_id": "64",
    "total": 7
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Kepulauan Riau (Islands Riau)",
    "prov_id": "21",
    "total": 49
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Lampung",
    "prov_id": "18",
    "total": 39
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Maluku",
    "prov_id": "81",
    "total": 11
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Maluku Utara (Maluku North)",
    "prov_id": "82",
    "total": 10
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Nusa Tenggara Barat (Nusa Tenggara West)",
    "prov_id": "52",
    "total": 44
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Nusa Tenggara Timur (Nusa Tenggara East)",
    "prov_id": "53",
    "total": 31
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Papua",
    "prov_id": "94",
    "total": 22
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Papua Barat (Papua West)",
    "prov_id": "91",
    "total": 18
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Riau",
    "prov_id": "14",
    "total": 67
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Sulawesi Barat (Sulawesi West)",
    "prov_id": "76",
    "total": 28
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Sulawesi Selatan (Sulawesi South)",
    "prov_id": "73",
    "total": 160
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Sulawesi Tengah (Sulawesi Central)",
    "prov_id": "72",
    "total": 37
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Sulawesi Tenggara (Sulawesi Southeast)",
    "prov_id": "74",
    "total": 28
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Sulawesi Utara (Sulawesi North)",
    "prov_id": "71",
    "total": 24
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Sumatra Barat (Sumatra West)",
    "prov_id": "13",
    "total": 85
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Sumatra Selatan (Sumatra South)",
    "prov_id": "16",
    "total": 54
  },
  {
    "Simpul": "A great deal",
    "Provinsi": "Sumatra Utara (Sumatra North)",
    "prov_id": "12",
    "total": 177
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Bali",
    "prov_id": "51",
    "total": 8
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Bangka Belitung",
    "prov_id": "19",
    "total": 3
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Banten",
    "prov_id": "36",
    "total": 10
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Gorontalo",
    "prov_id": "75",
    "total": 2
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Jakarta (DKI)",
    "prov_id": "31",
    "total": 6
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Jambi",
    "prov_id": "15",
    "total": 7
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Jawa Barat (Java West)",
    "prov_id": "32",
    "total": 7
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Jawa Tengah (Java Central)",
    "prov_id": "33",
    "total": 17
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Jawa Timur (Java East)",
    "prov_id": "35",
    "total": 29
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Lampung",
    "prov_id": "18",
    "total": 1
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Maluku Utara (Maluku North)",
    "prov_id": "82",
    "total": 1
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Nusa Tenggara Barat (Nusa Tenggara West)",
    "prov_id": "52",
    "total": 5
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Papua",
    "prov_id": "94",
    "total": 3
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Riau",
    "prov_id": "14",
    "total": 1
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Sulawesi Selatan (Sulawesi South)",
    "prov_id": "73",
    "total": 6
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Sulawesi Tengah (Sulawesi Central)",
    "prov_id": "72",
    "total": 2
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Sulawesi Utara (Sulawesi North)",
    "prov_id": "71",
    "total": 5
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Sumatra Selatan (Sumatra South)",
    "prov_id": "16",
    "total": 1
  },
  {
    "Simpul": "Dont know",
    "Provinsi": "Sumatra Utara (Sumatra North)",
    "prov_id": "12",
    "total": 3
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Aceh",
    "prov_id": "11",
    "total": 12
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Bali",
    "prov_id": "51",
    "total": 75
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Bangka Belitung",
    "prov_id": "19",
    "total": 7
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Banten",
    "prov_id": "36",
    "total": 193
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Bengkulu",
    "prov_id": "17",
    "total": 10
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Gorontalo",
    "prov_id": "75",
    "total": 1
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Jakarta (DKI)",
    "prov_id": "31",
    "total": 115
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Jambi",
    "prov_id": "15",
    "total": 24
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Jawa Barat (Java West)",
    "prov_id": "32",
    "total": 475
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Jawa Tengah (Java Central)",
    "prov_id": "33",
    "total": 465
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Jawa Timur (Java East)",
    "prov_id": "35",
    "total": 262
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Kalimantan Barat (Kalimantan West)",
    "prov_id": "61",
    "total": 94
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Kalimantan Selatan (Kalimantan South)",
    "prov_id": "63",
    "total": 63
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Kalimantan Tengah (Kalimantan Central)",
    "prov_id": "62",
    "total": 37
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Kalimantan Timur (Kalimantan East)",
    "prov_id": "64",
    "total": 43
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Kalimantan Utara (Kalimantan North)",
    "prov_id": "64",
    "total": 15
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Kepulauan Riau (Islands Riau)",
    "prov_id": "21",
    "total": 8
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Lampung",
    "prov_id": "18",
    "total": 118
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Maluku",
    "prov_id": "81",
    "total": 20
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Maluku Utara (Maluku North)",
    "prov_id": "82",
    "total": 16
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Nusa Tenggara Barat (Nusa Tenggara West)",
    "prov_id": "52",
    "total": 62
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Nusa Tenggara Timur (Nusa Tenggara East)",
    "prov_id": "53",
    "total": 69
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Papua",
    "prov_id": "94",
    "total": 35
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Papua Barat (Papua West)",
    "prov_id": "91",
    "total": 23
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Riau",
    "prov_id": "14",
    "total": 36
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Sulawesi Barat (Sulawesi West)",
    "prov_id": "76",
    "total": 1
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Sulawesi Selatan (Sulawesi South)",
    "prov_id": "73",
    "total": 17
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Sulawesi Tengah (Sulawesi Central)",
    "prov_id": "72",
    "total": 17
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Sulawesi Tenggara (Sulawesi Southeast)",
    "prov_id": "74",
    "total": 14
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Sulawesi Utara (Sulawesi North)",
    "prov_id": "71",
    "total": 3
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Sumatra Barat (Sumatra West)",
    "prov_id": "13",
    "total": 20
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Sumatra Selatan (Sumatra South)",
    "prov_id": "16",
    "total": 86
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Sumatra Utara (Sumatra North)",
    "prov_id": "12",
    "total": 90
  },
  {
    "Simpul": "Not at all",
    "Provinsi": "Yogyakarta",
    "prov_id": "34",
    "total": 1
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Aceh",
    "prov_id": "11",
    "total": 25
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Bali",
    "prov_id": "51",
    "total": 42
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Bangka Belitung",
    "prov_id": "19",
    "total": 14
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Banten",
    "prov_id": "36",
    "total": 149
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Bengkulu",
    "prov_id": "17",
    "total": 11
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Gorontalo",
    "prov_id": "75",
    "total": 5
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Jakarta (DKI)",
    "prov_id": "31",
    "total": 132
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Jambi",
    "prov_id": "15",
    "total": 47
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Jawa Barat (Java West)",
    "prov_id": "32",
    "total": 752
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Jawa Tengah (Java Central)",
    "prov_id": "33",
    "total": 429
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Jawa Timur (Java East)",
    "prov_id": "35",
    "total": 763
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Kalimantan Barat (Kalimantan West)",
    "prov_id": "61",
    "total": 58
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Kalimantan Selatan (Kalimantan South)",
    "prov_id": "63",
    "total": 40
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Kalimantan Tengah (Kalimantan Central)",
    "prov_id": "62",
    "total": 16
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Kalimantan Timur (Kalimantan East)",
    "prov_id": "64",
    "total": 50
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Kalimantan Utara (Kalimantan North)",
    "prov_id": "64",
    "total": 11
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Kepulauan Riau (Islands Riau)",
    "prov_id": "21",
    "total": 3
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Lampung",
    "prov_id": "18",
    "total": 102
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Maluku",
    "prov_id": "81",
    "total": 30
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Maluku Utara (Maluku North)",
    "prov_id": "82",
    "total": 22
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Nusa Tenggara Barat (Nusa Tenggara West)",
    "prov_id": "52",
    "total": 82
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Nusa Tenggara Timur (Nusa Tenggara East)",
    "prov_id": "53",
    "total": 47
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Papua",
    "prov_id": "94",
    "total": 49
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Papua Barat (Papua West)",
    "prov_id": "91",
    "total": 25
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Riau",
    "prov_id": "14",
    "total": 35
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Sulawesi Barat (Sulawesi West)",
    "prov_id": "76",
    "total": 3
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Sulawesi Selatan (Sulawesi South)",
    "prov_id": "73",
    "total": 39
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Sulawesi Tengah (Sulawesi Central)",
    "prov_id": "72",
    "total": 36
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Sulawesi Tenggara (Sulawesi Southeast)",
    "prov_id": "74",
    "total": 12
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Sulawesi Utara (Sulawesi North)",
    "prov_id": "71",
    "total": 3
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Sumatra Barat (Sumatra West)",
    "prov_id": "13",
    "total": 19
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Sumatra Selatan (Sumatra South)",
    "prov_id": "16",
    "total": 107
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Sumatra Utara (Sumatra North)",
    "prov_id": "12",
    "total": 115
  },
  {
    "Simpul": "Not very much",
    "Provinsi": "Yogyakarta",
    "prov_id": "34",
    "total": 1
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Aceh",
    "prov_id": "11",
    "total": 91
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Bali",
    "prov_id": "51",
    "total": 22
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Bangka Belitung",
    "prov_id": "19",
    "total": 6
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Banten",
    "prov_id": "36",
    "total": 115
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Bengkulu",
    "prov_id": "17",
    "total": 34
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Gorontalo",
    "prov_id": "75",
    "total": 25
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Jakarta (DKI)",
    "prov_id": "31",
    "total": 96
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Jambi",
    "prov_id": "15",
    "total": 25
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Jawa Barat (Java West)",
    "prov_id": "32",
    "total": 377
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Jawa Tengah (Java Central)",
    "prov_id": "33",
    "total": 221
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Jawa Timur (Java East)",
    "prov_id": "35",
    "total": 371
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Kalimantan Barat (Kalimantan West)",
    "prov_id": "61",
    "total": 19
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Kalimantan Selatan (Kalimantan South)",
    "prov_id": "63",
    "total": 10
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Kalimantan Tengah (Kalimantan Central)",
    "prov_id": "62",
    "total": 22
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Kalimantan Timur (Kalimantan East)",
    "prov_id": "64",
    "total": 20
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Kalimantan Utara (Kalimantan North)",
    "prov_id": "64",
    "total": 22
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Kepulauan Riau (Islands Riau)",
    "prov_id": "21",
    "total": 11
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Lampung",
    "prov_id": "18",
    "total": 92
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Maluku",
    "prov_id": "81",
    "total": 22
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Maluku Utara (Maluku North)",
    "prov_id": "82",
    "total": 18
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Nusa Tenggara Barat (Nusa Tenggara West)",
    "prov_id": "52",
    "total": 53
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Nusa Tenggara Timur (Nusa Tenggara East)",
    "prov_id": "53",
    "total": 12
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Papua",
    "prov_id": "94",
    "total": 19
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Papua Barat (Papua West)",
    "prov_id": "91",
    "total": 19
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Riau",
    "prov_id": "14",
    "total": 60
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Sulawesi Barat (Sulawesi West)",
    "prov_id": "76",
    "total": 11
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Sulawesi Selatan (Sulawesi South)",
    "prov_id": "73",
    "total": 76
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Sulawesi Tengah (Sulawesi Central)",
    "prov_id": "72",
    "total": 34
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Sulawesi Tenggara (Sulawesi Southeast)",
    "prov_id": "74",
    "total": 19
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Sulawesi Utara (Sulawesi North)",
    "prov_id": "71",
    "total": 17
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Sumatra Barat (Sumatra West)",
    "prov_id": "13",
    "total": 74
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Sumatra Selatan (Sumatra South)",
    "prov_id": "16",
    "total": 94
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Sumatra Utara (Sumatra North)",
    "prov_id": "12",
    "total": 230
  },
  {
    "Simpul": "Somewhat",
    "Provinsi": "Yogyakarta",
    "prov_id": "34",
    "total": 1
  }
]

export default class Bar100HorizontalETSupport extends PureComponent {
  render() {

    // console.log('xxx Bar100Horizontal Relawan')
    // console.log(this.props);

    let dataChart = dataDefault.filter(
      d => d['prov_id'] === String(this.props.region)
    )

    if (dataChart.length === 0) {
      dataChart = dataDefault
    }

    return (
      <VictoryChart
        theme={Dark}
        height={350}
        width={550}
        padding={{ left: 150, top: 0, right: 10, bottom: 50 }}
        domainPadding={{ x: 20, y: 20 }}
      >
        <VictoryStack horizontal>
          <VictoryBar
            barWidth={20}
            data={dataChart.map(d => { 
              // const total_data = d.total_data === undefined ? d.total_value : d.total_data
              return ({
                x: d.Simpul,
                y: d.total ,
                label: `${d.Simpul} : ${d.total}%`
            })})}
            style={{
              data: {
                fill: ({ x }) => 
                x.includes("COVID-19")
                  ? "#d0021b"
                  : x.includes("Not at all")
                  ? "rgb(6, 0, 194)"
                  : x.includes('Not very much')
                  ? "#edbc34" 
                  : x.includes('Somewhat')
                  ? "#eb0202"
                  : x.includes('A great deal')
                  ? "#f4ff1c"
                  : "#eee"
              },
              labels: {
                fill: "#121212",
                fontSize: 22,
                fontWeight: 500,
              }
            }}
            labelComponent={<CustomLabelCrowd dx={-20} dy={20} />}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => ({
                          style: { fill: "#00de78" }
                        })
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: true })
                      }
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => {}
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: false })
                      }
                    ];
                  }
                }
              }
            ]}
          />
        </VictoryStack>
      </VictoryChart>
    );
  }
}
