import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import Overview from "components/overview/Overview";
import OverviewDark from "components/overview/OverviewDark";
import OverviewCrowd from "components/overview/OverviewCrowdIframe";
import Perception360 from "components/overview/Perception360";
import OverviewPersuasionDash from "components/overview/OverviewPersuasionDash";
import Whatson360Dash from "components/overview/Whatson360Dash";
import OsintSna360 from "components/overview/OsintSna360";
import SocialMonitor from "components/overview/SocialMonitor";
import FypSentiment360 from "components/overview/FypSentiment360";
import FypCms360 from "components/overview/FypCms360";
import FypPushRank from "components/overview/FypPushRank";
import FypTakeDown from "components/overview/FypTakeDown";
import SOE from "components/overview/SOE";
import Mapping from "components/mapping/Mapping";
import MappingVP from "components/mapping/MappingVP";
import Countdown from "components/countdown/Countdown";
import Choice from "components/countdown/Choice";
import TopicMapping from "components/topicmapping/TopicMapping";
import TopicET from "components/topicmapping/TopicET";
import WhatsonNew from "components/topicmapping/WhatsonNew";
import DailyDose from "components/topicmapping/DailyDose";
import Sna from "components/topicmapping/Sna";
import POIRelawan from "components/poi/POIRelawan";
import POISupporter from "components/poi/POISupporter";
import POIVaksin from "components/poi/POIVaksin";
import ScoreAnalysis from "components/graph/Graph";
// import Survey from "components/survey/Survey";
import Navbar from "components/layout/navbar/Navbar";
import TurnoutScore from "components/turnoutscore/TurnoutScore";
import TestPage from "components/testpage"

const localProfile = JSON.parse(localStorage.getItem("idp"));

class Dashboard extends Component {
  state = {
    profile: false
  };
  componentDidMount() {
    this.props.profile
      ? this.setState({ profile: this.props.profile })
      : this.setState({ profile: localProfile });
  }

  render() {
    const { match, location } = this.props;
    const { profile } = this.state;
    return (
      <div id="app">
        <div className="content-wrapper">
          <Navbar location={location} />
          <div className="content">
            <Switch>
              <Route
                exact
                path={`${match.path}/overview`}
                component={Overview}
              />
              <Route exact path={`${match.path}/360view`} component={Mapping} />
              <Route exact path={`${match.path}/360viewVP`} component={MappingVP} />
              <Route exact path={`${match.path}/360crowd`} component={OverviewCrowd} />
              <Route exact path={`${match.path}/perception360`} component={Perception360} />
              <Route exact path={`${match.path}/360persuasion`} component={OverviewPersuasionDash} />

              <Route exact path={`${match.path}/fyp_sentiment`} component={FypSentiment360} />
              <Route exact path={`${match.path}/fyp_gen_ai`} component={FypCms360} />

              <Route exact path={`${match.path}/push_rank_fyp`} component={FypPushRank} />
              <Route exact path={`${match.path}/takedown_fyp`} component={FypTakeDown} />

              <Route exact path={`${match.path}/whatson360`} component={Whatson360Dash} />
              <Route exact path={`${match.path}/osint_sna`} component={OsintSna360} />
              <Route exact path={`${match.path}/media_monitor`} component={SocialMonitor} />

              <Route
                exact
                path={`${match.path}/horserace`}
                // component={Countdown}
                component={(profile.role === "ROLE_ADMIN" ? Overview : Countdown)}
              />
              <Route
                exact
                path={`${match.path}/choice`}
                // component={Countdown}
                component={(profile.role === "ROLE_ADMIN" ? Overview : Choice)}
              />
              <Route
                exact
                path={`${match.path}/whatson`}
                component={TopicMapping}
              />
              <Route
                exact
                path={`${match.path}/issues`}
                component={(OverviewDark)}
              />
              <Route
                exact
                path={`${match.path}/whatson_new`}
                component={(WhatsonNew)}
              />
              <Route
                exact
                path={`${match.path}/daily_dose`}
                component={(profile.role === "ROLE_ADMIN" ? Overview : DailyDose)}
              />
              <Route
                exact
                path={`${match.path}/sna`}
                component={(profile.role === "ROLE_ADMIN" ? Overview : Sna)}
              />
              <Route
                exact
                path={`${match.path}/dashboard_et`}
                component={(profile.role === "ROLE_ADMIN" ? Overview : TopicET)}
              />
              <Route
                exact
                path={`${match.path}/soe`}
                component={SOE}
              />
              <Route exact path={`${match.path}/poi`} component={POISupporter} />
              <Route
                exact
                path={`${match.path}/scoreanalysis`}
                // component={ScoreAnalysis}
                component={(profile.role === "ROLE_ADMIN" ? Overview : ScoreAnalysis)}
              />
              {/* <Route
                exact
                path={`${match.path}/vaksin`}
                component={POIVaksin}
              /> */}
              {/* <Route
                exact
                path={`${match.path}/turnoutscore`}
                component={TurnoutScore}
              /> */}
              <Route
                exact
                path={`${match.path}/testpage`}
                component={TestPage}
              />
              {/* <Route exact path={`${match.path}/survey`} component={Survey} /> */}
              {profile.role === "ROLE_OPERATION" && (
                <Redirect from="/dashboard" to="/dashboard/overview" />
              )}
              {(profile.role === "ROLE_ADMIN" ||
                profile.role === "ROLE_SUPER") && (
                <Redirect from="/dashboard" to="/dashboard/whatson360" />
              )}
              {/* {(profile.role === "ROLE_ADMIN") && (
                <Redirect from="/scoreanalysis" to="/dashboard/360view" />
              )}
              {(profile.role === "ROLE_ADMIN") && (
                <Redirect from="/horserace" to="/dashboard/360view" />
              )}
              {(profile.role === "ROLE_ADMIN") && (
                <Redirect from="/dashboard_et" to="/dashboard/360view" />
              )} */}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.auth.profile
});

export default connect(mapStateToProps)(Dashboard);
