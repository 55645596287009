import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const data = [
//     {
//       "Date": "Mar-20",
//       "Number of Articles Published": 93,
//       "Search Volume": 605
//     },
//     {
//       "Date": "Apr-20",
//       "Number of Articles Published": 1020,
//       "Search Volume": 495
//     },
//     {
//       "Date": "May-20",
//       "Number of Articles Published": 950,
//       "Search Volume": 605
//     },
//     {
//       "Date": "Jun-20",
//       "Number of Articles Published": 1426,
//       "Search Volume": 605
//     },
//     {
//       "Date": "Jul-20",
//       "Number of Articles Published": 1943,
//       "Search Volume": 74
//     },
//     {
//       "Date": "Aug-20",
//       "Number of Articles Published": 1542,
//       "Search Volume": 74
//     },
//     {
//       "Date": "Sep-20",
//       "Number of Articles Published": 1460,
//       "Search Volume": 495
//     },
//     {
//       "Date": "Oct-20",
//       "Number of Articles Published": 703,
//       "Search Volume": 405
//     },
//     {
//       "Date": "Nov-20",
//       "Number of Articles Published": 761,
//       "Search Volume": 331
//     },
//     {
//       "Date": "Dec-20",
//       "Number of Articles Published": 583,
//       "Search Volume": 605
//     },
//     {
//       "Date": "Jan-21",
//       "Number of Articles Published": 875,
//       "Search Volume": 405
//     },
//     {
//       "Date": "Feb-21",
//       "Number of Articles Published": 531,
//       "Search Volume": 331
//     },
//     {
//       "Date": "Mar-21",
//       "Number of Articles Published": 1051,
//       "Search Volume": 301
//     }
//   ]

const data = [
  {
    "Date": "Mar-21",
    "Number of Articles Published": 630,
    "Search Volume": 1995
  },
  {
    "Date": "Apr-21",
    "Number of Articles Published": 639,
    "Search Volume": 1386
  },
  {
    "Date": "May-21",
    "Number of Articles Published": 701,
    "Search Volume": 1590
  },
  {
    "Date": "Jun-21",
    "Number of Articles Published": 1206,
    "Search Volume": 1111
  },
  {
    "Date": "Jul-21",
    "Number of Articles Published": 1179,
    "Search Volume": 1183
  },
  {
    "Date": "Aug-21",
    "Number of Articles Published": 1032,
    "Search Volume": 1006
  },
  {
    "Date": "Sep-21",
    "Number of Articles Published": 1449,
    "Search Volume": 534
  },
  {
    "Date": "Oct-21",
    "Number of Articles Published": 1548,
    "Search Volume": 1770
  },
  {
    "Date": "Nov-21",
    "Number of Articles Published": 2815,
    "Search Volume": 3860
  },
  {
    "Date": "Dec-21",
    "Number of Articles Published": 2045,
    "Search Volume": 1741
  },
  {
    "Date": "Jan-22",
    "Number of Articles Published": 2596,
    "Search Volume": 1836
  },
  {
    "Date": "Feb-22",
    "Number of Articles Published": 1770,
    "Search Volume": 1984
  },
  {
    "Date": "Mar-22",
    "Number of Articles Published": 1335,
    "Search Volume": 1187
  }
]

// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

export default class BarGroupRechart extends PureComponent {

  render() {
    return (
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          width={500}
          height={150}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="Date" />
          {/* <YAxis /> */}
          <Tooltip />
          <Legend />
          <Bar dataKey="Number of Articles Published" fill="#352afa" />
          <Bar dataKey="Search Volume" fill="#0088FE" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
