import axios from "axios";
import { getCookie } from "helpers";

const API_HOST = process.env.REACT_APP_API_HOST,
  API_DIR = process.env.REACT_APP_API_DIR,
  API_TOPIC = process.env.REACT_APP_API_TOPIC;

const token = localStorage.getItem("idn");

export function GET(url, params = {}) {
  return axios({
    method: "GET",
    url,
    params,
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  });
}

export function GET_NOBEARER(url, params = {}) {
  return axios({
    method: "GET",
    url,
    params
  });
}

export function POST(url, data = {}, jwt = true) {
  return axios({
    method: "POST",
    url,
    data,
    headers: {
      Authorization:
        token && jwt
          ? `Bearer ${token}`
          : "Basic c2VudGFsX2FkbWluOjROYUwxdDFjNQ=="
    }
  });
}

export function PUT(url, data = {}) {
  return axios({
    method: "PUT",
    url,
    data,
    headers: {
      Authorization: getCookie("idn") || ""
    }
  });
}

export function DELETE(url) {
  return axios({
    method: "DELETE",
    url,
    headers: {
      Authorization: getCookie("idn") || ""
    }
  });
}

export function postCredentials(credentials) {
  const URI = `${API_HOST}/${API_DIR}/oauth/token?grant_type=password&username=${
    credentials.email
  }&password=${credentials.password}`;

  return POST(encodeURI(URI));
}

export async function refreshToken(reftok) {
  const response = await POST(
    `${API_HOST}/${API_DIR}/oauth/token?grant_type=refresh_token&refresh_token=${reftok}`,
    null,
    false
  );
  return response;
}

export async function revokeToken() {
  localStorage.clear();
  localStorage.removeItem("idr");
  const response = await GET(`${API_HOST}/${API_DIR}/oauth/revoke-token`);
  return response;
}

export async function registerNewUser(values) {
  const response = await POST(`${API_HOST}/${API_DIR}/user`, values);
  return response;
}

export async function submitSurvey(values) {
  const response = await POST(
    `${API_HOST}/${API_DIR}/overview/survey/submit`,
    values
  );
  return response;
}

export async function getSentiment(type, candidate) {
  const response = await GET(
    `${API_HOST}/${API_DIR}/whatson/${type}/aggregation?candidate=${candidate}`
  );
  return response;
}
