import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap
} from "actions/mapActions";
import { UncontrolledCollapse } from "reactstrap";
import SubmenuRegion from "./SubmenuRegion";
import { reAuth } from "actions/authActions";

import "styles/components/map-submenu.css";

class SidebarPoiRelawan extends PureComponent {
  state = {
    openMap: false,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null
  };

  handleClearPOI = () => {
    this.props.clearPOI();
    this.setState({
      ...this.state,
      isPOISubOpen: 0
    });
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        isPOISubOpen: submenu
      });
    }
  };

  handleTogglePOI = type => {
    this.props.clearPOI();
    if (this.props.state.region.provinsi.id === null) {
      this.props.getPOI(type);
    } else {
      this.props.getPOIprovince(type, this.props.state.region.provinsi.id);
    }
    this.setState({
      isPOISubOpen: 0
    });
  };

  handleClearPOI = () => {
    this.props.clearPOI();
    this.props.clearInfo("subpoi");
    this.setState({
      ...this.state,
      isPOISubOpen: 0
    });
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0
    });
  };
  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        isSubOpen: 0
      });
    } else {
      this.setState({
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  render() {
    const { info, state, handleGetSubMenu, handleToggleSatellite } = this.props;
    const {
      openMap,
      isSubOpen,
      openPOI,
      isPOISubOpen,
      isSatelite
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition
    } = this;

    const catPOI = Object.keys(state.poiList.category).map((d, i) => {
      return { cat: d, child: state.poiList.category[d] };
    });

    console.log('xxx SidebarPoiRelawan');
    console.log(catPOI);
    return (
      <Fragment>
        <div className="sidebar sidebar-left">
          <div className="sidebar-content">
            <nav className="main-menu">
              <ul className="nav">
                <li>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      openPOI
                        ? this.setState({ openPOI: false })
                        : this.setState({
                            openPOI: true,
                            isSubOpen: 0,
                            openMap: false
                          });
                    }}
                  >
                    <i className="icon dripicons-location" />
                  </a>
                  <div className={`map-submenu ${openPOI ? "sub-open" : ""}`}>
                    <ul className="submenu-map map-select">
                      {catPOI.map((data, key) => (
                        <li key={key}>
                          <a
                            href="#"
                            className="dropdown-toggle"
                            onClick={e => {
                              e.preventDefault();
                              handleTogglePOISubMenu(key + 1);
                            }}
                          >
                            {data.cat}
                          </a>
                          <ul
                            className={`submenu-children ${
                              isPOISubOpen === key + 1 ? "sub-child-open" : ""
                            }`}
                          >
                            {data.child.map((d, k) => (
                              <Fragment key={k}>
                                <li>
                                  <a
                                    href="#"
                                    onClick={e => {
                                      e.preventDefault();
                                      this.setState({
                                        ...this.state,
                                        isPOIAccordionOpen: d.name
                                      });
                                    }}
                                    id={`toggler-${key * 9999 + k}`}
                                    className="dropdown-toggle"
                                  >
                                    {d.name}
                                  </a>
                                </li>
                                <UncontrolledCollapse
                                  toggler={`#toggler-${key * 9999 + k}`}
                                >
                                  <ul className="submenu-accordion">
                                    {d.poi_type.map((e, q) => (
                                      <li key={q}>
                                        <a
                                          href="#"
                                          onClick={event => {
                                            event.preventDefault();
                                            handleTogglePOI(e.code, e.code);
                                          }}
                                        >
                                          {e.name}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </UncontrolledCollapse>
                              </Fragment>
                            ))}
                          </ul>
                        </li>
                      ))}
                      <li className="map-select-short">
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            handleClearPOI();
                          }}
                        >
                          <i className="zmdi zmdi-eye-off" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="sidebar sidebar-left right-side">
          <div className="sidebar-content">
            <nav className="main-menu">
              <ul className="nav">
                <li>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      openMap
                        ? this.setState({ openMap: false })
                        : this.setState({
                            openMap: true,
                            isSubOpen: 0,
                            openPOI: false
                          });
                    }}
                  >
                    <i className="icon dripicons-map" />
                  </a>
                  <SubmenuRegion
                    handleToggleInfo={handleToggleInfo}
                    handleGetSubMenu={handleGetSubMenu}
                    handleToggleSubMenu={handleToggleSubMenu}
                    handleCloseSubmenu={handleCloseSubmenu}
                    data={{
                      info,
                      openMap,
                      isSubOpen,
                      handleSubMenuChange,
                      region: state.region
                    }}
                  />
                </li>
                <li>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      handleToggleSatellite();
                    }}
                  >
                    <i className="icon dripicons-web" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      handleResetPosition();
                    }}
                  >
                    <i className="icon dripicons-zoom-out" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarPoiRelawan);
