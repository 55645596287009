import React, { StyleSheet } from 'react';
import "styles/components/map-style.css";

const legend = (props) => {
  if (props.dataLegend) {
    const styleLegend = props.position === 'bottomLeft' ? 'bottomLeft' : 'topRight';
    return(
      <div className={`legend ${styleLegend}`}>
        <ul className="legendList">
          {/* <li><strong>Berdasarkan Hasil Pilpres 2014</strong></li> */}
          {
            props.dataLegend.map((e, i) => {
               
              return(
                <li key={i}><div className="legendIcon" style={{ backgroundColor: e.color }}></div> { e.label }</li>
              )
            })
          }
        </ul>
      </div>
  ) } else {
    return(
    <div>kosong</div>
    )
  }
}

export default legend;