export const dataCountdownWave5 = {
  "0": [
    
  ],
  "11": [
    {
      "kode_prov": "11",
      "prov_db": "NANGGROE ACEH DARUSSALAM",
      "prov_survey": "Aceh",
      "candidate": "Prabowo Subianto",
      "count": 15,
      "count_prov": 57,
      "rank": 1
    },
    {
      "kode_prov": "11",
      "prov_db": "NANGGROE ACEH DARUSSALAM",
      "prov_survey": "Aceh",
      "candidate": "Anies Baswedan",
      "count": 12,
      "count_prov": 57,
      "rank": 2
    },
    {
      "kode_prov": "11",
      "prov_db": "NANGGROE ACEH DARUSSALAM",
      "prov_survey": "Aceh",
      "candidate": "Erick Thohir",
      "count": 12,
      "count_prov": 57,
      "rank": 2
    },
    {
      "kode_prov": "11",
      "prov_db": "NANGGROE ACEH DARUSSALAM",
      "prov_survey": "Aceh",
      "candidate": "Don\u2019t know",
      "count": 7,
      "count_prov": 57,
      "rank": 3
    },
    {
      "kode_prov": "11",
      "prov_db": "NANGGROE ACEH DARUSSALAM",
      "prov_survey": "Aceh",
      "candidate": "Ganjar Pranowo",
      "count": 6,
      "count_prov": 57,
      "rank": 4
    },
    {
      "kode_prov": "11",
      "prov_db": "NANGGROE ACEH DARUSSALAM",
      "prov_survey": "Aceh",
      "candidate": "Sandiaga Uno",
      "count": 4,
      "count_prov": 57,
      "rank": 5
    },
    {
      "kode_prov": "11",
      "prov_db": "NANGGROE ACEH DARUSSALAM",
      "prov_survey": "Aceh",
      "candidate": "Ridwan Kamil",
      "count": 1,
      "count_prov": 57,
      "rank": 6
    }
  ],
  "12": [
    {
      "kode_prov": "12",
      "prov_db": "SUMATERA UTARA",
      "prov_survey": "SumatraUtara(SumatraNorth)",
      "candidate": "Erick Thohir",
      "count": 46,
      "count_prov": 120,
      "rank": 1
    },
    {
      "kode_prov": "12",
      "prov_db": "SUMATERA UTARA",
      "prov_survey": "SumatraUtara(SumatraNorth)",
      "candidate": "Prabowo Subianto",
      "count": 41,
      "count_prov": 120,
      "rank": 2
    },
    {
      "kode_prov": "12",
      "prov_db": "SUMATERA UTARA",
      "prov_survey": "SumatraUtara(SumatraNorth)",
      "candidate": "Ganjar Pranowo",
      "count": 23,
      "count_prov": 120,
      "rank": 3
    },
    {
      "kode_prov": "12",
      "prov_db": "SUMATERA UTARA",
      "prov_survey": "SumatraUtara(SumatraNorth)",
      "candidate": "Anies Baswedan",
      "count": 5,
      "count_prov": 120,
      "rank": 4
    },
    {
      "kode_prov": "12",
      "prov_db": "SUMATERA UTARA",
      "prov_survey": "SumatraUtara(SumatraNorth)",
      "candidate": "Sandiaga Uno",
      "count": 2,
      "count_prov": 120,
      "rank": 5
    },
    {
      "kode_prov": "12",
      "prov_db": "SUMATERA UTARA",
      "prov_survey": "SumatraUtara(SumatraNorth)",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 1,
      "count_prov": 120,
      "rank": 6
    },
    {
      "kode_prov": "12",
      "prov_db": "SUMATERA UTARA",
      "prov_survey": "SumatraUtara(SumatraNorth)",
      "candidate": "Don\u2019t know",
      "count": 1,
      "count_prov": 120,
      "rank": 6
    },
    {
      "kode_prov": "12",
      "prov_db": "SUMATERA UTARA",
      "prov_survey": "SumatraUtara(SumatraNorth)",
      "candidate": "Ridwan Kamil",
      "count": 1,
      "count_prov": 120,
      "rank": 6
    }
  ],
  "13": [
    {
      "kode_prov": "13",
      "prov_db": "SUMATERA BARAT",
      "prov_survey": "SumatraBarat(SumatraWest)",
      "candidate": "Prabowo Subianto",
      "count": 10,
      "count_prov": 34,
      "rank": 1
    },
    {
      "kode_prov": "13",
      "prov_db": "SUMATERA BARAT",
      "prov_survey": "SumatraBarat(SumatraWest)",
      "candidate": "Anies Baswedan",
      "count": 9,
      "count_prov": 34,
      "rank": 2
    },
    {
      "kode_prov": "13",
      "prov_db": "SUMATERA BARAT",
      "prov_survey": "SumatraBarat(SumatraWest)",
      "candidate": "Don\u2019t know",
      "count": 8,
      "count_prov": 34,
      "rank": 3
    },
    {
      "kode_prov": "13",
      "prov_db": "SUMATERA BARAT",
      "prov_survey": "SumatraBarat(SumatraWest)",
      "candidate": "Sandiaga Uno",
      "count": 5,
      "count_prov": 34,
      "rank": 4
    },
    {
      "kode_prov": "13",
      "prov_db": "SUMATERA BARAT",
      "prov_survey": "SumatraBarat(SumatraWest)",
      "candidate": "Erick Thohir",
      "count": 2,
      "count_prov": 34,
      "rank": 5
    }
  ],
  "14": [
    {
      "kode_prov": "14",
      "prov_db": "RIAU",
      "prov_survey": "Riau",
      "candidate": "Erick Thohir",
      "count": 11,
      "count_prov": 24,
      "rank": 1
    },
    {
      "kode_prov": "14",
      "prov_db": "RIAU",
      "prov_survey": "Riau",
      "candidate": "Don\u2019t know",
      "count": 5,
      "count_prov": 24,
      "rank": 2
    },
    {
      "kode_prov": "14",
      "prov_db": "RIAU",
      "prov_survey": "Riau",
      "candidate": "Sandiaga Uno",
      "count": 3,
      "count_prov": 24,
      "rank": 3
    },
    {
      "kode_prov": "14",
      "prov_db": "RIAU",
      "prov_survey": "Riau",
      "candidate": "Ganjar Pranowo",
      "count": 2,
      "count_prov": 24,
      "rank": 4
    },
    {
      "kode_prov": "14",
      "prov_db": "RIAU",
      "prov_survey": "Riau",
      "candidate": "Prabowo Subianto",
      "count": 2,
      "count_prov": 24,
      "rank": 4
    },
    {
      "kode_prov": "14",
      "prov_db": "RIAU",
      "prov_survey": "Riau",
      "candidate": "Anies Baswedan",
      "count": 1,
      "count_prov": 24,
      "rank": 5
    }
  ],
  "15": [
    {
      "kode_prov": "15",
      "prov_db": "J A M B I",
      "prov_survey": "Jambi",
      "candidate": "Anies Baswedan",
      "count": 9,
      "count_prov": 44,
      "rank": 1
    },
    {
      "kode_prov": "15",
      "prov_db": "J A M B I",
      "prov_survey": "Jambi",
      "candidate": "Erick Thohir",
      "count": 9,
      "count_prov": 44,
      "rank": 1
    },
    {
      "kode_prov": "15",
      "prov_db": "J A M B I",
      "prov_survey": "Jambi",
      "candidate": "Ridwan Kamil",
      "count": 7,
      "count_prov": 44,
      "rank": 2
    },
    {
      "kode_prov": "15",
      "prov_db": "J A M B I",
      "prov_survey": "Jambi",
      "candidate": "Don\u2019t know",
      "count": 6,
      "count_prov": 44,
      "rank": 3
    },
    {
      "kode_prov": "15",
      "prov_db": "J A M B I",
      "prov_survey": "Jambi",
      "candidate": "Sandiaga Uno",
      "count": 6,
      "count_prov": 44,
      "rank": 3
    },
    {
      "kode_prov": "15",
      "prov_db": "J A M B I",
      "prov_survey": "Jambi",
      "candidate": "Prabowo Subianto",
      "count": 3,
      "count_prov": 44,
      "rank": 4
    },
    {
      "kode_prov": "15",
      "prov_db": "J A M B I",
      "prov_survey": "Jambi",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 2,
      "count_prov": 44,
      "rank": 5
    },
    {
      "kode_prov": "15",
      "prov_db": "J A M B I",
      "prov_survey": "Jambi",
      "candidate": "Other/SPECIFY",
      "count": 2,
      "count_prov": 44,
      "rank": 5
    }
  ],
  "16": [
    {
      "kode_prov": "16",
      "prov_db": "SUMATERA SELATAN",
      "prov_survey": "SumatraSelatan(SumatraSouth)",
      "candidate": "Don\u2019t know",
      "count": 36,
      "count_prov": 59,
      "rank": 1
    },
    {
      "kode_prov": "16",
      "prov_db": "SUMATERA SELATAN",
      "prov_survey": "SumatraSelatan(SumatraSouth)",
      "candidate": "Prabowo Subianto",
      "count": 9,
      "count_prov": 59,
      "rank": 2
    },
    {
      "kode_prov": "16",
      "prov_db": "SUMATERA SELATAN",
      "prov_survey": "SumatraSelatan(SumatraSouth)",
      "candidate": "Ganjar Pranowo",
      "count": 8,
      "count_prov": 59,
      "rank": 3
    },
    {
      "kode_prov": "16",
      "prov_db": "SUMATERA SELATAN",
      "prov_survey": "SumatraSelatan(SumatraSouth)",
      "candidate": "Erick Thohir",
      "count": 4,
      "count_prov": 59,
      "rank": 4
    },
    {
      "kode_prov": "16",
      "prov_db": "SUMATERA SELATAN",
      "prov_survey": "SumatraSelatan(SumatraSouth)",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 1,
      "count_prov": 59,
      "rank": 5
    },
    {
      "kode_prov": "16",
      "prov_db": "SUMATERA SELATAN",
      "prov_survey": "SumatraSelatan(SumatraSouth)",
      "candidate": "Sandiaga Uno",
      "count": 1,
      "count_prov": 59,
      "rank": 5
    }
  ],
  "17": [
    {
      "kode_prov": "17",
      "prov_db": "BENGKULU",
      "prov_survey": "Bengkulu",
      "candidate": "Erick Thohir",
      "count": 3,
      "count_prov": 8,
      "rank": 1
    },
    {
      "kode_prov": "17",
      "prov_db": "BENGKULU",
      "prov_survey": "Bengkulu",
      "candidate": "Don\u2019t know",
      "count": 2,
      "count_prov": 8,
      "rank": 2
    },
    {
      "kode_prov": "17",
      "prov_db": "BENGKULU",
      "prov_survey": "Bengkulu",
      "candidate": "Prabowo Subianto",
      "count": 2,
      "count_prov": 8,
      "rank": 2
    },
    {
      "kode_prov": "17",
      "prov_db": "BENGKULU",
      "prov_survey": "Bengkulu",
      "candidate": "Ridwan Kamil",
      "count": 1,
      "count_prov": 8,
      "rank": 3
    }
  ],
  "18": [
    {
      "kode_prov": "18",
      "prov_db": "LAMPUNG",
      "prov_survey": "Lampung",
      "candidate": "Anies Baswedan",
      "count": 19,
      "count_prov": 51,
      "rank": 1
    },
    {
      "kode_prov": "18",
      "prov_db": "LAMPUNG",
      "prov_survey": "Lampung",
      "candidate": "Erick Thohir",
      "count": 8,
      "count_prov": 51,
      "rank": 2
    },
    {
      "kode_prov": "18",
      "prov_db": "LAMPUNG",
      "prov_survey": "Lampung",
      "candidate": "Sandiaga Uno",
      "count": 8,
      "count_prov": 51,
      "rank": 2
    },
    {
      "kode_prov": "18",
      "prov_db": "LAMPUNG",
      "prov_survey": "Lampung",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 4,
      "count_prov": 51,
      "rank": 3
    },
    {
      "kode_prov": "18",
      "prov_db": "LAMPUNG",
      "prov_survey": "Lampung",
      "candidate": "Ridwan Kamil",
      "count": 4,
      "count_prov": 51,
      "rank": 3
    },
    {
      "kode_prov": "18",
      "prov_db": "LAMPUNG",
      "prov_survey": "Lampung",
      "candidate": "Ganjar Pranowo",
      "count": 3,
      "count_prov": 51,
      "rank": 4
    },
    {
      "kode_prov": "18",
      "prov_db": "LAMPUNG",
      "prov_survey": "Lampung",
      "candidate": "Prabowo Subianto",
      "count": 3,
      "count_prov": 51,
      "rank": 4
    },
    {
      "kode_prov": "18",
      "prov_db": "LAMPUNG",
      "prov_survey": "Lampung",
      "candidate": "Don\u2019t know",
      "count": 2,
      "count_prov": 51,
      "rank": 5
    }
  ],
  "19": [
    {
      "kode_prov": "19",
      "prov_db": "BANGKA BELITUNG",
      "prov_survey": "BangkaBelitung",
      "candidate": "Don\u2019t know",
      "count": 6,
      "count_prov": 20,
      "rank": 1
    },
    {
      "kode_prov": "19",
      "prov_db": "BANGKA BELITUNG",
      "prov_survey": "BangkaBelitung",
      "candidate": "Prabowo Subianto",
      "count": 5,
      "count_prov": 20,
      "rank": 2
    },
    {
      "kode_prov": "19",
      "prov_db": "BANGKA BELITUNG",
      "prov_survey": "BangkaBelitung",
      "candidate": "Erick Thohir",
      "count": 4,
      "count_prov": 20,
      "rank": 3
    },
    {
      "kode_prov": "19",
      "prov_db": "BANGKA BELITUNG",
      "prov_survey": "BangkaBelitung",
      "candidate": "Puan Maharani",
      "count": 3,
      "count_prov": 20,
      "rank": 4
    },
    {
      "kode_prov": "19",
      "prov_db": "BANGKA BELITUNG",
      "prov_survey": "BangkaBelitung",
      "candidate": "Anies Baswedan",
      "count": 1,
      "count_prov": 20,
      "rank": 5
    },
    {
      "kode_prov": "19",
      "prov_db": "BANGKA BELITUNG",
      "prov_survey": "BangkaBelitung",
      "candidate": "Sandiaga Uno",
      "count": 1,
      "count_prov": 20,
      "rank": 5
    }
  ],
  "21": [
    {
      "kode_prov": "21",
      "prov_db": "KEPULAUAN RIAU",
      "prov_survey": "KepulauanRiau(IslandsRiau)",
      "candidate": "Anies Baswedan",
      "count": 12,
      "count_prov": 23,
      "rank": 1
    },
    {
      "kode_prov": "21",
      "prov_db": "KEPULAUAN RIAU",
      "prov_survey": "KepulauanRiau(IslandsRiau)",
      "candidate": "Erick Thohir",
      "count": 8,
      "count_prov": 23,
      "rank": 2
    },
    {
      "kode_prov": "21",
      "prov_db": "KEPULAUAN RIAU",
      "prov_survey": "KepulauanRiau(IslandsRiau)",
      "candidate": "Sandiaga Uno",
      "count": 2,
      "count_prov": 23,
      "rank": 3
    },
    {
      "kode_prov": "21",
      "prov_db": "KEPULAUAN RIAU",
      "prov_survey": "KepulauanRiau(IslandsRiau)",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 1,
      "count_prov": 23,
      "rank": 4
    }
  ],
  "31": [
    {
      "kode_prov": "31",
      "prov_db": "DKI JAKARTA",
      "prov_survey": "Jakarta(DKI)",
      "candidate": "Ganjar Pranowo",
      "count": 23,
      "count_prov": 80,
      "rank": 1
    },
    {
      "kode_prov": "31",
      "prov_db": "DKI JAKARTA",
      "prov_survey": "Jakarta(DKI)",
      "candidate": "Anies Baswedan",
      "count": 17,
      "count_prov": 80,
      "rank": 2
    },
    {
      "kode_prov": "31",
      "prov_db": "DKI JAKARTA",
      "prov_survey": "Jakarta(DKI)",
      "candidate": "Erick Thohir",
      "count": 17,
      "count_prov": 80,
      "rank": 2
    },
    {
      "kode_prov": "31",
      "prov_db": "DKI JAKARTA",
      "prov_survey": "Jakarta(DKI)",
      "candidate": "Don\u2019t know",
      "count": 8,
      "count_prov": 80,
      "rank": 3
    },
    {
      "kode_prov": "31",
      "prov_db": "DKI JAKARTA",
      "prov_survey": "Jakarta(DKI)",
      "candidate": "Prabowo Subianto",
      "count": 7,
      "count_prov": 80,
      "rank": 4
    },
    {
      "kode_prov": "31",
      "prov_db": "DKI JAKARTA",
      "prov_survey": "Jakarta(DKI)",
      "candidate": "Sandiaga Uno",
      "count": 4,
      "count_prov": 80,
      "rank": 5
    },
    {
      "kode_prov": "31",
      "prov_db": "DKI JAKARTA",
      "prov_survey": "Jakarta(DKI)",
      "candidate": "Ridwan Kamil",
      "count": 3,
      "count_prov": 80,
      "rank": 6
    },
    {
      "kode_prov": "31",
      "prov_db": "DKI JAKARTA",
      "prov_survey": "Jakarta(DKI)",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 1,
      "count_prov": 80,
      "rank": 7
    }
  ],
  "32": [
    {
      "kode_prov": "32",
      "prov_db": "JAWA BARAT",
      "prov_survey": "JawaBarat(JavaWest)",
      "candidate": "Ridwan Kamil",
      "count": 129,
      "count_prov": 360,
      "rank": 1
    },
    {
      "kode_prov": "32",
      "prov_db": "JAWA BARAT",
      "prov_survey": "JawaBarat(JavaWest)",
      "candidate": "Erick Thohir",
      "count": 76,
      "count_prov": 360,
      "rank": 2
    },
    {
      "kode_prov": "32",
      "prov_db": "JAWA BARAT",
      "prov_survey": "JawaBarat(JavaWest)",
      "candidate": "Prabowo Subianto",
      "count": 56,
      "count_prov": 360,
      "rank": 3
    },
    {
      "kode_prov": "32",
      "prov_db": "JAWA BARAT",
      "prov_survey": "JawaBarat(JavaWest)",
      "candidate": "Sandiaga Uno",
      "count": 32,
      "count_prov": 360,
      "rank": 4
    },
    {
      "kode_prov": "32",
      "prov_db": "JAWA BARAT",
      "prov_survey": "JawaBarat(JavaWest)",
      "candidate": "Anies Baswedan",
      "count": 27,
      "count_prov": 360,
      "rank": 5
    },
    {
      "kode_prov": "32",
      "prov_db": "JAWA BARAT",
      "prov_survey": "JawaBarat(JavaWest)",
      "candidate": "Ganjar Pranowo",
      "count": 26,
      "count_prov": 360,
      "rank": 6
    },
    {
      "kode_prov": "32",
      "prov_db": "JAWA BARAT",
      "prov_survey": "JawaBarat(JavaWest)",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 7,
      "count_prov": 360,
      "rank": 7
    },
    {
      "kode_prov": "32",
      "prov_db": "JAWA BARAT",
      "prov_survey": "JawaBarat(JavaWest)",
      "candidate": "Don\u2019t know",
      "count": 5,
      "count_prov": 360,
      "rank": 8
    },
    {
      "kode_prov": "32",
      "prov_db": "JAWA BARAT",
      "prov_survey": "JawaBarat(JavaWest)",
      "candidate": "Other/SPECIFY",
      "count": 2,
      "count_prov": 360,
      "rank": 9
    }
  ],
  "33": [
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Ganjar Pranowo",
      "count": 125,
      "count_prov": 260,
      "rank": 1
    },
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Erick Thohir",
      "count": 33,
      "count_prov": 260,
      "rank": 2
    },
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Anies Baswedan",
      "count": 29,
      "count_prov": 260,
      "rank": 3
    },
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Don\u2019t know",
      "count": 27,
      "count_prov": 260,
      "rank": 4
    },
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Sandiaga Uno",
      "count": 14,
      "count_prov": 260,
      "rank": 5
    },
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Ridwan Kamil",
      "count": 13,
      "count_prov": 260,
      "rank": 6
    },
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Prabowo Subianto",
      "count": 12,
      "count_prov": 260,
      "rank": 7
    },
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 4,
      "count_prov": 260,
      "rank": 8
    },
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Other/SPECIFY",
      "count": 2,
      "count_prov": 260,
      "rank": 9
    },
    {
      "kode_prov": "33",
      "prov_db": "JAWA TENGAH",
      "prov_survey": "JawaTengah(JavaCentral)",
      "candidate": "Puan Maharani",
      "count": 1,
      "count_prov": 260,
      "rank": 10
    }
  ],
  "34": [
    {
      "kode_prov": "34",
      "prov_db": "DI YOGYAKARTA",
      "prov_survey": "Yogyakarta",
      "candidate": "Prabowo Subianto",
      "count": 7,
      "count_prov": 30,
      "rank": 1
    },
    {
      "kode_prov": "34",
      "prov_db": "DI YOGYAKARTA",
      "prov_survey": "Yogyakarta",
      "candidate": "Sandiaga Uno",
      "count": 6,
      "count_prov": 30,
      "rank": 2
    },
    {
      "kode_prov": "34",
      "prov_db": "DI YOGYAKARTA",
      "prov_survey": "Yogyakarta",
      "candidate": "Erick Thohir",
      "count": 5,
      "count_prov": 30,
      "rank": 3
    },
    {
      "kode_prov": "34",
      "prov_db": "DI YOGYAKARTA",
      "prov_survey": "Yogyakarta",
      "candidate": "Ganjar Pranowo",
      "count": 5,
      "count_prov": 30,
      "rank": 3
    },
    {
      "kode_prov": "34",
      "prov_db": "DI YOGYAKARTA",
      "prov_survey": "Yogyakarta",
      "candidate": "Anies Baswedan",
      "count": 3,
      "count_prov": 30,
      "rank": 4
    },
    {
      "kode_prov": "34",
      "prov_db": "DI YOGYAKARTA",
      "prov_survey": "Yogyakarta",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 2,
      "count_prov": 30,
      "rank": 5
    },
    {
      "kode_prov": "34",
      "prov_db": "DI YOGYAKARTA",
      "prov_survey": "Yogyakarta",
      "candidate": "Don\u2019t know",
      "count": 2,
      "count_prov": 30,
      "rank": 5
    }
  ],
  "35": [
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Ganjar Pranowo",
      "count": 111,
      "count_prov": 300,
      "rank": 1
    },
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Erick Thohir",
      "count": 78,
      "count_prov": 300,
      "rank": 2
    },
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Don\u2019t know",
      "count": 47,
      "count_prov": 300,
      "rank": 3
    },
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Prabowo Subianto",
      "count": 22,
      "count_prov": 300,
      "rank": 4
    },
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Ridwan Kamil",
      "count": 18,
      "count_prov": 300,
      "rank": 5
    },
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Other/SPECIFY",
      "count": 9,
      "count_prov": 300,
      "rank": 6
    },
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Sandiaga Uno",
      "count": 7,
      "count_prov": 300,
      "rank": 7
    },
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 4,
      "count_prov": 300,
      "rank": 8
    },
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Anies Baswedan",
      "count": 3,
      "count_prov": 300,
      "rank": 9
    },
    {
      "kode_prov": "35",
      "prov_db": "JAWA TIMUR",
      "prov_survey": "JawaTimur(JavaEast)",
      "candidate": "Puan Maharani",
      "count": 1,
      "count_prov": 300,
      "rank": 10
    }
  ],
  "36": [
    {
      "kode_prov": "36",
      "prov_db": "BANTEN",
      "prov_survey": "Banten",
      "candidate": "Don\u2019t know",
      "count": 24,
      "count_prov": 100,
      "rank": 1
    },
    {
      "kode_prov": "36",
      "prov_db": "BANTEN",
      "prov_survey": "Banten",
      "candidate": "Anies Baswedan",
      "count": 23,
      "count_prov": 100,
      "rank": 2
    },
    {
      "kode_prov": "36",
      "prov_db": "BANTEN",
      "prov_survey": "Banten",
      "candidate": "Ridwan Kamil",
      "count": 16,
      "count_prov": 100,
      "rank": 3
    },
    {
      "kode_prov": "36",
      "prov_db": "BANTEN",
      "prov_survey": "Banten",
      "candidate": "Erick Thohir",
      "count": 13,
      "count_prov": 100,
      "rank": 4
    },
    {
      "kode_prov": "36",
      "prov_db": "BANTEN",
      "prov_survey": "Banten",
      "candidate": "Prabowo Subianto",
      "count": 9,
      "count_prov": 100,
      "rank": 5
    },
    {
      "kode_prov": "36",
      "prov_db": "BANTEN",
      "prov_survey": "Banten",
      "candidate": "Ganjar Pranowo",
      "count": 7,
      "count_prov": 100,
      "rank": 6
    },
    {
      "kode_prov": "36",
      "prov_db": "BANTEN",
      "prov_survey": "Banten",
      "candidate": "Sandiaga Uno",
      "count": 4,
      "count_prov": 100,
      "rank": 7
    },
    {
      "kode_prov": "36",
      "prov_db": "BANTEN",
      "prov_survey": "Banten",
      "candidate": "Puan Maharani",
      "count": 3,
      "count_prov": 100,
      "rank": 8
    },
    {
      "kode_prov": "36",
      "prov_db": "BANTEN",
      "prov_survey": "Banten",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 1,
      "count_prov": 100,
      "rank": 9
    }
  ],
  "51": [
    {
      "kode_prov": "51",
      "prov_db": "B A L I",
      "prov_survey": "Bali",
      "candidate": "Erick Thohir",
      "count": 14,
      "count_prov": 30,
      "rank": 1
    },
    {
      "kode_prov": "51",
      "prov_db": "B A L I",
      "prov_survey": "Bali",
      "candidate": "Ganjar Pranowo",
      "count": 4,
      "count_prov": 30,
      "rank": 2
    },
    {
      "kode_prov": "51",
      "prov_db": "B A L I",
      "prov_survey": "Bali",
      "candidate": "Anies Baswedan",
      "count": 3,
      "count_prov": 30,
      "rank": 3
    },
    {
      "kode_prov": "51",
      "prov_db": "B A L I",
      "prov_survey": "Bali",
      "candidate": "Don\u2019t know",
      "count": 3,
      "count_prov": 30,
      "rank": 3
    },
    {
      "kode_prov": "51",
      "prov_db": "B A L I",
      "prov_survey": "Bali",
      "candidate": "Prabowo Subianto",
      "count": 3,
      "count_prov": 30,
      "rank": 3
    },
    {
      "kode_prov": "51",
      "prov_db": "B A L I",
      "prov_survey": "Bali",
      "candidate": "Sandiaga Uno",
      "count": 2,
      "count_prov": 30,
      "rank": 4
    },
    {
      "kode_prov": "51",
      "prov_db": "B A L I",
      "prov_survey": "Bali",
      "candidate": "Other/SPECIFY",
      "count": 1,
      "count_prov": 30,
      "rank": 5
    }
  ],
  "52": [
    {
      "kode_prov": "52",
      "prov_db": "NUSA TENGGARA BARAT",
      "prov_survey": "NusaTenggaraBarat(NusaTenggaraWest)",
      "candidate": "Erick Thohir",
      "count": 18,
      "count_prov": 40,
      "rank": 1
    },
    {
      "kode_prov": "52",
      "prov_db": "NUSA TENGGARA BARAT",
      "prov_survey": "NusaTenggaraBarat(NusaTenggaraWest)",
      "candidate": "Ganjar Pranowo",
      "count": 7,
      "count_prov": 40,
      "rank": 2
    },
    {
      "kode_prov": "52",
      "prov_db": "NUSA TENGGARA BARAT",
      "prov_survey": "NusaTenggaraBarat(NusaTenggaraWest)",
      "candidate": "Anies Baswedan",
      "count": 5,
      "count_prov": 40,
      "rank": 3
    },
    {
      "kode_prov": "52",
      "prov_db": "NUSA TENGGARA BARAT",
      "prov_survey": "NusaTenggaraBarat(NusaTenggaraWest)",
      "candidate": "Don\u2019t know",
      "count": 4,
      "count_prov": 40,
      "rank": 4
    },
    {
      "kode_prov": "52",
      "prov_db": "NUSA TENGGARA BARAT",
      "prov_survey": "NusaTenggaraBarat(NusaTenggaraWest)",
      "candidate": "Ridwan Kamil",
      "count": 3,
      "count_prov": 40,
      "rank": 5
    },
    {
      "kode_prov": "52",
      "prov_db": "NUSA TENGGARA BARAT",
      "prov_survey": "NusaTenggaraBarat(NusaTenggaraWest)",
      "candidate": "Prabowo Subianto",
      "count": 2,
      "count_prov": 40,
      "rank": 6
    },
    {
      "kode_prov": "52",
      "prov_db": "NUSA TENGGARA BARAT",
      "prov_survey": "NusaTenggaraBarat(NusaTenggaraWest)",
      "candidate": "Sandiaga Uno",
      "count": 1,
      "count_prov": 40,
      "rank": 7
    }
  ],
  "53": [
    {
      "kode_prov": "53",
      "prov_db": "NUSA TENGGARA TIMUR",
      "prov_survey": "NusaTenggaraTimur(NusaTenggaraEast)",
      "candidate": "Ganjar Pranowo",
      "count": 11,
      "count_prov": 30,
      "rank": 1
    },
    {
      "kode_prov": "53",
      "prov_db": "NUSA TENGGARA TIMUR",
      "prov_survey": "NusaTenggaraTimur(NusaTenggaraEast)",
      "candidate": "Don\u2019t know",
      "count": 8,
      "count_prov": 30,
      "rank": 2
    },
    {
      "kode_prov": "53",
      "prov_db": "NUSA TENGGARA TIMUR",
      "prov_survey": "NusaTenggaraTimur(NusaTenggaraEast)",
      "candidate": "Anies Baswedan",
      "count": 5,
      "count_prov": 30,
      "rank": 3
    },
    {
      "kode_prov": "53",
      "prov_db": "NUSA TENGGARA TIMUR",
      "prov_survey": "NusaTenggaraTimur(NusaTenggaraEast)",
      "candidate": "Erick Thohir",
      "count": 3,
      "count_prov": 30,
      "rank": 4
    },
    {
      "kode_prov": "53",
      "prov_db": "NUSA TENGGARA TIMUR",
      "prov_survey": "NusaTenggaraTimur(NusaTenggaraEast)",
      "candidate": "Sandiaga Uno",
      "count": 2,
      "count_prov": 30,
      "rank": 5
    },
    {
      "kode_prov": "53",
      "prov_db": "NUSA TENGGARA TIMUR",
      "prov_survey": "NusaTenggaraTimur(NusaTenggaraEast)",
      "candidate": "Prabowo Subianto",
      "count": 1,
      "count_prov": 30,
      "rank": 6
    }
  ],
  "61": [
    {
      "kode_prov": "61",
      "prov_db": "KALIMANTAN BARAT",
      "prov_survey": "KalimantanBarat(KalimantanWest)",
      "candidate": "Don\u2019t know",
      "count": 7,
      "count_prov": 29,
      "rank": 1
    },
    {
      "kode_prov": "61",
      "prov_db": "KALIMANTAN BARAT",
      "prov_survey": "KalimantanBarat(KalimantanWest)",
      "candidate": "Ganjar Pranowo",
      "count": 6,
      "count_prov": 29,
      "rank": 2
    },
    {
      "kode_prov": "61",
      "prov_db": "KALIMANTAN BARAT",
      "prov_survey": "KalimantanBarat(KalimantanWest)",
      "candidate": "Erick Thohir",
      "count": 5,
      "count_prov": 29,
      "rank": 3
    },
    {
      "kode_prov": "61",
      "prov_db": "KALIMANTAN BARAT",
      "prov_survey": "KalimantanBarat(KalimantanWest)",
      "candidate": "Sandiaga Uno",
      "count": 4,
      "count_prov": 29,
      "rank": 4
    },
    {
      "kode_prov": "61",
      "prov_db": "KALIMANTAN BARAT",
      "prov_survey": "KalimantanBarat(KalimantanWest)",
      "candidate": "Ridwan Kamil",
      "count": 3,
      "count_prov": 29,
      "rank": 5
    },
    {
      "kode_prov": "61",
      "prov_db": "KALIMANTAN BARAT",
      "prov_survey": "KalimantanBarat(KalimantanWest)",
      "candidate": "Anies Baswedan",
      "count": 2,
      "count_prov": 29,
      "rank": 6
    },
    {
      "kode_prov": "61",
      "prov_db": "KALIMANTAN BARAT",
      "prov_survey": "KalimantanBarat(KalimantanWest)",
      "candidate": "Other/SPECIFY",
      "count": 1,
      "count_prov": 29,
      "rank": 7
    },
    {
      "kode_prov": "61",
      "prov_db": "KALIMANTAN BARAT",
      "prov_survey": "KalimantanBarat(KalimantanWest)",
      "candidate": "Prabowo Subianto",
      "count": 1,
      "count_prov": 29,
      "rank": 7
    }
  ],
  "62": [
    {
      "kode_prov": "62",
      "prov_db": "KALIMANTAN TENGAH",
      "prov_survey": "KalimantanTengah(KalimantanCentral)",
      "candidate": "Ganjar Pranowo",
      "count": 9,
      "count_prov": 25,
      "rank": 1
    },
    {
      "kode_prov": "62",
      "prov_db": "KALIMANTAN TENGAH",
      "prov_survey": "KalimantanTengah(KalimantanCentral)",
      "candidate": "Don\u2019t know",
      "count": 5,
      "count_prov": 25,
      "rank": 2
    },
    {
      "kode_prov": "62",
      "prov_db": "KALIMANTAN TENGAH",
      "prov_survey": "KalimantanTengah(KalimantanCentral)",
      "candidate": "Anies Baswedan",
      "count": 4,
      "count_prov": 25,
      "rank": 3
    },
    {
      "kode_prov": "62",
      "prov_db": "KALIMANTAN TENGAH",
      "prov_survey": "KalimantanTengah(KalimantanCentral)",
      "candidate": "Prabowo Subianto",
      "count": 4,
      "count_prov": 25,
      "rank": 3
    },
    {
      "kode_prov": "62",
      "prov_db": "KALIMANTAN TENGAH",
      "prov_survey": "KalimantanTengah(KalimantanCentral)",
      "candidate": "Erick Thohir",
      "count": 1,
      "count_prov": 25,
      "rank": 4
    },
    {
      "kode_prov": "62",
      "prov_db": "KALIMANTAN TENGAH",
      "prov_survey": "KalimantanTengah(KalimantanCentral)",
      "candidate": "Ridwan Kamil",
      "count": 1,
      "count_prov": 25,
      "rank": 4
    },
    {
      "kode_prov": "62",
      "prov_db": "KALIMANTAN TENGAH",
      "prov_survey": "KalimantanTengah(KalimantanCentral)",
      "candidate": "Sandiaga Uno",
      "count": 1,
      "count_prov": 25,
      "rank": 4
    }
  ],
  "63": [
    {
      "kode_prov": "63",
      "prov_db": "KALIMANTAN SELATAN",
      "prov_survey": "KalimantanSelatan(KalimantanSouth)",
      "candidate": "Erick Thohir",
      "count": 8,
      "count_prov": 22,
      "rank": 1
    },
    {
      "kode_prov": "63",
      "prov_db": "KALIMANTAN SELATAN",
      "prov_survey": "KalimantanSelatan(KalimantanSouth)",
      "candidate": "Sandiaga Uno",
      "count": 4,
      "count_prov": 22,
      "rank": 2
    },
    {
      "kode_prov": "63",
      "prov_db": "KALIMANTAN SELATAN",
      "prov_survey": "KalimantanSelatan(KalimantanSouth)",
      "candidate": "Anies Baswedan",
      "count": 3,
      "count_prov": 22,
      "rank": 3
    },
    {
      "kode_prov": "63",
      "prov_db": "KALIMANTAN SELATAN",
      "prov_survey": "KalimantanSelatan(KalimantanSouth)",
      "candidate": "Don\u2019t know",
      "count": 3,
      "count_prov": 22,
      "rank": 3
    },
    {
      "kode_prov": "63",
      "prov_db": "KALIMANTAN SELATAN",
      "prov_survey": "KalimantanSelatan(KalimantanSouth)",
      "candidate": "Ganjar Pranowo",
      "count": 2,
      "count_prov": 22,
      "rank": 4
    },
    {
      "kode_prov": "63",
      "prov_db": "KALIMANTAN SELATAN",
      "prov_survey": "KalimantanSelatan(KalimantanSouth)",
      "candidate": "Prabowo Subianto",
      "count": 2,
      "count_prov": 22,
      "rank": 4
    }
  ],
  "64": [
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN TIMUR",
      "prov_survey": "KalimantanTimur(KalimantanEast)",
      "candidate": "Don\u2019t know",
      "count": 9,
      "count_prov": 21,
      "rank": 1
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN UTARA",
      "prov_survey": "KalimantanUtara(KalimantanNorth)",
      "candidate": "Ganjar Pranowo",
      "count": 7,
      "count_prov": 23,
      "rank": 1
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN TIMUR",
      "prov_survey": "KalimantanTimur(KalimantanEast)",
      "candidate": "Ridwan Kamil",
      "count": 4,
      "count_prov": 21,
      "rank": 2
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN UTARA",
      "prov_survey": "KalimantanUtara(KalimantanNorth)",
      "candidate": "Don\u2019t know",
      "count": 5,
      "count_prov": 23,
      "rank": 2
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN UTARA",
      "prov_survey": "KalimantanUtara(KalimantanNorth)",
      "candidate": "Erick Thohir",
      "count": 5,
      "count_prov": 23,
      "rank": 2
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN TIMUR",
      "prov_survey": "KalimantanTimur(KalimantanEast)",
      "candidate": "Prabowo Subianto",
      "count": 3,
      "count_prov": 21,
      "rank": 3
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN UTARA",
      "prov_survey": "KalimantanUtara(KalimantanNorth)",
      "candidate": "Sandiaga Uno",
      "count": 3,
      "count_prov": 23,
      "rank": 3
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN TIMUR",
      "prov_survey": "KalimantanTimur(KalimantanEast)",
      "candidate": "Puan Maharani",
      "count": 2,
      "count_prov": 21,
      "rank": 4
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN UTARA",
      "prov_survey": "KalimantanUtara(KalimantanNorth)",
      "candidate": "Prabowo Subianto",
      "count": 2,
      "count_prov": 23,
      "rank": 4
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN TIMUR",
      "prov_survey": "KalimantanTimur(KalimantanEast)",
      "candidate": "Anies Baswedan",
      "count": 1,
      "count_prov": 21,
      "rank": 5
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN TIMUR",
      "prov_survey": "KalimantanTimur(KalimantanEast)",
      "candidate": "Erick Thohir",
      "count": 1,
      "count_prov": 21,
      "rank": 5
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN TIMUR",
      "prov_survey": "KalimantanTimur(KalimantanEast)",
      "candidate": "Sandiaga Uno",
      "count": 1,
      "count_prov": 21,
      "rank": 5
    },
    {
      "kode_prov": "64",
      "prov_db": "KALIMANTAN UTARA",
      "prov_survey": "KalimantanUtara(KalimantanNorth)",
      "candidate": "Anies Baswedan",
      "count": 1,
      "count_prov": 23,
      "rank": 5
    }
  ],
  "71": [
    {
      "kode_prov": "71",
      "prov_db": "SULAWESI UTARA",
      "prov_survey": "SulawesiUtara(SulawesiNorth)",
      "candidate": "Anies Baswedan",
      "count": 12,
      "count_prov": 12,
      "rank": 1
    }
  ],
  "72": [
    {
      "kode_prov": "72",
      "prov_db": "SULAWESI TENGAH",
      "prov_survey": "SulawesiTengah(SulawesiCentral)",
      "candidate": "Erick Thohir",
      "count": 11,
      "count_prov": 27,
      "rank": 1
    },
    {
      "kode_prov": "72",
      "prov_db": "SULAWESI TENGAH",
      "prov_survey": "SulawesiTengah(SulawesiCentral)",
      "candidate": "Prabowo Subianto",
      "count": 7,
      "count_prov": 27,
      "rank": 2
    },
    {
      "kode_prov": "72",
      "prov_db": "SULAWESI TENGAH",
      "prov_survey": "SulawesiTengah(SulawesiCentral)",
      "candidate": "Ganjar Pranowo",
      "count": 6,
      "count_prov": 27,
      "rank": 3
    },
    {
      "kode_prov": "72",
      "prov_db": "SULAWESI TENGAH",
      "prov_survey": "SulawesiTengah(SulawesiCentral)",
      "candidate": "Sandiaga Uno",
      "count": 2,
      "count_prov": 27,
      "rank": 4
    },
    {
      "kode_prov": "72",
      "prov_db": "SULAWESI TENGAH",
      "prov_survey": "SulawesiTengah(SulawesiCentral)",
      "candidate": "Ridwan Kamil",
      "count": 1,
      "count_prov": 27,
      "rank": 5
    }
  ],
  "73": [
    {
      "kode_prov": "73",
      "prov_db": "SULAWESI SELATAN",
      "prov_survey": "SulawesiSelatan(SulawesiSouth)",
      "candidate": "Prabowo Subianto",
      "count": 16,
      "count_prov": 43,
      "rank": 1
    },
    {
      "kode_prov": "73",
      "prov_db": "SULAWESI SELATAN",
      "prov_survey": "SulawesiSelatan(SulawesiSouth)",
      "candidate": "Erick Thohir",
      "count": 10,
      "count_prov": 43,
      "rank": 2
    },
    {
      "kode_prov": "73",
      "prov_db": "SULAWESI SELATAN",
      "prov_survey": "SulawesiSelatan(SulawesiSouth)",
      "candidate": "Ganjar Pranowo",
      "count": 8,
      "count_prov": 43,
      "rank": 3
    },
    {
      "kode_prov": "73",
      "prov_db": "SULAWESI SELATAN",
      "prov_survey": "SulawesiSelatan(SulawesiSouth)",
      "candidate": "Anies Baswedan",
      "count": 2,
      "count_prov": 43,
      "rank": 4
    },
    {
      "kode_prov": "73",
      "prov_db": "SULAWESI SELATAN",
      "prov_survey": "SulawesiSelatan(SulawesiSouth)",
      "candidate": "Don\u2019t know",
      "count": 2,
      "count_prov": 43,
      "rank": 4
    },
    {
      "kode_prov": "73",
      "prov_db": "SULAWESI SELATAN",
      "prov_survey": "SulawesiSelatan(SulawesiSouth)",
      "candidate": "Ridwan Kamil",
      "count": 2,
      "count_prov": 43,
      "rank": 4
    },
    {
      "kode_prov": "73",
      "prov_db": "SULAWESI SELATAN",
      "prov_survey": "SulawesiSelatan(SulawesiSouth)",
      "candidate": "Sandiaga Uno",
      "count": 2,
      "count_prov": 43,
      "rank": 4
    },
    {
      "kode_prov": "73",
      "prov_db": "SULAWESI SELATAN",
      "prov_survey": "SulawesiSelatan(SulawesiSouth)",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 1,
      "count_prov": 43,
      "rank": 5
    }
  ],
  "74": [
    {
      "kode_prov": "74",
      "prov_db": "SULAWESI TENGGARA",
      "prov_survey": "SulawesiTenggara(SulawesiSoutheast)",
      "candidate": "Anies Baswedan",
      "count": 19,
      "count_prov": 33,
      "rank": 1
    },
    {
      "kode_prov": "74",
      "prov_db": "SULAWESI TENGGARA",
      "prov_survey": "SulawesiTenggara(SulawesiSoutheast)",
      "candidate": "Prabowo Subianto",
      "count": 7,
      "count_prov": 33,
      "rank": 2
    },
    {
      "kode_prov": "74",
      "prov_db": "SULAWESI TENGGARA",
      "prov_survey": "SulawesiTenggara(SulawesiSoutheast)",
      "candidate": "Erick Thohir",
      "count": 4,
      "count_prov": 33,
      "rank": 3
    },
    {
      "kode_prov": "74",
      "prov_db": "SULAWESI TENGGARA",
      "prov_survey": "SulawesiTenggara(SulawesiSoutheast)",
      "candidate": "Don\u2019t know",
      "count": 1,
      "count_prov": 33,
      "rank": 4
    },
    {
      "kode_prov": "74",
      "prov_db": "SULAWESI TENGGARA",
      "prov_survey": "SulawesiTenggara(SulawesiSoutheast)",
      "candidate": "Puan Maharani",
      "count": 1,
      "count_prov": 33,
      "rank": 4
    },
    {
      "kode_prov": "74",
      "prov_db": "SULAWESI TENGGARA",
      "prov_survey": "SulawesiTenggara(SulawesiSoutheast)",
      "candidate": "Sandiaga Uno",
      "count": 1,
      "count_prov": 33,
      "rank": 4
    }
  ],
  "75": [
    {
      "kode_prov": "75",
      "prov_db": "GORONTALO",
      "prov_survey": "Gorontalo",
      "candidate": "Anies Baswedan",
      "count": 6,
      "count_prov": 20,
      "rank": 1
    },
    {
      "kode_prov": "75",
      "prov_db": "GORONTALO",
      "prov_survey": "Gorontalo",
      "candidate": "Sandiaga Uno",
      "count": 6,
      "count_prov": 20,
      "rank": 1
    },
    {
      "kode_prov": "75",
      "prov_db": "GORONTALO",
      "prov_survey": "Gorontalo",
      "candidate": "Erick Thohir",
      "count": 3,
      "count_prov": 20,
      "rank": 2
    },
    {
      "kode_prov": "75",
      "prov_db": "GORONTALO",
      "prov_survey": "Gorontalo",
      "candidate": "Don\u2019t know",
      "count": 2,
      "count_prov": 20,
      "rank": 3
    },
    {
      "kode_prov": "75",
      "prov_db": "GORONTALO",
      "prov_survey": "Gorontalo",
      "candidate": "Ganjar Pranowo",
      "count": 2,
      "count_prov": 20,
      "rank": 3
    },
    {
      "kode_prov": "75",
      "prov_db": "GORONTALO",
      "prov_survey": "Gorontalo",
      "candidate": "Prabowo Subianto",
      "count": 1,
      "count_prov": 20,
      "rank": 4
    }
  ],
  "76": [
    {
      "kode_prov": "76",
      "prov_db": "SULAWESI BARAT",
      "prov_survey": "SulawesiBarat(SulawesiWest)",
      "candidate": "Anies Baswedan",
      "count": 3,
      "count_prov": 5,
      "rank": 1
    },
    {
      "kode_prov": "76",
      "prov_db": "SULAWESI BARAT",
      "prov_survey": "SulawesiBarat(SulawesiWest)",
      "candidate": "Erick Thohir",
      "count": 1,
      "count_prov": 5,
      "rank": 2
    },
    {
      "kode_prov": "76",
      "prov_db": "SULAWESI BARAT",
      "prov_survey": "SulawesiBarat(SulawesiWest)",
      "candidate": "Prabowo Subianto",
      "count": 1,
      "count_prov": 5,
      "rank": 2
    }
  ],
  "81": [
    {
      "kode_prov": "81",
      "prov_db": "MALUKU",
      "prov_survey": "Maluku",
      "candidate": "Anies Baswedan",
      "count": 5,
      "count_prov": 12,
      "rank": 1
    },
    {
      "kode_prov": "81",
      "prov_db": "MALUKU",
      "prov_survey": "Maluku",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 2,
      "count_prov": 12,
      "rank": 2
    },
    {
      "kode_prov": "81",
      "prov_db": "MALUKU",
      "prov_survey": "Maluku",
      "candidate": "Don\u2019t know",
      "count": 1,
      "count_prov": 12,
      "rank": 3
    },
    {
      "kode_prov": "81",
      "prov_db": "MALUKU",
      "prov_survey": "Maluku",
      "candidate": "Ganjar Pranowo",
      "count": 1,
      "count_prov": 12,
      "rank": 3
    },
    {
      "kode_prov": "81",
      "prov_db": "MALUKU",
      "prov_survey": "Maluku",
      "candidate": "Prabowo Subianto",
      "count": 1,
      "count_prov": 12,
      "rank": 3
    },
    {
      "kode_prov": "81",
      "prov_db": "MALUKU",
      "prov_survey": "Maluku",
      "candidate": "Ridwan Kamil",
      "count": 1,
      "count_prov": 12,
      "rank": 3
    },
    {
      "kode_prov": "81",
      "prov_db": "MALUKU",
      "prov_survey": "Maluku",
      "candidate": "Sandiaga Uno",
      "count": 1,
      "count_prov": 12,
      "rank": 3
    }
  ],
  "82": [
    {
      "kode_prov": "82",
      "prov_db": "MALUKU UTARA",
      "prov_survey": "MalukuUtara(MalukuNorth)",
      "candidate": "Ganjar Pranowo",
      "count": 5,
      "count_prov": 18,
      "rank": 1
    },
    {
      "kode_prov": "82",
      "prov_db": "MALUKU UTARA",
      "prov_survey": "MalukuUtara(MalukuNorth)",
      "candidate": "Anies Baswedan",
      "count": 4,
      "count_prov": 18,
      "rank": 2
    },
    {
      "kode_prov": "82",
      "prov_db": "MALUKU UTARA",
      "prov_survey": "MalukuUtara(MalukuNorth)",
      "candidate": "Erick Thohir",
      "count": 3,
      "count_prov": 18,
      "rank": 3
    },
    {
      "kode_prov": "82",
      "prov_db": "MALUKU UTARA",
      "prov_survey": "MalukuUtara(MalukuNorth)",
      "candidate": "Prabowo Subianto",
      "count": 2,
      "count_prov": 18,
      "rank": 4
    },
    {
      "kode_prov": "82",
      "prov_db": "MALUKU UTARA",
      "prov_survey": "MalukuUtara(MalukuNorth)",
      "candidate": "Don\u2019t know",
      "count": 1,
      "count_prov": 18,
      "rank": 5
    },
    {
      "kode_prov": "82",
      "prov_db": "MALUKU UTARA",
      "prov_survey": "MalukuUtara(MalukuNorth)",
      "candidate": "Other/SPECIFY",
      "count": 1,
      "count_prov": 18,
      "rank": 5
    },
    {
      "kode_prov": "82",
      "prov_db": "MALUKU UTARA",
      "prov_survey": "MalukuUtara(MalukuNorth)",
      "candidate": "Ridwan Kamil",
      "count": 1,
      "count_prov": 18,
      "rank": 5
    },
    {
      "kode_prov": "82",
      "prov_db": "MALUKU UTARA",
      "prov_survey": "MalukuUtara(MalukuNorth)",
      "candidate": "Sandiaga Uno",
      "count": 1,
      "count_prov": 18,
      "rank": 5
    }
  ],
  "91": [
    {
      "kode_prov": "91",
      "prov_db": "PAPUA BARAT",
      "prov_survey": "PapuaBarat(PapuaWest)",
      "candidate": "Ganjar Pranowo",
      "count": 8,
      "count_prov": 24,
      "rank": 1
    },
    {
      "kode_prov": "91",
      "prov_db": "PAPUA BARAT",
      "prov_survey": "PapuaBarat(PapuaWest)",
      "candidate": "Don\u2019t know",
      "count": 3,
      "count_prov": 24,
      "rank": 2
    },
    {
      "kode_prov": "91",
      "prov_db": "PAPUA BARAT",
      "prov_survey": "PapuaBarat(PapuaWest)",
      "candidate": "Erick Thohir",
      "count": 3,
      "count_prov": 24,
      "rank": 2
    },
    {
      "kode_prov": "91",
      "prov_db": "PAPUA BARAT",
      "prov_survey": "PapuaBarat(PapuaWest)",
      "candidate": "Prabowo Subianto",
      "count": 3,
      "count_prov": 24,
      "rank": 2
    },
    {
      "kode_prov": "91",
      "prov_db": "PAPUA BARAT",
      "prov_survey": "PapuaBarat(PapuaWest)",
      "candidate": "Ridwan Kamil",
      "count": 3,
      "count_prov": 24,
      "rank": 2
    },
    {
      "kode_prov": "91",
      "prov_db": "PAPUA BARAT",
      "prov_survey": "PapuaBarat(PapuaWest)",
      "candidate": "Anies Baswedan",
      "count": 2,
      "count_prov": 24,
      "rank": 3
    },
    {
      "kode_prov": "91",
      "prov_db": "PAPUA BARAT",
      "prov_survey": "PapuaBarat(PapuaWest)",
      "candidate": "Other/SPECIFY",
      "count": 1,
      "count_prov": 24,
      "rank": 4
    },
    {
      "kode_prov": "91",
      "prov_db": "PAPUA BARAT",
      "prov_survey": "PapuaBarat(PapuaWest)",
      "candidate": "Sandiaga Uno",
      "count": 1,
      "count_prov": 24,
      "rank": 4
    }
  ],
  "00": [
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Ganjar Pranowo",
      "count": 419.0,
      "count_prov": 10.48,
      "rank": 64
    },
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Prabowo Subianto",
      "count": 262.0,
      "count_prov": 6.55,
      "rank": 104
    },
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Erick Thohir",
      "count": 253.0,
      "count_prov": 10.58,
      "rank": 74
    },
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Anies Baswedan",
      "count": 248.0,
      "count_prov": 6.2,
      "rank": 94
    },
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Don\u2019t know",
      "count": 243.0,
      "count_prov": 6.08,
      "rank": 91
    },
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Ridwan Kamil",
      "count": 212.0,
      "count_prov": 5.3,
      "rank": 76
    },
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Sandiaga Uno",
      "count": 131.0,
      "count_prov": 3.28,
      "rank": 124
    },
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Agus Harimurti Yudhoyono",
      "count": 31.0,
      "count_prov": 0.78,
      "rank": 74
    },
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Other/SPECIFY",
      "count": 19.0,
      "count_prov": 0.48,
      "rank": 50
    },
    {
      "kode_prov": "00",
      "prov_db": "National",
      "prov_survey": "National",
      "candidate": "Puan Maharani",
      "count": 12.0,
      "count_prov": 0.3,
      "rank": 43
    }
  ]
}

export const dataCountdownWave4 = {
    "11": [
      {
        "kode_prov": "11",
        "prov_db": "NANGGROE ACEH DARUSSALAM",
        "prov_survey": "Aceh",
        "candidate": "Prabowo Subianto",
        "count": 19,
        "count_prov": 80,
        "rank": 1
      },
      {
        "kode_prov": "11",
        "prov_db": "NANGGROE ACEH DARUSSALAM",
        "prov_survey": "Aceh",
        "candidate": "Ridwan Kamil",
        "count": 11,
        "count_prov": 80,
        "rank": 2
      },
      {
        "kode_prov": "11",
        "prov_db": "NANGGROE ACEH DARUSSALAM",
        "prov_survey": "Aceh",
        "candidate": "Anies Baswedan",
        "count": 10,
        "count_prov": 80,
        "rank": 3
      },
      {
        "kode_prov": "11",
        "prov_db": "NANGGROE ACEH DARUSSALAM",
        "prov_survey": "Aceh",
        "candidate": "Erick Thohir",
        "count": 10,
        "count_prov": 80,
        "rank": 3
      },
      {
        "kode_prov": "11",
        "prov_db": "NANGGROE ACEH DARUSSALAM",
        "prov_survey": "Aceh",
        "candidate": "Ganjar Pranowo",
        "count": 10,
        "count_prov": 80,
        "rank": 3
      },
      {
        "kode_prov": "11",
        "prov_db": "NANGGROE ACEH DARUSSALAM",
        "prov_survey": "Aceh",
        "candidate": "Don't Know",
        "count": 9,
        "count_prov": 80,
        "rank": 4
      },
      {
        "kode_prov": "11",
        "prov_db": "NANGGROE ACEH DARUSSALAM",
        "prov_survey": "Aceh",
        "candidate": "Sandiaga Uno",
        "count": 8,
        "count_prov": 80,
        "rank": 5
      },
      {
        "kode_prov": "11",
        "prov_db": "NANGGROE ACEH DARUSSALAM",
        "prov_survey": "Aceh",
        "candidate": "Puan Maharani",
        "count": 3,
        "count_prov": 80,
        "rank": 6
      }
    ],
    "12": [
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Erick Thohir",
        "count": 71,
        "count_prov": 240,
        "rank": 1
      },
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Prabowo Subianto",
        "count": 50,
        "count_prov": 240,
        "rank": 2
      },
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Ganjar Pranowo",
        "count": 44,
        "count_prov": 240,
        "rank": 3
      },
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Don't Know",
        "count": 34,
        "count_prov": 240,
        "rank": 4
      },
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Anies Baswedan",
        "count": 18,
        "count_prov": 240,
        "rank": 5
      },
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Sandiaga Uno",
        "count": 11,
        "count_prov": 240,
        "rank": 6
      },
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Others",
        "count": 4,
        "count_prov": 240,
        "rank": 7
      },
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Ridwan Kamil",
        "count": 4,
        "count_prov": 240,
        "rank": 7
      },
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 3,
        "count_prov": 240,
        "rank": 8
      },
      {
        "kode_prov": "12",
        "prov_db": "SUMATERA UTARA",
        "prov_survey": "SumatraUtara(SumateraUtara)",
        "candidate": "Puan Maharani",
        "count": 1,
        "count_prov": 240,
        "rank": 9
      }
    ],
    "13": [
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Prabowo Subianto",
        "count": 18,
        "count_prov": 80,
        "rank": 1
      },
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Ganjar Pranowo",
        "count": 15,
        "count_prov": 80,
        "rank": 2
      },
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Erick Thohir",
        "count": 13,
        "count_prov": 80,
        "rank": 3
      },
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Don't Know",
        "count": 9,
        "count_prov": 80,
        "rank": 4
      },
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Sandiaga Uno",
        "count": 9,
        "count_prov": 80,
        "rank": 4
      },
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Anies Baswedan",
        "count": 8,
        "count_prov": 80,
        "rank": 5
      },
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Ridwan Kamil",
        "count": 3,
        "count_prov": 80,
        "rank": 6
      },
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 2,
        "count_prov": 80,
        "rank": 7
      },
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Puan Maharani",
        "count": 2,
        "count_prov": 80,
        "rank": 7
      },
      {
        "kode_prov": "13",
        "prov_db": "SUMATERA BARAT",
        "prov_survey": "SumatraBardi(SumatraWest)",
        "candidate": "Others",
        "count": 1,
        "count_prov": 80,
        "rank": 8
      }
    ],
    "14": [
      {
        "kode_prov": "14",
        "prov_db": "RIAU",
        "prov_survey": "Riau",
        "candidate": "Anies Baswedan",
        "count": 17,
        "count_prov": 71,
        "rank": 1
      },
      {
        "kode_prov": "14",
        "prov_db": "RIAU",
        "prov_survey": "Riau",
        "candidate": "Prabowo Subianto",
        "count": 13,
        "count_prov": 71,
        "rank": 2
      },
      {
        "kode_prov": "14",
        "prov_db": "RIAU",
        "prov_survey": "Riau",
        "candidate": "Erick Thohir",
        "count": 11,
        "count_prov": 71,
        "rank": 3
      },
      {
        "kode_prov": "14",
        "prov_db": "RIAU",
        "prov_survey": "Riau",
        "candidate": "Ganjar Pranowo",
        "count": 9,
        "count_prov": 71,
        "rank": 4
      },
      {
        "kode_prov": "14",
        "prov_db": "RIAU",
        "prov_survey": "Riau",
        "candidate": "Sandiaga Uno",
        "count": 8,
        "count_prov": 71,
        "rank": 5
      },
      {
        "kode_prov": "14",
        "prov_db": "RIAU",
        "prov_survey": "Riau",
        "candidate": "Ridwan Kamil",
        "count": 6,
        "count_prov": 71,
        "rank": 6
      },
      {
        "kode_prov": "14",
        "prov_db": "RIAU",
        "prov_survey": "Riau",
        "candidate": "Don't Know",
        "count": 4,
        "count_prov": 71,
        "rank": 7
      },
      {
        "kode_prov": "14",
        "prov_db": "RIAU",
        "prov_survey": "Riau",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 3,
        "count_prov": 71,
        "rank": 8
      }
    ],
    "15": [
      {
        "kode_prov": "15",
        "prov_db": "J A M B I",
        "prov_survey": "Jambi",
        "candidate": "Anies Baswedan",
        "count": 10,
        "count_prov": 45,
        "rank": 1
      },
      {
        "kode_prov": "15",
        "prov_db": "J A M B I",
        "prov_survey": "Jambi",
        "candidate": "Don't Know",
        "count": 7,
        "count_prov": 45,
        "rank": 2
      },
      {
        "kode_prov": "15",
        "prov_db": "J A M B I",
        "prov_survey": "Jambi",
        "candidate": "Erick Thohir",
        "count": 7,
        "count_prov": 45,
        "rank": 2
      },
      {
        "kode_prov": "15",
        "prov_db": "J A M B I",
        "prov_survey": "Jambi",
        "candidate": "Ganjar Pranowo",
        "count": 6,
        "count_prov": 45,
        "rank": 3
      },
      {
        "kode_prov": "15",
        "prov_db": "J A M B I",
        "prov_survey": "Jambi",
        "candidate": "Prabowo Subianto",
        "count": 6,
        "count_prov": 45,
        "rank": 3
      },
      {
        "kode_prov": "15",
        "prov_db": "J A M B I",
        "prov_survey": "Jambi",
        "candidate": "Sandiaga Uno",
        "count": 6,
        "count_prov": 45,
        "rank": 3
      },
      {
        "kode_prov": "15",
        "prov_db": "J A M B I",
        "prov_survey": "Jambi",
        "candidate": "Ridwan Kamil",
        "count": 2,
        "count_prov": 45,
        "rank": 4
      },
      {
        "kode_prov": "15",
        "prov_db": "J A M B I",
        "prov_survey": "Jambi",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 1,
        "count_prov": 45,
        "rank": 5
      }
    ],
    "16": [
      {
        "kode_prov": "16",
        "prov_db": "SUMATERA SELATAN",
        "prov_survey": "AtanSelSumatera(SumateraSelatan)",
        "candidate": "Don't Know",
        "count": 47,
        "count_prov": 120,
        "rank": 1
      },
      {
        "kode_prov": "16",
        "prov_db": "SUMATERA SELATAN",
        "prov_survey": "AtanSelSumatera(SumateraSelatan)",
        "candidate": "Erick Thohir",
        "count": 24,
        "count_prov": 120,
        "rank": 2
      },
      {
        "kode_prov": "16",
        "prov_db": "SUMATERA SELATAN",
        "prov_survey": "AtanSelSumatera(SumateraSelatan)",
        "candidate": "Ganjar Pranowo",
        "count": 12,
        "count_prov": 120,
        "rank": 3
      },
      {
        "kode_prov": "16",
        "prov_db": "SUMATERA SELATAN",
        "prov_survey": "AtanSelSumatera(SumateraSelatan)",
        "candidate": "Prabowo Subianto",
        "count": 12,
        "count_prov": 120,
        "rank": 3
      },
      {
        "kode_prov": "16",
        "prov_db": "SUMATERA SELATAN",
        "prov_survey": "AtanSelSumatera(SumateraSelatan)",
        "candidate": "Anies Baswedan",
        "count": 11,
        "count_prov": 120,
        "rank": 4
      },
      {
        "kode_prov": "16",
        "prov_db": "SUMATERA SELATAN",
        "prov_survey": "AtanSelSumatera(SumateraSelatan)",
        "candidate": "Sandiaga Uno",
        "count": 8,
        "count_prov": 120,
        "rank": 5
      },
      {
        "kode_prov": "16",
        "prov_db": "SUMATERA SELATAN",
        "prov_survey": "AtanSelSumatera(SumateraSelatan)",
        "candidate": "Ridwan Kamil",
        "count": 4,
        "count_prov": 120,
        "rank": 6
      },
      {
        "kode_prov": "16",
        "prov_db": "SUMATERA SELATAN",
        "prov_survey": "AtanSelSumatera(SumateraSelatan)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 1,
        "count_prov": 120,
        "rank": 7
      },
      {
        "kode_prov": "16",
        "prov_db": "SUMATERA SELATAN",
        "prov_survey": "AtanSelSumatera(SumateraSelatan)",
        "candidate": "Others",
        "count": 1,
        "count_prov": 120,
        "rank": 7
      }
    ],
    "17": [
      {
        "kode_prov": "17",
        "prov_db": "BENGKULU",
        "prov_survey": "Bengkulu",
        "candidate": "Anies Baswedan",
        "count": 8,
        "count_prov": 35,
        "rank": 1
      },
      {
        "kode_prov": "17",
        "prov_db": "BENGKULU",
        "prov_survey": "Bengkulu",
        "candidate": "Sandiaga Uno",
        "count": 8,
        "count_prov": 35,
        "rank": 1
      },
      {
        "kode_prov": "17",
        "prov_db": "BENGKULU",
        "prov_survey": "Bengkulu",
        "candidate": "Don't Know",
        "count": 6,
        "count_prov": 35,
        "rank": 2
      },
      {
        "kode_prov": "17",
        "prov_db": "BENGKULU",
        "prov_survey": "Bengkulu",
        "candidate": "Erick Thohir",
        "count": 4,
        "count_prov": 35,
        "rank": 3
      },
      {
        "kode_prov": "17",
        "prov_db": "BENGKULU",
        "prov_survey": "Bengkulu",
        "candidate": "Prabowo Subianto",
        "count": 4,
        "count_prov": 35,
        "rank": 3
      },
      {
        "kode_prov": "17",
        "prov_db": "BENGKULU",
        "prov_survey": "Bengkulu",
        "candidate": "Ganjar Pranowo",
        "count": 3,
        "count_prov": 35,
        "rank": 4
      },
      {
        "kode_prov": "17",
        "prov_db": "BENGKULU",
        "prov_survey": "Bengkulu",
        "candidate": "Ridwan Kamil",
        "count": 2,
        "count_prov": 35,
        "rank": 5
      }
    ],
    "18": [
      {
        "kode_prov": "18",
        "prov_db": "LAMPUNG",
        "prov_survey": "Lampung",
        "candidate": "Don't Know",
        "count": 33,
        "count_prov": 120,
        "rank": 1
      },
      {
        "kode_prov": "18",
        "prov_db": "LAMPUNG",
        "prov_survey": "Lampung",
        "candidate": "Ganjar Pranowo",
        "count": 21,
        "count_prov": 120,
        "rank": 2
      },
      {
        "kode_prov": "18",
        "prov_db": "LAMPUNG",
        "prov_survey": "Lampung",
        "candidate": "Prabowo Subianto",
        "count": 21,
        "count_prov": 120,
        "rank": 2
      },
      {
        "kode_prov": "18",
        "prov_db": "LAMPUNG",
        "prov_survey": "Lampung",
        "candidate": "Anies Baswedan",
        "count": 16,
        "count_prov": 120,
        "rank": 3
      },
      {
        "kode_prov": "18",
        "prov_db": "LAMPUNG",
        "prov_survey": "Lampung",
        "candidate": "Erick Thohir",
        "count": 12,
        "count_prov": 120,
        "rank": 4
      },
      {
        "kode_prov": "18",
        "prov_db": "LAMPUNG",
        "prov_survey": "Lampung",
        "candidate": "Sandiaga Uno",
        "count": 8,
        "count_prov": 120,
        "rank": 5
      },
      {
        "kode_prov": "18",
        "prov_db": "LAMPUNG",
        "prov_survey": "Lampung",
        "candidate": "Ridwan Kamil",
        "count": 5,
        "count_prov": 120,
        "rank": 6
      },
      {
        "kode_prov": "18",
        "prov_db": "LAMPUNG",
        "prov_survey": "Lampung",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 3,
        "count_prov": 120,
        "rank": 7
      },
      {
        "kode_prov": "18",
        "prov_db": "LAMPUNG",
        "prov_survey": "Lampung",
        "candidate": "Others",
        "count": 1,
        "count_prov": 120,
        "rank": 8
      }
    ],
    "19": [
      {
        "kode_prov": "19",
        "prov_db": "BANGKA BELITUNG",
        "prov_survey": "BangkaBelitung",
        "candidate": "Erick Thohir",
        "count": 13,
        "count_prov": 55,
        "rank": 1
      },
      {
        "kode_prov": "19",
        "prov_db": "BANGKA BELITUNG",
        "prov_survey": "BangkaBelitung",
        "candidate": "Ganjar Pranowo",
        "count": 12,
        "count_prov": 55,
        "rank": 2
      },
      {
        "kode_prov": "19",
        "prov_db": "BANGKA BELITUNG",
        "prov_survey": "BangkaBelitung",
        "candidate": "Don't Know",
        "count": 11,
        "count_prov": 55,
        "rank": 3
      },
      {
        "kode_prov": "19",
        "prov_db": "BANGKA BELITUNG",
        "prov_survey": "BangkaBelitung",
        "candidate": "Anies Baswedan",
        "count": 7,
        "count_prov": 55,
        "rank": 4
      },
      {
        "kode_prov": "19",
        "prov_db": "BANGKA BELITUNG",
        "prov_survey": "BangkaBelitung",
        "candidate": "Prabowo Subianto",
        "count": 4,
        "count_prov": 55,
        "rank": 5
      },
      {
        "kode_prov": "19",
        "prov_db": "BANGKA BELITUNG",
        "prov_survey": "BangkaBelitung",
        "candidate": "Ridwan Kamil",
        "count": 4,
        "count_prov": 55,
        "rank": 5
      },
      {
        "kode_prov": "19",
        "prov_db": "BANGKA BELITUNG",
        "prov_survey": "BangkaBelitung",
        "candidate": "Sandiaga Uno",
        "count": 3,
        "count_prov": 55,
        "rank": 6
      },
      {
        "kode_prov": "19",
        "prov_db": "BANGKA BELITUNG",
        "prov_survey": "BangkaBelitung",
        "candidate": "Puan Maharani",
        "count": 1,
        "count_prov": 55,
        "rank": 7
      }
    ],
    "21": [
      {
        "kode_prov": "21",
        "prov_db": "KEPULAUAN RIAU",
        "prov_survey": "KepulauanRiau(KepulauanRiau)",
        "candidate": "Prabowo Subianto",
        "count": 9,
        "count_prov": 34,
        "rank": 1
      },
      {
        "kode_prov": "21",
        "prov_db": "KEPULAUAN RIAU",
        "prov_survey": "KepulauanRiau(KepulauanRiau)",
        "candidate": "Ganjar Pranowo",
        "count": 6,
        "count_prov": 34,
        "rank": 2
      },
      {
        "kode_prov": "21",
        "prov_db": "KEPULAUAN RIAU",
        "prov_survey": "KepulauanRiau(KepulauanRiau)",
        "candidate": "Anies Baswedan",
        "count": 5,
        "count_prov": 34,
        "rank": 3
      },
      {
        "kode_prov": "21",
        "prov_db": "KEPULAUAN RIAU",
        "prov_survey": "KepulauanRiau(KepulauanRiau)",
        "candidate": "Don't Know",
        "count": 3,
        "count_prov": 34,
        "rank": 4
      },
      {
        "kode_prov": "21",
        "prov_db": "KEPULAUAN RIAU",
        "prov_survey": "KepulauanRiau(KepulauanRiau)",
        "candidate": "Erick Thohir",
        "count": 3,
        "count_prov": 34,
        "rank": 4
      },
      {
        "kode_prov": "21",
        "prov_db": "KEPULAUAN RIAU",
        "prov_survey": "KepulauanRiau(KepulauanRiau)",
        "candidate": "Sandiaga Uno",
        "count": 3,
        "count_prov": 34,
        "rank": 4
      },
      {
        "kode_prov": "21",
        "prov_db": "KEPULAUAN RIAU",
        "prov_survey": "KepulauanRiau(KepulauanRiau)",
        "candidate": "Others",
        "count": 2,
        "count_prov": 34,
        "rank": 5
      },
      {
        "kode_prov": "21",
        "prov_db": "KEPULAUAN RIAU",
        "prov_survey": "KepulauanRiau(KepulauanRiau)",
        "candidate": "Ridwan Kamil",
        "count": 2,
        "count_prov": 34,
        "rank": 5
      },
      {
        "kode_prov": "21",
        "prov_db": "KEPULAUAN RIAU",
        "prov_survey": "KepulauanRiau(KepulauanRiau)",
        "candidate": "Puan Maharani",
        "count": 1,
        "count_prov": 34,
        "rank": 6
      }
    ],
    "31": [
      {
        "kode_prov": "31",
        "prov_db": "DKI JAKARTA",
        "prov_survey": "Jakarta(DKI)",
        "candidate": "Anies Baswedan",
        "count": 26,
        "count_prov": 160,
        "rank": 1
      },
      {
        "kode_prov": "31",
        "prov_db": "DKI JAKARTA",
        "prov_survey": "Jakarta(DKI)",
        "candidate": "Ganjar Pranowo",
        "count": 25,
        "count_prov": 160,
        "rank": 2
      },
      {
        "kode_prov": "31",
        "prov_db": "DKI JAKARTA",
        "prov_survey": "Jakarta(DKI)",
        "candidate": "Don't Know",
        "count": 24,
        "count_prov": 160,
        "rank": 3
      },
      {
        "kode_prov": "31",
        "prov_db": "DKI JAKARTA",
        "prov_survey": "Jakarta(DKI)",
        "candidate": "Prabowo Subianto",
        "count": 20,
        "count_prov": 160,
        "rank": 4
      },
      {
        "kode_prov": "31",
        "prov_db": "DKI JAKARTA",
        "prov_survey": "Jakarta(DKI)",
        "candidate": "Sandiaga Uno",
        "count": 19,
        "count_prov": 160,
        "rank": 5
      },
      {
        "kode_prov": "31",
        "prov_db": "DKI JAKARTA",
        "prov_survey": "Jakarta(DKI)",
        "candidate": "Erick Thohir",
        "count": 17,
        "count_prov": 160,
        "rank": 6
      },
      {
        "kode_prov": "31",
        "prov_db": "DKI JAKARTA",
        "prov_survey": "Jakarta(DKI)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 14,
        "count_prov": 160,
        "rank": 7
      },
      {
        "kode_prov": "31",
        "prov_db": "DKI JAKARTA",
        "prov_survey": "Jakarta(DKI)",
        "candidate": "Ridwan Kamil",
        "count": 13,
        "count_prov": 160,
        "rank": 8
      },
      {
        "kode_prov": "31",
        "prov_db": "DKI JAKARTA",
        "prov_survey": "Jakarta(DKI)",
        "candidate": "Puan Maharani",
        "count": 2,
        "count_prov": 160,
        "rank": 9
      }
    ],
    "32": [
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Ridwan Kamil",
        "count": 225,
        "count_prov": 720,
        "rank": 1
      },
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Prabowo Subianto",
        "count": 166,
        "count_prov": 720,
        "rank": 2
      },
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Anies Baswedan",
        "count": 77,
        "count_prov": 720,
        "rank": 3
      },
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Don't Know",
        "count": 68,
        "count_prov": 720,
        "rank": 4
      },
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Ganjar Pranowo",
        "count": 58,
        "count_prov": 720,
        "rank": 5
      },
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Erick Thohir",
        "count": 53,
        "count_prov": 720,
        "rank": 6
      },
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Sandiaga Uno",
        "count": 42,
        "count_prov": 720,
        "rank": 7
      },
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 24,
        "count_prov": 720,
        "rank": 8
      },
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Puan Maharani",
        "count": 5,
        "count_prov": 720,
        "rank": 9
      },
      {
        "kode_prov": "32",
        "prov_db": "JAWA BARAT",
        "prov_survey": "JawaBarat(JawaBarat)",
        "candidate": "Others",
        "count": 2,
        "count_prov": 720,
        "rank": 10
      }
    ],
    "33": [
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Ganjar Pranowo",
        "count": 262,
        "count_prov": 520,
        "rank": 1
      },
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Erick Thohir",
        "count": 73,
        "count_prov": 520,
        "rank": 2
      },
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Prabowo Subianto",
        "count": 51,
        "count_prov": 520,
        "rank": 3
      },
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Don't Know",
        "count": 49,
        "count_prov": 520,
        "rank": 4
      },
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Anies Baswedan",
        "count": 41,
        "count_prov": 520,
        "rank": 5
      },
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Ridwan Kamil",
        "count": 16,
        "count_prov": 520,
        "rank": 6
      },
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Sandiaga Uno",
        "count": 12,
        "count_prov": 520,
        "rank": 7
      },
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 6,
        "count_prov": 520,
        "rank": 8
      },
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Others",
        "count": 6,
        "count_prov": 520,
        "rank": 8
      },
      {
        "kode_prov": "33",
        "prov_db": "JAWA TENGAH",
        "prov_survey": "JawaTengah(JawaTengah)",
        "candidate": "Puan Maharani",
        "count": 4,
        "count_prov": 520,
        "rank": 9
      }
    ],
    "34": [
      {
        "kode_prov": "34",
        "prov_db": "DI YOGYAKARTA",
        "prov_survey": "Yogyakarta",
        "candidate": "Ganjar Pranowo",
        "count": 22,
        "count_prov": 60,
        "rank": 1
      },
      {
        "kode_prov": "34",
        "prov_db": "DI YOGYAKARTA",
        "prov_survey": "Yogyakarta",
        "candidate": "Anies Baswedan",
        "count": 16,
        "count_prov": 60,
        "rank": 2
      },
      {
        "kode_prov": "34",
        "prov_db": "DI YOGYAKARTA",
        "prov_survey": "Yogyakarta",
        "candidate": "Erick Thohir",
        "count": 7,
        "count_prov": 60,
        "rank": 3
      },
      {
        "kode_prov": "34",
        "prov_db": "DI YOGYAKARTA",
        "prov_survey": "Yogyakarta",
        "candidate": "Sandiaga Uno",
        "count": 6,
        "count_prov": 60,
        "rank": 4
      },
      {
        "kode_prov": "34",
        "prov_db": "DI YOGYAKARTA",
        "prov_survey": "Yogyakarta",
        "candidate": "Prabowo Subianto",
        "count": 4,
        "count_prov": 60,
        "rank": 5
      },
      {
        "kode_prov": "34",
        "prov_db": "DI YOGYAKARTA",
        "prov_survey": "Yogyakarta",
        "candidate": "Ridwan Kamil",
        "count": 3,
        "count_prov": 60,
        "rank": 6
      },
      {
        "kode_prov": "34",
        "prov_db": "DI YOGYAKARTA",
        "prov_survey": "Yogyakarta",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 1,
        "count_prov": 60,
        "rank": 7
      },
      {
        "kode_prov": "34",
        "prov_db": "DI YOGYAKARTA",
        "prov_survey": "Yogyakarta",
        "candidate": "Don't Know",
        "count": 1,
        "count_prov": 60,
        "rank": 7
      }
    ],
    "35": [
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Ganjar Pranowo",
        "count": 297,
        "count_prov": 600,
        "rank": 1
      },
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Erick Thohir",
        "count": 86,
        "count_prov": 600,
        "rank": 2
      },
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Don't Know",
        "count": 59,
        "count_prov": 600,
        "rank": 3
      },
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Prabowo Subianto",
        "count": 58,
        "count_prov": 600,
        "rank": 4
      },
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Ridwan Kamil",
        "count": 58,
        "count_prov": 600,
        "rank": 4
      },
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Sandiaga Uno",
        "count": 18,
        "count_prov": 600,
        "rank": 5
      },
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 13,
        "count_prov": 600,
        "rank": 6
      },
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Anies Baswedan",
        "count": 7,
        "count_prov": 600,
        "rank": 7
      },
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Others",
        "count": 2,
        "count_prov": 600,
        "rank": 8
      },
      {
        "kode_prov": "35",
        "prov_db": "JAWA TIMUR",
        "prov_survey": "JawaTimur(JawaTimur)",
        "candidate": "Puan Maharani",
        "count": 2,
        "count_prov": 600,
        "rank": 8
      }
    ],
    "36": [
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Anies Baswedan",
        "count": 51,
        "count_prov": 200,
        "rank": 1
      },
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Prabowo Subianto",
        "count": 35,
        "count_prov": 200,
        "rank": 2
      },
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Ridwan Kamil",
        "count": 34,
        "count_prov": 200,
        "rank": 3
      },
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Ganjar Pranowo",
        "count": 28,
        "count_prov": 200,
        "rank": 4
      },
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Sandiaga Uno",
        "count": 21,
        "count_prov": 200,
        "rank": 5
      },
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Don't Know",
        "count": 15,
        "count_prov": 200,
        "rank": 6
      },
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 9,
        "count_prov": 200,
        "rank": 7
      },
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Erick Thohir",
        "count": 5,
        "count_prov": 200,
        "rank": 8
      },
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Others",
        "count": 1,
        "count_prov": 200,
        "rank": 9
      },
      {
        "kode_prov": "36",
        "prov_db": "BANTEN",
        "prov_survey": "Banten",
        "candidate": "Puan Maharani",
        "count": 1,
        "count_prov": 200,
        "rank": 9
      }
    ],
    "51": [
      {
        "kode_prov": "51",
        "prov_db": "B A L I",
        "prov_survey": "Bali",
        "candidate": "Ganjar Pranowo",
        "count": 15,
        "count_prov": 60,
        "rank": 1
      },
      {
        "kode_prov": "51",
        "prov_db": "B A L I",
        "prov_survey": "Bali",
        "candidate": "Don't Know",
        "count": 12,
        "count_prov": 60,
        "rank": 2
      },
      {
        "kode_prov": "51",
        "prov_db": "B A L I",
        "prov_survey": "Bali",
        "candidate": "Prabowo Subianto",
        "count": 12,
        "count_prov": 60,
        "rank": 2
      },
      {
        "kode_prov": "51",
        "prov_db": "B A L I",
        "prov_survey": "Bali",
        "candidate": "Ridwan Kamil",
        "count": 8,
        "count_prov": 60,
        "rank": 3
      },
      {
        "kode_prov": "51",
        "prov_db": "B A L I",
        "prov_survey": "Bali",
        "candidate": "Sandiaga Uno",
        "count": 4,
        "count_prov": 60,
        "rank": 4
      },
      {
        "kode_prov": "51",
        "prov_db": "B A L I",
        "prov_survey": "Bali",
        "candidate": "Anies Baswedan",
        "count": 3,
        "count_prov": 60,
        "rank": 5
      },
      {
        "kode_prov": "51",
        "prov_db": "B A L I",
        "prov_survey": "Bali",
        "candidate": "Erick Thohir",
        "count": 3,
        "count_prov": 60,
        "rank": 5
      },
      {
        "kode_prov": "51",
        "prov_db": "B A L I",
        "prov_survey": "Bali",
        "candidate": "Others",
        "count": 2,
        "count_prov": 60,
        "rank": 6
      },
      {
        "kode_prov": "51",
        "prov_db": "B A L I",
        "prov_survey": "Bali",
        "candidate": "Puan Maharani",
        "count": 1,
        "count_prov": 60,
        "rank": 7
      }
    ],
    "52": [
      {
        "kode_prov": "52",
        "prov_db": "NUSA TENGGARA BARAT",
        "prov_survey": "NusaTenggaraBarat(NusaTenggaraBarat)",
        "candidate": "Prabowo Subianto",
        "count": 25,
        "count_prov": 80,
        "rank": 1
      },
      {
        "kode_prov": "52",
        "prov_db": "NUSA TENGGARA BARAT",
        "prov_survey": "NusaTenggaraBarat(NusaTenggaraBarat)",
        "candidate": "Ganjar Pranowo",
        "count": 17,
        "count_prov": 80,
        "rank": 2
      },
      {
        "kode_prov": "52",
        "prov_db": "NUSA TENGGARA BARAT",
        "prov_survey": "NusaTenggaraBarat(NusaTenggaraBarat)",
        "candidate": "Anies Baswedan",
        "count": 14,
        "count_prov": 80,
        "rank": 3
      },
      {
        "kode_prov": "52",
        "prov_db": "NUSA TENGGARA BARAT",
        "prov_survey": "NusaTenggaraBarat(NusaTenggaraBarat)",
        "candidate": "Sandiaga Uno",
        "count": 10,
        "count_prov": 80,
        "rank": 4
      },
      {
        "kode_prov": "52",
        "prov_db": "NUSA TENGGARA BARAT",
        "prov_survey": "NusaTenggaraBarat(NusaTenggaraBarat)",
        "candidate": "Don't Know",
        "count": 4,
        "count_prov": 80,
        "rank": 5
      },
      {
        "kode_prov": "52",
        "prov_db": "NUSA TENGGARA BARAT",
        "prov_survey": "NusaTenggaraBarat(NusaTenggaraBarat)",
        "candidate": "Erick Thohir",
        "count": 3,
        "count_prov": 80,
        "rank": 6
      },
      {
        "kode_prov": "52",
        "prov_db": "NUSA TENGGARA BARAT",
        "prov_survey": "NusaTenggaraBarat(NusaTenggaraBarat)",
        "candidate": "Ridwan Kamil",
        "count": 3,
        "count_prov": 80,
        "rank": 6
      },
      {
        "kode_prov": "52",
        "prov_db": "NUSA TENGGARA BARAT",
        "prov_survey": "NusaTenggaraBarat(NusaTenggaraBarat)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 2,
        "count_prov": 80,
        "rank": 7
      },
      {
        "kode_prov": "52",
        "prov_db": "NUSA TENGGARA BARAT",
        "prov_survey": "NusaTenggaraBarat(NusaTenggaraBarat)",
        "candidate": "Others",
        "count": 2,
        "count_prov": 80,
        "rank": 7
      }
    ],
    "53": [
      {
        "kode_prov": "53",
        "prov_db": "NUSA TENGGARA TIMUR",
        "prov_survey": "NusaTenggaraTimur(NusaTenggaraTimur)",
        "candidate": "Ganjar Pranowo",
        "count": 19,
        "count_prov": 60,
        "rank": 1
      },
      {
        "kode_prov": "53",
        "prov_db": "NUSA TENGGARA TIMUR",
        "prov_survey": "NusaTenggaraTimur(NusaTenggaraTimur)",
        "candidate": "Prabowo Subianto",
        "count": 15,
        "count_prov": 60,
        "rank": 2
      },
      {
        "kode_prov": "53",
        "prov_db": "NUSA TENGGARA TIMUR",
        "prov_survey": "NusaTenggaraTimur(NusaTenggaraTimur)",
        "candidate": "Don't Know",
        "count": 12,
        "count_prov": 60,
        "rank": 3
      },
      {
        "kode_prov": "53",
        "prov_db": "NUSA TENGGARA TIMUR",
        "prov_survey": "NusaTenggaraTimur(NusaTenggaraTimur)",
        "candidate": "Erick Thohir",
        "count": 6,
        "count_prov": 60,
        "rank": 4
      },
      {
        "kode_prov": "53",
        "prov_db": "NUSA TENGGARA TIMUR",
        "prov_survey": "NusaTenggaraTimur(NusaTenggaraTimur)",
        "candidate": "Ridwan Kamil",
        "count": 4,
        "count_prov": 60,
        "rank": 5
      },
      {
        "kode_prov": "53",
        "prov_db": "NUSA TENGGARA TIMUR",
        "prov_survey": "NusaTenggaraTimur(NusaTenggaraTimur)",
        "candidate": "Others",
        "count": 3,
        "count_prov": 60,
        "rank": 6
      },
      {
        "kode_prov": "53",
        "prov_db": "NUSA TENGGARA TIMUR",
        "prov_survey": "NusaTenggaraTimur(NusaTenggaraTimur)",
        "candidate": "Anies Baswedan",
        "count": 1,
        "count_prov": 60,
        "rank": 7
      }
    ],
    "61": [
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Don't Know",
        "count": 27,
        "count_prov": 76,
        "rank": 1
      },
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Erick Thohir",
        "count": 13,
        "count_prov": 76,
        "rank": 2
      },
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Ganjar Pranowo",
        "count": 10,
        "count_prov": 76,
        "rank": 3
      },
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Anies Baswedan",
        "count": 7,
        "count_prov": 76,
        "rank": 4
      },
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Sandiaga Uno",
        "count": 6,
        "count_prov": 76,
        "rank": 5
      },
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Prabowo Subianto",
        "count": 5,
        "count_prov": 76,
        "rank": 6
      },
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 3,
        "count_prov": 76,
        "rank": 7
      },
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Ridwan Kamil",
        "count": 3,
        "count_prov": 76,
        "rank": 7
      },
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Others",
        "count": 1,
        "count_prov": 76,
        "rank": 8
      },
      {
        "kode_prov": "61",
        "prov_db": "KALIMANTAN BARAT",
        "prov_survey": "KalimantanBarat(KalimantanBarat)",
        "candidate": "Puan Maharani",
        "count": 1,
        "count_prov": 76,
        "rank": 8
      }
    ],
    "62": [
      {
        "kode_prov": "62",
        "prov_db": "KALIMANTAN TENGAH",
        "prov_survey": "KalimantanTengah(KalimantanTengah)",
        "candidate": "Don't Know",
        "count": 9,
        "count_prov": 26,
        "rank": 1
      },
      {
        "kode_prov": "62",
        "prov_db": "KALIMANTAN TENGAH",
        "prov_survey": "KalimantanTengah(KalimantanTengah)",
        "candidate": "Prabowo Subianto",
        "count": 7,
        "count_prov": 26,
        "rank": 2
      },
      {
        "kode_prov": "62",
        "prov_db": "KALIMANTAN TENGAH",
        "prov_survey": "KalimantanTengah(KalimantanTengah)",
        "candidate": "Sandiaga Uno",
        "count": 4,
        "count_prov": 26,
        "rank": 3
      },
      {
        "kode_prov": "62",
        "prov_db": "KALIMANTAN TENGAH",
        "prov_survey": "KalimantanTengah(KalimantanTengah)",
        "candidate": "Anies Baswedan",
        "count": 3,
        "count_prov": 26,
        "rank": 4
      },
      {
        "kode_prov": "62",
        "prov_db": "KALIMANTAN TENGAH",
        "prov_survey": "KalimantanTengah(KalimantanTengah)",
        "candidate": "Ganjar Pranowo",
        "count": 2,
        "count_prov": 26,
        "rank": 5
      },
      {
        "kode_prov": "62",
        "prov_db": "KALIMANTAN TENGAH",
        "prov_survey": "KalimantanTengah(KalimantanTengah)",
        "candidate": "Ridwan Kamil",
        "count": 1,
        "count_prov": 26,
        "rank": 6
      }
    ],
    "63": [
      {
        "kode_prov": "63",
        "prov_db": "KALIMANTAN SELATAN",
        "prov_survey": "KalimantanSelatan(KalimantanSelatan)",
        "candidate": "Don't Know",
        "count": 14,
        "count_prov": 60,
        "rank": 1
      },
      {
        "kode_prov": "63",
        "prov_db": "KALIMANTAN SELATAN",
        "prov_survey": "KalimantanSelatan(KalimantanSelatan)",
        "candidate": "Prabowo Subianto",
        "count": 13,
        "count_prov": 60,
        "rank": 2
      },
      {
        "kode_prov": "63",
        "prov_db": "KALIMANTAN SELATAN",
        "prov_survey": "KalimantanSelatan(KalimantanSelatan)",
        "candidate": "Erick Thohir",
        "count": 11,
        "count_prov": 60,
        "rank": 3
      },
      {
        "kode_prov": "63",
        "prov_db": "KALIMANTAN SELATAN",
        "prov_survey": "KalimantanSelatan(KalimantanSelatan)",
        "candidate": "Ganjar Pranowo",
        "count": 8,
        "count_prov": 60,
        "rank": 4
      },
      {
        "kode_prov": "63",
        "prov_db": "KALIMANTAN SELATAN",
        "prov_survey": "KalimantanSelatan(KalimantanSelatan)",
        "candidate": "Ridwan Kamil",
        "count": 7,
        "count_prov": 60,
        "rank": 5
      },
      {
        "kode_prov": "63",
        "prov_db": "KALIMANTAN SELATAN",
        "prov_survey": "KalimantanSelatan(KalimantanSelatan)",
        "candidate": "Anies Baswedan",
        "count": 3,
        "count_prov": 60,
        "rank": 6
      },
      {
        "kode_prov": "63",
        "prov_db": "KALIMANTAN SELATAN",
        "prov_survey": "KalimantanSelatan(KalimantanSelatan)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 2,
        "count_prov": 60,
        "rank": 7
      },
      {
        "kode_prov": "63",
        "prov_db": "KALIMANTAN SELATAN",
        "prov_survey": "KalimantanSelatan(KalimantanSelatan)",
        "candidate": "Sandiaga Uno",
        "count": 2,
        "count_prov": 60,
        "rank": 7
      }
    ],
    "64": [
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN TIMUR",
        "prov_survey": "KalimantanTimur(KalimantanTimur)",
        "candidate": "Ganjar Pranowo",
        "count": 13,
        "count_prov": 52,
        "rank": 1
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN UTARA",
        "prov_survey": "KalimantanUtara(KalimantanUtara)",
        "candidate": "Don't Know",
        "count": 11,
        "count_prov": 26,
        "rank": 1
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN TIMUR",
        "prov_survey": "KalimantanTimur(KalimantanTimur)",
        "candidate": "Don't Know",
        "count": 11,
        "count_prov": 52,
        "rank": 2
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN UTARA",
        "prov_survey": "KalimantanUtara(KalimantanUtara)",
        "candidate": "Prabowo Subianto",
        "count": 5,
        "count_prov": 26,
        "rank": 2
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN TIMUR",
        "prov_survey": "KalimantanTimur(KalimantanTimur)",
        "candidate": "Erick Thohir",
        "count": 10,
        "count_prov": 52,
        "rank": 3
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN UTARA",
        "prov_survey": "KalimantanUtara(KalimantanUtara)",
        "candidate": "Ridwan Kamil",
        "count": 3,
        "count_prov": 26,
        "rank": 3
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN UTARA",
        "prov_survey": "KalimantanUtara(KalimantanUtara)",
        "candidate": "Sandiaga Uno",
        "count": 3,
        "count_prov": 26,
        "rank": 3
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN TIMUR",
        "prov_survey": "KalimantanTimur(KalimantanTimur)",
        "candidate": "Prabowo Subianto",
        "count": 7,
        "count_prov": 52,
        "rank": 4
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN UTARA",
        "prov_survey": "KalimantanUtara(KalimantanUtara)",
        "candidate": "Anies Baswedan",
        "count": 2,
        "count_prov": 26,
        "rank": 4
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN TIMUR",
        "prov_survey": "KalimantanTimur(KalimantanTimur)",
        "candidate": "Sandiaga Uno",
        "count": 5,
        "count_prov": 52,
        "rank": 5
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN UTARA",
        "prov_survey": "KalimantanUtara(KalimantanUtara)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 1,
        "count_prov": 26,
        "rank": 5
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN UTARA",
        "prov_survey": "KalimantanUtara(KalimantanUtara)",
        "candidate": "Ganjar Pranowo",
        "count": 1,
        "count_prov": 26,
        "rank": 5
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN TIMUR",
        "prov_survey": "KalimantanTimur(KalimantanTimur)",
        "candidate": "Anies Baswedan",
        "count": 4,
        "count_prov": 52,
        "rank": 6
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN TIMUR",
        "prov_survey": "KalimantanTimur(KalimantanTimur)",
        "candidate": "Others",
        "count": 1,
        "count_prov": 52,
        "rank": 7
      },
      {
        "kode_prov": "64",
        "prov_db": "KALIMANTAN TIMUR",
        "prov_survey": "KalimantanTimur(KalimantanTimur)",
        "candidate": "Ridwan Kamil",
        "count": 1,
        "count_prov": 52,
        "rank": 7
      }
    ],
    "71": [
      {
        "kode_prov": "71",
        "prov_db": "SULAWESI UTARA",
        "prov_survey": "SulawesiUtara(SulawesiUtara)",
        "candidate": "Anies Baswedan",
        "count": 8,
        "count_prov": 21,
        "rank": 1
      },
      {
        "kode_prov": "71",
        "prov_db": "SULAWESI UTARA",
        "prov_survey": "SulawesiUtara(SulawesiUtara)",
        "candidate": "Prabowo Subianto",
        "count": 5,
        "count_prov": 21,
        "rank": 2
      },
      {
        "kode_prov": "71",
        "prov_db": "SULAWESI UTARA",
        "prov_survey": "SulawesiUtara(SulawesiUtara)",
        "candidate": "Ganjar Pranowo",
        "count": 3,
        "count_prov": 21,
        "rank": 3
      },
      {
        "kode_prov": "71",
        "prov_db": "SULAWESI UTARA",
        "prov_survey": "SulawesiUtara(SulawesiUtara)",
        "candidate": "Don't Know",
        "count": 2,
        "count_prov": 21,
        "rank": 4
      },
      {
        "kode_prov": "71",
        "prov_db": "SULAWESI UTARA",
        "prov_survey": "SulawesiUtara(SulawesiUtara)",
        "candidate": "Puan Maharani",
        "count": 2,
        "count_prov": 21,
        "rank": 4
      },
      {
        "kode_prov": "71",
        "prov_db": "SULAWESI UTARA",
        "prov_survey": "SulawesiUtara(SulawesiUtara)",
        "candidate": "Sandiaga Uno",
        "count": 1,
        "count_prov": 21,
        "rank": 5
      }
    ],
    "72": [
      {
        "kode_prov": "72",
        "prov_db": "SULAWESI TENGAH",
        "prov_survey": "SulawesiTengah(SulawesiTengah)",
        "candidate": "Prabowo Subianto",
        "count": 10,
        "count_prov": 46,
        "rank": 1
      },
      {
        "kode_prov": "72",
        "prov_db": "SULAWESI TENGAH",
        "prov_survey": "SulawesiTengah(SulawesiTengah)",
        "candidate": "Anies Baswedan",
        "count": 9,
        "count_prov": 46,
        "rank": 2
      },
      {
        "kode_prov": "72",
        "prov_db": "SULAWESI TENGAH",
        "prov_survey": "SulawesiTengah(SulawesiTengah)",
        "candidate": "Ganjar Pranowo",
        "count": 7,
        "count_prov": 46,
        "rank": 3
      },
      {
        "kode_prov": "72",
        "prov_db": "SULAWESI TENGAH",
        "prov_survey": "SulawesiTengah(SulawesiTengah)",
        "candidate": "Sandiaga Uno",
        "count": 6,
        "count_prov": 46,
        "rank": 4
      },
      {
        "kode_prov": "72",
        "prov_db": "SULAWESI TENGAH",
        "prov_survey": "SulawesiTengah(SulawesiTengah)",
        "candidate": "Don't Know",
        "count": 5,
        "count_prov": 46,
        "rank": 5
      },
      {
        "kode_prov": "72",
        "prov_db": "SULAWESI TENGAH",
        "prov_survey": "SulawesiTengah(SulawesiTengah)",
        "candidate": "Ridwan Kamil",
        "count": 4,
        "count_prov": 46,
        "rank": 6
      },
      {
        "kode_prov": "72",
        "prov_db": "SULAWESI TENGAH",
        "prov_survey": "SulawesiTengah(SulawesiTengah)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 3,
        "count_prov": 46,
        "rank": 7
      },
      {
        "kode_prov": "72",
        "prov_db": "SULAWESI TENGAH",
        "prov_survey": "SulawesiTengah(SulawesiTengah)",
        "candidate": "Erick Thohir",
        "count": 2,
        "count_prov": 46,
        "rank": 8
      }
    ],
    "73": [
      {
        "kode_prov": "73",
        "prov_db": "SULAWESI SELATAN",
        "prov_survey": "SulawesiSelatan(SulawesiSelatan)",
        "candidate": "Don't Know",
        "count": 37,
        "count_prov": 132,
        "rank": 1
      },
      {
        "kode_prov": "73",
        "prov_db": "SULAWESI SELATAN",
        "prov_survey": "SulawesiSelatan(SulawesiSelatan)",
        "candidate": "Erick Thohir",
        "count": 30,
        "count_prov": 132,
        "rank": 2
      },
      {
        "kode_prov": "73",
        "prov_db": "SULAWESI SELATAN",
        "prov_survey": "SulawesiSelatan(SulawesiSelatan)",
        "candidate": "Ganjar Pranowo",
        "count": 17,
        "count_prov": 132,
        "rank": 3
      },
      {
        "kode_prov": "73",
        "prov_db": "SULAWESI SELATAN",
        "prov_survey": "SulawesiSelatan(SulawesiSelatan)",
        "candidate": "Prabowo Subianto",
        "count": 16,
        "count_prov": 132,
        "rank": 4
      },
      {
        "kode_prov": "73",
        "prov_db": "SULAWESI SELATAN",
        "prov_survey": "SulawesiSelatan(SulawesiSelatan)",
        "candidate": "Anies Baswedan",
        "count": 13,
        "count_prov": 132,
        "rank": 5
      },
      {
        "kode_prov": "73",
        "prov_db": "SULAWESI SELATAN",
        "prov_survey": "SulawesiSelatan(SulawesiSelatan)",
        "candidate": "Ridwan Kamil",
        "count": 7,
        "count_prov": 132,
        "rank": 6
      },
      {
        "kode_prov": "73",
        "prov_db": "SULAWESI SELATAN",
        "prov_survey": "SulawesiSelatan(SulawesiSelatan)",
        "candidate": "Sandiaga Uno",
        "count": 7,
        "count_prov": 132,
        "rank": 6
      },
      {
        "kode_prov": "73",
        "prov_db": "SULAWESI SELATAN",
        "prov_survey": "SulawesiSelatan(SulawesiSelatan)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 3,
        "count_prov": 132,
        "rank": 7
      },
      {
        "kode_prov": "73",
        "prov_db": "SULAWESI SELATAN",
        "prov_survey": "SulawesiSelatan(SulawesiSelatan)",
        "candidate": "Others",
        "count": 2,
        "count_prov": 132,
        "rank": 8
      }
    ],
    "74": [
      {
        "kode_prov": "74",
        "prov_db": "SULAWESI TENGGARA",
        "prov_survey": "SulawesiTenggara(SulawesiTenggara)",
        "candidate": "Prabowo Subianto",
        "count": 11,
        "count_prov": 30,
        "rank": 1
      },
      {
        "kode_prov": "74",
        "prov_db": "SULAWESI TENGGARA",
        "prov_survey": "SulawesiTenggara(SulawesiTenggara)",
        "candidate": "Sandiaga Uno",
        "count": 6,
        "count_prov": 30,
        "rank": 2
      },
      {
        "kode_prov": "74",
        "prov_db": "SULAWESI TENGGARA",
        "prov_survey": "SulawesiTenggara(SulawesiTenggara)",
        "candidate": "Don't Know",
        "count": 5,
        "count_prov": 30,
        "rank": 3
      },
      {
        "kode_prov": "74",
        "prov_db": "SULAWESI TENGGARA",
        "prov_survey": "SulawesiTenggara(SulawesiTenggara)",
        "candidate": "Anies Baswedan",
        "count": 3,
        "count_prov": 30,
        "rank": 4
      },
      {
        "kode_prov": "74",
        "prov_db": "SULAWESI TENGGARA",
        "prov_survey": "SulawesiTenggara(SulawesiTenggara)",
        "candidate": "Ganjar Pranowo",
        "count": 2,
        "count_prov": 30,
        "rank": 5
      },
      {
        "kode_prov": "74",
        "prov_db": "SULAWESI TENGGARA",
        "prov_survey": "SulawesiTenggara(SulawesiTenggara)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 1,
        "count_prov": 30,
        "rank": 6
      },
      {
        "kode_prov": "74",
        "prov_db": "SULAWESI TENGGARA",
        "prov_survey": "SulawesiTenggara(SulawesiTenggara)",
        "candidate": "Erick Thohir",
        "count": 1,
        "count_prov": 30,
        "rank": 6
      },
      {
        "kode_prov": "74",
        "prov_db": "SULAWESI TENGGARA",
        "prov_survey": "SulawesiTenggara(SulawesiTenggara)",
        "candidate": "Ridwan Kamil",
        "count": 1,
        "count_prov": 30,
        "rank": 6
      }
    ],
    "75": [
      {
        "kode_prov": "75",
        "prov_db": "GORONTALO",
        "prov_survey": "Gorontalo",
        "candidate": "Sandiaga Uno",
        "count": 8,
        "count_prov": 20,
        "rank": 1
      },
      {
        "kode_prov": "75",
        "prov_db": "GORONTALO",
        "prov_survey": "Gorontalo",
        "candidate": "Prabowo Subianto",
        "count": 5,
        "count_prov": 20,
        "rank": 2
      },
      {
        "kode_prov": "75",
        "prov_db": "GORONTALO",
        "prov_survey": "Gorontalo",
        "candidate": "Ridwan Kamil",
        "count": 3,
        "count_prov": 20,
        "rank": 3
      },
      {
        "kode_prov": "75",
        "prov_db": "GORONTALO",
        "prov_survey": "Gorontalo",
        "candidate": "Don't Know",
        "count": 2,
        "count_prov": 20,
        "rank": 4
      },
      {
        "kode_prov": "75",
        "prov_db": "GORONTALO",
        "prov_survey": "Gorontalo",
        "candidate": "Erick Thohir",
        "count": 1,
        "count_prov": 20,
        "rank": 5
      },
      {
        "kode_prov": "75",
        "prov_db": "GORONTALO",
        "prov_survey": "Gorontalo",
        "candidate": "Ganjar Pranowo",
        "count": 1,
        "count_prov": 20,
        "rank": 5
      }
    ],
    "76": [
      {
        "kode_prov": "76",
        "prov_db": "SULAWESI BARAT",
        "prov_survey": "SulawesiBardi(SulawesiBarat)",
        "candidate": "Erick Thohir",
        "count": 7,
        "count_prov": 31,
        "rank": 1
      },
      {
        "kode_prov": "76",
        "prov_db": "SULAWESI BARAT",
        "prov_survey": "SulawesiBardi(SulawesiBarat)",
        "candidate": "Prabowo Subianto",
        "count": 6,
        "count_prov": 31,
        "rank": 2
      },
      {
        "kode_prov": "76",
        "prov_db": "SULAWESI BARAT",
        "prov_survey": "SulawesiBardi(SulawesiBarat)",
        "candidate": "Sandiaga Uno",
        "count": 6,
        "count_prov": 31,
        "rank": 2
      },
      {
        "kode_prov": "76",
        "prov_db": "SULAWESI BARAT",
        "prov_survey": "SulawesiBardi(SulawesiBarat)",
        "candidate": "Don't Know",
        "count": 5,
        "count_prov": 31,
        "rank": 3
      },
      {
        "kode_prov": "76",
        "prov_db": "SULAWESI BARAT",
        "prov_survey": "SulawesiBardi(SulawesiBarat)",
        "candidate": "Ridwan Kamil",
        "count": 4,
        "count_prov": 31,
        "rank": 4
      },
      {
        "kode_prov": "76",
        "prov_db": "SULAWESI BARAT",
        "prov_survey": "SulawesiBardi(SulawesiBarat)",
        "candidate": "Anies Baswedan",
        "count": 2,
        "count_prov": 31,
        "rank": 5
      },
      {
        "kode_prov": "76",
        "prov_db": "SULAWESI BARAT",
        "prov_survey": "SulawesiBardi(SulawesiBarat)",
        "candidate": "Puan Maharani",
        "count": 1,
        "count_prov": 31,
        "rank": 6
      }
    ],
    "81": [
      {
        "kode_prov": "81",
        "prov_db": "MALUKU",
        "prov_survey": "Maluku",
        "candidate": "Ganjar Pranowo",
        "count": 10,
        "count_prov": 26,
        "rank": 1
      },
      {
        "kode_prov": "81",
        "prov_db": "MALUKU",
        "prov_survey": "Maluku",
        "candidate": "Erick Thohir",
        "count": 4,
        "count_prov": 26,
        "rank": 2
      },
      {
        "kode_prov": "81",
        "prov_db": "MALUKU",
        "prov_survey": "Maluku",
        "candidate": "Prabowo Subianto",
        "count": 3,
        "count_prov": 26,
        "rank": 3
      },
      {
        "kode_prov": "81",
        "prov_db": "MALUKU",
        "prov_survey": "Maluku",
        "candidate": "Puan Maharani",
        "count": 3,
        "count_prov": 26,
        "rank": 3
      },
      {
        "kode_prov": "81",
        "prov_db": "MALUKU",
        "prov_survey": "Maluku",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 2,
        "count_prov": 26,
        "rank": 4
      },
      {
        "kode_prov": "81",
        "prov_db": "MALUKU",
        "prov_survey": "Maluku",
        "candidate": "Anies Baswedan",
        "count": 2,
        "count_prov": 26,
        "rank": 4
      },
      {
        "kode_prov": "81",
        "prov_db": "MALUKU",
        "prov_survey": "Maluku",
        "candidate": "Don't Know",
        "count": 1,
        "count_prov": 26,
        "rank": 5
      },
      {
        "kode_prov": "81",
        "prov_db": "MALUKU",
        "prov_survey": "Maluku",
        "candidate": "Ridwan Kamil",
        "count": 1,
        "count_prov": 26,
        "rank": 5
      }
    ],
    "82": [
      {
        "kode_prov": "82",
        "prov_db": "MALUKU UTARA",
        "prov_survey": "MalukuUtara(MalukuUtara)",
        "candidate": "Ganjar Pranowo",
        "count": 9,
        "count_prov": 34,
        "rank": 1
      },
      {
        "kode_prov": "82",
        "prov_db": "MALUKU UTARA",
        "prov_survey": "MalukuUtara(MalukuUtara)",
        "candidate": "Anies Baswedan",
        "count": 7,
        "count_prov": 34,
        "rank": 2
      },
      {
        "kode_prov": "82",
        "prov_db": "MALUKU UTARA",
        "prov_survey": "MalukuUtara(MalukuUtara)",
        "candidate": "Ridwan Kamil",
        "count": 6,
        "count_prov": 34,
        "rank": 3
      },
      {
        "kode_prov": "82",
        "prov_db": "MALUKU UTARA",
        "prov_survey": "MalukuUtara(MalukuUtara)",
        "candidate": "Prabowo Subianto",
        "count": 4,
        "count_prov": 34,
        "rank": 4
      },
      {
        "kode_prov": "82",
        "prov_db": "MALUKU UTARA",
        "prov_survey": "MalukuUtara(MalukuUtara)",
        "candidate": "Don't Know",
        "count": 2,
        "count_prov": 34,
        "rank": 5
      },
      {
        "kode_prov": "82",
        "prov_db": "MALUKU UTARA",
        "prov_survey": "MalukuUtara(MalukuUtara)",
        "candidate": "Puan Maharani",
        "count": 2,
        "count_prov": 34,
        "rank": 5
      },
      {
        "kode_prov": "82",
        "prov_db": "MALUKU UTARA",
        "prov_survey": "MalukuUtara(MalukuUtara)",
        "candidate": "Sandiaga Uno",
        "count": 2,
        "count_prov": 34,
        "rank": 5
      },
      {
        "kode_prov": "82",
        "prov_db": "MALUKU UTARA",
        "prov_survey": "MalukuUtara(MalukuUtara)",
        "candidate": "Erick Thohir",
        "count": 1,
        "count_prov": 34,
        "rank": 6
      },
      {
        "kode_prov": "82",
        "prov_db": "MALUKU UTARA",
        "prov_survey": "MalukuUtara(MalukuUtara)",
        "candidate": "Others",
        "count": 1,
        "count_prov": 34,
        "rank": 6
      }
    ],
    "91": [
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Ganjar Pranowo",
        "count": 12,
        "count_prov": 40,
        "rank": 1
      },
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Erick Thohir",
        "count": 7,
        "count_prov": 40,
        "rank": 2
      },
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Ridwan Kamil",
        "count": 6,
        "count_prov": 40,
        "rank": 3
      },
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Sandiaga Uno",
        "count": 5,
        "count_prov": 40,
        "rank": 4
      },
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Anies Baswedan",
        "count": 3,
        "count_prov": 40,
        "rank": 5
      },
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 2,
        "count_prov": 40,
        "rank": 6
      },
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Don't Know",
        "count": 2,
        "count_prov": 40,
        "rank": 6
      },
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Others",
        "count": 1,
        "count_prov": 40,
        "rank": 7
      },
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Prabowo Subianto",
        "count": 1,
        "count_prov": 40,
        "rank": 7
      },
      {
        "kode_prov": "91",
        "prov_db": "PAPUA BARAT",
        "prov_survey": "PapuaBarat(PapuaBarat)",
        "candidate": "Puan Maharani",
        "count": 1,
        "count_prov": 40,
        "rank": 7
      }
    ],
    "94": [
      {
        "kode_prov": "94",
        "prov_db": "PAPUA",
        "prov_survey": "Papua",
        "candidate": "Ganjar Pranowo",
        "count": 9,
        "count_prov": 40,
        "rank": 1
      },
      {
        "kode_prov": "94",
        "prov_db": "PAPUA",
        "prov_survey": "Papua",
        "candidate": "Anies Baswedan",
        "count": 7,
        "count_prov": 40,
        "rank": 2
      },
      {
        "kode_prov": "94",
        "prov_db": "PAPUA",
        "prov_survey": "Papua",
        "candidate": "Erick Thohir",
        "count": 7,
        "count_prov": 40,
        "rank": 2
      },
      {
        "kode_prov": "94",
        "prov_db": "PAPUA",
        "prov_survey": "Papua",
        "candidate": "Prabowo Subianto",
        "count": 6,
        "count_prov": 40,
        "rank": 3
      },
      {
        "kode_prov": "94",
        "prov_db": "PAPUA",
        "prov_survey": "Papua",
        "candidate": "Sandiaga Uno",
        "count": 4,
        "count_prov": 40,
        "rank": 4
      },
      {
        "kode_prov": "94",
        "prov_db": "PAPUA",
        "prov_survey": "Papua",
        "candidate": "Ridwan Kamil",
        "count": 3,
        "count_prov": 40,
        "rank": 5
      },
      {
        "kode_prov": "94",
        "prov_db": "PAPUA",
        "prov_survey": "Papua",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 2,
        "count_prov": 40,
        "rank": 6
      },
      {
        "kode_prov": "94",
        "prov_db": "PAPUA",
        "prov_survey": "Papua",
        "candidate": "Don't Know",
        "count": 2,
        "count_prov": 40,
        "rank": 6
      }
    ],
    "00": [
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Erick Thohir",
        "count": 423.0,
        "count_prov": 21.15,
        "rank": 87
      },
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Ganjar Pranowo",
        "count": 419.0,
        "count_prov": 20.95,
        "rank": 93
      },
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Prabowo Subianto",
        "count": 262.0,
        "count_prov": 13.10,
        "rank": 117
      },
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Anies Baswedan",
        "count": 248.0,
        "count_prov": 12.40,
        "rank": 110
      },
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Don't Know",
        "count": 243.0,
        "count_prov": 12.15,
        "rank": 163
      },
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Ridwan Kamil",
        "count": 212.0,
        "count_prov": 10.48,
        "rank": 118
      },
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Sandiaga Uno",
        "count": 131.0,
        "count_prov": 6.55,
        "rank": 141
      },
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Agus Harimurti  Yudhoyono",
        "count": 31.0,
        "count_prov": 1.55,
        "rank": 147
      },
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Others",
        "count": 19.0,
        "count_prov": 0.95,
        "rank": 125
      },
      {
        "kode_prov": "00",
        "prov_db": "National",
        "prov_survey": "National",
        "candidate": "Puan Maharani",
        "count": 12.0,
        "count_prov": 0.62,
        "rank": 119
      }
    ]
  }