import React, { PureComponent } from "react";
import { VictoryLegend, VictoryPie } from "victory";
import CustomLabel from './CustomLabel';

const colorScale = [
  "#4e77ab",
  "#93b458",
  "#b14c4b",
  "#775d90",
  "#4bacc6",
  "#f79646",
  "#ffffff"
];


class PieChart extends PureComponent {
  state = {
    legend: [],
    data: []
  };
  async componentDidMount() {
    let legend = [],
      data = [];

    await this.props.agama.data.map(d => {
      legend.push({ name: d.order_name, symbol: { type: "square" } });
      data.push({
        x: d.order_name,
        y: (d.total_data / this.props.agama.total_value) * 100,
        label: `${d.order_name} \n ${d.total_data.toLocaleString()} Jiwa`
      });
    });
    this.setState({
      legend,
      data
    });
  }
  render() {
    const { data, legend } = this.state;
    return (
      <svg width={350} height={250}>
        <VictoryLegend
          standalone={false}
          colorScale={colorScale}
          x={0}
          y={0}
          style={{ labels: { fontSize: 14, fill: "white" } }}
          data={legend}
        />
        <VictoryPie
          width={300}
          height={200}
          padding={{
            left: 100,
            top: 20
          }}
          labelRadius={70}
          standalone={false}
          data={data}
          style={{ labels: { fontSize: 18, fontWeight: 500 } }}
          colorScale={colorScale}
          labelComponent={<CustomLabel />}
          events={[{
            target: "data",
            eventHandlers: {
              onMouseOver: () => {
                return [
                  {
                    target: "data",
                    mutation: () => ({style: {fill: 'rgba(255,255,255,0.2)'}})
                  }, {
                    target: "labels",
                    mutation: () => ({ active: true })
                  }
                ];
              },
              onMouseOut: () => {
                return [
                  {
                    target: "data",
                    mutation: () => {}
                  }, {
                    target: "labels",
                    mutation: () => ({ active: false })
                  }
                ];
              }
            }
          }]}
        />
      </svg>
    );
  }
}

export default PieChart;
