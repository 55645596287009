import React from "react";
import "styles/components/submenuturnout.css";

const SubmenuTurnout = props => (
  <div className={`map-submenu ${props.data.openPOI ? "sub-open" : ""}`}>
    <ul className="submenu-map map-select">
      <li className="dropdown-turnout">
        <a
          href="#"
          className="dropdown-toggle dropdown-turnout"
          onClick={e => {
            e.preventDefault();
            props.handleToggleSubMenu(1);
          }}
        >
          {props.data.turnout.method.value !== null
            ? props.data.turnout.method.value
            : props.placeholder}
        </a>
        <ul
          className={`dropdown-turnout submenu-children ${
            props.data.isPOISubOpen === 1 ? "sub-child-open" : ""
          }`}
        >
          {props.data.turnout.method.data.length > 0 &&
            props.data.turnout.method.data.map((data, key) => (
              <li className="dropdown-turnout" key={key}>
                <a
                  href="#"
                  className={
                    props.data.info.location === data.name ? "active" : ""
                  }
                  onClick={e => {
                    e.preventDefault();
                    props.handleChangeMethod(
                      data.name
                    );
                  }}
                >
                  {data.name}
                </a>
              </li>
            ))}
        </ul>
      </li>
    </ul>
  </div>
);

export default SubmenuTurnout;
