import React, { PureComponent } from "react";
import { Marker } from "react-google-maps";
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'

class MarkerPoi extends PureComponent {
  state = {
    isOpen: false
  };

  handleToggleOpenMarker(item) {
    this.setState({
      isOpen: true
    });
  }

  handleToggleCloseMarker(item) {
    this.setState({
      isOpen: false
    });
  }

  render() {
    
    const { data } = this.props;
    let icon = `${process.env.PUBLIC_URL}/img/marker/${this.props.icon}.png`;
    return (
      <Marker
        defaultPosition={ new window.google.maps.LatLng(parseFloat(data.lat), parseFloat(data.lng)) }
        onMouseOver={() => this.handleToggleOpenMarker()}
        onMouseOut={() => this.handleToggleCloseMarker()}
        onClick={() => this.props.handleClickMarker(parseFloat(data.lat), parseFloat(data.lng))}

        options={{
          icon: {
            url: icon
            // scaledSize: { width: 30, height: 40 },
            //anchor: { x: -15, y: 15 },
          }
        }}
      >
        {this.state.isOpen && (
          <InfoBox 
            options={{
              alignBottom: true,
              pane: 'mapPane',
              pixelOffset: new window.google.maps.Size(0, -35),
              closeBoxURL: ``,
              enableEventPropagation: true 
            }}
          > 
            <div style={{ backgroundColor: 'rgba(0,0,0,0.7)', padding: '10px', color: 'white', }}>
              <h3>{data.name}</h3>
              <span>{data.address}</span>
            </div>
          </InfoBox>
        )}
      </Marker>
    );
  }
}

export default MarkerPoi;
