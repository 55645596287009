import React, { Fragment, Component } from "react";

import Sidebar from "components/layout/sidebar/Sidebar";
import Map from "components/shared/map/Map";
import ModalDialog from "components/shared/ModalDialog";
import { Dark } from "components/shared/charts/themeVictory";
import SubmenuRegion from "components/layout/sidebar/SubmenuRegion";
import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";
import CustomLabel from "components/shared/charts/CustomLabel";
import GroupedBarTurnout from "components/shared/charts/GroupedBarTurnout";

import MapBottom from "components/shared/map/MapBottom";

import BootstrapTable from 'react-bootstrap-table-next';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { setModalTopic } from "actions";
import "styles/components/whatson_et.css";

import { getTurnout } from "actions/mapActions";

import GoogleTrends from "./GoogleTrends";
import GoogleTopicTrends from "./GoogleTopicTrends";

import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap,
  getProvince,
  getMediaTrending
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import "styles/components/map-submenu.css";
import MapTurnout from "components/shared/map/MapTurnout";


const API_HOST = process.env.REACT_APP_API_HOST;

const news_data_01 = [];

let trending_twitter = [
  {
    "title": "Apriyani Rahayu",
    "count": "23,6K",
    "desc": ""
  },
  {
    "title": "Tidur",
    "count": "92.7K",
    "desc": ""
  },
  {
    "title": "Nurul Akmal",
    "count": "14.3K",
    "desc": ""
  },
  {
    "title": "#GreyApFinalTokyo2020",
    "count": "9,744",
    "desc": ""
  },
  {
    "title": "#PrankNasional",
    "count": "7,196",
    "desc": ""
  },
  {
    "title": "BOYFRIEND REVEAL",
    "count": "27.3K",
    "desc": ""
  },
  {
    "title": "Indonesia",
    "count": "201K",
    "desc": ""
  },
  {
    "title": "CPNS",
    "count": "6,585",
    "desc": ""
  },
  {
    "title": "Kevin Cordon",
    "count": "103K",
    "desc": ""
  },
  {
    "title": "11.000 T",
    "count": "5,082",
    "desc": ""
  },
  {
    "title": "Johnny",
    "count": "202K",
    "desc": ""
  }
];

// let trending_tiktok = [];

class DailyDose extends Component {
  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "News Media",
        map_type: "horserace",
        data: [
          { id: 1, name: "News Media" },
          { id: 2, name: "Twitter" },
          { id: 3, name: "Facebook" }
        ]
      }
    },
    tiktok_trending: [],
    news_trending: [],
    kompas_trending: [],
    detik_trending: [],
    twitter_trending: [],
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null,

    openSubOne: {
      active: 1,
      opened: false
    },
    openSubTwo: {
      active: 1,
      opened: false
    },
    isLoadingOne: true,
    isLoadingTwo: true
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleHashtagClick = text => {
    this.props.setModalTopic({
      type: "hashtag",
      // path: `whatson/wordcloud/data`,
      path: `whatson/${"sentimen"}/data`,
      params: { keyword: text }
    });
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "News Media" },
            { id: 2, name: "Twitter" },
            { id: 3, name: "Facebook" }
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
    // await GET(API_HOST + "/sental/master/pulau")
    //   .then(res =>
    //     this.setState({
    //       ...this.state,
    //       region: {
    //         ...this.state.region,
    //         pulau: { ...this.state.region.pulau, data: res.data },
    //         step: 1
    //       }
    //     })
    //   )
    //   .catch(err => {
    //     err.response.status === 401
    //       ? this.props.reAuth()
    //       : toastr.error(err.response.statusText);
    //   });

     //kompas trending
    await GET(`${API_HOST}/sental/overview/social-sources/trending?media=kompas`)
      .then(response => {

        console.log("XXXX");
        console.log(response.data.data);
        
        this.setState({
          ...this.state,
          news_trending: response.data.data,
          kompas_trending: response.data.data
        });
      })
      .catch(err => {
        console.log(err);
    });

    //detik trending
    await GET(`${API_HOST}/sental/overview/social-sources/trending?media=detik`)
      .then(response => {
        
        this.setState({
          ...this.state,
          detik_trending: response.data.data
        });
      })
      .catch(err => {
        console.log(err);
    });

    //tiktok trending
    await GET(`${API_HOST}/sental/overview/social-sources/trending?media=tiktok`)
        .then(response => {
          
          this.setState({
            ...this.state,
            tiktok_trending: response.data.data
          });
        })
        .catch(err => {
          console.log(err);
    });

    //twitter trending
    await GET(`${API_HOST}/sental/overview/social-sources/trending?media=twitter`)
        .then(response => {
          
          this.setState({
            ...this.state,
            twitter_trending: response.data.data
          });
        })
        .catch(err => {
          console.log(err);
    });
  }

  render() {
    const { info, state, handleToggleSatellite } = this.props;
    const {
      tiktok_trending,
      news_trending,
      kompas_trending, 
      detik_trending,
      twitter_trending,
      openSubOne,
      isLoadingOne
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition,
      handleGetSubMenu,
      handleChangeMethod,
      handleHashtagClick
    } = this;

    // console.log("xxx detik .. ");
    // console.log(detik_trending);

    //call tiktok trend
    let tiktok_component =  tiktok_trending.map((el, idx) => {
      return <div key={idx} className="col-lg-6">
        <ul style={{listStyleType: 'none'}}>
          <li>
          <img
              src={el.img}
              onClick={() => {}}
              style={{position:'absolute', left: 0, top: 0, width: '40px' }}
              alt=""
            />
            <div style={{padding: '5px 0 5px 10px'}}>
              <a href={el.url} target="_blank" style={{fontSize:'0.85rem', paddingRight: '10px'}} >{el.title} </a>
            </div>
          </li>
        </ul>
        </div>
    });

    let news_component = news_trending.map((el, idx) => {
      return <div key={idx} className="col-lg-6">
        <ul style={{listStyleType: 'none'}}>
          <li>
            <img
              src={el.img}
              onClick={() => {}}
              style={{position:'absolute', left: 0, top: 0, width: '75px' }}
              alt=""
            />
            <div style={{padding: '1px 0 1px 50px'}}>
              <a href={el.url} target="_blank" style={{fontSize:'0.75rem', paddingRight: '10px'}} >{el.title}</a>
            </div>
          </li>
        </ul>
        </div>
    });

    let twitter_component = twitter_trending.map((el, idx) => {
      let hashtag = el.title.replace('#','');
      return <div key={idx} className="col-lg-4">
        {/* <ul style={{listStyleType: 'none'}}>
          <li>
          <img
              src={el.img}
              onClick={() => {}}
              style={{position:'absolute', left: 0, top: 0, width: '40px' }}
              alt=""
            />
            <div style={{padding: '5px 0 5px 10px'}}>
              <a href="#" target="_blank" style={{fontSize:'1.0rem', paddingRight: '10px'}} >{el.title} -- {el.desc}  </a>
            </div>
          </li>
        </ul> */}
        <TwitterHashtagButton tag={hashtag.replace(/ /g,'')} options={{size: 'large',screenName: null,buttonHashtag: null}} />
      </div>
    });

    // let columns_twitter = [{
    //   dataField: 'title',
    //   text: 'Topic Hashtag'
    // }, {
    //   dataField: 'count',
    //   text: 'No. of count'
    // }];

    return (
      <React.Fragment>
        <div className="row" style={{borderBottom: '3px solid #932427', background: '#212529'}}>
          <div className="col-lg-6">
            <div className="row">
              <h2 style={{ marginLeft: "40px", marginTop:"25px",marginBottom:"2px", fontWeight: 'bolder',
                  fontSize:'2.1em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Whats'on People Mind?</h2>
            </div>
            {/* <div className="row">
              <h2 style={{ marginLeft: "40px", marginTop:"2px",marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.8em',  justifyContent:'center', alignItems:'center', color:'white'  }}>SEARCH INTEREST(Last 30days)</h2>
            </div> */}

            <iframe src="https://googletrends.github.io/iframe-scaffolder/#/view?urls=Google%20Hot%20Trends%7Chttps%3A%252F%252Ftrends.google.co.id%252Ftrends%252Fhottrends%252Fvisualize%3Fpn%3Dp19%26nrow%3D5%26ncol%3D5&active=0&sharing=1&autoplay=0&loop=1&layout=tabs&theme=default" 
            width="100%" height="65%">

            </iframe>
            
            {/* <div className="row">
              <h2 style={{ marginLeft: "40px", marginTop:"12px",marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.8em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Twitter | Trending</h2>
            </div> */}

            <div className="row">
              <h3 style={{ background: '#a61c1d', padding: '10px', marginLeft: "30px", marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.5em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Twitter | Trending</h3>
            </div>

            <div className="row" style={{paddingLeft: "20px"}}>
              {twitter_component}
            </div>
            
          </div>      
          <div className="col-lg-6" >
            <div className="row">
              <h2 style={{ marginLeft: "20px", marginTop:"25px",marginBottom:"2px", fontWeight: 'bolder',
                  fontSize:'2.1em',  justifyContent:'center', alignItems:'center', color:'white'  }}>NEWS COVERAGE</h2>
            </div>

            <div className="row" style={{paddingRight:'10px', marginTop: '-30px'}}>
              <div className="col-lg-12">
                <ul className="sliding-menu">
                  <li style={{width: 'calc(100%/2)'}}>
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState(
                          {
                            ...this.state,
                            openSubOne: {
                              active: 1,
                              opened: false
                            },
                            news_trending: kompas_trending
                          }
                        );
                      }}
                      className={` ${
                        openSubOne.active === 1 ? "selected" : ""
                      }`}
                    >
                      Kompas
                    </a>
                  </li>
                  <li style={{width: 'calc(100%/2)'}}>
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState(
                          {
                            ...this.state,
                            openSubOne: {
                              active: 2,
                              opened: false
                            },
                            news_trending: detik_trending
                          }
                        );
                      }}
                      className={` ${
                        openSubOne.active === 2 ? "selected" : ""
                      }`}
                    >
                      Detik
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>

            {/* <div className="row">
              <h2 style={{ marginLeft: "20px", marginTop:"2px",marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.8em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Top Media | Topic</h2>
            </div> */}

            {/* <div id="widget-topic">
              <GoogleTopicTrends
                type="dailytrends"
                url="https://ssl.gstatic.com/trends_nrtr/2578_RC02/embed_loader.js"
              />
            </div> */}

            <div className="row" style={{paddingLeft: "30px", marginBottom: "20px"}}>
              {news_component}
            </div>

            <div className="row" style={{background: '#a61c1d', marginTop:"5px", marginBottom: '5px', 
              marginLeft:'2px', marginRight:'10px', paddingTop: '5px', paddingLeft: '10px'}}>
              <h2 style={{ marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.8em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Tiktok | Trending</h2>
            </div>

            <div className="row" style={{paddingLeft: "20px"}}>
              {tiktok_component}
            </div>

            {/* <BootstrapTable
              keyField="title"
              data={ trending_twitter }
              columns={ columns_twitter }
              striped
              hover
              condensed
            /> */}
          </div>   
          
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout,
  setModalTopic,
  getMediaTrending
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyDose);
