const horseraceWave5 = {
  "location_id": "-1",
  "location_name": "National",
  "total_vote_1": 83664216,
  "total_vote_2": 67973884,
  "total_vote_1_percent": 13,
  "total_vote_2_percent": 23,
  "total_1_fav_national": "66/18",
  "total_2_fav_national": "78/13",
  "data": [
    {
      "id": "32",
      "name": "JAWA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 10718874,
      "vote_2": 16029115,
      "vote_1_percent": 7,
      "vote_2_percent": 32,
      "total_1_fav": "66/15",
      "total_2_fav": "83/11"
    },
    {
      "id": "33",
      "name": "JAWA TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 16784716,
      "vote_2": 4939445,
      "vote_1_percent": 14,
      "vote_2_percent": 50,
      "total_1_fav": "65/20",
      "total_2_fav": "75/13"
    },
    {
      "id": "35",
      "name": "JAWA TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 16145259,
      "vote_2": 8392506,
      "vote_1_percent": 14,
      "vote_2_percent": 50,
      "total_1_fav": "62/21",
      "total_2_fav": "53/30"
    },
    {
      "id": "36",
      "name": "BANTEN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2530608,
      "vote_2": 4045338,
      "vote_1_percent": 3,
      "vote_2_percent": 18,
      "total_1_fav": "63/20",
      "total_2_fav": "71/23"
    },
    {
      "id": "12",
      "name": "SUMATERA UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 3886014,
      "vote_2": 3553576,
      "vote_1_percent": 30,
      "vote_2_percent": 21,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "31",
      "name": "DKI JAKARTA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 3269971,
      "vote_2": 3057851,
      "vote_1_percent": 11,
      "vote_2_percent": 16,
      "total_1_fav": "63/20",
      "total_2_fav": "67/27"
    },
    {
      "id": "61",
      "name": "KALIMANTAN BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1707167,
      "vote_2": 1260628,
      "vote_1_percent": 17,
      "vote_2_percent": 7,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "18",
      "name": "LAMPUNG",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2845798,
      "vote_2": 1951645,
      "vote_1_percent": 10,
      "vote_2_percent": 18,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "14",
      "name": "RIAU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1246888,
      "vote_2": 1973298,
      "vote_1_percent": 26,
      "vote_2_percent": 18,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "91",
      "name": "PAPUA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 395084,
      "vote_2": 94534,
      "vote_1_percent": 18,
      "vote_2_percent": 30,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "65",
      "name": "KALIMANTAN UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 247352,
      "vote_2": 105498,
      "vote_1_percent": 0,
      "vote_2_percent": 42,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "64",
      "name": "KALIMANTAN TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1093148,
      "vote_2": 870043,
      "vote_1_percent": 16,
      "vote_2_percent": 21,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "53",
      "name": "NUSA TENGGARA TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2362041,
      "vote_2": 305615,
      "vote_1_percent": 10,
      "vote_2_percent": 32,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "51",
      "name": "B A L I",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2342628,
      "vote_2": 212586,
      "vote_1_percent": 35,
      "vote_2_percent": 25,
      "total_1_fav": "73/14",
      "total_2_fav": "69/25"
    },
    {
      "id": "11",
      "name": "NANGGROE ACEH DARUSSALAM",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 397251,
      "vote_2": 2352397,
      "vote_1_percent": 13,
      "vote_2_percent": 24,
      "total_1_fav": "63/20",
      "total_2_fav": "80/15"
    },
    {
      "id": "81",
      "name": "MALUKU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 561012,
      "vote_2": 388069,
      "vote_1_percent": 16,
      "vote_2_percent": 39,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "62",
      "name": "KALIMANTAN TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 828596,
      "vote_2": 536213,
      "vote_1_percent": 0,
      "vote_2_percent": 27,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "74",
      "name": "SULAWESI TENGGARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 554470,
      "vote_2": 840465,
      "vote_1_percent": 3,
      "vote_2_percent": 37,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "15",
      "name": "J A M B I",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 858738,
      "vote_2": 1200255,
      "vote_1_percent": 30,
      "vote_2_percent": 23,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "19",
      "name": "BANGKA BELITUNG",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 495500,
      "vote_2": 288097,
      "vote_1_percent": 24,
      "vote_2_percent": 8,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "76",
      "name": "SULAWESI BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 474852,
      "vote_2": 263345,
      "vote_1_percent": 12,
      "vote_2_percent": 19,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "63",
      "name": "KALIMANTAN SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 821284,
      "vote_2": 1463514,
      "vote_1_percent": 38,
      "vote_2_percent": 23,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "75",
      "name": "GORONTALO",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 369338,
      "vote_2": 344798,
      "vote_1_percent": 5,
      "vote_2_percent": 25,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "13",
      "name": "SUMATERA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 407638,
      "vote_2": 2485265,
      "vote_1_percent": 16,
      "vote_2_percent": 19,
      "total_1_fav": "60/15",
      "total_2_fav": "80/12"
    },
    {
      "id": "71",
      "name": "SULAWESI UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1218303,
      "vote_2": 359131,
      "vote_1_percent": 0,
      "vote_2_percent": 38,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "82",
      "name": "MALUKU UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 308197,
      "vote_2": 341711,
      "vote_1_percent": 23,
      "vote_2_percent": 27,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "72",
      "name": "SULAWESI TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 913753,
      "vote_2": 705310,
      "vote_1_percent": 34,
      "vote_2_percent": 22,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "34",
      "name": "DI YOGYAKARTA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1640789,
      "vote_2": 735789,
      "vote_1_percent": 12,
      "vote_2_percent": 37,
      "total_1_fav": "62/21",
      "total_2_fav": "53/30"
    },
    {
      "id": "16",
      "name": "SUMATERA SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1915488,
      "vote_2": 2830326,
      "vote_1_percent": 0,
      "vote_2_percent": 0,
      "total_1_fav": "60/15",
      "total_2_fav": "80/12"
    },
    {
      "id": "73",
      "name": "SULAWESI SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2117839,
      "vote_2": 2807109,
      "vote_1_percent": 33,
      "vote_2_percent": 28,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "17",
      "name": "BENGKULU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 582845,
      "vote_2": 585598,
      "vote_1_percent": 31,
      "vote_2_percent": 23,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "21",
      "name": "KEPULAUAN RIAU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 546373,
      "vote_2": 460957,
      "vote_1_percent": 9,
      "vote_2_percent": 27,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "52",
      "name": "NUSA TENGGARA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 950480,
      "vote_2": 2009305,
      "vote_1_percent": 54,
      "vote_2_percent": 31,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "94",
      "name": "PAPUA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2125922,
      "vote_2": 184552,
      "vote_1_percent": 58,
      "vote_2_percent": 23,
      "total_1_fav": "73/14",
      "total_2_fav": "69/25"
    }
  ]
};


const horseraceWave4 = {
  "location_id": "-1",
  "location_name": "National",
  "total_vote_1": 83664216,
  "total_vote_2": 67973884,
  "total_vote_1_percent": 13,
  "total_vote_2_percent": 25,
  "total_1_fav_national": "66/18",
  "total_2_fav_national": "78/13",
  "data": [
    {
      "id": "32",
      "name": "JAWA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 10718874,
      "vote_2": 16029115,
      "vote_1_percent": 7,
      "vote_2_percent": 32,
      "total_1_fav": "66/15",
      "total_2_fav": "83/11"
    },
    {
      "id": "33",
      "name": "JAWA TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 16784716,
      "vote_2": 4939445,
      "vote_1_percent": 14,
      "vote_2_percent": 50,
      "total_1_fav": "65/20",
      "total_2_fav": "75/13"
    },
    {
      "id": "35",
      "name": "JAWA TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 16145259,
      "vote_2": 8392506,
      "vote_1_percent": 14,
      "vote_2_percent": 50,
      "total_1_fav": "62/21",
      "total_2_fav": "53/30"
    },
    {
      "id": "36",
      "name": "BANTEN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2530608,
      "vote_2": 4045338,
      "vote_1_percent": 3,
      "vote_2_percent": 18,
      "total_1_fav": "63/20",
      "total_2_fav": "71/23"
    },
    {
      "id": "12",
      "name": "SUMATERA UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 3886014,
      "vote_2": 3553576,
      "vote_1_percent": 30,
      "vote_2_percent": 21,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "31",
      "name": "DKI JAKARTA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 3269971,
      "vote_2": 3057851,
      "vote_1_percent": 11,
      "vote_2_percent": 16,
      "total_1_fav": "63/20",
      "total_2_fav": "67/27"
    },
    {
      "id": "61",
      "name": "KALIMANTAN BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1707167,
      "vote_2": 1260628,
      "vote_1_percent": 17,
      "vote_2_percent": 7,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "18",
      "name": "LAMPUNG",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2845798,
      "vote_2": 1951645,
      "vote_1_percent": 10,
      "vote_2_percent": 18,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "14",
      "name": "RIAU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1246888,
      "vote_2": 1973298,
      "vote_1_percent": 16,
      "vote_2_percent": 18,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "91",
      "name": "PAPUA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 395084,
      "vote_2": 94534,
      "vote_1_percent": 18,
      "vote_2_percent": 30,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "65",
      "name": "KALIMANTAN UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 247352,
      "vote_2": 105498,
      "vote_1_percent": 0,
      "vote_2_percent": 42,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "64",
      "name": "KALIMANTAN TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1093148,
      "vote_2": 870043,
      "vote_1_percent": 19,
      "vote_2_percent": 25,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "53",
      "name": "NUSA TENGGARA TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2362041,
      "vote_2": 305615,
      "vote_1_percent": 10,
      "vote_2_percent": 32,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "51",
      "name": "B A L I",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2342628,
      "vote_2": 212586,
      "vote_1_percent": 5,
      "vote_2_percent": 25,
      "total_1_fav": "73/14",
      "total_2_fav": "69/25"
    },
    {
      "id": "11",
      "name": "NANGGROE ACEH DARUSSALAM",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 397251,
      "vote_2": 2352397,
      "vote_1_percent": 13,
      "vote_2_percent": 24,
      "total_1_fav": "63/20",
      "total_2_fav": "80/15"
    },
    {
      "id": "81",
      "name": "MALUKU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 561012,
      "vote_2": 388069,
      "vote_1_percent": 16,
      "vote_2_percent": 39,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "62",
      "name": "KALIMANTAN TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 828596,
      "vote_2": 536213,
      "vote_1_percent": 0,
      "vote_2_percent": 27,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "74",
      "name": "SULAWESI TENGGARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 554470,
      "vote_2": 840465,
      "vote_1_percent": 3,
      "vote_2_percent": 37,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "15",
      "name": "J A M B I",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 858738,
      "vote_2": 1200255,
      "vote_1_percent": 16,
      "vote_2_percent": 23,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "19",
      "name": "BANGKA BELITUNG",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 495500,
      "vote_2": 288097,
      "vote_1_percent": 24,
      "vote_2_percent": 8,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "76",
      "name": "SULAWESI BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 474852,
      "vote_2": 263345,
      "vote_1_percent": 23,
      "vote_2_percent": 19,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "63",
      "name": "KALIMANTAN SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 821284,
      "vote_2": 1463514,
      "vote_1_percent": 18,
      "vote_2_percent": 23,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "75",
      "name": "GORONTALO",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 369338,
      "vote_2": 344798,
      "vote_1_percent": 5,
      "vote_2_percent": 25,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "13",
      "name": "SUMATERA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 407638,
      "vote_2": 2485265,
      "vote_1_percent": 16,
      "vote_2_percent": 19,
      "total_1_fav": "60/15",
      "total_2_fav": "80/12"
    },
    {
      "id": "71",
      "name": "SULAWESI UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1218303,
      "vote_2": 359131,
      "vote_1_percent": 0,
      "vote_2_percent": 38,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "82",
      "name": "MALUKU UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 308197,
      "vote_2": 341711,
      "vote_1_percent": 3,
      "vote_2_percent": 27,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "72",
      "name": "SULAWESI TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 913753,
      "vote_2": 705310,
      "vote_1_percent": 4,
      "vote_2_percent": 22,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "34",
      "name": "DI YOGYAKARTA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1640789,
      "vote_2": 735789,
      "vote_1_percent": 12,
      "vote_2_percent": 37,
      "total_1_fav": "62/21",
      "total_2_fav": "53/30"
    },
    {
      "id": "16",
      "name": "SUMATERA SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1915488,
      "vote_2": 2830326,
      "vote_1_percent": 0,
      "vote_2_percent": 0,
      "total_1_fav": "60/15",
      "total_2_fav": "80/12"
    },
    {
      "id": "73",
      "name": "SULAWESI SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2117839,
      "vote_2": 2807109,
      "vote_1_percent": 23,
      "vote_2_percent": 28,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "17",
      "name": "BENGKULU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 582845,
      "vote_2": 585598,
      "vote_1_percent": 11,
      "vote_2_percent": 23,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "21",
      "name": "KEPULAUAN RIAU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 546373,
      "vote_2": 460957,
      "vote_1_percent": 9,
      "vote_2_percent": 27,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "52",
      "name": "NUSA TENGGARA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 950480,
      "vote_2": 2009305,
      "vote_1_percent": 4,
      "vote_2_percent": 31,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "94",
      "name": "PAPUA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2125922,
      "vote_2": 184552,
      "vote_1_percent": 18,
      "vote_2_percent": 23,
      "total_1_fav": "73/14",
      "total_2_fav": "69/25"
    }
  ]
};

const horseraceWave3 = {
  "location_id": "-1",
  "location_name": "National",
  "total_vote_1": 83664216,
  "total_vote_2": 67973884,
  "total_vote_1_percent": 4,
  "total_vote_2_percent": 17,
  "total_1_fav_national": "66/18",
  "total_2_fav_national": "78/13",
  "data": [
    {
      "id": "32",
      "name": "JAWA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 10718874,
      "vote_2": 16029115,
      "vote_1_percent": 1,
      "vote_2_percent": 16,
      "total_1_fav": "66/15",
      "total_2_fav": "83/11"
    },
    {
      "id": "33",
      "name": "JAWA TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 16784716,
      "vote_2": 4939445,
      "vote_1_percent": 4,
      "vote_2_percent": 36,
      "total_1_fav": "65/20",
      "total_2_fav": "75/13"
    },
    {
      "id": "35",
      "name": "JAWA TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 16145259,
      "vote_2": 8392506,
      "vote_1_percent": 6,
      "vote_2_percent": 15,
      "total_1_fav": "62/21",
      "total_2_fav": "53/30"
    },
    {
      "id": "36",
      "name": "BANTEN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2530608,
      "vote_2": 4045338,
      "vote_1_percent": 1,
      "vote_2_percent": 6,
      "total_1_fav": "63/20",
      "total_2_fav": "71/23"
    },
    {
      "id": "12",
      "name": "SUMATERA UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 3886014,
      "vote_2": 3553576,
      "vote_1_percent": 4,
      "vote_2_percent": 10,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "31",
      "name": "DKI JAKARTA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 3269971,
      "vote_2": 3057851,
      "vote_1_percent": 5,
      "vote_2_percent": 23,
      "total_1_fav": "63/20",
      "total_2_fav": "67/27"
    },
    {
      "id": "61",
      "name": "KALIMANTAN BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1707167,
      "vote_2": 1260628,
      "vote_1_percent": 10,
      "vote_2_percent": 21,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "18",
      "name": "LAMPUNG",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2845798,
      "vote_2": 1951645,
      "vote_1_percent": 9,
      "vote_2_percent": 15,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "14",
      "name": "RIAU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1246888,
      "vote_2": 1973298,
      "vote_1_percent": 11,
      "vote_2_percent": 11,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "91",
      "name": "PAPUA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 395084,
      "vote_2": 94534,
      "vote_1_percent": 7,
      "vote_2_percent": 34,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "65",
      "name": "KALIMANTAN UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 247352,
      "vote_2": 105498,
      "vote_1_percent": 0,
      "vote_2_percent": 44,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "64",
      "name": "KALIMANTAN TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1093148,
      "vote_2": 870043,
      "vote_1_percent": 1,
      "vote_2_percent": 21,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "53",
      "name": "NUSA TENGGARA TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2362041,
      "vote_2": 305615,
      "vote_1_percent": 9,
      "vote_2_percent": 22,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "51",
      "name": "B A L I",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2342628,
      "vote_2": 212586,
      "vote_1_percent": 1,
      "vote_2_percent": 35,
      "total_1_fav": "73/14",
      "total_2_fav": "69/25"
    },
    {
      "id": "11",
      "name": "NANGGROE ACEH DARUSSALAM",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 397251,
      "vote_2": 2352397,
      "vote_1_percent": 6,
      "vote_2_percent": 18,
      "total_1_fav": "63/20",
      "total_2_fav": "80/15"
    },
    {
      "id": "81",
      "name": "MALUKU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 561012,
      "vote_2": 388069,
      "vote_1_percent": 2,
      "vote_2_percent": 35,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "62",
      "name": "KALIMANTAN TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 828596,
      "vote_2": 536213,
      "vote_1_percent": 10,
      "vote_2_percent": 16,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "74",
      "name": "SULAWESI TENGGARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 554470,
      "vote_2": 840465,
      "vote_1_percent": 16,
      "vote_2_percent": 23,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "15",
      "name": "J A M B I",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 858738,
      "vote_2": 1200255,
      "vote_1_percent": 0,
      "vote_2_percent": 20,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "19",
      "name": "BANGKA BELITUNG",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 495500,
      "vote_2": 288097,
      "vote_1_percent": 0,
      "vote_2_percent": 39,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "76",
      "name": "SULAWESI BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 474852,
      "vote_2": 263345,
      "vote_1_percent": 0,
      "vote_2_percent": 28,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "63",
      "name": "KALIMANTAN SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 821284,
      "vote_2": 1463514,
      "vote_1_percent": 0,
      "vote_2_percent": 40,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "75",
      "name": "GORONTALO",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 369338,
      "vote_2": 344798,
      "vote_1_percent": 10,
      "vote_2_percent": 13,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "13",
      "name": "SUMATERA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 407638,
      "vote_2": 2485265,
      "vote_1_percent": 7,
      "vote_2_percent": 29,
      "total_1_fav": "60/15",
      "total_2_fav": "80/12"
    },
    {
      "id": "71",
      "name": "SULAWESI UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1218303,
      "vote_2": 359131,
      "vote_1_percent": 8,
      "vote_2_percent": 36,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "82",
      "name": "MALUKU UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 308197,
      "vote_2": 341711,
      "vote_1_percent": 18,
      "vote_2_percent": 18,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "72",
      "name": "SULAWESI TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 913753,
      "vote_2": 705310,
      "vote_1_percent": 0,
      "vote_2_percent": 37,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "34",
      "name": "DI YOGYAKARTA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1640789,
      "vote_2": 735789,
      "vote_1_percent": 1,
      "vote_2_percent": 17,
      "total_1_fav": "62/21",
      "total_2_fav": "53/30"
    },
    {
      "id": "16",
      "name": "SUMATERA SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1915488,
      "vote_2": 2830326,
      "vote_1_percent": 7,
      "vote_2_percent": 8,
      "total_1_fav": "60/15",
      "total_2_fav": "80/12"
    },
    {
      "id": "73",
      "name": "SULAWESI SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2117839,
      "vote_2": 2807109,
      "vote_1_percent": 3,
      "vote_2_percent": 20,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "17",
      "name": "BENGKULU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 582845,
      "vote_2": 585598,
      "vote_1_percent": 3,
      "vote_2_percent": 19,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "21",
      "name": "KEPULAUAN RIAU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 546373,
      "vote_2": 460957,
      "vote_1_percent": 1,
      "vote_2_percent": 15,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "52",
      "name": "NUSA TENGGARA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 950480,
      "vote_2": 2009305,
      "vote_1_percent": 0,
      "vote_2_percent": 31,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "94",
      "name": "PAPUA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2125922,
      "vote_2": 184552,
      "vote_1_percent": 8,
      "vote_2_percent": 32,
      "total_1_fav": "73/14",
      "total_2_fav": "69/25"
    }
  ]
};

const horseraceWave2 = {
  "location_id": "-1",
  "location_name": "National",
  "total_vote_1": 83664216,
  "total_vote_2": 67973884,
  "total_vote_1_percent": 6,
  "total_vote_2_percent": 16,
  "total_1_fav_national": "66/18",
  "total_2_fav_national": "78/13",
  "data": [
    {
      "id": "32",
      "name": "JAWA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 10718874,
      "vote_2": 16029115,
      "vote_1_percent": 8,
      "vote_2_percent": 20,
      "total_1_fav": "66/15",
      "total_2_fav": "83/11"
    },
    {
      "id": "33",
      "name": "JAWA TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 16784716,
      "vote_2": 4939445,
      "vote_1_percent": 5,
      "vote_2_percent": 26,
      "total_1_fav": "65/20",
      "total_2_fav": "75/13"
    },
    {
      "id": "35",
      "name": "JAWA TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 16145259,
      "vote_2": 8392506,
      "vote_1_percent": 4,
      "vote_2_percent": 25,
      "total_1_fav": "62/21",
      "total_2_fav": "53/30"
    },
    {
      "id": "36",
      "name": "BANTEN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2530608,
      "vote_2": 4045338,
      "vote_1_percent": 2,
      "vote_2_percent": 15,
      "total_1_fav": "63/20",
      "total_2_fav": "71/23"
    },
    {
      "id": "12",
      "name": "SUMATERA UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 3886014,
      "vote_2": 3553576,
      "vote_1_percent": 6,
      "vote_2_percent": 18,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "31",
      "name": "DKI JAKARTA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 3269971,
      "vote_2": 3057851,
      "vote_1_percent": 9,
      "vote_2_percent": 29,
      "total_1_fav": "63/20",
      "total_2_fav": "67/27"
    },
    {
      "id": "61",
      "name": "KALIMANTAN BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1707167,
      "vote_2": 1260628,
      "vote_1_percent": 10,
      "vote_2_percent": 21,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "18",
      "name": "LAMPUNG",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2845798,
      "vote_2": 1951645,
      "vote_1_percent": 9,
      "vote_2_percent": 15,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "14",
      "name": "RIAU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1246888,
      "vote_2": 1973298,
      "vote_1_percent": 11,
      "vote_2_percent": 11,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "91",
      "name": "PAPUA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 395084,
      "vote_2": 94534,
      "vote_1_percent": 7,
      "vote_2_percent": 34,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "65",
      "name": "KALIMANTAN UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 247352,
      "vote_2": 105498,
      "vote_1_percent": 0,
      "vote_2_percent": 24,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "64",
      "name": "KALIMANTAN TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1093148,
      "vote_2": 870043,
      "vote_1_percent": 1,
      "vote_2_percent": 21,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "53",
      "name": "NUSA TENGGARA TIMUR",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2362041,
      "vote_2": 305615,
      "vote_1_percent": 12,
      "vote_2_percent": 22,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "51",
      "name": "B A L I",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2342628,
      "vote_2": 212586,
      "vote_1_percent": 6,
      "vote_2_percent": 4,
      "total_1_fav": "73/14",
      "total_2_fav": "69/25"
    },
    {
      "id": "11",
      "name": "NANGGROE ACEH DARUSSALAM",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 397251,
      "vote_2": 2352397,
      "vote_1_percent": 6,
      "vote_2_percent": 18,
      "total_1_fav": "63/20",
      "total_2_fav": "80/15"
    },
    {
      "id": "81",
      "name": "MALUKU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 561012,
      "vote_2": 388069,
      "vote_1_percent": 2,
      "vote_2_percent": 35,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "62",
      "name": "KALIMANTAN TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 828596,
      "vote_2": 536213,
      "vote_1_percent": 0,
      "vote_2_percent": 12,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "74",
      "name": "SULAWESI TENGGARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 554470,
      "vote_2": 840465,
      "vote_1_percent": 16,
      "vote_2_percent": 23,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "15",
      "name": "J A M B I",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 858738,
      "vote_2": 1200255,
      "vote_1_percent": 0,
      "vote_2_percent": 20,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "19",
      "name": "BANGKA BELITUNG",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 495500,
      "vote_2": 288097,
      "vote_1_percent": 0,
      "vote_2_percent": 14,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "76",
      "name": "SULAWESI BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 474852,
      "vote_2": 263345,
      "vote_1_percent": 0,
      "vote_2_percent": 28,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "63",
      "name": "KALIMANTAN SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 821284,
      "vote_2": 1463514,
      "vote_1_percent": 0,
      "vote_2_percent": 40,
      "total_1_fav": "59/18",
      "total_2_fav": "79/10"
    },
    {
      "id": "75",
      "name": "GORONTALO",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 369338,
      "vote_2": 344798,
      "vote_1_percent": 10,
      "vote_2_percent": 13,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "13",
      "name": "SUMATERA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 407638,
      "vote_2": 2485265,
      "vote_1_percent": 7,
      "vote_2_percent": 29,
      "total_1_fav": "60/15",
      "total_2_fav": "80/12"
    },
    {
      "id": "71",
      "name": "SULAWESI UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1218303,
      "vote_2": 359131,
      "vote_1_percent": 8,
      "vote_2_percent": 36,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "82",
      "name": "MALUKU UTARA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 308197,
      "vote_2": 341711,
      "vote_1_percent": 18,
      "vote_2_percent": 18,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "72",
      "name": "SULAWESI TENGAH",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 913753,
      "vote_2": 705310,
      "vote_1_percent": 0,
      "vote_2_percent": 37,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "34",
      "name": "DI YOGYAKARTA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1640789,
      "vote_2": 735789,
      "vote_1_percent": 12,
      "vote_2_percent": 1,
      "total_1_fav": "62/21",
      "total_2_fav": "53/30"
    },
    {
      "id": "16",
      "name": "SUMATERA SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 1915488,
      "vote_2": 2830326,
      "vote_1_percent": 7,
      "vote_2_percent": 8,
      "total_1_fav": "60/15",
      "total_2_fav": "80/12"
    },
    {
      "id": "73",
      "name": "SULAWESI SELATAN",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2117839,
      "vote_2": 2807109,
      "vote_1_percent": 3,
      "vote_2_percent": 20,
      "total_1_fav": "70/14",
      "total_2_fav": "80/15"
    },
    {
      "id": "17",
      "name": "BENGKULU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 582845,
      "vote_2": 585598,
      "vote_1_percent": 3,
      "vote_2_percent": 19,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "21",
      "name": "KEPULAUAN RIAU",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 546373,
      "vote_2": 460957,
      "vote_1_percent": 21,
      "vote_2_percent": 11,
      "total_1_fav": "74/15",
      "total_2_fav": "72/24"
    },
    {
      "id": "52",
      "name": "NUSA TENGGARA BARAT",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 950480,
      "vote_2": 2009305,
      "vote_1_percent": 0,
      "vote_2_percent": 31,
      "total_1_fav": "73/14",
      "total_2_fav": "73/23"
    },
    {
      "id": "94",
      "name": "PAPUA",
      "color_1": "#932427",
      "color_2": "#1f496f",
      "vote_1": 2125922,
      "vote_2": 184552,
      "vote_1_percent": 8,
      "vote_2_percent": 32,
      "total_1_fav": "73/14",
      "total_2_fav": "69/25"
    }
  ]
};


export {
  horseraceWave2,
  horseraceWave3,
  horseraceWave4,
  horseraceWave5
}