import React, { Fragment, Component } from "react";

import Sidebar from "components/layout/sidebar/Sidebar";
import Map from "components/shared/map/Map";
import ModalDialog from "components/shared/ModalDialog";
import { Dark } from "components/shared/charts/themeVictory";
import SubmenuRegion from "components/layout/sidebar/SubmenuRegion";
import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";
import CustomLabel from "components/shared/charts/CustomLabel";
import GroupedBarTurnout from "components/shared/charts/GroupedBarTurnout";

import MapBottom from "components/shared/map/MapBottom";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { setModalTopic } from "actions";
import "styles/components/whatson_et.css";

import { getTurnout } from "actions/mapActions";

import GoogleTrends from "./GoogleTrends";

import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap,
  getProvince
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import "styles/components/map-submenu.css";
import MapTurnout from "components/shared/map/MapTurnout";


const API_HOST = process.env.REACT_APP_API_HOST;

const dataArticleTime = [
  {
    "name": "Mar-21",
    "Erick Thohir": 630,
    "Ridwan Kamil": 870,
    "Ganjar Pranowo": 224,
    "Sandiaga Uno": 379,
    "Anies Baswedan": 843
  },
  {
    "name": "Apr-21",
    "Erick Thohir": 639,
    "Ridwan Kamil": 1091,
    "Ganjar Pranowo": 259,
    "Sandiaga Uno": 433,
    "Anies Baswedan": 913
  },
  {
    "name": "May-21",
    "Erick Thohir": 701,
    "Ridwan Kamil": 890,
    "Ganjar Pranowo": 1107,
    "Sandiaga Uno": 424,
    "Anies Baswedan": 980
  },
  {
    "name": "Jun-21",
    "Erick Thohir": 1206,
    "Ridwan Kamil": 1394,
    "Ganjar Pranowo": 668,
    "Sandiaga Uno": 526,
    "Anies Baswedan": 1070
  },
  {
    "name": "Jul-21",
    "Erick Thohir": 1179,
    "Ridwan Kamil": 1165,
    "Ganjar Pranowo": 353,
    "Sandiaga Uno": 495,
    "Anies Baswedan": 1109
  },
  {
    "name": "Aug-21",
    "Erick Thohir": 1032,
    "Ridwan Kamil": 775,
    "Ganjar Pranowo": 639,
    "Sandiaga Uno": 525,
    "Anies Baswedan": 1014
  },
  {
    "name": "Sep-21",
    "Erick Thohir": 1449,
    "Ridwan Kamil": 682,
    "Ganjar Pranowo": 648,
    "Sandiaga Uno": 723,
    "Anies Baswedan": 1817
  },
  {
    "name": "Oct-21",
    "Erick Thohir": 1548,
    "Ridwan Kamil": 1445,
    "Ganjar Pranowo": 1177,
    "Sandiaga Uno": 879,
    "Anies Baswedan": 1648
  },
  {
    "name": "Nov-21",
    "Erick Thohir": 2815,
    "Ridwan Kamil": 1223,
    "Ganjar Pranowo": 1312,
    "Sandiaga Uno": 723,
    "Anies Baswedan": 1935
  },
  {
    "name": "Dec-21",
    "Erick Thohir": 2045,
    "Ridwan Kamil": 2430,
    "Ganjar Pranowo": 1100,
    "Sandiaga Uno": 814,
    "Anies Baswedan": 1802
  },
  {
    "name": "Jan-22",
    "Erick Thohir": 2596,
    "Ridwan Kamil": 2690,
    "Ganjar Pranowo": 1307,
    "Sandiaga Uno": 708,
    "Anies Baswedan": 1904
  },
  {
    "name": "Feb-22",
    "Erick Thohir": 1770,
    "Ridwan Kamil": 2180,
    "Ganjar Pranowo": 1955,
    "Sandiaga Uno": 693,
    "Anies Baswedan": 1771
  },
  {
    "name": "Mar-22",
    "Erick Thohir": 1336,
    "Ridwan Kamil": 1034,
    "Ganjar Pranowo": 726,
    "Sandiaga Uno": 732,
    "Anies Baswedan": 989
  }
];

const dataEngagementTime = [
  {
    "name": "Mar-21",
    "Erick Thohir": 199516,
    "Ridwan Kamil": 60292,
    "Ganjar Pranowo": 14234,
    "Sandiaga Uno": 14507,
    "Anies Baswedan": 174554
  },
  {
    "name": "Apr-21",
    "Erick Thohir": 138627,
    "Ridwan Kamil": 55870,
    "Ganjar Pranowo": 37998,
    "Sandiaga Uno": 50655,
    "Anies Baswedan": 193560
  },
  {
    "name": "May-21",
    "Erick Thohir": 158989,
    "Ridwan Kamil": 35285,
    "Ganjar Pranowo": 120994,
    "Sandiaga Uno": 10903,
    "Anies Baswedan": 172163
  },
  {
    "name": "Jun-21",
    "Erick Thohir": 111148,
    "Ridwan Kamil": 59726,
    "Ganjar Pranowo": 88071,
    "Sandiaga Uno": 10177,
    "Anies Baswedan": 87108
  },
  {
    "name": "Jul-21",
    "Erick Thohir": 118330,
    "Ridwan Kamil": 88855,
    "Ganjar Pranowo": 34494,
    "Sandiaga Uno": 35150,
    "Anies Baswedan": 162744
  },
  {
    "name": "Aug-21",
    "Erick Thohir": 100589,
    "Ridwan Kamil": 9979,
    "Ganjar Pranowo": 32615,
    "Sandiaga Uno": 9107,
    "Anies Baswedan": 80025
  },
  {
    "name": "Sep-21",
    "Erick Thohir": 53375,
    "Ridwan Kamil": 21195,
    "Ganjar Pranowo": 30567,
    "Sandiaga Uno": 15010,
    "Anies Baswedan": 231842
  },
  {
    "name": "Oct-21",
    "Erick Thohir": 177023,
    "Ridwan Kamil": 43947,
    "Ganjar Pranowo": 62439,
    "Sandiaga Uno": 17712,
    "Anies Baswedan": 244096
  },
  {
    "name": "Nov-21",
    "Erick Thohir": 386013,
    "Ridwan Kamil": 55558,
    "Ganjar Pranowo": 86409,
    "Sandiaga Uno": 27214,
    "Anies Baswedan": 362639
  },
  {
    "name": "Dec-21",
    "Erick Thohir": 174135,
    "Ridwan Kamil": 75207,
    "Ganjar Pranowo": 144520,
    "Sandiaga Uno": 18392,
    "Anies Baswedan": 249952
  },
  {
    "name": "Jan-22",
    "Erick Thohir": 183636,
    "Ridwan Kamil": 91615,
    "Ganjar Pranowo": 118792,
    "Sandiaga Uno": 11363,
    "Anies Baswedan": 192081
  },
  {
    "name": "Feb-22",
    "Erick Thohir": 198427,
    "Ridwan Kamil": 64437,
    "Ganjar Pranowo": 124922,
    "Sandiaga Uno": 15386,
    "Anies Baswedan": 144807
  },
  {
    "name": "Mar-22",
    "Erick Thohir": 118263,
    "Ridwan Kamil": 22740,
    "Ganjar Pranowo": 8927,
    "Sandiaga Uno": 17459,
    "Anies Baswedan": 27629
  }
];


class WhatsonNew extends Component {
  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "News Media",
        map_type: "horserace",
        data: [
          { id: 1, name: "News Media" },
          { id: 2, name: "Twitter" },
          { id: 3, name: "Facebook" }
        ]
      }
    },
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null,

    openSubOne: {
      active: 1,
      opened: false
    },
    openSubTwo: {
      active: 1,
      opened: false
    },
    isLoadingOne: true,
    isLoadingTwo: true
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleHashtagClick = text => {
    this.props.setModalTopic({
      type: "hashtag",
      // path: `whatson/wordcloud/data`,
      path: `whatson/${"sentimen"}/data`,
      params: { keyword: text }
    });
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "News Media" },
            { id: 2, name: "Twitter" },
            { id: 3, name: "Facebook" }
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
    // await GET(API_HOST + "/sental/master/pulau")
    //   .then(res =>
    //     this.setState({
    //       ...this.state,
    //       region: {
    //         ...this.state.region,
    //         pulau: { ...this.state.region.pulau, data: res.data },
    //         step: 1
    //       }
    //     })
    //   )
    //   .catch(err => {
    //     err.response.status === 401
    //       ? this.props.reAuth()
    //       : toastr.error(err.response.statusText);
    //   });
  }

  render() {
    const { info, state, handleToggleSatellite } = this.props;
    const {
      openMap,
      isSubOpen,
      openPOI,
      isPOISubOpen,
      region,
      turnout,

      openSubOne,
      isLoadingOne
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition,
      handleGetSubMenu,
      handleChangeMethod,
      handleHashtagClick
    } = this;


    return (
      <React.Fragment>
        {/* <div className="full-width" style={{background: '#212529'}}>
            <div className="col-lg-12">
              <div className="sidebar sidebar-left">
                <div className="sidebar-content">
                  <nav className="main-menu">
                    <ul className="nav">
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="icon dripicons-graph-bar" />
                        </a>
                        <SubmenuTurnout
                          handleToggleInfo={handleToggleInfo}
                          handleGetSubMenu={handleGetSubMenu}
                          handleToggleSubMenu={handleTogglePOISubMenu}
                          handleCloseSubmenu={handleCloseSubmenu}
                          handleChangeMethod={handleChangeMethod}
                          placeholder="Select Scoring"
                          data={{
                            info,
                            openPOI,
                            isPOISubOpen,
                            handleSubMenuChange,
                            turnout
                          }}
                        />
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
        </div> */}
        <div className="row" style={{borderBottom: '3px solid #932427', background: '#212529'}}>
          <div className="col-lg-6">
            <div className="row">
              <h2 style={{ marginLeft: "40px", marginTop:"25px",marginBottom:"2px", fontWeight: 'bolder',
                  fontSize:'2.1em',  justifyContent:'center', alignItems:'center', color:'white'  }}>NEWS COVERAGE</h2>
            </div>
            <div className="row">
              <h2 style={{ marginLeft: "40px", marginTop:"2px",marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.8em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Article Published YTD(monthly)</h2>
            </div>
            <ResponsiveContainer width="100%" height="35%">
              <LineChart
                width={500}
                height={300}
                data={dataArticleTime}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="name" stroke="white" />
                <YAxis stroke="white"/>
                <Tooltip />
                <Legend />
                <Line dataKey="Erick Thohir" strokeWidth={4} stroke="#427bff" activeDot={{ r: 8 }} />
                <Line dataKey="Ridwan Kamil" strokeWidth={4} stroke="red" />
                <Line dataKey="Ganjar Pranowo" strokeWidth={4} stroke="orange" />
                <Line dataKey="Sandiaga Uno" strokeWidth={4} stroke="green" />
                <Line dataKey="Anies Baswedan" strokeWidth={4} stroke="#d400f0" />
              </LineChart>
            </ResponsiveContainer>
            <div className="row">
              <h2 style={{ marginLeft: "40px", marginTop:"102px",marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.8em',  justifyContent:'center', alignItems:'center', color:'white'  }}>Article Engagement Over Time</h2>
            </div>
            <ResponsiveContainer width="100%" height="35%">
              <LineChart
                width={500}
                height={300}
                data={dataEngagementTime}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="name" stroke="white" />
                <YAxis stroke="white"/>
                <Tooltip />
                <Legend />
                <Line dataKey="Erick Thohir" strokeWidth={4} stroke="#427bff" activeDot={{ r: 8 }} />
                <Line dataKey="Ridwan Kamil" strokeWidth={4} stroke="red" />
                <Line dataKey="Ganjar Pranowo" strokeWidth={4} stroke="orange" />
                <Line dataKey="Sandiaga Uno" strokeWidth={4} stroke="green" />
                <Line dataKey="Anies Baswedan" strokeWidth={4} stroke="#d400f0" />
              </LineChart>
            </ResponsiveContainer>
          </div>      
          <div className="col-lg-6">
            <div className="row">
              <h2 style={{ marginLeft: "20px", marginTop:"25px",marginBottom:"2px", fontWeight: 'bolder',
                  fontSize:'2.1em',  justifyContent:'center', alignItems:'center', color:'white'  }}>WHO'S TRENDING</h2>
            </div>
            <div className="row">
              <h2 style={{ marginLeft: "20px", marginTop:"2px",marginBottom:"10px", fontWeight: 'bolder',
                  fontSize:'1.8em',  justifyContent:'center', alignItems:'center', color:'red'  }}>SEARCH INTEREST(Last 30days)</h2>
            </div>
            <div id="widget">
              <GoogleTrends
                type="TIMESERIES"
                url="https://ssl.gstatic.com/trends_nrtr/2578_RC01/embed_loader.js"
              />
              <GoogleTrends
                type="GEO_MAP"
                url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
              />
            </div>
          </div>   
          
        </div>
        {/* <div className="map50-bottom-soe">
          <MapBottom source="turnout" type="overview" handleBorderMapClick={()=>{}} />
        </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout,
  setModalTopic
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatsonNew);
