import React, { Component } from 'react';
import { VictoryTooltip } from 'victory';

export default class CustomLabelOverview extends Component {
  static defaultEvents = VictoryTooltip.defaultEvents

  render() {
    const { text, height } = this.props
    return (
      <g>
        <VictoryTooltip
          {...this.props}
          text={text}
          orientation="top"
          pointerLength={5}
          height={height ? height : 30}
          // centerOffset={{ x: 25 }} 
          // flyoutWidth={30}
          active={true}
          flyoutStyle={{
            fill: 'rgba(255, 255, 255, .7)'
          }}
          flyoutWidth={10}
          style={{fill: this.props.color ? this.props.color : '#000'}}
        />
      </g>
    )
  }
}