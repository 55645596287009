import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography
} from "react-simple-maps";
import { setHoverKab, clearHoverKab } from "actions/mapActions";


class BorderKab extends Component {

  render() {
    const colors = ['#333','#444', '#555', '#666', '#777', '#888', '#999', '#aaa'];
    const { modal, data } = this.props;
    let j = 0;
    if (modal.length === 0) {
      return <span>No Map Data</span>;
    } else {
      return (
        <div onMouseLeave={ ()=> this.props.clearHoverKab() } >
          <ComposableMap
            projection="mercator"
            // projectionConfig: case untuk DKI JAKARTA, BALI, DIY harus diperbesar
            projectionConfig={{ scale: modal.scale * 1.5 }}
            style={{
              width: "100%",
              height: "350px"
            }}
          >
            {/* Center Long Lat didapat dari component Poly.js */}
            <ZoomableGroup
              center={[modal.position.lng, modal.position.lat]}
              disablePanning
            >
              <Geographies disableOptimization={true} geography={`${process.env.PUBLIC_URL}/ind_kab_v2.json`}>
                {(geographies, projection) =>
                  geographies.map((geography, i) => {
                    let color = '';
                    let colorapi = '';
                    j = j < colors.length - 1 ? j + 1 : 0
                    
                    data.map((data,i) => {  
                      if (data.id == geography.properties.KODE_KAB){

                        return colorapi = data.color
                      }
                    })
                    
                    color = data.length > 0 ? colorapi : colors[j]


                    return (
                      // 51 adalah kode prop yang didapat dari component Poly.js
                      geography.properties.KODE_KAB.substring(0, 2) ===
                        `${modal.kode_prop}` && (
                        <Geography
                          key={i}
                          geography={geography}
                          projection={projection}
                          onMouseEnter={ () => this.props.setHoverKab(geography.properties.KODE_KAB)}
                          //onMouseLeave={ () => this.props.clearHoverKab() }
                          style={{
                            default: {
                              fill: `${ color }`,
                              stroke: "#ccc",
                              strokeWidth: 1,
                              outline: "none"
                            },
                            hover: {
                              fill: "#607D8B",
                              stroke: "#ddd",
                              strokeWidth: 2,
                              outline: "none"
                            },
                            pressed: {
                              fill: "#FF5722",
                              stroke: "#ddd",
                              strokeWidth: 3,
                              outline: "none"
                            }
                          }}
                        />
                      )
                    );
                  })
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
        </div>
      );
    }
  }
}
const mapStateToProps = state => ({
  modal: state.modal.data
});

const mapDispatchToProps = {
  setHoverKab,
  clearHoverKab
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BorderKab);
