import { createBrowserHistory } from 'history'

export * from 'helpers/object.js';

export var history = createBrowserHistory();

const permissionValue = {
  read: 0,
  write: 1,
  admin: 2
}

export function checkPermission(permission, target) {
  return permissionValue[permission] >= permissionValue[target];
}

export function checkResourcePermission(resource={}, target={}) {
  return target.path.indexOf(resource.path) > -1 && checkPermission(resource.permission, target.permission);
}

export function searchToObject() {

  var pairs = window.location.search.substring(1).split("&"),
  obj = {},
  pair,
  i;

  for ( i in pairs ) {
    if ( pairs[i] === "" ) continue;

    pair = pairs[i].split("=");
    obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
  }

  return obj;
}

export function cropImage(image, pixelCrop) {
  let canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  let ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  let croppedImage = new Image();
  croppedImage.src = canvas.toDataURL('image/jpeg');
  return croppedImage;
}

export function resizeImage(image, maxWidth, maxHeight) {
  let MAX_WIDTH = maxWidth;
  let MAX_HEIGHT = maxHeight;
  let width = image.width;
  let height = image.height;
  
  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }

  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(image, 0, 0, width, height);

  let resizedImage = new Image();
  resizedImage.src = canvas.toDataURL('image/jpeg');
  return resizedImage;
}

export function optimizeImage(image, scale) {
  if (!(scale < 1) || !(scale > 0)) throw ('scale must be a positive number <1 ');

  let cv = document.createElement('canvas');
  let ctx = cv.getContext('2d');

  cv.width = image.width;
  cv.height = image.height;
  ctx.drawImage(image, 0, 0, image.width, image.height);

  var sqScale = scale * scale; // square scale = area of source pixel within target
  var sw = cv.width; // source image width
  var sh = cv.height; // source image height
  var tw = Math.ceil(sw * scale); // target image width
  var th = Math.ceil(sh * scale); // target image height
  var sx = 0, sy = 0, sIndex = 0; // source x,y, index within source array
  var tx = 0, ty = 0, yIndex = 0, tIndex = 0; // target x,y, x,y index within target array
  var tX = 0, tY = 0; // rounded tx, ty
  var w = 0, nw = 0, wx = 0, nwx = 0, wy = 0, nwy = 0; // weight / next weight x / y
  // weight is weight of current source point within target.
  // next weight is weight of current source point within next target's point.
  var crossX = false; // does scaled px cross its current px right border ?
  var crossY = false; // does scaled px cross its current px bottom border ?
  var sBuffer = cv.getContext('2d').
  getImageData(0, 0, sw, sh).data; // source buffer 8 bit rgba
  var tBuffer = new Float32Array(4 * sw * sh); // target buffer Float32 rgb
  var sR = 0, sG = 0,  sB = 0; // source's current point r,g,b
  // untested !
  var sA = 0;  //source alpha    

  for (sy = 0; sy < sh; sy++) {
      ty = sy * scale; // y src position within target
      tY = 0 | ty;     // rounded : target pixel's y
      yIndex = 4 * tY * tw;  // line index within target array
      crossY = (tY != (0 | ty + scale)); 
      if (crossY) { // if pixel is crossing botton target pixel
          wy = (tY + 1 - ty); // weight of point within target pixel
          nwy = (ty + scale - tY - 1); // ... within y+1 target pixel
      }
      for (sx = 0; sx < sw; sx++, sIndex += 4) {
          tx = sx * scale; // x src position within target
          tX = 0 |  tx;    // rounded : target pixel's x
          tIndex = yIndex + tX * 4; // target pixel index within target array
          crossX = (tX != (0 | tx + scale));
          if (crossX) { // if pixel is crossing target pixel's right
              wx = (tX + 1 - tx); // weight of point within target pixel
              nwx = (tx + scale - tX - 1); // ... within x+1 target pixel
          }
          sR = sBuffer[sIndex    ];   // retrieving r,g,b for curr src px.
          sG = sBuffer[sIndex + 1];
          sB = sBuffer[sIndex + 2];
          sA = sBuffer[sIndex + 3];
          
          if (!crossX && !crossY) { // pixel does not cross
              // just add components weighted by squared scale.
              tBuffer[tIndex    ] += sR * sqScale;
              tBuffer[tIndex + 1] += sG * sqScale;
              tBuffer[tIndex + 2] += sB * sqScale;
              tBuffer[tIndex + 3] += sA * sqScale;
          } else if (crossX && !crossY) { // cross on X only
              w = wx * scale;
              // add weighted component for current px
              tBuffer[tIndex    ] += sR * w;
              tBuffer[tIndex + 1] += sG * w;
              tBuffer[tIndex + 2] += sB * w;
              tBuffer[tIndex + 3] += sA * w;
              // add weighted component for next (tX+1) px                
              nw = nwx * scale
              tBuffer[tIndex + 4] += sR * nw; // not 3
              tBuffer[tIndex + 5] += sG * nw; // not 4
              tBuffer[tIndex + 6] += sB * nw; // not 5
              tBuffer[tIndex + 7] += sA * nw; // not 6
          } else if (crossY && !crossX) { // cross on Y only
              w = wy * scale;
              // add weighted component for current px
              tBuffer[tIndex    ] += sR * w;
              tBuffer[tIndex + 1] += sG * w;
              tBuffer[tIndex + 2] += sB * w;
              tBuffer[tIndex + 3] += sA * w;
              // add weighted component for next (tY+1) px                
              nw = nwy * scale
              tBuffer[tIndex + 4 * tw    ] += sR * nw; // *4, not 3
              tBuffer[tIndex + 4 * tw + 1] += sG * nw; // *4, not 3
              tBuffer[tIndex + 4 * tw + 2] += sB * nw; // *4, not 3
              tBuffer[tIndex + 4 * tw + 3] += sA * nw; // *4, not 3
          } else { // crosses both x and y : four target points involved
              // add weighted component for current px
              w = wx * wy;
              tBuffer[tIndex    ] += sR * w;
              tBuffer[tIndex + 1] += sG * w;
              tBuffer[tIndex + 2] += sB * w;
              tBuffer[tIndex + 3] += sA * w;
              // for tX + 1; tY px
              nw = nwx * wy;
              tBuffer[tIndex + 4] += sR * nw; // same for x
              tBuffer[tIndex + 5] += sG * nw;
              tBuffer[tIndex + 6] += sB * nw;
              tBuffer[tIndex + 7] += sA * nw;
              // for tX ; tY + 1 px
              nw = wx * nwy;
              tBuffer[tIndex + 4 * tw    ] += sR * nw; // same for mul
              tBuffer[tIndex + 4 * tw + 1] += sG * nw;
              tBuffer[tIndex + 4 * tw + 2] += sB * nw;
              tBuffer[tIndex + 4 * tw + 3] += sA * nw;
              // for tX + 1 ; tY +1 px
              nw = nwx * nwy;
              tBuffer[tIndex + 4 * tw + 4] += sR * nw; // same for both x and y
              tBuffer[tIndex + 4 * tw + 5] += sG * nw;
              tBuffer[tIndex + 4 * tw + 6] += sB * nw;
              tBuffer[tIndex + 4 * tw + 7] += sA * nw;
          }
      } // end for sx 
  } // end for sy

  // create result canvas
  var resCV = document.createElement('canvas');
  resCV.width = tw;
  resCV.height = th;
  var resCtx = resCV.getContext('2d');
  var imgRes = resCtx.getImageData(0, 0, tw, th);
  var tByteBuffer = imgRes.data;
  // convert float32 array into a UInt8Clamped Array
  var pxIndex = 0; //  
  for (sIndex = 0, tIndex = 0; pxIndex < tw * th; sIndex += 4, tIndex += 4, pxIndex++) {
      tByteBuffer[tIndex] = Math.ceil(tBuffer[sIndex]);
      tByteBuffer[tIndex + 1] = Math.ceil(tBuffer[sIndex + 1]);
      tByteBuffer[tIndex + 2] = Math.ceil(tBuffer[sIndex + 2]);
      tByteBuffer[tIndex + 3] = Math.ceil(tBuffer[sIndex + 3]);
  }
  // writing result to canvas.
  resCtx.putImageData(imgRes, 0, 0);
  var optimizedImage = new Image();
  optimizedImage.src = resCV.toDataURL('image/jpeg');

  return optimizedImage;
}

export function toDataURL (url) {
  return fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }));
}

export function filterObjectByAllowed(object, allowed){
  return Object.keys(object).filter(key => allowed.includes(key)).reduce((obj, key) => {
    obj[key] = object[key];
    return obj;
  }, {});
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

export const deleteCookie = (name) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}