export const horserace = [
  {
    x: "p10",
    y: 16183632
  },
  {
    x: "p20",
    y: 19840600
  },
  {
    x: "p30",
    y: 18379744
  },
  {
    x: "p40",
    y: 18200372
  },
  {
    x: "p50",
    y: 19312837
  },
  {
    x: "p60",
    y: 15926941
  },
  {
    x: "p70",
    y: 11369023
  },
  {
    x: "p80",
    y: 11330260
  },
  {
    x: "p90",
    y: 19002976
  },
  {
    x: "p100",
    y: 22254682
  }
];

export const reelect = [
  {
    x: "p10",
    y: 17137141
  },
  {
    x: "p20",
    y: 17186871
  },
  {
    x: "p30",
    y: 17163840
  },
  {
    x: "p40",
    y: 17121506
  },
  {
    x: "p50",
    y: 17265356
  },
  {
    x: "p60",
    y: 17176710
  },
  {
    x: "p70",
    y: 17157058
  },
  {
    x: "p80",
    y: 17203192
  },
  {
    x: "p90",
    y: 17184807
  },
  {
    x: "p100",
    y: 17204586
  }
];

export const undecided_a = [
  {
    x: "p10",
    y: 17310088
  },
  {
    x: "p20",
    y: 17314371
  },
  {
    x: "p30",
    y: 17351041
  },
  {
    x: "p40",
    y: 17334888
  },
  {
    x: "p50",
    y: 17462811
  },
  {
    x: "p60",
    y: 17264450
  },
  {
    x: "p70",
    y: 17323096
  },
  {
    x: "p80",
    y: 17420466
  },
  {
    x: "p90",
    y: 17406340
  },
  {
    x: "p100",
    y: 17343555
  }
];

export const undecided_b = [
  {
    x: "p10",
    y: 17272818
  },
  {
    x: "p20",
    y: 17192125
  },
  {
    x: "p30",
    y: 17294994
  },
  {
    x: "p40",
    y: 17192130
  },
  {
    x: "p50",
    y: 17241617
  },
  {
    x: "p60",
    y: 17264493
  },
  {
    x: "p70",
    y: 17270014
  },
  {
    x: "p80",
    y: 17229993
  },
  {
    x: "p90",
    y: 17249526
  },
  {
    x: "p100",
    y: 17274092
  }
];

export const turnout_binary = [
  {
    x: "p10",
    y: 18954934
  },
  {
    x: "p20",
    y: 18995834
  },
  {
    x: "p30",
    y: 18928087
  },
  {
    x: "p40",
    y: 18983999
  },
  {
    x: "p50",
    y: 19035781
  },
  {
    x: "p60",
    y: 19044609
  },
  {
    x: "p70",
    y: 18844769
  },
  {
    x: "p80",
    y: 18973084
  },
  {
    x: "p90",
    y: 19136180
  },
  {
    x: "p100",
    y: 19061701
  }
];

export const turnout_continuous = [
  {
    x: "p10",
    y: 17283460
  },
  {
    x: "p20",
    y: 17374482
  },
  {
    x: "p30",
    y: 17365647
  },
  {
    x: "p40",
    y: 17353961
  },
  {
    x: "p50",
    y: 17381179
  },
  {
    x: "p60",
    y: 17350837
  },
  {
    x: "p70",
    y: 17324936
  },
  {
    x: "p80",
    y: 17374577
  },
  {
    x: "p90",
    y: 17358317
  },
  {
    x: "p100",
    y: 17363651
  }
];

// new dummy data mahaka 2020
export const turnout_intent_get_vaccinated = {
    "max_value": 8319701,
    "total_value": 31849700,
    "data": [
      {
        "order_id": 1,
        "order_name": "sd",
        "left_label": "Laki-Laki",
        "right_label": "Perempuan",
        "left_data": 8319701,
        "right_data": 8319701
      },
      {
        "order_id": 2,
        "order_name": "smp",
        "left_label": "Laki-Laki",
        "right_label": "Perempuan",
        "left_data": 3092955,
        "right_data": 3092955
      },
      {
        "order_id": 3,
        "order_name": "sma",
        "left_label": "Laki-Laki",
        "right_label": "Perempuan",
        "left_data": 3492117,
        "right_data": 3492117
      },
      {
        "order_id": 4,
        "order_name": "sarjana",
        "left_label": "Laki-Laki",
        "right_label": "Perempuan",
        "left_data": 1020077,
        "right_data": 1020077
      }
    ]
}
