import React, { PureComponent } from "react";
import { VictoryChart, VictoryBar, VictoryStack } from "victory";
import CustomLabelCrowd from "./CustomLabelCrowd";
import { Dark } from "./themeVictory";

const dataIssueMay2023 = [
  {
    "kode_prov": 11,
    "x": "Coronavirus, COVID-19",
    "y": 7.14
  },
  {
    "kode_prov": 11,
    "x": "Corruption, nepotism",
    "y": 33.33
  },
  {
    "kode_prov": 11,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 23.81
  },
  {
    "kode_prov": 11,
    "x": "Economic growth",
    "y": 7.14
  },
  {
    "kode_prov": 11,
    "x": "Jobs, unemployment",
    "y": 11.9
  },
  {
    "kode_prov": 11,
    "x": "Poverty",
    "y": 16.67
  },
  {
    "kode_prov": 12,
    "x": "Coronavirus, COVID-19",
    "y": 4.2
  },
  {
    "kode_prov": 12,
    "x": "Corruption, nepotism",
    "y": 38.66
  },
  {
    "kode_prov": 12,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 23.53
  },
  {
    "kode_prov": 12,
    "x": "Economic growth",
    "y": 3.36
  },
  {
    "kode_prov": 12,
    "x": "Economic inequality",
    "y": 1.68
  },
  {
    "kode_prov": 12,
    "x": "Health care",
    "y": 0.84
  },
  {
    "kode_prov": 12,
    "x": "Jobs, unemployment",
    "y": 3.36
  },
  {
    "kode_prov": 12,
    "x": "Poverty",
    "y": 20.17
  },
  {
    "kode_prov": 12,
    "x": "Schools, education",
    "y": 2.52
  },
  {
    "kode_prov": 12,
    "x": "Wages",
    "y": 1.68
  },
  {
    "kode_prov": 13,
    "x": "Coronavirus, COVID-19",
    "y": 2.22
  },
  {
    "kode_prov": 13,
    "x": "Corruption, nepotism",
    "y": 22.22
  },
  {
    "kode_prov": 13,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 28.89
  },
  {
    "kode_prov": 13,
    "x": "Dont know",
    "y": 8.89
  },
  {
    "kode_prov": 13,
    "x": "Economic growth",
    "y": 13.33
  },
  {
    "kode_prov": 13,
    "x": "Jobs, unemployment",
    "y": 11.11
  },
  {
    "kode_prov": 13,
    "x": "Poverty",
    "y": 4.44
  },
  {
    "kode_prov": 13,
    "x": "Schools, education",
    "y": 6.67
  },
  {
    "kode_prov": 13,
    "x": "Wages",
    "y": 2.22
  },
  {
    "kode_prov": 18,
    "x": "Coronavirus, COVID-19",
    "y": 1.35
  },
  {
    "kode_prov": 18,
    "x": "Corruption, nepotism",
    "y": 17.57
  },
  {
    "kode_prov": 18,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 17.57
  },
  {
    "kode_prov": 18,
    "x": "Economic growth",
    "y": 8.11
  },
  {
    "kode_prov": 18,
    "x": "Economic inequality",
    "y": 8.11
  },
  {
    "kode_prov": 18,
    "x": "Jobs, unemployment",
    "y": 20.27
  },
  {
    "kode_prov": 18,
    "x": "Poverty",
    "y": 18.92
  },
  {
    "kode_prov": 18,
    "x": "Schools, education",
    "y": 5.41
  },
  {
    "kode_prov": 18,
    "x": "Wages",
    "y": 2.7
  },
  {
    "kode_prov": 32,
    "x": "Coronavirus, COVID-19",
    "y": 0.55
  },
  {
    "kode_prov": 32,
    "x": "Corruption, nepotism",
    "y": 10.77
  },
  {
    "kode_prov": 32,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 27.62
  },
  {
    "kode_prov": 32,
    "x": "Economic growth",
    "y": 14.64
  },
  {
    "kode_prov": 32,
    "x": "Economic inequality",
    "y": 14.09
  },
  {
    "kode_prov": 32,
    "x": "Health care",
    "y": 1.1
  },
  {
    "kode_prov": 32,
    "x": "Jobs, unemployment",
    "y": 15.47
  },
  {
    "kode_prov": 32,
    "x": "Other\/SPECIFY",
    "y": 0.28
  },
  {
    "kode_prov": 32,
    "x": "Poverty",
    "y": 9.39
  },
  {
    "kode_prov": 32,
    "x": "Schools, education",
    "y": 3.04
  },
  {
    "kode_prov": 32,
    "x": "Wages",
    "y": 3.04
  },
  {
    "kode_prov": 33,
    "x": "Coronavirus, COVID-19",
    "y": 2.21
  },
  {
    "kode_prov": 33,
    "x": "Corruption, nepotism",
    "y": 17.28
  },
  {
    "kode_prov": 33,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 18.38
  },
  {
    "kode_prov": 33,
    "x": "Dont know",
    "y": 1.47
  },
  {
    "kode_prov": 33,
    "x": "Economic growth",
    "y": 12.87
  },
  {
    "kode_prov": 33,
    "x": "Economic inequality",
    "y": 11.4
  },
  {
    "kode_prov": 33,
    "x": "Health care",
    "y": 4.78
  },
  {
    "kode_prov": 33,
    "x": "Jobs, unemployment",
    "y": 10.66
  },
  {
    "kode_prov": 33,
    "x": "Other\/SPECIFY",
    "y": 3.68
  },
  {
    "kode_prov": 33,
    "x": "Poverty",
    "y": 12.87
  },
  {
    "kode_prov": 33,
    "x": "Schools, education",
    "y": 2.57
  },
  {
    "kode_prov": 33,
    "x": "Wages",
    "y": 1.84
  },
  {
    "kode_prov": 34,
    "x": "Coronavirus, COVID-19",
    "y": 3.33
  },
  {
    "kode_prov": 34,
    "x": "Corruption, nepotism",
    "y": 6.67
  },
  {
    "kode_prov": 34,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 10.0
  },
  {
    "kode_prov": 34,
    "x": "Economic growth",
    "y": 16.67
  },
  {
    "kode_prov": 34,
    "x": "Economic inequality",
    "y": 36.67
  },
  {
    "kode_prov": 34,
    "x": "Health care",
    "y": 6.67
  },
  {
    "kode_prov": 34,
    "x": "Jobs, unemployment",
    "y": 10.0
  },
  {
    "kode_prov": 34,
    "x": "Other\/SPECIFY",
    "y": 3.33
  },
  {
    "kode_prov": 34,
    "x": "Poverty",
    "y": 3.33
  },
  {
    "kode_prov": 34,
    "x": "Schools, education",
    "y": 3.33
  },
  {
    "kode_prov": 35,
    "x": "Coronavirus, COVID-19",
    "y": 1.96
  },
  {
    "kode_prov": 35,
    "x": "Corruption, nepotism",
    "y": 17.97
  },
  {
    "kode_prov": 35,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 5.56
  },
  {
    "kode_prov": 35,
    "x": "Dont know",
    "y": 0.65
  },
  {
    "kode_prov": 35,
    "x": "Economic growth",
    "y": 22.88
  },
  {
    "kode_prov": 35,
    "x": "Economic inequality",
    "y": 16.99
  },
  {
    "kode_prov": 35,
    "x": "Health care",
    "y": 0.65
  },
  {
    "kode_prov": 35,
    "x": "Jobs, unemployment",
    "y": 24.18
  },
  {
    "kode_prov": 35,
    "x": "Poverty",
    "y": 6.86
  },
  {
    "kode_prov": 35,
    "x": "Schools, education",
    "y": 2.29
  },
  {
    "kode_prov": 36,
    "x": "Coronavirus, COVID-19",
    "y": 2.91
  },
  {
    "kode_prov": 36,
    "x": "Corruption, nepotism",
    "y": 17.48
  },
  {
    "kode_prov": 36,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 23.3
  },
  {
    "kode_prov": 36,
    "x": "Economic growth",
    "y": 10.68
  },
  {
    "kode_prov": 36,
    "x": "Economic inequality",
    "y": 5.83
  },
  {
    "kode_prov": 36,
    "x": "Health care",
    "y": 0.97
  },
  {
    "kode_prov": 36,
    "x": "Jobs, unemployment",
    "y": 13.59
  },
  {
    "kode_prov": 36,
    "x": "Other\/SPECIFY",
    "y": 2.91
  },
  {
    "kode_prov": 36,
    "x": "Poverty",
    "y": 19.42
  },
  {
    "kode_prov": 36,
    "x": "Schools, education",
    "y": 0.97
  },
  {
    "kode_prov": 36,
    "x": "Wages",
    "y": 1.94
  },
  {
    "kode_prov": 61,
    "x": "Coronavirus, COVID-19",
    "y": 2.56
  },
  {
    "kode_prov": 61,
    "x": "Corruption, nepotism",
    "y": 17.95
  },
  {
    "kode_prov": 61,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 43.59
  },
  {
    "kode_prov": 61,
    "x": "Economic growth",
    "y": 5.13
  },
  {
    "kode_prov": 61,
    "x": "Economic inequality",
    "y": 7.69
  },
  {
    "kode_prov": 61,
    "x": "Jobs, unemployment",
    "y": 12.82
  },
  {
    "kode_prov": 61,
    "x": "Poverty",
    "y": 10.26
  },
  {
    "kode_prov": 71,
    "x": "Coronavirus, COVID-19",
    "y": 11.11
  },
  {
    "kode_prov": 71,
    "x": "Corruption, nepotism",
    "y": 55.56
  },
  {
    "kode_prov": 71,
    "x": "Economic inequality",
    "y": 5.56
  },
  {
    "kode_prov": 71,
    "x": "Poverty",
    "y": 22.22
  },
  {
    "kode_prov": 71,
    "x": "Wages",
    "y": 5.56
  },
  {
    "kode_prov": 76,
    "x": "Coronavirus, COVID-19",
    "y": 5.88
  },
  {
    "kode_prov": 76,
    "x": "Corruption, nepotism",
    "y": 35.29
  },
  {
    "kode_prov": 76,
    "x": "Dont know",
    "y": 17.65
  },
  {
    "kode_prov": 76,
    "x": "Jobs, unemployment",
    "y": 5.88
  },
  {
    "kode_prov": 76,
    "x": "Other\/SPECIFY",
    "y": 5.88
  },
  {
    "kode_prov": 76,
    "x": "Poverty",
    "y": 23.53
  },
  {
    "kode_prov": 76,
    "x": "Wages",
    "y": 5.88
  },
  {
    "kode_prov": 81,
    "x": "Coronavirus, COVID-19",
    "y": 3.23
  },
  {
    "kode_prov": 81,
    "x": "Corruption, nepotism",
    "y": 35.48
  },
  {
    "kode_prov": 81,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 29.03
  },
  {
    "kode_prov": 81,
    "x": "Economic inequality",
    "y": 19.35
  },
  {
    "kode_prov": 81,
    "x": "Health care",
    "y": 9.68
  },
  {
    "kode_prov": 81,
    "x": "Wages",
    "y": 3.23
  },
  {
    "kode_prov": 14,
    "x": "Corruption, nepotism",
    "y": 31.71
  },
  {
    "kode_prov": 14,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 19.51
  },
  {
    "kode_prov": 14,
    "x": "Economic growth",
    "y": 9.76
  },
  {
    "kode_prov": 14,
    "x": "Economic inequality",
    "y": 2.44
  },
  {
    "kode_prov": 14,
    "x": "Health care",
    "y": 7.32
  },
  {
    "kode_prov": 14,
    "x": "Jobs, unemployment",
    "y": 7.32
  },
  {
    "kode_prov": 14,
    "x": "Poverty",
    "y": 12.2
  },
  {
    "kode_prov": 14,
    "x": "Schools, education",
    "y": 4.88
  },
  {
    "kode_prov": 14,
    "x": "Wages",
    "y": 4.88
  },
  {
    "kode_prov": 15,
    "x": "Corruption, nepotism",
    "y": 3.85
  },
  {
    "kode_prov": 15,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 38.46
  },
  {
    "kode_prov": 15,
    "x": "Economic inequality",
    "y": 15.38
  },
  {
    "kode_prov": 15,
    "x": "Health care",
    "y": 7.69
  },
  {
    "kode_prov": 15,
    "x": "Jobs, unemployment",
    "y": 19.23
  },
  {
    "kode_prov": 15,
    "x": "Schools, education",
    "y": 7.69
  },
  {
    "kode_prov": 15,
    "x": "Wages",
    "y": 7.69
  },
  {
    "kode_prov": 16,
    "x": "Corruption, nepotism",
    "y": 15.28
  },
  {
    "kode_prov": 16,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 16.67
  },
  {
    "kode_prov": 16,
    "x": "Economic growth",
    "y": 12.5
  },
  {
    "kode_prov": 16,
    "x": "Economic inequality",
    "y": 8.33
  },
  {
    "kode_prov": 16,
    "x": "Health care",
    "y": 1.39
  },
  {
    "kode_prov": 16,
    "x": "Jobs, unemployment",
    "y": 26.39
  },
  {
    "kode_prov": 16,
    "x": "Other\/SPECIFY",
    "y": 2.78
  },
  {
    "kode_prov": 16,
    "x": "Poverty",
    "y": 9.72
  },
  {
    "kode_prov": 16,
    "x": "Schools, education",
    "y": 5.56
  },
  {
    "kode_prov": 16,
    "x": "Wages",
    "y": 1.39
  },
  {
    "kode_prov": 19,
    "x": "Corruption, nepotism",
    "y": 80.0
  },
  {
    "kode_prov": 19,
    "x": "Economic growth",
    "y": 10.0
  },
  {
    "kode_prov": 19,
    "x": "Poverty",
    "y": 10.0
  },
  {
    "kode_prov": 21,
    "x": "Corruption, nepotism",
    "y": 73.33
  },
  {
    "kode_prov": 21,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 6.67
  },
  {
    "kode_prov": 21,
    "x": "Poverty",
    "y": 20.0
  },
  {
    "kode_prov": 31,
    "x": "Corruption, nepotism",
    "y": 28.57
  },
  {
    "kode_prov": 31,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 20.24
  },
  {
    "kode_prov": 31,
    "x": "Economic growth",
    "y": 9.52
  },
  {
    "kode_prov": 31,
    "x": "Economic inequality",
    "y": 17.86
  },
  {
    "kode_prov": 31,
    "x": "Jobs, unemployment",
    "y": 13.1
  },
  {
    "kode_prov": 31,
    "x": "Other\/SPECIFY",
    "y": 1.19
  },
  {
    "kode_prov": 31,
    "x": "Poverty",
    "y": 3.57
  },
  {
    "kode_prov": 31,
    "x": "Schools, education",
    "y": 4.76
  },
  {
    "kode_prov": 31,
    "x": "Wages",
    "y": 1.19
  },
  {
    "kode_prov": 51,
    "x": "Corruption, nepotism",
    "y": 60.0
  },
  {
    "kode_prov": 51,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 6.67
  },
  {
    "kode_prov": 51,
    "x": "Economic growth",
    "y": 6.67
  },
  {
    "kode_prov": 51,
    "x": "Economic inequality",
    "y": 10.0
  },
  {
    "kode_prov": 51,
    "x": "Jobs, unemployment",
    "y": 6.67
  },
  {
    "kode_prov": 51,
    "x": "Schools, education",
    "y": 6.67
  },
  {
    "kode_prov": 51,
    "x": "Wages",
    "y": 3.33
  },
  {
    "kode_prov": 52,
    "x": "Corruption, nepotism",
    "y": 12.77
  },
  {
    "kode_prov": 52,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 2.13
  },
  {
    "kode_prov": 52,
    "x": "Economic growth",
    "y": 8.51
  },
  {
    "kode_prov": 52,
    "x": "Economic inequality",
    "y": 8.51
  },
  {
    "kode_prov": 52,
    "x": "Health care",
    "y": 12.77
  },
  {
    "kode_prov": 52,
    "x": "Jobs, unemployment",
    "y": 19.15
  },
  {
    "kode_prov": 52,
    "x": "Poverty",
    "y": 12.77
  },
  {
    "kode_prov": 52,
    "x": "Schools, education",
    "y": 21.28
  },
  {
    "kode_prov": 52,
    "x": "Wages",
    "y": 2.13
  },
  {
    "kode_prov": 53,
    "x": "Corruption, nepotism",
    "y": 20.59
  },
  {
    "kode_prov": 53,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 2.94
  },
  {
    "kode_prov": 53,
    "x": "Economic growth",
    "y": 14.71
  },
  {
    "kode_prov": 53,
    "x": "Economic inequality",
    "y": 11.76
  },
  {
    "kode_prov": 53,
    "x": "Jobs, unemployment",
    "y": 17.65
  },
  {
    "kode_prov": 53,
    "x": "Poverty",
    "y": 14.71
  },
  {
    "kode_prov": 53,
    "x": "Schools, education",
    "y": 11.76
  },
  {
    "kode_prov": 53,
    "x": "Wages",
    "y": 5.88
  },
  {
    "kode_prov": 62,
    "x": "Corruption, nepotism",
    "y": 11.11
  },
  {
    "kode_prov": 62,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 11.11
  },
  {
    "kode_prov": 62,
    "x": "Economic growth",
    "y": 61.11
  },
  {
    "kode_prov": 62,
    "x": "Economic inequality",
    "y": 5.56
  },
  {
    "kode_prov": 62,
    "x": "Health care",
    "y": 5.56
  },
  {
    "kode_prov": 62,
    "x": "Poverty",
    "y": 5.56
  },
  {
    "kode_prov": 63,
    "x": "Corruption, nepotism",
    "y": 16.13
  },
  {
    "kode_prov": 63,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 19.35
  },
  {
    "kode_prov": 63,
    "x": "Dont know",
    "y": 3.23
  },
  {
    "kode_prov": 63,
    "x": "Economic growth",
    "y": 29.03
  },
  {
    "kode_prov": 63,
    "x": "Economic inequality",
    "y": 16.13
  },
  {
    "kode_prov": 63,
    "x": "Health care",
    "y": 3.23
  },
  {
    "kode_prov": 63,
    "x": "Jobs, unemployment",
    "y": 6.45
  },
  {
    "kode_prov": 63,
    "x": "Poverty",
    "y": 6.45
  },
  {
    "kode_prov": 64,
    "x": "Corruption, nepotism",
    "y": 17.95
  },
  {
    "kode_prov": 64,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 15.38
  },
  {
    "kode_prov": 64,
    "x": "Economic growth",
    "y": 7.69
  },
  {
    "kode_prov": 64,
    "x": "Economic inequality",
    "y": 10.26
  },
  {
    "kode_prov": 64,
    "x": "Health care",
    "y": 5.13
  },
  {
    "kode_prov": 64,
    "x": "Jobs, unemployment",
    "y": 2.56
  },
  {
    "kode_prov": 64,
    "x": "Other\/SPECIFY",
    "y": 2.56
  },
  {
    "kode_prov": 64,
    "x": "Poverty",
    "y": 17.95
  },
  {
    "kode_prov": 64,
    "x": "Schools, education",
    "y": 10.26
  },
  {
    "kode_prov": 64,
    "x": "Wages",
    "y": 10.26
  },
  {
    "kode_prov": 73,
    "x": "Corruption, nepotism",
    "y": 2.7
  },
  {
    "kode_prov": 73,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 82.43
  },
  {
    "kode_prov": 73,
    "x": "Economic growth",
    "y": 2.7
  },
  {
    "kode_prov": 73,
    "x": "Economic inequality",
    "y": 1.35
  },
  {
    "kode_prov": 73,
    "x": "Jobs, unemployment",
    "y": 4.05
  },
  {
    "kode_prov": 73,
    "x": "Poverty",
    "y": 2.7
  },
  {
    "kode_prov": 73,
    "x": "Schools, education",
    "y": 4.05
  },
  {
    "kode_prov": 91,
    "x": "Corruption, nepotism",
    "y": 30.77
  },
  {
    "kode_prov": 91,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 23.08
  },
  {
    "kode_prov": 91,
    "x": "Economic growth",
    "y": 15.38
  },
  {
    "kode_prov": 91,
    "x": "Economic inequality",
    "y": 7.69
  },
  {
    "kode_prov": 91,
    "x": "Jobs, unemployment",
    "y": 7.69
  },
  {
    "kode_prov": 91,
    "x": "Poverty",
    "y": 15.38
  },
  {
    "kode_prov": 94,
    "x": "Corruption, nepotism",
    "y": 32.14
  },
  {
    "kode_prov": 94,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 14.29
  },
  {
    "kode_prov": 94,
    "x": "Economic growth",
    "y": 7.14
  },
  {
    "kode_prov": 94,
    "x": "Economic inequality",
    "y": 10.71
  },
  {
    "kode_prov": 94,
    "x": "Jobs, unemployment",
    "y": 10.71
  },
  {
    "kode_prov": 94,
    "x": "Other\/SPECIFY",
    "y": 3.57
  },
  {
    "kode_prov": 94,
    "x": "Poverty",
    "y": 14.29
  },
  {
    "kode_prov": 94,
    "x": "Schools, education",
    "y": 7.14
  },
  {
    "kode_prov": 17,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 68.75
  },
  {
    "kode_prov": 17,
    "x": "Economic growth",
    "y": 12.5
  },
  {
    "kode_prov": 17,
    "x": "Jobs, unemployment",
    "y": 6.25
  },
  {
    "kode_prov": 17,
    "x": "Poverty",
    "y": 6.25
  },
  {
    "kode_prov": 17,
    "x": "Schools, education",
    "y": 6.25
  },
  {
    "kode_prov": 72,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 41.38
  },
  {
    "kode_prov": 72,
    "x": "Economic growth",
    "y": 37.93
  },
  {
    "kode_prov": 72,
    "x": "Jobs, unemployment",
    "y": 17.24
  },
  {
    "kode_prov": 72,
    "x": "Schools, education",
    "y": 3.45
  },
  {
    "kode_prov": 74,
    "x": "Cost of living, prices, cost of basic items such as food, palm oil, rice",
    "y": 37.5
  },
  {
    "kode_prov": 74,
    "x": "Economic growth",
    "y": 62.5
  }
]

const dataDefault = [
  {
    "x": "COVID-19",
    "y": 1.58,
    "label": "1.58 %"
  },
  {
    "x": "Corruption",
    "y": 18.51,
    "label": "18.51 %"
  },
  {
    "x": "Cost of living",
    "y": 21.44,
    "label": "21.44 %"
  },
  {
    "x": "Poverty",
    "y": 10.61,
    "label": "10.61 %"
  },
  {
    "x": "Economic growth",
    "y": 13.61,
    "label": "13.61 %"
  },
  {
    "x": "Economic inequality",
    "y": 11.01,
    "label": "11.01 %"
  },
  {
    "x": "Health care",
    "y": 2.09,
    "label": "2.09 %"
  },
  {
    "x": "Jobs",
    "y": 14.06,
    "label": "14.06 %"
  },
  {
    "x": "Education",
    "y": 3.64,
    "label": "3.64 %"
  },
  {
    "x": "Wages",
    "y": 1.83,
    "label": "1.83 %"
  },
  {
    "x": "Dont know",
    "y": 0.61,
    "label": "0.61 %"
  },
  {
    "x": "Other or SPECIFY",
    "y": 0.21,
    "label": "0.21 %"
  },
];

export default class Bar100HorizontalCrowd extends PureComponent {
  render() {

    // console.log('xxx Bar100HorizontalCrowd')
    // console.log(this.props);

    let dataChart = dataIssueMay2023.filter(
      d => d['kode_prov'] === parseInt(this.props.region)
    )

    if (dataChart.length === 0) {
      dataChart = dataDefault
    }

    return (
      <VictoryChart
        theme={Dark}
        height={500}
        width={550}
        padding={{ left: 150, top: 0, right: 10, bottom: 50 }}
        domainPadding={{ x: 20, y: 30 }}
      >
        <VictoryStack horizontal>
          <VictoryBar
            barWidth={20}
            data={dataChart.map(d => { 
              // const total_data = d.total_data === undefined ? d.total_value : d.total_data
              return ({
                x: d.x,
                y: d.y ,
                label: `${d.x} : ${d.y}%`
            })})}
            style={{
              data: {
                fill: ({ x }) => 
                x.includes("COVID-19")
                  ? "#d0021b"
                  : x.includes("Corruption")
                  ? "rgb(6, 0, 194)"
                  : x.includes('Cost of living')
                  ? "#edbc34" 
                  : x.includes('Poverty')
                  ? "#eb0202"
                  : x.includes('Economic growth')
                  ? "#f4ff1c"
                  : x.includes('Economic inequality')
                  ? "#fabf1c"
                  : x.includes('Health care')
                  ? "#047522"
                  : x.includes('Education')
                  ? "#042552"
                  : x.includes('Wages')
                  ? "#ecac34"
                  : x.includes('Dont know')
                  ? "#ddd"
                  : "#eee"
              },
              labels: {
                fill: "#121212",
                fontSize: 22,
                fontWeight: 500,
              }
            }}
            labelComponent={<CustomLabelCrowd dx={-20} dy={20} />}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => ({
                          style: { fill: "#00de78" }
                        })
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: true })
                      }
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: "data",
                        mutation: () => {}
                      },
                      {
                        target: "labels",
                        mutation: () => ({ active: false })
                      }
                    ];
                  }
                }
              }
            ]}
          />
        </VictoryStack>
      </VictoryChart>
    );
  }
}
