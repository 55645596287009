import React, { Fragment, Component } from "react";

import Sidebar from "components/layout/sidebar/Sidebar";
import Map from "components/shared/map/Map";
import MapStackedInfo from "./MapStackedInfo";
import ModalDialog from "components/shared/ModalDialog";
import { VictoryChart, VictoryStack, VictoryBar, VictoryAxis } from "victory";
import { Dark } from "components/shared/charts/themeVictory";
import SubmenuRegion from "components/layout/sidebar/SubmenuRegion";
import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";
import CustomLabel from "components/shared/charts/CustomLabel";
import GroupedBarTurnout from "components/shared/charts/GroupedBarTurnout";

import NumberFormat from 'react-number-format';

import { getTurnout } from "actions/mapActions";
import {
  horserace,
  reelect,
  undecided_a,
  undecided_b,
  turnout_binary,
  turnout_continuous
} from "components/turnoutscore/turnoutDummy";

import { connect } from "react-redux";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import "styles/components/map-submenu.css";
import MapTurnout from "components/shared/map/MapTurnout";

import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";


const API_HOST = process.env.REACT_APP_API_HOST;

//source vaksin
//https://dashboard.kesehatan-digital.id/#/views/DASHBOARDPENGIRIMANVAKSIN-KEMENTERIANV_2/DashboardShipment?:iid=1

class PoiVaksin extends Component {
  state = {
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "Peta Zonasi",
        map_type: "horserace",
        data: [
          { id: 1, name: "Peta Zonasi" },
          { id: 2, name: "Distribusi Vaksin" },
          // { id: 3, name: "undecided_a" },
          // { id: 4, name: "undecided_b" },
          // { id: 5, name: "turnout_binary" },
          // { id: 6, name: "turnout_continuous" }
        ]
      }
    },
    vaccineData: {
      totalSasaran: 0,
      targetSasaran: 0,
      totalDeliveryVaksin: 0,
      targetDeliveryVaksin: 0,
      sasaranTenagaKesehatan: 0,
      cakupanTerkiniVaksin: 0,
      cakupanTerkiniPCT: 0,
      belumTerdeliveryVaksin: 0,
      estimateSisaDosisVaksin: 0,
    },
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "Peta Zonasi" },
            { id: 2, name: "Distribusi Vaksin" },
            // { id: 3, name: "undecided_a" },
            // { id: 4, name: "undecided_b" },
            // { id: 5, name: "turnout_binary" },
            // { id: 6, name: "turnout_continuous" }
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
          // err.response.status === 401
          //   ? this.props.reAuth()
          //   : toastr.error(err.response.statusText);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  async componentDidMount() {
    // await GET(API_HOST + "/sental/master/pulau")
    //   .then(res =>
    //     this.setState({
    //       ...this.state,
    //       region: {
    //         ...this.state.region,
    //         pulau: { ...this.state.region.pulau, data: res.data },
    //         step: 1
    //       }
    //     })
    //   )
    //   .catch(err => {
    //     err.response.status === 401
    //       ? this.props.reAuth()
    //       : toastr.error(err.response.statusText);
    //   });
    
    await GET(API_HOST + "/sental/vaccine/overview")
      .then(res => {
        let cakupanPCT = ((res.data.cakupanTerkiniVaksin / res.data.targetSasaran) * 100).toFixed(2);
        let estimateDosis = (res.data.targetDeliveryVaksin * 5) - res.data.cakupanTerkiniVaksin;

        this.setState({
          ...this.state,
          vaccineData: {
            ...this.state.vaccineData,
            totalSasaran: res.data.totalSasaran,
            targetSasaran: res.data.targetSasaran,
            totalDeliveryVaksin: res.data.totalDeliveryVaksin,
            targetDeliveryVaksin: res.data.targetDeliveryVaksin,
            sasaranTenagaKesehatan: res.data.sasaranTenagaKesehatan,
            cakupanTerkiniVaksin: res.data.cakupanTerkiniVaksin,
            cakupanTerkiniPCT: cakupanPCT,
            belumTerdeliveryVaksin: res.data.belumTerdeliveryVaksin,
            estimateSisaDosisVaksin: estimateDosis,
          }
        })
        console.log("XXX");
        console.log(cakupanPCT);
        }
      )
      .catch(err => {
        err.response.status === 401
          ? this.props.reAuth()
          : toastr.error(err.response.statusText);
      });
  }

  render() {
    const { info, state, handleToggleSatellite } = this.props;
    const {
      openMap,
      isSubOpen,
      openPOI,
      isPOISubOpen,
      region,
      turnout,
      vaccineData
    } = this.state;
    const {
      handleToggleInfo,
      handleTogglePOI,
      handleTogglePOISubMenu,
      handleClearPOI,
      handleSubMenuChange,
      handleCloseSubmenu,
      handleToggleSubMenu,
      handleResetPosition,
      handleGetSubMenu,
      handleChangeMethod
    } = this;

    let dataSource;
    let turnout_intent_get_vaccinated = {
      max_value: 100,
      total_value: 100,
      data: [
        {
          order_id: 1,
          order_name: "Total Try",
          left_label: "Probably Try",
          right_label: "Definitely Try",
          left_data: 33,
          right_data: 29
        },
        {
          order_id: 2,
          order_name: "Total Not Try",
          left_label: "Probably Not Try",
          right_label: "Definitely Not Try",
          left_data: 9,
          right_data: 7
        },
        {
          order_id: 3,
          order_name: "Total Unsure",
          left_label: "Don't Know",
          right_label: "Might or might not",
          left_data: 6,
          right_data: 16
        },
      ]
  }

  let turnout_confidence_get_vaccinated = {
    max_value: 100,
    total_value: 100,
    data: [
      {
        order_id: 1,
        order_name: "Total confident",
        left_label: "Somewhat confident",
        right_label: "Very confident",
        left_data: 54,
        right_data: 7
      },
      {
        order_id: 2,
        order_name: "Total Not Confident",
        left_label: "Not very confident",
        right_label: "Not at all confident",
        left_data: 31,
        right_data: 2
      },
      {
        order_id: 3,
        order_name: "Total Unsure",
        left_label: "Don't Know",
        right_label: "Don't Know",
        left_data: 3,
        right_data: 3
      },
    ]
}

    let groupBarData = turnout_intent_get_vaccinated;
    let map_bottom;
    switch (turnout.method.value) {
      case "Peta Zonasi":
        dataSource = {value : horserace, max: 25000000, min: 5000000};
        turnout.method.map_type = "horserace"
        groupBarData = turnout_intent_get_vaccinated;

        map_bottom = <MapTurnout source="turnout" type="poi_zonasi" />
        break;
      case "Distribusi Vaksin":
        // dataSource = {value : reelect , max: 17300000, min: 17000000};
        // turnout.method.map_type = "reelect"
        // groupBarData = turnout_confidence_get_vaccinated;
        map_bottom = <MapTurnout source="turnout" type="dist_vaksin" />
        break;
      // case "undecided_a":
      //   dataSource = {value : undecided_a , max: 17500000, min: 17100000};
      //   break;
      // case "undecided_b":
      //   dataSource = {value: undecided_b , max: 17320000, min: 17140000};
      //   break;
      // case "turnout_binary":
      //   dataSource = {value: turnout_binary , max: 19200000, min: 18650000};
      //   break;
      // case "turnout_continuous":
      //   dataSource = {value: turnout_continuous , max: 17400000, min: 17220000};
      //   break;
      default:
        map_bottom = <MapTurnout source="turnout" type="poi_zonasi" />
        break;
    }

    return (
      <div className="map50">
        <div className="row heightTurnoutBar" style={{borderBottom: '3px solid #932427'}}>
          <div className="col-lg-12">
            <div className="sidebar sidebar-left">
              <div className="sidebar-content">
                <nav className="main-menu">
                  <ul className="nav">
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          // openMap
                          //   ? this.setState({ openMap: false })
                          //   : this.setState({
                          //       openMap: true,
                          //       isSubOpen: 0,
                          //       openPOI: false
                          //     });
                        }}
                      >
                        <i className="icon dripicons-graph-bar" />
                      </a>
                      <SubmenuTurnout
                        handleToggleInfo={handleToggleInfo}
                        handleGetSubMenu={handleGetSubMenu}
                        handleToggleSubMenu={handleTogglePOISubMenu}
                        handleCloseSubmenu={handleCloseSubmenu}
                        handleChangeMethod={handleChangeMethod}
                        placeholder="Select Scoring"
                        data={{
                          info,
                          openPOI,
                          isPOISubOpen,
                          handleSubMenuChange,
                          turnout
                        }}
                      />
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            {/* <div className="sidebar sidebar-left right-side">
              <div className="sidebar-content">
                <nav className="main-menu">
                  <ul className="nav">
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          // openMap
                          //   ? this.setState({ openMap: false })
                          //   : this.setState({
                          //       openMap: true,
                          //       isSubOpen: 0,
                          //       openPOI: false
                          //     });
                        }}
                      >
                        <i className="icon dripicons-map" />
                      </a>
                      <SubmenuRegion
                        handleToggleInfo={handleToggleInfo}
                        handleGetSubMenu={handleGetSubMenu}
                        handleToggleSubMenu={handleToggleSubMenu}
                        handleCloseSubmenu={handleCloseSubmenu}
                        data={{
                          info,
                          openMap,
                          isSubOpen,
                          handleSubMenuChange,
                          region
                        }}
                      />
                    </li>
                  </ul>
                </nav>
              </div>
            </div> */}
          </div>
          <div className="col-lg-12" style={{ height: "100%", marginTop: "55px" }}>
              {/* <VictoryAxis
                dependentAxis
                style={{
                  axis: { stroke: "transparent" },
                  // axisLabel: { fontSize: 20, padding: 30 },
                  // grid: { stroke: t => (t > 2 ? "red" : "grey") },
                  ticks: { stroke: "#fff", size: 0 },
                  tickLabels: { fontSize: 0, padding: 5 }
                }}
                // tickFormat={t => `${this.mFormatter(t)}`}
              /> */}
              {/* </VictoryStack> */}
            {/* </VictoryChart> */}
            {/* <GroupedBarTurnout
                data={groupBarData}
                color={"qualitative"}
                total={groupBarData.total_value}
              /> */}
              <div className="row">
                <div className="col-lg-6">
                  <h6 style={{color: 'white'}}>Situasi Vaksinasi COVID-19 Saat Ini</h6>
                  <div className="row" style={{marginTop: '15px'}}>
                    <div className="col-lg-5 text-center" 
                      style={{marginLeft:'20px', marginRight: '20px', background: 'rgb(46, 52, 59)', padding:'2px', borderRadius: '20px!important'}}>
                      <h1 style={{fontSize:'55px', color: 'rgb(242, 201, 76)'}} >
                        {/* 181.554.465 */}
                        <NumberFormat value={vaccineData.totalSasaran} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                      </h1>
                      <p style={{color:"white"}}>Total Sasaran Vaksinasi</p>
                    </div>
                    <div className="col-lg-5 text-center" 
                      style={{marginLeft:'20px', marginRight: '20px', background: 'rgb(46, 52, 59)', padding:'2px', borderRadius: '20px!important'}}>
                      <h1 style={{fontSize:'55px', color: 'rgb(245, 166, 35)'}} >
                      {/* 1.468.764 */}
                      <NumberFormat value={vaccineData.sasaranTenagaKesehatan} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                      </h1>
                      <p style={{color:"white"}}>Total yang menerima Vaksin 1</p>
                      <div className="row" style={{paddingLeft: '10px', paddingRight: '40px', marginTop: '-15px'}}>
                        <Progress 
                          percent={vaccineData.sasaranTenagaKesehatan / vaccineData.targetSasaran * 100} 
                          status="success"
                          theme={{
                            success: {
                              symbol: ' ',
                              color: '#fbc630'
                            }
                          }}
                          className = "col-lg-10 text-center"/>
                          <span className="col-lg-2 text-center" style={{color:"white"}}>{
                          (vaccineData.sasaranTenagaKesehatan / vaccineData.targetSasaran * 100).toFixed(2)}%</span>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{marginTop: '25px'}}>
                    <div className="col-lg-5 text-center" 
                      style={{marginLeft:'20px', marginRight: '20px', background: 'rgb(46, 52, 59)', padding:'2px', borderRadius: '20px!important'}}>
                      <h1 style={{fontSize:'55px', color: 'rgb(51, 137, 254)'}} >
                      {/* 40.349.049 */}
                      <NumberFormat value={vaccineData.targetSasaran} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                      </h1>
                      <p style={{color:"white"}}>Target Sasaran Vaksinasi</p>
                    </div>
                    <div className="col-lg-5 text-center" 
                      style={{marginLeft:'20px', marginRight: '20px', background: 'rgb(46, 52, 59)', padding:'2px', borderRadius: '20px!important'}}>
                      <h1 style={{fontSize:'55px', color: 'rgb(33, 150, 83)'}} >
                      {/* 16.531.516 */}
                      <NumberFormat value={vaccineData.cakupanTerkiniVaksin} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                      </h1>
                      {/* <p style={{color: 'rgb(33, 150, 83)', fontSize:'11px'}}>( dari Target Sasaran Vaksinasi)</p> */}
                      <p style={{color:"white"}}>Menerima Dosis Lengkap(Tahap 1 + 2)</p>
                      <div className="row" style={{paddingLeft: '10px', paddingRight: '40px', marginTop: '-15px'}}>
                        <Progress 
                          percent={vaccineData.cakupanTerkiniPCT} 
                          status="success"
                          theme={{
                            success: {
                              symbol: ' ',
                              color: 'rgb(33, 150, 83)'
                            }
                          }}
                          className = "col-lg-10 text-center"/>
                          <span className="col-lg-2 text-center" style={{color:"white"}}>{vaccineData.cakupanTerkiniPCT}%</span>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-lg-6" style={{color: 'white'}}>
                  <h6>Tahapan Vaksinasi COVID-19 &amp; Distribusi Vaksin</h6>

                  <div className="row" style={{marginTop: '25px'}}>
                    <div className="col-lg-4 text-center" 
                      style={{marginLeft:'20px', marginRight: '20px', background: ' #1079dc', borderRadius: '20px!important'}}>
                      <p style={{color:"white", fontSize:'9px', marginTop: '10px'}}>Total Delivery Order <br/>(vial)</p>
                      <h1 style={{fontSize:'40px', color: 'white'}} >
                      {/* 5.028.704 */}
                      {/* 3.720.840 */}
                      <NumberFormat value={vaccineData.totalDeliveryVaksin} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                      </h1>
                    </div>
                    <div className="col-lg-3 text-center" 
                      style={{marginLeft:'20px', marginRight: '20px', background: '#DAF7A6 ',borderRadius: '20px!important'}}>
                      <p style={{color:"#060606", fontSize:'9px', marginTop: '10px'}}>Target Pengiriman <br/>(vial)</p>
                      <h1 style={{fontSize:'40px', color: '#060606'}} >
                      {/* 3.720.840 */}
                      {/* 5.112.404 */}
                      <NumberFormat value={vaccineData.targetDeliveryVaksin} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                      </h1>
                    </div>
                    <div className="col-lg-3 text-center" 
                      style={{marginLeft:'20px', marginRight: '20px', background: 'rgb(245, 166, 35)', borderRadius: '20px!important'}}>
                      <p style={{color:"white", fontSize:'9px', marginTop: '10px'}}>Jumlah Alokasi Belum Terlayani<br/>(vial)</p>
                      <h1 style={{fontSize:'40px', color: 'white'}} >
                      {/* 83.700 */}
                      <NumberFormat value={vaccineData.belumTerdeliveryVaksin} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                      </h1>
                    </div>
                  </div>
                  <div className="row" style={{marginTop: '25px'}}>
                  <div className="col-lg-4 text-center" 
                      style={{marginLeft:'20px', marginRight: '20px', borderRadius: '20px!important'}}>
                      <h1 style={{fontSize:'26px', color: 'white'}} >
                      Dosis Vaksin yang Belum Diberikan ?
                      </h1>
                      <p style={{color:"white", fontSize:'9px'}}>1 vial ~ 5mL (5 Orang per 2 suntikan)</p>
                    </div>
                    <div className="col-lg-6 text-center" 
                      style={{marginLeft:'20px', marginRight: '20px', background: '#e02309',borderRadius: '20px!important'}}>
                      <p style={{color:"white", fontSize:'11px', marginTop: '10px'}}>Estimasi Dosis Belum Diberikan (mL)</p>
                      <h1 style={{fontSize:'59px', color: 'white'}} >
                      {/* 9.030.504 */}
                      <NumberFormat value={vaccineData.estimateSisaDosisVaksin} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

          </div>
        </div>
        {map_bottom}
        {/* <MapTurnout source="turnout" type="poi_zonasi" /> */}
        <ModalDialog />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PoiVaksin);
