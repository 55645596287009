import React, { PureComponent } from "react";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography
} from "react-simple-maps";
import Legends from "./Legends";
import Jakarta from "./JakartaBesar";
import loading from "media/img/components/loading.gif";
import { Row, Col } from "reactstrap";

class BorderMap extends PureComponent {
  state = {
    key: 1
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data.legend !== this.props.data.legend) {
      this.setState({
        key: this.state.key + 1
      });
    }
  }
  render() {
    return this.props.data.legend !== undefined ? (
      <div>
        {this.props.type !== "coverage" && (
          <Legends dataLegend={this.props.data.legend} />
        )}

        <div
          style={{
            position: "absolute",
            left: "40px",
            bottom: "10px",
            width: "96px",
            height: "105px",
            cursor: "pointer",
            border: "1px dotted #b2b2b2",
            borderRadius: "15px",
            overflowX: "visible",
            padding: "10px"
          }}
          onClick={() =>
            this.props.handleBorderMapClick({
              kode_prop: 31,
              position: { lat: "-6.231888", lng: "106.846226" },
              scale: 70000,
              value: "DKI JAKARTA",
              type: this.props.type,
              candidate: this.props.candidate
            })
          }
        >
          <span
            style={{
              position: "absolute",
              left: "15px",
              bottom: "3px",
              fontSize: "0.7em"
            }}
          >
            DKI Jakarta
          </span>
          <Jakarta
            paslon={this.props.paslon}
            data={this.props.data.summary_capital}
          />
        </div>

        <ComposableMap
          projection="mercator"
          projectionConfig={{ scale: 1000 }}
          style={{
            width: "100%"
          }}
          key={this.state.key}
        >
          <ZoomableGroup center={[118, 2]} disablePanning>
            <Geographies
              geography={`${process.env.PUBLIC_URL}/prop_ind_min.json`}
            >
              {(geographies, projection) =>
                geographies.map((geography, i) => {
                  let fillBorder = "#ccc";

                  this.props.data.legend.map((val, i) => {

                    val.location_code.map(value => {
                      if (value === geography.properties.kode_prop) {
                        return (fillBorder = val.color);
                      }
                    });
                  });

                  return (
                    <Geography
                      key={i}
                      geography={geography}
                      projection={projection}
                      onClick={() =>
                        this.props.handleBorderMapClick({
                          kode_prop: geography.properties.kode_prop,
                          position: {
                            lat: geography.properties.center_lat,
                            lng: geography.properties.center_lng
                          },
                          scale: geography.properties.scale,
                          value: geography.properties.propinsi,
                          type: this.props.type,
                          candidate: this.props.candidate
                        })
                      }
                      style={{
                        default: {
                          fill: fillBorder,
                          fillOpacity: 1,
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none"
                        },
                        hover: {
                          fill: fillBorder,
                          fillOpacity: 0.7,
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none"
                        }
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </div>
    ) : (
      <Row>
        <Col
          style={{
            width: 650,
            height: 450,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img src={loading} alt="loading" />
        </Col>
      </Row>
    );
  }
}

export default BorderMap;
