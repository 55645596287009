import React from "react";
import { connect } from "react-redux";

// import SideInfo from "./SideInfo";

import {
    // getLegends,
    getDemographySidePanel,
    getDemographySidePanelMultiple
  } from "actions/mapActions";

import { Card, Select  } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

const provinceTest = [
    {
        "id": "-1",
        "label": "No Selection"
    },
    {
        "id": "-3",
        "label": "Oct 2023 Survey"
    },
    {
        "id": "-4",
        "label": "Nov 2023 Survey"
    },
    // {
    //     "id": "31",
    //     "label": "DKI Jakarta"
    // },
    // {
    //     "id": "32",
    //     "label": "Jawa Barat"
    // },
    // {
    //     "id": "33",
    //     "label": "Jawa Tengah"
    // },
    // {
    //     "id": "35",
    //     "label": "Jawa Timur"
    // }
]

const filterFavorableET = [
    {
        "id": 0,
        "label": "No Selection"
    },
    {
        "id": 1,
        "label": "Greater than Baseline"
    },
    {
        "id": 2,
        "label": "Below than Baseline"
    }
]

const jobApprovalET = [
    {
        "id": 0,
        "label": "No Selection"
    },
    {
        "id": 1,
        "label": "Greater than Baseline"
    },
    {
        "id": 2,
        "label": "Below than Baseline"
    }
]

const jobApprovalPssiET = [
    {
        "id": 0,
        "label": "No Selection"
    },
    {
        "id": 1,
        "label": "Greater than Baseline"
    },
    {
        "id": 2,
        "label": "Below than Baseline"
    }
]

const electabilityET = [
    {
        "id": 0,
        "label": "No Selection"
    },
    {
        "id": 1,
        "label": "Greater than 12%"
    },
    {
        "id": 2,
        "label": "Below than 12%"
    }
]

const filterLists = [];

class SidePanel extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        type: this.props.type,
        region: '-1',
        favorableSelection: 0,
        jobApprovalSelection: 0,
        jobApprovalPssiSelection: 0,
        electabilityEtSelection : 0

    };

    // async componentDidMount() {
    //     await this.props.getLegends('unknown', 'all');        
    // }

    handleChange = (event) => {
        const name = event.target.name;
        this.setState({
            region: event.target.value,
        });
        // console.log(event.target.value);

        // this.props.getDemographySidePanel('region',  event.target.value);

        // let position = {
        //     lat: parseFloat(-6.816131),
        //     lng: parseFloat(107.650177)
        //   }
        //   let data = { position, 32, 8000, 'JAWA BARAT' }
        //   this.setModal();
          /**
           * lat: -6.231888, lng: 106.846226}lat: -6.231888lng: 106.846226[[Prototype]]: Object '31' '70000' 'DKI JAKARTA'
           * {lat: -6.302644, lng: 106.200015} '36' '15000' 'BANTEN'
           * {lat: -6.816131, lng: 107.650177} '32' '8000' 'JAWA BARAT'
           * {lat: -7.287552, lng: 109.956063} '33' '8000' 'JAWA TENGAH'
           * {lat: -7.845196, lng: 110.40074} '34' '30000' 'DI YOGYAKARTA'
           * {lat: -7.768415, lng: 112.512732} '35' '7000' 'JAWA TIMUR'
           */
    };

    handleChangeFavorable = (event) => {
        const name = event.target.name;
        this.setState({
            favorableSelection: event.target.value,
        });

        let tblName = 'map_360_home_favorable';
        if (event.target.value > 0) {
            if (event.target.value === 2) {
                tblName += '_bottom';
            }

            if ((filterLists.find((str) => str === 'map_360_home_favorable') !== undefined) 
                || (filterLists.find((str) => str === 'map_360_home_favorable_bottom') !== undefined) ) {
                    filterLists.splice(filterLists.indexOf('map_360_home_favorable'), 1);
                    filterLists.splice(filterLists.indexOf('map_360_home_favorable_bottom'), 1);
            }

            filterLists.push(tblName)   
        } else {
            filterLists.splice(filterLists.indexOf('map_360_home_favorable'), 1);
            filterLists.splice(filterLists.indexOf('map_360_home_favorable_bottom'), 1);
        }
        console.log("favorable : " + event.target.value);

        console.log(filterLists);

        if (filterLists.length > 1) {
            this.props.getDemographySidePanelMultiple(filterLists.join(';'));
        } else {
            this.props.getDemographySidePanel('favorable',  event.target.value);
        }

    };

    handleChangeJobApproval = (event) => {
        const name = event.target.name;
        this.setState({
            jobApprovalSelection: event.target.value
        });
        console.log("job approval: " + event.target.value);

        let tblName = 'map_360_home_approval_bumn';
        if (event.target.value > 0) {
            if (event.target.value === 2) {
                tblName += '_bottom';
            }

            if ((filterLists.find((str) => str === 'map_360_home_approval_bumn') !== undefined) 
                || (filterLists.find((str) => str === 'map_360_home_approval_bumn_bottom') !== undefined) ) {
                    filterLists.splice(filterLists.indexOf('map_360_home_approval_bumn'), 1);
                    filterLists.splice(filterLists.indexOf('map_360_home_approval_bumn_bottom'), 1);
            }

            filterLists.push(tblName)   
        } else {
            filterLists.splice(filterLists.indexOf('map_360_home_approval_bumn'), 1);
            filterLists.splice(filterLists.indexOf('map_360_home_approval_bumn_bottom'), 1);
        }
        console.log(filterLists);

        if (filterLists.length > 1) {
            this.props.getDemographySidePanelMultiple(filterLists.join(';'));
        } else {
            this.props.getDemographySidePanel('approval_bumn',  event.target.value);
        }
    };

    handleChangeJobApprovalPssi = (event) => {
        const name = event.target.name;
        this.setState({
            jobApprovalPssiSelection: event.target.value
        });
        console.log("job approval pssi: " + event.target.value);

        let tblName = 'map_360_home_approval_pssi';
        if (event.target.value > 0) {
            if (event.target.value === 2) {
                tblName += '_bottom';
            }

            if ((filterLists.find((str) => str === 'map_360_home_approval_pssi') !== undefined) 
                || (filterLists.find((str) => str === 'map_360_home_approval_pssi_bottom') !== undefined) ) {
                    filterLists.splice(filterLists.indexOf('map_360_home_approval_pssi'), 1);
                    filterLists.splice(filterLists.indexOf('map_360_home_approval_pssi_bottom'), 1);
            }

            filterLists.push(tblName)   
        } else {
            filterLists.splice(filterLists.indexOf('map_360_home_approval_pssi'), 1);
            filterLists.splice(filterLists.indexOf('map_360_home_approval_pssi_bottom'), 1);
        }

        console.log(filterLists);

        if (filterLists.length > 1) {
            this.props.getDemographySidePanelMultiple(filterLists.join(';'));
        } else {
            this.props.getDemographySidePanel('approval_pssi',  event.target.value);
        }

    };

    handleChangeElectabilityET = (event) => {
        const name = event.target.name;
        this.setState({
            electabilityEtSelection: event.target.value,
        });
        console.log("electability: " + event.target.value);

        let tblName = 'map_360_home_election';
        if (event.target.value > 0) {
            if (event.target.value === 2) {
                tblName += '_bottom';
            }

            if ((filterLists.find((str) => str === 'map_360_home_election') !== undefined) 
                || (filterLists.find((str) => str === 'map_360_home_election_bottom') !== undefined) ) {
                    filterLists.splice(filterLists.indexOf('map_360_home_election'), 1);
                    filterLists.splice(filterLists.indexOf('map_360_home_election_bottom'), 1);
            }

            filterLists.push(tblName)   
        } else {
            filterLists.splice(filterLists.indexOf('map_360_home_election'), 1);
            filterLists.splice(filterLists.indexOf('map_360_home_election_bottom'), 1);
        }
        console.log(filterLists);

        if (filterLists.length > 1) {
            this.props.getDemographySidePanelMultiple(filterLists.join(';'));
        } else {
            this.props.getDemographySidePanel('electability',  event.target.value);
        }

        // this.props.getDemographySidePanel('map_360_home_election',  event.target.value);
    };


    

    render() {
        const { info } = this.props;
        const { region, favorableSelection, jobApprovalSelection, jobApprovalPssiSelection, electabilityEtSelection, countFilter } = this.state;

        return (
            <>
                <div className="row" style={{marginTop: '10px', marginBottom: '20px'}}>
                    <div className="col-md-12">
                        <Card style={{background: '#282828', padding: '20px'}}>
                            <div className="row" style={{padding: '0px 20px', color: 'white', fontSize: '16px'}}>
                                <strong>Survey Result</strong>
                            </div>
                            <div className="row" style={{padding: '5px 20px 20px 20px'}}>
                                <Select
                                    value={region}
                                    onChange={this.handleChange}
                                    label="National"
                                    fullWidth
                                    style={{background: '#4D4C51', color:"white", borderRadius: '4px', padding: '10px 20px', fontSize: '12px', textTransform:'capitalize'}}
                                >
                                    {
                                        provinceTest.map((val, idx) => (
                                            <MenuItem value={val.id} style={{color:"#4D4C51", padding: '5px 8px'}}>
                                                {val.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                            
                        </Card>
                        
                    </div>
                </div>

                <div className="row" style={{marginTop: '10px', marginBottom: '20px'}}>
                    <div className="col-md-12">
                        <Card style={{background: '#282828', padding: '20px'}}>
                            <div className="row" style={{padding: '0px 20px', color: 'white', fontSize: '16px'}}>
                                <strong>Strong Voters (PS): 80%</strong>
                            </div>
                            <div className="row" style={{padding: '5px 20px 20px 20px'}}>
                                <Select
                                    value={favorableSelection}
                                    onChange={this.handleChangeFavorable}
                                    label="No Selection"
                                    fullWidth
                                    style={{background: '#4D4C51', color:"white", borderRadius: '4px', padding: '10px 20px', fontSize: '12px', textTransform:'capitalize'}}
                                >
                                    {
                                        filterFavorableET.map((val, idx) => (
                                            <MenuItem value={val.id} style={{color:"#4D4C51", padding: '5px 8px'}}>
                                                {val.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                            
                        </Card>
                        
                    </div>
                </div>

                <div className="row" style={{marginTop: '10px', marginBottom: '20px'}}>
                    <div className="col-md-12">
                        <Card style={{background: '#282828', padding: '20px'}}>
                            <div className="row" style={{padding: '0px 20px', color: 'white', fontSize: '16px'}}>
                                <strong>Area Potential</strong>
                            </div>
                            <div className="row" style={{padding: '5px 20px 20px 20px'}}>
                                <Select
                                    value={jobApprovalSelection}
                                    onChange={this.handleChangeJobApproval}
                                    label="No Selection"
                                    fullWidth
                                    style={{background: '#4D4C51', color:"white", borderRadius: '4px', padding: '10px 20px', fontSize: '12px', textTransform:'capitalize'}}
                                >
                                    {
                                        jobApprovalET.map((val, idx) => (
                                            <MenuItem value={val.id} style={{color:"#4D4C51", padding: '5px 8px'}}>
                                                {val.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                            
                        </Card>
                        
                    </div>
                </div>
{/* 
                <div className="row" style={{marginTop: '10px', marginBottom: '20px'}}>
                    <div className="col-md-12">
                        <Card style={{background: '#282828', padding: '20px'}}>
                            <div className="row" style={{padding: '0px 20px', color: 'white', fontSize: '16px'}}>
                                <strong>Job Approval - PSSI</strong>
                            </div>
                            <div className="row" style={{padding: '5px 20px 20px 20px'}}>
                                <Select
                                    value={jobApprovalPssiSelection}
                                    onChange={this.handleChangeJobApprovalPssi}
                                    label="No Selection"
                                    fullWidth
                                    style={{background: '#4D4C51', color:"white", borderRadius: '4px', padding: '10px 20px', fontSize: '12px', textTransform:'capitalize'}}
                                >
                                    {
                                        jobApprovalPssiET.map((val, idx) => (
                                            <MenuItem value={val.id} style={{color:"#4D4C51", padding: '5px 8px'}}>
                                                {val.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                            
                        </Card>
                        
                    </div>
                </div> */}

                <div className="row" style={{marginTop: '10px', marginBottom: '20px'}}>
                    <div className="col-md-12">
                        <Card style={{background: '#282828', padding: '20px'}}>
                            <div className="row" style={{padding: '0px 20px', color: 'white', fontSize: '16px'}}>
                                <strong>Electability</strong>
                            </div>
                            <div className="row" style={{padding: '5px 20px 20px 20px'}}>
                                <Select
                                    value={electabilityEtSelection}
                                    onChange={this.handleChangeElectabilityET}
                                    label="No Selection"
                                    fullWidth
                                    style={{background: '#4D4C51', color:"white", borderRadius: '4px', padding: '10px 20px', fontSize: '12px', textTransform:'capitalize'}}
                                >
                                    {
                                        electabilityET.map((val, idx) => (
                                            <MenuItem value={val.id} style={{color:"#4D4C51", padding: '5px 8px'}}>
                                                {val.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                            
                        </Card>
                        
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-12">
                        <Card style={{background: '#282828'}}>
                            <SideInfo />
                        </Card>
                    </div>
                </div>
                 */}
            </>
        )
    }
}
  
const mapStateToProps = state => ({
    info: state.map
});
  
const mapDispatchToProps = {
    // getLegends,
    getDemographySidePanel,
    getDemographySidePanelMultiple
};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidePanel);

// export default SidePanel;