const initState = {
  HRC: {
    data: []
  },
  wordCloud: {
    data:{}
  },
  posts: {
    content: [],
    totalPages: 0,
    totalElements : 0
  },
  isLoading: false
};

export default function chartReducer(state = initState, action) {
  switch (action.type) {
    case "FETCHING":
      return{
        ...state,
        isLoading: true
      }
    case "SET_HRC":
      return {
        ...state,
        HRC: action.payload
      };
      case "SET_WORD_CLOUD":
      return {
        ...state,
        wordCloud: action.payload,
        isLoading: false
      };
      case "SET_SOCIAL_MEDIA_POST":
      return {
        ...state,
        posts: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
}
