import React, { PureComponent } from 'react'
import { Polygon } from "react-google-maps"


class Poly extends PureComponent {
  constructor(props) {
    super(props);
    // this.eventTimeout;
  }

  state = {
    fillOpacity: 1,
    strokeWeight: 0.3,
    isClick:false
  }

  handlePolyClick = () => {
    this.setState({ fillOpacity: 0.8, strokeWeight: 1.4 })
    this.props.handlePolyClick()
  }

  handlePolyHover = () => {
    this.setState({
      fillOpacity: 0.4,
      strokeWeight: 1.4
    })
    this.props.handlePolyHover();
  }

  handlePolyMouseOut = () => {
    this.props.handlePolyMouseOut()
    this.setState({
      fillOpacity: 1,
      strokeWeight: 0.3
    })
  }

  render() {
    // const fillColor = this.props.color;
    
    let fillColor = '#fff';

    this.props.dataLegend.map((e, i) => {
        e.location_code.map((f, j) => {
          if (f === this.props.kode_prop){
            return fillColor = e.color;
          } 
        })
      
    })

    // if(this.props.jumlah)
  
    //   if (this.props.jumlah > this.props.dataLegend[0].range) {
    //     fillColor = this.props.dataLegend[0].color;
    //   } else if (this.props.jumlah > this.props.dataLegend[1].range) {
    //     fillColor = this.props.dataLegend[1].color;
    //   } else if (this.props.jumlah > this.props.dataLegend[2].range) {
    //     fillColdataLegendor = this.props.dataLegend[2].color;
    //   } else if (this.props.jumlah > this.props.dataLegend[3].range) {
    //     fillColor = this.props.dataLegend[3].color;
    //   } else if (this.props.jumlah > this.props.dataLegend[4].range) {
    //     fillColor = this.props.dataLegend[4].color;
    //   }

    

    return (
      <Polygon
        options={{
          strokeColor: '#000',
          strokeOpacity: 0.5,
          strokeWeight: this.state.strokeWeight,
          fillColor: fillColor,
          fillOpacity: this.state.fillOpacity
        }}
        defaultPaths={this.props.triangleCoords}
        onClick={this.handlePolyClick}
        onMouseOver={this.handlePolyHover}
        onMouseOut={this.handlePolyMouseOut}
      >

      </Polygon>
    )
  }
}

export default Poly
