import React, { Component, Fragment } from "react";
import { HorseRaceChart } from "components/shared/charts/HorseRaceChart";
import { HorseRaceChart2 } from "components/shared/charts/HorseRaceChart2";
import { HorseRaceChart3 } from "components/shared/charts/HorseRaceChart3";
import { HorseRaceChart4 } from "components/shared/charts/HorseRaceChart4";
import { HorseRaceChart5 } from "components/shared/charts/HorseRaceChart5";
import { connect } from "react-redux";
import { getHRC } from "actions/chartActions";
import { getOverview } from "actions/mapActions";

import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";

import ShiftingChart from "components/shared/charts/ShiftingChart";
import BarChartHRechart from "components/shared/charts/BarChartHRechart";
import { LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ReferenceLine, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { getTurnout } from "actions/mapActions";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import {CustomInput, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup, ButtonToolbar, Label, Input } from 'reactstrap';
import {RangeStepInput} from 'react-range-step-input';

import "styles/components/map-submenu.css";

import {
  dataCountdownWave4,
  dataCountdownWave5
} from "./DataCountdown";

import { horseraceWave2, horseraceWave3, horseraceWave4, horseraceWave5} from "./DataWave2";

const API_HOST = process.env.REACT_APP_API_HOST;

class Countdown extends Component {
  state = {
    period: {
      type: "historical",
      value: "pemilu2014",
      year: "2014",
      filter: {
        value: "5050",
        time: "undecided_a"
      }
    },
    loc: false,
    chartValue: "historical",
    dropdownOpen: false,
    w1: 10,
    w2: 20,
    w3: 30,
    mapIdHover: '00',
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "Survey 5 (jan 2022)",
        map_type: "horserace",
        data: [
          { id: 1, name: "Survey 5 (jan 2022)" },
          { id: 2, name: "Survey 4 (jul 2021)" },
          { id: 3, name: "Survey 3 (jun 2021)" },
          { id: 4, name: "Survey 2 (mar 2021)" },
          { id: 5, name: "Survey 1 (nov 2020)" },
        ]
      }
    },
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null
  };

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  onChangeW1(e) {
    const newVal = (e.target.value);
    this.setState({w1: newVal});
  };

  onChangeW2(e) {
    const newVal = (e.target.value);
    this.setState({w2: newVal});
  };

  onChangeW3(e) {
    const newVal = (e.target.value);
    this.setState({w3: newVal});
  };


  toogleDropdown = () => {
    this.setState({
      ...this.state,
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  centerMenuSelection = (event) => {
    console.log(event.target.value);

    if(event.target.value == 2) {
      this.setState({
        ...this.state,
        chartValue: 'historical'
      })
    } else {
      this.setState({
        ...this.state,
        chartValue: 'predictive'
      })
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "Survey 5 (jan 2022)" },
            { id: 2, name: "Survey 4 (jul 2021)" },
            { id: 3, name: "Survey 3 (jun 2021)" },
            { id: 4, name: "Survey 2 (mar 2021)" },
            { id: 5, name: "Survey 1 (nov 2020)" },
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
          // err.response.status === 401
          //   ? this.props.reAuth()
          //   : toastr.error(err.response.statusText);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  handleChangePeriod = ({ type, value }) => {
    this.setState({
      period: {
        type,
        value,
        filter: {
          value:
            type === "predictive" ? this.state.period.filter.value : "5050",
          time:
            type === "predictive"
              ? this.state.period.filter.time
              : "undecided_a"
        }
      },
      loc: false
    });
  };

  handleChangeFilter = val => {
    this.setState({
      period: {
        ...this.state.period,
        filter: {
          value: val.value,
          time: val.time
        }
      }
    });
  };

  handlePieClick = async (id, isProv) => {
    if (this.state.period.value === "pemilu2014") {
      await this.props.getHRC(
        this.state.period.type,
        this.state.period.value,
        id
      );
      this.setState({
        ...this.state,
        loc: !isProv
      });
    }
  };

  handleToUpdatePieHover = async (mapId) => {
    console.log("XXX in parent countdown");
    console.log(mapId);
    // alert('We pass argument from Child to Parent: ' + someArg);

    await this.setState({
      ...this.state,
      mapIdHover: mapId
    })
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.period.value !== this.state.period.value &&
      this.state.period.type === "historical"
    ) {
      this.props.getHRC(this.state.period.type, this.state.period.value);
    } else if (
      prevState.period !== this.state.period &&
      this.state.period.type === "predictive"
    ) {
      await this.props.getOverview(
        this.state.period.value,
        this.state.period.filter.time
      );
    }
  }

  componentDidMount() {
    this.props.getHRC("historical", "pemilu2014");
  }

  render() {
    const { info, chart } = this.props;
    const { period, loc, chartValue, openPOI,
      isPOISubOpen, isSubOpen, region, turnout, dropdownOpen, mapIdHover } = this.state;
    const { handleChangePeriod, handlePieClick, handleChangeFilter, 
      handleToggleInfo, handleTogglePOISubMenu, handleSubMenuChange,
      handleCloseSubmenu, handleGetSubMenu, handleChangeMethod, handleToUpdatePieHover
     } = this;

    let data_shifting_1 = [
      {
        "x": 18,
        "y": 11,
        "label": "Jawa Barat",
        "id": "32"
      },
      {
        "x": 15,
        "y": 14,
        "label": "Jawa Tengah",
        "id": "33"
      },
      {
        "x": 47,
        "y": 48,
        "label": "Jawa Timur",
        "id": "35"
      },
      {
        "x": 2,
        "y": 1,
        "label": "Banten",
        "id": "36"
      },
      {
        "x": 2,
        "y": 2,
        "label": "Sumatera Utara",
        "id": "12"
      },
      {
        "x": 5,
        "y": 5,
        "label": "Jakarta",
        "id": "31"
      },
      {
        "x": 6,
        "y": 1,
        "label": "Kalimantan Barat",
        "id": "61"
      },
      {
        "x": 3,
        "y": 1,
        "label": "Lampung",
        "id": "18"
      },
      {
        "x": 5,
        "y": 3,
        "label": "Riau",
        "id": "14"
      },
      {
        "x": 5,
        "y": 5,
        "label": "Papua",
        "id": "91"
      },
      {
        "x": 3,
        "y": 1,
        "label": "Kalimantan Timur",
        "id": "64"
      },
      {
        "x": 2,
        "y": 2,
        "label": "Kalimantan Utara",
        "id": "64"
      },
      {
        "x": 1,
        "y": 1,
        "label": "Nusa Tenggara Timur",
        "id": "53"
      },
      {
        "x": 55,
        "y": 48,
        "label": "Bali",
        "id": "51"
      },
      {
        "x": 5,
        "y": 1,
        "label": "Aceh",
        "id": "11"
      },
      {
        "x": 7,
        "y": 1,
        "label": "Maluku",
        "id": "81"
      },
      {
        "x": 3,
        "y": 1,
        "label": "Kalimantan Tengah",
        "id": "62"
      },
      {
        "x": 7,
        "y": 5,
        "label": "Sulawesi Tenggara",
        "id": "74"
      },
      {
        "x": 5,
        "y": 4,
        "label": "Jambi",
        "id": "15"
      },
      {
        "x": 5,
        "y": 1,
        "label": "Bangka Belitung",
        "id": "19"
      },
      {
        "x": 4,
        "y": 1,
        "label": "Sulawesi Barat",
        "id": "76"
      },
      {
        "x": 2,
        "y": 1,
        "label": "Kalimantan Selatan",
        "id": "63"
      },
      {
        "x": 12,
        "y": 10,
        "label": "Gorontalo",
        "id": "75"
      },
      {
        "x": 9,
        "y": 7,
        "label": "Sumatera Barat",
        "id": "13"
      },
      {
        "x": 1,
        "y": 1,
        "label": "Sulawesi Utara",
        "id": "71"
      },
      {
        "x": 2,
        "y": 0,
        "label": "Maluku Utara",
        "id": "82"
      },
      {
        "x": 0,
        "y": 0,
        "label": "Sulawesi Tengah",
        "id": "72"
      },
      {
        "x": 52,
        "y": 41,
        "label": "Yogyakarta",
        "id": "34"
      },
      {
        "x": 7,
        "y": 1,
        "label": "Sumatera Selatan",
        "id": "16"
      },
      {
        "x": 2,
        "y": 2,
        "label": "Sulawesi Selatan",
        "id": "73"
      },
      {
        "x": 3,
        "y": 3,
        "label": "Bengkulu",
        "id": "17"
      },
      {
        "x": 61,
        "y": 41,
        "label": "Kepulauan Riau",
        "id": "21"
      },
      {
        "x": 0,
        "y": 0,
        "label": "Nusa Tenggara Barat",
        "id": "52"
      },
      {
        "x": 7,
        "y": 1,
        "label": "Papua Barat",
        "id": "92"
      }
    ];

    let data_table_elected = dataCountdownWave5;

    let horseRaceTokohData = {
      "location_id": "-1",
      "location_name": "National",
      "total_vote_1": 83664216,
      "total_vote_2": 67973884,
      "total_vote_1_percent": 13,
      "total_vote_2_percent": 16,
      "total_1_fav_national": "66/18",
      "total_2_fav_national": "78/13",
      "data": [
        {
          "id": "32",
          "name": "JAWA BARAT",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 10718874,
          "vote_2": 16029115,
          "vote_1_percent": 12,
          "vote_2_percent": 16,
          "total_1_fav": "66/15",
          "total_2_fav": "83/11"
        },
        {
          "id": "33",
          "name": "JAWA TENGAH",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 16784716,
          "vote_2": 4939445,
          "vote_1_percent": 14,
          "vote_2_percent": 14,
          "total_1_fav": "65/20",
          "total_2_fav": "75/13"
        },
        {
          "id": "35",
          "name": "JAWA TIMUR",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 16145259,
          "vote_2": 8392506,
          "vote_1_percent": 15,
          "vote_2_percent": 7,
          "total_1_fav": "62/21",
          "total_2_fav": "53/30"
        },
        {
          "id": "36",
          "name": "BANTEN",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 2530608,
          "vote_2": 4045338,
          "vote_1_percent": 11,
          "vote_2_percent": 16,
          "total_1_fav": "63/20",
          "total_2_fav": "71/23"
        },
        {
          "id": "12",
          "name": "SUMATERA UTARA",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 3886014,
          "vote_2": 3553576,
          "vote_1_percent": 15,
          "vote_2_percent": 16,
          "total_1_fav": "74/15",
          "total_2_fav": "72/24"
        },
        {
          "id": "31",
          "name": "DKI JAKARTA",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 3269971,
          "vote_2": 3057851,
          "vote_1_percent": 14,
          "vote_2_percent": 16,
          "total_1_fav": "63/20",
          "total_2_fav": "67/27"
        },
        {
          "id": "61",
          "name": "KALIMANTAN BARAT",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 1707167,
          "vote_2": 1260628,
          "vote_1_percent": 10,
          "vote_2_percent": 16,
          "total_1_fav": "59/18",
          "total_2_fav": "79/10"
        },
        {
          "id": "18",
          "name": "LAMPUNG",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 2845798,
          "vote_2": 1951645,
          "vote_1_percent": 15,
          "vote_2_percent": 16,
          "total_1_fav": "74/15",
          "total_2_fav": "72/24"
        },
        {
          "id": "14",
          "name": "RIAU",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 1246888,
          "vote_2": 1973298,
          "vote_1_percent": 15,
          "vote_2_percent": 16,
          "total_1_fav": "74/15",
          "total_2_fav": "72/24"
        },
        {
          "id": "91",
          "name": "PAPUA BARAT",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 395084,
          "vote_2": 94534,
          "vote_1_percent": 20,
          "vote_2_percent": 9,
          "total_1_fav": "73/14",
          "total_2_fav": "73/23"
        },
        {
          "id": "65",
          "name": "KALIMANTAN UTARA",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 247352,
          "vote_2": 105498,
          "vote_1_percent": 9,
          "vote_2_percent": 16,
          "total_1_fav": "59/18",
          "total_2_fav": "79/10"
        },
        {
          "id": "64",
          "name": "KALIMANTAN TIMUR",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 1093148,
          "vote_2": 870043,
          "vote_1_percent": 10,
          "vote_2_percent": 16,
          "total_1_fav": "59/18",
          "total_2_fav": "79/10"
        },
        {
          "id": "53",
          "name": "NUSA TENGGARA TIMUR",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 2362041,
          "vote_2": 305615,
          "vote_1_percent": 17,
          "vote_2_percent": 9,
          "total_1_fav": "73/14",
          "total_2_fav": "73/23"
        },
        {
          "id": "51",
          "name": "B A L I",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 2342628,
          "vote_2": 212586,
          "vote_1_percent": 15,
          "vote_2_percent": 9,
          "total_1_fav": "73/14",
          "total_2_fav": "69/25"
        },
        {
          "id": "11",
          "name": "NANGGROE ACEH DARUSSALAM",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 397251,
          "vote_2": 2352397,
          "vote_1_percent": 9,
          "vote_2_percent": 16,
          "total_1_fav": "63/20",
          "total_2_fav": "80/15"
        },
        {
          "id": "81",
          "name": "MALUKU",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 561012,
          "vote_2": 388069,
          "vote_1_percent": 16,
          "vote_2_percent": 16,
          "total_1_fav": "73/14",
          "total_2_fav": "73/23"
        },
        {
          "id": "62",
          "name": "KALIMANTAN TENGAH",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 828596,
          "vote_2": 536213,
          "vote_1_percent": 10,
          "vote_2_percent": 16,
          "total_1_fav": "59/18",
          "total_2_fav": "79/10"
        },
        {
          "id": "74",
          "name": "SULAWESI TENGGARA",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 554470,
          "vote_2": 840465,
          "vote_1_percent": 14,
          "vote_2_percent": 16,
          "total_1_fav": "70/14",
          "total_2_fav": "80/15"
        },
        {
          "id": "15",
          "name": "J A M B I",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 858738,
          "vote_2": 1200255,
          "vote_1_percent": 17,
          "vote_2_percent": 16,
          "total_1_fav": "74/15",
          "total_2_fav": "72/24"
        },
        {
          "id": "19",
          "name": "BANGKA BELITUNG",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 495500,
          "vote_2": 288097,
          "vote_1_percent": 18,
          "vote_2_percent": 16,
          "total_1_fav": "74/15",
          "total_2_fav": "72/24"
        },
        {
          "id": "76",
          "name": "SULAWESI BARAT",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 474852,
          "vote_2": 263345,
          "vote_1_percent": 15,
          "vote_2_percent": 16,
          "total_1_fav": "70/14",
          "total_2_fav": "80/15"
        },
        {
          "id": "63",
          "name": "KALIMANTAN SELATAN",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 821284,
          "vote_2": 1463514,
          "vote_1_percent": 9,
          "vote_2_percent": 16,
          "total_1_fav": "59/18",
          "total_2_fav": "79/10"
        },
        {
          "id": "75",
          "name": "GORONTALO",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 369338,
          "vote_2": 344798,
          "vote_1_percent": 15,
          "vote_2_percent": 16,
          "total_1_fav": "70/14",
          "total_2_fav": "80/15"
        },
        {
          "id": "13",
          "name": "SUMATERA BARAT",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 407638,
          "vote_2": 2485265,
          "vote_1_percent": 9,
          "vote_2_percent": 16,
          "total_1_fav": "60/15",
          "total_2_fav": "80/12"
        },
        {
          "id": "71",
          "name": "SULAWESI UTARA",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 1218303,
          "vote_2": 359131,
          "vote_1_percent": 11,
          "vote_2_percent": 16,
          "total_1_fav": "70/14",
          "total_2_fav": "80/15"
        },
        {
          "id": "82",
          "name": "MALUKU UTARA",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 308197,
          "vote_2": 341711,
          "vote_1_percent": 12,
          "vote_2_percent": 16,
          "total_1_fav": "70/14",
          "total_2_fav": "80/15"
        },
        {
          "id": "72",
          "name": "SULAWESI TENGAH",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 913753,
          "vote_2": 705310,
          "vote_1_percent": 12,
          "vote_2_percent": 16,
          "total_1_fav": "70/14",
          "total_2_fav": "80/15"
        },
        {
          "id": "34",
          "name": "DI YOGYAKARTA",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 1640789,
          "vote_2": 735789,
          "vote_1_percent": 17,
          "vote_2_percent": 14,
          "total_1_fav": "62/21",
          "total_2_fav": "53/30"
        },
        {
          "id": "16",
          "name": "SUMATERA SELATAN",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 1915488,
          "vote_2": 2830326,
          "vote_1_percent": 11,
          "vote_2_percent": 16,
          "total_1_fav": "60/15",
          "total_2_fav": "80/12"
        },
        {
          "id": "73",
          "name": "SULAWESI SELATAN",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 2117839,
          "vote_2": 2807109,
          "vote_1_percent": 13,
          "vote_2_percent": 16,
          "total_1_fav": "70/14",
          "total_2_fav": "80/15"
        },
        {
          "id": "17",
          "name": "BENGKULU",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 582845,
          "vote_2": 585598,
          "vote_1_percent": 17,
          "vote_2_percent": 16,
          "total_1_fav": "74/15",
          "total_2_fav": "72/24"
        },
        {
          "id": "21",
          "name": "KEPULAUAN RIAU",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 546373,
          "vote_2": 460957,
          "vote_1_percent": 17,
          "vote_2_percent": 16,
          "total_1_fav": "74/15",
          "total_2_fav": "72/24"
        },
        {
          "id": "52",
          "name": "NUSA TENGGARA BARAT",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 950480,
          "vote_2": 2009305,
          "vote_1_percent": 16,
          "vote_2_percent": 16,
          "total_1_fav": "73/14",
          "total_2_fav": "73/23"
        },
        {
          "id": "94",
          "name": "PAPUA",
          "color_1": "#932427",
          "color_2": "#1f496f",
          "vote_1": 2125922,
          "vote_2": 184552,
          "vote_1_percent": 18,
          "vote_2_percent": 23,
          "total_1_fav": "73/14",
          "total_2_fav": "69/25"
        }
      ]
    };

    let chartData = horseRaceTokohData;
    // console.log("XXXX chart horser race")
    // console.log(JSON.stringify(chartData))

    if (period.type === "predictive" && this.props.map.province.summary) {
      chartData = {
        location_id: "-1",
        location_name: "National",
        total_vote_1: this.props.map.province.total_calon_1,
        total_vote_1_percent: this.props.map.province.total_calon_1.toLocaleString(
          1
        ),
        total_vote_2: this.props.map.province.total_calon_2,
        total_vote_2_percent: this.props.map.province.total_calon_2.toLocaleString(
          1
        ),
        data: this.props.map.province.summary.map((d, i) => {
          return {
            color_1: "#932427",
            color_2: "#1f496f",
            id: d.location_id,
            name: d.location_name,
            vote_1: d.total_calon_1,
            vote_1_percent: d.total_calon_1.toLocaleString(1),
            vote_2: d.total_calon_2,
            vote_2_percent: d.total_calon_2.toLocaleString(1)
          };
        })
      };
    }

    let horseraceChart;

  switch (turnout.method.value) {
    case "Survey 5 (jan 2022)":
      horseraceChart = <div className="container-fluid countdown-content">
        {chartData.data.length > 0 && (
          <HorseRaceChart5
            data={horseraceWave5}
            handlePieClick={handlePieClick}
            isProv={loc}
            period={period}
            isNational={
              period.value !== "pilkada2017" && period.value !== "pilkada2018"
            }
            handleToUpdatePieHover={handleToUpdatePieHover}
          />
        )}
      </div>
      console.log("processing new result of survey..");

      data_table_elected = dataCountdownWave5;
      break;

    case "Survey 4 (jul 2021)":
      horseraceChart = <div className="container-fluid countdown-content">
        {chartData.data.length > 0 && (
          <HorseRaceChart4
            data={horseraceWave4}
            handlePieClick={handlePieClick}
            isProv={loc}
            period={period}
            isNational={
              period.value !== "pilkada2017" && period.value !== "pilkada2018"
            }
            handleToUpdatePieHover={handleToUpdatePieHover}
          />
        )}
      </div>
      console.log("processing new result of survey..");

      data_table_elected = dataCountdownWave4;
      break;

    case "Survey 3 (jun 2021)":
      horseraceChart = <div className="container-fluid countdown-content">
        {chartData.data.length > 0 && (
          <HorseRaceChart3
            data={horseraceWave3}
            handlePieClick={handlePieClick}
            isProv={loc}
            period={period}
            isNational={
              period.value !== "pilkada2017" && period.value !== "pilkada2018"
            }
          />
        )}
      </div>
      console.log("processing new result of survey..");
      break;

    case "Survey 2 (mar 2021)":
      horseraceChart = <div className="container-fluid countdown-content">
        {chartData.data.length > 0 && (
          <HorseRaceChart2
            data={horseraceWave2}
            handlePieClick={handlePieClick}
            isProv={loc}
            period={period}
            isNational={
              period.value !== "pilkada2017" && period.value !== "pilkada2018"
            }
          />
        )}
      </div>
      break;
    case "Survey 1 (nov 2020)":
      horseraceChart = <div className="container-fluid countdown-content">
        {chartData.data.length > 0 && (
          <HorseRaceChart
            data={chartData}
            handlePieClick={handlePieClick}
            isProv={loc}
            period={period}
            isNational={
              period.value !== "pilkada2017" && period.value !== "pilkada2018"
            }
          />
        )}
      </div>
      break;
    default:
      horseraceChart = <div className="container-fluid countdown-content">
        {chartData.data.length > 0 && (
          <HorseRaceChart2
            data={horseraceWave2}
            handlePieClick={handlePieClick}
            isProv={loc}
            period={period}
            isNational={
              period.value !== "pilkada2017" && period.value !== "pilkada2018"
            }
          />
        )}
      </div>
  };

  let dataET = [
    {
      "name": "DKI Jakarta",
      "value": 2.2
    },
    {
      "name": "Jawa Barat",
      "value": 2.3
    },
    {
      "name": "Jawa Tengah",
      "value": 1.2
    },
    {
      "name": "Jawa Timur",
      "value": 3.9
    },
    {
      "name": "Banten",
      "value": 0.4
    }
  ];

  // console.log("----xxxxx----");
  // console.log(dataCountdownWave4['00']);

  console.log("XXXX data mapIdHover");
  console.log(mapIdHover);
  // if(dataCountdownWave4.hasOwnProperty("mapIdHover")) {
  //   this.setState({
  //     mapIdHover: '00'
  //   })
  // }

  let data_table_countdown =  data_table_elected['00'].map((el, idx) => {
    let countDiv = el.count_prov;

    if(el.kode_prov == '00') {
      countDiv = 4000
    }

    let pctCount = el.count/countDiv * 100;

    return <Fragment>
       <tr>
          <td>{el.candidate}</td>
          <td>{el.count}</td>
          <td>{pctCount.toFixed(2)}%</td>
        </tr>
    </Fragment>
  });

  try {
    data_table_countdown =  data_table_elected[mapIdHover].map((el, idx) => {
      let countDiv = el.count_prov;
  
      if(el.kode_prov == '00') {
        countDiv = 4000
      }
  
      let pctCount = el.count/countDiv * 100;
  
      return <Fragment>
         <tr>
            <td>{el.candidate}</td>
            <td>{el.count}</td>
            <td>{pctCount.toFixed(2)}%</td>
          </tr>
      </Fragment>
    });
  }
  catch(err) {
    this.setState({
      ...this.state,
      mapIdHover: '00'
    })
  }

  let layoutSimulation = <Fragment>
    <div className="row">
      <div className="col-lg-7" style={{background:'#212529', borderBottom: '3px solid #932427'}}>
        <div style={{marginTop:'-50px'}}>{horseraceChart}</div>
      </div>
      <div className="col-lg-5" style={{background: '#212529', borderBottom: '3px solid #932427', paddingRight:'20px'}}>
        <h4 className="text-center" style={{marginTop:'45px', marginBottom: '20px', color:'white'}}> 
          Political Figure Electability
        </h4>
        <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-red table-bordered">
                <thead>
                  <tr>
                    <th>Candidate</th>
                    <th>#Voters</th>
                    <th>Voters Pct</th>
                  </tr>
                </thead>
                <tbody>
                {data_table_countdown}
                  {/* <tr>
                    <td>Ganjar Pranowo</td>
                    <td>985</td>
                    <td>24.63%</td>
                  </tr>
                  <tr>
                    <td>Prabowo Subianto</td>
                    <td>646</td>
                    <td>16.15%</td>
                  </tr>
                  <tr>
                    <td>Erick Thohir</td>
                    <td>515</td>
                    <td>12.88%</td>
                  </tr>
                  <tr>
                    <td>Ridwan Kamil</td>
                    <td>457</td>
                    <td>11.43%</td>
                  </tr>
                  <tr>
                    <td>Anies Baswedan</td>
                    <td>419</td>
                    <td>10.48%</td>
                  </tr>
                  <tr>
                    <td>Sandiaga Uno</td>
                    <td>262</td>
                    <td>6.73%</td>
                  </tr>
                  <tr>
                    <td>Agus Harimurti Yudhoyono</td>
                    <td>101</td>
                    <td>2.53%</td>
                  </tr>
                  <tr>
                    <td>Puan Maharani</td>
                    <td>22</td>
                    <td>0.83%</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
      </div>
      {/* <div className="col-lg-4" style={{background: '#212529', borderBottom: '3px solid #932427', paddingRight:'100px'}}>
        <h4 className="text-center" style={{marginTop:'55px', marginBottom: '-125px', color:'white'}}> Shift Chart Electability ET in Province</h4>
        <ShiftingChart data={data_shifting_1}/>
      </div> */}
    </div>
  </Fragment>
  if(chartValue === 'predictive') {

    let et_weight_month1 = ((this.state.w1 * 0.02 + 0.5 + (Math.random() * 0.3)  ) + (this.state.w2 * 0.03 + 0.8 + (Math.random() * 0.3)  ) + (this.state.w3 * 0.05 + 0.2 + (Math.random() * 0.3)));
    let et_weight_month2 = ((this.state.w1 * 0.03 + 0.5 + (Math.random() * 0.5)  ) + (this.state.w2 * 0.03 + 0.8 + (Math.random() * 0.5)  ) + (this.state.w3 * 0.07 + 0.2 + (Math.random() * 0.5)));
    let et_weight_month3 = ((this.state.w1 * 0.05 + 0.5 + (Math.random() * 0.2)  ) + (this.state.w2 * 0.01 + 0.8 + (Math.random() * 0.2)  ) + (this.state.w3 * 0.09 + 0.2 + (Math.random() * 0.2)));

    if(this.state.w1 < -10) {
      et_weight_month1 = ((this.state.w1 * 0.02 + 0.5 + (Math.random() * 0.3)  ) + (this.state.w2 * 0.03 + 0.8 + (Math.random() * 0.3)  ) + (-12 * 0.05 + 0.2 + (Math.random() * 0.3)));
      et_weight_month2 = ((this.state.w1 * 0.03 + 0.5 + (Math.random() * 0.5)  ) + (this.state.w2 * 0.03 + 0.8 + (Math.random() * 0.5)  ) + (-5 * 0.07 + 0.2 + (Math.random() * 0.5)));
      et_weight_month3 = ((this.state.w1 * 0.05 + 0.5 + (Math.random() * 0.2)  ) + (this.state.w2 * 0.01 + 0.8 + (Math.random() * 0.2)  ) + (-20 * 0.09 + 0.2 + (Math.random() * 0.2)));
    }

    
    // if(et_weight_month1 < 0){
    //   et_weight_month1 = 0.1
    // }

    // if(et_weight_month2 < 0){
    //   et_weight_month2 = 0.1
    // }
  

    const dataSimulation = [
      {
        name: 'Wave 1',
        "Erick Thohir": 13,
        "Ridwan Kamil": 14,
        "Ganjar Pranowo": 16,
        "Sandiaga Uno": 5,
        "Anies Baswedan": 16
      },
      {
        name: 'Wave 2',
        "Erick Thohir": 6,
        "Ridwan Kamil": 14,
        "Ganjar Pranowo": 16,
        "Sandiaga Uno": 10,
        "Anies Baswedan": 14
      },{
        name: 'Wave 3',
        "Erick Thohir": 4,
        "Ridwan Kamil": 9,
        "Ganjar Pranowo": 17,
        "Sandiaga Uno": 8,
        "Anies Baswedan": 11
      },{
        name: 'Wave 4',
        "Erick Thohir": 13,
        "Ridwan Kamil": 11,
        "Ganjar Pranowo": 25,
        "Sandiaga Uno": 7,
        "Anies Baswedan": 10
      },{
        name: 'Wave 5',
        "Erick Thohir": 13,
        "Ridwan Kamil": 12,
        "Ganjar Pranowo": 23,
        "Sandiaga Uno": 11,
        "Anies Baswedan": 12
      },{
        name: 'Next Months',
        "Erick Thohir": (9 + et_weight_month2).toFixed(2),
        "Ridwan Kamil": (8 - et_weight_month2 / 5).toFixed(2),
        "Ganjar Pranowo": (29 - et_weight_month2 / 8).toFixed(2),
        "Sandiaga Uno": (7 - et_weight_month2 / 5).toFixed(2),
        "Anies Baswedan": (9 - et_weight_month2 / 8).toFixed(2)
      },{
        name: 'Next 3 Months',
        "Erick Thohir": (9 + et_weight_month3).toFixed(2),
        "Ridwan Kamil": (8 - et_weight_month3 / 6).toFixed(2),
        "Ganjar Pranowo": (26 - et_weight_month3 / 8).toFixed(2),
        "Sandiaga Uno": (7 - et_weight_month3 / 6).toFixed(2),
        "Anies Baswedan": (9 - et_weight_month3 / 6).toFixed(2)
      },
    ];

    let pred_et_1 = (2 + et_weight_month1).toFixed(2);
    let pred_et_2 = (4 + et_weight_month2).toFixed(2);
    let pred_et_3 = (3 + et_weight_month3).toFixed(2);
    let avgPred_ET = ((parseFloat(pred_et_1) + parseFloat(pred_et_2) + parseFloat(pred_et_3)).toFixed(2));

    const dataForecast = [
      {
        "name": "Nov-20",
        "Erick Thohir": 13,
        "Ridwan Kamil": 14,
        "Ganjar Pranowo": 16,
        "Sandiaga Uno": 5,
        "Anies Baswedan": 16
      },
      {
        "name": "Mar-21",
        "Erick Thohir": 6,
        "Ridwan Kamil": 14,
        "Ganjar Pranowo": 16,
        "Sandiaga Uno": 10,
        "Anies Baswedan": 14
      },
      {
        "name": "Jun-21",
        "Erick Thohir": 4,
        "Ridwan Kamil": 9,
        "Ganjar Pranowo": 17,
        "Sandiaga Uno": 8,
        "Anies Baswedan": 11
      },
      {
        "name": 'Jul-21',
        "Erick Thohir": 13,
        "Ridwan Kamil": 11,
        "Ganjar Pranowo": 25,
        "Sandiaga Uno": 7,
        "Anies Baswedan": 10
      },
      {
        "name": "Jan-22",
        "Erick Thohir": 13,
        "Ridwan Kamil": 12,
        "Ganjar Pranowo": 23,
        "Sandiaga Uno": 11,
        "Anies Baswedan": 12
      },
      {
        "name": "Apr-22",
        "Erick Thohir": (9 + et_weight_month2).toFixed(2),
        "Ridwan Kamil": (8 - et_weight_month2 / 5).toFixed(2),
        "Ganjar Pranowo": (29 - et_weight_month2 / 8).toFixed(2),
        "Sandiaga Uno": (7 - et_weight_month2 / 5).toFixed(2),
        "Anies Baswedan": (9 - et_weight_month2 / 8).toFixed(2)
      },
      {
        "name": "Jul-22",
        "Erick Thohir": (9 + et_weight_month3).toFixed(2),
        "Ridwan Kamil": (8 - et_weight_month3 / 6).toFixed(2),
        "Ganjar Pranowo": (26 - et_weight_month3 / 8).toFixed(2),
        "Sandiaga Uno": (7 - et_weight_month3 / 6).toFixed(2),
        "Anies Baswedan": (9 - et_weight_month3 / 6).toFixed(2)
      }
    ];
    
    
    layoutSimulation = <Fragment>
      <div style={{background: '#212529', height: '100%'}}>
        <div className="row">
          <div className="col-lg-12 text-center" style={{color:'white', padding: '30px'}}>
            <h3>Simulation for Electability &amp; Coverage</h3>
          </div>
        </div>

        <div style={{paddingLeft: '130px'}}>
        <Dropdown className="dropdown" isOpen={dropdownOpen} toggle={this.toogleDropdown}>
              <DropdownToggle caret>
                Calculate by:
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem style={{color:'white'}} className="dropdown-item">Province</DropdownItem>
                <DropdownItem style={{color:'white'}} className="dropdown-item">Age</DropdownItem>
                <DropdownItem style={{color:'white'}} className="dropdown-item">Religion</DropdownItem>
                <DropdownItem style={{color:'white'}} className="dropdown-item">SES</DropdownItem>
              </DropdownMenu>
            </Dropdown>
        </div>
        
        <div className="row" height="50%">
          <div className="col-lg-12" style={{height: '300px', width: '100%'}}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={1000}
                height={550}
                data={dataSimulation}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Erick Thohir" fill="#F44436" />
                <Bar dataKey="Ridwan Kamil" fill="#A24F36" />
                <Bar dataKey="Ganjar Pranowo" fill="#2196F3" />
                <Bar dataKey="Sandiaga Uno" fill="#43A048" />
                <Bar dataKey="Anies Baswedan" fill="#9D27B0" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="row" style={{color:'white', marginLeft: '50px', marginTop:'25px'}}>
          {/* <div className="col-lg-3">
            <Dropdown className="dropdown" isOpen={dropdownOpen} toggle={this.toogleDropdown}>
              <DropdownToggle caret>
                Calculate by:
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem style={{color:'white'}} className="dropdown-item">Province</DropdownItem>
                <DropdownItem style={{color:'white'}} className="dropdown-item">Age</DropdownItem>
                <DropdownItem style={{color:'white'}} className="dropdown-item">Religion</DropdownItem>
                <DropdownItem style={{color:'white'}} className="dropdown-item">SES</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div> */}
          <div className="col-lg-2 text-left">
            <h4>Custom Growth (weighted) :</h4>
            <div style={{padding: '15px 5px 5px 1px'}}>
              {/* <Label check>
                <Input type="checkbox" id="checkbox2" />{' '}
                News Coverage
              </Label> */}
              <CustomInput type="checkbox" checked="checked" id="sim_weight1" label="News Coverage" />
            </div>
            
            <RangeStepInput
                min={-100} max={100}
                value={this.state.w1} step={1}
                onChange={this.onChangeW1.bind(this)}
              />
            <span style={{}}> {this.state.w1}%</span>

            <div style={{padding: '5px 5px 5px 1px'}}>
              {/* <Label check>
                <Input type="checkbox" id="checkbox2" />{' '}
                Sentiment Positif
              </Label> */}
              <CustomInput type="checkbox" checked="checked" id="sim_weight2" label="Positive Sentiments" />
            </div>
            <RangeStepInput
                min={-100} max={100}
                value={this.state.w2} step={1}
                onChange={this.onChangeW2.bind(this)}
              />

            <span style={{}}> {this.state.w2}%</span>

            <div style={{padding: '5px 5px 5px 1px'}}>  
              {/* <Label value='checked' check>
                <Input  type="checkbox" id="checkbox2" />{' '}
                Total Engagements
              </Label> */}
              <CustomInput type="checkbox" checked="checked" id="sim_weight3" label="Total Engagements" />
              {/* <CustomInput type="switch" id="weight3" name="customSwitch" label="Total Engagements" /> */}
            </div>
            <RangeStepInput
                min={-100} max={100}
                value={this.state.w3} step={1}
                onChange={this.onChangeW3.bind(this)}
              />
            <span style={{}}> {this.state.w3}%</span>
          </div>
          <div className="col-lg-7" style={{height: '350px', width: '100%', paddingRight:'50px'}}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={350}
                data={dataForecast}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <ReferenceLine x="Aug-21" stroke="white" strokeDasharray="3 4 5 2" label="Prediction" />
                <Line dataKey="Erick Thohir" stroke="#F44436" strokeWidth={5} activeDot={{ r: 8 }} strokeDasharray="3 4 5 2"/>
                <Line dataKey="Ridwan Kamil" stroke="#A24F36" strokeWidth={2} activeDot={{ r: 8 }} />
                <Line dataKey="Ganjar Pranowo" stroke="#2196F3" strokeWidth={2} activeDot={{ r: 8 }} />
                <Line dataKey="Sandiaga Uno" stroke="#43A048" strokeWidth={2} activeDot={{ r: 8 }} />
                <Line dataKey="Anies Baswedan" stroke="#9D27B0" strokeWidth={2} activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="col-lg-3" height="200px" style={{color:'white', padding: '20px'}}>
            <h2>
              {/* If Total of Monthly News Coverage increase by <span style={{color:'#2196F3', fontSize:'1.2rem'}}>avg. {this.state.w1}% </span> 
              + Total Eng. <span style={{color:'#2196F3', fontSize:'1.2rem'}}>avg. {this.state.w3}% </span> 
              with positive sentiment increase by <span style={{color:'#2196F3', fontSize:'1.2rem'}}>avg. {this.state.w2}% </span> then */}
              Monthly Electability of Erick Thohir will increase by <br/> 
              <span style={{color:'#F44436', fontSize:'2.9rem'}}>avg. {avgPred_ET}% </span> 
            </h2>
          </div>
        </div>
      </div>
    </Fragment>
  }

    return (
      <React.Fragment>
       <div className="full-width" style={{background: '#212529'}}>
            <div className="col-lg-12">
              <div className="sidebar sidebar-left">
                <div className="sidebar-content">
                  <nav className="main-menu">
                    <ul className="nav">
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="icon dripicons-graph-bar" />
                        </a>
                        <SubmenuTurnout
                          handleToggleInfo={handleToggleInfo}
                          handleGetSubMenu={handleGetSubMenu}
                          handleToggleSubMenu={handleTogglePOISubMenu}
                          handleCloseSubmenu={handleCloseSubmenu}
                          handleChangeMethod={handleChangeMethod}
                          placeholder="Select Scoring"
                          data={{
                            info,
                            openPOI,
                            isPOISubOpen,
                            handleSubMenuChange,
                            turnout
                          }}
                        />
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
        </div>
        <div
            style={{display:'flex', justifyContent:'center', background:'#212529'}}
          >
            <ButtonToolbar>
              <ButtonGroup onClick={this.centerMenuSelection.bind(this)}>
                <Button className={`${
                      chartValue === "predictive" ? "active" : ""
                    }`} value='2'>Historical</Button>
                <Button className={`${
                      chartValue === "historical" ? "active" : ""
                    }`} value='1'>Predictive Simulator</Button>
              </ButtonGroup>
            </ButtonToolbar>
        </div>
        {layoutSimulation}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  chart: state.chart,
  map: state.map,
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getHRC,
  getOverview,
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Countdown);
