import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';

import Login from 'components/unauthorized/Login';
import Dashboard from 'components/Dashboard';

import store from 'reducers';

import { history } from 'helpers';

class App extends Component {

  componentDidMount(){
		const token = localStorage.getItem('idn');
		if(!token){
			history.push('/auth/login')
		}else{
      if(history.location.pathname === '/auth/login'){
        history.push('/dashboard')
      }
    }
	}

  render() {
    return (
      <Provider store={ store }>
        <Router history={ history }>
          <Switch>
            <Route path="/auth/login" component={ Login } />
            <Route path="/dashboard" component={ Dashboard } />
						<Redirect from="/" to="/auth/login" />
          </Switch>  
        </Router>
      </Provider>
    );
  }
}

export default App;