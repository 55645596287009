import React from "react";
import Script from "react-load-script";

export default function GoogleTrends({ type, url }) {
  const handleScriptLoad = _ => {
    window.trends.embed.renderExploreWidgetTo(
      document.getElementById("widget"),
      type,
      {
        comparisonItem: [
            { keyword: "/m/0h_9zgb", geo: "ID", time: "today 1-m" },
            { keyword: "/m/0k2fm41", geo: "ID", time: "today 1-m" },
            { keyword: "/g/12b02cxjk", geo: "ID", time: "today 1-m" },
            { keyword: "/m/0bwj_gq", geo: "ID", time: "today 1-m" },
            { keyword: "/m/02vrsyl", geo: "ID", time: "today 1-m" }
        ],
        category: 0,
        property: ""
      },
      {
        exploreQuery: `q=%2Fm%2F0h_9zgb,%2Fm%2F0k2fm41,%2Fg%2F12b02cxjk,%2Fm%2F0bwj_gq,%2Fm%2F02vrsyl&geo=ID&date=today%201-m`,
        guestPath: "https://trends.google.com:443/trends/embed/"
      }
    );
  };

  const renderGoogleTrend = _ => {
    return <Script url={url} onLoad={handleScriptLoad} />;
  };

  return <div className="googleTrend">{renderGoogleTrend()}</div>;
}
