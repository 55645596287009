import React, { Component, Fragment } from "react";
import { HorseRaceChart } from "components/shared/charts/HorseRaceChart";
import { connect } from "react-redux";
import { getHRC } from "actions/chartActions";
import { getOverview } from "actions/mapActions";

import SubmenuTurnout from "components/layout/sidebar/SubmenuTurnout";

import { getTurnout } from "actions/mapActions";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap
} from "actions/mapActions";
import { reAuth } from "actions/authActions";
import toastr from "toastr";
import { GET } from "API";

import ReactRoundedImage from "react-rounded-image";

import profile1 from "media/img/avatars/chosen_gp.jpeg";
import profile2 from "media/img/avatars/chosen_prabowo.jpeg";
import profile3 from "media/img/avatars/chosen_et.jpeg";
import profile4 from "media/img/avatars/chosen_rk.jpeg";
import profile5 from "media/img/avatars/chosen_anies.jpeg";
import profile6 from "media/img/avatars/chosen_sandiaga.jpeg";
import profile7 from "media/img/avatars/chosen_ahy.jpeg";

import {CustomInput, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup, ButtonToolbar, Label, Input } from 'reactstrap';
import {RangeStepInput} from 'react-range-step-input';

// import Dropdown from 'react-dropdown';
// import 'react-dropdown/style.css';

import Cookies from 'universal-cookie';


import "styles/components/map-submenu.css";

import { horseraceWave2, horseraceWave3, horseraceWave4} from "./DataWave2";

const API_HOST = process.env.REACT_APP_API_HOST;

// const options = [
//   'one', 'two', 'three'
// ]

const cookies = new Cookies();

class Choice extends Component {

  constructor(props) {
    super(props);
    this.select = this.select.bind(this);

    cookies.set('chosen_selected', '', { path: '/' });
  }

  state = {
    period: {
      type: "historical",
      value: "pemilu2014",
      year: "2014",
      filter: {
        value: "5050",
        time: "undecided_a"
      }
    },
    loc: false,
    chartValue: "historical",
    dropdownOpen: false,
    w1: 10,
    w2: 20,
    w3: 30,
    region: {
      pulau: { value: null, data: [] },
      provinsi: { value: null, data: [], id: null },
      step: 0
    },
    turnout: {
      method: {
        value: "Survey 5 (jan 2022)",
        map_type: "horserace",
        data: [
          { id: 1, name: "Survey 5 (jan 2022)" },
          { id: 2, name: "Survey 4 (jul 2021)" },
        ]
      }
    },
    dropdownChosenOpen: false,
    dataSelected : {
      name: "Erick Thohir",
      url: profile3,
      name2: "Ganjar Pranowo",
      url2: profile1,
      pct: 57
    },
    nn: '',
    openMap: true,
    isSubOpen: 0,
    openPOI: true,
    isPOISubOpen: 0,
    isPOIAccordionOpen: null
  };

  toogleDropdownChosen = () => {
    this.setState({
      ...this.state,
      // dataSelected: data,
      dropdownChosenOpen: !this.state.dropdownChosenOpen
    })
  }

  select(event) {
    this.setState({
      ...this.state,
      dropdownChosenOpen: !this.state.dropdownChosenOpen,
      dataSelected: {
        "name": "Ganjar Pranowo",
        "url": profile1,
        "name2": "Erick Thohir",
        "url2": profile3,
        "pct": 32
      }
    })
  }

  changesDataSelected = (data) => {
    this.setState({
      ...this.state,
      dataSelected: data,
      nn: data.name
    });

    cookies.set('chosen_selected', data, { path: '/' });
  }

  handleTogglePOISubMenu = submenu => {
    if (this.state.isPOISubOpen === submenu) {
      this.setState({
        ...this.state,
        isPOISubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isPOISubOpen: submenu
      });
    }
  };

  onChangeW1(e) {
    const newVal = (e.target.value);
    this.setState({w1: newVal});
  };

  onChangeW2(e) {
    const newVal = (e.target.value);
    this.setState({w2: newVal});
  };

  onChangeW3(e) {
    const newVal = (e.target.value);
    this.setState({w3: newVal});
  };


  toogleDropdown = () => {
    this.setState({
      ...this.state,
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  centerMenuSelection = (event) => {
    console.log(event.target.value);

    if(event.target.value == 2) {
      this.setState({
        ...this.state,
        chartValue: 'historical'
      })
    } else {
      this.setState({
        ...this.state,
        chartValue: 'predictive'
      })
    }
  };

  handleCloseSubmenu = () => {
    this.setState({
      ...this.state,
      isSubOpen: 0,
      isPOISubOpen: 0
    });
  };

  handleToggleSubMenu = submenu => {
    if (this.state.isSubOpen === submenu) {
      this.setState({
        ...this.state,
        isSubOpen: 0
      });
    } else {
      this.setState({
        ...this.state,
        isSubOpen: submenu,
        openPOI: false
      });
    }
  };

  handleSubMenuChange = data => {
    this.props.setPositionMap(data.center_lat, data.center_lng, 9);
  };

  handleResetPosition = () => {
    this.props.clearPositionMap();
  };

  handleChangeMethod = name => {
    this.setState({
      ...this.state,
      turnout: {
        method: {
          value: name,
          data: [
            { id: 1, name: "Survey 5 (jan 2022)" },
            { id: 2, name: "Survey 4 (jul 2021)" },
          ]
        }
      },
      isPOISubOpen: 0
    });
  };

  mFormatter = num => {
    return num > 999 ? (num / 1000).toLocaleString(0) + "K" : num;
  };

  handleGetSubMenu = (parentID, target, parentName, parent, step, id) => {
    if (id) {
      GET(`${API_HOST}/sental/geo/master/poi/${id}`)
        .then(response => {
          this.setState({
            region: {
              ...this.state.region,
              [parent]: {
                ...this.state.region[parent],
                value: parentName,
                id
              },
              step
            }
          });
        })
        .catch(err => {
          console.log(err);
          // err.response.status === 401
          //   ? this.props.reAuth()
          //   : toastr.error(err.response.statusText);
        });
    } else {
      GET(`${API_HOST}/sental/master/${parentID}/${target}`).then(res => {
        this.setState({
          ...this.state,
          region: {
            ...this.state.region,
            [parent]: {
              ...this.state.region[parent],
              value: parentName,
              id
            },
            [target]: { value: null, data: res.data },
            step
          }
        });
      });
    }
  };

  handleChangePeriod = ({ type, value }) => {
    this.setState({
      period: {
        type,
        value,
        filter: {
          value:
            type === "predictive" ? this.state.period.filter.value : "5050",
          time:
            type === "predictive"
              ? this.state.period.filter.time
              : "undecided_a"
        }
      },
      loc: false
    });
  };

  handleChangeFilter = val => {
    this.setState({
      period: {
        ...this.state.period,
        filter: {
          value: val.value,
          time: val.time
        }
      }
    });
  };

  handlePieClick = async (id, isProv) => {
    if (this.state.period.value === "pemilu2014") {
      await this.props.getHRC(
        this.state.period.type,
        this.state.period.value,
        id
      );
      this.setState({
        ...this.state,
        loc: !isProv
      });
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    // console.log("comp update xxx");

    let cookies_chosen = cookies.get('chosen_selected');
    // console.log(cookies_chosen);

    if(cookies_chosen !== '' ) {
      // console.log("OKK")

      if(this.state.dataSelected.name != cookies_chosen.name) {
        this.setState({
          ...this.state,
          dataSelected: cookies_chosen
        });
      }
    }

    if (
      prevState.period.value !== this.state.period.value &&
      this.state.period.type === "historical"
    ) {
      await this.props.getHRC(this.state.period.type, this.state.period.value);
    } else if (
      prevState.period !== this.state.period &&
      this.state.period.type === "predictive"
    ) {
      await this.props.getOverview(
        this.state.period.value,
        this.state.period.filter.time
      );
    }
  }

  componentDidMount() {
    this.props.getHRC("historical", "pemilu2014");
  }

  render() {
    const { info, chart } = this.props;
    const { period, loc, chartValue, openPOI,
      isPOISubOpen, isSubOpen, region, turnout, dropdownOpen, dropdownChosenOpen, dataSelected } = this.state;
    const { handleChangePeriod, handlePieClick, handleChangeFilter, 
      handleToggleInfo, handleTogglePOISubMenu, handleSubMenuChange,
      handleCloseSubmenu, handleGetSubMenu, handleChangeMethod
     } = this;

    //  let dataSelected = this.state.dataSelected;

     let data_choice = [
       {
         "name": "Ganjar Pranowo",
         "url": profile1,
         "name2": "Erick Thohir",
         "url2": profile3,
         "pct": 32
       },
       {
        "name": "Prabowo Subianto",
        "url": profile2,
        "name2": "Sandiaga Uno",
        "url2": profile6,
        "pct": 22
      },
      {
        "name": "Erick Thohir",
        "url": profile3,
        "name2": "Ganjar Pranowo",
        "url2": profile1,
        "pct": 57
      },
      {
        "name": "Ridwan Kamil",
        "url": profile4,
        "name2": "Ganjar Pranowo",
        "url2": profile1,
        "pct": 26
      },
      {
        "name": "Anies Baswedan",
        "url": profile5,
        "name2": "Sandiaga Uno",
        "url2": profile6,
        "pct": 32
      },
      {
        "name": "Sandiaga Uno",
        "url": profile6,
        "name2": "Anies Baswedan",
        "url2": profile5,
        "pct": 25
      },
      {
        "name": "Agus Harimurti Yudhoyono",
        "url": profile7,
        "name2": "Prabowo Subianto",
        "url2": profile2,
        "pct": 23
      },
     ]
    
    return (
      <React.Fragment>
       <div className="full-width" style={{color: 'white', background: '#212529', height: '100%'}}>
            <div className="col-lg-12">
              <div className="sidebar sidebar-left">
                <div className="sidebar-content">
                  <nav className="main-menu">
                    <ul className="nav">
                      <li>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="icon dripicons-graph-bar" />
                        </a>
                        <SubmenuTurnout
                          handleToggleInfo={handleToggleInfo}
                          handleGetSubMenu={handleGetSubMenu}
                          handleToggleSubMenu={handleTogglePOISubMenu}
                          handleCloseSubmenu={handleCloseSubmenu}
                          handleChangeMethod={handleChangeMethod}
                          placeholder="Select Scoring"
                          data={{
                            info,
                            openPOI,
                            isPOISubOpen,
                            handleSubMenuChange,
                            turnout
                          }}
                        />
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="row" style={{marginTop: '20px'}} >
              <div className="col-lg-12">
                <h4 style={{color: 'white', margin: '20px', justifyContent:'center', textAlign: 'center' }} >
                Who would be your CHOICE to be next PRESIDENT 
                  </h4>
              </div>
            </div>

            <div className="row" style={{marginTop: '20px'}} >
              <div className="col-lg-5">
                <h5 style={{ justifyContent:'center', textAlign: 'center'}}>
                If [FIRST CHOICE] were not a candidate for president.
                </h5>
              </div>
              <div className="col-lg-2">

              </div>
              <div className="col-lg-5">
                <h5 style={{ justifyContent:'center', textAlign: 'center'}}>
                The SECOND CHOICE to be the next president of Indonesia
                </h5>
              </div>
            </div>    

            <div className="row" style={{marginTop: '30px'}} >
              <div className="col-lg-5" style={{ justifyContent:'center'}}>
                <div
                style={{display:'flex', justifyContent:'center', background:'#212529'}}
                >
                  <ReactRoundedImage
                    image={dataSelected.url}
                    roundedColor="#b30707"
                    imageWidth="250"
                    imageHeight="250"
                    roundedSize="13"
                    borderRadius="70"
                  />
                </div>
              </div>
              <div className="col-lg-2" >
                <h4 style={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '15px', paddingRight: '15px',
                    color:'black', background: 'white', border: '2px solid #b30707', borderRadius: '5px',
                    justifyContent:'center', textAlign: 'center'}}>
                  Probability Match for <br/>SECOND CHOICE is <br/><br/> <span style={{fontSize: '1.8rem'}}>{dataSelected.name2}: {dataSelected.pct}%</span>
                </h4>
              </div>
              <div className="col-lg-5">
                <div
                style={{display:'flex', justifyContent:'center', background:'#212529'}}
                >
                  <ReactRoundedImage
                    image={dataSelected.url2}
                    roundedColor="#b30707"
                    imageWidth="250"
                    imageHeight="250"
                    roundedSize="13"
                    borderRadius="70"
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{marginTop: '20px'}} >
              <div className="col-lg-5 text-center d-flex justify-content-center">
                <Dropdown className="dropdown" isOpen={dropdownChosenOpen} toggle={this.toogleDropdownChosen}>
                  <DropdownToggle>
                    First Choice : <strong>[{dataSelected.name}]</strong>
                  </DropdownToggle>
                  <DropdownMenu>
                    {data_choice.map((data, index) => (
                      <DropdownItem key={index} style={{color:'white'}} className="dropdown-item" onClick={()=>{this.changesDataSelected(data)}} value={data.name} >
                        <div>{data.name}</div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown> 
                {/* <Dropdown options={options} onChange={this._onSelect} value={defaultOption} placeholder="Select an option" /> */}
              </div>
              <div className="col-lg-2">

              </div>
              <div className="col-lg-5">
                <h5 style={{ justifyContent:'center', textAlign: 'center'}}>
                
                </h5>
              </div>
            </div>  

            <div className="row" style={{marginTop: '1px', padding: '25px' }}>

              <div className="col-lg-12">
                <h5 style={{ justifyContent:'center', textAlign: 'center'}}>
                If FIRST CHOICE and SECOND CHOICE were not candidates for president, who would be your THIRD CHOICE to be the next president of Indonesia?
                </h5>
              </div>

              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-red table-bordered">
                    <thead>
                      <tr>
                        <th>Candidate</th>
                        <th>First Choice</th>
                        <th>First+Second Choice </th>
                        <th>First+Second+Third Choice</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ganjar Pranowo</td>
                        <td>25%</td>
                        <td>42%</td>
                        <td>50%</td>
                      </tr>
                      <tr>
                        <td>Prabowo Subianto</td>
                        <td>16%</td>
                        <td>26%</td>
                        <td>36%</td>
                      </tr>
                      <tr>
                        <td>Erick Thohir</td>
                        <td>13%</td>
                        <td>25%</td>
                        <td>34%</td>
                      </tr>
                      <tr>
                        <td>Ridwan Kamil</td>
                        <td>11%</td>
                        <td>24%</td>
                        <td>35%</td>
                      </tr>
                      <tr>
                        <td>Anies Baswedan</td>
                        <td>10%</td>
                        <td>20%</td>
                        <td>32%</td>
                      </tr>
                      <tr>
                        <td>Agus Harimurti Yudhoyono</td>
                        <td>2%</td>
                        <td>8%</td>
                        <td>14%</td>
                      </tr>
                      <tr>
                        <td>Puan Maharani</td>
                        <td>1%</td>
                        <td>3%</td>
                        <td>5%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

                     

            
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  chart: state.chart,
  map: state.map,
  info: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  getHRC,
  getOverview,
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Choice);
