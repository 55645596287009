import React, { Component } from "react";
import Horizontal100 from "components/shared/charts/Horizontal100";
import LineDot from "components/shared/charts/LineDot";
import moment from "moment";

import profile1 from "media/img/avatars/et_overview.jpeg";
import profile2 from "media/img/avatars/gp_overview.jpeg";
import profile3 from "media/img/avatars/prabowo_overview.jpeg";
import profile4 from "media/img/avatars/chosen_anies.jpeg";

import BorderMap from "components/shared/map/BorderMap";
import ShiftingChart from "components/shared/charts/ShiftingChartWhite";
import ModalOverview from "components/shared/ModalOverview";
import MapBottom from "components/shared/map/MapBottom";

import { connect } from "react-redux";
import { setModal } from "actions";

import { ArrowRight, ArrowUp, ArrowDown } from 'react-bootstrap-icons';

import { getTurnout, getVPCandidate } from "actions/mapActions";
import {
  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  setPositionMap,
  clearPositionMap,
  // getOverview
} from "actions/mapActions";
import { reAuth } from "actions/authActions";

import { VictoryChart, VictoryBar, VictoryGroup, VictoryAxis } from "victory";


import "styles/components/map-submenu.css";

const dataVPCandidateSV4 = {
    'gp': [
      {
        "x": "Erick Thohir",
        "y": 17.7,
        "label": "17.7 %"
      },
      {
        "x": "Agus Harimurti Yudhoyono",
        "y": 4.63,
        "label": "4.63 %"
      },
      {
        "x": "Andika Perkasa",
        "y": 9.27,
        "label": "9.27 %"
      },
      {
        "x": "Prabowo Subianto",
        "y": 13.14,
        "label": "13.14 %"
      },
      {
        "x": "Puan Maharani",
        "y": 5.09,
        "label": "5.09 %"
      },
      {
        "x": "Ridwan Kamil",
        "y": 25.72,
        "label": "25.72 %"
      },
      {
        "x": "Sandiaga Uno",
        "y": 14.68,
        "label": "14.68 %"
      },
      {
        "x": "Dont know",
        "y": 8.93,
        "label": "8.93 %"
      },
      {
        "x": "Other or SPECIFY",
        "y": 0.85,
        "label": "0.85 %"
      }
  ],

  'ps': [
    {
      "x": "Erick Thohir",
      "y": 21.15,
      "label": "21.15 %"
    },
    {
      "x": "Agus Harimurti Yudhoyono",
      "y": 6.89,
      "label": "6.89 %"
    },
    {
      "x": "Andika Perkasa",
      "y": 13.42,
      "label": "13.42 %"
    },
    {
      "x": "Puan Maharani",
      "y": 3.7,
      "label": "3.7 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 19.0,
      "label": "19.0 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 25.14,
      "label": "25.14 %"
    },
    {
      "x": "Dont know",
      "y": 9.43,
      "label": "9.43 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 1.28,
      "label": "1.28 %"
    }
  ],

  'ab': [
    {
      "x": "Erick Thohir",
      "y": 14.38,
      "label": "14.38 %"
    },
    {
      "x": "Agus Harimurti Yudhoyono",
      "y": 17.64,
      "label": "17.64 %"
    },
    {
      "x": "Andika Perkasa",
      "y": 7.37,
      "label": "7.37 %"
    },
    {
      "x": "Prabowo Subianto",
      "y": 10.19,
      "label": "10.19 %"
    },
    {
      "x": "Puan Maharani",
      "y": 3.64,
      "label": "3.64 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 16.55,
      "label": "16.55 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 21.5,
      "label": "21.5 %"
    },
    {
      "x": "Dont know",
      "y": 8.11,
      "label": "8.11 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 0.62,
      "label": "0.62 %"
    }, 
  ],
}

const dataVPCandidateSV5 = {
  'gp': [
    {
      "x": "Erick Thohir",
      "y": 25.48,
      "label": "25.48 %"
    },
    {
      "x": "Agus Harimurti Yudhoyono",
      "y": 3.98,
      "label": "3.98 %"
    },
    {
      "x": "Andika Perkasa",
      "y": 6.13,
      "label": "6.13 %"
    },
    {
      "x": "Mahfud MD",
      "y": 10.87,
      "label": "10.87 %"
    },
    {
      "x": "Prabowo Subianto",
      "y": 8.61,
      "label": "8.61 %"
    },
    {
      "x": "Puan Maharani",
      "y": 2.27,
      "label": "2.27 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 22.13,
      "label": "22.13 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 15.71,
      "label": "15.71 %"
    },
    {
      "x": "Dont know",
      "y": 4.6,
      "label": "4.6 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 0.21,
      "label": "0.21 %"
    },
  ],
  'ps': [
    {
      "x": "Erick Thohir",
      "y": 25.54,
      "label": "25.54 %"
    },
    {
      "x": "Agus Harimurti Yudhoyono",
      "y": 5.28,
      "label": "5.28 %"
    },
    {
      "x": "Andika Perkasa",
      "y": 8.47,
      "label": "8.47 %"
    },
    {
      "x": "Mahfud MD",
      "y": 13.25,
      "label": "13.25 %"
    },
    {
      "x": "Puan Maharani",
      "y": 1.9,
      "label": "1.9 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 21.53,
      "label": "21.53 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 19.81,
      "label": "19.81 %"
    },
    {
      "x": "Dont know",
      "y": 4.11,
      "label": "4.11 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 0.11,
      "label": "0.11 %"
    }
  ],
  'ab':[
    {
      "x": "Erick Thohir",
      "y": 17.15,
      "label": "17.15 %"
    },
    {
      "x": "Agus Harimurti Yudhoyono",
      "y": 16.61,
      "label": "16.61 %"
    },
    {
      "x": "Andika Perkasa",
      "y": 5.93,
      "label": "5.93 %"
    },
    {
      "x": "Mahfud MD",
      "y": 7.69,
      "label": "7.69 %"
    },
    {
      "x": "Prabowo Subianto",
      "y": 7.29,
      "label": "7.29 %"
    },
    {
      "x": "Puan Maharani",
      "y": 1.61,
      "label": "1.61 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 18.25,
      "label": "18.25 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 18.63,
      "label": "18.63 %"
    },
    {
      "x": "Dont know",
      "y": 6.78,
      "label": "6.78 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 0.06,
      "label": "0.06 %"
    }
  ]
}

const dataVPCandidateSV6 = {
  'gp': [
    {
      "x": "Agus Harimurti Yudhoyono",
      "y": 3.67,
      "label": "3.67 %"
    },
    {
      "x": "Airlangga Hartarto",
      "y": 1.89,
      "label": "1.89 %"
    },
    {
      "x": "Andika Perkasa",
      "y": 5.5,
      "label": "5.5 %"
    },
    {
      "x": "Chairul Tanjung",
      "y": 1.89,
      "label": "1.89 %"
    },
    {
      "x": "Erick Thohir",
      "y": 24.19,
      "label": "24.19 %"
    },
    {
      "x": "Khofifah Indar Parawansa",
      "y": 1.6,
      "label": "1.6 %"
    },
    {
      "x": "Mahfud MD",
      "y": 9.17,
      "label": "9.17 %"
    },
    {
      "x": "Muhaimin Iskandar",
      "y": 1.3,
      "label": "1.3 %"
    },
    {
      "x": "Puan Maharani",
      "y": 3.13,
      "label": "3.13 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 25.13,
      "label": "25.13 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 17.15,
      "label": "17.15 %"
    },
    {
      "x": "Dont know",
      "y": 4.14,
      "label": "4.14 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 0.18,
      "label": "0.18 %"
    }
  ],
  'ab': [
    {
      "x": "Agus Harimurti Yudhoyono",
      "y": 11.71,
      "label": "11.71 %"
    },
    {
      "x": "Airlangga Hartarto",
      "y": 2.07,
      "label": "2.07 %"
    },
    {
      "x": "Andika Perkasa",
      "y": 5.97,
      "label": "5.97 %"
    },
    {
      "x": "Chairul Tanjung",
      "y": 2.37,
      "label": "2.37 %"
    },
    {
      "x": "Erick Thohir",
      "y": 16.62,
      "label": "16.62 %"
    },
    {
      "x": "Khofifah Indar Parawansa",
      "y": 1.77,
      "label": "1.77 %"
    },
    {
      "x": "Mahfud MD",
      "y": 8.04,
      "label": "8.04 %"
    },
    {
      "x": "Muhaimin Iskandar",
      "y": 1.66,
      "label": "1.66 %"
    },
    {
      "x": "Puan Maharani",
      "y": 1.89,
      "label": "1.89 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 23.18,
      "label": "23.18 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 19.22,
      "label": "19.22 %"
    },
    {
      "x": "Dont know",
      "y": 4.26,
      "label": "4.26 %"
    },
  
    {
      "x": "Other or SPECIFY",
      "y": 0.3,
      "label": "0.3 %"
    }
  ],
  'ps':[
    {
      "x": "Agus Harimurti Yudhoyono",
      "y": 2.66,
      "label": "2.66 %"
    },
    {
      "x": "Airlangga Hartarto",
      "y": 3.13,
      "label": "3.13 %"
    },
    {
      "x": "Andika Perkasa",
      "y": 7.87,
      "label": "7.87 %"
    },
    {
      "x": "Chairul Tanjung",
      "y": 1.71,
      "label": "1.71 %"
    },
    {
      "x": "Erick Thohir",
      "y": 20.46,
      "label": "20.46 %"
    },
    {
      "x": "Khofifah Indar Parawansa",
      "y": 1.48,
      "label": "1.48 %"
    },
    {
      "x": "Mahfud MD",
      "y": 11.59,
      "label": "11.59 %"
    },
    {
      "x": "Muhaimin Iskandar",
      "y": 1.6,
      "label": "1.6 %"
    },
    {
      "x": "Puan Maharani",
      "y": 2.84,
      "label": "2.84 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 22.12,
      "label": "22.12 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 19.4,
      "label": "19.4 %"
    },
    {
      "x": "Dont know",
      "y": 3.9,
      "label": "3.9 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 0.18,
      "label": "0.18 %"
    }
  ]
}

const dataVPCandidateSV7 = {
  'gp': [
    {
      "x": "Erick Thohir",
      "y": 30.82,
      "label": "30.82 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 34.39,
      "label": "34.39 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 30.07,
      "label": "30.07 %"
    },
    {
      "x": "Dont know",
      "y": 4.3,
      "label": "4.3 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 0.43,
      "label": "0.43 %"
    }
  ],
  'ab': [
    {
      "x": "Erick Thohir",
      "y": 30.82,
      "label": "30.82 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 34.39,
      "label": "34.39 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 30.07,
      "label": "30.07 %"
    },
    {
      "x": "Dont know",
      "y": 4.3,
      "label": "4.3 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 0.43,
      "label": "0.43 %"
    }
  ],
  'ps':[
    {
      "x": "Erick Thohir",
      "y": 30.82,
      "label": "30.82 %"
    },
    {
      "x": "Ridwan Kamil",
      "y": 34.39,
      "label": "34.39 %"
    },
    {
      "x": "Sandiaga Uno",
      "y": 30.07,
      "label": "30.07 %"
    },
    {
      "x": "Dont know",
      "y": 4.3,
      "label": "4.3 %"
    },
    {
      "x": "Other or SPECIFY",
      "y": 0.43,
      "label": "0.43 %"
    }
  ]
}

class MappingVP extends Component {
  state = {
    period: {
      value: "jun2023",
    },
    candidateSelection: "gp",
    month: moment().format("YYYY-MM"),
    survey_phase: 'april2023', //may2023_1, //may2023_2
    dataRespondenVoters: dataVPCandidateSV7['gp']
  };

  handleChangePeriod = val => {
    this.setState({
      period: {
        value: val.value
      },
    });

    if( val.value === 'may2023_1') {
      this.setState({
        dataRespondenVoters: dataVPCandidateSV5[this.state.candidateSelection]
      });
    } else if( val.value === 'may2023_2') {
      this.setState({
        dataRespondenVoters: dataVPCandidateSV6[this.state.candidateSelection]
      });
    } else if( val.value === 'jun2023') {
      this.setState({
        dataRespondenVoters: dataVPCandidateSV7[this.state.candidateSelection]
      });
    } else {
      this.setState({
        dataRespondenVoters: dataVPCandidateSV4[this.state.candidateSelection]
      });
    }
  };

  handleChangeFilter = val => {
    this.setState({
      period: {
        ...this.state.period,
        filter: {
          value: val.value,
          time: val.time
        }
      }
    });
  };

  handleBorderMapClick = data => {
    this.props.setModal(data);
  };

  componentDidMount() {
    // this.props.getOverview(this.state.period.time);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.period !== this.state.period) {
      if (this.state.period.type === "historical") {
        // this.props.getOverview(this.state.period.time);
      } else {
        // this.props.getOverview(
        //   this.state.period.time,
        //   this.state.period.filter.time
        // );
      }
    }
  }

  handleChange = (event) => {
    
    console.log(event);
    let dataSource = dataVPCandidateSV4[event['type']];
    if(this.state.period.value === 'may2023_1') {
      dataSource = dataVPCandidateSV5[event['type']];
    }

    this.setState({
      candidateSelection: event['type'],
      dataRespondenVoters: dataSource
    });
    // if (event['type'] == 'gp') {
    //   console.log('gp');
    //   this.setState({
    //       candidateSelection: 1,
    //   });
    // } else if(event['type'] == 'ps') {
    //   this.setState({
    //       candidateSelection: 2,
    //   });
    // } else if(event['type'] == 'ab') {
    //   this.setState({
    //       candidateSelection: 3,
    //   });
    // }
    
  }
  

  render() {
    const { period, month, candidateSelection, dataRespondenVoters } = this.state;
    const {
      handleChangePeriod,
      handleBorderMapClick,
      handleChangeFilter
    } = this;
    const { map } = this.props;

    const data = {
      suara_popular: {
        title: "Suara Polling",
        items: [
          {
            percentage: 12,
            color: "#d0021b"
          },
          {
            percentage: 20,
            color: "rgb(6, 0, 194)"
          },
          {
            percentage: 24,
            color: "rgb(20, 219, 80)"
          },
          {
            percentage: 20,
            color: "rgb(219, 199, 20)"
          },
          {
            percentage: 24,
            color: "#bbb"
          }
        ]
      }
    };

    console.log(period);

    return (
      <React.Fragment>
        <div className="full-width dark-bg overview-legend">
          <section className="page-content container-fluid">
            <div className="row">      
              <div className="col-lg-1">
                <div className="candidate-info-header left">
                  <img
                    src={profile1}
                    className="rounded-circle"
                    alt="Candidate Name"
                  />
                </div>
              </div>         
              <div className="col-lg-4">
                <h1 className="overview-header-vp">Vice President Scenario </h1>
              </div>
              
              {/* <div className="col-lg-6">
                <div className="row">
                  <span className="overview-header">Choose Candidate: </span>
                </div>
                <div className="row">
                  
                </div>
              </div> */}

              <div 
                className="col-lg-2 cursor-button"
                onClick={ e =>
                    this.handleChange({"type": "gp"})
                }
              >
                <div className={`candidate-info-header right right1 
                  ${candidateSelection === "gp" ? "" : "candidate-options" }`}>
                  <img
                    src={profile2}
                    className="rounded-circle"
                    alt="Candidate Name"
                  />
                  <div className="candidate-name">
                    <span>Ganjar P.</span>
                  </div>
                </div>
              </div>
              <div 
                className="col-lg-2 cursor-button"
                onClick={ e =>
                    this.handleChange({"type": "ps"})
                }
              >
                <div className={`candidate-info-header right left2 
                  ${candidateSelection === "ps" ? "" : "candidate-options" }`}>
                  <img
                    src={profile3}
                    className="rounded-circle"
                    alt="Candidate Name"
                  />
                  <div className="candidate-name">
                    <span>Prabowo S.</span>
                  </div>
                </div>
              </div>
              <div 
                className="col-lg-2 cursor-button"
                onClick={ e =>
                    this.handleChange({"type": "ab"})
                }
              >
                <div className={`candidate-info-header right right2 
                  ${candidateSelection === "ab" ? "" : "candidate-options" }`}>
                  <img
                    src={profile4}
                    className="rounded-circle"
                    alt="Candidate Name"
                  />
                  <div className="candidate-name">
                    <span>Anies B.</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="container-fluid overview-content">
          <div className="overview-right">
            <div className="row" style={{ marginBottom: 15 + "px" }}>
              <div className="col-lg-12">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    value="historical"
                    disabled
                    className={`btn btn-secondary btn-sm ${
                      period.type === "historical" ? "active" : ""
                    }`}
                  >
                    Survey Filter:
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        value: "april2023"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "april2023" ? "active" : ""
                    }`}
                  >
                    Apr (2023)
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        value: "may2023_1"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "may2023_1" ? "active" : ""
                    }`}
                  >
                    May (2023)
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        value: "may2023_2"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "may2023_2" ? "active" : ""
                    }`}
                  >
                    Late May (2023)
                  </button>
                  <button
                    type="button"
                    onClick={e =>
                      handleChangePeriod({
                        value: "jun2023"
                      })
                    }
                    className={`btn btn-secondary btn-sm ${
                      period.value === "jun2023" ? "active" : ""
                    }`}
                  >
                    June (2023)
                  </button>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center">Who do you think would be the best vice president ?</h4>
                  <VictoryChart
                    height={270}
                    width={1366}
                    domainPadding={10}
                    maxDomain={{ y: 30 }}
                  >
                    <VictoryBar
                      data={dataRespondenVoters}
                      style={{
                        data: {
                          fill: d =>
                            d.x === "Erick Thohir"
                              ? "#d0021b"
                              : d.x === "Agus Harimurti Yudhoyono"
                              ? "rgb(6, 0, 194)"
                              : d.x === 'Prabowo Subianto'
                              ? "#edbc34" 
                              : d.x === 'Puan Maharani'
                              ? "#eb0202"
                              : d.x === 'Ridwan Kamil'
                              ? "#f4ff1c"
                              : d.x === 'Sandiaga Uno'
                              ? "#047522"
                              : d.x === 'Dont know'
                              ? "#ddd"
                              : d.x === 'Andika Perkasa'
                              ? "#04c437"
                              : d.x === 'Mahfud MD'
                              ? "#f59376" 
                              : d.x === 'Airlangga Hartarto'
                              ? "#f5ec42"
                              : d.x === 'Chairul Tanjung'
                              ? "#471d96"
                              : d.x === 'Khofifah Indar Parawansa'
                              ? "#91000c"
                              : d.x === 'Muhaimin Iskandar'
                              ? "#14c700" 
                              : "#eee",
                          width: 80
                        }
                      }}
                    />
                    <VictoryAxis
                      style={{
                        axis: { stroke: "none" }
                      }}
                    />
                  </VictoryChart>
                </div>
            </div>
            
          </div>
        </section>

        <div className="map50-bottom-soe">
          <MapBottom source="vp-candidate" type={candidateSelection} handleBorderMapClick={()=>{}} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  map: state.map,
  turnout: state.map.turnout
});

const mapDispatchToProps = {
  // getOverview,
  setModal,


  getInfo,
  setInfo,
  clearInfo,
  getPOI,
  getPOIprovince,
  clearPOI,
  getLegends,
  reAuth,
  setPositionMap,
  clearPositionMap,
  getTurnout,
  getVPCandidate
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MappingVP);
