import React from "react";
import { connect } from "react-redux";

import SideInfoRelawan from "./SideInfoRelawan";

import {
    getLegendsDemography,
    getDemographySidePanelDemography
  } from "actions/mapActions";

import { makeStyles } from '@material-ui/core/styles';
import { Card, Select  } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl'

const provinceTest = [
    {
        "id": "-1",
        "label": "National"
    },
    {
        "id": "31",
        "label": "DKI Jakarta"
    },
    {
        "id": "32",
        "label": "Jawa Barat"
    },
    {
        "id": "33",
        "label": "Jawa Tengah"
    },
    {
        "id": "35",
        "label": "Jawa Timur"
    }
]

class SidePanelRelawan extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        type: this.props.type,
        region: this.props.region
    };

    async componentDidMount() {
        // await this.props.getLegendsDemography('unknown', 'all');  
        await this.props.getDemographySidePanelDemography(this.state.region);
    }

    async componentDidUpdate(prevProps, prevState) {
        if(this.props.region !== this.state.region) {
            this.setState({
                region: this.props.region
            })

            this.props.getDemographySidePanelDemography(this.props.region);
        } 
    }

    handleChange = (event) => {
        const name = event.target.name;
        this.setState({
            region: event.target.value,
        });

        //call api get province
        console.log(event.target.value);
        this.props.getDemographySidePanelDemography(event.target.value);  

    };
    

    render() {
        const { info } = this.props;
        const { region } = this.state;

        return (
            <>
                {/* <div className="row" style={{marginTop: '10px', marginBottom: '20px'}}>
                    <div className="col-md-12">
                        <Card style={{background: '#282828', padding: '20px'}}>
                            <div className="row" style={{padding: '0px 20px', color: 'white', fontSize: '16px'}}>
                                <strong>Region</strong>
                            </div>
                            <div className="row" style={{padding: '5px 20px 20px 20px'}}>
                                <Select
                                    value={region}
                                    onChange={this.handleChange}
                                    label="National"
                                    fullWidth
                                    style={{background: '#4D4C51', color:"white", borderRadius: '4px', padding: '10px 20px', fontSize: '12px', textTransform:'capitalize'}}
                                >
                                    {
                                        provinceTest.map((val, idx) => (
                                            <MenuItem value={val.id} style={{color:"#4D4C51", padding: '5px 8px'}}>
                                                {val.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                            
                        </Card>
                        
                    </div>
                </div> */}
                <div className="row" style={{padding: '0px 20px', color: 'white', fontSize: '16px'}}>
                    <strong>Supporter Group</strong>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Card style={{background: '#282828'}}>
                            <SideInfoRelawan region={this.state.region} />
                        </Card>
                    </div>
                </div>
                
            </>
        )
    }
}
  
const mapStateToProps = state => ({
    info: state.map
});
  
const mapDispatchToProps = {
    getLegendsDemography,
    getDemographySidePanelDemography
};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidePanelRelawan);

// export default SidePanel;