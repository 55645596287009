export * from 'actions/APIActions';
export * from 'actions/authActions';
export * from 'actions/mapActions';

export function setModal(payload) {
  console.log('xxx set modal  ')
  console.log(payload);
  return {
    type: 'SET_MODAL',
    payload
  }
}

export function setModalRegister() {
  return {
    type: 'SET_MODAL_REGISTER',
  }
}

export function setModalTopic(payload) {
  return {
    type: 'SET_MODAL_TOPIC',
    payload
  }
}


export function closeModal(){
  return{
    type: 'CLOSE_MODAL'
  }
}