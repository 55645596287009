import React, { Component } from "react";
import { Scatter } from "react-chartjs-2";
import { scatterA, scatterB, scatterC, scatterD } from "components/graph/scatterDummy"

const data = {
  labels: ["Scatter"],
  datasets: [
    {
      label: "A",
      fill: false,
      backgroundColor: "rgba(215, 55, 62, .8)",
      pointBorderColor: "rgba(215, 55, 62, 1)",
      pointBackgroundColor: "rgba(215, 55, 62, .8)",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(215, 55, 62, 1)",
      pointHoverBorderColor: "#000",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: scatterA
    },
    {
      label: "B",
      fill: false,
      backgroundColor: "rgba(255, 125, 49, .8)",
      pointBorderColor: "rgba(255, 125, 49, .1)",
      pointBackgroundColor: "rgba(255, 125, 49, .1)",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(255, 125, 49, .1)",
      pointHoverBorderColor: "#000",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: scatterB
    },
    {
      label: "C",
      fill: false,
      backgroundColor: "rgba(255, 189, 99, .8)",
      pointBorderColor: "rgba(255, 189, 99, 1)",
      pointBackgroundColor: "rgba(255, 189, 99, .8)",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(255, 189, 99, .1)",
      pointHoverBorderColor: "#000",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: scatterC
    },
    {
      label: "D",
      fill: false,
      backgroundColor: "rgba(188, 188, 70, .8)",
      pointBorderColor: "rgba(188, 188, 70, 1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(188, 188, 70, .8)",
      pointHoverBorderColor: "#000",
      pointHoverBorderWidth: 1,
      pointRadius: 1,
      pointHitRadius: 10,
      data: scatterD
    }
  ]
};

const ScatterChart = (props) => (
        <Scatter data={data} />
)

export default ScatterChart;
