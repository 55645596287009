import React, { PureComponent } from "react";
import WordCloud from "react-d3-cloud";
import { GET } from "API";
import loading from "media/img/components/loading.gif";
import { Row, Col } from "reactstrap";

const API_HOST = process.env.REACT_APP_API_HOST,
  API_DIR = process.env.REACT_APP_API_DIR;

const fontSizeMapper = word => Math.log2(word.value) * 8;

class TopicCloudET extends PureComponent {
  state = {
    weight: 500,
    data: [],
    isLoading: true,
  };

  handleHover = () => {
    this.setState({
      weight: 700
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if(this.props.data !== undefined) {
      this.setState({
        data: this.props.data
      })
    }
  };

  componentDidMount() {
    // GET(
    //   `${API_HOST}/${API_DIR}/whatson/wordcloud?candidate=${
    //     this.props.candidate
    //   }`
    // ).then(res => {
    //   this.setState({
    //     data: res.data.data,
    //     isLoading: false
    //   });
    // });
    this.setState({
      isLoading: false
    })
  }

  render() {
    const { data, isLoading } = this.state;
    if (isLoading) {
      return (
        <Row>
          <Col
            style={{
              width: 750,
              height: 450,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img src={loading} alt="loading" />
          </Col>
        </Row>
      );
    } else {
      return (
        <WordCloud
          data={data}
          width={750}
          font="Impact"
          padding={0.1}
          height={450}
          weight={500}
          onWordClick={word => {}}
          // onWordClick={word => this.props.handleHashtagClick(word.text)}
          fontSizeMapper={fontSizeMapper}
        />
      );
    }
  }
}

export default TopicCloudET;
